/*eslint-disable*/
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import classes from './TermsAndConditions.module.css';
import Footer from '../../Component/Footer';
import Header from '../../Component/Header';
import { useState } from 'react';
import HeadingWithUnderline from '../../Component/HeadingWithUnderline';
import Parser from 'html-react-parser';
import Tabs from '../../Component/Tabs';
import { useLazyGetApiQuery } from '../../store/apiSlice/apiSlice';
import { Loader } from '../../Component/Loader';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const TermsAndConditions = () => {
  const {t}=useTranslation('webCommon')
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const tabsOptions = [
    { id: 1, text: t('termsAndConditions.tab1'), value: 'user' },
    { id: 2, text:t('termsAndConditions.tab2'), value: 'company' }
  ];
  const [selectedTab, setSelectedTab] = useState(tabsOptions[0]);

  const [getApiFunc, { isLoading, data: response }] = useLazyGetApiQuery();

  const getData = async () => {
    await getApiFunc({
      url: `terms-and-conditions?type=${selectedTab?.value}`,
      tag: `TermsAndConditions`
    });
  };

  useEffect(() => {
    getData();
  }, [selectedTab]);

  return (
    <div className={classes.page}>
      <Header containerClass="customContainer" />
      <div className={classes?.containerWrapper}>
        {(isLoading ) ? (
          <Loader />
        ) : (
          <Container className="customContainer py-4">
            <Row className={classes.row}>
              <Col md={12}>
                <HeadingWithUnderline>
                  <h4>{t('termsAndConditions.title')}</h4>
                </HeadingWithUnderline>
              </Col>
              <Col md={12}>
                <Tabs
                  tabsData={tabsOptions}
                  value={selectedTab}
                  setValue={setSelectedTab}
                  className={classes.tab}
                  tabClass={classes.tabLi}
                />
              </Col>
              <Col md={12}>
                <div className={classes.decriptionBox}>
                  {response?.data?.[0] 
                    ? Parser(response?.data[0]?.content?.[selectedLanguage])
                    : ''}
                </div>
              </Col>
            </Row>

            <div className={classes?.buttonContainer}></div>
          </Container>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
