import React, { useRef } from 'react';
import { MdUpload, MdModeEdit, MdClose } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import { imageUrl } from '../../config/apiUrl';
// import { GalleryImage } from "../../constant/imagePath";
import classes from './UploadImageBox.module.css';
import { IoMdPhotos } from 'react-icons/io';
import Button from '../Button/Button';

function UploadImageBox({
  value,
  setValue,
  label,
  edit = true,
  onDelete,
  onClose,
  isCloseable,
  hideDeleteIcon = false,
  isVideo = false,
  imgClass,
  containerClass = '',
  onEdit,
  fallBackIcon,
  disabled
}) {
  const inputRef = useRef(null);

  const showVideo = () => {
    return value ? (
      <video className={classes.video_div} id="video" width="320" height="240" controls>
        <source src={URL?.createObjectURL(value)} type="video/mp4" />
      </video>
    ) : (
      <div className={classes.uploadBox}>
        {fallBackIcon ? fallBackIcon : <IoMdPhotos size={50} className={classes.icon} />}
        <div className={classes.uploadIcon} onClick={() => inputRef.current.click()}>
          <MdUpload />
        </div>
      </div>
    );
  };
  const showPicture = () => {
    return value?.name || typeof value == 'string' ? (
      <div className={classes.imageUploaded}>
        <img
          src={typeof value == 'object' ? URL?.createObjectURL(value) : imageUrl(value)}
          className={imgClass ? imgClass : ''}
        />
        <div className={classes.editAndDelete}>
          {!hideDeleteIcon && (
            <div className={classes.icon} onClick={onDelete}>
              <RiDeleteBinLine />
            </div>
          )}
          {edit && (
            <>
              <div
                className={classes.icon}
                onClick={() => {
                  inputRef.current.click();
                  onEdit();
                }}>
                <MdModeEdit />
              </div>
            </>
          )}
        </div>
      </div>
    ) : (
      <div className={classes.uploadBox}>
        {fallBackIcon ? fallBackIcon : <IoMdPhotos size={50} className={classes.icon} />}
        <Button className={classes.uploadIcon} disabled={disabled} onClick={() => !disabled && inputRef.current.click()}>
          <MdUpload />
        </Button>
      </div>
    );
  };
  return (
    <>
      {label && <label className={classes.label}>{label}</label>}
      <div className={`${classes.box} ${containerClass}`}>
        <div className={classes.uploadImageBox}>
          {isVideo && showVideo()}

          {isCloseable && (
            <span className={classes.closeIcon} onClick={onClose}>
              <MdClose />
            </span>
          )}
          {!isVideo && showPicture()}
        </div>
        <input hidden type={'file'} ref={inputRef} onChange={(e) => setValue(e.target.files[0])} />
      </div>
    </>
  );
}

export default UploadImageBox;
