const BASE_CURRENCY = 'USD';
const API_URL = 'https://api.forexapi.eu/v2/live';
// const API_KEY = process.env.REACT_APP_CURRENCY_API;
const API_KEY = '1C6sgeudZnSHZwoP9UXFmC';
// const API_KEY = '1C819tnzprFy79YZhyTFVJ';

export const CURRENCY = {
  euro: 'EUR',
  pound: 'GBP',
  lira: 'TRY'
};

export const convertCurrency = async ({ unit = CURRENCY.euro }) => {
  const baseURL = `${API_URL}?base=${BASE_CURRENCY}&counter=${unit}&apikey=${API_KEY}`;
  try {
    const response = await fetch(baseURL);

    if (response.status !== 200) throw new Error('');

    const data = await response.json();
    return data?.quotes[unit]?.bid;
  } catch (error) {
    console.log('🚀 ~ convertCurrency ~ error:', error);
    return null;
  }
};
