/*eslint-disable*/
import React, { useEffect } from 'react';
import classes from './SavedProperties.module.css';
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import { Col, Row, FormCheck } from 'react-bootstrap';
import Tabs from '../../Component/Tabs';
import { useState } from 'react';
import { AiFillHeart } from 'react-icons/ai';
import AccountInfoLayout from '../../Component/AccountInfoLayout';
import PropertyHorizontalCard from '../../Component/PropertyHorizontalCard/PropertyHorizontalCard';
import { PaginationComponent } from '../../Component/Pagination/Pagination';
import EventHorizontalCard from '../../Component/EventHorizontalCard/EventHorizontalCard';
import DropDown from '../../Component/DropDown/DropDown';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useDeleteSavedPropertyEventsProjectsMutation,
  useLazyGetApiQuery
} from '../../store/apiSlice/apiSlice';
import { Loader } from '../../Component/Loader';
import { recordsLimit } from '../../config/apiUrl';
import { dateFilterOptions } from '../../constant/commonData';
import ProjectHorizontalCard from '../../Component/ProjectHorizontalCard/ProjectHorizontalCard';
import NoData from '../../Component/NoData/NoData';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function SavedProperties() {
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const { t } = useTranslation('savedEntities',{lng:'en'});
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState([]);
  const [getApiFunc, { isLoading: pageLoading, data: response }] =
    useLazyGetApiQuery();
  const [deleteRecordsApi, { isLoading: isDeleting }] =
    useDeleteSavedPropertyEventsProjectsMutation();
  const tabs = [
    {
      id: 1,
      text: `${t('savedProperties')} (${response?.savedProperties || 0})`,
      icon: <AiFillHeart />,
      path: '/saved-properties',
      type: 'property',
      showingResults: t('properties'),
      noData: t('noSavedProperties')
    },
    {
      id: 2,
      text: `${t('savedEvents')}  (${response?.savedEvents || 0})`,
      icon: <AiFillHeart />,
      path: '/saved-events',
      type: 'event',
      showingResults: t('events'),
      noData: t('noSavedEvents')
    },
    {
      id: 3,
      text: `${t('savedProjects')}  (${response?.savedProjects || 0})`,
      icon: <AiFillHeart />,
      path: '/saved-projects',
      type: 'project',
      showingResults: t('projects'),
      noData: t('noSavedProjects')
    }
  ];
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState(dateFilterOptions[0]);
  const [selectedTab, setSelectedTab] = useState(
    tabs?.find((el) => el?.path == pathname) || tabs[0]
  );

  function addOrRemoveItem(setter, getter, item) {
    const newData = [...getter];
    if (newData?.findIndex((e) => e?._id == item?._id) != -1) {
      newData.splice(
        newData?.findIndex((e) => e?._id == item?._id),
        1
      );
    } else {
      newData.splice(
        newData?.findIndex((e) => e?._id == item?._id),
        1,
        item
      );
    }
    setter(newData);
  }

  const deleteMultiple = async (deleteAll) => {
    const params = {
      ...(deleteAll
        ? { deleteAll: deleteAll, type: selectedTab?.type }
        : {
            ids: selectedItems?.map((item) => item?._id),
            deleteAll: deleteAll,
            type: selectedTab?.type
          })
    };

    if (deleteAll && response?.data.length == 0) return;
    const res = await deleteRecordsApi(params);
    if (res?.data) {
      toast.success(`Deleted Successfully.`);
    }
  };

  const getData = async (pgNo = page, sortBy = sort?.value, type = selectedTab?.type) => {
    await getApiFunc({
      url: `users/getMyFavorites?type=${type}&page=${pgNo}&limit=${recordsLimit}&sortBy=${sortBy}`,
      tag: `SavedPropertiesEventsProjects`
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className={classes.page}>
        <Header />
        <AccountInfoLayout>
          <Row className={classes.row}>
            <Col md={12}>
              <Tabs
                tabsData={tabs}
                value={selectedTab}
                setValue={(e) => {
                  setSelectedTab(e);
                  getData(1, sort?.value, e?.type);
                  navigate(e?.path);
                }}
                className={classes.tab}
                tabClass={classes.tabLi}
                selectedTabClass={classes.selectedTab}
              />
            </Col>
            <Col md={12}>
              <div className={classes.delete_div}>
                <div
                  style={{
                    display: 'flex'
                  }}>
                  <p>{`${t('showing')} ${response?.data?.length || 0}-${
                    response?.totalRecords || 0
                  } ${selectedTab?.showingResults} `}</p>
                  <p
                    className={[classes.deleteText, 'c-p'].join(' ')}
                    onClick={() => {
                      if (isDeleting) return;
                      deleteMultiple(true);
                    }}>
                    {t('deleteAll')}
                  </p>
                  <p
                    className={[classes.deleteText, 'c-p'].join(' ')}
                    onClick={() => {
                      if (isDeleting) return;
                      if (selectedItems?.length > 0) {
                        deleteMultiple(false);
                      } else {
                        toast.error('Please select at least one item.');
                      }
                    }}>
                    {t('deleteSelected')}
                  </p>
                </div>
                <div className={classes.sort_div}>
                  <p>{t('sortBy')}:</p>
                  <DropDown
                    classNamePrefix={'dropDownMain'}
                    placeholder={'Featured'}
                    optionLabel={[selectedLanguage]}
                    setValue={(e) => {
                      setPage(1);
                      setSort(e);
                      getData(1, e?.value);
                    }}
                    value={sort}
                    options={dateFilterOptions}
                  />
                </div>
              </div>
            </Col>
            {pageLoading ? (
              <Loader />
            ) : (
              <Col md={12}>
                {selectedTab?.id == 1
                  ? response?.data?.map((item, key) => (
                      <div className={classes.cardWithCheckbox} key={`property-${key}`}>
                        <PropertyHorizontalCard data={item?.entity}
                          refetch={() => {
                            getData(page, sort?.value);
                          }}
                        />
                        <FormCheck
                          value={selectedItems?.find((e) => e?._id == item?._id) ? true : false}
                          onChange={() => addOrRemoveItem(setSelectedItems, selectedItems, item)}
                        />
                      </div>
                    ))
                  : selectedTab?.id == 2
                  ? response?.data?.map((item, key) => (
                      <div
                        className={[classes.cardWithCheckbox, classes?.mb15].join(' ')}
                        key={`events-${key}`}>
                        <EventHorizontalCard
                          data={item?.entity}
                          refetch={() => {
                            getData(page, sort?.value);
                          }}
                        />
                        <FormCheck
                          value={selectedItems?.find((e) => e?._id == item?._id) ? true : false}
                          onChange={() => addOrRemoveItem(setSelectedItems, selectedItems, item)}
                        />
                      </div>
                    ))
                  : response?.data?.map((item, key) => (
                      <div
                        className={[classes.cardWithCheckbox, classes?.mb15].join(' ')}
                        key={`projects-${key}`}>
                        <ProjectHorizontalCard
                          data={item?.entity}
                          refetch={() => {
                            getData(page, sort?.value);
                          }}
                        />
                        <FormCheck
                          value={selectedItems?.find((e) => e?._id == item?._id) ? true : false}
                          onChange={() => addOrRemoveItem(setSelectedItems, selectedItems, item)}
                        />
                      </div>
                    ))}

                {response?.data?.length == 0 && <NoData text={selectedTab?.noData} />}
              </Col>
            )}
            {response?.data?.length > 0 && (
              <Col md={12} className="flexEnd">
                <PaginationComponent
                  currentPage={page}
                  setCurrentPage={(e) => {
                    setPage(e);
                    getData(e, sort?.value);
                  }}
                  totalPages={Math.ceil(response?.totalRecords / recordsLimit)}
                />
              </Col>
            )}
          </Row>
        </AccountInfoLayout>
      </div>
      <Footer />
    </>
  );
}

export default SavedProperties;
