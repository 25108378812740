import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categories: [],
  eventTypes: [],
  projectTypes: [],
  propertyTypes: [],
  interiorAmenities: [],
  exteriorAmenities: [],
  cms: {},
  agents: [],
  allBanners: [],
  areaUnit: 'm²',
  currency: { unit: 'USD', value: 1 }
};

const commonSlice = createSlice({
  name: 'commonReducer',
  initialState,
  reducers: {
    setAllCategories: (state, action) => {
      state.categories = action.payload.data;
    },

    setAllProjectTypes: (state, action) => {
      state.projectTypes = action.payload.data;
    },

    setAllPropertyTypes: (state, action) => {
      state.propertyTypes = action.payload.data;
    },

    setAllAmenities: (state, action) => {
      if (action.payload.type === 'interior') {
        state.interiorAmenities = action.payload.data;
      } else {
        state.exteriorAmenities = action.payload.data;
      }
    },

    setAllEventTypes: (state, action) => {
      state.eventTypes = action.payload.data;
    },
    setAllAgents: (state, action) => {
      state.agents = action.payload.data;
    },
    setAllCmsData: (state) => {
      state.cms = {};
    },
    setAllBanners: (state, action) => {
      state.allBanners = action.payload.data;
    },

    setAreaUnit: (state, action) => {
      state.areaUnit = action.payload;
    },

    setCurrencyUnit: (state, action) => {
      state.currency = action.payload;
    }
  }
});

export const {
  setAllCategories,
  setAllCmsData,
  setAllEventTypes,

  setAllAmenities,
  setAllPropertyTypes,
  setAllProjectTypes,
  setAllAgents,
  setAllBanners,
  setAreaUnit,
  setCurrencyUnit
} = commonSlice.actions;

export default commonSlice.reducer;
