/*eslint-disable*/

import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import AgentIntroSection from '../../Component/AgentIntroSection/AgentIntroSection';
import DropDown from '../../Component/DropDown/DropDown';
import { PaginationComponent } from '../../Component/Pagination/Pagination';
import PropertyHorizontalCard from '../../Component/PropertyHorizontalCard/PropertyHorizontalCard';
import Tabs from '../../Component/Tabs';
import classes from './AgentDetailPage.module.css';
// import EventHorizontalCard from '../../Component/EventHorizontalCard/EventHorizontalCard';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BannerImageAd from '../../Component/BannerImageAd';
import EventHorizontalCard from '../../Component/EventHorizontalCard/EventHorizontalCard';
import Footer from '../../Component/Footer';
import Header from '../../Component/Header';
import { Loader } from '../../Component/Loader';
import NoData from '../../Component/NoData/NoData';
import ProjectHorizontalCard from '../../Component/ProjectHorizontalCard/ProjectHorizontalCard';
import { imageUrl, recordsLimit } from '../../config/apiUrl';
import { propertiesData } from '../../constant/commonData';
import EmailAgentModal from '../../modals/EmailAgentModal';
import {
  useGetPublicAgentDetailQuery,
  useGetPublicAgentEntitiesMutation
} from '../../store/apiSlice/apiSlice';
import { filterOptions } from '../CompanyDetailPage/CompanyDetailPage';

const AgentDetailPage = () => {
  const { t } = useTranslation('agentOrCompanyDetail');
  const _tabsData = [
    {
      id: 1,
      text: t('agent.tabs.properties'),
      value: 'property'
    },
    {
      id: 2,
      text: t('agent.tabs.projects'),
      value: 'project'
    },
    {
      id: 3,
      text: t('agent.tabs.events'),
      value: 'event'
    }
  ];
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const slug = useParams()?.slug;
  const [selectedTab, setSelectedTab] = useState(_tabsData[0]);
  const [featuredFilterValue, setFeaturedFilterValue] = useState('');
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState('');
  const [propertyPurpose, setPropertyPurpose] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);

  // Banners
  const { allBanners } = useSelector((state) => state.commonReducer);

  const verticalBanners = allBanners?.filter(
    (item) => item?.page === 'agent-detail' && item?.bannerType == 'vertical'
  );

  const horizontalBanners = allBanners?.filter(
    (item) => item?.page === 'agent-detail' && item?.bannerType == 'horizontal'
  );

  // scroll to top
  const { data, isLoading } = useGetPublicAgentDetailQuery({ slug });
  const [getData, { data: propertyOrAgent, isLoading: isGetting, isFetching }] =
    useGetPublicAgentEntitiesMutation();
  const agentData = data?.data;

  async function getDataByType(pg = page, filters = {}, type = selectedTab?.value) {
    getData({
      slug,
      type: type,
      page: pg,
      data: { ...filters, lan: selectedLanguage },
      limit: recordsLimit
    });
  }

  // scroll to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      left: 0
    });
    getDataByType();
  }, []);

  const handleRefetch = () => {
    setPage(1);
    getDataByType(1, {
      ...(propertyPurpose?.value
        ? {
            propertyPurpose: propertyPurpose?.value?.propertyPurpose?.[selectedLanguage]
          }
        : {}),
      ...(featuredFilterValue?.value ? featuredFilterValue?.value : {})
    });
  };

  return (
    <>
      <style>
        {`
        .smDropdown__indicator{
            padding: 0px 8px;
        }
        .smDropdown__control{
            border-radius: 0;
        }
        `}
      </style>
      <div className={classes?.mainPage}>
        <Header containerClass={''} />
        {isLoading ? (
          <Loader />
        ) : (
          <Container className={`${classes?.mainContainer} `}>
            {/* Agent Intro Section: 2Col => Agent Intro, 8Col => About Me, 2Col => Banner Ad */}
            {verticalBanners[0]?.image && (
              <AgentIntroSection
                className={''}
                data={agentData}
                t={t}
                verticalBanner={imageUrl(verticalBanners[0]?.image)}
              />
            )}

            {/* Tabs => Properties + Events */}
            <div className={classes?.tabsWrapper}>
              <Tabs
                tabsData={_tabsData}
                value={selectedTab}
                setValue={(e) => {
                  setPage(1);
                  setSelectedTab(e);
                  setPropertyPurpose(null);
                  setFeaturedFilterValue(null);
                  getDataByType(1, {}, e?.value);
                }}
                className={classes?.tabsContainer}
                scrollOffset={200}
              />
            </div>

            <Row className={classes?.dataAndBannerContainer}>
              <Col md={12} lg={10}>
                {/* Data count strip */}
                <div className={classes?.dataCountStripContainer}>
                  <div className={classes?.dataStripLeft}>
                    {t('agent.filterResults.showing')} 1 - {propertyOrAgent?.data?.length ?? 0} of{' '}
                    {numeral(propertyOrAgent?.totalRecords ?? 0).format('0,0')}{' '}
                    {selectedTab?.value == 'property'
                      ? t('agent.filterResults.properties')
                      : selectedTab?.text}
                  </div>
                  {selectedTab?.value !== 'agent' && (
                    <div className={classes?.dataStripRight}>
                      <span>{t('agent.filterResults.sortBy')}</span>

                      {selectedTab?.value == 'property' && (
                        <DropDown
                          className={classes.filters}
                          isClearable={true}
                          classNamePrefix={'smDropdown'}
                          placeholder={t('agent.filterResults.buyRentSelect')}
                          options={filterOptions}
                          customStyle={{ width: '150px' }}
                          value={propertyPurpose}
                          setValue={(e) => {
                            setPropertyPurpose(e);
                            setPage(1);
                            getDataByType(1, {
                              ...(e?.value
                                ? {
                                    propertyPurpose: e?.value?.propertyPurpose?.[selectedLanguage]
                                  }
                                : {}),
                              ...(featuredFilterValue?.value ? featuredFilterValue?.value : {})
                            });
                          }}
                          getOptionLabel={(e) => e?.label?.[selectedLanguage]}
                          getOptionValue={(e) => e?.value?.propertyPurpose?.[selectedLanguage]}
                        />
                      )}
                      <DropDown
                        isClearable={true}
                        classNamePrefix={'smDropdown'}
                        options={_featuredFilterData}
                        value={featuredFilterValue}
                        setValue={(e) => {
                          setFeaturedFilterValue(e);
                          setPage(1);
                          getDataByType(1, {
                            ...e?.value,
                            ...(propertyPurpose?.value
                              ? {
                                  propertyPurpose:
                                    propertyPurpose?.value?.propertyPurpose?.[selectedLanguage]
                                }
                              : {})
                          });
                        }}
                        placeholder={t('agent.filterResults.featuredPremiumSelect')}
                        getOptionLabel={(e) => e?.label?.[selectedLanguage]}
                        optionValue={'id'}
                        customStyle={{ width: '190px' }}
                      />
                    </div>
                  )}
                </div>

                {/* Mapper */}
                {/* {selectedTab?.text?.toLocaleLowerCase() === 'our properties' ? (
                <> */}

                {isGetting || isFetching ? (
                  <Loader className={classes?.itemLoader} />
                ) : (
                  <>
                    {
                      <Row className={classes?.propertiesContainer}>
                        {propertyOrAgent?.data?.length == 0 ? (
                          <NoData text={t('agent.notFound')} />
                        ) : (
                          propertyOrAgent?.data?.map((item, index) => {
                            return (
                              <>
                                <Col
                                  md={12}
                                  key={index}
                                  className={
                                    ['project', 'event'].includes(selectedTab?.value) ? 'mb-3' : ''
                                  }>
                                  {selectedTab?.value === 'project' ? (
                                    <ProjectHorizontalCard
                                      data={item}
                                      index={index}
                                      onEmailClick={() => {
                                        setSelectedItem(item);
                                        setIsModalOpen('email');
                                      }}
                                      refetch={handleRefetch}
                                    />
                                  ) : selectedTab?.value === 'property' ? (
                                    <PropertyHorizontalCard
                                      data={item}
                                      index={index}
                                      onEmailClick={() => {
                                        setSelectedItem(item);
                                        setIsModalOpen('email');
                                      }}
                                      refetch={handleRefetch}
                                    />
                                  ) : (
                                    selectedTab?.value === 'event' && (
                                      <EventHorizontalCard
                                        data={item}
                                        index={index}
                                        onEmailClick={() => {
                                          setSelectedItem(item);
                                          setIsModalOpen('email');
                                        }}
                                        refetch={handleRefetch}
                                      />
                                    )
                                  )}
                                </Col>

                                {(index + 1) % 7 === 0 &&
                                  horizontalBanners[(page - 1) % horizontalBanners?.length]
                                    ?.image && (
                                    <Col md={12}>
                                      <div className={classes?.bannerAdContainer}>
                                        <div className={classes?.bannerAdContainer}>
                                          <BannerImageAd
                                            image={
                                              horizontalBanners[
                                                (page - 1) % horizontalBanners?.length
                                              ]?.image
                                            }
                                            className={classes.horizontalBanner}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                              </>
                            );
                          })
                        )}
                      </Row>
                    }

                    {!!propertyOrAgent?.data?.length && (
                      <div className="jCenter">
                        <PaginationComponent
                          totalPages={Math.ceil(propertyOrAgent?.totalRecords / recordsLimit)}
                          currentPage={page}
                          setCurrentPage={(e) => {
                            setPage(e);
                            getDataByType(e, {
                              ...(propertyPurpose?.value
                                ? {
                                    propertyPurpose:
                                      propertyPurpose?.value?.propertyPurpose?.[selectedLanguage]
                                  }
                                : {}),
                              ...(featuredFilterValue?.value ? featuredFilterValue?.value : {})
                            });
                          }}
                          className={'mb-4'}
                        />
                      </div>
                    )}
                  </>
                )}
                {/* <div className={classes?.propertiesContainer}>
                {_propertiesData?.map((property, index) => {
                  return (
                    <PropertyHorizontalCard
                      key={index}
                      data={property}
                      index={index}
                      onEmailClick={() => setIsModalOpen('email')}
                    />
                  );
                })}
              </div>
              <div className="jCenter">
                <PaginationComponent
                  totalPages={10}
                  currentPage={propertyPage}
                  setCurrentPage={setPropertyPage}
                  className={'mb-4'}
                />
              </div> */}

                {/* </>
              ) : (
                <>
                  <div className={classes?.propertiesContainer}>
                    {_propertiesData?.map((property, index) => {
                      return <EventHorizontalCard key={index} data={property} index={index} />;
                    })}
                  </div>

                  <PaginationComponent
                    totalPages={10}
                    currentPage={eventPage}
                    setCurrentPage={setEventPage}
                    className={'mb-4'}
                  />
                </>
              )} */}
              </Col>
              {/* Banner */}
              <Col md={12} lg={2}>
                <div className={classes?.bannerAdContainer}>
                  {verticalBanners[1]?.image && (
                    <BannerImageAd
                      image={imageUrl(verticalBanners[1]?.image)}
                      className={classes.banner}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>
      <Footer containerClass="" />
      <EmailAgentModal
        show={isModalOpen == 'email'}
        setShow={setIsModalOpen}
        data={selectedItem}
        type={selectedTab?.value}
      />
    </>
  );
};

export default AgentDetailPage;

export const _featuredFilterData = [
  {
    id: 1,
    label: {
      en: 'Featured',
      fa: 'ویژه',
      tr: 'Featured',
      ar: 'مميز',
      ru: 'Премиум',
      de: 'Premium',
      fr: 'Premium'
    },
    value: { isFeatured: true }
  },
  {
    id: 2,
    label: {
      en: 'Premium',
      fa: 'ویژه',
      tr: 'Premium',
      ar: 'مميز',
      ru: 'Премиум',
      de: 'Premium',
      fr: 'Premium'
    },
    value: { isPremium: true }
  }
];
export const _propertiesData = propertiesData;
