/*eslint-disable*/
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SideBarSkeleton from '../../../Component/SideBarSkeleton';
import classes from './NewProperty.module.css';
import Button from '../../../Component/Button/Button';
import { IoChevronBackOutline } from 'react-icons/io5';
import Input from '../../../Component/Input/Input';
import DropDown from '../../../Component/DropDown/DropDown';
import TextArea from '../../../Component/TextArea';
import {
  furnitureOptions,
  propertyStatusOptions,
  propertyPurposeOptions,
  propertyAgeOptions,
  propertyOrientationOptions,
  languagesOptions,
  roomsOptions,
  floorLevelOptions,
  titleDeedOptions
} from '../../../constant/commonData';
import FileUpload from '../../../Component/FileUpload';

import { useNavigate, useParams } from 'react-router-dom';
import LocationComponent from '../../../Component/LocationComponent';
import { CreateFormData, convertArrayKeysToObject, formatLabel } from '../../../config/apiUrl';
import {
  useCreatePropertyMutation,
  useDeletePropertyMediaMutation,
  useLazyGetSinglePropertyQuery
} from '../../../store/apiSlice/apiSlice';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import moment from 'moment';
import UploadImageBox from '../../../Component/UploadImageBox';
import { Loader } from '../../../Component/Loader';
import FormLanguages from '../../../Component/FormLanguages';
import { useTranslation } from 'react-i18next';
import validator from 'validator';

function NewProperty() {
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const [language, setLanguage] = useState(
    languagesOptions?.find((item) => item?.value === selectedLanguage)
  );
  const { t } = useTranslation(['dashboardProperties', 'locationComp'], {
    lng: language?.value
  });

  const { t: languageD } = useTranslation('locationComp', { lng: language?.value });
  const slug = useParams()?.slug;

  const languagesKeysObject = convertArrayKeysToObject(languagesOptions?.map((e) => e?.value));
  const propertyTypesOptions = useSelector((state) => state.commonReducer?.propertyTypes);
  const interiorAmenitiesOptions = useSelector((state) => state.commonReducer?.interiorAmenities);
  const exteriorAmenitiesOptions = useSelector((state) => state.commonReducer?.exteriorAmenities);
  const navigate = useNavigate();
  const [getSingleProperty, { isLoading: isGetting }] = useLazyGetSinglePropertyQuery();

  // Language

  const [formState, setFormState] = useState({
    title: languagesKeysObject,
    description: languagesKeysObject,
    propertyPurpose: languagesKeysObject,
    propertyType: languagesKeysObject,
    price: 0,
    netArea: 0,
    noOfRooms: null,
    noOfBathrooms: 0,
    floorLevel: null,
    furniture: languagesKeysObject,
    exAmenities: [],
    inAmenities: [],
    propertyStatus: languagesKeysObject,
    parkingSpace: 0,
    propertyAge: languagesKeysObject,
    propertyOrientation: languagesKeysObject,
    images: [],
    planImages: [],
    videoLink: '',
    view360: '',
    view360File: null,
    openHouseStartDate: '',
    openHouseEndDate: '',
    titleOfDeed: ''
  });

  const updateField = (key, value, isDropDown) => {
    setFormState((prev) => ({
      ...prev,
      [key]: {
        ...(isDropDown ? { ...value } : { ...prev[key], [language?.value]: value })
      }
    }));
  };


  const [otherLocation, setOtherLocation] = useState({
    province: '',
    town: '',
    neighbourhood: '',
    address: '',
    lat: 41.8777253,
    lng: 12.4707246
  });
  // Media Section States

  // Images

  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadedPlanImages, setUploadedPlanImages] = useState([]);
  const [createProperty, { isLoading }] = useCreatePropertyMutation();

  // For Delete Property Media
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [deletePropertyMedia, { isLoading: isDeleting }] = useDeletePropertyMediaMutation();

  const getSinglePropertyData = async () => {
    const res = await getSingleProperty({ slug });
    const responseData = res?.data?.data;
    if (res?.data) {
      setFormState((prev) => ({
        ...prev,
        title: responseData?.title,
        description: responseData?.description,
        propertyPurpose: responseData?.propertyPurpose,
        propertyType: responseData?.propertyType,
        price: responseData?.price,
        netArea: responseData?.netArea,
        noOfRooms: responseData?.noOfRooms,
        noOfBathrooms: responseData?.noOfBathrooms,
        floorLevel: responseData?.floorLevel,
        furniture: responseData?.furniture,
        exAmenities: responseData?.exteriorAmenities,
        inAmenities: responseData?.interiorAmenities,
        propertyStatus: responseData?.propertyStatus,
        parkingSpace: responseData?.parkingSpace,
        propertyAge: responseData?.propertyAge,
        propertyOrientation: responseData?.propertyOrientation,

        videoLink: responseData?.videoLink,
        view360: responseData?.threeSixtyViewLink?.includes('https://')
          ? responseData?.threeSixtyViewLink
          : '',
        view360File: !responseData?.threeSixtyViewLink?.includes('https://')
          ? responseData?.threeSixtyViewLink
          : null,
        openHouseStartDate: moment(responseData?.openHouseStartDate).format('YYYY-MM-DDTHH:mm'),
        openHouseEndDate: moment(responseData?.openHouseEndDate).format('YYYY-MM-DDTHH:mm'),
        planImages: [],
        images: [],
        titleOfDeed: responseData?.titleOfDeed
      }));
      setOtherLocation((prev) => ({
        ...prev,
        province: responseData?.province,
        town: responseData?.town,
        neighbourhood: responseData?.neighborhood,
        address: responseData?.address,
        lat: responseData?.location?.coordinates[0],
        lng: responseData?.location?.coordinates[1]
      }));
      setUploadedImages(responseData?.images);
      setUploadedPlanImages(responseData?.planImages);
    }
  };
  useEffect(() => {
    if (slug) {
      getSinglePropertyData();
    }
  }, [slug]);

  const validateParams = () => {
    const multiLanguageParams = {
      ...(slug ? {} : { title: formState?.title }),
      description: formState?.description
    };

    let params = {
      propertyPurpose: formState?.propertyPurpose,
      propertyType: formState?.propertyType?._id,
      price: formState?.price,
      netArea: formState?.netArea,
      noOfRooms: formState?.noOfRooms,
      noOfBathrooms: formState?.noOfBathrooms,
      floorLevel: formState?.floorLevel,
      furniture: formState?.furniture,
      exteriorAmenities: formState?.exAmenities?.map((e) => e?._id),
      interiorAmenities: formState?.inAmenities?.map((e) => e?._id),
      propertyStatus: formState?.propertyStatus,
      parkingSpace: formState?.parkingSpace,
      propertyAge: formState?.propertyAge,
      propertyOrientation: formState?.propertyOrientation,
      titleOfDeed: formState?.titleOfDeed,
      lng: otherLocation?.lng,
      lat: otherLocation?.lat,
      address: otherLocation?.address,
      province: otherLocation?.province?._id,
      town: otherLocation?.town?._id,
      neighborhood: otherLocation?.neighbourhood?._id,

      ...(!!formState?.view360File
        ? { threeSixtyViewLink: formState?.view360File }
        : { threeSixtyViewLink: formState?.view360 }),

      videoLink: formState?.videoLink,

      openHouseStartDate: formState?.openHouseStartDate,
      openHouseEndDate: formState?.openHouseEndDate
    };
    for (let lang in languagesKeysObject) {
      // validate multiLanguageParams
      for (let key in multiLanguageParams) {
        if (!multiLanguageParams[key][lang]) {
          toast.error(`Please fill the ${formatLabel(key)} field in language "${lang}".`);
          return [false, null];
        }
      }

      // validate params
      for (let key in params) {
        if (!params[key]) {
          toast.error(`Please fill the ${formatLabel(key)} field.`);
          return [false, null];
        }
        if (Array.isArray(params[key]) && params[key].length === 0) {
          toast.error(`Please add/select ${formatLabel(key)}.`);
          return [false, null];
        }
        // if key is a url
        if (['videoLink', !!formState?.view360File ? '' : 'threeSixtyViewLink'].includes(key)) {
          if (!validator.isURL(params[key])) {
            toast.error(`Please enter a valid ${formatLabel(key)}.`);
            return [false, null];
          }
        }
      }
    }
    params = {
      ...params,
      images: formState?.images,
      planImages: formState?.planImages
    };
    //  Plan Images and Images
    if (uploadedImages?.length + params?.images?.length < 3) {
      toast.error(`Please add at least 3 images.`);
      return [false, null];
    }
    if (uploadedPlanImages?.length + params?.planImages?.length < 3) {
      toast.error(`Please add at least 3 plan images.`);
      return [false, null];
    }
    return [true, { ...params, ...multiLanguageParams }];
  };

  const handleSubmit = async () => {
    const [isValid, params] = validateParams();

    if (!isValid) return;
    const formData = await CreateFormData(params);

    // api
    const response = await createProperty({ data: formData, ...(slug && { slug }) });
    if (response.data) {
      toast.success(`Property ${slug ? 'Updated' : 'Added'} Successfully`);
      navigate('/agent/properties-management');
    }
  };

  const deleteMedia = async (e) => {
    const res = await deletePropertyMedia(e);
    if (res?.data) {
      if (e?.type == 'images') {
        setUploadedImages((prev) => prev.filter((a) => a !== e?.key));
      } else {
        setUploadedPlanImages((prev) => prev.filter((a) => a !== e?.key));
      }
      toast.success('Media deleted successfully');
    }
  };

  return (
    <SideBarSkeleton>
      {isGetting ? (
        <Loader className={classes?.loader} />
      ) : (
        <Container fluid className={[classes.page].join(' ')}>
          <Row>
            <Col md={12} className={classes.mb30}>
              <Button
                leftIcon={<IoChevronBackOutline />}
                variant=""
                className={[classes.backBtn, 'ltr'].join(' ')}
                onClick={() => {
                  navigate('/agent/properties-management');
                }}>
                {t('form.goBack')}
              </Button>
            </Col>
            <Col md={12}>
              <div
                className={[
                  classes.formBg,
                  ['ar', 'fa']?.includes(language?.value) ? 'rtl' : 'ltr'
                ].join(' ')}>
                <Container>
                  <Row className={classes.formRow}>
                    <Col md={12}>
                      <h3>
                        {slug
                          ? t('form.titleEdit', { lng: language?.value })
                          : t('form.titleCreate')}
                      </h3>
                    </Col>
                    <Col md={12}>
                      <div className={classes.headAndDropDown}>
                        <h4>{t('form.tagline')}</h4>
                        <FormLanguages value={language} setter={setLanguage} />
                      </div>
                    </Col>
                    <Col md={12}>
                      <Input
                        label={t('form.propertyTitleLabel')}
                        placeholder={t('form.propertyTitlePlaceholder')}
                        variant="dashboard"
                        value={formState.title[language?.value]}
                        setValue={(e) => updateField('title', e)}
                        disabled={slug}
                      />
                    </Col>

                    <Col md={12}>
                      <TextArea
                        label={t('form.propertyDescriptionLabel')}
                        placeholder={t('form.propertyDescriptionPlaceholder')}
                        variant="dashboard"
                        value={formState.description[language?.value]}
                        setValue={(e) => updateField('description', e)}
                      />
                    </Col>
                    <Col md={6}>
                      <DropDown
                        label={t('form.propertyPurposeLabel')}
                        placeholder={t('form.propertyPurposePlaceholder')}
                        indicatorColor="var(--dashboard-text-gray-clr)"
                        variant="dashboard"
                        value={formState.propertyPurpose}
                        setValue={(e) => updateField('propertyPurpose', e, true)}
                        options={propertyPurposeOptions}
                        getOptionValue={(e) => {
                          return e?.[language?.value];
                        }}
                        getOptionLabel={(e) => {
                          return e?.[language?.value];
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <DropDown
                        label={t('form.propertyTypeLabel')}
                        placeholder={t('form.propertyTypePlaceholder')}
                        indicatorColor="var(--dashboard-text-gray-clr)"
                        variant="dashboard"
                        value={formState.propertyType}
                        setValue={(e) => updateField('propertyType', e, true)}
                        optionValue={'_id'}
                        getOptionLabel={(e) => {
                          return e?.title?.[language?.value];
                        }}
                        options={propertyTypesOptions}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        label={t(
                          formState.propertyPurpose?.en == 'Rent'
                            ? 'form.propertyRentPriceLabel'
                            : 'form.propertyPriceLabel'
                        )}
                        placeholder={t(
                          formState.propertyPurpose?.en == 'Rent'
                            ? 'form.propertyRentPricePlaceholder'
                            : 'form.propertyPricePlaceholder'
                        )}
                        variant="dashboard"
                        value={formState.price}
                        setValue={(e) => setFormState((prev) => ({ ...prev, price: e }))}
                      />
                    </Col>
                    {/* <Col md={6}>
                    <DropDown
                      label={'Rent Duration Period*'}
                      placeholder={'Select Rent Duration Period'}
                      variant="dashboard"
                      options={rentDurationPeriodOptions}
                      indicatorColor="var(--dashboard-text-gray-clr)"
                      value={rentDurationPeriod}
                      setValue={setRentDurationPeriod}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      label={'Rent Duration*'}
                      placeholder={'Enter Duration'}
                      variant="dashboard"
                      value={rentDuration}
                      setValue={setRentDuration}
                    />
                  </Col> */}
                    <Col md={6}>
                      <Input
                        type={'number'}
                        label={t('form.propertyAreaLabel')}
                        placeholder={t('form.propertyAreaPlaceholder')}
                        variant="dashboard"
                        value={formState.netArea}
                        setValue={(e) => setFormState((prev) => ({ ...prev, netArea: e }))}
                      />
                    </Col>

                    <Col md={6}>
                      <DropDown
                        label={t('form.propertyRoomsLabel')}
                        placeholder={t('form.propertyRoomsPlaceholder')}
                        variant="dashboard"
                        options={roomsOptions}
                        optionLabel={[language?.value]}
                        optionValue={[language?.value]}
                        value={formState.noOfRooms}
                        setValue={(e) => setFormState((prev) => ({ ...prev, noOfRooms: e }))}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        type={'number'}
                        label={t('form.propertyBathroomsLabel')}
                        placeholder={t('form.propertyBathroomsPlaceholder')}
                        variant="dashboard"
                        value={formState.noOfBathrooms}
                        setValue={(e) => setFormState((prev) => ({ ...prev, noOfBathrooms: e }))}
                      />
                    </Col>
                    <Col md={6}>
                      <DropDown
                        label={t('form.propertyFloorLevelLabel')}
                        placeholder={t('form.propertyFloorLevelPlaceholder')}
                        options={floorLevelOptions}
                        variant="dashboard"
                        value={formState.floorLevel}
                        optionLabel={[language?.value]}
                        optionValue={[language?.value]}
                        setValue={(e) => setFormState((prev) => ({ ...prev, floorLevel: e }))}
                      />
                    </Col>

                    <Col md={6}>
                      <DropDown
                        label={t('form.propertyFurnitureLabel')}
                        placeholder={t('form.propertyFurniturePlaceholder')}
                        variant="dashboard"
                        options={furnitureOptions}
                        indicatorColor="var(--dashboard-text-gray-clr)"
                        value={formState.furniture}
                        setValue={(e) => updateField('furniture', e, true)}
                        getOptionValue={(e) => {
                          return e?.[language?.value];
                        }}
                        getOptionLabel={(e) => {
                          return e?.[language?.value];
                        }}
                      />
                    </Col>

                    <Col md={6}>
                      <DropDown
                        indicatorColor="var(--dashboard-text-gray-clr)"
                        label={t('form.propertyExteriorAmenities')}
                        placeholder={t('form.propertyExteriorAmenitiesPlaceholder')}
                        variant="dashboard"
                        value={formState.exAmenities}
                        isMulti={true}
                        getOptionLabel={(e) => e?.title?.[language?.value]}
                        optionValue={'_id'}
                        setValue={(e) => setFormState((prev) => ({ ...prev, exAmenities: e }))}
                        options={exteriorAmenitiesOptions}
                      />
                    </Col>
                    <Col md={6}>
                      <DropDown
                        label={t('form.propertyInteriorAmenities')}
                        placeholder={t('form.propertyInteriorAmenitiesPlaceholder')}
                        indicatorColor="var(--dashboard-text-gray-clr)"
                        variant="dashboard"
                        value={formState.inAmenities}
                        isMulti={true}
                        optionValue={'_id'}
                        getOptionLabel={(e) => e?.title?.[language?.value]}
                        setValue={(e) => setFormState((prev) => ({ ...prev, inAmenities: e }))}
                        options={interiorAmenitiesOptions}
                      />
                    </Col>

                    <Col md={6}>
                      <DropDown
                        label={t('form.propertyStatusLabel')}
                        placeholder={t('form.propertyStatusLabelPlaceholder')}
                        options={propertyStatusOptions}
                        variant="dashboard"
                        indicatorColor="var(--dashboard-text-gray-clr)"
                        value={formState.propertyStatus}
                        setValue={(e) => updateField('propertyStatus', e, true)}
                        getOptionValue={(e) => e?.[language?.value]}
                        getOptionLabel={(e) => e?.[language?.value]}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        label={t('form.propertyParkingSpaceLabel')}
                        placeholder={t('form.propertyParkingSpacePlaceholder')}
                        variant="dashboard"
                        value={formState.parkingSpace}
                        setValue={(e) => setFormState((prev) => ({ ...prev, parkingSpace: e }))}
                      />
                    </Col>
                    <Col md={6}>
                      <DropDown
                        label={t('form.propertyAgeLabel')}
                        placeholder={t('form.propertyAgePlaceholder')}
                        variant="dashboard"
                        indicatorColor="var(--dashboard-text-gray-clr)"
                        value={formState.propertyAge}
                        setValue={(e) => updateField('propertyAge', e, true)}
                        options={propertyAgeOptions}
                        getOptionValue={(e) => e?.[language?.value]}
                        getOptionLabel={(e) => e?.[language?.value]}
                      />
                    </Col>

                    <Col md={6}>
                      <DropDown
                        label={t('form.propertyOrientationLabel')}
                        placeholder={t('form.propertyOrientationPlaceholder')}
                        indicatorColor="var(--dashboard-text-gray-clr)"
                        variant="dashboard"
                        value={formState.propertyOrientation}
                        setValue={(e) => updateField('propertyOrientation', e, true)}
                        options={propertyOrientationOptions}
                        getOptionValue={(e) => e?.[language?.value]}
                        getOptionLabel={(e) => {
                          return e?.[language?.value];
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <DropDown
                        label={t('form.propertyTitleDeedLabel')}
                        placeholder={t('form.propertyTitleDeedPlaceholder')}
                        indicatorColor="var(--dashboard-text-gray-clr)"
                        variant="dashboard"
                        value={formState.titleOfDeed}
                        setValue={(e) => updateField('titleOfDeed', e, true)}
                        options={titleDeedOptions}
                        getOptionValue={(e) => e?.[language?.value]}
                        getOptionLabel={(e) => {
                          return e?.[language?.value];
                        }}
                      />
                    </Col>

                    {/* Location */}

                    <LocationComponent
                      t={languageD}
                      setLocationState={setOtherLocation}
                      locationState={otherLocation}
                      selectedLanguage={language}
                    />
                    {/* End of Location */}
                    {/* Media */}
                    <Col md={12}>
                      <h4>{t('form.propertyMedia')}</h4>
                    </Col>
                    <Col md={12}>
                      <label className={classes.label}>{t('form.propertyImagesLabel')}</label>
                      <Row className="gy-3">
                        {uploadedImages
                          // ?.filter((e) => typeof e == 'string')
                          ?.map((item, index) => (
                            <Col md={3} key={index}>
                              <UploadImageBox
                                value={item}
                                edit={false}
                                onDelete={() => {
                                  deleteMedia({ slug, type: 'images', key: item });
                                }}
                              />
                            </Col>
                          ))}
                      </Row>
                    </Col>
                    <Col md={12}>
                      <FileUpload
                        // label={'Images'}
                        files={
                          formState.images
                          // ?.filter((e) => typeof e !== 'string')
                        }
                        setFiles={(e) => setFormState((prev) => ({ ...prev, images: e }))}
                        acceptTypes={['png', 'jpg', 'jpeg']}
                        text={t('form.dragAndDrop')}
                        orText={t('form.orText')}
                        buttonLabel={t('form.browseFiles')}
                      />
                    </Col>
                    <Col md={12}>
                      <label className={classes.label}>{t('form.propertyPlansLabel')}</label>

                      <Row className="gy-3">
                        {uploadedPlanImages
                          // ?.filter((e) => typeof e == 'string')
                          ?.map((item, index) => (
                            <Col md={3} key={index}>
                              <UploadImageBox
                                value={item}
                                edit={false}
                                onDelete={() => {
                                  deleteMedia({ slug, type: 'planImages', key: item });
                                }}
                              />
                            </Col>
                          ))}
                      </Row>
                    </Col>
                    <Col md={12}>
                      <FileUpload
                        files={formState.planImages}
                        setFiles={(e) => setFormState((prev) => ({ ...prev, planImages: e }))}
                        acceptTypes={['png', 'jpg', 'jpeg']}
                        text={t('form.dragAndDrop')}
                        orText={t('form.orText')}
                        buttonLabel={t('form.browseFiles')}
                      />
                    </Col>
                    <Col md={12}>
                      <Input
                        label={t('form.propertyVideoLabel')}
                        placeholder={t('form.propertyVideoPlaceholder')}
                        variant="dashboard"
                        value={formState.videoLink}
                        setValue={(e) => setFormState((prev) => ({ ...prev, videoLink: e }))}
                      />
                    </Col>
                    <Col md={12}>
                      <label className={classes.label}>{t('form.property360Link')}</label>
                      <div className={classes.linkAndUpload}>
                        <Input
                          placeholder={t('form.property360LinkPlaceholder')}
                          variant="dashboard"
                          value={formState.view360}
                          setValue={(e) => setFormState((prev) => ({ ...prev, view360: e }))}
                          className={classes.linkInput}
                          disabled={!!formState.view360File}
                        />
                        <span>--- {t('form.orText')} ---</span>
                        <UploadImageBox
                          setValue={(e) => {
                            if (!['image/png', 'image/jpg', 'image/jpeg'].includes(e?.type)) {
                              return toast.error('Please upload only Png, Jpg or Jpeg file');
                            }
                            setFormState((prev) => ({ ...prev, view360File: e }));
                          }}
                          value={formState.view360File}
                          label={t('form.property360Upload')}
                          // btnLabel={t('form.property360UploadBtn')}
                          disabled={!!formState.view360}
                          onDelete={() => {
                            setFormState((prev) => ({ ...prev, view360File: null }));
                          }}
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      <Input
                        label={t('form.propertyOpenHouseLabel')}
                        type={'datetime-local'}
                        variant="dashboard"
                        value={formState.openHouseStartDate}
                        min={moment().format('YYYY-MM-DDTHH:MM')}
                        setValue={(e) => {
                          if (moment(e).isBefore(moment().format())) {
                            return toast.error("You can't select a date before today's date");
                          } else {
                            setFormState((prev) => ({ ...prev, openHouseStartDate: e }));
                          }
                        }}
                      />
                    </Col>
                    <Col md={6} className="aEnd">
                      <Input
                        label={t('form.propertyOpenHouseEndingLabel')}
                        type={'datetime-local'}
                        variant="dashboard"
                        value={formState.openHouseEndDate}
                        min={moment().format('YYYY-MM-DDTHH:MM')}
                        setValue={(e) => {
                          if (moment(e).isBefore(formState.openHouseStartDate)) {
                            return toast.error("You can't select the date before starting date");
                          } else {
                            setFormState((prev) => ({ ...prev, openHouseEndDate: e }));
                          }
                        }}
                      />
                    </Col>

                    <Col md={12} className={classes.btnDiv}>
                      <Button className={classes.btn} disabled={isLoading} onClick={handleSubmit}>
                        {t(
                          `form.${
                            isLoading
                              ? slug
                                ? 'updateBtnLoading'
                                : 'saveBtnLoading'
                              : slug
                              ? 'updateBtn'
                              : 'saveBtn'
                          }`
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </SideBarSkeleton>
  );
}

export default NewProperty;
