/*eslint-disable*/
import React, { useState } from 'react';
import classes from './ProjectsManagement.module.css';
import SideBarSkeleton from '../../../Component/SideBarSkeleton';
import { Col, Container, Row } from 'react-bootstrap';
import CustomMaterialTable from '../../../Component/CustomMaterialTable';
import { AiFillEye } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';
import { MdOutlineUpload } from 'react-icons/md';
import Button from '../../../Component/Button/Button';
import SearchInput from '../../../Component/SearchInput';
import { useNavigate } from 'react-router-dom';
import { FaToggleOn } from 'react-icons/fa';
import { dateFilterOptions } from '../../../constant/commonData';
import DropDown from '../../../Component/DropDown/DropDown';
import {
  useActiveDeactiveProjectMutation,
  useAddOrEditUnitMutation,
  useAssignAgentOnPropertyMutation,
  useDeleteProjectMutation,
  useGetCompanyProjectsQuery,
  useRefreshProjectMutation,
  useUpgradeToPremiumOrFeaturedMutation
} from '../../../store/apiSlice/apiSlice';
import moment from 'moment';
import { toast } from 'react-toastify';
import AreYouSureModal from '../../../modals/AreYouSureModal';
import AssignAgentModal from '../../../modals/AssignAgentModal';
import UpgradingListingModal from '../../../modals/UpgradingListingModal/UpgradingListingModal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ImUserCheck } from 'react-icons/im';
import { HiRefresh } from 'react-icons/hi';
import { recordsLimit } from '../../../config/apiUrl';
import useDebounce from '../../../CustomHooks/useDebounce';
import UnitAddModal from '../../../Component/UnitAddModal';

const tableData = Array(10)
  .fill({
    id: '5645',
    date: '2020.11.12',
    category: 'For Sale',
    type: 'broker',
    title: 'Text text',
    location: 'London',
    status: 'Active',
    agent: 'John Smith'
  })
  ?.map((item) => ({ ...item, id: Math.floor(Math.random() * 10000) }));

function ProjectsManagement() {
  const { t } = useTranslation(['dashboardProjects', 'locationComp']);
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [dateFilter, setDateFilter] = useState(dateFilterOptions[0]);

  const [isFeaturedModalOpen, setIsFeaturedModalOpen] = useState(null);
  const [isUnitAddModal, setIsUnitAddModal] = useState(null);
  const [page, setPage] = useState(1);

  const [refreshApi, { isLoading: isRefreshing }] = useRefreshProjectMutation();
  const [upgradeApi, { isLoading: isUpgradeLoading }] = useUpgradeToPremiumOrFeaturedMutation();
  const [assignApi, { isLoading: isAssignLoading }] = useAssignAgentOnPropertyMutation();
  const [addUnitApi, { isLoading: isAddUnitLoading }] = useAddOrEditUnitMutation();

  console.log(isUnitAddModal, 'isUnitAddModalisUnitAddModal isUnitAddModal');

  // active deactive api
  const [activeDeactiveApi, { isLoading: isActiveDeactive }] = useActiveDeactiveProjectMutation();

  // Delete

  const [deleteRecordsApi, { isLoading: isDeleting }] = useDeleteProjectMutation();
  //  get company projects
  const debounceSearch = useDebounce(searchText, 500);
  const { data: projectsGetApi, isLoading } = useGetCompanyProjectsQuery({
    page: page,
    search: debounceSearch,
    sortBy: dateFilter?.value,
    lan:selectedLanguage
  });

  // handle active deactive

  const handleActiveDeactive = async (slug, data) => {
    const response = await activeDeactiveApi({ slug, data });
    if (response?.data) {
      toast.success('Project status updated successfully.');
      setSelectedItem(null);
    }
  };

  // Refresh Api
  const handleRefresh = async (slug, url) => {
    const response = await refreshApi({ slug, url });
    if (response?.data) {
      toast.success('Project refreshed successfully.');
      setSelectedItem(null);
    }
  };

  //  delete api
  const deleteMultiple = async () => {
    const res = await deleteRecordsApi({ ids: selectedRows?.map((item) => item._id) });
    if (res?.data) {
      toast.success(`${selectedRows?.length > 1 ? 'Events' : 'Event'} deleted successfully.`);
      setSelectedRows([]);
    }
  };

  const headings = [
    { id: 'listingId', label: t('table.col1') },
    {
      id: 'date',
      label: t('table.col2'),
      renderValue: (item, col) => {
        return moment(item[col?.id]).format('YYYY.DD.MM ');
      }
    },
    {
      id: 'type',
      label: t('table.col3'),
      renderValue: (item) => {
        return item?.projectType?.title[selectedLanguage];
      }
    },
    {
      id: 'title',
      label: t('table.col4'),
      renderValue: (item) => {
        return item?.title[selectedLanguage];
      }
    },
    { id: 'address', label: 'LOCATION' },
    {
      id: 'isActive',
      label: t('table.col5'),
      renderValue: (item) => {
        return item?.isActive ? 'Active' : 'Deactive';
      }
    },
    {
      id: 'agent',
      label: t('table.col7'),
      renderValue: (item, col) =>
        item?.assignedAgent
          ? `${item?.assignedAgent?.agentName?.[selectedLanguage]}`
          : 'No Agent Assigned'
    },
    { id: 'options', label: t('table.col8'), align: 'center' }
  ];

  return (
    <SideBarSkeleton>
      <Container fluid className={classes.page}>
        <Row>
          <Col md={12} className={classes.mb58}>
            <div className={classes.header}>
              <div className={classes.left}>
                <SearchInput
                  placeholder={t('table.searchInput')}
                  setter={setSearchText}
                  state={searchText}
                  variant="dashboard"
                  containerStyle={{ width: '300px' }}
                />
                <DropDown
                  label={t('table.sortByDate')}
                  containerClassName={classes.dropDownContainer}
                  customStyle={{ padding: '0px 6px', minHeight: '40px', minWidth: '200px' }}
                  options={dateFilterOptions}
                  setValue={(e) => {
                    setDateFilter(e);
                  }}
                  value={dateFilter}
                  getOptionLabel={(e) => {
                    return e?.[selectedLanguage];
                  }}
                  // isClearable={true}
                />
              </div>
              <div className={classes.headerRight}>
                <Button
                  borderRadius={'8px'}
                  className={classes.headerBtn}
                  onClick={() => navigate('/company/projects-management/new-project')}>
                  {t('table.createNewProject')}
                </Button>
                <Button
                  borderRadius={'8px'}
                  className={classes.deleteBtn}
                  disabled={selectedRows?.length == 0}
                  onClick={deleteMultiple}>
                  {isDeleting ? t('table.deleting') : t('table.delete')}
                </Button>
                {/* <Button borderRadius={'8px'}>Create New Property</Button> */}
              </div>
            </div>
          </Col>
          <Col md={12}>
            <h5 className={classes.heading}>{t('table.title')}</h5>
          </Col>
          <Col md={12}>
            <CustomMaterialTable
              noDataText={t('table.noDataText')}
              headings={headings}
              data={projectsGetApi?.data}
              showOptionsColumn
              isLoading={isLoading}
              setPage={setPage}
              page={page}
              totalRecords={Math.ceil(projectsGetApi?.totalRecords / recordsLimit)}
              options={[
                {
                  label: t('table.options.view'),
                  icon: <AiFillEye size={18} />,
                  onClick: (label, item) => {
                    navigate(`/projects/${item?.slug}`);
                  }
                },
                {
                  label: t('table.options.refresh'),
                  icon: <HiRefresh size={18} />,
                  onClick: (label, item) => {
                    setSelectedItem({ ...item, flag: 'refresh' });
                  }
                },
                {
                  label: t('table.options.edit'),
                  icon: <FiEdit size={18} />,
                  onClick: (e, rowData) => {
                    navigate(`/company/projects-management/edit-project/${rowData.slug}`);
                  }
                },
                {
                  label: (e) =>
                  !e?.isActive ? t('table.options.activate') : t('table.options.deactivate'),
                  icon: <FaToggleOn size={18} />,
                  onClick: (label, item) => {
                    setSelectedItem({ ...item, flag: 'active-deactive' });
                  },
                  switch: true
                },
                {
                  label: (e) =>
                    e?.assignedAgent
                      ? t('table.options.agentAssigned')
                      : t('table.options.assignToAgent'),
                  className: (e) => (e?.assignedAgent ? 'disabledMenuItem' : ''),
                  onClick: (label, item) => {
                    if (!!item?.assignedAgent) return;
                    setSelectedItem({ ...item, flag: 'assignAgent' });
                  },

                  icon: <ImUserCheck size={18} />
                },

                {
                  label: t('table.options.upgradeToPremiumORFeatured'),
                  onClick: (label, item) => {
                    console.log('upgrade package', label, item);
                    setIsFeaturedModalOpen({ ...item, boolean: true });
                  },

                  icon: <MdOutlineUpload size={18} />
                },
                {
                  label: t('table.options.addUnitLabel'),
                  onClick: (label, item) => {
                    setIsUnitAddModal({ ...item, boolean: true });
                    // setIsFeaturedModalOpen({ ...item, boolean: true });
                  },

                  icon: <MdOutlineUpload size={18} />
                },
                {
                  label: 'View Units',
                  onClick: (label, item) => {
                    console.log(item, 'item itemitem');
                    navigate(`/company/projects-units/${item?.slug} `);
                  },

                  icon: <MdOutlineUpload size={18} />
                }
              ]}
              onOptionClick={() => console.log()}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              allowMultipleSelection
            />
          </Col>
        </Row>
      </Container>
      {isUnitAddModal && (
        <UnitAddModal
          onClick={async (e) => {
            const response = await addUnitApi({
              slug: isUnitAddModal?.slug,
              data: e,
              url: `project/addProjectUnit/${isUnitAddModal?.slug}`
            });
            if (response?.data) {
              toast.success(`Unit added successfully`);
              setIsUnitAddModal(false);
            }
          }}
          show={isUnitAddModal?.boolean}
          setShow={setIsUnitAddModal}
          isApiCall={isAddUnitLoading}
        />
      )}
      {selectedItem?.flag == 'active-deactive' && (
        <AreYouSureModal
          label1Icon={false}
          label2Icon={false}
          title={t('activeDeactiveModal.title')}
          label1={t('activeDeactiveModal.yesBtn')}
          label2={t('activeDeactiveModal.noBtn')}
          show={selectedItem?.flag == 'active-deactive'}
          setShow={setSelectedItem}
          subTitle={`${
            selectedItem?.isActive
              ? t('activeDeactiveModal.deactiveText')
              : t('activeDeactiveModal.activeText')
          } `}
          isApiCall={isActiveDeactive}
          onClick={() =>
            handleActiveDeactive(selectedItem?.slug, selectedItem?.isActive ? false : true)
          }
        />
      )}
      {selectedItem?.flag == 'assignAgent' && (
        <AssignAgentModal
          show={selectedItem?.flag == 'assignAgent'}
          setShow={setSelectedItem}
          isLoading={isAssignLoading}
          t={t}
          onClick={async (e) => {
            const res = await assignApi({
              slug: selectedItem?.slug,
              agentSlug: e,
              type: 'project'
            });
            if (res?.data) {
              toast.success(`Agent assigned successfully`);
              setSelectedItem(null);
            }
          }}
        />
      )}
      {selectedItem?.flag == 'refresh' && (
        <AreYouSureModal
          label1Icon={false}
          label2Icon={false}
          label1={t('activeDeactiveModal.yesBtn')}
          label2={t('activeDeactiveModal.noBtn')}
          show={selectedItem?.flag == 'refresh'}
          setShow={setSelectedItem}
          subTitle={t('refreshModal.text')}
          title={t('refreshModal.title')}
          isApiCall={isRefreshing}
          onClick={() => {
            handleRefresh(selectedItem?.slug);
          }}
        />
      )}

      {isFeaturedModalOpen?.boolean && (
        <UpgradingListingModal
          show={isFeaturedModalOpen?.boolean}
          setShow={setIsFeaturedModalOpen}
          isSubmitting={isUpgradeLoading}
          onSubmit={async (params) => {
            console.log(params, 'qwe ewq');
            const res = await upgradeApi({
              slug: isFeaturedModalOpen?.slug,
              data: params,
              type: 'project'
            });
            if (res?.data) {
              setIsFeaturedModalOpen(false);
              return toast.success(`Project upgraded successfully`);
            }
          }}
        />
      )}
    </SideBarSkeleton>
  );
}

export default ProjectsManagement;
