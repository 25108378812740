/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SideBarSkeleton from '../../../Component/SideBarSkeleton';
import classes from './NewProject.module.css';
import Button from '../../../Component/Button/Button';
import { IoChevronBackOutline } from 'react-icons/io5';
import Input from '../../../Component/Input/Input';
import DropDown from '../../../Component/DropDown/DropDown';
import TextArea from '../../../Component/TextArea';
import { languagesKeysObject, languagesOptions } from '../../../constant/commonData';
import FileUpload from '../../../Component/FileUpload';
import CustomFileInput from '../../../Component/CustomFileInput';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LocationComponent from '../../../Component/LocationComponent';
import {
  useAddOrEditProjectMutation,
  useDeletePropertyMediaMutation,
  useGetSingleProjectQuery
} from '../../../store/apiSlice/apiSlice';
import { toast } from 'react-toastify';
import UploadImageBox from '../../../Component/UploadImageBox';
import moment from 'moment';
import FormLanguages from '../../../Component/FormLanguages';
import { CreateFormData, formatLabel } from '../../../config/apiUrl';
import validator from 'validator';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../Component/Loader';

function NewProject() {
  const navigate = useNavigate();
  const slug = useParams()?.slug;
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const [language, setLanguage] = useState(
    languagesOptions?.find((item) => item?.value === selectedLanguage)
  );

  const { t } = useTranslation(['dashboardProjects', 'locationComp'], {
    lng: language?.value
  });
  const { t: languageD } = useTranslation('locationComp', { lng: language?.value });

  // Language
  const [formStates, setFormStates] = useState({
    name: languagesKeysObject,
    title: languagesKeysObject,
    description: languagesKeysObject,
    developerName: languagesKeysObject,
    // registrationNumber: null,
    projectType: '',
    // minimumSellingPrice: '',
    // maximumSellingPrice: '',
    price: '',
    noOfUnits: '',
    minimumArea: '',
    maximumArea: '',
    deliveryDate: '',
    exteriorAmenities: [],
    interiorAmenities: []
  });

  const [locationState, setLocationState] = useState({
    province: null,
    town: null,
    neighbourhood: null,
    address: 'test',
    lat: '25.2048',
    lng: '55.2708'
  });

  //  media
  const [projectLogo, setProjectLogo] = useState(null);
  const [images, setImages] = useState([]);
  const [planImages, setPlanImages] = useState([]);
  const [videoLink, setVideoLink] = useState('');
  const [View360, setView360] = useState('');
  const [view360DragDrop, setView360DragDrop] = useState(null);
  const [catalogue, setCatalogue] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadedPlanImages, setUploadedPlanImages] = useState([]);

  const { projectTypes, interiorAmenities, exteriorAmenities } = useSelector(
    (state) => state?.commonReducer
  );
  const [creatOrEditProjectFn, { isLoading: apiSubmitingLoading }] = useAddOrEditProjectMutation();
  const [deletePropertyMedia, { isLoading: isDeleting }] = useDeletePropertyMediaMutation();

  const { data: projectData, isLoading: isGetting } = useGetSingleProjectQuery(
    {
      slug: slug
    },
    {
      skip: !slug
    }
  );

  const deleteMedia = async (e) => {
    const res = await deletePropertyMedia(e);
    if (res?.data) {
      if (e?.type == 'images') {
        setUploadedImages((prev) => prev.filter((a) => a !== e?.key));
      } else {
        setUploadedPlanImages((prev) => prev.filter((a) => a !== e?.key));
      }
      toast.success('Media deleted successfully');
    }
  };

  // validateParams
  const validateParams = () => {
    const multiLanguageParams = {
      name: formStates?.name,
      title: formStates?.title,
      description: formStates?.description,
      developerName: formStates?.developerName
    };
    let params = {
      // registrationNumber: formStates.registrationNumber,
      projectType: formStates?.projectType?._id,
      // minimumSellingPrice: formStates?.minimumSellingPrice,
      // maximumSellingPrice: formStates?.maximumSellingPrice,
      price: formStates?.price,
      noOfUnits: formStates?.noOfUnits,
      minimumArea: formStates?.minimumArea,
      maximumArea: formStates?.maximumArea,
      deliveryDate: formStates?.deliveryDate,
      exteriorAmenities: formStates?.exteriorAmenities?.map((item) => item?._id),
      interiorAmenities: formStates?.interiorAmenities?.map((item) => item?._id),

      logo: projectLogo,
      lng: locationState?.lng,
      lat: locationState?.lat,
      address: locationState?.address,
      province: locationState?.province?._id,
      town: locationState?.town?._id,
      neighborhood: locationState?.neighbourhood?._id,
      videoLink,
      threeSixtyViewLink: View360 ?? view360DragDrop,
      // threeSixtyViewImage: view360DragDrop,
      // threeSixtyViewLink: View360,
      pdf: catalogue
    };

    // validation on plan and images length must be 3 or greater than 3
    if (uploadedImages?.length + params?.images?.length < 3) {
      toast.error('Please upload at least 3 project images.');
      return [false, null];
    }
    if (uploadedPlanImages?.length + params?.planImages?.length < 3) {
      toast.error('Please upload at least 3 plan images.');
      return [false, null];
    }

    for (let lang in languagesKeysObject) {
      // validate multiLanguageParams
      for (let key in multiLanguageParams) {
        if (!multiLanguageParams[key][lang]) {
          toast.error(`Please fill the ${formatLabel(key)} field in language "${lang}".`);
          return [false, null];
        }
      }

      // validate params
      for (let key in params) {
        if (!params[key]) {
          toast.error(`Please fill the ${formatLabel(key)} field.`);
          return [false, null];
        }
        if (Array.isArray(params[key]) && params[key].length === 0) {
          toast.error(`Please add/select ${formatLabel(key)}.`);
          return [false, null];
        }
        // if key is a url
        if (key.includes('Link') || key.includes('link')) {
          if (typeof params[key] == 'object') {
            return [true, { ...params, ...multiLanguageParams }];
          }
          // if key is a url
          if (['videoLink', !!view360DragDrop ? '' : 'threeSixtyViewLink'].includes(key)) {
            if (!validator.isURL(params[key])) {
              toast.error(`Please enter a valid ${formatLabel(key)}.`);
              return [false, null];
            }
          }
        }
      }
    }
    params = {
      ...params,
      images,
      planImages
    };
    if (Number(formStates?.maximumArea) < Number(formStates?.minimumArea)) {
      toast.error('Maximum Area must be greater than Minimum Area');
      return [false, null];
    }

    return [true, { ...params, ...multiLanguageParams }];
  };

  // handleSubmit
  const handleSubmit = async () => {
    const [isValid, params] = validateParams();

    if (!isValid) return;
    const formData = await CreateFormData(params);

    // api
    const response = await creatOrEditProjectFn({ data: formData, ...(slug && { slug }) });
    if (response.data) {
      toast.success(`Project ${slug ? 'Updated' : 'Added'} Successfully`);
      navigate(-1);
    }
  };

  useEffect(() => {
    if (projectData?.data) {
      console.log(projectData, 'projectData projectData projectData');
      setFormStates({
        name: projectData?.data?.name,
        title: projectData?.data?.title,
        description: projectData?.data?.description,
        developerName: projectData?.data?.developerName,
        projectType: projectData?.data?.projectType,
        price: projectData?.data?.price,
        noOfUnits: projectData?.data?.noOfUnits,
        minimumArea: projectData?.data?.minimumArea,
        maximumArea: projectData?.data?.maximumArea,
        deliveryDate: projectData?.data?.deliveryDate,
        exteriorAmenities: projectData?.data?.exteriorAmenities,
        interiorAmenities: projectData?.data?.interiorAmenities,
        // registrationNumber: projectData?.data?.registrationNumber

      });
      setLocationState({
        province: projectData?.data?.province,
        town: projectData?.data?.town,
        neighbourhood: projectData?.data?.neighborhood,
        address: projectData?.data?.address,
        lat:
          projectData?.data?.location?.coordinates?.length > 0
            ? projectData?.data?.location?.coordinates[1]
            : '',
        lng:
          projectData?.data?.location?.coordinates?.length > 0
            ? projectData?.data?.location?.coordinates[0]
            : ''
      });
      setProjectLogo(projectData?.data?.logo);
      // setImages(projectData?.data?.images);
      // setPlanImages(projectData?.data?.planImages);
      setVideoLink(projectData?.data?.videoLink);
      setView360(projectData?.data?.threeSixtyViewLink);
      setCatalogue(projectData?.data?.projectBrochure);
      // only Show States

      setUploadedImages(projectData?.data?.images);
      setUploadedPlanImages(projectData?.data?.planImages);
    }
   
  }, [isGetting]);

  console.log(uploadedImages, images, 'test');
  return (
    <SideBarSkeleton>
      {isGetting ? (
        <Loader className={classes?.loader} />
      ) : (
        <Container fluid className={classes.page}>
          <Row>
            <Col md={12} className={classes.mb30}>
              <Button
                leftIcon={<IoChevronBackOutline />}
                variant=""
                className={classes.backBtn}
                onClick={() => {
                  navigate('/agent/projects-management');
                }}>
                {t('form.goBack')}
              </Button>
            </Col>
            <Col md={12}>
              <div
                className={[
                  classes.formBg,
                  ['ar', 'fa']?.includes(language?.value) ? 'rtl' : 'ltr'
                ].join(' ')}>
                <Container>
                  <Row className={classes.formRow}>
                    <Col md={12}>
                      <h3>{slug ? t('form.titleEdit') : t('form.titleCreate')}</h3>
                    </Col>
                    <Col md={12}>
                      <div className={classes.headAndDropDown}>
                        <h4>{t('form.tagline')}</h4>
                        <FormLanguages value={language} setter={setLanguage} />
                      </div>
                    </Col>
                    <Col md={12}>
                      <Input
                        label={t('form.projectTitleLabel')}
                        placeholder={t('form.projectTitlePlaceholder')}
                        variant="dashboard"
                        value={formStates?.name?.[language?.value]}
                        setValue={(e) =>
                          setFormStates({
                            ...formStates,
                            name: {
                              ...formStates?.name,
                              [language?.value]: e
                            }
                          })
                        }
                      />
                    </Col>
                    <Col md={12}>
                      <Input
                        label={t('form.projectTaglineLabel')}
                        placeholder={t('form.projectTaglinePlaceholder')}
                        variant="dashboard"
                        value={formStates?.title?.[language?.value]}
                        setValue={(e) =>
                          setFormStates({
                            ...formStates,
                            title: {
                              ...formStates?.title,
                              [language?.value]: e
                            }
                          })
                        }
                      />
                    </Col>
                    <Col md={12}>
                      <TextArea
                        label={t('form.projectDescriptionLabel')}
                        placeholder={t('form.projectDescriptionPlaceholder')}
                        variant="dashboard"
                        value={formStates?.description?.[language?.value]}
                        setValue={(e) =>
                          setFormStates({
                            ...formStates,
                            description: {
                              ...formStates?.description,
                              [language?.value]: e
                            }
                          })
                        }
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        label={t('form.developerNameLabel')}
                        placeholder={t('form.developerNamePlaceholder')}
                        variant="dashboard"
                        value={formStates?.developerName?.[language?.value]}
                        setValue={(e) =>
                          setFormStates({
                            ...formStates,
                            developerName: {
                              ...formStates?.developerName,
                              [language?.value]: e
                            }
                          })
                        }
                      />
                    </Col>
                    {/* <Col md={6}>
                      <Input
                        label={t('form.registrationNumberLabel')}
                        placeholder={t('form.registrationNumberPlaceholder')}
                        variant="dashboard"
                        value={formStates?.registrationNumber}
                        type={'number'}
                        setValue={(e) =>
                          setFormStates({
                            ...formStates,
                            registrationNumber: e
                          })
                        }
                      />
                    </Col> */}
                    <Col md={6}>
                      <DropDown
                        label={t('form.projectTypeLabel')}
                        indicatorColor="var(--dashboard-text-gray-clr)"
                        placeholder={t('form.projectTypePlaceholder')}
                        variant="dashboard"
                        value={formStates?.projectType}
                        options={projectTypes}
                        optionValue={'_id'}
                        getOptionLabel={(e) => e?.title?.[language?.value]}
                        setValue={(e) =>
                          setFormStates({
                            ...formStates,
                            projectType: e
                          })
                        }
                        optionLabel={'title'}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        label={t('form.projectPriceLabel')}
                        type={'number'}
                        placeholder={t('form.projectPricePlaceholder')}
                        variant="dashboard"
                        value={formStates?.price}
                        setValue={(e) => {
                          setFormStates({ ...formStates, price: e });
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        label={t('form.projectNoOfUnits')}
                        type={'number'}
                        placeholder={t('form.projectNoOfUnitsPlaceholder')}
                        variant="dashboard"
                        value={formStates?.noOfUnits}
                        setValue={(e) => {
                          setFormStates({ ...formStates, noOfUnits: e });
                        }}
                      />
                    </Col>

                    <Col md={6}>
                      <Input
                        label={t('form.projectMinAreaLabel')}
                        type={'number'}
                        placeholder={t('form.projectMinAreaPlaceholder')}
                        variant="dashboard"
                        value={formStates?.minimumArea}
                        setValue={(e) => {
                          setFormStates({ ...formStates, minimumArea: e });
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        label={t('form.projectMaxAreaLabel')}
                        type={'number'}
                        placeholder={t('form.projectMaxAreaPlaceholder')}
                        variant="dashboard"
                        value={formStates?.maximumArea}
                        setValue={(e) => {
                          if (!formStates?.minimumArea)
                            return toast.error(t('form.projectMaxAreaToast'));
                          setFormStates({ ...formStates, maximumArea: e });
                        }}
                      />
                    </Col>

                    <Col md={6}>
                      <Input
                        type={'date'}
                        label={t('form.projectPlanDeliveryDate')}
                        min={moment().format('YYYY-MM-DD')}
                        placeholder={t('form.projectPlanDeliveryDatePlaceholder')}
                        variant="dashboard"
                        value={
                          formStates?.deliveryDate
                            ? moment(formStates?.deliveryDate).format('YYYY-MM-DD')
                            : ''
                        }
                        setValue={(e) =>
                          setFormStates({
                            ...formStates,
                            deliveryDate: e
                          })
                        }
                      />
                    </Col>
                    <Col md={6}>
                      <DropDown
                        label={t('form.projectExteriorAmenities')}
                        placeholder={t('form.projectExteriorAmenitiesPlaceholder')}
                        variant="dashboard"
                        value={
                          formStates?.exteriorAmenities?.length > 0
                            ? formStates?.exteriorAmenities
                            : []
                        }
                        setValue={(e) => {
                          setFormStates({
                            ...formStates,
                            exteriorAmenities: e
                          });
                        }}
                        indicatorColor="var(--dashboard-text-gray-clr)"
                        optionValue={'_id'}
                        options={exteriorAmenities}
                        isMulti={true}
                        getOptionLabel={(e) => {
                          return e?.title?.[language?.value];
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <DropDown
                        label={t('form.projectInteriorAmenities')}
                        placeholder={t('form.projectInteriorAmenitiesPlaceholder')}
                        variant="dashboard"
                        value={
                          formStates?.interiorAmenities?.length > 0
                            ? formStates?.interiorAmenities
                            : []
                        }
                        setValue={(e) => {
                          setFormStates({
                            ...formStates,
                            interiorAmenities: e
                          });
                        }}
                        indicatorColor="var(--dashboard-text-gray-clr)"
                        optionValue={'_id'}
                        options={interiorAmenities}
                        isMulti={true}
                        getOptionLabel={(e) => {
                          return e?.title?.[language?.value];
                        }}
                      />
                    </Col>

                    {/* Location */}
                    {/* <Col md={12}>
                    <h4>Location</h4>
                  </Col> */}
                    <Col md={12}>
                      <LocationComponent
                        t={languageD}
                        locationState={locationState}
                        setLocationState={setLocationState}
                        selectedLanguage={language}
                      />
                    </Col>

                    {/* media */}
                    <Col md={12}>
                      <h4>{t('form.projectMediaHeading')}</h4>
                    </Col>
                    <Col md={12}>
                      <UploadImageBox
                        setValue={(e) => {
                          if (!['image/png', 'image/jpeg', 'image/jpg'].includes(e?.type))
                            return toast.error(
                              'Please upload a valid image formats (png, jpeg, jpg) only'
                            );
                          setProjectLogo(e);
                        }}
                        value={projectLogo}
                        onDelete={() => setProjectLogo(null)}
                        label={t('form.projectMediaLabel')}
                      />
                    </Col>
                    <Col md={12}>
                      <label className={classes.label}>{t('form.projectPlansHeading')}</label>
                      <Row>
                        {uploadedImages?.map((item, index) => (
                          <Col md={3} key={index}>
                            <UploadImageBox
                              label={''}
                              value={item}
                              edit={false}
                              onDelete={() => {
                                deleteMedia({
                                  slug,
                                  type: 'images',
                                  key: item,
                                  urlType: 'project'
                                });
                              }}
                            />
                          </Col>
                        ))}
                      </Row>
                    </Col>
                    <Col md={12}>
                      <FileUpload
                        label={t('form.projectPlansHeading')}
                        files={images}
                        setFiles={setImages}
                        text={t('form.dragAndDrop')}
                        orText={t('form.orText')}
                        buttonLabel={t('form.browseFiles')}
                      />
                    </Col>
                    <Col md={12}>
                      <label className={classes.label}>{t('form.projectPlansHeading')}</label>
                      <Row>
                        {uploadedPlanImages?.map((item, index) => (
                          <Col md={3} key={index}>
                            <UploadImageBox
                              value={item}
                              edit={false}
                              label={''}
                              onDelete={() => {
                                deleteMedia({
                                  slug,
                                  type: 'planImages',
                                  key: item,
                                  urlType: 'project'
                                });
                              }}
                            />
                          </Col>
                        ))}
                      </Row>
                    </Col>
                    <Col md={12}>
                      <FileUpload
                        label={t('form.projectPlansLabel')}
                        files={planImages}
                        setFiles={setPlanImages}
                        text={t('form.dragAndDrop')}
                        orText={t('form.orText')}
                        buttonLabel={t('form.browseFiles')}
                      />
                    </Col>
                    <Col md={12}>
                      <Input
                        label={t('form.projectVideoLabel')}
                        placeholder={t('form.projectVideoPlaceholder')}
                        variant="dashboard"
                        value={videoLink}
                        setValue={setVideoLink}
                      />
                    </Col>
                    <Col md={12}>
                      <Input
                        label={t('form.project360Link')}
                        placeholder={t('form.project360LinkPlaceholder')}
                        variant="dashboard"
                        value={View360}
                        setValue={(e) => {
                          setView360(e);
                          setView360DragDrop(null);
                        }}
                        disabled={!!view360DragDrop}
                      />
                    </Col>
                    <Col className="text-center" md={12}>
                      {t('form.orText')}
                    </Col>
                    <Col md={12}>
                      <UploadImageBox
                        label={t('form.project360Upload')}
                        disabled={!!View360}
                        value={view360DragDrop}
                        setValue={(e) => {
                          if (!['image/png', 'image/jpeg', 'image/jpg'].includes(e?.type))
                            return toast.error(
                              'Please upload a valid image formats (png, jpeg, jpg) only'
                            );
                          setView360DragDrop(e);
                          setView360('');
                        }}
                        onDelete={() => {
                          setView360DragDrop(null);
                        }}
                      />
                    </Col>
                    <Col md={12}>
                      <CustomFileInput
                        label={t('form.projectPdfCatalogue')}
                        placeholder={''}
                        // placeholder={t('form.projectPdfCataloguePlaceholder')}
                        value={catalogue}
                        isEdit={projectData?.data?.projectBrochureName}
                        setValue={(e) => {
                          if (!['application/pdf'].includes(e?.type))
                            return toast.error('Please upload pdf format only');
                          setCatalogue(e);
                        }}
                        selectedLanguage={language}
                        btnLabel={t('form.projectPdfCatalogueBtn')}
                      />
                    </Col>

                    {/* Project Unit Information */}
                    {/* <Col md={12}>
                    <h4>Project Unit Information</h4>
                  </Col>

                  <Col md={6}>
                    <Input
                      label={'Project Unit Description'}
                      placeholder={'Write Project Unit Description'}
                      variant="dashboard"
                      value={unitDescription}
                      setValue={setUnitDescription}
                    />
                  </Col>
                  <Col md={6}>
                    <DropDown
                      label={'Project Units Type'}
                      placeholder={'Select Project Unit Type'}
                      options={propertyStatusOptions}
                      variant="dashboard"
                      indicatorColor="var(--dashboard-text-gray-clr)"
                      value={unitsType}
                      setValue={setUnitsType}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      type={'number'}
                      label={'Project Units Area*'}
                      placeholder={'Enter Project Units Area'}
                      variant="dashboard"
                      value={unitsArea}
                      setValue={setUnitsArea}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      type={'number'}
                      label={'No. Of Rooms*'}
                      placeholder={'Enter No. Of Rooms'}
                      variant="dashboard"
                      value={rooms}
                      setValue={setRooms}
                      options={NoOfUnitsOptions}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      type={'number'}
                      label={'No. Of Bathrooms *'}
                      placeholder={'Enter No. Of Bathrooms'}
                      variant="dashboard"
                      value={bathRooms}
                      setValue={setBathRooms}
                      options={NoOfUnitsOptions}
                    />
                  </Col>

                  <Col md={6}>
                    <Input
                      label={'Car Parking'}
                      placeholder={'Enter Car Parking'}
                      variant="dashboard"
                      value={carParking}
                      setValue={setCarParking}
                    />
                  </Col>
                  <Col md={12}>
                    <FileUpload
                      label={'Project Units Images and Plans'}
                      files={unitImagesAndPlan}
                      setFiles={setUnitImagesAndPlan}
                    />
                  </Col>

                  <Col md={6}>
                    <DropDown
                      label={'Exterior Amenities'}
                      placeholder={'Select Exterior Amenities'}
                      variant="dashboard"
                      value={unitExAmenities}
                      setValue={setUnitExAmenities}
                      indicatorColor="var(--dashboard-text-gray-clr)"
                      options={exteriorAmenitiesOptions}
                      isMulti={true}
                    />
                  </Col>
                  <Col md={6}>
                    <DropDown
                      label={'Interior Amenities'}
                      placeholder={'Select Interior Amenities'}
                      variant="dashboard"
                      value={unitInAmenities}
                      setValue={setUnitInAmenities}
                      indicatorColor="var(--dashboard-text-gray-clr)"
                      options={interiorAmenitiesOptions}
                      isMulti={true}
                    />
                  </Col> */}
                    <Col md={12} className="text-center">
                      <Button
                        label={t(
                          `form.${
                            apiSubmitingLoading
                              ? slug
                                ? 'updateBtnLoading'
                                : 'saveBtnLoading'
                              : slug
                              ? 'updateBtn'
                              : 'saveBtn'
                          }`
                        )}
                        disabled={apiSubmitingLoading}
                        onClick={handleSubmit}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </SideBarSkeleton>
  );
}

export default NewProject;
