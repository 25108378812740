import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  areaUnit: 'm²',
  currency: { unit: 'USD', value: 1, symbol: '$' }
};

const commonSlice = createSlice({
  name: 'converterReducer',
  initialState,
  reducers: {
    setAreaUnit: (state, action) => {
      state.areaUnit = action.payload;
    },

    setCurrencyUnit: (state, action) => {
      state.currency = action.payload;
    }
  }
});

export const { setAreaUnit, setCurrencyUnit } = commonSlice.actions;

export default commonSlice.reducer;
