import React from 'react';
import classes from './ShareModal.module.css';

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookMessengerIcon
} from 'react-share';
import { MdOutlineContentCopy } from 'react-icons/md';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import ModalSkeleton from '../../modals/ModalSkeleton';

const ShareModal = ({ show, setShow }) => {
  const socialIconProps = {
    size: 32
  };

  const title = "Demo - Let's Book";
  const url = window.location.href;

  return (
    <ModalSkeleton show={show} setShow={setShow}>
      {/* <div className={classes.close_icon_main}>
        <CloseIcon onClick={() => setShow(false)} className={classes.close_icon} />
      </div> */}
      <div className={classes.container}>
        <div className={classes.main}>
          <h1>Share with friends and family</h1>
        </div>
        <div className={classes.subHead}>
          <h4>Demo | Share</h4>
        </div>
        <div className={classes.socialDivs}>
          <div className="row">
            <div className={`col-md-6 ${classes.social} c-p`}>
              <CopyToClipboard
                text={window.location.href}
                onCopy={() => toast.success('Link copied to clipboard.')}>
                <div className={`${classes.social_inner}`}>
                  <MdOutlineContentCopy round {...socialIconProps} />
                  <span>Copy Link</span>
                </div>
              </CopyToClipboard>
            </div>
            <div className={`col-md-6 ${classes.social}`}>
              <EmailShareButton url={url} quote={title}>
                <EmailIcon round {...socialIconProps} />
                <span>Gmail</span>
              </EmailShareButton>
            </div>
            <div className={`col-md-6 ${classes.social}`}>
              <WhatsappShareButton url={url} quote={title}>
                <WhatsappIcon round {...socialIconProps} />
                <span>WhatsApp</span>
              </WhatsappShareButton>
            </div>{' '}
            <div className={`col-md-6 ${classes.social}`}>
              <FacebookShareButton url={url} quote={title}>
                <FacebookIcon round {...socialIconProps} />
                <span>Facebook</span>
              </FacebookShareButton>
            </div>
            <div className={`col-md-6 ${classes.social}`}>
              <FacebookShareButton url={url} quote={title}>
                <FacebookMessengerIcon round {...socialIconProps} />
                <span>Messenger</span>
              </FacebookShareButton>
            </div>
            <div className={`col-md-6 ${classes.social}`}>
              <TwitterShareButton url={url} quote={title}>
                <TwitterIcon round {...socialIconProps} />
                <span>Twitter</span>
              </TwitterShareButton>
            </div>
          </div>
        </div>
      </div>
    </ModalSkeleton>
  );
};

export default ShareModal;
