import React from 'react';
import classes from './RenewPackage.module.css';
import SideBarSkeleton from '../SideBarSkeleton';
import { Container, Row } from 'react-bootstrap';
import Button from '../Button/Button';
import { Link } from 'react-router-dom';
import PackageExpiredSVG from '../../assets/Svgs/package-expired.svg';

export default function RenewPackage() {
  return (
    <SideBarSkeleton>
      <Container className={classes.main}>
        <Row className={classes.row}>
          <div className={classes.middle}>
            <img src={PackageExpiredSVG} className={classes.image} />
            <h5 className="text-center">
              You package has been expired. Kindly update your packages
            </h5>
            <Link to="/advertise-with-us">
              <Button label="Renew Package" className="mt-3" />
            </Link>
          </div>
        </Row>
      </Container>
    </SideBarSkeleton>
  );
}
