/*eslint-disable*/
import React, { useRef } from 'react';
import classes from './AccountInfoLayout.module.css';
import { Container } from 'react-bootstrap';
import { AiOutlineCaretRight } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useState } from 'react';
import { Popover } from '@mui/material';
import Button from '../Button/Button';
import { useEffect } from 'react';
import { getWindowWidth } from '../../CustomHooks/isMobileViewHook';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { apiUrl } from '../../config/apiUrl';
import { io } from 'socket.io-client';
import { signOutRequest } from '../../store/auth/authSlice';

export const MenuList = ({ isMobile, d }) => {
  const socket = useRef(null);
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const { user } = useSelector((state) => state?.authReducer);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  function logout() {
    socket.current = io(apiUrl);
    socket.current.emit('join', user?._id);
    socket.current.emit('logout', user?._id);
    dispatch(signOutRequest());
    navigate('/');
  }

  const options =
    user?.role == 'user'
      ? [
          {
            name: d('accountSettings'),
            path: '/account-settings'
          },
          {
            name: d('followedAgents'),
            path: '/followed-agents'
          },

          {
            name: d('savedProperties'),
            path: '/saved-properties'
          },
          {
            name: d('savedEvents'),
            path: '/saved-events'
          },
          {
            name: d('savedProjects'),
            path: '/saved-projects'
          },
          {
            name: d('savedSearches'),
            path: '/saved-searches'
          },
          {
            name: d('compareList'),
            path: '/compare-list'
          },

          {
            name: d('contactedProperties'),
            path: '/contacted-properties'
          },
          {
            name: d('propertiesRequests'),
            path: '/property-requests'
          }
        ]
      : [
          {
            name: 'Dashboard',
            path: `/${user?.role}/dashboard`
          }
        ];

  return (
    <>
      {isMobile ? (
        <>
          <Button onClick={handleClick}>
            <GiHamburgerMenu />
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}>
            <div>
              <div className={classes.profile_head}>
                <div className={classes.profile_img}>
                  <img src="https://www.w3schools.com/howto/img_avatar.png" alt="profile" />
                </div>
                <div className={classes.profile_name}>
                  <p>
                    <span>
                      <h5>
                        {(user?.role == 'company' && user?.companyName?.[selectedLanguage]) ||
                          (user?.role == 'agent' && user?.agentName?.[selectedLanguage]) ||
                          (user?.role == 'user' && user?.firstName + ' ' + user?.lastName)}
                      </h5>
                    </span>

                    <h6 onClick={logout}>{d('logout')}</h6>
                  </p>
                </div>
              </div>
              {options?.map((item, index) => {
                return (
                  <div
                    onClick={() => navigate(item?.path)}
                    style={{
                      cursor: 'pointer',
                      background: window.location.pathname == item?.path && '#f6f6f6'
                    }}
                    key={index}
                    className={classes.item_lists}>
                    <p>{item?.name}</p>

                    <div className={classes.chevron_icon}>
                      <AiOutlineCaretRight color="var(--secondary-clr)" />
                    </div>
                  </div>
                );
              })}
            </div>
          </Popover>
        </>
      ) : (
        <div>
          <div className={classes.profile_head}>
            <div className={classes.profile_img}>
              <img src="https://www.w3schools.com/howto/img_avatar.png" alt="profile" />
            </div>
            <div className={classes.profile_name}>
              <p>
                <span>
                  <h5>
                    {(user?.role == 'company' && user?.companyName?.[selectedLanguage]) ||
                      (user?.role == 'agent' && user?.agentName?.[selectedLanguage]) ||
                      (user?.role == 'user' && user?.firstName + ' ' + user?.lastName)}
                  </h5>
                </span>
                <h6 onClick={logout} className="c-p">
                  {d('logout')}
                </h6>
              </p>
            </div>
          </div>
          {options?.map((item, index) => {
            return (
              <div
                onClick={() => navigate(item?.path)}
                style={{
                  cursor: 'pointer',
                  background: window.location.pathname == item?.path && '#f6f6f6'
                }}
                key={index}
                className={classes.item_lists}>
                <p>{item?.name}</p>

                <div className={classes.chevron_icon}>
                  <AiOutlineCaretRight color="var(--secondary-clr)" />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

const AccountInfoLayout = ({ children }) => {
  const { t } = useTranslation('webCommon');

  const d = (a) => t(`userSidebarOptions.${[a]}`);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    getWindowWidth(setScreenWidth);
  }, []);
  return (
    <Container>
      <div className={classes.main_conti} data-direction-col={screenWidth < 1400}>
        <div className={classes.sidebar_main}>
          <MenuList isMobile={screenWidth < 1400} d={d} />
        </div>
        <div
          style={{
            width: '100%'
          }}>
          {children}
        </div>
      </div>
    </Container>
  );
};

export default AccountInfoLayout;
