/*eslint-disable*/
import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { MdPhotoLibrary } from 'react-icons/md';
import Tabs from '../../Component/Tabs';
import classes from './SavedSearches.module.css';
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import AccountInfoLayout from '../../Component/AccountInfoLayout';
// import DropDown from '../../Component/DropDown/DropDown';
import { BiBell } from 'react-icons/bi';
import { AiOutlineSearch } from 'react-icons/ai';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { useGetAllSavedSearchesQuery, usePostApiMutation } from '../../store/apiSlice/apiSlice';
import { Loader } from '../../Component/Loader';
import NoData from '../../Component/NoData/NoData';
import { recordsLimit } from '../../config/apiUrl';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { PaginationComponent } from '../../Component/Pagination/Pagination';

const SavedSearches = () => {
  const { t } = useTranslation('savedSearches');
  const [page, setPage] = useState(1);
  const { isLoading, data, refetch } = useGetAllSavedSearchesQuery({
    page: page,
    limit: recordsLimit
  });
  const [deleteApiFunc, { isLoading: isDeleting }] = usePostApiMutation();
  const [checkboxArray, setCheckboxArray] = useState([]);

  const deleteApi = async (deleteAll) => {
    // console.log(Array.isArray(allUsers), 'allUsers allUsersallUsers');

    if (!deleteAll && checkboxArray?.length == 0) {
      return toast.error(`Please select at least one saved search`);
    }
    if (deleteAll && data?.data?.length == 0) {
      return;
    }

    const deleteResponse = await deleteApiFunc({
      url: `savedSearch/delete`,
      data: {
        ...(deleteAll
          ? { isDeleteAll: deleteAll }
          : {
              ids: checkboxArray?.map((e) => e?._id)
            })
      },

      method: 'DELETE',
      tag: `savedSearches`
    });
    if (deleteResponse?.data) {
      console.log(deleteResponse, 'deleteResponse deleteResponse deleteResponse');
      setCheckboxArray([]);
      toast.success(
        `${deleteAll ? 'All' : ''} Saved Search${
          checkboxArray?.length != 0 || deleteAll ? 'es' : ''
        }  deleted successfully`
      );
      refetch();
    }
  };

  const tabsData = [
    {
      icon: <MdPhotoLibrary />,
      text: `${t('savedSearches')} (${data?.totalRecords ?? 0})`,
      id: '1'
    }
  ];
  const [tabValue] = useState(tabsData[0]);

  console.log(checkboxArray, 'data data data data');

  return (
    <>
      <div className={`page_bg ${classes.page}`}>
        <Header containerClass="customContainer" />
        <AccountInfoLayout>
          <Tabs
            className={classes.tab_main}
            tabClass={classes.tab_class_main}
            value={tabValue}
            tabsData={tabsData}
          />
          <div className={classes.delete_div}>
            <div
              style={{
                display: 'flex'
              }}>
              <p>{`${t('showing')} ${data?.data?.length ?? 0} - ${data?.totalRecords ?? 0} ${t(
                'savedSearches'
              )}`}</p>
              <p
                onClick={() => {
                  setCheckboxArray([]);
                  deleteApi(true);
                }}
                className={isDeleting ? 'c-d' : 'c-p'}>
                {t('deleteAll')}
              </p>
              <p onClick={() => deleteApi(false)} className={isDeleting ? 'c-d' : 'c-p'}>
                {t('deleteSelected')}
              </p>
            </div>
            {/* <div className={classes.sort_div}>
              <p>Sort by:</p>

              <DropDown classNamePrefix={'dropDownMain'} placeholder={'Featured'} />
            </div> */}
          </div>

          <Row>
            <Col md={12}>
              {isLoading ? (
                <Loader />
              ) : data?.data?.length === 0 ? (
                <NoData text={t('noData')} />
              ) : (
                data?.data?.map((item, index) => {
                  return (
                    <div key={index} className={classes.search_div}>
                      <div className={classes.bell_div}>
                        <BiBell color={'var(--secondary-clr)'} size={45} />
                        <div className={classes.left_div}>
                          <h6>Balluta</h6>
                          <p>25-6-2023</p>
                        </div>
                      </div>
                      <div className={classes.right_div}>
                        <div className={classes.icon_div}>
                          <AiOutlineSearch color="#fff" />
                        </div>
                        <div className={classes.icon_div1}>
                          <RiDeleteBin5Line size={40} />
                        </div>
                        <Form.Check
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                          }}
                          className={isDeleting ? 'c-d' : 'c-p'}
                          name="group1"
                          type={'checkbox'}
                          id={`inline-checkbox-1`}
                          onChange={() => {
                            const newData = [...checkboxArray];
                            const index = newData.findIndex((e) => e?._id == item?._id);
                            if (index != -1) {
                              newData?.splice(index, 1);
                            } else {
                              newData.push(item);
                            }
                            setCheckboxArray(newData);
                          }}
                          checked={!!checkboxArray?.find((e) => e?._id == item?._id)}
                          disabled={isDeleting}
                        />
                      </div>
                    </div>
                  );
                })
              )}
            </Col>
            {!!data?.data?.length && !isLoading && (
              <Col md={12}>
                <PaginationComponent
                  currentPage={page}
                  setCurrentPage={setPage}
                  totalPages={Math.ceil(data?.totalRecords / recordsLimit)}
                />
              </Col>
            )}
          </Row>
        </AccountInfoLayout>
      </div>
      <Footer />
    </>
  );
};

export default SavedSearches;
