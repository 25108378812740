import React from 'react';
import classes from './Mortgage.module.css';

import HeadingWithUnderline from '../HeadingWithUnderline';
import { Col, Row } from 'react-bootstrap';
import { Slider } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// import { useState } from 'react';

const PrettoSlider = styled(Slider)({
  color: 'var(--primary-clr)',
  height: 8,
  marginBottom: '-10px',
  '& .MuiSlider-track': {
    height: '8px',
    border: 'none',
    backgroundColor: 'var(--primary-clr)'
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: 'var(--primary-clr)',
    border: '4px solid var(--white-clr)',
    boxShadow: '2px 2px 10px #00000094',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: '2px 2px 10px #00000094'
    },
    '&:before': {
      display: 'none'
    }
  },
  '& .MuiSlider-rail': {
    opacity: 0.3,
    backgroundColor: 'var(--primary-clr)',
    borderRadius: '0px !important',
    height: '8px'
  }
});

const MortgageCalculator = ({
  heading = 'Mortgage Calculator',
  data = {
    principalAmount: 'AED 422000',
    interestRate: '16.6%',
    duration: '16.6%',
    totalInterestAmount: '573363 AED',
    monthlyCost: '21000 AED',
    totalCost: '422000 AED',
    interestAmount: '573362 AED',
    loanAmount: '422000 AED'
  },
  tKey='propertyDetail'
}) => {
  const { t } = useTranslation(tKey);
  const [propertyValue, setPropertyValue] = useState(100000);
  const [loanPeriod, setLoanPeriod] = useState(2);
  const [interest, setInterest] = useState(100000);
  const [duration, setDuration] = useState(2);

  return (
    <>
      <style>
        {`
            .slider .MuiSlider-root{
                margin-bottom:-8px
            }

            `}
      </style>
      <div className={classes.main}>
        <HeadingWithUnderline underlineColor="var(--secondary-clr)">
          <h5>{heading || t('mortgage.title')}</h5>
        </HeadingWithUnderline>

        <Row className={classes.row}>
          <Col className={classes.col} md={6}>
            <div>
              <div className={classes.head}>
                <div>{t('mortgage.range1')}</div>
                <div className={classes.valueBox}>42000 AED</div>
              </div>
              <div className={classes.slider}>
                <PrettoSlider
                  value={propertyValue}
                  onChange={(val) => {
                    setPropertyValue(val.target.value);
                  }}
                  aria-label="Default"
                  valueLabelDisplay="auto"
                  min={30000}
                  step={1000}
                  max={1000000}
                  //   track={'inverted'}
                />
              </div>
              <div className={`flex-sb ${classes.range}`}>
                <span>30000</span>
                <span>100,000</span>
              </div>
            </div>
            <div>
              <div className={classes.head}>
                <div>{t('mortgage.range2')}</div>
                <div className={classes.valueBox}>42000 AED</div>
              </div>
              <div className={classes.slider}>
                <PrettoSlider
                  value={loanPeriod}
                  onChange={(val) => {
                    setLoanPeriod(val.target.value);
                  }}
                  aria-label="Default"
                  valueLabelDisplay="auto"
                  min={1}
                  max={4}
                />
              </div>
              <div className={`flex-sb ${classes.range}`}>
                <span>1 Year</span>
                <span>4 Year</span>
              </div>
            </div>
            <div>
              <div className={classes.head}>
                <div>{t('mortgage.range3')}</div>
                <div className={classes.valueBox}>42000 AED</div>
              </div>
              <div className={classes.slider}>
                <PrettoSlider
                  value={interest}
                  onChange={(val) => {
                    setInterest(val.target.value);
                  }}
                  aria-label="Default"
                  valueLabelDisplay="auto"
                  min={30000}
                  step={1000}
                  max={1000000}
                />
              </div>
              <div className={`flex-sb ${classes.range}`}>
                <span>30000</span>
                <span>100,000</span>
              </div>
            </div>
            <div>
              <div className={classes.head}>
                <div>{t('mortgage.range4')}</div>
                <div className={classes.valueBox}>42000 AED</div>
              </div>
              <div className={classes.slider}>
                <PrettoSlider
                  value={duration}
                  onChange={(val) => {
                    setDuration(val.target.value);
                  }}
                  aria-label="Default"
                  valueLabelDisplay="auto"
                  min={1}
                  max={4}
                />
              </div>
              <div className={`flex-sb ${classes.range}`}>
                <span>1 {t('mortgage.year')}</span>
                <span>4 {t('mortgage.year')}</span>
              </div>
            </div>
          </Col>
          <Col className={classes.col} md={6}>
            <div className={classes.col2Head}>
              <div className={classes.head1}>
                <h6>{data?.interestAmount}</h6>
                <p>{t('mortgage.interest')}</p>
              </div>
              <div className={classes.head2}>
                <h6>{data?.loanAmount}</h6>
                <p>{t('mortgage.loanAmount')}</p>
              </div>
            </div>
            <Row className={classes.mainRow}>
              <Col md={12}>
                <Row>
                  <Col lg={6} md={6}>
                    <h6>{t('mortgage.principalAmount')}</h6>
                  </Col>
                  <Col lg={6} md={6}>
                    <p>{data?.principalAmount}</p>
                  </Col>
                </Row>
              </Col>

              <Col md={12}>
                <Row>
                  <Col lg={6} md={6}>
                    <h6>{t('mortgage.interestRate')}</h6>
                  </Col>
                  <Col lg={6} md={6}>
                    <p>{data?.interestRate}</p>
                  </Col>
                </Row>
              </Col>

              <Col md={12}>
                <Row>
                  <Col lg={6} md={6}>
                    <h6>{t('mortgage.duration')}</h6>
                  </Col>
                  <Col lg={6} md={6}>
                    <p>{data?.duration}</p>
                  </Col>
                </Row>
              </Col>

              <Col md={12}>
                <Row>
                  <Col lg={6} md={6}>
                    <h6>{t('mortgage.totalInterestAmount')}</h6>
                  </Col>
                  <Col lg={6} md={6}>
                    <p>{data?.totalInterestAmount}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className={classes.col2Cost}>
              <div className={classes.cost}>
                <h5>{t('mortgage.monthlyCost')}</h5>
                <h4>{data?.monthlyCost}</h4>
              </div>
              <div className={classes.cost}>
                <h5>{t('mortgage.totalCost')}</h5>
                <h4 data-color={'primary'}>{data?.totalCost}</h4>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MortgageCalculator;
