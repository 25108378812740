/*eslint-disable*/
import React from 'react';
import classes from './ClientManager.module.css';
import { keyDevsLogo, EmptyProfileImage } from '../../constant/imagePath';
import { MdWifiCalling3 } from 'react-icons/md';
import { IoIosMail, IoLogoWhatsapp } from 'react-icons/io';
import { SlUserFollow, SlUserFollowing } from 'react-icons/sl';
import Tooltip from '../Tooltip';
import { useState } from 'react';
import EmailAgentModal from '../../modals/EmailAgentModal';
import { imageUrl } from '../../config/apiUrl';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFollowUnFollowCompanyOrAgentMutation } from '../../store/apiSlice/apiSlice';
import { restrictOnlyUserActions } from '../../Helper/restrictActions';
import { updateUser } from '../../store/auth/authSlice';
import Button from '../Button/Button';
import { toast } from 'react-toastify';

const ClientManagerCard = ({
  data = {
    name: 'AgentCard',
    designation: 'Client Manager',
    photo: EmptyProfileImage,
    logo: keyDevsLogo,
    languages: ['English']
  },
  pageData = {},
  dataType
}) => {
  const dispatch = useDispatch();
  const { isLogin, user } = useSelector((state) => state.authReducer);
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const { t } = useTranslation('webCommon');
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [onFollow, { isLoading }] = useFollowUnFollowCompanyOrAgentMutation();

  const renderData = React.useMemo(
    () => (!!data?.assignedAgent ? data?.assignedAgent : !!data?.company ? data?.company : null),
    [data]
  );

  const isFollowed =
    renderData?.role == 'company'
      ? user?.companyFollowing?.includes(renderData?._id)
      : user?.agentFollowing?.includes(renderData?._id);

  const handleAction = () => {
    const isAllowedActions = restrictOnlyUserActions(user?.role);
    if (!isLogin) {
      navigate('/login');
    } else if (isAllowedActions) {
      return true;
    }
  };

  async function handleFollow() {
    if (!handleAction()) return;
    const res = await onFollow(renderData?._id);
    if (res?.data) {
      dispatch(updateUser(res?.data?.data));
      toast.success(
        `${renderData?.role == 'company' ? 'Company' : 'Agent'} ${
          isFollowed ? 'unfollowed' : 'followed'
        } successfully`
      );
    }
  }

  return (
    <div className={classes.main}>
      <div className={`${classes?.agentIntroContainer}`}>
        <div
          className={classes?.profileImageContainer}
          onClick={() =>
            navigate(`/${renderData?.role == 'agent' ? 'agents' : 'company'}/${renderData?.slug}`)
          }>
          <img src={imageUrl(renderData?.photo)} />
        </div>
        <h5>
          {renderData?.agentName?.[selectedLanguage] ?? renderData?.companyName?.[selectedLanguage]}
        </h5>
        <p>
          {renderData?.agentDesignation?.[selectedLanguage] ??
            renderData?.companyType?.[selectedLanguage]}
        </p>
        <div className={classes.language}>
          <span>{t('propertyDetailAgentCard.speaks')}: </span>
          {renderData?.language?.length > 2
            ? `${renderData?.language
                ?.map((e) => e?.[selectedLanguage])
                ?.slice(0, 2)
                ?.join(', ')}...`
            : renderData?.language?.map((e) => e?.[selectedLanguage])?.join(', ')}
        </div>
        {renderData?.role == 'agent' && (
          <div className={classes.midContent}>
            <div
              className={classes.logo}
              onClick={() => navigate(`/company/${renderData?.company?.slug}`)}>
              <img src={imageUrl(renderData?.company?.photo)} alt="" />
            </div>
            <p>Links Real Estate Brokers</p>
          </div>
        )}

        {/* <div className={classes?.iconsContainer}>
          <div>
          <Tooltip text={'Cal'}>
            <MdWifiCalling3 fill="#E2831B" size={30} />
          </Tooltip>
          </div>
          <div>
            <Tooltip text={'Email'}>
              <IoIosMail fill="#E2831B" size={30} />
            </Tooltip>
          </div>
          <div>
            <Tooltip text={"Whatsapp"}>

            <IoLogoWhatsapp fill="#E2831B" size={30} />
            </Tooltip>
          </div>
        </div> */}
        <div className={classes.socialIconsWrap1}>
          <a href={`tel:${renderData?.phoneNumber}`} className={classes.socialIcons1}>
            <Tooltip text={t('propertyDetailAgentCard.tooltips.call')}>
              <MdWifiCalling3 fill="#E2831B" size={30} />
            </Tooltip>
          </a>
          <div
            className={[classes.socialIcons1, classes.midIcon].join(' ')}
            onClick={() => setIsOpen(true)}>
            <Tooltip text={t('propertyDetailAgentCard.tooltips.email')}>
              <IoIosMail fill="#E2831B" size={30} />
            </Tooltip>
          </div>
          <a
            href={`https://wa.me/${renderData?.whatsapp}`}
            target="_blank"
            className={classes.socialIcons1}>
            <Tooltip text={t('propertyDetailAgentCard.tooltips.whatsapp')}>
              <IoLogoWhatsapp fill="#E2831B" size={30} />
            </Tooltip>
          </a>
        </div>
      </div>
      <Button
        className={classes?.followBtnContainer}
        onClick={() => {
          handleFollow();
        }}
        disabled={isLoading}>
        {isLoading ? (
          ''
        ) : isFollowed ? (
          <SlUserFollowing fill="#fff" size={25} />
        ) : (
          <SlUserFollow fill="#fff" size={25} />
        )}
        {isLoading
          ? t('propertyDetailAgentCard.followBtnLoading')
          : isFollowed
          ? t('propertyDetailAgentCard.following')
          : t('propertyDetailAgentCard.follow')}
      </Button>
      <EmailAgentModal show={isOpen} setShow={setIsOpen} data={data} type={dataType} />
    </div>
  );
};

export default ClientManagerCard;
