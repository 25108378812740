/*eslint-disable*/ 
import React, { useEffect, useState } from 'react';
import classes from './AgentRegisterationForm.module.css';
import Footer from '../../Component/Footer';
import Header from '../../Component/Header';
import {
  AuctionsIcon,
  KeepInTouchIcon1,
  KeepInTouchIcon2,
  KeepInTouchIcon3,
  KeepInTouchIcon4,
  KeepInTouchIcon5,
  KeepInTouchSectionImage,
  NewProjectsIcon,
  OpenHousesIcon,
  PowerfullIcon1,
  PowerfullIcon2,
  PowerfullIcon3,
  PowerfullIcon5,
  PowerfullIcon6,
  PowerfullIcon7,
  PowerfullIcon8,
  // PowerfullSectionImage,
  SeminarsIcon,
  ViewingToursIcon,
  WhiteLogo
} from '../../constant/imagePath';
import { Col, Container, Row } from 'react-bootstrap';
import Input from '../../Component/Input/Input';
import Checkbox from '../../Component/Checkbox/Checkbox';
import CustomPhoneInput from '../../Component/CustomPhoneInput';
import Button from '../../Component/Button/Button';
import { Link } from 'react-router-dom';
import { RxChevronRight } from 'react-icons/rx';
// import { BsDownload } from 'react-icons/bs';
import HeadingWithUnderline from '../../Component/HeadingWithUnderline';
import PackageCard from '../../Component/PackageCard';
import { useTranslation } from 'react-i18next';
import Parser from 'html-react-parser';
import { useLazyGetApiQuery, usePostApiMutation } from '../../store/apiSlice/apiSlice';
import { Loader } from '../../Component/Loader';
import { toast } from 'react-toastify';
import validator from 'validator';
import { useSelector } from 'react-redux';

const RegisterationForm = ({ t, onSubmit, isSubmitting }) => {
  const [formStates, setFormStates] = useState({
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    allowTerms: false
  });
  function handleFormStateUpdate(key, value) {
    setFormStates({ ...formStates, [key]: value });
  }
  console.log(formStates, 'formStates');

  return (
    <form
      className={classes.form}
      onSubmit={(e) => {
        e?.preventDefault();
      }}>
      <Row className={classes.formRow}>
        <Col md={12}>
          <h4>{t('form.title')}</h4>
          <h6>{t('form.tagline')}</h6>
        </Col>
        <Col md={12}>
          <Input
            value={formStates?.companyName}
            setValue={(e) => handleFormStateUpdate('companyName', e)}
            label={t('form.companyName')}
            placeholder={t('form.companyNamePlaceholder')}
          />
        </Col>
        <Col md={6}>
          <Input
            value={formStates?.firstName}
            setValue={(e) => handleFormStateUpdate('firstName', e)}
            label={t('form.firstName')}
            placeholder={t('form.firstNamePlaceholder')}
          />
        </Col>
        <Col md={6}>
          <Input
            value={formStates?.lastName}
            setValue={(e) => handleFormStateUpdate('lastName', e)}
            label={t('form.lastName')}
            placeholder={t('form.lastNamePlaceholder')}
          />
        </Col>
        <Col md={6}>
          <Input
            value={formStates?.email}
            setValue={(e) => handleFormStateUpdate('email', e)}
            label={t('form.email')}
            placeholder={t('form.emailPlaceholder')}
            type={'email'}
          />
        </Col>
        <Col md={6}>
          <CustomPhoneInput
            value={formStates?.phone}
            setter={(e) => handleFormStateUpdate('phone', e)}
            label={t('form.phone')}
            placeholder={t('form.phonePlaceholder')}
          />
        </Col>
        <Col md={12}>
          <Checkbox
            value={formStates?.allowTerms ? t('form.acceptTerms') : ''}
            setValue={(e) => handleFormStateUpdate('allowTerms', e?.length > 0)}
            label={t('form.acceptTerms')}
          />
        </Col>
        <Col md={12} className="j-center">
          <Button
            variant="secondary"
            type={'submit'}
            disabled={isSubmitting}
            onClick={() => {
              const body = {
                companyName: formStates?.companyName,
                firstName: formStates?.firstName,
                lastName: formStates?.lastName,
                email: formStates?.email,
                phoneNumber: formStates?.phone
              };
              for (let key in body) {
                if (!body[key]) {
                  return toast.error('Please fill all the fields');
                }
              }
              if (!validator.isEmail(body?.email)) {
                return toast.error('Please enter valid email');
              }
              if (
                !validator.isMobilePhone(
                  body?.phoneNumber?.startsWith('+') ? body?.phoneNumber : `+${body?.phoneNumber}`
                )
              ) {
                return toast.error('Please enter valid phone');
              }
              if (!formStates?.allowTerms) {
                return toast.error('Please accept terms and conditions');
              }
              onSubmit(body);
            }}>
            {isSubmitting ? t('form.btnTextLoading') : t('form.btnText')}
          </Button>
        </Col>
        <Col md={12} className="j-center">
          <p>
            {t('form.alreadyAMember') + ' '}
            <Link to={'#'} className="link">
              {t('form.login')}
            </Link>
          </p>
        </Col>
      </Row>
    </form>
  );
};

const DISABLED_PACKAGES = {
  basic: true,
  lite: true,
  plus: true,
  pro: true
};

function AgentRegisterationForm() {
  const { t } = useTranslation('advertiseWithUs');
  const [getPackages, { isLoading, data: packagesData }] = useLazyGetApiQuery();
  const [postApiFunc, { isLoading: isSubmitting }] = usePostApiMutation();
  const [selectedPackage, setSelectedPackage] = useState(null);
  // const [disablePackage, setDisabledPackage] = useState(DISABLED_PACKAGES);
  const [status, setStatus] = useState('Register Now');

  const { user } = useSelector((state) => state.authReducer);

  const handleSubmit = async (body) => {
    if (!selectedPackage) {
      return toast.error('Please select a package');
    }

    const response = await postApiFunc({
      url: `package/purchase/request`,
      method: 'POST',
      data: { ...body, packageId: selectedPackage?._id }
    });
    if (response?.data) {
      toast.success('Membership Request sent successfully');
    }
  };

  const getData = async () => {
    await getPackages({
      url: 'package/all'
    });
  };

  useEffect(() => {
    getData();
  }, []);

  console.log(t('sectionTwo.items[0]'));
  // Form States

  const keepInTouchData = {
    image: KeepInTouchSectionImage,
    list: [
      {
        title: t('section3.items.title1'),
        image: KeepInTouchIcon1
      },
      { title: t('section3.items.title2'), image: KeepInTouchIcon2 },
      { title: t('section3.items.title3'), image: KeepInTouchIcon3 },
      { title: t('section3.items.title4'), image: KeepInTouchIcon4 },
      { title: t('section3.items.title5'), image: KeepInTouchIcon5 }
    ]
  };
  const eventFeatures = [
    { title: t('section4.items.title1'), image: OpenHousesIcon },
    { title: t('section4.items.title2'), image: AuctionsIcon },
    { title: t('section4.items.title3'), image: NewProjectsIcon },
    { title: t('section4.items.title4'), image: ViewingToursIcon },
    { title: t('section4.items.title5'), image: SeminarsIcon }
  ];

  const powerfullTools = [
    { title: t('section2.items.title1'), image: PowerfullIcon1 },
    { title: t('section2.items.title2'), image: PowerfullIcon2 },
    { title: t('section2.items.title3'), image: PowerfullIcon3 },
    { title: t('section2.items.title4'), image: PowerfullIcon1 },
    { title: t('section2.items.title5'), image: PowerfullIcon5 },
    { title: t('section2.items.title6'), image: PowerfullIcon6 },
    { title: t('section2.items.title7'), image: PowerfullIcon7 },
    { title: t('section2.items.title8'), image: PowerfullIcon8 },
    { title: t('section2.items.title9'), image: PowerfullIcon1 }
  ];

  const getStatus = () => {
    const isExpired = user?.packageDetails?.isPackageExpired;

    if (isExpired === true) return setStatus('Renew');
    if (isExpired === false) return setStatus('Purchased');
  };

  // const packageDisabledFunc = () => {
  //   const type = user?.packageDetails?.packageType;

  //   if (!type) return;

  //   if (type === 'pro') return setDisabledPackage((prev) => ({ ...prev, pro: false }));

  //   if (type === 'plus')
  //     return setDisabledPackage((prev) => ({ ...prev, plus: false, pro: false }));

  //   if (type === 'lite')
  //     return setDisabledPackage((prev) => ({ ...prev, lite: false, plus: false, pro: false }));

  //   if (type === 'basic')
  //     return setDisabledPackage(() => ({ basic: false, lite: false, plus: false, pro: false }));
  // };

  useEffect(() => {
    // packageDisabledFunc();
    getStatus();
  }, []);

  return (
    <div className={classes.page}>
      <Header containerClass="" />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {/* Hero Section */}
          <section
            className={classes.heroSection}
            id="hero"
            // imageUrl={HomeHeroBgImg}
          >
            <Container>
              <Row className={classes.heroRow}>
                <Col
                  lg={{ span: 5, order: 1 }}
                  md={{ span: 12, order: 2 }}
                  sm={{ span: 12, order: 2 }}
                  xs={{ span: 12, order: 2 }}>
                  <RegisterationForm t={t} onSubmit={handleSubmit} isSubmitting={isSubmitting} />
                </Col>
                <Col
                  lg={{ span: 7, order: 2 }}
                  md={{ span: 12, order: 1 }}
                  sm={{ span: 12, order: 1 }}
                  xs={{ span: 12, order: 1 }}>
                  <div className={classes.heroContent}>
                    <div className={classes.logoDiv}>
                      <img src={WhiteLogo} draggable={false} />
                    </div>
                    <h3>{Parser(t('heroSection.title'))}</h3>
                    <ul>
                      <li>
                        <RxChevronRight size={18} color="var(--primary-clr)" />
                        {t('heroSection.list1')}
                      </li>
                      <li>
                        <RxChevronRight size={18} color="var(--primary-clr)" />
                        {t('heroSection.list2')}
                      </li>
                      <li>
                        <RxChevronRight size={18} color="var(--primary-clr)" />
                        {t('heroSection.list3')}
                      </li>
                    </ul>
                    {/* <Button className={classes.btn} leftIcon={<BsDownload />}>
                  Download Media Kit
                </Button> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Powerfull set of tools */}
          <section className={classes.powerfullSection}>
            <Container className={classes.bgImage}>
              <Row>
                <Col lg={6}>
                  <h2>{t('section2.title')}</h2>
                  <h5>{t('section2.tagline')}</h5>
                  <Row className={classes.powerFullItemRow}>
                    {powerfullTools?.map((item, index) => (
                      <Col lg={4} md={6} sm={6} xs={6} key={index}>
                        <div className={classes.powerFullItem}>
                          <div className={classes.imgDiv}>
                            <img src={item?.image} />
                          </div>
                          <p>{item?.title}</p>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Keep In Touch */}
          <section className={classes.keepInTouchSection}>
            <Container>
              <Row>
                <Col md={6}>
                  <div className={classes.img}>
                    <img src={keepInTouchData?.image} />
                  </div>
                </Col>
                <Col md={6}>
                  <HeadingWithUnderline underlineColor="var(--secondary-clr)">
                    <h3>{t('section3.title')}</h3>
                  </HeadingWithUnderline>
                  <ul>
                    {keepInTouchData?.list?.map((item, index) => (
                      <li key={index}>
                        <div className={classes.mainImgDiv}>
                          <div className={classes.imgDiv}>
                            <img src={item?.image} />
                          </div>
                        </div>
                        <p>{item?.title}</p>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Events Management */}
          <section className={classes.eventsManagementSection}>
            <Container>
              <Row className={classes.eventsRow}>
                <Col md={12}>
                  <h2>{t('section4.title')}</h2>
                  <h5>{t('section4.tagline')}</h5>
                </Col>
                <Col md={12}>
                  <div className={classes.eventsFeaturesDiv}>
                    {eventFeatures?.map((item, index) => {
                      return (
                        <div className={classes.featureMainDiv} key={index}>
                          <div className={classes.circleDiv}>
                            <img src={item?.image} />
                          </div>
                          <p>{item?.title}</p>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Packages Section */}
          <section className={classes.packagesSection}>
            <Container>
              <Row className={classes.packagesRow}>
                <Col md={12} className={classes.headings}>
                  <h2>{t('section5.title')}</h2>
                  <h5>{t('section5.tagline')}</h5>
                </Col>

                {console.log('🚀 ~ {packagesData?.data?.map ~ item:', user?.packageDetails)}

                {packagesData?.data?.map((item, index) => {
                  return (
                    <Col xl={3} md={6} key={index}>
                      <PackageCard
                        selected={selectedPackage?._id == item?._id}
                        status={status}
                        // disablePackage={disablePackage}
                        packageType={user?.packageDetails?.packageType || ''}
                        data={item}
                        color={
                          (index == 0 && '#A4C235') ||
                          (index == 1 && '#9A5582') ||
                          (index == 2 && '#F6C43D') ||
                          (index == 3 && '#00A897')
                        }
                        onRegister={() => {
                          if (status === 'purchased')
                            return toast.error('Package already purchased');

                          setSelectedPackage(item);
                          document.getElementById('hero').scrollIntoView({ block: 'start' });
                        }}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </section>
        </>
      )}

      <Footer />
    </div>
  );
}

export default AgentRegisterationForm;
