/*eslint-disable*/
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import RenewPackage from '../Component/RenewPackage/RenewPackage';

function ProtectedRoute({ element: Element, allowedRole }) {
  const { isLogin, user } = useSelector((state) => state.authReducer);
  const location = useLocation();
  const packageRestrictedRoutes = [
    {
      role: 'company',
      route: '/company/property-requests',
      allow: user?.packageDetails?.receivePropertyRequests
    },
    {
      role: 'agent',
      route: '/agent/property-requests',
      allow: user?.company?.packageDetails?.receivePropertyRequests
    }
  ];

  function redirectOnRoute() {
    let condition = true;
    if (isLogin) {
      if (['company', 'agent'].includes(user?.role)) {
        condition = true;
        if (!allowedRole?.includes(user?.role)) {
          condition = false;
        }
        // If the Company Package Does not allow to receive property requests
        if (
          ![undefined, true].includes(
            packageRestrictedRoutes.find(
              (e) => e.role == user?.role && e.route == location.pathname
            )?.allow
          )
        ) {
          condition = false;
        }
        // If the Company Package Does not allow to receive property requests
      }
    } else if (allowedRole?.includes(user?.role)) {
      condition = true;
    } else {
      condition = false;
    }
    return condition;
  }

  console.log('user');

  return (
    <>
      {redirectOnRoute() ? (
        user?.packageDetails?.isPackageExpired ? (
          <RenewPackage />
        ) : (
          <Outlet />
        )
      ) : (
        <Navigate replace to="/" />
      )}
    </>
  );
}

export default ProtectedRoute;
