import React from 'react'
import classes from './headingWithUnderline.module.css'
import styled from 'styled-components'

function HeadingWithUnderline({ children, alignUnderline='left', underlineColor = 'var(--primary-clr)',className='' }) {
    const StyledUnderline = styled.div`
    ${alignUnderline =='left' && `padding-inline-start:10px;`}
    position:relative;
    ${alignUnderline=='center' && 'width:max-content;margin:0 auto;margin-bottom:20px;'}
    

    &::after{
        position:absolute;
        ${alignUnderline =='center' ? 'bottom:-7px':'top:0px' };
        ${alignUnderline =='center' ? 'left:50%;':'inset-inline-start:0px;' };
        height:${alignUnderline =='center' ? '4px':'100%' };
        width:${alignUnderline =='center' ? '95px':'4px' };
        content:'';
        background-color:${underlineColor};
        transform:translateX(-50%)
    }
    `
    return (
        <StyledUnderline className={[classes.main,className].join(' ')}>
            {children}
        </StyledUnderline>
    )
}

export default HeadingWithUnderline