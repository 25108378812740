import React from 'react'
import classes from './listItemWithCheckMark.module.css'
import { ImCheckmark } from 'react-icons/im'

function ListItemWithCheckMark({ textClass = '', mainClass = '', children, showCheck = true }) {
    return (
        <div className={[classes.listWithCheck, mainClass].join(' ')}>
            {showCheck && <ImCheckmark />}
            <span className={[classes.text, textClass].join(' ')}>{children}</span>
        </div>
    )
}

export default ListItemWithCheckMark