/*eslint-disable*/

import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BsGrid3X3GapFill, BsListStars } from 'react-icons/bs';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { IoBookmarkOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import BannerImageAd from '../../Component/BannerImageAd';
import EventCard from '../../Component/EventCard';
import EventFilters from '../../Component/EventFilters';
import EventHorizontalCard from '../../Component/EventHorizontalCard/EventHorizontalCard';
import Footer from '../../Component/Footer';
import Header from '../../Component/Header';
import { Loader } from '../../Component/Loader';
import NoData from '../../Component/NoData/NoData';
import { PaginationComponent } from '../../Component/Pagination/Pagination';
import Tabs from '../../Component/Tabs';
import Tooltip from '../../Component/Tooltip';
import MapMain from '../../Component/maps/MapMain';
import { isMobileViewHook } from '../../CustomHooks/isMobileViewHook';
import { imageUrl, recordsLimit, showTabsWhenUserIsLogin } from '../../config/apiUrl';
import { horizontalBanner, verticalBanner } from '../../constant/imagePath';
import EmailAgentModal from '../../modals/EmailAgentModal';
import { useGetPublicEventsMutation } from '../../store/apiSlice/apiSlice';
import classes from './newEvents.module.css';

// const tabsData = [
//   { icon: <BsGrid3X3GapFill size={20} color="var(--white-clr)" />, text: 'grid', id: '1' },
//   { icon: <BsListStars size={22} color="var(--white-clr)" />, text: 'list', id: '2' },
//   { icon: <IoBookmarkOutline size={20} color="var(--white-clr)" />, text: 'save search', id: '3' }
// ];

function NewEvents() {
  const { t } = useTranslation('events');
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const [isMobile, setIsMobile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState('');
  const { isLogin } = useSelector((state) => state.authReducer);
  const [getEvents, { isLoading, data }] = useGetPublicEventsMutation();
  const [page, setPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filters, setFilters] = useState({
  
  });

  const routeName = useLocation()?.pathname?.split('/')[1];
  const { allBanners } = useSelector((state) => state.commonReducer);

  const verticalBanners = allBanners?.filter(
    (item) => item?.page === routeName && item?.bannerType == 'vertical'
  );

  const horizontalBanners = allBanners?.filter(
    (item) => item?.page === routeName && item?.bannerType == 'horizontal'
  );

  const handleGetEvents = async (fil, pg = page) => {
    await getEvents({
      page: pg,
      limit: recordsLimit,
      data: {
        lan: selectedLanguage,
        ...(fil ? fil : {})
      }
    });
  };

  useEffect(() => {
    handleGetEvents();
  }, []);

  const saveSearchTab = showTabsWhenUserIsLogin(isLogin, [
    {
      icon: (
        <Tooltip text={t('tabs.saveSearch')}>
          <IoBookmarkOutline size={20} color="var(--white-clr)" />
        </Tooltip>
      ),
      text: t('tabs.saveSearch'),
      value: 'save search',
      id: '4'
    }
  ]);

  const tabsData = isMobile
    ? [
        {
          icon: (
            <Tooltip text={'Map'}>
              <FaMapMarkedAlt size={22} color="var(--white-clr)" />
            </Tooltip>
          ),
          value: 'map',
          text: t('tabs.map'),
          id: '3'
        },
        ...saveSearchTab
      ]
    : [
        {
          icon: <BsGrid3X3GapFill size={20} color="var(--white-clr)" />,
          text: t('tabs.grid'),
          value: 'grid',
          id: '1'
        },
        {
          icon: <BsListStars size={22} color="var(--white-clr)" />,
          text: t('tabs.list'),
          value: 'list',
          id: '2'
        },
        {
          icon: <FaMapMarkedAlt size={22} color="var(--white-clr)" />,
          value: 'map',
          text: t('tabs.map'),
          id: '3'
        },
        ...saveSearchTab
      ];
  const [selectedTab, setSelectedTab] = useState(tabsData[1]);
  useEffect(() => {
    // setData(eventsData);
    isMobileViewHook(setIsMobile, 992);
  }, []);
  useEffect(() => {
    if (isMobile) {
      setSelectedTab({ text: 'list', id: '2' });
    }
  }, [isMobile]);

  function onSearch(fill) {
    const pg = 1;
    
    setPage(pg);
    handleGetEvents(fill, pg);
  }
  return (
    <div className={classes.page}>
      <Header containerClass="" />
      <div className={classes.filtersDiv}>
        <EventFilters
          containerClass=""
          showOneLine
          filtersWrapperClass={classes.filters}
          onSearch={onSearch}
          t={t}
        />
      </div>
      <div className={classes.tabsDiv}>
        <Container className="">
          <p>
            {data?.totalRecords ?? 0} {t('tabs.title')}
          </p>
          <div>
            <Tabs
              selectedColor="var(--secondary-clr)"
              bgColor="var(--primary-clr)"
              separatorColor="var(--white-clr)"
              tabClass={classes.tab}
              setValue={(e) => {
                if (e?.value == 'save search') {
                  return;
                }
                setSelectedTab(e);
              }}
              value={selectedTab}
              tabsData={tabsData}
            />
          </div>
        </Container>
      </div>
      <Container className={classes?.contentContainer}>
        <Row className={classes.mainRow}>
          <Col
            md={selectedTab?.value == 'map' ? 12 : 10}
            className={[
              selectedTab?.value !== 'map' && 'verticalBannerAndContentWidth',
              classes.col1
            ].join(' ')}>
            {isLoading ? (
              <Loader className={classes?.loader} />
            ) : (
              <Row
                className={[classes.innerRow, selectedTab?.value == 'map' && classes.h100].join(
                  ' '
                )}>
                {selectedTab?.value == 'map' ? (
                  <>
                    <Col
                      xl={{ span: 3, order: 1 }}
                      xs={{ span: 12, order: 2 }}
                      className={`scroller ${classes.mapLisingCards}`}>
                      <Row className={classes.mapList}>
                        {data?.data?.slice(0, 9)?.map((item, key) => (
                          <Col xl={12} lg={4} sm={6} key={key}>
                            <EventCard
                              data={item}
                              onEmailClick={() => {
                                setIsModalOpen('email');
                              }}
                              refetch={handleGetEvents}
                            />
                          </Col>
                        ))}
                      </Row>
                    </Col>
                    <Col xl={{ span: 9, order: 2 }} xs={{ span: 12, order: 1 }}>
                      <MapMain className={classes.mainMap} mapClass={classes?.mapClass} zoom={13} />
                    </Col>
                  </>
                ) : data?.data?.length == 0 ? (
                  <NoData text={t('noDataText')} />
                ) : (
                  data?.data?.map((item, key) => (
                    <>
                      <Col
                        md={selectedTab?.value == 'list' ? 12 : 6}
                        lg={selectedTab?.value == 'list' ? 12 : 4}
                        sm={12}
                        key={key}>
                        {selectedTab?.value == 'list' ? (
                          <EventHorizontalCard
                            data={item}
                            index={key}
                            className={'mb-0'}
                            onEmailClick={() => {
                              setSelectedItem(item);
                              setIsModalOpen('email');
                            }}
                            refetch={handleGetEvents}
                          />
                        ) : (
                          <EventCard
                            data={item}
                            onEmailClick={() => {
                              setSelectedItem(item);
                              setIsModalOpen('email');
                            }}
                            refetch={handleGetEvents}
                          />
                        )}
                      </Col>
                      {(selectedTab?.value == 'list'
                        ? (key + 1) % 7 === 0
                        : (key + 1) % 9 === 0) && (
                        <Col md={12}>
                          <div className={classes?.bannerAdContainer}>
                            <div className={classes?.bannerAdContainer}>
                              {horizontalBanners[(page - 1) % horizontalBanners?.length]?.image && (
                                <BannerImageAd
                                  image={imageUrl(
                                    horizontalBanners[(page - 1) % horizontalBanners?.length]?.image
                                  )}
                                  className={classes.horizontalBanner}
                                />
                              )}
                            </div>
                          </div>
                        </Col>
                      )}
                    </>
                  ))
                )}

                {['list', 'grid'].includes(selectedTab?.value) && !!data?.data?.length && (
                  <Col md={12} className="jCenter">
                    <PaginationComponent
                      totalPages={Math.ceil(data?.totalRecords / recordsLimit)}
                      setCurrentPage={(e) => {
                        setPage(e);
                        handleGetEvents(filters, e);
                      }}
                      currentPage={page}
                    />
                  </Col>
                )}
              </Row>
            )}
          </Col>
          <Col
            md={2}
            className={[
              selectedTab?.value == 'map' && 'd-none',
              'verticalBannerWidth',
              classes.col2
            ].join(' ')}>
            <Row className={`${classes.bannerRow}`}>
              {!isMobile &&
                verticalBanners?.map((item, key) => (
                  <Col key={key} md={12} className={key == 1 && classes?.bannerCol}>
                    <BannerImageAd
                      image={imageUrl(item?.image)}
                      className={classes.verticalBanner}
                    />
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
      <EmailAgentModal
        show={isModalOpen == 'email'}
        setShow={setIsModalOpen}
        data={selectedItem}
        type={'event'}
      />
    </div>
  );
}

export default NewEvents;
