/* eslint-disable */

import React from 'react';
import ModalSkeleton from '../ModalSkeleton';
import classes from './ContactSales.module.css';
import { Col, Row } from 'react-bootstrap';
import { MdEmail } from 'react-icons/md';
import { IoMdCall } from 'react-icons/io';
import { RiWhatsappFill } from 'react-icons/ri';
import { Contact } from '../../constant/imagePath';
const ContactSales = ({ show, setShow, t }) => {
  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      modalClass={classes.main}
      borderRadius={'0px'}
      width={'1200px'}
      header={true}
      headerClass={classes.header}>
      <div className={classes.main}>
        <Row className={[classes.row, 'g-0'].join(' ')}>
          <Col md={6} className={classes.col1}>
            <h4>{t('contactSalesModal.title')}</h4>
            <p>{t('contactSalesModal.description')}</p>
            <div className={classes.iconsWrap}>
              <div>
                <div className={classes.icon}>
                  <MdEmail size={30} fill="#FFFF" />
                </div>
                <p> {t('contactSalesModal.emailUs')}</p>
              </div>
              <div>
                <div className={classes.icon}>
                  <IoMdCall size={30} fill="#FFFFFF" />
                </div>
                <p>{t('contactSalesModal.callUs')}</p>
              </div>
              <div>
                <div className={classes.icon}>
                  <RiWhatsappFill size={30} fill="#FFFFFF" />
                </div>
                <p>{t('contactSalesModal.whatsappUs')}</p>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className={classes.sideImage}>
              <img src={Contact} alt="" />
            </div>
          </Col>
        </Row>
        {/* <div className={classes.footer}></div> */}
      </div>
    </ModalSkeleton>
  );
};

export default ContactSales;
