import React from 'react';
import classes from './PropertyCard.module.css';
import { BsGrid3X3Gap } from 'react-icons/bs';
import { BiTransfer } from 'react-icons/bi';
import {
  // MdDateRange,
  MdLocationOn,
  MdOutlineBathtub,
  MdOutlineHotel,
  MdWifiCalling3
} from 'react-icons/md';
import { RiHotelFill } from 'react-icons/ri';
// import { GoMail } from 'react-icons/go';
import { IoIosMail, IoLogoWhatsapp } from 'react-icons/io';

import { Col, Row } from 'react-bootstrap';
// import Carousel from 'react-elastic-carousel';
import ElasticCarousel from '../ElasticCarousel';
import { useNavigate } from 'react-router-dom';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import Tooltip from '../Tooltip';
import IconList from '../IconList/IconList';
import { useDispatch, useSelector } from 'react-redux';
import { imageUrl } from '../../config/apiUrl';
import { useTranslation } from 'react-i18next';
import { propertiesData } from '../../constant/commonData';
import { restrictOnlyUserActions } from '../../Helper/restrictActions';
import { useFavoriteEntityMutation, usePostApiMutation } from '../../store/apiSlice/apiSlice';
import { toast } from 'react-toastify';
import { updateUser } from '../../store/auth/authSlice';
import { m2ToFt2, usdToOther } from '../../utils/helpers';

/*eslint-disable*/

const PropertyCard = ({
  data = propertiesData[0],
  onEmailClick = () => null,
  setPropertyData,
  refetch
}) => {
  const { t } = useTranslation('webCommon');
  const { isLogin, user } = useSelector((state) => state.authReducer);
  const [postApiFunc] = usePostApiMutation();

  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const { areaUnit, currency } = useSelector((state) => state.converterReducer);

  const feature = data?.isPremium || data?.isFeatured;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [isFavorite, setIsFavorite] = useState(data?.favoriteBy?.includes(user?._id));
  const [onFavorite, { isLoading: isFavoriting }] = useFavoriteEntityMutation();

  const isFavorite = data?.favoriteBy?.includes(user?._id);

  const handleCompare = async (data) => {
    const onlyCompareProperty = user?.compareList?.filter((item) => item?.type == 'Property');
    const ifAlreadyExist = onlyCompareProperty?.find((item) => item?.entity == data?._id);
    if (!ifAlreadyExist && onlyCompareProperty?.length >= 3)
      return toast.error('You can only compare 3 properties at a time');

    const response = await postApiFunc({
      url: `users/compare/add`,
      method: 'POST',
      tag: `compareListFront`,
      data: {
        id: data?._id,
        type: 'property'
      }
    });
    if (response?.data) {
      dispatch(updateUser(response?.data?.data));
      toast.success(
        `Property ${ifAlreadyExist ? 'removed from' : 'added in'} compare list successfully`
      );
    }
  };

  const handleFavorite = async () => {
    if (isFavoriting) return;
    const res = await onFavorite({ id: data?._id, type: 'property' });
    refetch && refetch();
    if (res?.data) {
      if (res?.data?.data?.favoriteBy?.includes(user?._id)) {
        toast.success(`${'Property added in favorite list'} successfully`);
      } else {
        toast.success(`${'Property removed from favorite list'} successfully`);
      }
    }
  };

  const handleAction = () => {
    const isAllowedActions = restrictOnlyUserActions(user?.role);
    if (!isLogin) {
      navigate('/login');
    } else if (isAllowedActions) {
      return true;
    }
  };

  return (
    <>
      <div className={classes.cardMain}>
        <div className={classes.image}>
          <ElasticCarousel
            navigateLink={`/property/${data?.slug ?? '1'}`}
            images={data?.images ?? []}
          />
          {!feature ? (
            <div className={classes.socialIconsWrap}>
              <Tooltip text={t('propertyCard.tooltips.call')}>
                <div className={classes.socialIcons}>
                  <MdWifiCalling3 fill="#E2831B" size={20} />
                </div>
              </Tooltip>
              <Tooltip text={t('propertyCard.tooltips.email')}>
                <div
                  className={classes.socialIcons}
                  onClick={(e) => {
                    e?.stopPropagation();
                    onEmailClick && onEmailClick();
                  }}>
                  {/* <GoMail fill="#E2831B" size={20} /> */}
                  <IoIosMail fill="#E2831B" size={20} />
                </div>
              </Tooltip>
            </div>
          ) : (
            <>
              <div className={classes?.featuredPremiumContainer}>
                <span
                  className={`${
                    (data?.isFeatured && classes?.featuredIco) ||
                    (data?.isPremium && classes?.premiumIco)
                  }`}>
                  {(data?.isPremium && t('projectCard.premium')) ||
                    (data?.isFeatured && t('projectCard.featured'))}
                </span>
              </div>
            </>
            // <div className={classes.featureTag}>{t('propertyCard.featured')}</div>
          )}
        </div>
        <div
          className={classes.contentWrap}
          onClick={() => navigate(`/property/${data?.slug ?? '1'}`)}>
          <div className={classes.content}>
            {/* {!feature ? ( */}
            <div className={classes.priceRow}>
              {/* {!feature ? ( */}
              <div className={classes.price}>
                <p>{usdToOther({ amount: data?.price, ...currency })}</p>
                <p>{data?.propertyPurpose?.en == 'Rent' && t('propertyCard.perMonth')}</p>
              </div>
              {/* ) : ( */}
              {/* <div className={classes.price1}>
                  <p>$ {data?.priceRange}</p>
                </div> */}
              {/* )} */}
              <div className={classes.iconWrap}>
                <Tooltip text={t('propertyCard.tooltips.compare')}>
                  <BiTransfer
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!handleAction()) return;
                      handleCompare(data);
                    }}
                    size={25}
                    fill={`${
                      user?.compareList?.find((item) => item?.entity == data?._id)
                        ? 'var(--action-icon-clr)'
                        : 'var(--text-light-gray-clr)'
                    } `}
                  />
                </Tooltip>
                <Tooltip
                  text={
                    isFavorite
                      ? t('propertyCard.tooltips.removeFavorite')
                      : t('propertyCard.tooltips.addFavorite')
                  }>
                  {isFavorite ? (
                    <AiFillHeart
                      onClick={(e) => {
                        e.stopPropagation();
                        handleFavorite();
                      }}
                      fill="var(--action-icon-clr)"
                      size={25}
                    />
                  ) : (
                    <AiOutlineHeart
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!handleAction()) return;
                        handleFavorite();
                      }}
                      fill="var(--text-light-gray-clr)"
                      size={25}
                    />
                  )}
                </Tooltip>
              </div>
            </div>

            <div className={classes.description}>
              <p>{data?.title?.[selectedLanguage]}</p>
            </div>
            <div className={classes.addressWrap}>
              <MdLocationOn fill="var(--text-dark-gray-clr)" />
              <p>{data?.address}</p>
            </div>

            <div className={classes.propertyDetailWrap}>
              <Row className={classes.gap}>
                <Col md={6} className={classes.propertyDetail}>
                  {/* <RiHotelFill color="var(--text-dark-gray-clr)" /> */}
                  {/* <p>{data?.propertyType}</p> */}
                  <IconList
                    className={classes.infoIcons}
                    Icon={RiHotelFill}
                    value={data?.propertyType?.title?.[selectedLanguage]}
                    label={t('propertyCard.tooltips.propertyType')}
                  />
                </Col>
                <Col md={6} className={classes.propertyDetail}>
                  {/* <BsGrid3X3Gap color="var(--text-dark-gray-clr)" />
                    <p>{data?.propertyArea} sq ft</p> */}
                  <IconList
                    className={classes.infoIcons}
                    Icon={BsGrid3X3Gap}
                    value={`${m2ToFt2({ area: data?.netArea, areaUnit, unit: false })}`}
                    label={t('propertyCard.tooltips.area') + ` ${areaUnit}`}
                  />
                </Col>
                <Col md={6} className={classes.propertyDetail}>
                  {/* <MdOutlineHotel color="var(--text-dark-gray-clr)" /> */}
                  {/* <p>{data?.noOfBedroooms} Bedrooms</p> */}
                  <IconList
                    className={classes.infoIcons}
                    Icon={MdOutlineHotel}
                    value={data?.noOfRooms?.[selectedLanguage]}
                    label={t('propertyCard.tooltips.rooms')}
                  />
                </Col>
                <Col md={6} className={classes.propertyDetail}>
                  {/* <MdOutlineBathtub color="var(--text-dark-gray-clr)" /> */}
                  {/* <p>{data?.noOfBathrooms} Bathrooms</p> */}
                  <IconList
                    className={classes.infoIcons}
                    Icon={MdOutlineBathtub}
                    value={data?.noOfBathrooms}
                    label={t('propertyCard.tooltips.bathrooms')}
                  />
                </Col>
              </Row>
            </div>
          </div>
          {!feature ? (
            <div
              className={[classes?.companyLogoCenter].join(' ')}
              onClick={(e) => {
                e?.stopPropagation();
                navigate(`/company/${data?.company?.slug}`);
              }}>
              <img src={imageUrl(data?.company?.photo)} />
            </div>
          ) : (
            <div className={classes.key1}>
              <div className={classes.socialIconsWrap1}>
                <a className={classes.socialIcons1} href={`tel:${data?.phoneNumber}`}>
                  <Tooltip text={t('propertyCard.tooltips.call')}>
                    <MdWifiCalling3 fill="#E2831B" size={25} />
                  </Tooltip>
                </a>
                <div
                  className={[classes.socialIcons1, classes.midIcon].join(' ')}
                  onClick={(e) => {
                    e?.stopPropagation();
                    onEmailClick && onEmailClick();
                  }}>
                  <Tooltip text={t('propertyCard.tooltips.email')}>
                    <IoIosMail fill="#E2831B" size={28} />
                  </Tooltip>
                </div>
                <a
                  href={`https://wa.me/${data?.whatsapp}`}
                  target={'_blank'}
                  className={classes.socialIcons1}>
                  <Tooltip text={t('propertyCard.tooltips.whatsapp')}>
                    <IoLogoWhatsapp fill="#E2831B" size={25} />
                  </Tooltip>
                </a>
              </div>
              <div
                className={classes?.companyLogo}
                onClick={(e) => {
                  e?.stopPropagation();
                  navigate(`/company/${data?.company?.slug}`);
                }}>
                <img src={imageUrl(data?.company?.photo)} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PropertyCard;
