import { toast } from 'react-toastify';

export const allowLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          // User has granted location access
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          // Use the user's location data
          resolve({ latitude, longitude });
        },
        function (error) {
          if (error.PERMISSION_DENIED) {
            toast.error('Please allow the location.');
          }
          reject();
        }
      );
    } else {
      toast.error('Geolocation is not supported by this browser.');
      reject();
    }
  });
};
