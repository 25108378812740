import React from 'react';

import classes from './IconList.module.css';
import Tooltip from '../Tooltip';

const IconList = ({ Icon, value, label, className,iconClass="" ,iconSize=20}) => {
  return (
    <div className={`${classes?.mainContainer} ${className && className}`}>
      <Tooltip text={label}>
        <Icon className={iconClass} size={iconSize} color={'var(--text-dark-gray-clr)'} />
      </Tooltip>
      <span>{value} </span>
    </div>
  );
};

export default IconList;
