import React from 'react';
import classes from './AgentCard.module.css';
import { useNavigate } from 'react-router-dom';
import { imageUrl } from '../../config/apiUrl';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const AgentCard = ({ data, onClick }) => {
  const { t } = useTranslation('webCommon');
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const navigate = useNavigate();
  return (
    <div className={classes?.cardContainer} onClick={onClick?.bind(this, data)}>
      {/* top */}
      <div
        className={classes?.topStrip}
        onClick={(e) => {
          e?.stopPropagation();
          navigate(`/company/${data?.company?.slug}`);
        }}>
        <div className={classes?.imageContainer}>
          <img src={imageUrl(data?.company?.photo)} />
        </div>
        <div className={classes?.topStripInfo}>
          <p>{data?.company?.companyName?.[selectedLanguage]}</p>
          <p>{data?.company?.companyType?.[selectedLanguage]}</p>
        </div>
      </div>

      {/* agent Image */}
      <div className={classes?.agentImageContainer}>
        <img src={imageUrl(data?.photo)} />
      </div>

      {/* name and designation */}
      <div className={classes?.nameAndDesignation}>
        <p>{data?.agentName?.[selectedLanguage]}</p>
        <p>{data?.agentDesignation?.[0]?.[selectedLanguage]}</p>
      </div>

      {/* PropertiesInfo */}
      <div className={classes?.propertiesInfo}>
        <p className={classes?.paraHeading}>{t('agentCard.properties')}</p>
        <div className={classes?.propertyCountInfo}>
          <p>
            <span>{data?.rentProperties ?? 0}</span>
            {t('agentCard.forRent')}
          </p>
          <p>
            <span>{data?.buyProperties ?? 0}</span>
            {/* for Sale (We have no sale in property) */}
            {t('agentCard.forBuy')}
          </p>
        </div>
      </div>

      {/* Languages */}
      <div className={classes?.languagesInfo}>
        <p className={classes?.paraHeading}>{t('agentCard.weSpeak')}</p>
        <p>{data?.language?.map((e) => e?.[selectedLanguage])?.join(', ')}</p>
      </div>
    </div>
  );
};

export default AgentCard;
