import React from 'react';
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';
import TestPano from '../../assets/images/Test_Pano.jpg';
import { useMemo } from 'react';

// import classes from './PanoramaView.module.css';

const PanoramaView = ({threeSixtyImage=TestPano}) => {
  const photoSphereRef = React.useRef();
  const image=useMemo(()=>threeSixtyImage,[threeSixtyImage])

  const handleClick = () => {
    photoSphereRef.current.animate({
      zoom: 0,
      speed: '10rpm',
      littlePlanet: true,
      defaultZoomLvl: 0,
      sphereCorrection: {
        pan: 0,
        tilt: 0,
        roll: 0
      }
    });
  };
  return (
    <ReactPhotoSphereViewer
      ref={photoSphereRef}
      src={image}
      height={'100%'}
      width={'100%'}
      onClick={handleClick}></ReactPhotoSphereViewer>
  );
};

export default PanoramaView;
