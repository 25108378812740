import React from 'react';
import ModalSkeleton from '../ModalSkeleton';
import classes from './AgentsMoreFiltersModal.module.css';
// import Button from '../../Component/Button/Button';
import { Col, Row } from 'react-bootstrap';
import Button from '../../Component/Button/Button';

const AgentsMoreFiltersModal = ({
  show,
  setShow,
  // onClick, isLoading,
  children
}) => {
  return (
    <ModalSkeleton
      width={'600px'}
      show={show}
      setShow={setShow}
      // header={'More Filters'}
      modalClass={classes.modal}>
      <Row className={classes.row}>
        {children}
        <Col md={12}>
          <Button className={classes.btn}>Apply</Button>
        </Col>
        
      </Row>
    </ModalSkeleton>
  );
};

export default AgentsMoreFiltersModal;
