/*eslint-disable*/
import React, { useState } from 'react';
import classes from './PropertyRequests.module.css';
import SideBarSkeleton from '../../../Component/SideBarSkeleton';
import { Col, Container, Row } from 'react-bootstrap';
import CustomMaterialTable from '../../../Component/CustomMaterialTable';
import { AiFillEye } from 'react-icons/ai';
import SearchInput from '../../../Component/SearchInput';
import { recordsLimit } from '../../../config/apiUrl';
import Button from '../../../Component/Button/Button';
import useDebounce from '../../../CustomHooks/useDebounce';
import {
  useDeletePropertyRequestsMutation,
  useGetAllPropertyRequestsQuery
} from '../../../store/apiSlice/apiSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import PropertyRequestDetailModal from '../../../modals/PropertyRequestDetailModal';

const tableData = Array(10)
  .fill({
    id: '5645',
    date: '2020.11.12',
    fullName: 'John Smith',
    email: 'abc@gmail.com',
    phoneNo: '2328902183',
    budget: '1000'
  })
  ?.map((item) => ({ ...item, id: Math.floor(Math.random() * 10000) }));

function PropertyRequests() {
  const { t } = useTranslation('dashboardPropertyRequests');
  const headings = [
    { id: 'id', label: t('table.col1'), renderValue: (_, __, i) => i + 1 },
    { id: 'fullName', label: t('table.col2') },
    { id: 'email', label: t('table.col3') },
    { id: 'phoneNo', label: t('table.col4'), renderValue: (e) => e?.phoneNumber },
    { id: 'budget', label: t('table.col5'), renderValue: (e) => `$${e?.budget}` },
    { id: 'options', label: t('table.col6'), align: 'center' }
  ];
  const [searchText, setSearchText] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [limit, setLimit] = useState(recordsLimit);
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);

  const debounceSearch = useDebounce(searchText, 500);
  const { data, isLoading, isFetching } = useGetAllPropertyRequestsQuery({
    limit: recordsLimit,
    page: page,
    search: debounceSearch
  });
  const [deleteRecordsApi, { isLoading: isDeleting }] = useDeletePropertyRequestsMutation();
  //  delete api
  const deleteMultiple = async () => {
    const res = await deleteRecordsApi({ ids: selectedRows?.map((item) => item._id) });
    if (res?.data) {
      toast.success(`Property Request${selectedRows?.length > 1 ? 's' : ''} deleted successfully.`);
      setSelectedRows([]);
    }
  };

  return (
    <SideBarSkeleton>
      <Container fluid className={classes.page}>
        <Row>
          <Col md={12} className={classes.mb58}>
            <div className={classes.header}>
              <div>
                <SearchInput
                  placeholder={t('searchByName')}
                  setter={setSearchText}
                  state={searchText}
                  variant="dashboard"
                />
              </div>
              <Button
                borderRadius={'8px'}
                className={classes.deleteBtn}
                disabled={selectedRows?.length == 0 || isDeleting}
                onClick={deleteMultiple}>
                {isDeleting ? t('deleting') : t('delete')}
              </Button>
            </div>
          </Col>
          <Col md={12}>
            <h5 className={classes.heading}>{t('title')}</h5>
          </Col>
          <Col md={12}>
            <CustomMaterialTable
              headings={headings}
              data={data?.data}
              page={page}
              setPage={setPage}
              isLoading={isLoading || isFetching}
              showOptionsColumn
              options={[
                {
                  label: t('table.options.view'),
                  icon: <AiFillEye size={18} />,
                  onClick: (e, a) => {
                    setSelectedItem(a);
                    setShowModal('detail');
                  }
                }
              ]}
              totalRecords={Math.ceil(data?.totalRecords / recordsLimit)}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              allowMultipleSelection
              rowsPerPage={limit}
              setRowsPerPage={setLimit}
            />
          </Col>
        </Row>

        {showModal == 'detail' && (
          <PropertyRequestDetailModal
            data={selectedItem}
            show={showModal == 'detail'}
            setShow={setShowModal}
          />
        )}
      </Container>
    </SideBarSkeleton>
  );
}

export default PropertyRequests;
