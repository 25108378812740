import { ReactSVG } from 'react-svg';
import {
  ArabicLanguage,
  DashdBoardIcon,
  EnglishLanguage,
  EventsManagement,
  FrenchLanguage,
  GermanLanguage,
  PersianLanguage,
  ProjectsManagement,
  PropertiesManagment,
  PropertyRequest,
  RussianLanguage,
  SettingsIcon,
  SubscribedUsers,
  TurkishLanguage,
  houseAd
} from './imagePath';
import { FaUsers } from 'react-icons/fa';
import { convertArrayKeysToObject } from '../config/apiUrl';

// Sidebar Options
export const decideSidebarOptions = (role = 'agent', user) => {
  const options = [
    ...(role == 'agent'
      ? [
          {
            icon: <ReactSVG src={DashdBoardIcon} />,
            title: {
              en: 'Dashboard',
              fa: 'داشبورد',
              tr: 'Gösterge Paneli',
              ar: 'لوحة القيادة',
              ru: 'Панель приборов',
              de: 'Instrumententafel',
              fr: 'Tableau de bord'
            },
            path: '/agent/dashboard'
          },
          {
            icon: <ReactSVG src={PropertiesManagment} />,
            title: {
              en: 'Properties Management',
              fa: 'مدیریت املاک',
              tr: 'Özellikler Yönetimi',
              ar: 'إدارة الخصائص',
              ru: 'Управление свойствами',
              de: 'Eigenschaftenverwaltung',
              fr: 'Gestion des propriétés'
            },
            path: '/agent/properties-management'
          },
          {
            icon: <ReactSVG src={ProjectsManagement} />,
            title: {
              en: 'Projects Management',
              fa: 'مدیریت پروژه‌ها',
              tr: 'Projeler Yönetimi',
              ar: 'إدارة المشاريع',
              ru: 'Управление проектами',
              de: 'Projektmanagement',
              fr: 'Gestion de projet'
            },
            path: '/agent/projects-management'
          },
          {
            icon: <ReactSVG src={EventsManagement} />,
            title: {
              en: 'Events Management',
              fa: 'مدیریت رویدادها',
              tr: 'Etkinlikler Yönetimi',
              ar: 'إدارة الأحداث',
              ru: 'Управление событиями',
              de: 'Veranstaltungsmanagement',
              fr: 'Gestion des événements'
            },
            path: '/agent/events-management'
          },
          ...(user?.company?.packageDetails?.receivePropertyRequests
            ? [
                {
                  icon: <ReactSVG src={PropertyRequest} />,
                  title: {
                    en: 'Property Requests',
                    fa: 'درخواست‌های املاک',
                    tr: 'Mülk Talepleri',
                    ar: 'طلبات الملكية',
                    ru: 'Запросы на собственность',
                    de: 'Eigentumsanfragen',
                    fr: 'Demandes de propriété'
                  },
                  path: '/agent/property-requests'
                }
              ]
            : []),
          {
            icon: <ReactSVG src={SubscribedUsers} />,
            title: {
              en: 'Followers',
              fa: 'دنبال‌کنندگان',
              tr: 'Takipçiler',
              ar: 'أتباع',
              ru: 'Подписчики',
              de: 'Anhänger',
              fr: 'Abonnés'
            },
            path: '/agent/followers'
          },
          {
            icon: <ReactSVG src={SettingsIcon} />,
            title: {
              en: 'Account Settings',
              fa: 'تنظیمات حساب کاربری',
              tr: 'Hesap Ayarları',
              ar: 'إعدادات الحساب',
              ru: 'Настройки аккаунта',
              de: 'Kontoeinstellungen',
              fr: 'Paramètres du compte'
            },
            path: '/agent/account-settings'
          }
        ]
      : role == 'company'
      ? [
          {
            icon: <ReactSVG src={DashdBoardIcon} />,
            title: {
              en: 'Dashboard',
              fa: 'داشبورد',
              tr: 'Gösterge Paneli',
              ar: 'لوحة القيادة',
              ru: 'Панель приборов',
              de: 'Instrumententafel',
              fr: 'Tableau de bord'
            },
            path: '/company/dashboard'
          },
          {
            icon: <ReactSVG src={PropertiesManagment} />,
            title: {
              en: 'Properties Management',
              fa: 'مدیریت املاک',
              tr: 'Özellikler Yönetimi',
              ar: 'إدارة الخصائص',
              ru: 'Управление свойствами',
              de: 'Eigenschaftenverwaltung',
              fr: 'Gestion des propriétés'
            },
            path: '/company/properties-management'
          },
          {
            icon: <ReactSVG src={ProjectsManagement} />,
            title: {
              en: 'Projects Management',
              fa: 'مدیریت پروژه‌ها',
              tr: 'Projeler Yönetimi',
              ar: 'إدارة المشاريع',
              ru: 'Управление проектами',
              de: 'Projektmanagement',
              fr: 'Gestion de projet'
            },
            path: '/company/projects-management'
            // subMenu: [
            //   {
            //     icon: <ReactSVG src={PropertiesManagment} />,
            //     title: 'Project Units',
            //     path: '/company/projects-units'
            //   }
            // ]
          },
          {
            icon: <ReactSVG src={EventsManagement} />,
            title: {
              en: 'Events Management',
              fa: 'مدیریت رویدادها',
              tr: 'Etkinlikler Yönetimi',
              ar: 'إدارة الأحداث',
              ru: 'Управление событиями',
              de: 'Veranstaltungsmanagement',
              fr: 'Gestion des événements'
            },
            path: '/company/events-management'
          },
          {
            icon: <FaUsers size={20} />,
            title: {
              en: 'Agents Management',
              fa: 'مدیریت نمایندگان',
              tr: 'Ajanlar Yönetimi',
              ar: 'إدارة الوكلاء',
              ru: 'Управление агентами',
              de: 'Agentenverwaltung',
              fr: 'Gestion des agents'
            },
            path: '/company/agents-management'
          },
          {
            icon: <ReactSVG src={SettingsIcon} />,
            title: {
              en: 'Profile Settings',
              fa: 'تنظیمات پروفایل',
              tr: 'Profil Ayarları',
              ar: 'إعدادات الملف الشخصي',
              ru: 'Настройки профиля',
              de: 'Profil-Einstellungen',
              fr: 'Paramètres du profil'
            },
            path: '/company/profile-settings'
          },
          {
            icon: <ReactSVG src={SubscribedUsers} />,
            title: {
              en: 'Followers',
              fa: 'دنبال‌کنندگان',
              tr: 'Takipçiler',
              ar: 'أتباع',
              ru: 'Подписчики',
              de: 'Anhänger',
              fr: 'Abonnés'
            },
            path: '/company/followers'
          },

          ...(user?.packageDetails?.receivePropertyRequests
            ? [
                {
                  icon: <ReactSVG src={PropertyRequest} />,
                  title: {
                    en: 'Property Requests',
                    fa: 'درخواست‌های املاک',
                    tr: 'Mülk Talepleri',
                    ar: 'طلبات الملكية',
                    ru: 'Запросы на собственность',
                    de: 'Eigentumsanfragen',
                    fr: 'Demandes de propriété'
                  },
                  path: '/company/property-requests'
                }
              ]
            : [])
        ]
      : role == 'user'
      ? []
      : [])
  ];
  return options;
};

export const logoutText = {
  en: 'Logout',
  fa: 'خروج',
  tr: 'Çıkış Yap',
  ar: 'الخروج',
  ru: 'Выйти',
  de: 'Ausloggen',
  fr: 'Se déconnecter'
};

// Sidebar Options

// Sort By Date Options
export const dateFilterOptions = [
  {
    label: 'Newest to Oldest',
    value: -1,
    en: 'Newest to Oldest',
    fa: 'جدیدترین به قدیمی‌ترین',
    tr: 'En Yeniden En Eskiye',
    ar: 'من الأحدث إلى الأقدم',
    ru: 'Самые новые в Старейшие',
    de: 'Neueste zu Älteste',
    fr: 'Du plus récent au plus ancien'
  },
  {
    label: 'Oldest to Newest',
    value: 1,
    en: 'Oldest to Newest',
    fa: 'قدیمی‌ترین به جدیدترین',
    tr: 'En Eskiden En Yeniye',
    ar: 'من الأقدم إلى الأحدث',
    ru: 'Самые старые в Новейшие',
    de: 'Älteste zu Neueste',
    fr: 'Du plus ancien au plus récent'
  }
];
// Sort By Date Options

export const roomsOptions = [
  {
    fr: 'Studio',
    ru: 'Студия',
    de: 'Studio',
    fa: 'استودیو',
    tr: 'Stüdyo',
    ar: 'استوديو',
    en: 'Studio'
  },
  {
    fr: '1+1',
    ru: '1+1',
    de: '1+1',
    fa: '1+1',
    tr: '1+1',
    ar: '1+1',
    en: '1+1'
  },
  {
    fr: '2+1',
    ru: '2+1',
    de: '2+1',
    fa: '2+1',
    tr: '2+1',
    ar: '2+1',
    en: '2+1'
  },
  {
    fr: '3+1',
    ru: '3+1',
    de: '3+1',
    fa: '3+1',
    tr: '3+1',
    ar: '3+1',
    en: '3+1'
  },
  {
    fr: '4+1',
    ru: '4+1',
    de: '4+1',
    fa: '4+1',
    tr: '4+1',
    ar: '4+1',
    en: '4+1'
  },
  {
    fr: '5+1',
    ru: '5+1',
    de: '5+1',
    fa: '5+1',
    tr: '5+1',
    ar: '5+1',
    en: '5+1'
  },
  {
    fr: '2',
    ru: '2',
    de: '2',
    fa: '2',
    tr: '2',
    ar: '2',
    en: '2'
  },
  {
    fr: '2+2',
    ru: '2+2',
    de: '2+2',
    fa: '2+2',
    tr: '2+2',
    ar: '2+2',
    en: '2+2'
  },
  {
    fr: '3+2',
    ru: '3+2',
    de: '3+2',
    fa: '3+2',
    tr: '3+2',
    ar: '3+2',
    en: '3+2'
  },
  {
    fr: '4+2',
    ru: '4+2',
    de: '4+2',
    fa: '4+2',
    tr: '4+2',
    ar: '4+2',
    en: '4+2'
  },
  {
    fr: '5+2',
    ru: '5+2',
    de: '5+2',
    fa: '5+2',
    tr: '5+2',
    ar: '5+2',
    en: '5+2'
  },
  {
    fr: '6+2',
    ru: '6+2',
    de: '6+2',
    fa: '6+2',
    tr: '6+2',
    ar: '6+2',
    en: '6+2'
  },
  {
    fr: '3',
    ru: '3',
    de: '3',
    fa: '3',
    tr: '3',
    ar: '3',
    en: '3'
  },
  {
    fr: '2+3',
    ru: '2+3',
    de: '2+3',
    fa: '2+3',
    tr: '2+3',
    ar: '2+3',
    en: '2+3'
  },
  {
    fr: '3+3',
    ru: '3+3',
    de: '3+3',
    fa: '3+3',
    tr: '3+3',
    ar: '3+3',
    en: '3+3'
  },
  {
    fr: '4+3',
    ru: '4+3',
    de: '4+3',
    fa: '4+3',
    tr: '4+3',
    ar: '4+3',
    en: '4+3'
  },
  {
    fr: '4',
    ru: '4',
    de: '4',
    fa: '4',
    tr: '4',
    ar: '4',
    en: '4'
  },
  {
    fr: '4+2',
    ru: '4+2',
    de: '4+2',
    fa: '4+2',
    tr: '4+2',
    ar: '4+2',
    en: '4+2'
  },
  {
    fr: '4+3',
    ru: '4+3',
    de: '4+3',
    fa: '4+3',
    tr: '4+3',
    ar: '4+3',
    en: '4+3'
  },
  {
    fr: '4+4',
    ru: '4+4',
    de: '4+4',
    fa: '4+4',
    tr: '4+4',
    ar: '4+4',
    en: '4+4'
  },
  {
    fr: '5+4',
    ru: '5+4',
    de: '5+4',
    fa: '5+4',
    tr: '5+4',
    ar: '5+4',
    en: '5+4'
  },
  {
    fr: '5',
    ru: '5',
    de: '5',
    fa: '5',
    tr: '5',
    ar: '5',
    en: '5'
  },
  {
    fr: '5+1',
    ru: '5+1',
    de: '5+1',
    fa: '5+1',
    tr: '5+1',
    ar: '5+1',
    en: '5+1'
  },
  {
    fr: '5+2',
    ru: '5+2',
    de: '5+2',
    fa: '5+2',
    tr: '5+2',
    ar: '5+2',
    en: '5+2'
  },
  {
    fr: '5+3',
    ru: '5+3',
    de: '5+3',
    fa: '5+3',
    tr: '5+3',
    ar: '5+3',
    en: '5+3'
  },
  {
    fr: '6',
    ru: '6',
    de: '6',
    fa: '6',
    tr: '6',
    ar: '6',
    en: '6'
  },
  {
    fr: '6+1',
    ru: '6+1',
    de: '6+1',
    fa: '6+1',
    tr: '6+1',
    ar: '6+1',
    en: '6+1'
  },
  {
    fr: '6+2',
    ru: '6+2',
    de: '6+2',
    fa: '6+2',
    tr: '6+2',
    ar: '6+2',
    en: '6+2'
  },
  {
    fr: '6+3',
    ru: '6+3',
    de: '6+3',
    fa: '6+3',
    tr: '6+3',
    ar: '6+3',
    en: '6+3'
  },
  {
    fr: '7',
    ru: '7',
    de: '7',
    fa: '7',
    tr: '7',
    ar: '7',
    en: '7'
  },
  {
    fr: '7+1',
    ru: '7+1',
    de: '7+1',
    fa: '7+1',
    tr: '7+1',
    ar: '7+1',
    en: '7+1'
  },
  {
    fr: '7+2',
    ru: '7+2',
    de: '7+2',
    fa: '7+2',
    tr: '7+2',
    ar: '7+2',
    en: '7+2'
  },
  {
    fr: '7+3',
    ru: '7+3',
    de: '7+3',
    fa: '7+3',
    tr: '7+3',
    ar: '7+3',
    en: '7+3'
  },
  {
    fr: '7+4',
    ru: '7+4',
    de: '7+4',
    fa: '7+4',
    tr: '7+4',
    ar: '7+4',
    en: '7+4'
  },
  {
    fr: '8+',
    ru: '8+',
    de: '8+',
    fa: '8+',
    tr: '8+',
    ar: '8+',
    en: '8+'
  },
  {
    fr: '8+1',
    ru: '8+1',
    de: '8+1',
    fa: '8+1',
    tr: '8+1',
    ar: '8+1',
    en: '8+1'
  },
  {
    fr: '8+2',
    ru: '8+2',
    de: '8+2',
    fa: '8+2',
    tr: '8+2',
    ar: '8+2',
    en: '8+2'
  },
  {
    fr: '8+3',
    ru: '8+3',
    de: '8+3',
    fa: '8+3',
    tr: '8+3',
    ar: '8+3',
    en: '8+3'
  },
  {
    fr: '8+4',
    ru: '8+4',
    de: '8+4',
    fa: '8+4',
    tr: '8+4',
    ar: '8+4',
    en: '8+4'
  },
  {
    fr: '9+',
    ru: '9+',
    de: '9+',
    fa: '9+',
    tr: '9+',
    ar: '9+',
    en: '9+'
  }
];

export const roomFilterOptions = [
  {
    fr: 'Tout',
    ru: 'Любой',
    de: 'Beliebig',
    fa: 'هر چیز',
    tr: 'Herhangi',
    ar: 'أي',
    en: 'Any'
  },
  {
    fr: 'Studio',
    ru: 'Студия',
    de: 'Studio',
    fa: 'استودیو',
    tr: 'Stüdyo',
    ar: 'استوديو',
    en: 'Studio'
  },
  {
    fr: '1+1',
    ru: '1+1',
    de: '1+1',
    fa: '1+1',
    tr: '1+1',
    ar: '1+1',
    en: '1+1'
  },
  {
    fr: '2+1',
    ru: '2+1',
    de: '2+1',
    fa: '2+1',
    tr: '2+1',
    ar: '2+1',
    en: '2+1'
  },
  {
    fr: '3+1',
    ru: '3+1',
    de: '3+1',
    fa: '3+1',
    tr: '3+1',
    ar: '3+1',
    en: '3+1'
  },
  {
    fr: '4+1',
    ru: '4+1',
    de: '4+1',
    fa: '4+1',
    tr: '4+1',
    ar: '4+1',
    en: '4+1'
  },
  {
    fr: '5+1',
    ru: '5+1',
    de: '5+1',
    fa: '5+1',
    tr: '5+1',
    ar: '5+1',
    en: '5+1'
  },
  {
    fr: '2',
    ru: '2',
    de: '2',
    fa: '2',
    tr: '2',
    ar: '2',
    en: '2'
  },
  {
    fr: '2+2',
    ru: '2+2',
    de: '2+2',
    fa: '2+2',
    tr: '2+2',
    ar: '2+2',
    en: '2+2'
  },
  {
    fr: '3+2',
    ru: '3+2',
    de: '3+2',
    fa: '3+2',
    tr: '3+2',
    ar: '3+2',
    en: '3+2'
  },
  {
    fr: '4+2',
    ru: '4+2',
    de: '4+2',
    fa: '4+2',
    tr: '4+2',
    ar: '4+2',
    en: '4+2'
  },
  {
    fr: '5+2',
    ru: '5+2',
    de: '5+2',
    fa: '5+2',
    tr: '5+2',
    ar: '5+2',
    en: '5+2'
  },
  {
    fr: '6+2',
    ru: '6+2',
    de: '6+2',
    fa: '6+2',
    tr: '6+2',
    ar: '6+2',
    en: '6+2'
  },
  {
    fr: '3',
    ru: '3',
    de: '3',
    fa: '3',
    tr: '3',
    ar: '3',
    en: '3'
  },
  {
    fr: '2+3',
    ru: '2+3',
    de: '2+3',
    fa: '2+3',
    tr: '2+3',
    ar: '2+3',
    en: '2+3'
  },
  {
    fr: '3+3',
    ru: '3+3',
    de: '3+3',
    fa: '3+3',
    tr: '3+3',
    ar: '3+3',
    en: '3+3'
  },
  {
    fr: '4+3',
    ru: '4+3',
    de: '4+3',
    fa: '4+3',
    tr: '4+3',
    ar: '4+3',
    en: '4+3'
  },
  {
    fr: '4',
    ru: '4',
    de: '4',
    fa: '4',
    tr: '4',
    ar: '4',
    en: '4'
  },
  {
    fr: '4+2',
    ru: '4+2',
    de: '4+2',
    fa: '4+2',
    tr: '4+2',
    ar: '4+2',
    en: '4+2'
  },
  {
    fr: '4+3',
    ru: '4+3',
    de: '4+3',
    fa: '4+3',
    tr: '4+3',
    ar: '4+3'
  },
  {
    fr: '4+4',
    ru: '4+4',
    de: '4+4',
    fa: '4+4',
    tr: '4+4',
    ar: '4+4',
    en: '4+4'
  },
  {
    fr: '5+4',
    ru: '5+4',
    de: '5+4',
    fa: '5+4',
    tr: '5+4',
    ar: '5+4',
    en: '5+4'
  },
  {
    fr: '5',
    ru: '5',
    de: '5',
    fa: '5',
    tr: '5',
    ar: '5',
    en: '5'
  },
  {
    fr: '5+1',
    ru: '5+1',
    de: '5+1',
    fa: '5+1',
    tr: '5+1',
    ar: '5+1',
    en: '5+1'
  },
  {
    fr: '5+2',
    ru: '5+2',
    de: '5+2',
    fa: '5+2',
    tr: '5+2',
    ar: '5+2',
    en: '5+2'
  },
  {
    fr: '5+3',
    ru: '5+3',
    de: '5+3',
    fa: '5+3',
    tr: '5+3',
    ar: '5+3',
    en: '5+3'
  },
  {
    fr: '6',
    ru: '6',
    de: '6',
    fa: '6',
    tr: '6',
    ar: '6',
    en: '6'
  },
  {
    fr: '6+1',
    ru: '6+1',
    de: '6+1',
    fa: '6+1',
    tr: '6+1',
    ar: '6+1',
    en: '6+1'
  },
  {
    fr: '6+2',
    ru: '6+2',
    de: '6+2',
    fa: '6+2',
    tr: '6+2',
    ar: '6+2',
    en: '6+2'
  },
  {
    fr: '6+3',
    ru: '6+3',
    de: '6+3',
    fa: '6+3',
    tr: '6+3',
    ar: '6+3',
    en: '6+3'
  },
  {
    fr: '7',
    ru: '7',
    de: '7',
    fa: '7',
    tr: '7',
    ar: '7',
    en: '7'
  },
  {
    fr: '7+1',
    ru: '7+1',
    de: '7+1',
    fa: '7+1',
    tr: '7+1',
    ar: '7+1',
    en: '7+1'
  },
  {
    fr: '7+2',
    ru: '7+2',
    de: '7+2',
    fa: '7+2',
    tr: '7+2',
    ar: '7+2',
    en: '7+2'
  },
  {
    fr: '7+3',
    ru: '7+3',
    de: '7+3',
    fa: '7+3',
    tr: '7+3',
    ar: '7+3',
    en: '7+3'
  },
  {
    fr: '7+4',
    ru: '7+4',
    de: '7+4',
    fa: '7+4',
    tr: '7+4',
    ar: '7+4',
    en: '7+4'
  },
  {
    fr: '8+',
    ru: '8+',
    de: '8+',
    fa: '8+',
    tr: '8+',
    ar: '8+',
    en: '8+'
  },
  {
    fr: '8+1',
    ru: '8+1',
    de: '8+1',
    fa: '8+1',
    tr: '8+1',
    ar: '8+1',
    en: '8+1'
  },
  {
    fr: '8+2',
    ru: '8+2',
    de: '8+2',
    fa: '8+2',
    tr: '8+2',
    ar: '8+2',
    en: '8+2'
  },
  {
    fr: '8+3',
    ru: '8+3',
    de: '8+3',
    fa: '8+3',
    tr: '8+3',
    ar: '8+3',
    en: '8+3'
  },
  {
    fr: '8+4',
    ru: '8+4',
    de: '8+4',
    fa: '8+4',
    tr: '8+4',
    ar: '8+4',
    en: '8+4'
  },
  {
    fr: '9+',
    ru: '9+',
    de: '9+',
    fa: '9+',
    tr: '9+',
    ar: '9+',
    en: '9+'
  }
];

// Languages Array With Flags
export const languagesWithFlags = [
  { value: 'EN', label: 'EN', flag: 'https://cdn-icons-png.flaticon.com/512/206/206626.png' }
];
// Languages Array With Flags

// Home Page Filters
const commercialFilterOptions = [
  { id: 1, title: 'Bungalow/Villa1' },
  { id: 2, title: 'Bungalow/Villa2' },
  { id: 3, title: 'Bungalow/Villa3' },
  { id: 4, title: 'Bungalow/Villa4' },
  { id: 5, title: 'Bungalow/Villa5' }
];
const residentialFilterOptions = [
  { id: 1, title: 'Bungalow/Villa6' },
  { id: 2, title: 'Bungalow/Villa7' },
  { id: 3, title: 'Bungalow/Villa8' },
  { id: 4, title: 'Bungalow/Villa9' },
  { id: 5, title: 'Bungalow/Villa10' }
];
const priceFilterOptions = ['0 to 150K', '151K to 300K', '0 to Infinite'];
const areaFilterOptions = ['0 sqft to 120 sqft', '121 sqft to 249 sqft', '0 to Infinite'];
const bedsFilterOptions = ['1 room', '2 rooms', '3 rooms', '4 rooms', '5+ rooms'];
const termsFilterOptions = ['daily', 'monthly', 'weekly', 'yearly'];
const bathsFilterOptions = [
  '1 bathroom',
  '2 bathrooms',
  '3 bathrooms',
  '4 bathrooms',
  '5+ bathrooms'
];

export const bathRoomsFilterOptions = [
  { en: '1 bathroom', fr: '1 salle de bain', ru: '1 ванная комната', de: '1 Badezimmer', fa: '1 حمام', tr: '1 banyo', ar: 'حمام 1', value:1 },
  { en: '2 bathrooms', fr: '2 salles de bain', ru: '2 ванные комнаты', de: '2 Badezimmer', fa: '2 حمام', tr: '2 banyo', ar: '2 حمام', value:2 },
  { en: '3 bathrooms', fr: '3 salles de bain', ru: '3 ванные комнаты', de: '3 Badezimmer', fa: '3 حمام', tr: '3 banyo', ar: '3 حمام', value:3 },
  { en: '4 bathrooms', fr: '4 salles de bain', ru: '4 ванные комнаты', de: '4 Badezimmer', fa: '4 حمام', tr: '4 banyo', ar: '4 حمام', value:4 },
  { en: '5+ bathrooms', fr: '5 salles de bain et plus', ru: '5 ванных комнат и более', de: '5 oder mehr Badezimmer', fa: '5 حمام و بیشتر', tr: '5 ve daha fazla banyo', ar: '5 حمام وأكثر', value:5, more:true }
];

// Home Page Filters

// New Event Page Filters
const eventTypesFilters = [
  'Open House event',
  'Project Launch event',
  'Viewing tour',
  'Auction',
  'Exhibitions & trade show',
  'Seminar & conferences'
];
// New Event Page Filters

const otherFiltersOptions = [
  {
    name: 'Parking Space',
    options: ['1 to 20 sqft ', '20 to 40 sqft', '40 to 60 sqft', '60 to 80 sqft', '100+ sqft'],
    key: 'parking-space'
  },
  {
    name: 'Propery Age',
    options: ['1 to 10 years', '10 to 20 years', '30 to 40 years', '40 to 50 years', '50+ years'],
    key: 'property-age'
  },
  { name: 'Furniture', options: ['Yes', 'No'], key: 'furniture' }
];
export const propertyAgeFilterOptions = [
  {
    en: 'Any',
    tr: 'Tümü',
    ar: 'الكل',
    fr: 'Tout',
    ru: 'Все',
    de: 'Anye',
    fa: 'همه'
  },
  {
    en: 'new',
    tr: 'yeni',
    ar: 'جديد',
    fr: 'Nouveau',
    ru: 'новый',
    de: 'Neu',
    fa: 'جدید'
  },
  {
    en: '1-5 Years',
    tr: '1-5 Yıl',
    ar: '1-5 Yıl',
    fr: '1-5 ans',
    ru: '1-5 лет',
    de: '1-5 Jahre',
    fa: '1-5 سال'
  },
  {
    en: '6-10 Years',
    tr: '6-10 Yıl',
    ar: '6-10 Yıl',
    fr: '6-10 ans',
    ru: '6-10 лет',
    de: '6-10 Jahre',
    fa: '6-10 سال'
  },
  {
    en: '11-15 Years',
    tr: '11-15 Yaş',
    ar: '11-15 Yaş',
    fr: '11-15 ans',
    ru: '11-15 лет',
    de: '11-15 Jahre',
    fa: '11-15 سال'
  },
  {
    en: '16-20',
    tr: '16-20',
    ar: '16-20',
    fr: '16-20',
    ru: '16-20',
    de: '16-20',
    fa: '16-20'
  },
  {
    en: '21 +',
    tr: '21 +',
    ar: '21 +',
    fr: '21 +',
    ru: '21+',
    de: '21 +',
    fa: '21 +'
  }
];

export const propertyAgeOptions = [
  {
    en: '1-5 Years',
    tr: '1-5 Yıl',
    ar: '1-5 Yıl',
    fr: '1-5 ans',
    ru: '1-5 лет',
    de: '1-5 Jahre',
    fa: '1-5 سال'
  },
  {
    en: '6-10 Years',
    tr: '6-10 Yıl',
    ar: '6-10 Yıl',
    fr: '6-10 ans',
    ru: '6-10 лет',
    de: '6-10 Jahre',
    fa: '6-10 سال'
  },
  {
    en: '11-15 Years',
    tr: '11-15 Yaş',
    ar: '11-15 Yaş',
    fr: '11-15 ans',
    ru: '11-15 лет',
    de: '11-15 Jahre',
    fa: '11-15 سال'
  },
  {
    en: '16-20',
    tr: '16-20',
    ar: '16-20',
    fr: '16-20',
    ru: '16-20',
    de: '16-20',
    fa: '16-20'
  },
  {
    en: '21 +',
    tr: '21 +',
    ar: '21 +',
    fr: '21 +',
    ru: '21+',
    de: '21 +',
    fa: '21 +'
  }
];

export const parkingSpaceOptions = [
  {
    en: '1',
    tr: '1',
    ar: '1',
    fr: '1',
    ru: '1',
    de: '1',
    fa: '1'
  },
  {
    en: '2',
    tr: '2',
    ar: '2',
    fr: '2',
    ru: '2',
    de: '2',
    fa: '2'
  },
  {
    en: '3',
    tr: '3',
    ar: '3',
    fr: '3',
    ru: '3',
    de: '3',
    fa: '3'
  },
  {
    en: '4',
    tr: '4',
    ar: '4',
    fr: '4',
    ru: '4',
    de: '4',
    fa: '4'
  },
  {
    en: '5',
    tr: '5',
    ar: '5',
    fr: '5',
    ru: '5',
    de: '5',
    fa: '5'
  },
  {
    en: '6+',
    tr: '6+',
    ar: '6+',
    fr: '6+',
    ru: '6+',
    de: '6+',
    fa: '6+'
  }
];
export const parkingSpaceFilterOptions = [
  {
    en: 'Any',
    tr: 'Tümü',
    ar: 'الكل',
    fr: 'Tout',
    ru: 'Все',
    de: 'Anye',
    fa: 'همه',
    
  },
  {
    en: '1',
    tr: '1',
    ar: '1',
    fr: '1',
    ru: '1',
    de: '1',
    fa: '1'
  },
  {
    en: '2',
    tr: '2',
    ar: '2',
    fr: '2',
    ru: '2',
    de: '2',
    fa: '2'
  },
  {
    en: '3',
    tr: '3',
    ar: '3',
    fr: '3',
    ru: '3',
    de: '3',
    fa: '3'
  },
  {
    en: '4',
    tr: '4',
    ar: '4',
    fr: '4',
    ru: '4',
    de: '4',
    fa: '4'
  },
  {
    en: '5',
    tr: '5',
    ar: '5',
    fr: '5',
    ru: '5',
    de: '5',
    fa: '5'
  },
  {
    en: '6+',
    tr: '6+',
    ar: '6+',
    fr: '6+',
    ru: '6+',
    de: '6+',
    fa: '6+',
    more:true
  }
];

export const preferredMethodOptions = [
  {
    en: 'Email',
    tr: 'E-posta',
    ar: 'البريد الإلكتروني',
    fr: 'E-mail',
    ru: 'Электронная почта',
    de: 'E-Mail',
    fa: 'ایمیل'
  },
  {
    en: 'Phone',
    tr: 'Telefon',
    ar: 'هاتف',
    fr: 'Téléphone',
    ru: 'Телефон',
    de: 'Telefon',
    fa: 'تلفن'
  },
  {
    en: 'WhatsApp',
    tr: 'WhatsApp',
    ar: 'واتساب',
    fr: 'WhatsApp',
    ru: 'WhatsApp',
    de: 'WhatsApp',
    fa: 'واتساپ'
  }
];

export const floorLevelFilterOptions = [
  {
    en: 'Any',
    tr: 'Tümü',
    ar: 'الكل',
    fr: 'Tout',
    ru: 'Все',
    de: 'Anye',
    fa: 'همه'
  },
  {
    en: 'Ground',
    tr: 'Zemin',
    ar: 'أرضي',
    fr: 'Terre',
    ru: 'Земля',
    de: 'Boden',
    fa: 'زمینی'
  },
  {
    en: 'Garden floor',
    tr: 'Bahçe Katı',
    ar: 'طابق حديقة',
    fr: 'Rez-de-jardin',
    ru: 'Садовый этаж',
    de: 'Gartenboden',
    fa: 'طبقه باغ'
  },
  {
    en: '1',
    tr: '1',
    ar: '1',
    fr: '1',
    ru: '1',
    de: '1',
    fa: '1'
  },
  {
    en: '2',
    tr: '2',
    ar: '2',
    fr: '2',
    ru: '2',
    de: '2',
    fa: '2'
  },
  {
    en: '3 - 5',
    tr: '3 - 5',
    ar: '3 - 5',
    fr: '3 - 5',
    ru: '3 - 5',
    de: '3 - 5',
    fa: '3 - 5'
  },
  {
    en: '6 - 10',
    tr: '6 - 10',
    ar: '6 - 10',
    fr: '6 - 10',
    ru: '6 - 10',
    de: '6 - 10',
    fa: '6 - 10'
  },
  {
    en: '10-20',
    tr: '10+',
    ar: '10+',
    fr: '10+',
    ru: '10+',
    de: '10+',
    fa: '10+'
  },
  {
    en: '20+',
    tr: '20+',
    ar: '20+',
    fr: '20+',
    ru: '20+',
    de: '20+',
    fa: '20+'
  },
  {
    en: 'top floor',
    tr: 'üst kat',
    ar: 'الطابق العلوي',
    fr: 'dernier étage',
    ru: 'верхний этаж',
    de: 'Dachgeschoss',
    fa: 'طبقه بالا'
  },
  {
    en: 'Basement',
    tr: 'Bodrum',
    ar: 'قبو',
    fr: 'Sous-sol',
    ru: 'Подвал',
    de: 'Untergeschoss',
    fa: 'Basement'
  },
  {
    en: 'mezzanine',
    tr: 'asma kat',
    ar: 'ميزانين',
    fr: 'mezzanine',
    ru: 'мезонин',
    de: 'Zwischenstock',
    fa: 'mezzanine'
  },
  {
    en: 'Penthouse',
    tr: 'Çatı katı',
    ar: 'بنتهاوس',
    fr: 'Attique',
    ru: 'Пентхаус',
    de: 'Penthouse',
    fa: 'Penthouse'
  },
  {
    en: 'High entrance',
    tr: 'Yüksek giriş',
    ar: 'مدخل مرتفع',
    fr: 'Entrée haute',
    ru: 'Высокий вход',
    de: 'Hoher Eingang',
    fa: 'High entrance'
  },
  {
    en: 'Semi Basement',
    tr: 'Yarı Bodrum',
    ar: 'شبه قبو',
    fr: 'Demi sous-sol',
    ru: 'Полуподвал',
    de: 'Halbkeller',
    fa: 'Semi Basement'
  },
  {
    en: 'Direct entrance',
    tr: 'Doğrudan giriş',
    ar: 'مدخل مباشر',
    fr: 'Entrée directe',
    ru: 'Прямой вход',
    de: 'Direkter Eingang',
    fa: 'Direct entrance'
  }
];
export const titleDeedOptions = [
  {
    en: 'Condominium',
    tr: 'Kat Mülkiyeti',
    ar: 'سند الملكية التامة',
    fr: 'Copropriété',
    ru: 'Квартирное товарищество',
    de: 'Wohnungseigentum',
    fa: 'مشارکت در مالکیت واحد'
  },
  {
    en: 'Construction Servitude',
    tr: 'İnşaat Sermayesi',
    ar: 'رهن البناء',
    fr: 'Servitude de construction',
    ru: 'Строительная служителей',
    de: 'Baulast',
    fa: 'رهن خدمات ساخت و ساز'
  },
  {
    en: 'Joint ownership',
    tr: 'Ortak Mülkiyet',
    ar: 'ملكية مشتركة',
    fr: 'Copropriété',
    ru: 'Совместная собственность',
    de: 'Gemeinschaftseigentum',
    fa: 'مالکیت مشترک'
  },
  {
    en: 'Residential land',
    tr: 'Konut Arsa',
    ar: 'أرض سكنية',
    fr: 'Terrain résidentiel',
    ru: 'Жилая земля',
    de: 'Wohnland',
    fa: 'زمین مسکونی'
  },
  {
    en: 'Detached Parcel',
    tr: 'Bağımsız Parsel',
    ar: 'قطعة مستقلة',
    fr: 'Parcelle détachée',
    ru: 'Отдельный участок',
    de: 'Einzelgrundstück',
    fa: 'متمرکز زمین'
  },
  {
    en: 'Occupation Permit Received',
    tr: 'Ruhsat Alındı',
    ar: 'تم استلام تصريح الاحتلال',
    fr: "Permis d'occupation reçu",
    ru: 'Получено разрешение на использование',
    de: 'Besetzungsgenehmigung erhalten',
    fa: 'مجوز اشغال دریافت شد'
  },
  {
    en: 'Shared title Deed',
    tr: 'Paylaşılan Tapu',
    ar: 'سند ملكية مشتركة',
    fr: 'Titre de propriété partagé',
    ru: 'Общий титул собственности',
    de: 'Gemeinsame Grundbuchurkunde',
    fa: 'سند مالکیت مشترک'
  },
  {
    en: 'Anyocation',
    tr: 'Herhangi',
    ar: 'أي ترجمة',
    fr: "N'importe quelle traduction",
    ru: 'Любой перевод',
    de: 'Beliebige Übersetzung',
    fa: 'هر ترجمه‌ای'
  },
  {
    en: 'Possession',
    tr: 'Mülkiyet',
    ar: 'ملكية',
    fr: 'Possession',
    ru: 'Последующее владение',
    de: 'Besitz',
    fa: 'مالکیت'
  }
];

export const floorLevelOptions = [
  {
    en: 'Ground',
    tr: 'Zemin',
    ar: 'أرضي',
    fr: 'Terre',
    ru: 'Земля',
    de: 'Boden',
    fa: 'زمینی'
  },
  {
    en: 'Garden floor',
    tr: 'Bahçe Katı',
    ar: 'طابق حديقة',
    fr: 'Rez-de-jardin',
    ru: 'Садовый этаж',
    de: 'Gartenboden',
    fa: 'طبقه باغ'
  },
  {
    en: '1',
    tr: '1',
    ar: '1',
    fr: '1',
    ru: '1',
    de: '1',
    fa: '1'
  },
  {
    en: '2',
    tr: '2',
    ar: '2',
    fr: '2',
    ru: '2',
    de: '2',
    fa: '2'
  },
  {
    en: '3 - 5',
    tr: '3 - 5',
    ar: '3 - 5',
    fr: '3 - 5',
    ru: '3 - 5',
    de: '3 - 5',
    fa: '3 - 5'
  },
  {
    en: '6 - 10',
    tr: '6 - 10',
    ar: '6 - 10',
    fr: '6 - 10',
    ru: '6 - 10',
    de: '6 - 10',
    fa: '6 - 10'
  },
  {
    en: '10-20',
    tr: '10+',
    ar: '10+',
    fr: '10+',
    ru: '10+',
    de: '10+',
    fa: '10+'
  },
  {
    en: '20+',
    tr: '20+',
    ar: '20+',
    fr: '20+',
    ru: '20+',
    de: '20+',
    fa: '20+'
  },
  {
    en: 'top floor',
    tr: 'üst kat',
    ar: 'الطابق العلوي',
    fr: 'dernier étage',
    ru: 'верхний этаж',
    de: 'Dachgeschoss',
    fa: 'طبقه بالا'
  },
  {
    en: 'Basement',
    tr: 'Bodrum',
    ar: 'قبو',
    fr: 'Sous-sol',
    ru: 'Подвал',
    de: 'Untergeschoss',
    fa: 'Basement'
  },
  {
    en: 'mezzanine',
    tr: 'asma kat',
    ar: 'ميزانين',
    fr: 'mezzanine',
    ru: 'мезонин',
    de: 'Zwischenstock',
    fa: 'mezzanine'
  },
  {
    en: 'Penthouse',
    tr: 'Çatı katı',
    ar: 'بنتهاوس',
    fr: 'Attique',
    ru: 'Пентхаус',
    de: 'Penthouse',
    fa: 'Penthouse'
  },
  {
    en: 'High entrance',
    tr: 'Yüksek giriş',
    ar: 'مدخل مرتفع',
    fr: 'Entrée haute',
    ru: 'Высокий вход',
    de: 'Hoher Eingang',
    fa: 'High entrance'
  },
  {
    en: 'Semi Basement',
    tr: 'Yarı Bodrum',
    ar: 'شبه قبو',
    fr: 'Demi sous-sol',
    ru: 'Полуподвал',
    de: 'Halbkeller',
    fa: 'Semi Basement'
  },
  {
    en: 'Direct entrance',
    tr: 'Doğrudan giriş',
    ar: 'مدخل مباشر',
    fr: 'Entrée directe',
    ru: 'Прямой вход',
    de: 'Direkter Eingang',
    fa: 'Direct entrance'
  }
];
const interiorAmenities = [
  { _id: 1232, name: 'Test 1' },
  { _id: 1234, name: 'Test 2' },
  { _id: 12341, name: 'Test 3' }
];
const exteriorAmenities = [
  { _id: 1231, name: 'Test 2' },
  { _id: 124, name: 'Test 3' },
  { _id: 1214, name: 'Test 3' }
];

const propertiesData = Array(20)
  .fill({
    _id: '652d1f56f7c6064248f54b2d',
    location: {
      type: 'Point',
      coordinates: [12.4707246, 41.8777253]
    },
    title: {
      en: 'Full floor for sale | All unit ready rented | One Bedroom',
      fa: 'Full floor for sale | All unit ready rented | One Bedroom',
      tr: 'Full floor for sale | All unit ready rented | One Bedroom',
      ar: 'Full floor for sale | All unit ready rented | One Bedroom',
      ru: 'Full floor for sale | All unit ready rented | One Bedroom',
      de: 'Full floor for sale | All unit ready rented | One Bedroom',
      fr: 'Full floor for sale | All unit ready rented | One Bedroom'
    },
    description: {
      en: 'Description Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus',
      fa: 'Description Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus',
      tr: 'Description Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus',
      ar: 'Description Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus',
      ru: 'Description Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus',
      de: 'Description Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus',
      fr: 'Description Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus'
    },
    propertyPurpose: {
      en: 'Buy',
      fa: 'خرید',
      tr: 'Satın Al',
      ar: 'شراء',
      ru: 'Купить',
      de: 'Kaufen',
      fr: 'Acheter'
    },
    netArea: '100',
    noOfRooms: 10,
    noOfBathrooms: 5,
    floorLevel: 4,
    furniture: {
      en: 'Yes',
      fa: 'آره',
      tr: 'Evet',
      ar: 'نعم',
      ru: 'да',
      de: 'Ja',
      fr: 'Oui'
    },
    interiorAmenities: [
      {
        _id: '652ce8e39a8b88493c375429',
        amenityType: 'interior',
        isActive: true,
        title: {
          en: 'sweet home interior',
          tr: 'tatlı ev',
          ar: 'بيت حلو ',
          fr: 'douce maison',
          ru: 'сладкий дом',
          de: 'süßes Zuhause',
          fa: 'خانه شیرین '
        },
        slug: 'sweet-home-interior-8',
        slugId: '8',
        createdAt: '2023-10-16T07:40:19.712Z',
        updatedAt: '2023-10-16T07:40:19.712Z',
        __v: 0
      }
    ],
    exteriorAmenities: [
      {
        _id: '652ce8f89a8b88493c37542e',
        amenityType: 'exterior',
        isActive: true,
        title: {
          en: 'sweet home exterior',
          tr: 'tatlı ev',
          ar: 'بيت حلو ',
          fr: 'douce maison',
          ru: 'сладкий дом',
          de: 'süßes Zuhause',
          fa: 'خانه شیرین '
        },
        slug: 'sweet-home-exterior-9',
        slugId: '9',
        createdAt: '2023-10-16T07:40:40.449Z',
        updatedAt: '2023-10-16T07:40:40.449Z',
        __v: 0
      }
    ],
    images: [
      'https://images.unsplash.com/photo-1600585152220-90363fe7e115?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
      'https://images.unsplash.com/photo-1600607687939-ce8a6c25118c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1453&q=80',
      'https://images.unsplash.com/photo-1600585152220-90363fe7e115?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
      'https://images.unsplash.com/photo-1600607687939-ce8a6c25118c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1453&q=80',
      'https://images.unsplash.com/photo-1600585152220-90363fe7e115?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
      'https://images.unsplash.com/photo-1600607687939-ce8a6c25118c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1453&q=80',
      'https://images.unsplash.com/photo-1600585152220-90363fe7e115?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
      'https://images.unsplash.com/photo-1600607687939-ce8a6c25118c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1453&q=80'
    ],
    planImages: [
      'https://images.unsplash.com/photo-1600585152220-90363fe7e115?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
      'https://images.unsplash.com/photo-1600607687939-ce8a6c25118c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1453&q=80',
      'https://images.unsplash.com/photo-1600585152220-90363fe7e115?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
    ],
    videoLink:
      'https://kuula.co/share/collection/7JSGL?logo=0&info=0&fs=1&vr=1&initload=0&thumbs=1',
    threeSixtyViewLink: '8e8b3cbb-9da7-49ce-9610-564617146d2a.png',
    openHouseStartDate: '2023-11-01T11:18:00.000Z',
    openHouseEndDate: '2023-11-29T11:18:00.000Z',
    propertyStatus: {
      en: 'Residential',
      fa: 'مسکونی',
      tr: 'Konut',
      ar: 'سكني',
      ru: 'Жилой ',
      de: 'Wohngebäude',
      fr: 'Résidentiel'
    },
    propertyAge: {
      en: '11 to 20 years',
      ar: 'من 11 إلى 20 سنة',
      fa: '11 تا 20 سال',
      tr: '11 ila 20 yıl',
      ru: '11-20 лет',
      de: '11 bis 20 Jahre',
      fr: '11 à 20 ans'
    },
    address: 'Jalan Bukit, 55100 Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur',
    province: 'İstanbul',
    neighborhood: 'İstanbul',
    isFeatured: false,
    featuredStartDate: null,
    featuredEndDate: null,
    isPremium: false,
    premiumStartDate: null,
    premiumEndDate: null,
    isActive: true,
    propertyOrientation: {
      en: 'Yes',
      fa: 'آره',
      tr: 'Evet',
      ar: 'نعم',
      ru: 'да',
      de: 'Ja',
      fr: 'Oui'
    },
    slug: 'full-floor-for-sale-|-all-unit-ready-rented-|-one-bedroom-3',
    slugId: '3',
    propertyType: {
      _id: '652ce9129a8b88493c375433',
      isActive: true,
      title: {
        en: 'test property type',
        tr: 'test',
        ar: 'اختبار',
        fr: 'test',
        ru: 'тест',
        de: 'Test',
        fa: 'آزمون'
      },
      slug: 'test-property-type-2',
      slugId: '2',
      createdAt: '2023-10-16T07:41:06.324Z',
      updatedAt: '2023-10-16T07:41:06.324Z',
      __v: 0
    },
    price: 1000000,
    parkingSpace: 2,
    town: 'İstanbul',
    listingId: '51249249',
    company: {
      _id: '650867b0976aa700e0c88be0',
      location: {
        type: 'Point',
        coordinates: [12.4707246, 41.8777253]
      },
      fullName: null,
      firstName: null,
      lastName: null,
      language: ['Afar', 'Afrikaans', 'Abkhaz'],
      serviceAreas: 'Test',
      platformType: 'google',
      signupType: 'normal',
      loginType: 'normal',
      photo: 'c7e77c9c-a9a2-4698-922b-a16e06d54b5d.jpg',
      role: 'company',
      fcmToken: [],
      isActive: false,
      isBlockedByAdmin: false,
      otpCode: null,
      otpCodeExpireAt: null,
      lastLogin: '2023-09-18T15:07:15.028Z',
      socketId: 'iCwUxZZg25zfQVxvAAAD',
      isOnline: true,
      companyName: 'Test Company',
      description:
        'Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test ',
      email: 'testcompany@yopmail.com',
      whatsapp: '11111111111',
      phoneNumber: '12345550000',
      slug: 'test-company-24',
      slugId: '24',
      createdAt: '2023-09-18T15:07:28.033Z',
      updatedAt: '2023-10-16T11:32:39.129Z',
      __v: 1,
      availableCreditPoints: 4,
      totalCreditPoints: 10,
      package: '650ac50a5007d581f840c58b',
      packageDetails: {
        totalProperties: 100,
        availableProperties: 76,
        totalProjects: 100,
        availableProjects: 80,
        totalEvents: 100,
        availableEvents: 67,
        startDate: null,
        endDate: null,
        isPackageExpired: false,
        _id: '650c120157d30c20ccd5e257',
        createdAt: '2023-09-21T09:50:57.474Z',
        updatedAt: '2023-09-21T09:50:57.474Z'
      },
      followers: ['65114c39079c8219c812d393', '65117dcdd0eb02203c92c457'],
      agents: ['651589ebf7460249502fcafd', '65158a9af3045a49c4394ad6'],
      address: 'Testaccio, Rome, Metropolitan City of Rome Capital, Italy',
      companyType: 'real-estate',
      province: 'Test',
      town: 'Test',
      neighborhood: 'Test',
      registrationNumber: 1222332,
      agentFollowing: [],
      companyFollowing: [],
      passwordChangedAt: '2023-10-06T10:07:27.048Z',
      userId: 0
    },
    postedBy: {
      _id: '650867b0976aa700e0c88be0',
      location: {
        type: 'Point',
        coordinates: [12.4707246, 41.8777253]
      },
      fullName: null,
      firstName: null,
      lastName: null,
      language: ['Afar', 'Afrikaans', 'Abkhaz'],
      serviceAreas: 'Test',
      platformType: 'google',
      signupType: 'normal',
      loginType: 'normal',
      photo: 'c7e77c9c-a9a2-4698-922b-a16e06d54b5d.jpg',
      role: 'company',
      fcmToken: [],
      isActive: false,
      isBlockedByAdmin: false,
      otpCode: null,
      otpCodeExpireAt: null,
      lastLogin: '2023-09-18T15:07:15.028Z',
      socketId: 'iCwUxZZg25zfQVxvAAAD',
      isOnline: true,
      companyName: 'Test Company',
      description:
        'Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test Test ',
      email: 'testcompany@yopmail.com',
      whatsapp: '11111111111',
      phoneNumber: '12345550000',
      slug: 'test-company-24',
      slugId: '24',
      createdAt: '2023-09-18T15:07:28.033Z',
      updatedAt: '2023-10-16T11:32:39.129Z',
      __v: 1,
      availableCreditPoints: 4,
      totalCreditPoints: 10,
      package: '650ac50a5007d581f840c58b',
      packageDetails: {
        totalProperties: 100,
        availableProperties: 76,
        totalProjects: 100,
        availableProjects: 80,
        totalEvents: 100,
        availableEvents: 67,
        startDate: null,
        endDate: null,
        isPackageExpired: false,
        _id: '650c120157d30c20ccd5e257',
        createdAt: '2023-09-21T09:50:57.474Z',
        updatedAt: '2023-09-21T09:50:57.474Z'
      },
      followers: ['65114c39079c8219c812d393', '65117dcdd0eb02203c92c457'],
      agents: ['651589ebf7460249502fcafd', '65158a9af3045a49c4394ad6'],
      address: 'Testaccio, Rome, Metropolitan City of Rome Capital, Italy',
      companyType: 'real-estate',
      province: 'Test',
      town: 'Test',
      neighborhood: 'Test',
      registrationNumber: 1222332,
      agentFollowing: [],
      companyFollowing: [],
      passwordChangedAt: '2023-10-06T10:07:27.048Z',
      userId: 0
    },
    lastRefresh: '2023-10-16T11:30:27.996Z',
    createdAt: '2023-10-16T11:32:38.887Z',
    updatedAt: '2023-10-16T11:54:07.388Z',
    __v: 0
  })
  .map((e) => e);

const eventsData = Array(15)
  .fill({
    _id: 1,
    images: [
      'https://images.unsplash.com/photo-1600585152220-90363fe7e115?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
      'https://images.unsplash.com/photo-1600607687939-ce8a6c25118c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1453&q=80',
      'https://images.unsplash.com/photo-1600585152220-90363fe7e115?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
      'https://images.unsplash.com/photo-1600607687939-ce8a6c25118c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1453&q=80',
      'https://images.unsplash.com/photo-1600585152220-90363fe7e115?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
      'https://images.unsplash.com/photo-1600607687939-ce8a6c25118c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1453&q=80',
      'https://images.unsplash.com/photo-1600585152220-90363fe7e115?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
      'https://images.unsplash.com/photo-1600607687939-ce8a6c25118c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1453&q=80'
    ],
    price: 1000000,
    title: 'Full floor for sale | All unit ready rented | One Bedroom',
    address: 'Jalan Bukit, 55100 Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc ut aliquam tincidunt, nunc nisl aliquam lorem, eget aliquam nisl nisl sit amet lorem. Sed euismod, nunc ut aliquam tincidunt, nunc nisl aliquam lorem, eget aliquam nisl nisl sit amet lorem.',
    propertyType: 'Villa',
    propertySize: 1000,
    numberOfBedrooms: 3,
    numberOfBathrooms: 2,
    numberOfStories: 2,
    companyLogo:
      'https://www.makeitealing.co.uk/sites/default/files/upload/logos/barnard_marcus_3f09fb979cb9238cb5ffc63a6d28aed6.png',
    eventName: 'Mariana Towers',
    createdAt: Date.now(),
    typeOfEvent: 'Open House',
    noOfUnits: 2,
    eventType: 'Auction'
  })
  ?.map((e) => e);

const projectsData = Array(15)
  .fill({
    _id: '65324b628f948036a856611b',
    location: {
      type: 'Point',
      coordinates: [-95.712891, 37.09024]
    },
    name: {
      en: 'Test',
      fa: 'Test',
      tr: 'Test',
      ar: 'Test',
      ru: 'Test',
      de: 'Test',
      fr: 'Test'
    },
    title: {
      en: 'Test',
      fa: 'Test',
      tr: 'Test',
      ar: 'Test',
      ru: 'Test',
      de: 'Test',
      fr: 'Test'
    },
    description: {
      en: 'Test',
      fa: 'Test',
      tr: 'Test',
      ar: 'Test',
      ru: 'Test',
      de: 'Test',
      fr: 'Test'
    },
    noOfUnits: 10,
    price: 1000,
    minimumArea: 100,
    maximumArea: 200,
    interiorAmenities: [
      {
        _id: '652ce8e39a8b88493c375429',
        amenityType: 'interior',
        isActive: true,
        title: {
          en: 'sweet home interior',
          tr: 'tatlı ev',
          ar: 'بيت حلو',
          fr: 'douce maison',
          ru: 'сладкий дом',
          de: 'süßes Zuhause',
          fa: 'خانه شیرین'
        },
        slug: 'sweet-home-interior-8',
        slugId: '8',
        createdAt: '2023-10-16T07:40:19.712Z',
        updatedAt: '2023-10-17T13:23:17.404Z',
        __v: 0
      }
    ],
    exteriorAmenities: [
      {
        _id: '652ce8f89a8b88493c37542e',
        amenityType: 'exterior',
        isActive: true,
        title: {
          en: 'sweet home exterior',
          tr: 'tatlı ev',
          ar: 'بيت حلو ',
          fr: 'douce maison',
          ru: 'сладкий дом',
          de: 'süßes Zuhause',
          fa: 'خانه شیرین '
        },
        slug: 'sweet-home-exterior-9',
        slugId: '9',
        createdAt: '2023-10-16T07:40:40.449Z',
        updatedAt: '2023-10-16T07:40:40.449Z',
        __v: 0
      }
    ],
    logo: 'eb899d88-1564-48b9-a171-f34706360885.jpg',
    images: [
      '4a850a59-c761-4019-bbee-9d8af47f1c3e.jpg',
      'c0b9a165-1271-431f-ade2-4ab04369b226.jpg',
      '4aadf060-688a-43b3-a35c-17f362b25a9f.jpg'
    ],
    planImages: [
      '85805cea-19c1-48b7-86a6-05fa8c7b093e.jpg',
      '85bc457d-8459-40f4-9a0e-8d392dadbad7.jpg',
      '7aa084a0-165a-4e61-9a52-9176c60f9320.jpg'
    ],
    videoLink:
      'https://www.youtube.com/watch?v=XXYlFuWEuKI&list=PsaqweqweqweLI_7Mg2Z_-4I-W_lI55D9lBUkC66ftHMg&index=3',
    threeSixtyViewLink:
      'https://www.pexels.com/photo/silhouette-of-tree-near-body-of-water-during-golden-hour-36717/',
    projectBrochure: '1026ccd6-893b-44dd-a834-c81fdcad4b3b.pdf',
    projectBrochureName: 'dummy.pdf',
    developerName: {
      en: 'Test',
      fa: 'Test',
      tr: 'Test',
      ar: 'Test',
      ru: 'Test',
      de: 'Test',
      fr: 'Test'
    },
    startDate: null,
    deliveryDate: '2023-11-04T00:00:00.000Z',
    isActive: true,
    isFeatured: false,
    featuredStartDate: null,
    featuredEndDate: null,
    isPremium: true,
    premiumStartDate: '2023-10-27T19:00:00.000Z',
    premiumEndDate: '2024-01-27T19:00:00.000Z',
    lastRefresh: '2023-10-25T08:14:54.779Z',
    assignedAgent: null,
    projectType: {
      _id: '650c37c7cf395f05a85fa23d',
      isActive: true,
      title: {
        en: 'Test',
        fa: 'آزمون',
        tr: 'Test',
        ar: 'اختبار',
        ru: 'тест',
        de: 'Test',
        fr: 'Test'
      },
      slug: 'appartment-3',
      slugId: '3',
      createdAt: '2023-09-21T12:32:07.324Z',
      updatedAt: '2023-10-17T11:46:28.662Z',
      __v: 0
    },
    address: 'United States',
    province: null,
    town: null,
    neighborhood: {
      _id: '652423e69cf2fb51f86e9d11',
      name: {
        en: 'Kiriş updated',
        tr: 'Kiriş',
        ar: 'كيريش',
        fr: 'Kiriş',
        ru: 'Кириш',
        de: 'Kiriş',
        fa: 'کیریش'
      },
      type: 'neighborhood',
      ref: '65365ebbd74ee04fc0281b5a',
      isActive: true,
      __v: 0,
      updatedAt: '2023-10-24T09:41:58.371Z'
    },
    slug: 'test-4',
    slugId: '4',
    listingId: '42489232',
    company: {
      _id: '650867b0976aa700e0c88be0',
      location: {
        type: 'Point',
        coordinates: [12.4707246, 41.8777253]
      },
      fullName: null,
      firstName: null,
      lastName: null,
      language: [
        {
          en: 'Abkhaz',
          ar: 'أبخاز',
          fa: 'آبخاز',
          de: 'Abchasisch',
          tr: 'Abhazca',
          ru: 'Абхазский',
          fr: 'Abkhaze'
        },
        {
          en: 'Afrikaans',
          ar: 'الأفريكانية',
          fa: 'آفریکانس',
          de: 'Afrikaans',
          tr: 'Afrikanca',
          ru: 'Африкаанс',
          fr: 'Afrikaans'
        },
        {
          en: 'English',
          ar: 'الإنجليزية',
          fa: 'انگلیسی',
          de: 'Englisch',
          tr: 'İngilizce',
          ru: 'Английский',
          fr: 'Anglais'
        },
        {
          en: 'French',
          ar: 'الفرنسية',
          fa: 'فرانسوی',
          de: 'Französisch',
          tr: 'Fransızca',
          ru: 'Французский',
          fr: 'Français'
        }
      ],
      serviceAreas: {
        en: 'Test',
        fa: 'Test',
        tr: 'Test',
        ar: 'Test',
        ru: 'Test',
        de: 'Test',
        fr: 'Test'
      },
      platformType: 'google',
      signupType: 'normal',
      loginType: 'normal',
      photo: 'c2d90065-baee-4a0c-be46-e9b49aa81462.jpg',
      role: 'company',
      fcmToken: [],
      isActive: true,
      isBlockedByAdmin: false,
      otpCode: null,
      otpCodeExpireAt: null,
      lastLogin: '2023-09-18T15:07:15.028Z',
      socketId: 'P5eSYfqu4EUG7q5RAAAB',
      isOnline: true,
      companyName: {
        en: 'Test Company',
        fa: 'Test Company',
        tr: 'Test Company',
        ar: 'Test Company',
        ru: 'Test Company',
        de: 'Test Company',
        fr: 'Test Company'
      },
      description: {
        en: 'Test Company',
        fa: 'Test Company',
        tr: 'Test Company',
        ar: 'Test Company',
        ru: 'Test Company',
        de: 'Test Company',
        fr: 'Test Company'
      },
      email: 'testcompany@yopmail.com',
      whatsapp: '11111111111',
      phoneNumber: '12345550000',
      slug: 'test-company-24',
      slugId: '24',
      createdAt: '2023-09-18T15:07:28.033Z',
      updatedAt: '2023-10-26T09:47:52.503Z',
      __v: 1,
      availableCreditPoints: 710,
      totalCreditPoints: 1000,
      packageDetails: {
        totalAgents: 20,
        availableAgents: 12,
        totalProperties: 20,
        availableProperties: 20,
        totalProjects: 20,
        availableProjects: 20,
        totalEvents: 20,
        availableEvents: 20,
        startDate: '2023-10-25T10:52:40.164Z',
        endDate: '2023-11-25T10:52:40.000Z',
        isPackageExpired: false,
        _id: '6538f378a135a94a9ce17659',
        packageId: '65377fd2d4d29b6b1ac2424e',
        packageType: 'lite',
        receivePropertyRequests: false,
        includeCompanyAndAgentSearch: false,
        companyLogoInOurPartners: true,
        packageAmount: 200,
        duration: 1,
        updatedAt: '2023-10-25T10:52:40.166Z',
        createdAt: '2023-10-25T10:52:40.166Z'
      },
      followers: ['65114c39079c8219c812d393', '65117dcdd0eb02203c92c457'],
      agents: [
        '65369c83c1a0bf50c00eba45',
        '65390f934601ce4424fb2b91',
        '65391aed540b0c16fc27f199',
        '65391e274a52f03860b7c626',
        '65391e2b4a52f03860b7c63d',
        '653929e9404c631cb85bbb44',
        '65392b921aeee446acb71ab1',
        '65392ce60f23b24590a0579a'
      ],
      address: 'Testaccio, Rome, Metropolitan City of Rome Capital, Italy',
      companyType: {
        en: 'Real Estate Company',
        ar: 'شركة عقارية',
        fa: 'شرکت مشاور املاک',
        de: 'Immobilienunternehmen',
        tr: 'Emlak Şirketi',
        ru: 'Компания по недвижимости',
        fr: 'Société immobilière'
      },
      province: '65365b65f6a0514ae0e43600',
      town: '65365ebbd74ee04fc0281b5a',
      neighborhood: '652423e69cf2fb51f86e9d11',
      registrationNumber: 1222332,
      agentFollowing: [],
      companyFollowing: [],
      passwordChangedAt: '2023-10-06T10:07:27.048Z',
      userId: 0,
      package: '65377fd2d4d29b6b1ac2424e'
    },
    postedBy: {
      _id: '650867b0976aa700e0c88be0',
      location: {
        type: 'Point',
        coordinates: [12.4707246, 41.8777253]
      },
      fullName: null,
      firstName: null,
      lastName: null,
      language: [
        {
          en: 'Abkhaz',
          ar: 'أبخاز',
          fa: 'آبخاز',
          de: 'Abchasisch',
          tr: 'Abhazca',
          ru: 'Абхазский',
          fr: 'Abkhaze'
        },
        {
          en: 'Afrikaans',
          ar: 'الأفريكانية',
          fa: 'آفریکانس',
          de: 'Afrikaans',
          tr: 'Afrikanca',
          ru: 'Африкаанс',
          fr: 'Afrikaans'
        },
        {
          en: 'English',
          ar: 'الإنجليزية',
          fa: 'انگلیسی',
          de: 'Englisch',
          tr: 'İngilizce',
          ru: 'Английский',
          fr: 'Anglais'
        },
        {
          en: 'French',
          ar: 'الفرنسية',
          fa: 'فرانسوی',
          de: 'Französisch',
          tr: 'Fransızca',
          ru: 'Французский',
          fr: 'Français'
        }
      ],
      serviceAreas: {
        en: 'Test',
        fa: 'Test',
        tr: 'Test',
        ar: 'Test',
        ru: 'Test',
        de: 'Test',
        fr: 'Test'
      },
      platformType: 'google',
      signupType: 'normal',
      loginType: 'normal',
      photo: 'c2d90065-baee-4a0c-be46-e9b49aa81462.jpg',
      role: 'company',
      fcmToken: [],
      isActive: true,
      isBlockedByAdmin: false,
      otpCode: null,
      otpCodeExpireAt: null,
      lastLogin: '2023-09-18T15:07:15.028Z',
      socketId: 'P5eSYfqu4EUG7q5RAAAB',
      isOnline: true,
      companyName: {
        en: 'Test Company',
        fa: 'Test Company',
        tr: 'Test Company',
        ar: 'Test Company',
        ru: 'Test Company',
        de: 'Test Company',
        fr: 'Test Company'
      },
      description: {
        en: 'Test Company',
        fa: 'Test Company',
        tr: 'Test Company',
        ar: 'Test Company',
        ru: 'Test Company',
        de: 'Test Company',
        fr: 'Test Company'
      },
      email: 'testcompany@yopmail.com',
      whatsapp: '11111111111',
      phoneNumber: '12345550000',
      slug: 'test-company-24',
      slugId: '24',
      createdAt: '2023-09-18T15:07:28.033Z',
      updatedAt: '2023-10-26T09:47:52.503Z',
      __v: 1,
      availableCreditPoints: 710,
      totalCreditPoints: 1000,
      packageDetails: {
        totalAgents: 20,
        availableAgents: 12,
        totalProperties: 20,
        availableProperties: 20,
        totalProjects: 20,
        availableProjects: 20,
        totalEvents: 20,
        availableEvents: 20,
        startDate: '2023-10-25T10:52:40.164Z',
        endDate: '2023-11-25T10:52:40.000Z',
        isPackageExpired: false,
        _id: '6538f378a135a94a9ce17659',
        packageId: '65377fd2d4d29b6b1ac2424e',
        packageType: 'lite',
        receivePropertyRequests: false,
        includeCompanyAndAgentSearch: false,
        companyLogoInOurPartners: true,
        packageAmount: 200,
        duration: 1,
        updatedAt: '2023-10-25T10:52:40.166Z',
        createdAt: '2023-10-25T10:52:40.166Z'
      },
      followers: ['65114c39079c8219c812d393', '65117dcdd0eb02203c92c457'],
      agents: [
        '65369c83c1a0bf50c00eba45',
        '65390f934601ce4424fb2b91',
        '65391aed540b0c16fc27f199',
        '65391e274a52f03860b7c626',
        '65391e2b4a52f03860b7c63d',
        '653929e9404c631cb85bbb44',
        '65392b921aeee446acb71ab1',
        '65392ce60f23b24590a0579a'
      ],
      address: 'Testaccio, Rome, Metropolitan City of Rome Capital, Italy',
      companyType: {
        en: 'Real Estate Company',
        ar: 'شركة عقارية',
        fa: 'شرکت مشاور املاک',
        de: 'Immobilienunternehmen',
        tr: 'Emlak Şirketi',
        ru: 'Компания по недвижимости',
        fr: 'Société immobilière'
      },
      province: '65365b65f6a0514ae0e43600',
      town: '65365ebbd74ee04fc0281b5a',
      neighborhood: '652423e69cf2fb51f86e9d11',
      registrationNumber: 1222332,
      agentFollowing: [],
      companyFollowing: [],
      passwordChangedAt: '2023-10-06T10:07:27.048Z',
      userId: 0,
      package: '65377fd2d4d29b6b1ac2424e'
    },
    projectUnit: [],
    createdAt: '2023-10-20T09:41:54.346Z',
    updatedAt: '2023-10-25T08:15:12.922Z',
    __v: 0
  })
  ?.map((e) => e);

const blogsData = Array(8).fill({
  _id: 1,
  image: houseAd,
  price: 1000000,
  title: 'Resources that we throught you may find useful',
  description:
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
  postedBy: {
    firstName: 'Admin',
    lastName: ''
  },
  createdAt: Date.now()
});

// Agent/Company Filters
const languages = ['English', 'Turkish'];
// Agent/Company Filters

// Property Form Options
export const furnitureFiltersOptions = [
  {
    en: 'Any',
    tr: 'Tümü',
    ar: 'الكل',
    fr: 'Tout',
    ru: 'Все',
    de: 'Anye',
    fa: 'همه'
  },
  {
    en: 'Fully Furnished',
    tr: 'Tam eşyalı',
    ar: 'مفروش',
    fr: 'Meublé',
    ru: 'Меблированный',
    de: 'Möbliert',
    fa: 'مبله'
  },
  {
    en: 'Unfurnished',
    tr: 'Eşyasız',
    ar: 'غير مفروش',
    fr: 'Non meublé',
    ru: 'Без мебели',
    de: 'Unmöbliert',
    fa: 'مبله نشده'
  },
  {
    en: 'Partially Furnished',
    tr: 'Kısmen eşyalı',
    ar: 'مفروش جزئياً',
    fr: 'En partie meublé',
    ru: 'Частично меблирована',
    de: 'Teilmöbliert',
    fa: 'تا حدی مبله'
  }
];
const furnitureOptions = [
  {
    en: 'Fully Furnished',
    tr: 'Tam eşyalı',
    ar: 'مفروش',
    fr: 'Meublé',
    ru: 'Меблированный',
    de: 'Möbliert',
    fa: 'مبله'
  },
  {
    en: 'Unfurnished',
    tr: 'Eşyasız',
    ar: 'غير مفروش',
    fr: 'Non meublé',
    ru: 'Без мебели',
    de: 'Unmöbliert',
    fa: 'مبله نشده'
  },
  {
    en: 'Partially Furnished',
    tr: 'Kısmen eşyalı',
    ar: 'مفروش جزئياً',
    fr: 'En partie meublé',
    ru: 'Частично меблирована',
    de: 'Teilmöbliert',
    fa: 'تا حدی مبله'
  }
];
const propertyOrientationOptions = [
  // {
  //   en: 'Any',
  //   tr: 'Tümü',
  //   ar: 'الكل',
  //   fr: 'Tout',
  //   ru: 'Все',
  //   de: 'Anye',
  //   fa: 'همه'
  // },
  {
    en: 'North',
    tr: 'Kuzeyinde',
    ar: 'شمال',
    fr: 'Nord',
    ru: 'к северу',
    de: 'Norden',
    fa: 'شمال'
  },
  {
    en: 'South',
    tr: 'Güney',
    ar: 'جنوب',
    fr: 'Sud',
    ru: 'юг',
    de: 'Süd',
    fa: 'جنوب'
  },
  {
    en: 'East',
    tr: 'Doğu',
    ar: 'شرق',
    fr: 'Est',
    ru: 'Восток',
    de: 'Osten',
    fa: 'مشرق'
  },
  {
    en: 'West',
    tr: 'Batı',
    ar: 'غرب',
    fr: 'Ouest',
    ru: 'Запад',
    de: 'Westen',
    fa: 'غرب'
  },
  {
    en: 'Northeast',
    tr: 'kuzeydoğu',
    ar: 'شمال شرق',
    fr: 'Nord-Est',
    ru: 'К северо-востоку',
    de: 'Nordost',
    fa: 'شمال شرقی'
  },
  {
    en: 'Northwest',
    tr: 'Kuzey Batı',
    ar: 'شمال غرب',
    fr: 'Nord Ouest',
    ru: 'Северо-Запад',
    de: 'Nordwest',
    fa: 'شمال غربی'
  },
  {
    en: 'Southeast',
    tr: 'Güneydoğu',
    ar: 'جنوب شرق',
    fr: 'Sud-est',
    ru: 'Юго-восток',
    de: 'Süd-Ost',
    fa: 'جنوب شرقی'
  },
  {
    en: 'Southwest',
    tr: 'Güneybatı',
    ar: 'جنوب غرب',
    fr: 'Sud-ouest',
    ru: 'Юго-запад',
    de: 'Südwesten',
    fa: 'جنوب غربی'
  },
  {
    en: 'sea view',
    tr: 'Deniz Manzaralı',
    ar: 'اطلالة البحر',
    fr: 'vue sur la mer',
    ru: 'вид на море',
    de: 'Seeblick',
    fa: 'منظره دریا'
  },
  {
    en: 'Lake View',
    tr: 'göl Manzaralı',
    ar: 'إطلالة على البحيرة',
    fr: 'vue sur le lac',
    ru: 'вид на озеро',
    de: 'Seeblick',
    fa: 'نمای دریاچه'
  },
  {
    en: 'City view',
    tr: 'Şehir Manzaralı',
    ar: 'إطلالة على المدينة',
    fr: 'Vue de la ville',
    ru: 'Вид на город',
    de: 'Stadtblick',
    fa: 'منظر شهری'
  },
  {
    en: 'Mountain view',
    tr: 'Dağ Manzaralı',
    ar: 'إطلالة على الجبل',
    fr: 'Vue sur la montagne',
    ru: 'Вид на горы',
    de: 'Blick auf die Berge',
    fa: 'منظره کوهستانی'
  },
  {
    en: 'Garden view',
    tr: 'Bahçe Manzaralı',
    ar: 'إطلالة على الحديقة',
    fr: 'Vue du jardin',
    ru: 'Вид на сад',
    de: 'Gartenaussicht',
    fa: 'منظره باغ'
  },
  {
    en: 'Inner courtyard view',
    tr: 'İç avlu Manzaralı',
    ar: 'إطلالة الفناء الداخلي',
    fr: 'Vue sur la cour intérieure',
    ru: 'Вид на внутренний двор',
    de: 'Blick auf den Innenhof',
    fa: 'نمای حیاط داخلی'
  },
  {
    en: 'Pool view',
    tr: 'Havuz Manzaralı',
    ar: 'إطلالة على المسبح',
    fr: 'Vue de la piscine',
    ru: 'С видом на бассейн',
    de: 'Blick auf den Pool',
    fa: 'نمای استخر'
  },
  {
    en: 'Park view',
    tr: 'Park Manzaralı',
    ar: 'إطلالة على منتزه',
    fr: 'Vue sur le parc',
    ru: 'Вид на парк',
    de: 'Parkblick',
    fa: 'نمای پارک'
  },
  {
    en: 'Landmark view',
    tr: 'Simgesel yapı Manzaralı',
    ar: 'إطلالة على معلم',
    fr: "Vue sur le site d'intérêt",
    ru: 'Вид на достопримечательность',
    de: 'Wahrzeichen-Ansicht',
    fa: 'نمای برجسته'
  }
];
const preferredContactMethodOptions = [
  {
    label: 'Phone Call',
    value: 'phoneCall'
  },
  {
    label: 'Email',
    value: 'email'
  },
  {
    label: 'WhatsApp',
    value: 'whatsApp'
  }
];
const enquiryTypeOptions = [
  {
    label: 'Looking to Buy a Property',
    value: 'lookingToBuyAProperty'
  },
  {
    label: 'Looking to Rent a Property',
    value: 'lookingToRentAProperty'
  },
  {
    label: 'Looking to Sell my Property',
    value: 'lookingToSellMyProperty'
  },

  {
    label: 'Looking to Rent my Property',
    value: 'lookingToRentMyProperty'
  }
];
const furnishingOptions = [
  {
    label: 'Fully Furnished',
    value: 'fullyFurnished'
  },
  {
    label: 'Partially Furnished',
    value: 'partiallyFurnished'
  },
  {
    label: 'Unfurnished',
    value: 'unfurnished'
  }
];
const propertyStatusOptions = [
  {
    en: 'New',
    tr: 'Sıfır',
    ar: 'جديد',
    fr: 'Nouveau bâtiment',
    ru: 'новое здание',
    de: 'Neues Gebäude',
    fa: 'ساختمان جدید'
  },
  {
    en: 'Second-hand',
    tr: 'İkinci el',
    ar: 'مستخدم',
    fr: "D'occasion",
    ru: 'Подержанный',
    de: 'Gebraucht',
    fa: 'دست دوم'
  },
  {
    en: 'Renovated',
    tr: 'Yenilenmiş',
    ar: 'مُجدد'
  },
  {
    en: 'Under construction',
    tr: 'Yapım Aşamasında',
    ar: 'تحت الإنشاء',
    fr: 'En construction',
    ru: 'В разработке',
    de: 'Im Bau',
    fa: 'در دست ساخت'
  },
  {
    en: 'Shell and Core',
    tr: 'Kabuk ve Çekirdek',
    ar: 'على العظم',
    fr: 'Coque et noyau',
    ru: 'Оболочка и ядро',
    de: 'Schale und Kern',
    fa: 'پوسته و هسته'
  }
];

export const propertyTypes = [
  {
    en: 'Sales director',
    tr: 'Satış Direktörü',
    ar: 'مدير المبيعات',
    fr: 'Directeur des ventes',
    ru: 'Директор по продажам',
    de: 'Vertriebsleiter',
    fa: 'مدیر فروش'
  },
  {
    en: 'Sales Manager',
    tr: 'Satış Müdürü',
    ar: 'مدير المبيعات',
    fr: 'Directeur des ventes',
    ru: 'Менеджер по продажам',
    de: 'Verkaufsleiter',
    fa: 'مدیر فروش'
  },
  {
    en: 'Senior Property Consultant',
    tr: 'Kıdemli Mülkiyet Danışmanı',
    ar: 'مستشار عقاري كبير',
    fr: 'Conseiller immobilier senior',
    ru: 'Старший консультант по недвижимости',
    de: 'Senior Immobilienberater',
    fa: 'مشاور املاک برتر'
  },
  {
    en: 'Property Consultant',
    tr: 'Mülkiyet Danışmanı',
    ar: 'مستشار عقاري',
    fr: 'Conseiller immobilier',
    ru: 'Консультант по недвижимости',
    de: 'Immobilienberater',
    fa: 'مشاور املاک'
  },
  {
    en: 'Property Specialist',
    tr: 'Mülkiyet Uzmanı',
    ar: 'خبير العقارات',
    fr: 'Spécialiste en immobilier',
    ru: 'Специалист по недвижимости',
    de: 'Immobilienfachmann',
    fa: 'تخصصی املاک'
  },
  {
    en: 'Property Agent',
    tr: 'Mülkiyet Acentesi',
    ar: 'وكيل العقارات',
    fr: 'Agent immobilier',
    ru: 'Агент по недвижимости',
    de: 'Immobilienmakler',
    fa: 'نماینده املاک'
  },
  {
    en: 'Sales Agent',
    tr: 'Satış Temsilcisi',
    ar: 'وكيل المبيعات',
    fr: 'Agent commercial',
    ru: 'Агент по продажам',
    de: 'Vertriebsmitarbeiter',
    fa: 'نماینده فروش'
  },
  {
    en: 'Leasing Agent',
    tr: 'Kiralama Temsilcisi',
    ar: 'وكيل التأجير',
    fr: 'Agent de location',
    ru: 'Агент по аренде',
    de: 'Vermietungsmitarbeiter',
    fa: 'نماینده اجاره'
  },
  {
    en: 'Marketing Consultant',
    tr: 'Pazarlama Danışmanı',
    ar: 'مستشار التسويق',
    fr: 'Conseiller en marketing',
    ru: 'Маркетинговый консультант',
    de: 'Marketingberater',
    fa: 'مشاور بازاریابی'
  }
];

const propertyStatusFilterOptions = [
  {
    en: 'Any',
    tr: 'Tümü',
    ar: 'الكل',
    fr: 'Tout',
    ru: 'Все',
    de: 'Anye',
    fa: 'همه'
  },
  {
    en: 'New',
    tr: 'Sıfır',
    ar: 'جديد',
    fr: 'Nouveau bâtiment',
    ru: 'новое здание',
    de: 'Neues Gebäude',
    fa: 'ساختمان جدید'
  },
  {
    en: 'Second-hand',
    tr: 'İkinci el',
    ar: 'مستخدم',
    fr: "D'occasion",
    ru: 'Подержанный',
    de: 'Gebraucht',
    fa: 'دست دوم'
  },
  {
    en: 'Renovated',
    tr: 'Yenilenmiş',
    ar: 'مُجدد'
  },
  {
    en: 'Under construction',
    tr: 'Yapım Aşamasında',
    ar: 'تحت الإنشاء',
    fr: 'En construction',
    ru: 'В разработке',
    de: 'Im Bau',
    fa: 'در دست ساخت'
  },
  {
    en: 'Shell and Core',
    tr: 'Kabuk ve Çekirdek',
    ar: 'على العظم',
    fr: 'Coque et noyau',
    ru: 'Оболочка и ядро',
    de: 'Schale und Kern',
    fa: 'پوسته و هسته'
  }
];
const propertyPurposeOptions = [
  {
    en: 'Buy',
    fa: 'خرید',
    tr: 'Satın Al',
    ar: 'شراء',
    ru: 'Купить',
    de: 'Kaufen',
    fr: 'Acheter'
  },
  {
    en: 'Rent',
    fa: 'اجاره',
    tr: 'Kiralama',
    ar: 'ايجار',
    ru: 'Аренда',
    de: 'Mieten',
    fr: 'Louer'
  }
];
const rentDurationPeriodOptions = [
  {
    label: 'Yearly',
    value: 'yearly'
  },
  {
    label: 'Monthly',
    value: 'monthly'
  }
];

export const languagesOptions = [
  { label: 'English', value: 'en', flag: EnglishLanguage },
  { label: 'Farsi', value: 'fa', flag: PersianLanguage },
  { label: 'Turkish', value: 'tr', flag: TurkishLanguage },
  { label: 'Arabic', value: 'ar', flag: ArabicLanguage },
  { label: 'Russian', value: 'ru', flag: RussianLanguage },
  { label: 'German', value: 'de', flag: GermanLanguage },
  { label: 'French', value: 'fr', flag: FrenchLanguage }
];

export const areaUnitOptions = [
  { label: 'Meter Sq. (m²)', value: 'm²' },
  { label: 'Feet Sq. (ft²)', value: 'ft²' }
];

export const CURRENCY_UNITS = [
  { label: 'US Dollar', value: 'USD', symbol: '$' },
  { label: 'Euro', value: 'EUR', symbol: '€' },
  { label: 'Pound', value: 'GBP', symbol: '£' },
  { label: 'Turkish lira', value: 'TRY', symbol: '₺' }
];

export const languagesKeysObject = convertArrayKeysToObject(languagesOptions?.map((e) => e?.value));

export const propertyFormLanguages = {
  en: {
    propertyTitle: '',
    propertyTitlePlaceholder: '',
    propertyDescription: '',
    propertyDescriptionPlaceholder: '',
    propertyPurpose: 'Property Purpose',
    propertyPurposePlaceholder: 'Select Property Purpose',
    propertyType: 'Property Type',
    propertyTypePlaceholder: 'Select Property Type',
    propertyPrice: '',
    propertyPricePlaceholder: '',
    propertyArea: '',
    propertyAreaPlaceholder: '',
    propertyRooms: '',
    propertyRoomsPlaceholder: '',
    propertyBaths: '',
    propertyBathsPlaceholder: '',
    floorLevel: '',
    floorLevelPlaceholder: '',
    furniture: '',
    furniturePlaceholder: '',
    exteriorAmenities: '',
    exteriorAmenitiesPlaceholder: '',
    interiorAmenities: '',
    interiorAmenitiesPlaceholder: '',
    propertyStatus: '',
    propertyStatusPlaceholder: '',
    parkingSpace: '',
    parkingSpacePlaceholder: '',
    propertyAge: '',
    propertyAgePlaceholder: '',
    propertyOrientation: '',
    propertyOrientationPlaceholder: ''

    // Location
  }
};

export const allLanguagesArray = [
  {
    en: 'Abkhaz',
    ar: 'أبخاز',
    fa: 'آبخاز',
    de: 'Abchasisch',
    tr: 'Abhazca',
    ru: 'Абхазский',
    fr: 'Abkhaze'
  },
  {
    en: 'Afar',

    ar: 'عفار',
    fa: 'عفار',
    de: 'Danakil',
    tr: 'Afarca',
    ru: 'Афарский',
    fr: 'Afar'
  },
  {
    en: 'Afrikaans',

    ar: 'الأفريكانية',
    fa: 'آفریکانس',
    de: 'Afrikaans',
    tr: 'Afrikanca',
    ru: 'Африкаанс',
    fr: 'Afrikaans'
  },
  {
    en: 'Akan',

    ar: 'الأكان',
    fa: 'آکان',
    de: 'Akan',
    tr: 'Akan Dili',
    ru: 'Акан',
    fr: 'Akan'
  },
  {
    en: 'Albanian',

    ar: 'الألبانية',
    fa: 'البانی',
    de: 'Albanisch',
    tr: 'Arnavutça',
    ru: 'Албанский',
    fr: 'Albanais'
  },
  {
    en: 'Amharic',

    ar: 'الأمهرية',
    fa: 'امهری',
    de: 'Amharisch',
    tr: 'Amharca',
    ru: 'Амхарский',
    fr: 'Amharique'
  },
  {
    en: 'Arabic',

    ar: 'العربية',
    fa: 'عربی',
    de: 'Arabisch',
    tr: 'Arapça',
    ru: 'Арабский',
    fr: 'Arabe'
  },
  {
    en: 'Aragonese',

    ar: 'أراغونية',
    fa: 'آراگونی',
    de: 'Aragonisch',
    tr: 'Aragonca',
    ru: 'Арагонский',
    fr: 'Aragonais'
  },
  {
    en: 'Armenian',

    ar: 'الأرمينية',
    fa: 'ارمنی',
    de: 'Armenisch',
    tr: 'Ermenice',
    ru: 'Армянский',
    fr: 'Arménien'
  },
  {
    en: 'Assamese',

    ar: 'الأسامية',
    fa: 'آسامی',
    de: 'Assamesisch',
    tr: 'Asamca',
    ru: 'Ассамский',
    fr: 'Assamais'
  },
  {
    en: 'Avaric',

    ar: 'الأفاريقية',
    fa: 'آواری',
    de: 'Awarisch',
    tr: 'Avarca',
    ru: 'Аварский',
    fr: 'Avar'
  },
  {
    en: 'Avestan',

    ar: 'الأفستي',
    fa: 'اوستایی',
    de: 'Avestisch',
    tr: 'Avestanca',
    ru: 'Авестийский',
    fr: 'Avestique'
  },
  {
    en: 'Aymara',

    ar: 'الأيمارا',
    fa: 'آیمارا',
    de: 'Aymara',
    tr: 'Aymara Dili',
    ru: 'Аймара',
    fr: 'Aymara'
  },
  {
    en: 'Azerbaijani',

    ar: 'الأذربيجانية',
    fa: 'آذربایجانی',
    de: 'Aserbaidschanisch',
    tr: 'Azerbaycan Dili',
    ru: 'Азербайджанский',
    fr: 'Azéri'
  },
  {
    en: 'Bambara',

    ar: 'البامبارا',
    fa: 'بامبارا',
    de: 'Bambara',
    tr: 'Bambara Dili',
    ru: 'Бамбара',
    fr: 'Bambara'
  },
  {
    en: 'Bashkir',

    ar: 'الباشكيرية',
    fa: 'باشکیری',
    de: 'Baschkirisch',
    tr: 'Başkurtça',
    ru: 'Башкирский',
    fr: 'Bachkir'
  },
  {
    en: 'Basque',

    ar: 'الباسكي',
    fa: 'باسک',
    de: 'Baskisch',
    tr: 'Baskça',
    ru: 'Баскский',
    fr: 'Basque'
  },
  {
    en: 'Belarusian',

    ar: 'البيلاروسية',
    fa: 'بلاروسی',
    de: 'Weißrussisch',
    tr: 'Beyaz Rusça',
    ru: 'Белорусский',
    fr: 'Biélorusse'
  },
  {
    en: 'Bengali; Bangla',

    ar: 'البنغالية',
    fa: 'بنگالی',
    de: 'Bengali',
    tr: 'Bengalce',
    ru: 'Бенгальский',
    fr: 'Bengali; Bangla'
  },
  {
    en: 'Bihari',

    ar: 'البيهارية',
    fa: 'بیهاری',
    de: 'Bihari',
    tr: 'Bihari Dili',
    ru: 'Бихари',
    fr: 'Bihari'
  },
  {
    en: 'Bislama',

    ar: 'البيسلاما',
    fa: 'بیسلاما',
    de: 'Bislama',
    tr: 'Bislama Dili',
    ru: 'Бислама',
    fr: 'Bichelamar'
  },
  {
    en: 'Bosnian',

    ar: 'البوسنية',
    fa: 'بوسنیایی',
    de: 'Bosnisch',
    tr: 'Bosna Dili',
    ru: 'Боснийский',
    fr: 'Bosnien'
  },
  {
    en: 'Breton',

    ar: 'البريتونية',
    fa: 'برتون',
    de: 'Bretonisch',
    tr: 'Bretonca',
    ru: 'Бретонский',
    fr: 'Breton'
  },
  {
    en: 'Bulgarian',

    ar: 'البلغارية',
    fa: 'بلغاری',
    de: 'Bulgarisch',
    tr: 'Bulgarca',
    ru: 'Болгарский',
    fr: 'Bulgare'
  },
  {
    en: 'Burmese',

    ar: 'البورمية',
    fa: 'برمه',
    de: 'Burmesisch',
    tr: 'Burmaca',
    ru: 'Бирманский',
    fr: 'Birman'
  },
  {
    en: 'Catalan; Valencian',

    ar: 'الكتالانية',
    fa: 'کاتالانیایی',
    de: 'Katalanisch',
    tr: 'Katalanca',
    ru: 'Каталанский',
    fr: 'Catalan'
  },
  {
    en: 'Chamorro',

    ar: 'التشامورو',
    fa: 'چامورو',
    de: 'Chamorro',
    tr: 'Chamorro Dili',
    ru: 'Чаморро',
    fr: 'Chamorro'
  },
  {
    en: 'Chechen',

    ar: 'التشيشن',
    fa: 'چچن',
    de: 'Tschetschenisch',
    tr: 'Çeçen Dili',
    ru: 'Чеченский',
    fr: 'Tchétchène'
  },
  {
    en: 'Chichewa; Chewa; Nyanja',

    ar: 'تشيتشوا؛ تشيو؛ نيانجا',
    fa: 'چیچو؛ چوا؛ نیانجا',
    de: 'Chichewa; Chewa; Nyanja',
    tr: 'Chichewa; Chewa; Nyanja Dili',
    ru: 'Чичева; Чева; Ньянджа',
    fr: 'Chichewa; Chewa; Nyanja'
  },
  {
    en: 'Chinese',

    ar: 'الصينية',
    fa: 'چینی',
    de: 'Chinesisch',
    tr: 'Çince',
    ru: 'Китайский',
    fr: 'Chinois'
  },
  {
    en: 'Chuvash',

    ar: 'التشوفاش',
    fa: 'چوواش',
    de: 'Tschuwaschisch',
    tr: 'Çuvaşça',
    ru: 'Чувашский',
    fr: 'Tchouvache'
  },
  {
    en: 'Cornish',

    ar: 'الكورنية',
    fa: 'کورنیش',
    de: 'Kornisch',
    tr: 'Kornca',
    ru: 'Корнийский',
    fr: 'Cornique'
  },
  {
    en: 'Corsican',

    ar: 'الكورسيكية',
    fa: 'کورسیکایی',
    de: 'Korsisch',
    tr: 'Korsikaca',
    ru: 'Корсиканский',
    fr: 'Corse'
  },
  {
    en: 'Cree',

    ar: 'الكري',
    fa: 'کری',
    de: 'Cree',
    tr: 'Cree Dili',
    ru: 'Кри',
    fr: 'Cris'
  },
  {
    en: 'Croatian',

    ar: 'الكرواتية',
    fa: 'کرواتی',
    de: 'Kroatisch',
    tr: 'Hırvatça',
    ru: 'Хорватский',
    fr: 'Croate'
  },
  {
    en: 'Czech',

    ar: 'التشيكية',
    fa: 'چکی',
    de: 'Tschechisch',
    tr: 'Çekçe',
    ru: 'Чешский',
    fr: 'Tchèque'
  },
  {
    en: 'Danish',

    ar: 'الدنماركية',
    fa: 'دانمارکی',
    de: 'Dänisch',
    tr: 'Danca',
    ru: 'Датский',
    fr: 'Danois'
  },
  {
    en: 'Divehi; Dhivehi; Maldivian;',

    ar: 'الديفهي؛ الذيفي؛ المالديفية',
    fa: 'دیوهی؛ ذیوهی؛ مالدیوی',
    de: 'Dhivehi; Dhivehi; Maledivisch;',
    tr: 'Divehi; Dhivehi; Maldivce;',
    ru: 'Дивехи; Дивехи; Мальдивский;',
    fr: 'Divehi; Dhivehi; Maldivien;'
  },
  {
    en: 'Dutch',

    ar: 'الهولندية',
    fa: 'هلندی',
    de: 'Holländisch',
    tr: 'Felemenkçe',
    ru: 'Голландский',
    fr: 'Néerlandais'
  },
  {
    en: 'Dzongkha',

    ar: 'الزونغكا',
    fa: 'ژونگکایی',
    de: 'Dzongkha',
    tr: 'Dzongkha Dili',
    ru: 'Дзонгкха',
    fr: 'Dzongkha'
  },
  {
    en: 'English',

    ar: 'الإنجليزية',
    fa: 'انگلیسی',
    de: 'Englisch',
    tr: 'İngilizce',
    ru: 'Английский',
    fr: 'Anglais'
  },
  {
    en: 'Esperanto',

    ar: 'إسبرانتو',
    fa: 'اسپرانتو',
    de: 'Esperanto',
    tr: 'Esperanto Dili',
    ru: 'Эсперанто',
    fr: 'Espéranto'
  },
  {
    en: 'Estonian',

    ar: 'الإستونية',
    fa: 'استونیایی',
    de: 'Estnisch',
    tr: 'Estonca',
    ru: 'Эстонский',
    fr: 'Estonien'
  },
  {
    en: 'Ewe',

    ar: 'الإيو',
    fa: 'اوه',
    de: 'Ewe',
    tr: 'Ewe Dili',
    ru: 'Эве',
    fr: 'Éwé'
  },
  {
    en: 'Faroese',

    ar: 'الفارويز',
    fa: 'فاروی',
    de: 'Färöisch',
    tr: 'Faroece',
    ru: 'Фарерский',
    fr: 'Féroïen'
  },
  {
    en: 'Fijian',

    ar: 'فيجي',
    fa: 'فیجی',
    de: 'Fidschi',
    tr: 'Fiji Dili',
    ru: 'Фиджи',
    fr: 'Fidjien'
  },
  {
    en: 'Finnish',

    ar: 'الفنلندية',
    fa: 'فنلاندی',
    de: 'Finnisch',
    tr: 'Fince',
    ru: 'Финский',
    fr: 'Finnois'
  },
  {
    en: 'French',

    ar: 'الفرنسية',
    fa: 'فرانسوی',
    de: 'Französisch',
    tr: 'Fransızca',
    ru: 'Французский',
    fr: 'Français'
  },
  {
    en: 'Fula; Fulah; Pulaar; Pular',

    ar: 'فولاني؛ فولاح؛ بولار؛ بولار',
    fa: 'فولا؛ فولاه؛ پولار؛ پولار',
    de: 'Fula; Fulah; Pulaar; Pular',
    tr: 'Fula; Fulah; Pulaar; Pular Dili',
    ru: 'Фула; Фулах; Пулаар; Пуляр',
    fr: 'Peul; Fulfulde; Fula; Fulfulde'
  },
  {
    en: 'Galician',

    ar: 'الغاليسية',
    fa: 'گالیسیایی',
    de: 'Galizisch',
    tr: 'Galiçyaca',
    ru: 'Галисийский',
    fr: 'Galicien'
  },
  {
    en: 'Georgian',

    ar: 'الجورجية',
    fa: 'گرجی',
    de: 'Georgisch',
    tr: 'Gürcüce',
    ru: 'Грузинский',
    fr: 'Géorgien'
  },
  {
    en: 'German',

    ar: 'الألمانية',
    fa: 'آلمانی',
    de: 'Deutsch',
    tr: 'Almanca',
    ru: 'Немецкий',
    fr: 'Allemand'
  },
  {
    en: 'Greek, Modern',

    ar: 'اليونانية الحديثة',
    fa: 'یونانی مدرن',
    de: 'Neugriechisch',
    tr: 'Modern Yunanca',
    ru: 'Современный греческий',
    fr: 'Grec moderne'
  },
  {
    en: 'Guaraní',

    ar: 'الغواراني',
    fa: 'گوارانی',
    de: 'Guaraní',
    tr: 'Guaranice',
    ru: 'Гуарани',
    fr: 'Guarani'
  },
  {
    en: 'Gujarati',

    ar: 'الغوجاراتية',
    fa: 'گجراتی',
    de: 'Gujarati',
    tr: 'Güceratça',
    ru: 'Гуджарати',
    fr: 'Gujarati'
  },
  {
    en: 'Haitian; Haitian Creole',

    ar: 'هائيتي؛ الكريولية الهايتية',
    fa: 'هائیتی؛ کریول هائیتی',
    de: 'Haitianisch; Haitianisches Kreol',
    tr: 'Haiti Kreyolu',
    ru: 'Гаитянский; Гаитянский креольский',
    fr: 'Haïtien; Créole haïtien'
  },
  {
    en: 'Hausa',

    ar: 'الهوسا',
    fa: 'هوسا',
    de: 'Haussa',
    tr: 'Hausa Dili',
    ru: 'Хауса',
    fr: 'Haoussa'
  },
  {
    en: 'Hebrew (modern)',

    ar: 'العبرية (الحديثة)',
    fa: 'عبری (مدرن)',
    de: 'Hebräisch (Modern)',
    tr: 'Modern İbranice',
    ru: 'Современный иврит',
    fr: 'Hébreu moderne'
  },
  {
    en: 'Herero',

    ar: 'الهيريرو',
    fa: 'هررو',
    de: 'Herero',
    tr: 'Herero Dili',
    ru: 'Гереро',
    fr: 'Herero'
  },
  {
    en: 'Hindi',

    ar: 'الهندية',
    fa: 'هندی',
    de: 'Hindi',
    tr: 'Hintçe',
    ru: 'Хинди',
    fr: 'Hindi'
  },
  {
    en: 'Hiri Motu',

    ar: 'هيري موتو',
    fa: 'هیری موتو',
    de: 'Hiri Motu',
    tr: 'Hiri Motu Dili',
    ru: 'Хиримоту',
    fr: 'Hiri Motu'
  },
  {
    en: 'Hungarian',

    ar: 'المجرية',
    fa: 'مجارستانی',
    de: 'Ungarisch',
    tr: 'Macarca',
    ru: 'Венгерский',
    fr: 'Hongrois'
  },
  {
    en: 'Interlingua',

    ar: 'الإنترلينغوا',
    fa: 'انترلینگوآ',
    de: 'Interlingua',
    tr: 'Interlingua Dili',
    ru: 'Интерлингва',
    fr: 'Interlingua'
  },
  {
    en: 'Indonesian',

    ar: 'الإندونيسية',
    fa: 'اندونزیایی',
    de: 'Indonesisch',
    tr: 'Endonezce',
    ru: 'Индонезийский',
    fr: 'Indonésien'
  },
  {
    en: 'Interlingue',

    ar: 'الإنترلينجو',
    fa: 'آنترلینگوئه',
    de: 'Interlingue',
    tr: 'Interlingue Dili',
    ru: 'Интерлингве',
    fr: 'Interlingue'
  },
  {
    en: 'Irish',

    ar: 'الإيرلندية',
    fa: 'ایرلندی',
    de: 'Irisch',
    tr: 'İrlandaca',
    ru: 'Ирландский',
    fr: 'Irlandais'
  },
  {
    en: 'Igbo',

    ar: 'الإيجبو',
    fa: 'ایگبو',
    de: 'Igbo',
    tr: 'Igbo Dili',
    ru: 'Игбо',
    fr: 'Igbo'
  },
  {
    en: 'Inupiaq',

    ar: 'الإينبياك',
    fa: 'اینوپیاک',
    de: 'Inupiaq',
    tr: 'Inupiaq Dili',
    ru: 'Инупиак',
    fr: 'Inupiaq'
  },
  {
    en: 'Ido',

    ar: 'الإيدو',
    fa: 'ایدو',
    de: 'Ido',
    tr: 'Ido Dili',
    ru: 'Идо',
    fr: 'Ido'
  },
  {
    en: 'Icelandic',

    ar: 'الأيسلندية',
    fa: 'ایسلندی',
    de: 'Isländisch',
    tr: 'İzlandaca',
    ru: 'Исландский',
    fr: 'Islandais'
  },
  {
    en: 'Italian',

    ar: 'الإيطالية',
    fa: 'ایتالیایی',
    de: 'Italienisch',
    tr: 'İtalyanca',
    ru: 'Итальянский',
    fr: 'Italien'
  },
  {
    en: 'Inuktitut',

    ar: 'الإينوكتيتوت',
    fa: 'اینوکتیتوت',
    de: 'Inuktitut',
    tr: 'Inuktitut Dili',
    ru: 'Инуктитут',
    fr: 'Inuktitut'
  },
  {
    en: 'Japanese',

    ar: 'اليابانية',
    fa: 'ژاپنی',
    de: 'Japanisch',
    tr: 'Japonca',
    ru: 'Японский',
    fr: 'Japonais'
  },
  {
    en: 'Javanese',

    ar: 'الجاوية',
    fa: 'جاوایی',
    de: 'Javanisch',
    tr: 'Cava Dili',
    ru: 'Яванский',
    fr: 'Javanais'
  },
  {
    en: 'Kalaallisut, Greenlandic',

    ar: 'الكالاليست، الجرينلاندي',
    fa: 'کالالیسوت، گرینلندی',
    de: 'Kalaallisut, Grönländisch',
    tr: 'Kalaallisut, Grönlandca',
    ru: 'Kalaallisut, Гренландский',
    fr: 'Kalaallisut, Groenlandais'
  },
  {
    en: 'Kannada',

    ar: 'الكانادا',
    fa: 'کانادا',
    de: 'Kannada',
    tr: 'Kannada Dili',
    ru: 'Каннада',
    fr: 'Kannada'
  },
  {
    en: 'Kanuri',

    ar: 'الكانوري',
    fa: 'کانوری',
    de: 'Kanuri',
    tr: 'Kanuri Dili',
    ru: 'Канури',
    fr: 'Kanouri'
  },
  {
    en: 'Kashmiri',

    ar: 'الكشميرية',
    fa: 'کشمیری',
    de: 'Kaschmirisch',
    tr: 'Keşmirce',
    ru: 'Кашмирский',
    fr: 'Kashmiri'
  },
  {
    en: 'Kazakh',

    ar: 'الكازاخية',
    fa: 'قزاقی',
    de: 'Kasachisch',
    tr: 'Kazakça',
    ru: 'Казахский',
    fr: 'Kazakh'
  },
  {
    en: 'Khmer',

    ar: 'الخميرية',
    fa: 'خمر',
    de: 'Khmer',
    tr: 'Khmer Dili',
    ru: 'Кхмерский',
    fr: 'Khmer'
  },
  {
    en: 'Kikuyu, Gikuyu',

    ar: 'الكيكويو، الجيكويو',
    fa: 'کیکویو، گیکویو',
    de: 'Kikuyu, Gikuyu',
    tr: 'Kikuyu, Gikuyu Dili',
    ru: 'Кикуйю; Гикуйю',
    fr: 'Kikuyu, Gikuyu'
  },
  {
    en: 'Kinyarwanda',

    ar: 'الكينياروندا',
    fa: 'کینیارواندا',
    de: 'Kinyarwanda',
    tr: 'Kinyarwanda Dili',
    ru: 'Киньяруанда',
    fr: 'Kinyarwanda'
  },
  {
    en: 'Kyrgyz',

    ar: 'القيرغيزية',
    fa: 'قرقیز',
    de: 'Kirgisisch',
    tr: 'Kırgızca',
    ru: 'Кыргызский',
    fr: 'Kirghize'
  },
  {
    en: 'Komi',

    ar: 'الكومية',
    fa: 'کومی',
    de: 'Komi',
    tr: 'Komi Dili',
    ru: 'Коми',
    fr: 'Kom'
  },
  {
    en: 'Kongo',

    ar: 'الكونغو',
    fa: 'کنگو',
    de: 'Kongo',
    tr: 'Kongo Dili',
    ru: 'Конго',
    fr: 'Kongo'
  },
  {
    en: 'Korean',

    ar: 'الكورية',
    fa: 'کره ای',
    de: 'Koreanisch',
    tr: 'Korece',
    ru: 'Корейский',
    fr: 'Coréen'
  },
  {
    en: 'Kurdish',

    ar: 'الكردية',
    fa: 'کردی',
    de: 'Kurdisch',
    tr: 'Kürtçe',
    ru: 'Курдский',
    fr: 'Kurde'
  },
  {
    en: 'Kwanyama, Kuanyama',

    ar: 'الكوانياما، كوانياما',
    fa: 'کوانیاما، کوانیاما',
    de: 'Kwanyama, Kuanyama',
    tr: 'Kwanyama, Kuanyama Dili',
    ru: 'Kwanyama; Kuanyama',
    fr: 'Kwanyama, Kuanyama'
  },
  {
    en: 'Latin',

    ar: 'اللاتينية',
    fa: 'لاتین',
    de: 'Latein',
    tr: 'Latince',
    ru: 'Латинский',
    fr: 'Latin'
  },
  {
    en: 'Luxembourgish, Letzeburgesch',

    ar: 'اللوكسمبورغية، ليتزيبورغيش',
    fa: 'لوکزامبورگی؛ لوتزامبورگیش',
    de: 'Luxemburgisch, Letzeburgesch',
    tr: 'Lüksemburgça, Letzeburca',
    ru: 'Люксембургский, Летцебургский',
    fr: 'Luxembourgeois, Luxembourgeois'
  },
  {
    en: 'Ganda',

    ar: 'الغاندا',
    fa: 'گاندا',
    de: 'Ganda',
    tr: 'Ganda Dili',
    ru: 'Ганда',
    fr: 'Luganda'
  },
  {
    en: 'Limburgish, Limburgan, Limburger',

    ar: 'الليمبورغيش، الليمبورجيش، الليمبورغر',
    fa: 'لیمبورگیش؛ لیمبورگان؛ لیمبورگر',
    de: 'Limburgisch, Limburgan, Limburger',
    tr: 'Limburgish, Limburgan, Limburger Dili',
    ru: 'Лимбургский, Лимбурган, Лимбургский',
    fr: 'Limbourgeois, Limbourgeois, Limbourgeois'
  },
  {
    en: 'Lingala',

    ar: 'اللينجالا',
    fa: 'لینگالا',
    de: 'Lingala',
    tr: 'Lingala Dili',
    ru: 'Лингала',
    fr: 'Lingala'
  },
  {
    en: 'Lao',

    ar: 'اللاو',
    fa: 'لاو',
    de: 'Laotisch',
    tr: 'Laoca',
    ru: 'Лаосский',
    fr: 'Laotien'
  },
  {
    en: 'Lithuanian',

    ar: 'الليتوانية',
    fa: 'لیتوانیایی',
    de: 'Litauisch',
    tr: 'Litvanca',
    ru: 'Литовский',
    fr: 'Lituanien'
  },
  {
    en: 'Luba-Katanga',

    ar: 'اللوبا-كاتانجا',
    fa: 'لوبا-کاتانگا',
    de: 'Luba-Katanga',
    tr: 'Luba-Katanga Dili',
    ru: 'Луба-Катанга',
    fr: 'Luba-Katanga'
  },
  {
    en: 'Latvian',

    ar: 'اللاتفية',
    fa: 'لتونیایی',
    de: 'Lettisch',
    tr: 'Letonca',
    ru: 'Латышский',
    fr: 'Letton'
  },
  {
    en: 'Manx',

    ar: 'الإنسان',
    fa: 'منکس',
    de: 'Manx',
    tr: 'Manxcade',
    ru: 'Манкс',
    fr: 'Manx'
  },
  {
    en: 'Macedonian',

    ar: 'المقدونية',
    fa: 'مقدونی',
    de: 'Mazedonisch',
    tr: 'Makedonca',
    ru: 'Македонский',
    fr: 'Macédonien'
  },
  {
    en: 'Malagasy',

    ar: 'الملغاشية',
    fa: 'مالاگاسی',
    de: 'Malagasy',
    tr: 'Malgaşça',
    ru: 'Малагасийский',
    fr: 'Malgache'
  },
  {
    en: 'Malay',

    ar: 'المالاي',
    fa: 'مالایی',
    de: 'Malaiisch',
    tr: 'Malayca',
    ru: 'Малайский',
    fr: 'Malais'
  },
  {
    en: 'Malayalam',

    ar: 'الماليالامية',
    fa: 'مالایالم',
    de: 'Malayalam',
    tr: 'Malayalam Dili',
    ru: 'Малаялам',
    fr: 'Malayalam'
  },
  {
    en: 'Maltese',

    ar: 'المالطية',
    fa: 'مالتی',
    de: 'Maltesisch',
    tr: 'Maltaca',
    ru: 'Мальтийский',
    fr: 'Maltais'
  },
  {
    en: 'Māori',

    ar: 'الماوري',
    fa: 'مائوری',
    de: 'Māori',
    tr: 'Māori Dili',
    ru: 'Маори',
    fr: 'Maori'
  },
  {
    en: 'Marathi (Marāṭhī)',

    ar: 'المراثية (ماراثى)',
    fa: 'مراتی (مراٹھی)',
    de: 'Marathi (Marāṭhī)',
    tr: 'Marathi (Marāṭhī) Dili',
    ru: 'Маратхи (Маратхи)',
    fr: 'Marathi (Marāṭhī)'
  },
  {
    en: 'Marshallese',

    ar: 'المارشالية',
    fa: 'مارشالی',
    de: 'Marshallese',
    tr: 'Marshallese Dili',
    ru: 'Маршалльский',
    fr: 'Marshallais'
  },
  {
    en: 'Mongolian',

    ar: 'المغولية',
    fa: 'مغولی',
    de: 'Mongolisch',
    tr: 'Moğolca',
    ru: 'Монгольский',
    fr: 'Mongol'
  },
  {
    en: 'Nauru',

    ar: 'ناورو',
    fa: 'نائورو',
    de: 'Nauruisch',
    tr: 'Nauru Dili',
    ru: 'Науру',
    fr: 'Nauruan'
  },
  {
    en: 'Navajo, Navaho',

    ar: 'النافاجو، النافاهو',
    fa: 'ناواهو، ناواهو',
    de: 'Navajo, Navaho',
    tr: 'Navajo, Navaho Dili',
    ru: 'Навахо, Навахо',
    fr: 'Navajo, Navaho'
  },
  {
    en: 'Norwegian Bokmål',

    ar: 'البوكمالية النرويجية',
    fa: 'بوکمال نروژی',
    de: 'Norwegisch (Bokmål)',
    tr: 'Norveççe (Bokmål)',
    ru: 'Норвежский (Bokmål)',
    fr: 'Norvégien Bokmål'
  },
  {
    en: 'North Ndebele',

    ar: 'النديبيل الشمالية',
    fa: 'ندبله شمالی',
    de: 'Nord-Ndebele',
    tr: 'Kuzey Ndebele Dili',
    ru: 'Северный ндебеле',
    fr: 'Ndebele du Nord'
  },
  {
    en: 'Nepali',

    ar: 'النيبالية',
    fa: 'نپالی',
    de: 'Nepali',
    tr: 'Nepalce',
    ru: 'Непали',
    fr: 'Népalais'
  },
  {
    en: 'Ndonga',

    ar: 'الندونجا',
    fa: 'ندونگا',
    de: 'Ndonga',
    tr: 'Ndonga Dili',
    ru: 'Ндонга',
    fr: 'Ndonga'
  },
  {
    en: 'Norwegian Nynorsk',

    ar: 'النرويجية النينورسك',
    fa: 'نینورسک نروژی',
    de: 'Norwegisch (Nynorsk)',
    tr: 'Norveççe (Nynorsk)',
    ru: 'Норвежский (Nynorsk)',
    fr: 'Norvégien Nynorsk'
  },
  {
    en: 'Norwegian',

    ar: 'النرويجية',
    fa: 'نروژی',
    de: 'Norwegisch',
    tr: 'Norveççe',
    ru: 'Норвежский',
    fr: 'Norvégien'
  },
  {
    en: 'Nuosu',

    ar: 'النوسو',
    fa: 'نوسو',
    de: 'Nuosu',
    tr: 'Nuosu Dili',
    ru: 'Nuosu',
    fr: 'Nuosu'
  },
  {
    en: 'South Ndebele',

    ar: 'النديبيل الجنوبي',
    fa: 'ندبله جنوبی',
    de: 'Süd-Ndebele',
    tr: 'Güney Ndebele Dili',
    ru: 'Южный ндебеле',
    fr: 'Ndebele du Sud'
  },
  {
    en: 'Occitan',

    ar: 'الأوكيتانية',
    fa: 'اکسیتانی',
    de: 'Okzitanisch',
    tr: 'Oksitanca',
    ru: 'Окситанский',
    fr: 'Occitan'
  },
  {
    en: 'Ojibwe, Ojibwa',

    ar: 'أوجيبوي، أوجيبوا',
    fa: 'اوجیبوو؛ اوجیبووا',
    de: 'Ojibwe, Ojibwa',
    tr: 'Ojibwe, Ojibwa Dili',
    ru: 'Оджибве, Оджибва',
    fr: 'Ojibwa, Ojibwa'
  },
  {
    en: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',

    ar: 'الكنيسة السلافية القديمة، الكنيسة السلافية، الكنيسة السلافية، البلغارية القديمة، السلافية القديمة',
    fa: 'کلیسای قدیمی اسلاوی، کلیسای اسلاوی، کلیسای اسلاوی کلیسای قدیمی بلغارستان، کلیسای اسلاوی قدیمی',
    de: 'Altkirchenslawisch, Kirchenslawisch, Kirchenslawisch, Altbulgarisch, Altslawisch',
    tr: 'Eski Kilise Slavcası, Kilise Slavcası, Kilise Slavcası, Eski Bulgarca, Eski Slavca',
    ru: 'Древнеславянский, Церковнославянский, Церковнославянский, Староболгарский, Старославянский',
    fr: "Vieux slavon d'église, slavon d'église, slavon d'église, vieux bulgare, vieux slavon"
  },
  {
    en: 'Oromo',

    ar: 'الأورومو',
    fa: 'اورومو',
    de: 'Oromo',
    tr: 'Oromo Dili',
    ru: 'Оромо',
    fr: 'Oromo'
  },
  {
    en: 'Oriya',

    ar: 'الأوريا',
    fa: 'اوریا',
    de: 'Oriya',
    tr: 'Oriya Dili',
    ru: 'Ория',
    fr: 'Oriya'
  },
  {
    en: 'Ossetian, Ossetic',

    ar: 'الأوسيتية، الأوسيتية',
    fa: 'آسامی، آسامی',
    de: 'Ossetisch, Ossetisch',
    tr: 'Osetçe, Osetçe',
    ru: 'Осетинский, Осетинский',
    fr: 'Ossète, Ossète'
  },
  {
    en: 'Panjabi, Punjabi',

    ar: 'البنجابية، البنجابية',
    fa: 'پنجابی، پنجابی',
    de: 'Pandschabi, Pandschabi',
    tr: 'Pencapça, Pencapça',
    ru: 'Панджаби, Панджаби',
    fr: 'Pendjabi, Pendjabi'
  },

  {
    en: 'PÄli',

    ar: 'البالي',
    fa: 'پالی',
    de: 'Pali',
    tr: 'Pali Dili',
    ru: 'Пали',
    fr: 'Pali'
  },
  {
    en: 'Persian (Farsi)',

    ar: 'الفارسية',
    fa: 'فارسی',
    de: 'Persisch (Farsi)',
    tr: 'Farsça (Farsça)',
    ru: 'Персидский (Фарси)',
    fr: 'Persan (Farsi)'
  },
  {
    en: 'Polish',

    ar: 'البولنديّة',
    fa: 'لهستانی',
    de: 'Polnisch',
    tr: 'Lehçe',
    ru: 'Польский',
    fr: 'Polonais'
  },
  {
    en: 'Pashto, Pushto',

    ar: 'البشتو',
    fa: 'پشتو',
    de: 'Paschtu',
    tr: 'Peştuca',
    ru: 'Пушту (Пушту)',
    fr: 'Pachto, Pachto'
  },
  // Continue with the rest of the languages
  {
    en: 'Portuguese',

    ar: 'البرتغالية',
    fa: 'پرتغالی',
    de: 'Portugiesisch',
    tr: 'Portekizce',
    ru: 'Португальский',
    fr: 'Portugais'
  },
  {
    en: 'Quechua',

    ar: 'الكيشوا',
    fa: 'کچوا',
    de: 'Quechua',
    tr: 'Keçuva',
    ru: 'Кечуа',
    fr: 'Quechua'
  },
  {
    en: 'Romansh',

    ar: 'الرومانشية',
    fa: 'رومانش',
    de: 'Rätoromanisch',
    tr: 'Romanşça',
    ru: 'Романш',
    fr: 'Romanche'
  },
  {
    en: 'Kirundi',

    ar: 'الكيروندي',
    fa: 'کیروندی',
    de: 'Kirundi',
    tr: 'Kirundi Dili',
    ru: 'Кирунди',
    fr: 'Kirundi'
  },
  {
    en: 'Romanian, [])',

    ar: 'الرومانيّة',
    fa: 'رومانی',
    de: 'Rumänisch',
    tr: 'Rumence',
    ru: 'Румынский',
    fr: 'Roumain, [])'
  },
  {
    en: 'Russian',

    ar: 'الروسية',
    fa: 'روسی',
    de: 'Russisch',
    tr: 'Rusça',
    ru: 'Русский',
    fr: 'Russe'
  },
  {
    en: 'Sanskrit (Saṁskṛta)',

    ar: 'السنسكريتية',
    fa: 'سانسکریت',
    de: 'Sanskrit',
    tr: 'Sanskrit Dili',
    ru: 'Санскрит',
    fr: 'Sanskrit'
  },
  {
    en: 'Sardinian',

    ar: 'السردينية',
    fa: 'ساردینی',
    de: 'Sardisch',
    tr: 'Sardinya Dili',
    ru: 'Сардинский',
    fr: 'Sarde'
  },
  {
    en: 'Sindhi',

    ar: 'السندية',
    fa: 'سندی',
    de: 'Sindhi',
    tr: 'Sindh Dili',
    ru: 'Синдхи',
    fr: 'Sindhi'
  },
  {
    en: 'Northern Sami',

    ar: 'الساميّة الشمالية',
    fa: 'سامی شمالی',
    de: 'Nordsamisch',
    tr: 'Kuzey Sami Dili',
    ru: 'Северносаамский',
    fr: 'Sami du Nord'
  },
  {
    en: 'Samoan',

    ar: 'الساموائي',
    fa: 'ساموایی',
    de: 'Samoanisch',
    tr: 'Samoanca',
    ru: 'Самоанский',
    fr: 'Samoan'
  },
  {
    en: 'Sango',

    ar: 'السانغو',
    fa: 'سانگو',
    de: 'Sango',
    tr: 'Sango Dili',
    ru: 'Санго',
    fr: 'Sango'
  },
  {
    en: 'Serbian',

    ar: 'الصربية',
    fa: 'صربی',
    de: 'Serbisch',
    tr: 'Sırpça',
    ru: 'Сербский',
    fr: 'Serbe'
  },
  {
    en: 'Scottish Gaelic; Gaelic',

    ar: 'الغيلية الاسكتلندية',
    fa: 'غیلی اسکاتلندی',
    de: 'Schottisch-Gälisch; Gälisch',
    tr: 'İskoç Galcesi; Geylik',
    ru: 'Шотландский гэльский; Гэльский',
    fr: 'Gaélique écossais; Gaélique'
  },
  {
    en: 'Shona',

    ar: 'الشونا',
    fa: 'شونا',
    de: 'Shona',
    tr: 'Shona Dili',
    ru: 'Шона',
    fr: 'Shona'
  },
  {
    en: 'Sinhala, Sinhalese',

    ar: 'السنهالية',
    fa: 'سینهالا',
    de: 'Singhalesisch',
    tr: 'Singalaca',
    ru: 'Сингальский',
    fr: 'Cingalais, Singhala'
  },
  {
    en: 'Slovak',

    ar: 'السلوفاكية',
    fa: 'اسلواکی',
    de: 'Slowakisch',
    tr: 'Slovakça',
    ru: 'Словацкий',
    fr: 'Slovaque'
  },
  {
    en: 'Slovene',

    ar: 'السلوفينية',
    fa: 'اسلوونی',
    de: 'Slowenisch',
    tr: 'Slovence',
    ru: 'Словенский',
    fr: 'Slovène'
  },
  {
    en: 'Somali',

    ar: 'الصومالية',
    fa: 'سومالی',
    de: 'Somali',
    tr: 'Somalice',
    ru: 'Сомалийский',
    fr: 'Somali'
  },
  {
    en: 'Southern Sotho',

    ar: 'السوتو الجنوبية',
    fa: 'سوتو جنوبی',
    de: 'Sesotho (Süd-Sotho)',
    tr: 'Güney Sotho Dili',
    ru: 'Сесото (Южный сото)',
    fr: 'Sotho du Sud'
  },
  {
    en: 'Spanish; Castilian',

    ar: 'الإسبانية; الكاستيلانية',
    fa: 'اسپانیایی; کاستیلیایی',
    de: 'Spanisch; Kastilisch',
    tr: 'İspanyolca; Kastilyanca',
    ru: 'Испанский; Кастильский',
    fr: 'Espagnol; Castillan'
  },
  {
    en: 'Sundanese',

    ar: 'السوندانية',
    fa: 'سوندانی',
    de: 'Sundanesisch',
    tr: 'Sunda Dili',
    ru: 'Сунданский',
    fr: 'Soundanais'
  },
  {
    en: 'Swahili',

    ar: 'السواحيلية',
    fa: 'سواحلی',
    de: 'Suaheli',
    tr: 'Swahili Dili',
    ru: 'Суахили',
    fr: 'Swahili'
  },
  {
    en: 'Swati',

    ar: 'السواتي',
    fa: 'سواتی',
    de: 'Swati',
    tr: 'Swati Dili',
    ru: 'Свати',
    fr: 'Swati'
  },
  {
    en: 'Swedish',

    ar: 'السويدية',
    fa: 'سوئدی',
    de: 'Schwedisch',
    tr: 'İsveççe',
    ru: 'Шведский',
    fr: 'Suédois'
  },
  {
    en: 'Tamil',

    ar: 'التاميل',
    fa: 'تامیل',
    de: 'Tamil',
    tr: 'Tamilce',
    ru: 'Тамильский',
    fr: 'Tamoul'
  },
  {
    en: 'Telugu',

    ar: 'التيلوغو',
    fa: 'تلوگو',
    de: 'Telugu',
    tr: 'Telugu Dili',
    ru: 'Телугу',
    fr: 'Télougou'
  },
  {
    en: 'Tajik',

    ar: 'الطاجيكية',
    fa: 'تاجیک',
    de: 'Tadschikisch',
    tr: 'Tacik Dili',
    ru: 'Таджикский',
    fr: 'Tadjik'
  },
  {
    en: 'Thai',

    ar: 'التايلاندية',
    fa: 'تایلندی',
    de: 'Thailändisch',
    tr: 'Tayca',
    ru: 'Тайский',
    fr: 'Thaï'
  },
  {
    en: 'Tigrinya',

    ar: 'التيغرينيا',
    fa: 'تیگرینیا',
    de: 'Tigrinya',
    tr: 'Tigrinya Dili',
    ru: 'Тигринья',
    fr: 'Tigrigna'
  },
  {
    en: 'Tibetan Standard, Tibetan, Central',

    ar: 'التبتية القياسية، التبتية الوسطى',
    fa: 'تبتی استاندارد، تبتی مرکزی',
    de: 'Tibetisch (Standard), Tibetisch (Zentral)',
    tr: 'Tibetçe (Standart), Tibetçe (Merkezi)',
    ru: 'Тибетский стандартный, Тибетский центральный',
    fr: 'Tibétain standard, Tibétain central'
  },
  {
    en: 'Turkmen',

    ar: 'التركمانية',
    fa: 'ترکمن',
    de: 'Turkmenisch',
    tr: 'Türkmence',
    ru: 'Туркменский',
    fr: 'Turkmène'
  },
  {
    en: 'Tagalog',

    ar: 'التاغالوغ',
    fa: 'تاگالوگ',
    de: 'Tagalog',
    tr: 'Tagalog Dili',
    ru: 'Тагальский',
    fr: 'Tagalog'
  },
  {
    en: 'Tswana',

    ar: 'التسوانية',
    fa: 'تسوانا',
    de: 'Tswana',
    tr: 'Tswana Dili',
    ru: 'Тсвана',
    fr: 'Tswana'
  },
  {
    en: 'Tonga (Tonga Islands)',

    ar: 'التونغية (جزر تونغا)',
    fa: 'تونگایی (جزایر تونگا)',
    de: 'Tongaisch (Tonga-Inseln)',
    tr: 'Tonga Dili (Tonga Adaları)',
    ru: 'Тонганский (Острова Тонга)',
    fr: 'Tongien (Îles Tonga)'
  },
  {
    en: 'Turkish',

    ar: 'التركية',
    fa: 'ترکی',
    de: 'Türkisch',
    tr: 'Türkçe',
    ru: 'Турецкий',
    fr: 'Turc'
  },
  {
    en: 'Tsonga',

    ar: 'التسونجا',
    fa: 'تسونگا',
    de: 'Tsonga',
    tr: 'Tsonga Dili',
    ru: 'Тсонга',
    fr: 'Tsonga'
  },
  {
    en: 'Tatar',

    ar: 'التتارية',
    fa: 'تاتار',
    de: 'Tatarisch',
    tr: 'Tatarca',
    ru: 'Татарский',
    fr: 'Tatar'
  },
  {
    en: 'Twi',

    ar: 'التوي',
    fa: 'تویی',
    de: 'Twi',
    tr: 'Twi Dili',
    ru: 'Тви',
    fr: 'Twi'
  },
  {
    en: 'Tahitian',

    ar: 'التاهيتي',
    fa: 'تاهیتی',
    de: 'Tahitianisch',
    tr: 'Tahiti Dili',
    ru: 'Таитянский',
    fr: 'Tahitien'
  },
  {
    en: 'Uyghur, Uighur',

    ar: 'الأويغورية',
    fa: 'آیغور',
    de: 'Uigurisch',
    tr: 'Uygurca',
    ru: 'Уйгурский',
    fr: 'Ouïgour'
  },
  {
    en: 'Ukrainian',

    ar: 'الأوكرانية',
    fa: 'اوکراینی',
    de: 'Ukrainisch',
    tr: 'Ukraynaca',
    ru: 'Украинский',
    fr: 'Ukrainien'
  },
  {
    en: 'Urdu',

    ar: 'الأردية',
    fa: 'اردو',
    de: 'Urdu',
    tr: 'Urdu Dili',
    ru: 'Урду',
    fr: 'Ourdou'
  },
  {
    en: 'Uzbek',

    ar: 'الأوزبكية',
    fa: 'ازبک',
    de: 'Usbekisch',
    tr: 'Özbekce',
    ru: 'Узбекский',
    fr: 'Ouzbek'
  },
  {
    en: 'Venda',

    ar: 'الفندية',
    fa: 'وندا',
    de: 'Venda',
    tr: 'Venda Dili',
    ru: 'Венда',
    fr: 'Venda'
  },
  {
    en: 'Vietnamese',

    ar: 'الفيتنامية',
    fa: 'ویتنامی',
    de: 'Vietnamesisch',
    tr: 'Vietnamca',
    ru: 'Вьетнамский',
    fr: 'Vietnamien'
  },
  {
    en: 'Volapük',

    ar: 'الفولابوك',
    fa: 'ولاپوک',
    de: 'Volapük',
    tr: 'Volapük Dili',
    ru: 'Волапюк',
    fr: 'Volapük'
  },
  {
    en: 'Walloon',

    ar: 'الوالون',
    fa: 'والون',
    de: 'Wallonisch',
    tr: 'Walloon Dili',
    ru: 'Валлонский',
    fr: 'Wallon'
  },
  {
    en: 'Welsh',

    ar: 'الويلزية',
    fa: 'ولزی',
    de: 'Walisisch',
    tr: 'Galce',
    ru: 'Уэльский',
    fr: 'Gallois'
  },
  {
    en: 'Wolof',

    ar: 'الولوف',
    fa: 'ولوف',
    de: 'Wolof',
    tr: 'Wolof Dili',
    ru: 'Волоф',
    fr: 'Wolof'
  },
  {
    en: 'Western Frisian',

    ar: 'الفريزي الغربي',
    fa: 'فريزي غربي',
    de: 'Friesisch',
    tr: 'Batı Frizcesi',
    ru: 'Западнофризский',
    fr: 'Frison occidental'
  },
  {
    en: 'Xhosa',

    ar: 'الخوسا',
    fa: 'خوسا',
    de: 'Xhosa',
    tr: 'Xhosa Dili',
    ru: 'Коса',
    fr: 'Xhosa'
  },
  {
    en: 'Yiddish',

    ar: 'اليديش',
    fa: 'یدیش',
    de: 'Jiddisch',
    tr: 'Yidiş',
    ru: 'Идиш',
    fr: 'Yiddish'
  },
  {
    en: 'Yoruba',

    ar: 'اليوروبا',
    fa: 'یوروبا',
    de: 'Yoruba',
    tr: 'Yoruba Dili',
    ru: 'Йоруба',
    fr: 'Yoruba'
  },
  {
    en: 'Zhuang, Chuang',

    ar: 'الزهانغ، تشوانغ',
    fa: 'چوانگ، چوآنگ',
    de: 'Zhuang',
    tr: 'Zhuang Dili',
    ru: 'Чжуанский, Чуанг',
    fr: 'Zhuang, Chuang'
  },
  {
    en: 'Zulu',

    ar: 'الزولو',
    fa: 'زولو',
    de: 'Zulu',
    tr: 'Zulu Dili',
    ru: 'Зулу',
    fr: 'Zoulou'
  }
];

const companyTypeOptions = [
  {
    en: 'Real Estate Company',
    ar: 'شركة عقارية',
    fa: 'شرکت مشاور املاک',
    de: 'Immobilienunternehmen',
    tr: 'Emlak Şirketi',
    ru: 'Компания по недвижимости',
    fr: 'Société immobilière'
  },
  {
    en: 'Real Estate Developer',
    ar: 'مطور عقاري',
    fa: 'توسعه دهنده املاک',
    de: 'Immobilienentwickler',
    tr: 'Emlak Geliştirici',
    ru: 'Застройщик недвижимости',
    fr: 'Promoteur immobilier'
  },
  {
    en: 'Construction Company',
    ar: 'شركة مقاولات',
    fa: 'شرکت ساخت و ساز',
    de: 'Bauunternehmen',
    tr: 'İnşaat Şirketi',
    ru: 'Строительная компания',
    fr: 'Entreprise de construction'
  },
  {
    en: 'Marketing & Advertising Company',
    ar: 'شركة تسويق وإعلان',
    fa: 'شرکت بازاریابی و تبلیغات',
    de: 'Marketing- und Werbeagentur',
    tr: 'Pazarlama ve Reklam Şirketi',
    ru: 'Маркетинговая и рекламная компания',
    fr: 'Société de marketing et de publicité'
  }
];

const upgradeListingOptions = [
  {
    id: 1,
    coins: 20,
    coinsText: {
      en: '20 Coins',
      fa: '20 سکه',
      tr: '20 Coins',
      ar: '20 عملة',
      ru: '20 монет',
      de: '20 Münzen',
      fr: '20 Pièces'
    },
    // duration: '1 month',
    duration: {
      en: '1 month',
      fa: '1 ماه',
      tr: '1 ay',
      ar: 'شهر واحد',
      ru: '1 месяц',
      de: '1 Monat',
      fr: '1 mois'
    },
    value: 1,
    type: 'premium',
    label: {
      en: 'Premium',
      fa: 'ویژه',
      tr: 'Premium',
      ar: 'مميز',
      ru: 'Премиум',
      de: 'Premium',
      fr: 'Premium'
    }
  },
  {
    id: 2,
    label: {
      en: 'Premium',
      fa: 'ویژه',
      tr: 'Premium',
      ar: 'مميز',
      ru: 'Премиум',
      de: 'Premium',
      fr: 'Premium'
    },
    coins: 50,
    coinsText: {
      en: '50 Coins',
      fa: '50 سکه',
      tr: '50 Coins',
      ar: '50 عملة',
      ru: '50 монет',
      de: '50 Münzen',
      fr: '50 Pièces'
    },
    duration: {
      en: '3 months',
      fa: '3 ماه',
      tr: '3 ay',
      ar: '3 أشهر',
      ru: '3 месяца',
      de: '3 Monate',
      fr: '3 mois'
    },
    value: 3,
    type: 'premium'
  },
  {
    id: 3,
    label: {
      en: 'Featured',
      fa: 'ویژه',
      tr: 'Featured',
      ar: 'مميز',
      ru: 'Премиум',
      de: 'Premium',
      fr: 'Premium'
    },
    coins: 10,
    coinsText: {
      en: '10 Coins',
      fa: '10 سکه',
      tr: '10 Coins',
      ar: '10 عملة',
      ru: '10 монет',
      de: '10 Münzen',
      fr: '10 Pièces'
    },
    duration: {
      en: '1 month',
      fa: '1 ماه',
      tr: '1 ay',
      ar: 'شهر واحد',
      ru: '1 месяц',
      de: '1 Monat',
      fr: '1 mois'
    },
    value: 1,
    type: 'feature'
  },
  {
    id: 4,
    label: {
      en: 'Featured',
      fa: 'ویژه',
      tr: 'Featured',
      ar: 'مميز',
      ru: 'Премиум',
      de: 'Premium',
      fr: 'Premium'
    },
    coins: 25,
    coinsText: {
      en: '25 Coins',
      fa: '25 سکه',
      tr: '25 Coins',
      ar: '25 عملة',
      ru: '25 монет',
      de: '25 Münzen',
      fr: '25 Pièces'
    },
    duration: {
      en: '3 months',
      fa: '3 ماه',
      tr: '3 ay',
      ar: '3 أشهر',
      ru: '3 месяца',
      de: '3 Monate',
      fr: '3 mois'
    },
    value: 3,
    type: 'feature'
  }
];

const designationArray = [
  {
    en: 'Sales Director',
    tr: 'Satış Direktörü',
    ar: 'مدير المبيعات',
    fr: 'Directeur des ventes',
    ru: 'Директор по продажам',
    de: 'Vertriebsleiter',
    fa: 'مدیر فروش'
  },
  {
    en: 'Sales Manager',
    tr: 'Satış Müdürü',
    ar: 'مدير المبيعات',
    fr: 'Responsable des ventes',
    ru: 'Менеджер по продажам',
    de: 'Verkaufsleiter',
    fa: 'مدیر فروش'
  },
  {
    en: 'Senior Property Consultant',
    tr: 'Kıdemli Emlak Danışmanı',
    ar: 'مستشار عقاري كبير',
    fr: 'Conseiller Immobilier Senior',
    ru: 'Старший консультант по недвижимости',
    de: 'Senior Immobilienberater',
    fa: 'مشاور املاک موظف'
  },
  {
    en: 'Property Consultant',
    tr: 'Emlak Danışmanı',
    ar: 'مستشار عقاري',
    fr: 'Conseiller Immobilier',
    ru: 'Консультант по недвижимости',
    de: 'Immobilienberater',
    fa: 'مشاور املاک'
  },
  {
    en: 'Property Specialist',
    tr: 'Emlak Uzmanı',
    ar: 'خبير عقاري',
    fr: 'Spécialiste Immobilier',
    ru: 'Специалист по недвижимости',
    de: 'Immobilienexperte',
    fa: 'کارشناس املاک'
  },
  {
    en: 'Property Agent',
    tr: 'Emlak Acentesi',
    ar: 'وكيل عقاري',
    fr: 'Agent Immobilier',
    ru: 'Агент по недвижимости',
    de: 'Immobilienagent',
    fa: 'نماینده املاک'
  },
  {
    en: 'Sales Agent',
    tr: 'Satış Temsilcisi',
    ar: 'وكيل المبيعات',
    fr: 'Agent Commercial',
    ru: 'Агент по продажам',
    de: 'Vertriebsmitarbeiter',
    fa: 'نماینده فروش'
  },
  {
    en: 'Leasing Agent',
    tr: 'Kiralama Temsilcisi',
    ar: 'وكيل التأجير',
    fr: 'Agent de location',
    ru: 'Агент по аренде',
    de: 'Vermietungsagent',
    fa: 'نماینده اجاره'
  },
  {
    en: 'Marketing Consultant',
    tr: 'Pazarlama Danışmanı',
    ar: 'مستشار تسويق',
    fr: 'Conseiller en marketing',
    ru: 'Маркетинговый консультант',
    de: 'Marketingberater',
    fa: 'مشاور بازاریابی'
  }
];

export const projectUnits = [
  {
    en: 'All',
    tr: 'Herşey',
    ar: 'الكل',
    fr: 'Tout',
    ru: 'Все',
    de: 'Alle',
    fa: 'همه'
  },
  {
    en: 'Apartment',
    tr: 'Apartman',
    ar: 'شقة',
    fr: 'Appartement',
    ru: 'Квартира',
    de: 'Wohnung',
    fa: 'اپارتمان'
  },
  {
    en: 'Villa',
    tr: 'Villa',
    ar: 'فيلا',
    fr: 'Villa',
    ru: 'Вилла',
    de: 'Villa',
    fa: 'ویلا'
  },
  {
    en: 'Duplex',
    tr: 'Dubleks',
    ar: 'دوبلكس',
    fr: 'Duplex',
    ru: 'Дуплекс',
    de: 'Duplex',
    fa: 'دوبلکس'
  },
  {
    en: 'Penthouse',
    tr: 'Çatı katı/çatı katı',
    ar: 'بنتهاوس / دور علوي',
    fr: 'Penthouse/loft',
    ru: 'Пентхаус / лофт',
    de: 'Penthouse/Loft',
    fa: 'پنت هاوس / اتاق زیر شیروانی'
  },
  {
    en: 'Townhouse',
    tr: 'Şehir evi',
    ar: 'تاون هاوس',
    fr: 'Maison de ville',
    ru: 'Таунхаус',
    de: 'Stadthaus',
    fa: 'خانه شهر'
  },
  {
    en: 'Office',
    tr: 'Ofis',
    ar: 'مكتب',
    fr: 'Bureau',
    ru: 'Офис',
    de: 'Büro',
    fa: 'دفتر'
  },
  {
    en: 'Restaurant/café',
    tr: 'Restoran/kafe',
    ar: 'مطعم / مقهى',
    fr: 'Restaurant/café',
    ru: 'ресторан / кафе',
    de: 'Restaurant/Café',
    fa: 'رستوران / کافه'
  },
  {
    en: 'Showroom',
    tr: 'Sergi salonu',
    ar: 'قاعة عرض',
    fr: "Salle d'exposition",
    ru: 'Шоу рум',
    de: 'Ausstellungsraum',
    fa: 'نمایشگاه'
  },
  {
    en: 'Shop',
    tr: 'Perakende',
    ar: 'بيع بالتجزئة',
    fr: 'Vendre au détail',
    ru: 'Розничная торговля',
    de: 'Verkauf',
    fa: 'خرده فروشی'
  }
];

export {
  commercialFilterOptions,
  residentialFilterOptions,
  priceFilterOptions,
  areaFilterOptions,
  bedsFilterOptions,
  termsFilterOptions,
  bathsFilterOptions,
  otherFiltersOptions,
  propertiesData,
  eventsData,
  eventTypesFilters,
  languages,
  blogsData,
  interiorAmenities,
  exteriorAmenities,
  furnitureOptions,
  propertyStatusOptions,
  propertyStatusFilterOptions,
  rentDurationPeriodOptions,
  projectsData,
  propertyPurposeOptions,
  propertyOrientationOptions,
  preferredContactMethodOptions,
  enquiryTypeOptions,
  furnishingOptions,
  companyTypeOptions,
  upgradeListingOptions,
  designationArray
};
