import React from 'react';
import ReactTooltip from 'react-tooltip';

import styles from './tooltip.module.css';
import { useId } from 'react';

function Tooltip({ text, children, className = '', position = 'bottom', ...props }) {
  const randomId = useId();

  return (
    <>
      <a data-tip data-for={`tooltip${randomId}`} className={[styles['link']].join(' ')} {...props}>
        {children}
      </a>
      {/* <span data-tip data-for="tooltip" className={[styles['link'], className].join(' ')}>
              {icon}
        </span> */}
      <ReactTooltip
        id={`tooltip${randomId}`}
        type="dark"
        effect="solid"
        place={position}
        data-event-off="mouseleave click"
        className={[styles.tooltip, className].join(' ')}>
        {text}
      </ReactTooltip>
    </>
  );
}

export default Tooltip;
