// import React, { useState } from 'react';
// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';
// import classes from './CustomMaterialTable.module.css';
// import CustomPopover from '../CustomPopover';
// import { BsThreeDotsVertical } from 'react-icons/bs';
// import { Checkbox } from '@mui/material';
// import { FaToggleOff, FaToggleOn } from 'react-icons/fa';

// function CustomMaterialTable({
//   totalRecords = 10,
//   page = 1,
//   setPage,
//   rowsPerPage = 10,
//   setRowsPerPage,
//   containerClass = classes.tableHeight,
//   //   onSelectAllClick,
//   headings = [],
//   data = [],
//   showOptionsColumn = true,
//   options = [],
//   allowMultipleSelection,
//   selectedRows = [],
//   setSelectedRows
// }) {
//   const [anchorEl, setAnchorEl] = useState(null);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(1);
//   };

//   return (
//     <Paper sx={{ width: '100%', overflow: 'hidden' }} className={classes.tableWrapper}>
//       <TableContainer className={[containerClass].join(' ')}>
//         <Table stickyHeader aria-label="sticky table">
//           <TableHead className={classes.thead}>
//             <TableRow>
//               {allowMultipleSelection && <TableCell padding="checkbox"></TableCell>}
//               {headings?.map((column) => (
//                 <TableCell
//                   key={column?.id}
//                   align={column?.align}
//                   style={{ minWidth: column?.minWidth }}>
//                   {column?.label}
//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {data?.map((item, key) => (
//               <TableRow hover role="checkbox" tabIndex={-1} key={key} className={classes.tableRow}>
//                 {allowMultipleSelection && (
//                   <TableCell padding="checkbox">
//                     <Checkbox
//                       color="primary"
//                       checked={selectedRows?.find((e) => e?.id == item?.id) && true}
//                       // inputProps={{
//                       //   'aria-labelledby': `enhanced-table-checkbox-${key}`
//                       // }}
//                       onChange={() => {
//                         const newData = [...selectedRows];
//                         if (newData?.find((e) => e?.id == item?.id)) {
//                           newData?.splice(
//                             newData?.findIndex((e) => e?.id == item?.id),
//                             1
//                           );
//                         } else {
//                           newData?.push(item);
//                         }

//                         setSelectedRows(newData);
//                       }}
//                     />
//                   </TableCell>
//                 )}
//                 {headings
//                   ?.slice(0, showOptionsColumn ? headings?.length - 1:headings?.length)
//                   ?.map((column, index) => {
//                     return (
//                       <TableCell key={index} align={column?.align}>
//                         {item[column?.id]}
//                       </TableCell>
//                     );
//                   })}
//                 {showOptionsColumn && (
//                   <TableCell align={'center'}>
//                     <CustomPopover
//                       anchorEl={anchorEl}
//                       setAnchorEl={setAnchorEl}
//                       Icon={<BsThreeDotsVertical size={20} color={'var(--primary-clr)'} />}
//                       iconBtnClass={classes.iconBtn}
//                       Component={
//                         <ul className={classes.list}>
//                           {options?.map((op, index) => (
//                             <li
//                               className="c-p"
//                               key={index}
//                               onClick={() => {
//                                 op?.onClick(op?.label, item);
//                                 setAnchorEl(null);
//                               }}>
//                               {!op?.switch ? (
//                                 op?.icon
//                               ) : item?.status === 'Active' ? (
//                                 <FaToggleOn />
//                               ) : (
//                                 <FaToggleOff size={18} />
//                               )}{' '}
//                               {op?.label}
//                             </li>
//                           ))}
//                         </ul>
//                       }
//                     />
//                   </TableCell>
//                 )}
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <TablePagination
//         rowsPerPageOptions={[10, 25, 50]}
//         component="div"
//         count={totalRecords}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={handleChangePage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//       />
//     </Paper>
//   );
// }

// export default CustomMaterialTable;

import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import classes from './CustomMaterialTable.module.css';
import { IconButton, TablePopperComp } from '../CustomPopover';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Checkbox, Pagination, Skeleton } from '@mui/material';
import { FaToggleOff, FaToggleOn } from 'react-icons/fa';
import NoData from '../NoData/NoData';
import { recordsLimit } from '../../config/apiUrl';
import { useRef } from 'react';

const TableSkeleton = ({ rowsCount = 10, colsCount = 5 }) => {
  const colArrary = Array(colsCount).fill(0);
  const rowArrary = Array(rowsCount).fill(0);
  return (
    <>
      {rowArrary?.map((_, index) => (
        <TableRow key={`skeleton-row-${index}`}>
          {colArrary?.map((_, i) => (
            <TableCell key={`skeleton-row-${i}`}>
              <Skeleton height={'70px'} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

function CustomMaterialTable({
  totalRecords = 10,
  page = 1,
  setPage,
  containerClass = classes.tableHeight,
  noDataText = 'No Data Found',
  //   onSelectAllClick,
  headings = [],
  data = [],
  showOptionsColumn = true,
  options = [],
  allowMultipleSelection,
  selectedRows = [],
  setSelectedRows,
  isLoading = false,
  limit = recordsLimit
}) {
  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [indexMap, setIndexMap] = useState(-1);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }} className={classes.tableWrapper}>
      <TableContainer className={[containerClass].join(' ')}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className={classes.thead}>
            <TableRow>
              {allowMultipleSelection && <TableCell padding="checkbox"></TableCell>}
              {headings?.map((column) => (
                <TableCell
                  key={column?.id}
                  align={column?.align}
                  style={{ minWidth: column?.minWidth }}>
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableSkeleton
                rowsCount={limit}
                colsCount={headings?.length + (allowMultipleSelection ? 1 : 0)}
              />
            ) : data?.length == 0 ? (
              <TableRow>
                <TableCell
                  colSpan={headings?.length + (allowMultipleSelection ? 1 : 0)}
                  style={{ borderBottom: 'none' }}>
                  <NoData text={noDataText} isAdminThemeColor={true} />
                </TableCell>
              </TableRow>
            ) : (
              data?.map((item, key) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={key}
                  className={classes.tableRow}>
                  {allowMultipleSelection && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={selectedRows?.find((e) => e?._id == item?._id) != undefined}
                        // inputProps={{
                        //   'aria-labelledby': `enhanced-table-checkbox-${key}`
                        // }}
                        onChange={() => {
                          const newData = [...selectedRows];
                          if (newData?.find((e) => e?._id == item?._id)) {
                            newData?.splice(
                              newData?.findIndex((e) => e?._id == item?._id),
                              1
                            );
                          } else {
                            newData?.push(item);
                          }

                          setSelectedRows(newData);
                        }}
                      />
                    </TableCell>
                  )}
                  {headings
                    ?.slice(0, showOptionsColumn ? headings?.length - 1 : headings?.length)
                    ?.map((column, index) => {
                      return (
                        <TableCell key={index} align={column?.align}>
                          {headings[index]?.renderValue
                            ? headings[index]?.renderValue(item, column, key)
                            : item[column?.id]}
                        </TableCell>
                      );
                    })}
                  {showOptionsColumn && (
                    <TableCell style={{ display: 'flex', justifyContent: 'center' }}>
                      <div
                        ref={key == indexMap ? anchorRef : null}
                        id="composition-button"
                        aria-controls={anchorEl ? 'composition-menu' : undefined}
                        aria-expanded={anchorEl ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={() => {
                          setSelectedItem(item);
                          setIndexMap(key);
                          setTimeout(() => {
                            setAnchorEl(true);
                          }, 100);
                        }}>
                        <IconButton
                          Icon={
                            <BsThreeDotsVertical
                              size={20}
                              className={[classes.threeDots]?.join(' ')}
                            />
                          }
                          btnClass={classes.iconBtn}
                        />
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePopperComp
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
        anchorRef={anchorRef}
        Component={
          <ul className={classes.list}>
            {options?.map((op, index) => (
              <li
                className={[
                  'c-p',
                  typeof op?.className == 'function' ? op?.className(selectedItem) : op?.className
                ].join(' ')}
                onClick={() => {
                  op?.onClick(op?.label, selectedItem);
                  setAnchorEl(null);
                }}
                key={`option-${index}`}>
                {!op?.switch ? (
                  op?.icon
                ) : selectedItem?.isActive ? (
                  <FaToggleOn />
                ) : (
                  <FaToggleOff size={18} />
                )}{' '}
                {typeof op?.label == 'function' ? op?.label(selectedItem) : op?.label}
              </li>
            ))}
          </ul>
        }
      />
      {!!data?.length && (
        <div className={classes?.paginationDiv}>
          <Pagination count={totalRecords} page={page} onChange={handleChangePage} />
        </div>
      )}
    </Paper>
  );
}

export default CustomMaterialTable;
