import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessToken: '',
  isLogin: false,
  user: null,
  mode: 'light',
  isOpen: false,
  fcmToken: 'abc'
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  //   reducer needs a map
  reducers: {
    SaveFcmToken(state, action) {
      state.fcmToken = action.payload.fcmToken;
    },
    saveLoginUserData(state, action) {
      console.log(action);
      state.user = action.payload.data.user;
      state.isLogin = true;
      state.accessToken = action.payload.token;
    },
    updateUser(state, action) {
      state.user = action.payload;
    },
    signOutRequest(state) {
      state.accessToken = '';
      state.isLogin = false;
      state.user = null;
    },
    ToggleDrawer(state, action) {
      state.isOpen = action.payload;
    }
  }
});

export const { SaveFcmToken, saveLoginUserData, signOutRequest, ToggleDrawer, updateUser } =
  authSlice.actions;

export default authSlice.reducer;
