import React from 'react';
import { languagesOptions } from '../../constant/commonData';
import classes from './FormLanguages.module.css';

function FormLanguages({value,setter}) {
  return (
    <ul className={classes?.ul}>
      {languagesOptions?.map((e, i) => (
        <li key={`flag-${i}`} data-selected={value?.value == e?.value} onClick={()=>setter(e)} title={e?.label}>
          <img src={e?.flag} />
        </li>
      ))}
    </ul>
  );
}

export default FormLanguages;
