/* eslint-disable */
import React from 'react';
import ModalSkeleton from '../ModalSkeleton';
import classes from './CompanyOrAgentEmailModal.module.css';
import Input from '../../Component/Input/Input';
import { useState } from 'react';
import Button from '../../Component/Button/Button';
import { Col, Row } from 'react-bootstrap';
import CustomPhoneInput from '../../Component/CustomPhoneInput';
import { Radio } from '../../Component/Radio/Radio';
import { Post } from '../../Axios/AxiosFunctions';
import validator from 'validator';

import { BaseURL, apiHeader, imageUrl } from '../../config/apiUrl';
import { toast } from 'react-toastify';
import TextArea from '../../Component/TextArea';
import Captcha from '../../Component/Captcha';
import Checkbox from '../../Component/Checkbox/Checkbox';
import SuccessModal from '../SuccessModal';
import { avatar } from '../../constant/imagePath';
import { MdLocationPin } from 'react-icons/md';
import { useSelector } from 'react-redux';

const CompanyOrAgentEmailModal = ({ show, setShow, type = 'agent', data }) => {
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const [reason, setReason] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [contactMethod, setContactMethod] = useState('Email');
  const [isLoading, setIsLoading] = useState(false);
  const [isCaptcha, setIsCaptcha] = useState(false);
  const [isAccept, setIsAccept] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);
  console.log({ isSuccess, show });
  const handleSend = async () => {
    setIsSuccess(true);
    setShow(false);
    return;
    const url = BaseURL('');
    const params = {
      reason,
      email,
      name,
      phone,
      contactMethod
    };
    for (let key in params) {
      if (params[key] == '' || params[key] == null) {
        return toast.error(`Please fill the ${key} field`);
      }
    }
    if (!validator.isEmail(email)) {
      return toast.error('Your email is not valid');
    }
    setIsLoading(true);
    const response = await Post(url, params, apiHeader());
    if (response !== undefined) {
      console.log(response?.data);
    }
    setIsLoading(false);
  };
  const companyLogo =
    'https://www.makeitealing.co.uk/sites/default/files/upload/logos/barnard_marcus_3f09fb979cb9238cb5ffc63a6d28aed6.png';

  console.log({ data });
  return (
    <>
      {show && (
        <ModalSkeleton
          width={'670px'}
          show={show}
          setShow={setShow}
          header={'Email Agent'}
          headerClass={classes.header}
          modalClass={classes.body}>
          <style>{`.DropdownOptionContainer{
          text-transform:none;
        }`}</style>
          <Row className={classes.gap}>
            <Col md={12}>
              <Row className={[classes.propertyCard, 'gx-0'].join(' ')}>
                <Col md={5}>
                  <div className={classes.imgDiv}>
                    <img src={imageUrl(data?.photo)} />
                  </div>
                </Col>
                <Col md={7} className={classes.detailInfo}>
                  {type == 'agent' ? (
                    <>
                      <h5>{data?.agentName?.[selectedLanguage]}</h5>
                      <p>{data?.agentDesignation?.[0]?.[selectedLanguage]}</p>
                    </>
                  ) : (
                    <>
                      <h6>{data?.companyName?.[selectedLanguage]}</h6>
                      <h5>{data?.companyType?.[selectedLanguage]}</h5>
                      <div className={classes?.location}>
                        <MdLocationPin fill="var(--secondary-clr)" size={22} />
                        <span>{data?.address}</span>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <TextArea value={reason} setter={setReason} placeholder="Please enter a message" />
            </Col>
            <Col md={6}>
              <Input
                inputStyle={{
                  borderRadius: '5px'
                }}
                value={name}
                setValue={setName}
                placeholder={'Name*'}
              />
            </Col>
            <Col md={6}>
              <Input
                value={email}
                setValue={setEmail}
                inputStyle={{
                  borderRadius: '5px'
                }}
                placeholder={'Email*'}
              />
            </Col>

            <Col md={6}>
              <CustomPhoneInput value={phone} setter={setPhone} placeholder="Phone*" />
            </Col>
            <Col md={6} className={classes.contactWrap}>
              <p>Perferred Method of contact*</p>
              <div className={classes.methodWrap}>
                <Radio value={contactMethod} setValue={setContactMethod} label="Email" />
                <Radio value={contactMethod} setValue={setContactMethod} label="Phone" />
                <Radio value={contactMethod} setValue={setContactMethod} label="Whatsapp" />
              </div>
            </Col>
            <Col md={6}>
              <Button
                className={classes.btn}
                onClick={handleSend}
                label={isLoading ? 'Sending' : 'Send'}
              />
            </Col>
            <Col md={6}>
              <Captcha setValue={setIsCaptcha} />
            </Col>
            <Col md={12}>
              <Checkbox
                label={'I accept the Terms & Conditions'}
                value={isAccept}
                setValue={setIsAccept}
              />
            </Col>
          </Row>
        </ModalSkeleton>
      )}
      {isSuccess && <SuccessModal show={isSuccess} setShow={setIsSuccess} />}
    </>
  );
};

export default CompanyOrAgentEmailModal;
