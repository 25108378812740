import React from 'react';
import { useLoadScript } from '@react-google-maps/api';
import Map from './Map';
import Places from './Places';
import { google_places_key } from '../../config/apiUrl';

const _libraries = ['places'];

// type = 'map' || 'places'
const MapMain = ({
  type = 'map',
  className = '',
  mapClass,
  placeClass,
  setCoordinates,
  setAddress,
  placeholder = 'Search Location',
  comboRootClassName,
  deleteIcon,
  address,
  location = {
    lat: 25.153824,
    lng: 55.261943
  }, // taf
  valueToPrint,
  makeEmptyValue,
  setCity,
  setCityChecker,
  zoom = 16,
  options = {},
  variant, //default,
  isPanorama = false,
  label
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: google_places_key,
    libraries: _libraries
  });
  if (!isLoaded) {
    return <div>Loading</div>;
  }

  return (
    <div className={`${className}`}>
      {type === 'map' ? (
        <Map
          location={location}
          className={mapClass}
          zoom={zoom}
          options={options}
          isPanorama={isPanorama}
        />
      ) : (
        <>
          <Places
            makeEmptyValue={makeEmptyValue}
            valueToPrint={valueToPrint}
            setCoordinates={setCoordinates}
            setAddress={setAddress}
            className={placeClass}
            placeholder={placeholder}
            comboRootClassName={comboRootClassName && comboRootClassName}
            deleteIcon={deleteIcon}
            address={address}
            variant={variant}
            setCity={setCity}
            setCityChecker={setCityChecker}
            label={label}
          />
        </>
      )}
    </div>
  );
};

export default React.memo(MapMain);
