import React from 'react';
import classes from './FileUpload.module.css';
import Dropzone from 'react-dropzone';
import { useRef } from 'react';
import Button from '../Button/Button';
import { IoCloseOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { imageUrl } from '../../config/apiUrl';

const FileUpload = ({
  label,
  files = [],
  setFiles,
  maxFiles = 10,
  acceptTypes = ['png', 'jpg', 'jpeg'],
  text = 'Drag and drop file here',
  buttonLabel = 'Browse Files',
  uploadBoxClass = '',
  noClick,
  noDrag,
  orText='OR'
}) => {
  const fileInput = useRef(null);

  return (
    <div className={classes.fileInputDiv}>
      {label && <label>{label}</label>}
      <div className={[classes.main, uploadBoxClass].join(' ')}>
        <Dropzone
          noClick={noClick}
          noDrag={noDrag}
          accept={acceptTypes}
          maxFiles={maxFiles}
          onDrop={(acceptedFiles) => {
            // console.log(acceptedFiles, 'acceptedFiles');
            if (!acceptedFiles?.every((item) => acceptTypes?.includes(item?.type?.split('/')[1]))) {
              return toast.error(`Only ${acceptTypes?.join(', ')} files are allowed`);
            }
            // console.log(acceptedFiles);
            setFiles([...files, ...acceptedFiles]);
          }}>
          {({ getRootProps, getInputProps }) => (
            <section
              onClick={(e) => {
                if (!e) {
                  e = window.event;
                }
                e.stopPropagation();
                getInputProps()?.ref?.current?.click();
              }}>
              <div className={classes.section} {...getRootProps()}>
                <input {...getInputProps()} />
                {files?.length <= 0 && (
                  <div className={classes.fileUploadOptions}>
                    {text && (
                      <>
                        <p>{text}</p>
                        <p>{orText}</p>
                      </>
                    )}
                    <button
                      onClick={(e) => {
                        if (!e) {
                          e = window.event;
                        }
                        e.stopPropagation();
                        getInputProps()?.ref?.current?.click();
                      }}>
                      {buttonLabel}
                    </button>
                  </div>
                )}
              </div>
              <div className={classes.imagesWrap}>
                {files?.map((item, index) => (
                  <div key={index} className={classes.image}>
                    <Button
                      className={classes.closeIconBtn}
                      onClick={(e) => {
                        if (!e) {
                          e = window.event;
                        }
                        e.stopPropagation();
                        const newFiles = [...files];
                        newFiles?.splice(index, 1);
                        setFiles(newFiles);
                      }}>
                      <IoCloseOutline size={20} />
                    </Button>
                    <img
                      src={typeof item === 'string' ? imageUrl(item) : URL.createObjectURL(item)}
                      alt=""
                    />
                  </div>
                ))}
              </div>
            </section>
          )}
        </Dropzone>

        <input ref={fileInput} hidden type="file" />
      </div>
    </div>
  );
};

export default FileUpload;
