/* eslint-disable */

import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SideBarSkeleton from '../../../Component/SideBarSkeleton';
import classes from './ProfileSettings.module.css';
import Button from '../../../Component/Button/Button';
import { ProfileWithEditButton } from '../../../Component/ProfileWithEditButton';
import Input from '../../../Component/Input/Input';
import DropDown from '../../../Component/DropDown/DropDown';
import TextArea from '../../../Component/TextArea';
import CustomPhoneInput from '../../../Component/CustomPhoneInput';
import ChangePasswordModal from '../../../modals/ChangePasswordModal';
import { useDispatch, useSelector } from 'react-redux';
import LocationComponent from '../../../Component/LocationComponent';
import { useTranslation } from 'react-i18next';
import {
  allLanguagesArray,
  languagesKeysObject,
  languagesOptions,
  propertyTypes
} from '../../../constant/commonData';
import FormLanguages from '../../../Component/FormLanguages';
import { set } from 'date-fns';
import { useUpdateProfileMutation } from '../../../store/apiSlice/apiSlice';
import { toast } from 'react-toastify';
import {
  CreateFormData,
  capitalizeFirstLetter,
  formRegEx,
  formRegExReplacer
} from '../../../config/apiUrl';
import { updateUser } from '../../../store/auth/authSlice';

function ProfileSettings() {
  const { user } = useSelector((state) => state?.authReducer);

  console.log(user, 'user user');

  const [profileData, setProfileData] = useState({
    image: user?.photo || null,
    agentName: user?.agentName || languagesKeysObject,
    agentDesignation: user?.agentDesignation[0] || '',
    area: user?.serviceAreas || languagesKeysObject,
    languages: user?.language || [],
    registerationNo: user?.registrationNumber || '',
    description: user?.description || languagesKeysObject,
    email: user?.email || '',
    phoneNo: user?.phoneNumber || '',
    whatsappNo: user?.whatsapp || ''
  });
  console.log(profileData, 'profileData profileData profileData');
  const [language, setLanguage] = useState(languagesOptions[0]);

  const { t } = useTranslation(['agentAccountSettings', 'locationComp']);
  const dispatch = useDispatch();
  const [locationState, setLocationState] = useState({
    province: user?.province,
    town: user?.town,
    neighbourhood: user?.neighborhood,
    address: user?.address,
    lat: user?.location?.coordinates[1],
    lng: user?.location?.coordinates[0]
  });
  const [showModal, setShowModal] = useState('');
  const updateField = (field, val) => {
    setProfileData((prev) => ({ ...prev, [field]: val }));
  };
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();

  console.log(profileData, 'profileData profileData profileData');

  const updateProfileHandler = async () => {
    const multiLangFields = {
      agentName: profileData?.agentName,
      area: profileData?.area,

      description: profileData?.description
    };

    for (let key in multiLangFields) {
      for (let lang in languagesKeysObject) {
        if (!multiLangFields?.[key]?.[lang]) {
          return toast.error(
            `Please fill the ${capitalizeFirstLetter(
              key?.replace(formRegEx, formRegExReplacer)
            )} in language: ${capitalizeFirstLetter(lang)}`
          );
        }
      }
    }

    const body = {
      ...profileData,
      language: profileData?.languages,
      ...locationState,
      province: locationState?.province?._id,
      town: locationState?.town?._id,
      neighborhood: locationState?.neighbourhood?._id,
      serviceAreas: profileData?.area,
      phoneNumber: profileData?.phoneNo,
      whatsapp: profileData?.whatsappNo,
      registrationNumber: profileData?.registerationNo,
      agentDesignation: [profileData?.agentDesignation],

      email: profileData?.email,
      photo: profileData?.image
    };
    console.log(body, 'body body body');
    for (let key in body) {
      if (!body[key]) {
        return toast.error('Please fill all the fields', key);
      }
    }
    const formData = await CreateFormData(body);
    const res = await updateProfile(formData);
    if (res?.data) {
      dispatch(updateUser(res?.data.data?.user));
      toast.success('Profile updated successfully');
    }
  };

  return (
    <SideBarSkeleton>
      <Container fluid className={classes.page}>
        <Row>
          <Col md={6}>
            <h3 className={classes.headingGreen}>{t('pageTitle')}</h3>
          </Col>
          <Col md={6}>
            <FormLanguages value={language} setter={setLanguage} />
          </Col>
          <Col md={12}>
            <Row className="d-flex justify-content-center align-items-center">
              <Col md={10}>
                <p className={`m-0 ${classes.tagline}`}>{t('tagline')}</p>
              </Col>
              <Col md={2}>
                <ProfileWithEditButton
                  isEdit={true}
                  updateImage={profileData?.image}
                  setUpdateImage={(e) => updateField('image', e)}
                />
              </Col>
            </Row>
          </Col>
          <Col md={12} className={classes.mb30}>
            <h6 className={classes.heading}>{t('title')}</h6>
          </Col>
          <Col md={12}>
            <div className={classes.formBg}>
              <Container>
                <Row className={classes.formRow}>
                  <Col md={6}>
                    <Input
                      label={t('agentName')}
                      placeholder={t('agentName')}
                      variant="dashboard"
                      value={profileData?.agentName[language?.value]}
                      setValue={(e) =>
                        setProfileData({
                          ...profileData,
                          agentName: {
                            ...profileData?.agentName,
                            [language?.value]: e
                          }
                        })
                      }
                    />
                  </Col>
                  <Col md={6}>
                    <DropDown
                      label={t('agentDesignation')}
                      indicatorColor="var(--dashboard-text-gray-clr)"
                      placeholder={t('agentDesignation')}
                      variant="dashboard"
                      value={profileData?.agentDesignation}
                      // value={profileData?.agentDesignation[language?.value]}
                      setValue={(e) => updateField('agentDesignation', e)}
                      // setValue={(e) =>
                      //   setProfileData({
                      //     ...profileData,
                      //     agentDesignation: {
                      //       ...profileData?.agentDesignation,
                      //       [language?.value]: e
                      //     }
                      //   })
                      // }
                      optionLabel={[language?.value]}
                      optionValue={[language?.value]}
                      options={propertyTypes}
                    />
                  </Col>

                  <Col md={6}>
                    <Input
                      label={t('serviceArea')}
                      placeholder={t('serviceArea')}
                      variant="dashboard"
                      value={profileData?.area[language?.value]}
                      setValue={(e) =>
                        setProfileData({
                          ...profileData,
                          area: {
                            ...profileData?.area,
                            [language?.value]: e
                          }
                        })
                      }
                    />
                  </Col>
                  <Col md={6}>
                    <DropDown
                      label={t('languagesWeSpeak')}
                      indicatorColor="var(--dashboard-text-gray-clr)"
                      placeholder={t('languagesWeSpeak')}
                      variant="dashboard"
                      value={profileData?.languages}
                      isMulti={true}
                      options={allLanguagesArray}
                      optionLabel={[language?.value]}
                      optionValue={[language?.value]}
                      setValue={(e) => updateField('language', e)}
                    />
                  </Col>

                  <Col md={6}>
                    <Input
                      label={t('languagesWeSpeak')}
                      placeholder={t('languagesWeSpeak')}
                      variant="dashboard"
                      value={profileData?.registerationNo}
                      setValue={(e) => updateField('registerationNo', e)}
                    />
                  </Col>
                  <Col md={12}>
                    <TextArea
                      label={t('aboutUs')}
                      placeholder={t('aboutUs')}
                      variant="dashboard"
                      value={profileData?.description?.[language?.value]}
                      setValue={(e) => set}
                    />
                  </Col>
                  <Col md={12}>
                    <LocationComponent
                      locationState={locationState}
                      setLocationState={setLocationState}
                      selectedLanguage={language}
                    />
                  </Col>

                  <Col md={6}>
                    <Input
                      label={t('email')}
                      placeholder={t('email')}
                      variant="dashboard"
                      value={profileData?.email}
                      setValue={(e) => updateField('email', e)}
                      disabled={true}
                    />
                  </Col>
                  <Col md={6}>
                    <CustomPhoneInput
                      label={t('phone')}
                      placeholder={t('phone')}
                      variant="dashboard"
                      value={profileData?.phoneNo}
                      setter={(e) => updateField('phoneNo', e)}
                    />
                  </Col>
                  <Col md={6}>
                    <CustomPhoneInput
                      label={t('whatsappNumber')}
                      placeholder={t('whatsappNumber')}
                      variant="dashboard"
                      value={profileData?.whatsappNo}
                      setter={(e) => updateField('whatsappNo', e)}
                    />
                  </Col>
                  {/* <Col md={6} className={classes.aEnd}>
                    <Button
                      className={classes.changePassBtn}
                      variant=""
                      onClick={() => {
                        setShowModal('password');
                      }}>
                      Change Password
                    </Button>
                  </Col> */}
                </Row>
              </Container>
            </div>
          </Col>
          <Col md={12} className={classes.btnDiv}>
            <Button className={classes.btnCancel}>Cancel</Button>
            <Button onClick={updateProfileHandler} disabled={isLoading} className={classes.btn}>
              Save Changes
            </Button>
          </Col>
        </Row>
      </Container>
      {showModal == 'password' && (
        <ChangePasswordModal show={showModal == 'password'} setShow={() => setShowModal('')} />
      )}
    </SideBarSkeleton>
  );
}

export default ProfileSettings;

const _d = `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.`;

const agentDesignationOptions = [
  {
    label: 'Real Estate',
    value: 'real_estate',
    _id: 'real_estate'
  },
  {
    label: 'Agent',
    value: 'agent',
    _id: 'agent'
  }
];

const languagesWeSpeakOptions = [
  {
    label: 'English',
    value: 'english',
    _id: 'english'
  },
  {
    label: 'French',
    value: 'french',
    _id: 'french'
  }
];
