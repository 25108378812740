import React, { useState, useEffect, useRef } from 'react';
import { isMobileViewHook } from '../../CustomHooks/isMobileViewHook';
import DesktopHeader from './DesktopHeader';
import { MobileHeader } from './MobileHeader';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { apiUrl } from '../../config/apiUrl';
import { signOutRequest } from '../../store/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { useTranslation } from 'react-i18next';
import { setDirection, setSelectLanguage } from '../../store/language/languageSlice';

const Header = ({ backgroundColor, containerClass, className, logo, customStyle }) => {
  const { i18n, t } = useTranslation('webCommon');
  const { user } = useSelector((state) => state?.authReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = useRef(null);

  const [isMobile, setIsMobile] = useState(false);
  const [isScroll, setIsScroll] = useState(false);

  function logout() {
    socket.current = io(apiUrl);
    socket.current.emit('join', user?._id);
    socket.current.emit('logout',  user?._id );
    dispatch(signOutRequest());
    navigate('/');
  }

  function onScroll() {
    if (window.scrollY > 160) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    isMobileViewHook(setIsMobile, 992);
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    dispatch(setSelectLanguage(lng));
    if (['ar', 'fa'].includes(lng)) {
      dispatch(setDirection('rtl'));

      // document.body.dir = i18n.dir();
    } else {
      dispatch(setDirection('ltr'));

      // document.body.dir = i18n.dir("ltr");
    }
  };

  return (
    <>
      {isMobile ? (
        <MobileHeader
          logo={logo}
          customStyle={customStyle}
          containerClass={containerClass}
          isScroll={isScroll}
          onLogout={logout}
          changeLanguage={changeLanguage}
          t={t}
        />
      ) : (
        <DesktopHeader
          logo={logo}
          backgroundColor={backgroundColor}
          containerClass={containerClass}
          className={className}
          isScroll={isScroll}
          onLogout={logout}
          changeLanguage={changeLanguage}
          t={t}
        />
      )}
    </>
  );
};

export default Header;

Header.propTypes = {
  backgroundColor: PropTypes.string,
  containerClass: PropTypes.string,
  className: PropTypes.string,
  logo: PropTypes.object,
  customStyle: PropTypes.object
};
