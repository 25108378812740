/*eslint-disable*/
import React, { useState } from 'react';
import { Container, Nav, Navbar, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AiFillHeart, AiOutlineUser } from 'react-icons/ai';
import { BsFillBookmarkFill } from 'react-icons/bs';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { IoNotificationsSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { CURRENCY_UNITS, areaUnitOptions, languagesOptions } from '../../constant/commonData';
import { Logo, compare_icon } from '../../constant/imagePath';
import { setAreaUnit, setCurrencyUnit } from '../../store/converter/converterSlice';
import { MenuList } from '../AccountInfoLayout';
import Button from '../Button/Button';
import CustomPopover from '../CustomPopover';
import HeaderFavoriteList from '../HeaderFavouriteList';
import HeaderNotification from '../HeaderNotification/HeaderNotification';
import HeaderSaveSearch from '../HeaderSaveSearch';
import Style from './DesktopHeader.module.css';
import HeaderCompareList from './HeaderCompareList';
import { convertCurrency } from '../../services/currency-converter';
import { toast } from 'react-toastify';

const IconWithCount = ({ icon, count }) => {
  return (
    <div className={[Style.iconWithCount, 'c-p'].join(' ')}>
      {!!count && <span>{count > 9 ? `+9` : count}</span>}
      {icon}
    </div>
  );
};

const AccountInfoOverlay = () => {
  const { t } = useTranslation('webCommon');
  const d = (a) => t(`userSidebarOptions.${[a]}`);

  const navigate = useNavigate();

  return (
    <div className={Style.sidebar_main}>
      <MenuList d={d} isMobile={false} />
      {/* <div>
        <div className={Style.profile_head}>
          <div className={Style.profile_img}>
            <img
              src={imageUrl(user?.photo)}
              alt="profile"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = EmptyProfileImage;
              }}
            />
          </div>
          <div className={Style.profile_name}>
            <p>
              <span>
                <h5>
                  {(user?.role == 'company' && user?.companyName?.[selectedLanguage]) ||
                    (user?.role == 'agent' && user?.agentName?.[selectedLanguage]) ||
                    (user?.role == 'user' && user?.firstName + ' ' + user?.lastName)}
                </h5>
              </span>
              <h6 onClick={onLogout} className="c-p">
                Logout
              </h6>
            </p>
          </div>
        </div>
        <AccountInfoLayout />
      
      </div> */}
      {/* {data?.map((item, index) => {
          return (
            <div
              onClick={() => navigate(item?.path)}
              style={{
                cursor: 'pointer',
                background: window.location.pathname == item?.path && '#f6f6f6'
              }}
              key={index}
              className={Style.item_lists}>
              <p>{item?.name}</p>

              <div className={Style.chevron_icon}>
                <AiOutlineCaretRight color="var(--secondary-clr)" />
              </div>
            </div>
          );
        })} */}
    </div>
  );
};

const DesktopHeader = ({
  logo = Logo,
  backgroundColor,
  className,
  isScroll,
  containerClass,
  onLogout,
  changeLanguage,
  t
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const { areaUnit, currency } = useSelector((state) => state?.converterReducer);
  const { isLogin, user } = useSelector((state) => state?.authReducer);

  const [openNotification, setOpenNotification] = useState(null);
  const [openFavorites, setOpenFavorites] = useState(null);
  const [openCompare, setOpenCompare] = useState(null);
  const [openSaveSearch, setOpenSaveSearch] = useState(null);
  const [openAccount, setOpenAccount] = useState(null);
  const [openLanguages, setOpenLanguages] = useState(null);
  const [openUnits, setOpenUnits] = useState(null);
  const [openCurrency, setOpenCurrency] = useState(null);
  const [loadingCurrency, setLoadingCurrency] = useState(false);

  const onChangeCurrency = async (unit = '', symbol = '') => {
    setOpenCurrency(null);

    if (unit === 'USD') {
      dispatch(setCurrencyUnit({ unit, value: 1, symbol }));
      return;
    }

    let value = null;
    setLoadingCurrency(true);

    try {
      value = await convertCurrency({ unit });

      if (!value) throw new Error('');

      dispatch(setCurrencyUnit({ unit, value, symbol }));
    } catch (error) {
      toast.error('Could not convert currency! Please try after sometime.');
    } finally {
      setLoadingCurrency(false);
    }
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className={`${[Style.header, className, isScroll && Style.afterScroll].join(' ')}`}
      style={{ backgroundColor: backgroundColor }}
      id={'navDesktopHeader'}>
      {/* First Header */}
      <div className={Style.firstHead}>
        <Container className={`${Style.flexSpBtw} ${containerClass}`}>
          <ul>
            <CustomPopover
              anchorEl={openLanguages}
              setAnchorEl={setOpenLanguages}
              Component={
                <ul className={[Style?.languagesOptions, 'ltr'].join(' ')}>
                  {languagesOptions?.map((item, index) => {
                    return (
                      <li
                        key={index}
                        data-selected={selectedLanguage == item?.value}
                        onClick={() => {
                          changeLanguage(item?.value);
                          setOpenLanguages(null);
                        }}>
                        <img src={item?.flag} /> {item?.label}
                      </li>
                    );
                  })}
                </ul>
              }
              className={Style.overlay}
              TriggerComp={() => (
                <li>
                  <Button
                    variant="transparent"
                    rightIcon={<HiOutlineChevronDown size={20} color="var(--secondary-clr)" />}
                    className="t-t-c">
                    {selectedLanguage}
                  </Button>
                </li>
              )}
              // anchorEl={openNotification}
              // setAnchorEl={setOpenNotification}
            />

            <li>
              {/* <Button
                variant="transparent"
                rightIcon={<HiOutlineChevronDown size={20} color="var(--secondary-clr)" />}>
                {t('webHeader.currency')}
              </Button> */}

              <CustomPopover
                anchorEl={openCurrency}
                setAnchorEl={setOpenCurrency}
                Component={
                  <ul className={[Style?.languagesOptions, 'ltr'].join(' ')}>
                    {CURRENCY_UNITS?.map((item, index) => {
                      return (
                        <li
                          key={index}
                          data-selected={currency.unit == item?.value}
                          onClick={() => onChangeCurrency(item?.value, item?.symbol)}>
                          {item?.label} ({item.symbol})
                        </li>
                      );
                    })}
                  </ul>
                }
                className={Style.overlay}
                TriggerComp={() => (
                  <li>
                    <Button
                      variant="transparent"
                      rightIcon={<HiOutlineChevronDown size={20} color="var(--secondary-clr)" />}>
                      {t('webHeader.currency')} ({currency.unit}){' '}
                      {loadingCurrency && <Spinner animation="border" size="sm" />}
                    </Button>
                  </li>
                )}
              />
            </li>
            <li>
              <CustomPopover
                anchorEl={openUnits}
                setAnchorEl={setOpenUnits}
                Component={
                  <ul className={[Style?.languagesOptions, 'ltr'].join(' ')}>
                    {areaUnitOptions?.map((item, index) => {
                      return (
                        <li
                          key={index}
                          data-selected={areaUnit == item?.value}
                          onClick={() => {
                            dispatch(setAreaUnit(item?.value));
                            setOpenUnits(null);
                          }}>
                          {item?.label}
                        </li>
                      );
                    })}
                  </ul>
                }
                className={Style.overlay}
                TriggerComp={() => (
                  <li>
                    <Button
                      variant="transparent"
                      rightIcon={<HiOutlineChevronDown size={20} color="var(--secondary-clr)" />}>
                      {t('webHeader.areaUnit')} ({areaUnit})
                    </Button>
                  </li>
                )}
              />
            </li>
          </ul>
          <ul>
            {isLogin && (
              <>
                <CustomPopover
                  Component={<HeaderNotification t={t} />}
                  className={Style.overlay}
                  TriggerComp={() => (
                    <li>
                      <IconWithCount icon={<IoNotificationsSharp size={24} />} count={0} />
                    </li>
                  )}
                  anchorEl={openNotification}
                  setAnchorEl={setOpenNotification}
                />
                {user?.role == 'user' && (
                  <>
                    {/* Favourites List */}
                    <CustomPopover
                      Component={<HeaderFavoriteList t={t} />}
                      className={Style.overlay}
                      TriggerComp={() => (
                        <li>
                          <IconWithCount icon={<AiFillHeart size={24} />} count={0} />
                        </li>
                      )}
                      anchorEl={openFavorites}
                      setAnchorEl={setOpenFavorites}
                    />
                    {/* Save Searches */}
                    <CustomPopover
                      Component={<HeaderSaveSearch t={t} />}
                      className={Style.overlay}
                      TriggerComp={() => (
                        <li>
                          <IconWithCount icon={<BsFillBookmarkFill size={20} />} count={0} />
                        </li>
                      )}
                      anchorEl={openSaveSearch}
                      setAnchorEl={setOpenSaveSearch}
                    />

                    {/* Compare List */}
                    <CustomPopover
                      Component={<HeaderCompareList t={t} />}
                      className={Style.overlay}
                      anchorEl={openCompare}
                      setAnchorEl={setOpenCompare}
                      TriggerComp={() => (
                        <li>
                          <IconWithCount
                            icon={
                              <img
                                src={compare_icon}
                                draggable={false}
                                width={20}
                                height={'auto'}
                              />
                            }
                            count={0}
                          />
                        </li>
                      )}
                    />
                  </>
                )}
                {/* My Account Overlay */}
                <CustomPopover
                  Component={<AccountInfoOverlay />}
                  className={Style.overlay}
                  anchorEl={openAccount}
                  setAnchorEl={setOpenAccount}
                  TriggerComp={() => (
                    <li>
                      <Button
                        variant="transparent"
                        className={Style.loginBtn}
                        leftIcon={<AiOutlineUser size={22} />}
                        onClick={() => {}}>
                        {t('webHeader.myAccountPopup.title')}
                      </Button>
                    </li>
                  )}
                />
              </>
            )}
            {!isLogin && (
              <li>
                <Button
                  variant="transparent"
                  className={Style.loginBtn}
                  leftIcon={<AiOutlineUser size={22} />}
                  onClick={() => {
                    navigate('/login');
                  }}>
                  {t('webHeader.login')}
                </Button>
              </li>
            )}
          </ul>
        </Container>
      </div>
      {/* First Header */}
      {/* Second Header */}
      <div className={Style.secondHead}>
        <Container className={`${Style.aCenter} ${containerClass}`}>
          <div onClick={() => navigate('/')} className={Style.logoDiv}>
            {/* <img src={logo} alt="" /> */}
            <h3>Demo</h3>
          </div>

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className={` ${[Style.gapCustm].join(' ')}`}>
              <>
                <NavLink
                  to="/buy"
                  className={({ isActive }) =>
                    `${[Style.nabarLinks, isActive && Style.navActive].join(' ')}`
                  }>
                  {t('webHeader.buy')}
                </NavLink>
                <NavLink
                  to="/rent"
                  className={({ isActive }) =>
                    `${[Style.nabarLinks, isActive && Style.navActive].join(' ')}`
                  }>
                  {t('webHeader.rent')}
                </NavLink>
                {/* <NavLink
                  to="/commercial"
                  className={({ isActive }) =>
                    `${[Style.nabarLinks, isActive && Style.navActive].join(' ')}`
                  }>
                  Commercial
                </NavLink> */}
                <NavLink
                  to="/projects"
                  className={({ isActive }) =>
                    `${[Style.nabarLinks, isActive && Style.navActive].join(' ')}`
                  }>
                  {t('webHeader.projects')}
                </NavLink>
                <NavLink
                  to="/events"
                  className={({ isActive }) =>
                    `${[Style.nabarLinks, isActive && Style.navActive].join(' ')}`
                  }>
                  {t('webHeader.events')}
                </NavLink>
                <NavLink
                  to="/property-request"
                  className={({ isActive }) =>
                    `${[Style.nabarLinks, isActive && Style.navActive].join(' ')}`
                  }>
                  {t('webHeader.propertyRequest')}
                </NavLink>
                {/* <NavLink
                  to="/property-request"
                  className={({ isActive }) =>
                    `${[Style.nabarLinks, isActive && Style.navActive].join(' ')}`
                  }>
                  Property Request
                </NavLink> */}

                <NavLink
                  to="/agents"
                  className={({ isActive }) =>
                    `${[Style.nabarLinks, isActive && Style.navActive].join(' ')}`
                  }>
                  {t('webHeader.agents')}
                </NavLink>
              </>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </div>
      {/* Second Header */}
    </Navbar>
  );
};

export default DesktopHeader;
