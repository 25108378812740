/*eslint-disable*/

import React, { useState } from 'react';
import classes from './HeaderSaveSearch.module.css';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// import image from '../../assets/images/house.jpg';

// import { BaseURL } from '../../config/apiUrl';
// import { Get } from '../../Axios/AxiosFunctions';
// import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import NoData from '../NoData/NoData';
import { AiFillHeart } from 'react-icons/ai';
// import { MdNotifications } from "react-icons/md";

const Notifications = ({ item }) => {
  // const navigate = useNavigate();
  return (
    <div
      className={[classes.HeaderNotificationInnerDiv]}
      onClick={() => {
        // item?.flag == 'chat' &&
        //   navigate('/chat', {
        //     state: {
        //       currentRoom: item?.project?.room
        //     }
        //   });
      }}>
      {/* <div> */}
      <p>{item?.description}</p>
      {/* </div> */}
      <div className={[classes.contentDiv]}>
        <div className={classes.time}>
          <p>{moment(item?.createdAt).format('Do/MM/YYYY')}</p>
        </div>
      </div>
    </div>
  );
};

const HeaderSaveSearch = ({ t }) => {
  const saveSearchData = [
    {
      description: 'Full floor for sale ',
      postedBy: 'Anas',
      createdAt: moment()
    },
    {
      description: 'Full floor for sale ',
      postedBy: 'Anas',
      createdAt: moment()
    },
    {
      description: 'Full floor for sale ',
      postedBy: 'Anas',
      createdAt: moment()
    },
    {
      description: 'Full floor for sale ',
      postedBy: 'Anas',
      createdAt: moment()
    },
    {
      description: 'Full floor for sale ',
      postedBy: 'Anas',
      createdAt: moment()
    }
  ];
  const navigate = useNavigate();
  //   const accessToken = useSelector((state) => state?.authReducer?.accessToken);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(saveSearchData);

  //   const fetchNotifications = async () => {
  //     const url = BaseURL('notifications/all?page=1&limit=5');
  //     setLoading(true);
  //     let response = await Get(url, accessToken);
  //     setLoading(false);

  //     if (response !== undefined) {
  //       setNotifications(notifications);
  //     }
  //   };

  //   useEffect(() => {
  //     fetchNotifications();
  //   }, []);
  return (
    <div className={[classes.HeaderNotification]}>
      <div className={[classes.notificationHeader]}>
        <AiFillHeart size={25} />
        <h6>{t('webHeader.saveSearchPopup.title')}</h6>
      </div>
      <div className={classes.notificationSkeleton}>
        {loading ? (
          Array(4)
            .fill(0)
            ?.map((index) => (
              <Skeleton key={index} height={'80px'} width={'100%'} variant={'rectangular'} />
            ))
        ) : data?.length == 0 ? (
          <NoData text={t('webHeader.saveSearchPopup.noData')} className={classes.noData} />
        ) : (
          data?.slice(Math.max(data?.length - 5, 0))?.map((item, i) => {
            return (
              <div className={[classes.mb16]} key={i}>
                <Notifications item={item} />
              </div>
            );
          })
        )}
        <p
          className={['p18', classes.viewAll].join(' ')}
          onClick={() => navigate('/saved-searches')}>
          {t('webHeader.saveSearchPopup.seeAll')}
        </p>
      </div>
    </div>
  );
};
export default HeaderSaveSearch;
