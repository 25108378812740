import React, { useEffect, useState } from 'react';
import classes from './projectFilters.module.css';
import CustomOverlay from '../CustomOverlay';
import Input from '../Input/Input';
import { Col, Container, Row } from 'react-bootstrap';
import Button from '../Button/Button';
import { AiFillCaretDown } from 'react-icons/ai';
import { ImLocation } from 'react-icons/im';
// import DropDown from '../DropDown/DropDown';
// import { Country } from 'country-state-city';
// import { Radio } from '../Radio/Radio';
import DateRangeCalendar from '../DateRangeCalendar';
import { getWindowWidth } from '../../CustomHooks/isMobileViewHook';
import { FaFilter } from 'react-icons/fa';
import { BiSearchAlt2 } from 'react-icons/bi';
import ProjectsMoreFiltersModal from '../../modals/ProjectsMoreFiltersModal';
import LocationFiltersOverlay from '../LocationFilterOverlay';
// import { useSelector } from 'react-redux';
import { discardUndefinedOrNullValues } from '../../config/apiUrl';

const FilterDiv = ({
  text,
  icon,
  iconRight = <AiFillCaretDown className={classes.icon} />,
  ...props
}) => {
  return (
    <div className={classes.dropdownBox} {...props}>
      <div>
        {icon} <span>{text}</span>
      </div>
      {iconRight && iconRight}
    </div>
  );
};

/*eslint-disable*/
function ProjectFilters({ data, onSearch, filtersWrapperClass = '', containerClass, t }) {
  // const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const [searchText, setSearchText] = useState('');
  const [date, setDate] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);
  const [price, setPrice] = useState({ minPrice: '', maxPrice: '' });
  const [mainData, setMainData] = useState({
    province: null,
    neighborhood: null,
    town: null,
    nearby: false,
    search: '',
    minPrice: '',
    maxPrice: '',
  
  });
  // For Popover
  const [show, setShow] = useState('');
  const [target, setTarget] = useState(null);
  const [screenWidth, setScreenWidth] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getWindowWidth(setScreenWidth);
  }, []);
  useEffect(() => {
    if (data) {
      setMainData((prev) => ({ ...prev, ...data }));
    }
  }, [data]);

  const handleClick = (event, type) => {
    console.log('type', type, event);
    console.log('click', event);
    if (type) {
      setShow(type);
    } else {
      setShow('');
    }
    if (event) {
      setTarget(event.target);
    } else {
      setTarget(null);
    }
  };

  const handleSearch = () => {
    const newData = {
      province: mainData?.province?._id,
      town: mainData?.town?._id,
      neighborhood: mainData?.neighborhood?._id,
      nearby: mainData?.nearby,
      ...(mainData?.nearby || searchText
        ? {
            lng: mainData?.longitude,
            lat: mainData?.latitude
          }
        : {}),
      search: searchText,
      minPrice: mainData?.minPrice ? Number(mainData?.minPrice) : '',
      maxPrice: mainData?.maxPrice ? Number(mainData?.maxPrice) : '',
      minDate: date?.[0]?.startDate,
      maxDate: date?.[0]?.endDate
    };
    onSearch && onSearch(discardUndefinedOrNullValues(newData));
  };

  const LocationOverlay = () => (
    <CustomOverlay
      show={show == 'location'}
      target={target}
      handleClose={(e) => handleClick('', e)}
      targetElement={
        <FilterDiv
          onClick={(e) => handleClick(e, 'location')}
          icon={<ImLocation color={'var(--secondary-clr)'} />}
          text={t('filters.location')}
        />
      }>
      <div className={classes.overlayBox}>
        <LocationFiltersOverlay
          setLocationState={setMainData}
          locationState={mainData}
          setClose={setShow}
          setSearchText={setSearchText}
        />
      </div>
    </CustomOverlay>
  );
  return (
    <Container className={`${containerClass}`}>
      <div className={[classes.filters, filtersWrapperClass].join(' ')}>
        <Row className={[classes.row, 'gx-0'].join(' ')}>
          {/* 1st Option */}
          {screenWidth > 992 && (
            <Col md={2} className={classes.minWidth190}>
              <LocationOverlay />
            </Col>
          )}
          <Col md={4} sm={9} xs={8} className={classes.flex2}>
            <Input
              value={searchText}
              setValue={setSearchText}
              placeholder={t('filters.search')}
              className={classes.searchInput}
            />
          </Col>

          {/* 3rd Option */}
          {screenWidth > 992 && (
            <Col md={2} className={classes.minWidth170}>
              <CustomOverlay
                show={show == 'price'}
                target={target}
                handleClose={(e) => handleClick('', e)}
                targetElement={
                  <FilterDiv
                    onClick={(e) => {
                      handleClick(e, 'price');
                    }}
                    text={t('filters.price')}
                  />
                }>
                <div className={[classes.overlayBox, classes.minWidth350].join(' ')}>
                  <Row>
                    <Col md={6}>
                      <Input
                        value={price?.minPrice}
                        label={t('filters.minPrice')}
                        placeholder={t('filters.minPricePlaceholder')}
                        type={'number'}
                        setValue={(e) => {
                          setPrice((prev) => ({ ...prev, minPrice: e }));
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        label={t('filters.maxPrice')}
                        placeholder={t('filters.maxPricePlaceholder')}
                        value={price?.maxPrice}
                        type={'number'}
                        setValue={(e) => {
                          setPrice((prev) => ({ ...prev, maxPrice: e }));
                        }}
                      />
                    </Col>
                    <Col md={12}>
                      <div className={[classes.spBtw, classes.mt20].join(' ')}>
                        <Button
                          borderRadius={'3px'}
                          className={classes.clearBtn}
                          onClick={() => {
                            setPrice({ minPrice: '', maxPrice: '' });
                            setMainData((prev) => ({ ...prev, minPrice: '', maxPrice: '' }));
                          }}>
                          {t('filters.clearBtn')}
                        </Button>
                        <Button
                          borderRadius={'3px'}
                          className={classes.btnMaxContent}
                          onClick={() => {
                            setMainData((prev) => ({ ...prev, ...price }));
                          }}>
                          {t('filters.applyBtn')}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CustomOverlay>
            </Col>
          )}
          {/* 4th Option */}
          {screenWidth > 992 && (
            <Col md={2} className={classes.minWidth190}>
              <CustomOverlay
                show={show == 'date-range'}
                target={target}
                handleClose={(e) => handleClick('', e)}
                targetElement={
                  <FilterDiv
                    onClick={(e) => {
                      handleClick(e, 'date-range');
                    }}
                    text={t('filters.dateRange')}
                  />
                }>
                <div className={[classes.overlayBox, classes.p0].join(' ')}>
                  <DateRangeCalendar value={date} setValue={setDate} />
                </div>
              </CustomOverlay>
            </Col>
          )}
          {screenWidth <= 992 && (
            <Col className={classes.minWidth170}>
              <FilterDiv
                onClick={() => {
                  setShowModal(true);
                }}
                iconRight={screenWidth <= 600 ? false : <FaFilter className={classes.icon} />}
                text={screenWidth <= 600 ? <FaFilter className={classes.icon} /> : 'Filters'}
              />
            </Col>
          )}
          <Button
            variant="secondary"
            borderRadius={'3px'}
            onClick={handleSearch}
            className={classes.searchBtn}>
            {screenWidth <= 600 ? (
              <BiSearchAlt2 color="var(--white-clr)" size={20} />
            ) : (
              t('filters.searchBtn')
            )}
          </Button>
        </Row>
      </div>
      {screenWidth <= 992 && (
        <>
          <style>{`
      .modal-body{
        overflow:visible;
      }
      `}</style>
          <ProjectsMoreFiltersModal show={showModal} setShow={setShowModal}>
            <Col md={12}>
              <LocationOverlay />
            </Col>
            <Col md={12}>
              <CustomOverlay
                show={show == 'price'}
                target={target}
                handleClose={(e) => handleClick('', e)}
                targetElement={
                  <FilterDiv
                    onClick={(e) => {
                      handleClick(e, 'price');
                    }}
                    text={t('filters.price')}
                  />
                }>
                <div className={[classes.overlayBox, classes.minWidth350].join(' ')}>
                  <Row>
                    <Col md={6}>
                      <Input
                        value={price?.minPrice}
                        label={t('filters.minPrice')}
                        placeholder={t('filters.minPricePlaceholder')}
                        type={'number'}
                        setValue={(e) => {
                          setPrice((prev) => ({ ...prev, minPrice: e }));
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        label={t('filters.maxPrice')}
                        placeholder={t('filters.maxPricePlaceholder')}
                        value={price?.maxPrice}
                        type={'number'}
                        setValue={(e) => {
                          setPrice((prev) => ({ ...prev, maxPrice: e }));
                        }}
                      />
                    </Col>
                    <Col md={12}>
                      <div className={[classes.spBtw, classes.mt20].join(' ')}>
                        <Button
                          borderRadius={'3px'}
                          className={classes.clearBtn}
                          onClick={() => {
                            setPrice({ minPrice: '', maxPrice: '' });
                            setMainData((prev) => ({ ...prev, minPrice: '', maxPrice: '' }));
                          }}>
                          {t('filters.clearBtn')}
                        </Button>
                        <Button
                          borderRadius={'3px'}
                          className={classes.btnMaxContent}
                          onClick={() => {
                            setMainData((prev) => ({ ...prev, ...price }));
                          }}>
                          {t('filters.applyBtn')}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CustomOverlay>
            </Col>
            <Col md={12}>
              <CustomOverlay
                show={show == 'date-range'}
                target={target}
                handleClose={(e) => handleClick('', e)}
                targetElement={
                  <FilterDiv
                    onClick={(e) => {
                      handleClick(e, 'date-range');
                    }}
                    text={t('filters.dateRange')}
                  />
                }>
                <div className={[classes.overlayBox, classes.p0].join(' ')}>
                  <DateRangeCalendar value={date} setValue={setDate} className={'w-100'} />
                </div>
              </CustomOverlay>
            </Col>
          </ProjectsMoreFiltersModal>
        </>
      )}
    </Container>
  );
}

export default ProjectFilters;
