import React from 'react';
import HeadingWithUnderline from '../HeadingWithUnderline';
import classes from './AverageRatesWithMarketTrends.module.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Col, Row } from 'react-bootstrap';
import { FaHome, FaRegCalendarAlt } from 'react-icons/fa';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart'
    }
  }
};
const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const data = {
  labels,
  datasets: [
    {
      label: 'Ankara',
      data: [200, 400, 500, 300, 100, 50, 400],
      border: 'none',
      backgroundColor: '#0e3df6'
    },
    {
      label: 'Etimesgut',
      data: [400, 250, 100, 200, 400, 300, 200],
      border: 'none',
      backgroundColor: '#00ffff'
    }
  ]
};

function AverageRatesWithMarketTrends({ title = 'Market Trends', t }) {
  const salesData = [
    {
      title: t('averageSalePrice.title'),
      price: 3000,
      type: t('averageSalePrice.tl'),
      min: '164.12',
      max: '274.916',
      icon: <FaHome fill="var(--primary-clr)" size={70} />
    },
    {
      title: t('averageRentPrice.title'),
      price: 15,
      type: t('averageRentPrice.tl/Month'),
      min: '164.12',
      max: '274.916',
      icon: <FaHome fill="var(--primary-clr)" size={70} />
    },
    {
      title: t('returnOnInvestment.title'),
      price: 3000,
      type: t('returnOnInvestment.years'),
      description: t('returnOnInvestment.returnPeriodOfRentHouse'),
      icon: <FaRegCalendarAlt fill="var(--primary-clr)" size={70} />
    }
  ];
  return (
    <div>
      <HeadingWithUnderline className="mb-4 mt-4" underlineColor="var(--secondary-clr)">
        <h5>{title}</h5>
      </HeadingWithUnderline>
      <div>
        <Row>
          {salesData?.map((item, index) => (
            <Col md={4} key={index}>
              <div className={classes.contentWrap}>
                {item?.icon}
                <p>{item?.title}</p>
                <div className={classes.price}>
                  <h6>{item?.price}</h6>
                  <p>{item?.type}</p>
                </div>
                {item?.type !== 'Years' ? (
                  <div className={classes.minmax}>
                    <p>
                      {t('averageSalePrice.minimum')} {item.min}{' '}
                      <span> {t('averageSalePrice.tl')}</span>
                    </p>
                    <p>
                      {t('averageSalePrice.maximum')} {item.max}{' '}
                      <span>{t('averageSalePrice.tl')}</span>
                    </p>
                  </div>
                ) : (
                  <div className={classes.minmax}>
                    <p>{item?.description}</p>
                  </div>
                )}
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <div className={classes.mt30}>
        <Line options={options} data={data} />
      </div>
      <div className={classes.mt30}>
        <Line options={options} data={data} />
      </div>
    </div>
  );
}

export default AverageRatesWithMarketTrends;
