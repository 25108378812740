import React, { useEffect, useState } from 'react';
import classes from './eventFilters.module.css';
import CustomOverlay from '../CustomOverlay';
import { Col, Container, Row } from 'react-bootstrap';
import Button from '../Button/Button';
import { AiFillCaretDown } from 'react-icons/ai';
// import { ImLocation } from 'react-icons/im';
// import DropDown from '../DropDown/DropDown';
// import { Country } from 'country-state-city';
// import { Radio } from '../Radio/Radio';
import DateRangeCalendar from '../DateRangeCalendar';
import { getWindowWidth } from '../../CustomHooks/isMobileViewHook';
import EventsMoreFiltersModal from '../../modals/EventsMoreFiltersModal';
import { BiSearchAlt2 } from 'react-icons/bi';
import { FaFilter } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import LocationFiltersOverlay from '../LocationFilterOverlay';
import MapMain from '../maps/MapMain';
import { discardUndefinedOrNullValues } from '../../config/apiUrl';

/*eslint-disable*/
const FilterDiv = ({
  text,
  icon,
  iconRight = <AiFillCaretDown className={classes.icon} />,
  className = '',
  ...props
}) => {
  return (
    <div className={`${classes.dropdownBox} ${className}`} {...props}>
      <div>
        {icon} <span>{text}</span>
      </div>
      {iconRight && iconRight}
    </div>
  );
};

function EventFilters({ data, onSearch, filtersWrapperClass = '', containerClass, t }) {
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const { eventTypes } = useSelector((state) => state?.commonReducer);
  const [searchText, setSearchText] = useState('');
  const [mainData, setMainData] = useState({
    province: null,
    town: null,
    neighborhood: null,
    nearby: false,
    eventType: null,
    lng: null,
    lat: null
  });
  const [date, setDate] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);

  // For Popover
  const [show, setShow] = useState('');
  const [target, setTarget] = useState(null);
  const [screenWidth, setScreenWidth] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getWindowWidth(setScreenWidth);
  }, []);
  useEffect(() => {
  }, [searchText]);
  const handleClick = (event, type) => {
    if (type) {
      setShow(type);
    } else {
      setShow('');
    }
    if (event) {
      setTarget(event.target);
    } else {
      setTarget(null);
    }
  };
  const EventTypesDropDown = () => (
    <CustomOverlay
      show={show == 'event-types'}
      target={target}
      handleClose={(e) => handleClick('', e)}
      targetElement={
        <FilterDiv
          onClick={(e) => {
            handleClick(e, 'event-types');
          }}
          text={t('filters.eventType')}
        />
      }>
      <div className={[classes.overlayBox, classes.p0].join(' ')}>
        <ul className={classes.listOptionsWithBrBtm}>
          {eventTypes?.map((item, key) => (
            <li
              key={key}
              data-selected={mainData?.eventType?._id == item?._id}
              onClick={() => {
                setMainData((prev) => ({ ...prev, eventType: prev?.eventType?._id == item?._id ? null : item}));
                // setShow('');
              }}>
              {item?.title?.[selectedLanguage]}
            </li>
          ))}
        </ul>
      </div>
    </CustomOverlay>
  );

  const LocationDropDown = () => (
    <CustomOverlay
      show={show == 'location'}
      target={target}
      handleClose={(e) => handleClick('', e)}
      targetElement={
        <FilterDiv
          onClick={(e) => {
            handleClick(e, 'location');
          }}
          text={t('filters.location')}
        />
      }>
      <div className={[classes.overlayBox, classes.width280].join(' ')}>
        <LocationFiltersOverlay
          setLocationState={(e) => {
            setMainData(e);
          }}
          setSearchText={setSearchText}
          setClose={setShow}
          t={(e) => e}
          locationState={mainData}
        />
      </div>
    </CustomOverlay>
  );

  const DateRangeCalendarDropDown = ({ target, show, handleClick, screenWidth, t }) => (
    <CustomOverlay
      show={show == 'date-range'}
      target={target}
      handleClose={(e) => handleClick('', e)}
      targetElement={
        <FilterDiv
          onClick={(e) => {
            handleClick(e, 'date-range');
          }}
          text={t('filters.dateRange')}
        />
      }>
      <div className={[classes.overlayBox, classes.p0].join(' ')}>
        <DateRangeCalendar
          value={date}
          setValue={(e) => {
            setDate(e);
            if (e[0]?.endDate) {
              handleClick(null, null);
            }
          }}
          className={screenWidth < 992 && classes?.w100}
          minDate={undefined}
        />
      </div>
    </CustomOverlay>
  );


  const handleSearch = () => {
    const newData = {
      province: mainData?.province?._id,
      town: mainData?.town?._id,
      neighborhood: mainData?.neighborhood?._id,
      nearby: mainData?.nearby,
      ...(mainData?.nearby || searchText
        ? {
            lng: mainData?.longitude,
            lat: mainData?.latitude
          }
        : {}),
      search: searchText,
      eventType: mainData?.eventType?._id,
      minDate: date?.[0]?.startDate,
      maxDate: date?.[0]?.endDate
    };
    onSearch && onSearch(discardUndefinedOrNullValues(newData));
  };
  return (
    <Container className={`${containerClass}`}>
      <div className={[classes.filters, filtersWrapperClass].join(' ')}>
        <Row className={[classes.row, 'gx-0'].join(' ')}>
          {/* 1st Option */}
          {screenWidth > 992 && (
            <Col md={2} className={classes.width280}>
              <LocationDropDown />
            </Col>
          )}
          <Col md={4}>
            <MapMain
              setCoordinates={(e) => {
                setMainData((prev) => ({ ...prev, lng: e?.lng, lat: e?.lat }));
              }}
              address={searchText}
              setAddress={(e) => {
                setSearchText(e);
                setMainData((prev) => ({
                  ...prev,
                  nearby: false,
                  province: null,
                  town: null,
                  neighborhood: null
                }));
              }}
              placeholder={t('filters.search')}
              type="places"
              comboRootClassName={classes?.searchInput}
            />
            {/* <Input
              value={searchText}
              setValue={setSearchText}
              
              className={classes.searchInput}
            /> */}
          </Col>
          {/* 3rd Option */}
          {screenWidth > 992 && (
            <Col md={2} className={classes.width200}>
              <EventTypesDropDown />
            </Col>
          )}
          {/* 4th Option */}
          {screenWidth > 992 && (
            <Col md={2} className={classes.width180}>
              <DateRangeCalendarDropDown
                show={show}
                target={target}
                handleClick={handleClick}
                screenWidth={screenWidth}
                t={t}
              />
            </Col>
          )}
          {screenWidth <= 992 && (
            <Col className={classes.minWidth170}>
              <FilterDiv
                onClick={() => {
                  setShowModal(true);
                }}
                iconRight={screenWidth <= 600 ? false : <FaFilter className={classes.icon} />}
                text={
                  screenWidth <= 600 ? <FaFilter className={classes.icon} /> : t('filters.filters')
                }
              />
            </Col>
          )}
          <Button
            variant="secondary"
            borderRadius={'3px'}
            onClick={() => {
              setShow('');
              // onSearch && onSearch();
              handleSearch()
            }}
            className={classes.searchBtn}>
            {screenWidth <= 600 ? (
              <BiSearchAlt2 size={20} color="var(--white-clr)" />
            ) : (
              t('filters.searchBtn')
            )}
          </Button>
        </Row>
      </div>
      <style>{`
      .modal-body{
        overflow:visible;
      }
      `}</style>
      <EventsMoreFiltersModal show={showModal} setShow={setShowModal} t={t}>
        <Col md={12}>
          <LocationDropDown />
        </Col>
        <Col md={12}>
          <EventTypesDropDown />
        </Col>
        {/* 4th Option */}
        <Col md={12}>
          <DateRangeCalendarDropDown
            show={show}
            target={target}
            handleClick={handleClick}
            screenWidth={screenWidth}
            t={t}
          />
        </Col>
      </EventsMoreFiltersModal>
    </Container>
  );
}

export default EventFilters;
