import React, { useState } from 'react';
import classes from './AgentsManagement.module.css';
import SideBarSkeleton from '../../../Component/SideBarSkeleton';
import { Col, Container, Row } from 'react-bootstrap';
import CustomMaterialTable from '../../../Component/CustomMaterialTable';
import { FiEdit } from 'react-icons/fi';
import Button from '../../../Component/Button/Button';
import SearchInput from '../../../Component/SearchInput';
import { useNavigate } from 'react-router-dom';
import { IoIosAddCircle } from 'react-icons/io';
import AddCreditPointsModal from '../../../modals/AddCreditPointsModal/AddCreditPointsModal';
import { dateFilterOptions } from '../../../constant/commonData';
import DropDown from '../../../Component/DropDown/DropDown';
import { useDeleteAgentMutation, useGetAllAgentQuery } from '../../../store/apiSlice/apiSlice';
import moment from 'moment';
import useDebounce from '../../../CustomHooks/useDebounce';
import { recordsLimit } from '../../../config/apiUrl';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { useSelector } from 'react-redux';

// const tableData = Array(10)
//   .fill({
//     id: '5645',
//     date: '2020.11.12',
//     agent: 'John Smith',
//     email: 'abc@yahoo.com',
//     phoneNo: '05012454'
//   })
//   ?.map((item) => ({ ...item, id: Math.floor(Math.random() * 10000) }));

function AgentsManagement() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const search = useDebounce(searchText, 500);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showModal, setShowModal] = useState('');
  const [dateFilter, setDateFilter] = useState(dateFilterOptions[0]);
  const [page, setPage] = useState(1);
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const [selectedItem, setSelectedItem] = useState(null);
  const { t } = useTranslation('dashboardAgents');

  const { data, isLoading, isFetching } = useGetAllAgentQuery({
    page: page,
    limit: recordsLimit,
    search: search,
    sortBy: dateFilter?.value,
    lan: selectedLanguage
  });
  const [deleteAgent, { isLoading: isDeleting }] = useDeleteAgentMutation();

  const headings = [
    {
      id: 'createdAt',
      label: t('table.col1'),

      renderValue: (item, col) => {
        return moment(item[col?.id]).format('YYYY.DD.MM ');
      }
    },
    {
      // id: 'agentName',
      label: t('table.col2'),
      renderValue: (item) => {
        return item?.agentName?.[selectedLanguage];
      }
    },
    {
      label: t('table.col3'),
      renderValue: (item) => {
        return item?.email;
      }
    },
    {
      label: t('table.col4'),
      renderValue: (item) => {
        return item?.phoneNumber;
      }
    },
    { id: 'options', label: t('table.col5'), align: 'center' }
  ];

  return (
    <SideBarSkeleton>
      <Container fluid className={classes.page}>
        <Row>
          <Col md={12} className={classes.mb58}>
            <div className={classes.header}>
              <div className={classes.left}>
                <SearchInput
                  placeholder={t('table.searchInput')}
                  setter={setSearchText}
                  state={searchText}
                  variant="dashboard"
                  containerStyle={{ width: '300px' }}
                />
                <DropDown
                  label={t('table.sortByDate')}
                  containerClassName={classes.dropDownContainer}
                  customStyle={{ padding: '0px 6px', minHeight: '40px', minWidth: '200px' }}
                  options={dateFilterOptions}
                  setValue={(e) => {
                    setDateFilter(e);
                  }}
                  value={dateFilter}
                  placeholder={'Select Date'}
                  // isClearable={true}
                />
              </div>
              <div className={classes.headerRight}>
                <Button
                  borderRadius={'8px'}
                  className={classes.headerBtn}
                  onClick={() => navigate('/company/agents-management/new-agent')}>
                  {t('table.createNewagents')}
                </Button>
                <Button
                  borderRadius={'8px'}
                  onClick={() => {
                    deleteAgent({
                      ids: selectedRows?.map((item) => item?._id)
                    });
                  }}
                  className={classes.deleteBtn}
                  disabled={selectedRows?.length == 0}>
                  {isDeleting ? t('table.deleting') : t('table.delete')}
                </Button>
              </div>
            </div>
          </Col>
          <Col md={12}>
            <h5 className={classes.heading}>{t('table.title')}</h5>
          </Col>
          <Col md={12}>
            <CustomMaterialTable
              headings={headings}
              data={data?.data}
              showOptionsColumn
              isLoading={isLoading || isFetching}
              page={page}
              setPage={setPage}
              totalRecords={Math.ceil(data?.totalRecords / recordsLimit)}
              options={[
                {
                  label: t('table.options.edit'),
                  icon: <FiEdit size={18} />,
                  onClick: (e, item) => {
                    console.log(e, item, '123 123 123');
                    navigate(`edit-agent/${item?.slug}`);
                  }
                },

                {
                  label: t('table.options.addPoints'),
                  icon: <IoIosAddCircle size={18} />,
                  onClick: (e, a) => {
                    setSelectedItem(a);
                    setShowModal(e);
                  }
                }
              ]}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              allowMultipleSelection
            />
          </Col>
        </Row>
      </Container>
      {showModal?.toLowerCase() == 'add points' && (
        <AddCreditPointsModal
          show={showModal?.toLowerCase() == 'add points'}
          setShow={() => setShowModal('')}
          data={selectedItem}
        />
      )}
    </SideBarSkeleton>
  );
}

export default AgentsManagement;
