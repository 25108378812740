import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { AiFillPrinter } from 'react-icons/ai';
import { BsFillShareFill } from 'react-icons/bs';
import { IoIosMail, IoLogoWhatsapp } from 'react-icons/io';
import { MdLocationPin, MdWifiCalling3 } from 'react-icons/md';
import { SlUserFollow, SlUserFollowing } from 'react-icons/sl';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { restrictOnlyUserActions } from '../../Helper/restrictActions';
import { imageUrl } from '../../config/apiUrl';
import CompanyOrAgentEmailModal from '../../modals/CompanyOrAgentEmailModal';
import { useFollowUnFollowCompanyOrAgentMutation } from '../../store/apiSlice/apiSlice';
import { updateUser } from '../../store/auth/authSlice';
import BannerImageAd from '../BannerImageAd';
import Button from '../Button/Button';
import ShareModal from '../ShareModal/ShareModal';
import ShowMoreShowLessText from '../ShowMoreShowLess/ShowMoreShowLessText';
import Tooltip from '../Tooltip';
import MapMain from '../maps/MapMain';
import classes from './CompanyIntroSection.module.css';

// const companyLogo =
//   'https://www.makeitealing.co.uk/sites/default/files/upload/logos/barnard_marcus_3f09fb979cb9238cb5ffc63a6d28aed6.png';

const CompanyIntroSection = ({ className, data, t, verticalBanner = '' }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isLogin, user } = useSelector((state) => state.authReducer);
  const [onFollow, { isLoading }] = useFollowUnFollowCompanyOrAgentMutation();

  const isFollowed = user?.companyFollowing?.includes(data?._id);

  const handleAction = () => {
    const isAllowedActions = restrictOnlyUserActions(user?.role);
    if (!isLogin) {
      navigate('/login');
    } else if (isAllowedActions) {
      return true;
    }
  };

  async function handleFollow() {
    if (!handleAction()) return;
    const res = await onFollow(data?._id);
    if (res?.data) {
      dispatch(updateUser(res?.data?.data));
      toast.success(`${'Company'} ${isFollowed ? 'unfollowed' : 'followed'} successfully`);
    }
  }

  return (
    <section className={className && className}>
      <Row className={classes.gap}>
        {/* Basic Intro */}
        <Col sm={12} md={4} lg={3}>
          <div className={classes?.agentIntroContainer}>
            <div className={classes?.profileImageContainer}>
              <img src={imageUrl(data?.photo)} />
            </div>
            {/* map */}
            <div className={classes?.mapContainer}>
              <MapMain
                mapClass={classes?.mapClass}
                zoom={13}
                options={mapOptions}
                location={{
                  lng: data?.location?.coordinates[1],
                  lat: data?.location?.coordinates[0]
                }}
              />
            </div>

            {/* <div className={classes?.iconsContainer}>
              <div>
                <MdWifiCalling3 fill="#E2831B" size={30} />
              </div>
              <div>
                <GoMail fill="#E2831B" size={30} />
              </div>
              <div>
                <IoLogoWhatsapp fill="#E2831B" size={30} />
              </div>
            </div> */}
            <div className={classes.socialIconsWrap1}>
              <div className={classes.socialIcons1}>
                <Tooltip text={t('company.tooltips.call')} href={`tel:${data?.phoneNumber}`}>
                  <MdWifiCalling3 fill="#E2831B" size={30} />
                </Tooltip>
              </div>
              <div
                className={[classes.socialIcons1, classes.midIcon].join(' ')}
                onClick={() => setIsModalOpen(true)}>
                <Tooltip text={t('company.tooltips.email')}>
                  <IoIosMail fill="#E2831B" size={32} />
                </Tooltip>
              </div>
              <div className={classes.socialIcons1}>
                <Tooltip
                  text={t('company.tooltips.whatsapp')}
                  href={`https://wa.me/${data?.whatsapp}`}
                  target={'_blank'}>
                  <IoLogoWhatsapp fill="#E2831B" size={30} />
                </Tooltip>
              </div>
            </div>
          </div>
          {/* Follow Btn */}

          {/* Follow Btn */}
          <Button
            disabled={isLoading}
            className={classes?.followBtnContainer}
            onClick={handleFollow}>
            {isLoading ? (
              ''
            ) : isFollowed ? (
              <SlUserFollowing fill="#fff" size={25} />
            ) : (
              <SlUserFollow fill="#fff" size={25} />
            )}
            {isLoading
              ? t('company.followBtnLoading')
              : isFollowed
              ? t('company.following')
              : t('company.follow')}
          </Button>
        </Col>

        {/* About Me */}
        <Col sm={12} md={8} lg={7}>
          <div className={classes?.aboutMeContainer}>
            {/* company logo + share */}
            <div className={classes?.companyLogoAndShareContainer}>
              <div className={classes?.companyInfo}>
                <h6>{data?.companyName?.[selectedLanguage]}</h6>
                <h5>{data?.companyType?.[selectedLanguage]}</h5>
                <div className={classes?.location}>
                  <MdLocationPin fill="var(--secondary-clr)" />
                  <span>{data?.address}</span>
                </div>
              </div>
              {/* Share + print */}
              <div className={classes?.shareLogoContainer}>
                <div>
                  <BsFillShareFill fill="#a4a4a4" size={25} />
                </div>
                <div>
                  <AiFillPrinter fill="#a4a4a4" size={25} />
                </div>
              </div>
            </div>

            {/* Agent Details */}
            <div className={classes?.agentDetailsContainer}>
              <Row className={classes?.agentDetails}>
                <Col md={4}>{t('company.employees')} </Col>
                <Col md={8}>
                  {data?.agents?.length} {t('company.agents')}
                </Col>
              </Row>
              <Row className={classes?.agentDetails}>
                <Col md={4}>{t('company.weSpeak')} </Col>
                <Col md={8}>{data?.language?.map((e) => e?.[selectedLanguage])?.join(', ')}</Col>
              </Row>
              <Row className={classes?.agentDetails}>
                <Col md={4}>{t('company.properties')} </Col>
                <Col md={8}>
                  ({data?.buyProperties ?? 0}) {t('company.forBuy')}, ({data?.rentProperties ?? 0}){' '}
                  {t('company.forRent')}, ({data?.totalProjects ?? 0}) {t('company.projects')}, (
                  {data?.totalEvents ?? 0}) {t('company.events')}
                </Col>
              </Row>
              <div className={classes?.agentDetails}>
                <p>{t('company.serviceAreas')} </p>
                <p>{data?.serviceAreas?.[selectedLanguage]}</p>
              </div>
            </div>

            {/* Description */}
            <div className={classes?.descriptionContainer}>
              <h5>{t('company.aboutMe')}</h5>
              <ShowMoreShowLessText text={data?.description?.[selectedLanguage]} visibility={200} />
            </div>
          </div>
        </Col>

        {/* Banner Ad */}
        <Col sm={12} md={12} lg={2}>
          <div className={classes?.bannerAdContainer}>
            <BannerImageAd image={verticalBanner} className={classes.banner} />
          </div>
        </Col>
      </Row>

      {isShareModalOpen && <ShareModal show={isShareModalOpen} setShow={setIsShareModalOpen} />}
      {isModalOpen && (
        <CompanyOrAgentEmailModal
          show={isModalOpen}
          setShow={setIsModalOpen}
          type="company"
          data={data}
        />
      )}
    </section>
  );
};

export default CompanyIntroSection;

// const _d = `Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
// molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
// numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
// optio, eaque rerum!
// Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
// molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
// numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
// optio, eaque rerum!
// Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
// molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
// numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
// optio, eaque rerum!

// `;

const mapOptions = {
  mapTypeControl: false,
  streetViewControl: false,
  zoomControl: false,
  styles: [
    {
      featureType: 'poi',
      elementType: 'labels'
    }
  ]
};
