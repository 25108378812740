import React from 'react';
import { MdLocationPin } from 'react-icons/md';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
// import { BsArrowLeftRight } from 'react-icons/bs';
import moment from 'moment';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { IoIosMail, IoLogoWhatsapp } from 'react-icons/io';
import { MdWifiCalling3 } from 'react-icons/md';

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BiBuildingHouse, BiTransfer } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { toast } from 'react-toastify';
import { isMobileViewHook } from '../../CustomHooks/isMobileViewHook';
import { restrictOnlyUserActions } from '../../Helper/restrictActions';
import { imageUrl } from '../../config/apiUrl';
import { DateTime, NoOfUnits } from '../../constant/imagePath';
import { useFavoriteEntityMutation, usePostApiMutation } from '../../store/apiSlice/apiSlice';
import { updateUser } from '../../store/auth/authSlice';
import { usdToOther } from '../../utils/helpers';
import IconList from '../IconList/IconList';
import Tooltip from '../Tooltip';
import classes from './ProjectHorizontalCard.module.css';

/* eslint-disable */

const ProjectHorizontalCard = ({
  data,
  index,
  className = '',
  onEmailClick = () => null,
  refetch
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('webCommon');
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const { currency } = useSelector((state) => state.converterReducer);

  const { user, isLogin } = useSelector((state) => state.authReducer);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = React.useState(false);
  const [postApiFunc] = usePostApiMutation();

  const [onFavorite, { isLoading: isFavoriting }] = useFavoriteEntityMutation();

  const responsiveSettings = [
    { breakpoint: isMobile ? 700 : 465, settings: { slidesToShow: 2, slidesToScroll: 2 } },
    { breakpoint: 200, settings: { slidesToShow: 1, slidesToScroll: 1 } }
  ];

  useEffect(() => {
    isMobileViewHook(setIsMobile, 992);
  }, []);

  const allowCallOrWhatsApp = data?.assignedAgent ?? data?.agent ?? data?.company;

  const handleCompare = async (data) => {
    const onlyCompareProperty = user?.compareList?.filter((item) => item?.type == 'Project');
    const ifAlreadyExist = onlyCompareProperty?.find((item) => item?.entity == data?._id);
    if (!ifAlreadyExist && onlyCompareProperty?.length >= 3)
      return toast.error('You can only compare 3 project at a time');

    const response = await postApiFunc({
      url: `users/compare/add`,
      method: 'POST',
      tag: `compareListFront`,
      data: {
        id: data?._id,
        type: 'project'
      }
    });
    if (response?.data) {
      dispatch(updateUser(response?.data?.data));
      toast.success(
        `Project ${ifAlreadyExist ? 'removed from' : 'added in'} compare list successfully`
      );
    }
  };

  const isFavorite = data?.favoriteBy?.includes(user?._id);
  const handleFavorite = async () => {
    if (isFavoriting) return;
    const res = await onFavorite({ id: data?._id, type: 'project' });
    refetch && refetch();
    if (res?.data) {
      if (res?.data?.data?.favoriteBy?.includes(user?._id)) {
        toast.success(`${'Project added in favorite list'} successfully`);
      } else {
        toast.success(`${'Project removed from favorite list'} successfully`);
      }
    }
  };

  const handleAction = () => {
    const isAllowedActions = restrictOnlyUserActions(user?.role);
    if (!isLogin) {
      navigate('/login');
    } else if (isAllowedActions) {
      return true;
    }
  };

  return (
    <>
      <style>
        {`
      .${classes.sliderContainer} .react-slideshow-container .default-nav{
        background: transparent;
        color:#FFFFFF !important;
        transition: 0.5s ease;
        opacity:0;
      }
      .${classes.sliderContainer} .react-slideshow-container .default-nav svg{
        fill:#FFFFFF !important;
      }
      .${classes.sliderContainer} .react-slideshow-container .default-nav:hover{
        background: transparent;
      }

      .${classes.sliderContainer}:hover .react-slideshow-container .default-nav{
        opacity:1;
      }
      `}
      </style>
      <div className={[classes?.propertyMain, className].join(' ')}>
        {/* Slider */}
        <div className={classes?.sliderContainer}>
          <Slide slidesToScroll={2} slidesToShow={2} responsive={responsiveSettings}>
            {data?.images?.map((image, i) => (
              <div className={classes?.propertyImageContainer} key={i}>
                <img src={imageUrl(image)} draggable={false} />
              </div>
            ))}
          </Slide>
        </div>

        <div
          className={classes?.rightContainer}
          onClick={() => {
            navigate(`/projects/${data?.slug ?? '1'}`);
          }}>
          {/* Price + Logo */}
          <div className={classes?.topContainer}>
            <div className={classes?.priceContainer}>
              <h6>{data?.name?.[selectedLanguage]}</h6>

              <span>{usdToOther({ amount: data?.price, ...currency })}</span>
            </div>
            <div
              className={classes?.logoContainer}
              onClick={(e) => {
                e?.stopPropagation();
                navigate(`/company/${data?.company?.slug}`);
              }}>
              <img src={imageUrl(data?.company?.photo)} />
            </div>
          </div>

          {/* Title + Location */}
          <div className={classes?.titleContainer}>
            <h6>{data?.title?.[selectedLanguage]}</h6>
            <div className={classes?.location}>
              <MdLocationPin fill="var(--text-dark-gray-clr)" />
              <span>{data?.address}</span>
            </div>
          </div>

          {/* Property Info */}
          <div className={classes?.propertyInfoContainer}>
            <IconList
              className={classes.infoIcons}
              Icon={BiBuildingHouse}
              label={t('projectCard.tooltips.projectType')}
              value={data?.projectType?.title?.[selectedLanguage]}
            />
            <div className={classes.propertyDetail}>
              <Tooltip text={t('projectCard.tooltips.noOfUnits')}>
                <ReactSVG
                  fill="var(--text-dark-gray-clr)"
                  className={classes.svgIcon}
                  src={NoOfUnits}
                />
              </Tooltip>

              <p>{data?.noOfUnits} Units</p>
            </div>
            <div className={classes.propertyDetail}>
              <Tooltip text={t('projectCard.tooltips.deliveryDate')}>
                <ReactSVG
                  color="var(--text-dark-gray-clr)"
                  className={classes.svgIcon}
                  src={DateTime}
                />
              </Tooltip>
              <p>{moment(data?.deliveryDate).format('ll')}</p>
            </div>
          </div>

          {/* bottom strip */}
          <div className={classes?.bottomStrip}>
            {/* <div className={classes?.iconsContainer}>
            <div>
              <MdWifiCalling3 fill="#E2831B" size={30} />
            </div>
            <div>
              <GoMail fill="#E2831B" size={30} />
            </div>
            <div>
              <IoLogoWhatsapp fill="#E2831B" size={30} />
            </div>
          </div> */}

            <div className={classes.socialIconsWrap1}>
              <div
                className={classes.socialIcons1}
                onClick={(e) => {
                  e?.stopPropagation();
                }}>
                <Tooltip
                  text={t('projectCard.tooltips.call')}
                  href={`tel:${allowCallOrWhatsApp?.phoneNumber}`}>
                  <MdWifiCalling3 fill="#E2831B" className={classes.iconsBottom} />
                </Tooltip>
              </div>
              <div
                className={[classes.socialIcons1, classes.midIcon].join(' ')}
                onClick={(e) => {
                  e?.stopPropagation();
                  onEmailClick && onEmailClick();
                }}>
                <Tooltip text={t('projectCard.tooltips.email')}>
                  <IoIosMail fill="#E2831B" className={classes.iconsBottom} />
                </Tooltip>
              </div>
              <div
                className={classes.socialIcons1}
                onClick={(e) => {
                  e?.stopPropagation();
                }}>
                <Tooltip
                  text={t('projectCard.tooltips.whatsapp')}
                  href={`https://wa.me/${allowCallOrWhatsApp?.whatsapp}`}
                  target={'_blank'}>
                  <IoLogoWhatsapp fill="#E2831B" className={classes.iconsBottom} />
                </Tooltip>
              </div>
            </div>
            {/* right */}
            <div className={classes.iconWrap}>
              <Tooltip text={t('projectCard.tooltips.compare')}>
                <BiTransfer
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!handleAction()) return;
                    handleCompare(data);
                  }}
                  className={classes.iconsBottom}
                  fill={`${
                    user?.compareList?.find((item) => item?.entity == data?._id)
                      ? 'var(--action-icon-clr)'
                      : 'var(--text-light-gray-clr)'
                  } `}
                />
              </Tooltip>
              <Tooltip
                text={
                  isFavorite
                    ? t('projectCard.tooltips.removeFavorite')
                    : t('projectCard.tooltips.addFavorite')
                }>
                {isFavorite ? (
                  <AiFillHeart
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFavorite();
                    }}
                    fill="var(--action-icon-clr)"
                    className={classes.iconsBottom}
                  />
                ) : (
                  <AiOutlineHeart
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!handleAction()) return;
                      handleFavorite();
                    }}
                    fill="var(--text-light-gray-clr)"
                    className={classes.iconsBottom}
                  />
                )}
              </Tooltip>
            </div>
            {/* <div className={classes?.bottomStripRight}>
            <div onClick={() => setIsComparable((p) => !p)}>
              <BsArrowLeftRight fill={isComparable ? 'var(--primary-clr)' : 'gray'} size={28} />
            </div>
            <div onClick={() => setIsFavourite((p) => !p)}>
              <AiOutlineHeart fill={isFavourite ? 'var(--primary-clr)' : 'gray'} size={28} />
            </div>
          </div> */}
          </div>
        </div>

        {(data?.isPremium || data?.isFeatured) && (
          <div className={classes?.featuredPremiumContainer}>
            <span
              className={`${
                (data?.isFeatured && classes?.featuredIco) ||
                (data?.isPremium && classes?.premiumIco)
              }`}>
              {(data?.isPremium && t('projectCard.premium')) ||
                (data?.isFeatured && t('projectCard.featured'))}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default ProjectHorizontalCard;

// const responsiveSettings = [
//   {
//     breakpoint: 800,
//     settings: {
//       slidesToShow: 3,
//       slidesToScroll: 3
//     }
//   },
//   {
//     breakpoint: 500,
//     settings: {
//       slidesToShow: 2,
//       slidesToScroll: 2
//     }
//   }
// ];
