import React, { useState } from 'react';
import classes from './Signup.module.css';
import Footer from '../../Component/Footer';
import Header from '../../Component/Header';
import {
  SignUpPageIcon1,
  SignUpPageIcon2,
  SignUpPageIcon3,
  SignUpPageIcon4,
  SignUpPageIcon5,
  SignUpPageIcon6,
  WhiteLogo
} from '../../constant/imagePath';
import { Col, Container, Row } from 'react-bootstrap';
import Input from '../../Component/Input/Input';
import Checkbox from '../../Component/Checkbox/Checkbox';
import Button from '../../Component/Button/Button';
import { Link, useNavigate } from 'react-router-dom';
// import { FaFacebookF } from 'react-icons/fa';
import { AiFillGoogleCircle } from 'react-icons/ai';
import { useRegisterCustomerMutation } from '../../store/apiSlice/apiSlice';
import { toast } from 'react-toastify';
import { capitalizeFirstLetter, formRegEx, formRegExReplacer } from '../../config/apiUrl';
import { useTranslation } from 'react-i18next';

const RegisterationForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('loginAndSignup');
  const d = (e) => t(`signup.${e}`);

  const [handleSignup, { isLoading }] = useRegisterCustomerMutation();
  const [formStates, setFormStates] = useState({
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    allowTerms: false
  });

  function handleFormStateUpdate(key, value) {
    setFormStates({ ...formStates, [key]: value });
  }

  const onSubmit = async () => {
    const body = {
      firstName: formStates?.firstName,
      lastName: formStates?.lastName,
      email: formStates?.email,
      password: formStates?.password
    };
    for (let key in body) {
      if (!body[key]) {
        return toast.error(
          `Please fill the ${capitalizeFirstLetter(
            key.replace(formRegEx, formRegExReplacer)
          )} field`
        );
      }
    }
    if (!formStates.allowTerms) {
      return toast.error(d('pleaseAcceptTheTermsAndConditions'));
    }
    const res = await handleSignup(body);
    if (res?.data) {
      toast.success(d('accountCreatedSuccessfully'));
      navigate('/login');
    }
  };

  return (
    <form
      className={classes.form}
      onSubmit={(e) => {
        e?.preventDefault();
      }}>
      <Row className={classes.formRow}>
        <Col md={12}>
          <h4>{d('title')}</h4>
        </Col>

        <Col md={12}>
          <Input
            value={formStates?.firstName}
            setValue={(e) => handleFormStateUpdate('firstName', e)}
            placeholder={d('firstName')}
          />
        </Col>
        <Col md={12}>
          <Input
            value={formStates?.lastName}
            setValue={(e) => handleFormStateUpdate('lastName', e)}
            placeholder={d('lastName')}
          />
        </Col>
        <Col md={12}>
          <Input
            value={formStates?.email}
            setValue={(e) => handleFormStateUpdate('email', e)}
            type={'email'}
            placeholder={d('enterYourEmail')}
          />
        </Col>
        <Col md={12}>
          <Input
            value={formStates?.password}
            setValue={(e) => handleFormStateUpdate('password', e)}
            type={'password'}
            placeholder={d('createYourPassword')}
          />
        </Col>
        <Col md={12}>
          <Checkbox
            value={formStates?.allowTerms}
            setValue={(e) => handleFormStateUpdate('allowTerms', e)}
            label={d('iAcceptTheTermsConditions')}
          />
        </Col>
        <Col md={12} className={classes.btns}>
          <Button variant="secondary" onClick={onSubmit} disabled={isLoading}>
            {isLoading ? `${d('creating')}...` : d('createAccount')}
          </Button>
          <Button
            variant=""
            leftIcon={<AiFillGoogleCircle color="var(--white-clr)" size={20} />}
            className={classes.googleBtn}>
            {d('signInWithGoogle')}
          </Button>
        </Col>
        <Col md={12} className="j-center">
          <p className={classes.alreadyRegistered}>
            {d('alreadyRegistered')}
            <Link to={'/login'} className="link">
              {' '}
              {d('loginHere')}
            </Link>
          </p>
        </Col>
      </Row>
    </form>
  );
};

function Signup() {
  // Form States
  const { t } = useTranslation('loginAndSignup');
  const d2 = (e) => t(`powerFullTools.${e}`);

  const powerfullTools = [
    { title: d2('title1'), image: SignUpPageIcon1 },
    {
      title: d2('title2'),
      image: SignUpPageIcon2
    },
    { title: d2('title3'), image: SignUpPageIcon3 },
    { title: d2('title4'), image: SignUpPageIcon4 },
    {
      title: d2('title5'),
      image: SignUpPageIcon5
    },
    { title: d2('title6'), image: SignUpPageIcon6 }
  ];

  return (
    <>
      <div className={classes.page}>
        <Header containerClass="" />
        {/* Hero Section */}
        <section
          className={classes.heroSection}
          // imageUrl={HomeHeroBgImg}
        >
          <Container>
            <Row className={classes.heroRow}>
              <Col xl={{ span: 4, order: 1 }} xs={{ span: 12, order: 2 }}>
                <RegisterationForm />
              </Col>
              <Col xl={{ span: 8, order: 2 }} xs={{ span: 12, order: 1 }}>
                <div className={classes.heroContent}>
                  <div className={classes.logoDiv}>
                    <img src={WhiteLogo} draggable={false} />
                  </div>
                  <h3>{d2('title')}</h3>
                  <p>{d2('desc')}</p>
                </div>
                <Row className={classes.optionsRow}>
                  {powerfullTools?.map((item, index) => (
                    <Col lg={4} md={6} sm={6} xs={6} key={index}>
                      <div className={classes.iconDiv}>
                        <div className={classes.imgDiv}>
                          <img src={item?.image} />
                        </div>
                        <p>{item?.title}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Signup;
