import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedLanguage: 'en',
  direction:'ltr',
};

const languageSlice = createSlice({
  name: 'languageReducer',
  initialState,
  reducers: {
    setSelectLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
    setDirection: (state, action) => {
      state.direction = action.payload;
    }
  }
});

export const { setSelectLanguage,setDirection } = languageSlice.actions;

export default languageSlice.reducer;
