/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SideBarSkeleton from '../../../Component/SideBarSkeleton';
import classes from './NewEvent.module.css';
import Button from '../../../Component/Button/Button';
import { IoChevronBackOutline } from 'react-icons/io5';
import Input from '../../../Component/Input/Input';
import DropDown from '../../../Component/DropDown/DropDown';
import TextArea from '../../../Component/TextArea';

import MapMain from '../../../Component/maps/MapMain';
import FileUpload from '../../../Component/FileUpload';
import { eventTypeOptions } from '../../../config/DummyData';
import { useNavigate, useParams } from 'react-router-dom';
import {
  languagesKeysObject,
  languagesOptions,
  languagesWithFlags
} from '../../../constant/commonData';
import LanguagesDropDown from '../../../Component/LanguagesDropDown';
import { useSelector } from 'react-redux';
import LocationComponent from '../../../Component/LocationComponent';
import {
  useAddOrEditEventMutation,
  useDeleteEventMediaMutation,
  useGetSingleEventQuery
} from '../../../store/apiSlice/apiSlice';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CreateFormData, formatLabel } from '../../../config/apiUrl';
import { toast } from 'react-toastify';
import FormLanguages from '../../../Component/FormLanguages';
import validator from 'validator';
import { ProfileWithEditButton } from '../../../Component/ProfileWithEditButton';
import UploadImageBox from '../../../Component/UploadImageBox';
import { Loader } from '../../../Component/Loader';

function NewEvent() {
  const navigate = useNavigate();
  const slug = useParams()?.slug;
  // Language
  const [language, setLanguage] = useState(languagesOptions[0]);
  // Language

  const { t } = useTranslation(['dashboardEvents', 'locationComp'], {
    lng: language?.value
  });
  const { t: languageD } = useTranslation('locationComp', { lng: language?.value });
  const [deleteEventMedia, { isLoading: isDeleting }] = useDeleteEventMediaMutation();

  // Language
  const [formStates, setFormStates] = useState({
    eventName: languagesKeysObject,
    title: languagesKeysObject,
    description: languagesKeysObject,
    eventType: '',
    price: '',
    DateTime: ''
  });

  //  media
  const [images, setImages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [videoLink, setVideoLink] = useState('');
  const { eventTypes } = useSelector((state) => state?.commonReducer);
  const [addOrEditFn, { data, error, isLoading: loading }] = useAddOrEditEventMutation();

  const { data: singleEventData, isLoading: loaderApi } = useGetSingleEventQuery(
    { slug: slug },
    {
      skip: !slug
    }
  );

  const [locationState, setLocationState] = useState({
    province: '',
    town: '',
    neighbourhood: '',
    address: '',
    lat: '',
    lng: ''
  });
  const validateParams = () => {
    const multiLanguageParams = {
      name: formStates?.eventName,
      title: formStates?.title,
      description: formStates?.description
    };

    const params = {
      eventType: formStates?.eventType?._id,
      price: formStates?.price,
      eventDate: formStates?.DateTime,
      images: images,
      videoLink,
      province: locationState?.province?._id,
      town: locationState?.town?._id,
      neighborhood: locationState?.neighbourhood?._id,
      address: locationState?.address,
      lat: locationState?.lat,
      lng: locationState?.lng

  };

  
    for (let lang in languagesKeysObject) {
      // validate multiLanguageParams
      for (let key in multiLanguageParams) {
        if (!multiLanguageParams[key][lang]) {
          toast.error(`Please fill the ${formatLabel(key)} field in language "${lang}".`);
          return [false, null];
        }
      }

      // validate params
      for (let key in params) {
        if (!params[key]) {
          toast.error(`Please fill the ${formatLabel(key)} field.`);
          return [false, null];
        }

        // if key is a url
        if (key.includes('Link') || key.includes('link')) {
          if (typeof params[key] == 'object') {
            return [true, { ...params, ...multiLanguageParams }];
          }
          if (!validator.isURL(params[key])) {
            toast.error(`Please enter a valid ${formatLabel(key)}.`);
            return [false, null];
          }
        }
      }

      if (uploadedImages?.length + images?.length < 3) {
        toast.error('Please upload at least 3 event images.');
        return [false, null];
      }
  
    }

    return [true, { ...params, ...multiLanguageParams }];
  };

  const handleSubmit = async (e) => {
    const [isValid, params] = validateParams();
    if (!isValid) return;
    const formData = await CreateFormData(params);
    const response = await addOrEditFn({ data: formData, slug });
    if (response.data) {
      toast.success(`Event ${slug ? 'updated' : 'created'} successfully`);
      navigate(-1);
    }
  };

  useEffect(() => {
    if (singleEventData?.data) {
      setFormStates({
        eventName: singleEventData?.data?.name,
        title: singleEventData?.data?.title,
        description: singleEventData?.data?.description,
        eventType: singleEventData?.data?.eventType,
        price: singleEventData?.data?.price,
        DateTime: moment(singleEventData?.data?.eventDate).format('YYYY-MM-DD')
      });
      setLocationState({
        province: singleEventData?.data?.province,
        town: singleEventData?.data?.town,
        neighbourhood: singleEventData?.data?.neighborhood,
        address: singleEventData?.data?.address,
        lat: singleEventData?.data?.location.coordinates[1],
        lng: singleEventData?.data?.location.coordinates[0]
      });
      setVideoLink(singleEventData?.data?.videoLink);
      setUploadedImages(singleEventData?.data?.images);

      // setEventName(singleEventData?.data?.name);
      // setTitle(singleEventData?.data?.title);
      // setDescription(singleEventData?.data?.description);
      // setEventType(eventTypes?.find((item) => item?._id === singleEventData?.data?.eventType));
      // setDateTime(moment(singleEventData?.data?.eventDate).format('YYYY-MM-DD'));
      // setPrice(singleEventData?.data?.price);
      // setVideoLink(singleEventData?.data?.videoLink);
      // setLocationState({
      //   province: singleEventData?.data?.province,
      //   town: singleEventData?.data?.town,
      //   neighbourhood: singleEventData?.data?.neighbourhood,
      //   location: singleEventData?.data?.location,
      //   address: singleEventData?.data?.address,
      //   lat:
      //     singleEventData?.data?.location?.coordinates?.length > 0
      //       ? singleEventData?.data?.location?.coordinates[1]
      //       : '',
      //   lng:
      //     singleEventData?.data?.location?.coordinates?.length > 0
      //       ? singleEventData?.data?.location?.coordinates[0]
      //       : ''
      // });
    }
  }, [singleEventData]);

  const deleteMedia = async (e) => {
    const res = await deleteEventMedia(e);
    if (res?.data) {
      if (e?.type == 'images') {
        setUploadedImages((prev) => prev.filter((a) => a !== e?.key));
      }
      toast.success('Media deleted successfully');
    }
  };

  return (
    <SideBarSkeleton>
      {loaderApi ? (
        <Loader className={classes?.loader} />
      ) : (
        <Container fluid className={classes.page}>
          <Row>
            <Col md={12} className={classes.mb30}>
              <Button
                leftIcon={<IoChevronBackOutline />}
                variant=""
                className={classes.backBtn}
                onClick={() => {
                  // navigate('/events-management');
                  navigate(-1);
                }}>
                {t('form.goBack')}
              </Button>
            </Col>
            <Col md={12}>
              <div
                className={[
                  classes.formBg,
                  ['ar', 'fa']?.includes(language?.value) ? 'rtl' : 'ltr'
                ].join(' ')}>
                <Container>
                  <Row className={classes.formRow}>
                    <Col md={12}>
                      <h3>{slug ? t('form.titleEdit') : t('form.titleCreate')} </h3>
                    </Col>
                    {/* <Col md={6}>

                    <ProfileWithEditButton
                      isEdit={true}
                      updateImage={image}
                      setUpdateImage={setImage}
                    />
                  </Col> */}
                    <Col md={12}>
                      <div className={classes.headAndDropDown}>
                        <h4>{t('form.tagline')}</h4>
                        <FormLanguages value={language} setter={setLanguage} />
                      </div>
                    </Col>

                    <Col md={12}>
                      <Input
                        label={t('form.eventsTitleLabel')}
                        placeholder={t('form.eventsTitlePlaceholder')}
                        variant="dashboard"
                        value={formStates?.eventName?.[language?.value]}
                        setValue={(e) => {
                          setFormStates({
                            ...formStates,
                            eventName: {
                              ...formStates?.eventName,
                              [language?.value]: e
                            }
                          });
                        }}
                      />
                    </Col>
                    <Col md={12}>
                      <Input
                        label={t('form.eventsTitle')}
                        placeholder={t('form.eventsPlaceholder')}
                        variant="dashboard"
                        value={formStates?.title?.[language?.value]}
                        setValue={(e) => {
                          setFormStates({
                            ...formStates,
                            title: {
                              ...formStates?.title,
                              [language?.value]: e
                            }
                          });
                        }}
                      />
                    </Col>
                    <Col md={12}>
                      <TextArea
                        label={t('form.eventsDescriptionLabel')}
                        placeholder={t('form.eventsDescriptionPlaceholder')}
                        variant="dashboard"
                        value={formStates?.description?.[language?.value]}
                        setValue={(e) => {
                          setFormStates({
                            ...formStates,
                            description: {
                              ...formStates?.description,
                              [language?.value]: e
                            }
                          });
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <DropDown
                        label={t('form.eventsTypeLabel')}
                        options={eventTypes}
                        indicatorColor="var(--dashboard-text-gray-clr)"
                        placeholder={t('form.eventsTypePlaceholder')}
                        variant="dashboard"
                        optionValue={'_id'}
                        getOptionLabel={(e) => {
                          return e?.title[language?.value];
                        }}
                        value={formStates?.eventType}
                        setValue={(e) => {
                          setFormStates({ ...formStates, eventType: e });
                        }}
                      />
                    </Col>
                    {/* <Col md={6}>
                    <DropDown
                      label={'Invitaion Type*'}
                      indicatorColor="var(--dashboard-text-gray-clr)"
                      placeholder={'Select Invitaion type'}
                      variant="dashboard"
                      value={invitaionType}
                      setValue={setInvitaionType}
                    />
                  </Col> */}
                    <Col md={6}>
                      <Input
                        type={'date'}
                        label={t('form.eventsDateLabel')}
                        variant="dashboard"
                        value={formStates?.DateTime}
                        setValue={(e) => {
                          setFormStates({ ...formStates, DateTime: e });
                        }}
                        min={moment().format('YYYY-MM-DD')}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        type={'number'}
                        label={t('form.eventsPrice')}
                        placeholder={t('form.eventsPricePlaceholder')}
                        variant="dashboard"
                        value={formStates?.price}
                        setValue={(e) => {
                          setFormStates({ ...formStates, price: e });
                        }}
                        min={0}
                      />
                    </Col>

                    {/* Location */}

                    <LocationComponent
                      selectedLanguage={language}
                      t={languageD}
                      locationState={locationState}
                      setLocationState={setLocationState}
                    />

                    {/* 
                  <Col md={6}>
                    <DropDown
                      label={'Province*'}
                      options={eventTypes}
                      indicatorColor="var(--dashboard-text-gray-clr)"
                      placeholder={'Select Type'}
                      variant="dashboard"
                      optionLabel={'title'}
                      optionValue={'_id'}
                      value={eventType}
                      setValue={setEventType}
                    />
                  </Col>
                  <Col md={6}>
                    <DropDown
                      label={'Town*'}
                      options={eventTypes}
                      indicatorColor="var(--dashboard-text-gray-clr)"
                      placeholder={'Select Town'}
                      variant="dashboard"
                      optionLabel={'title'}
                      optionValue={'_id'}
                      value={eventType}
                      setValue={setEventType}
                    />
                  </Col>
                  <Col md={6}>
                    <DropDown
                      label={'Neighbourhood*'}
                      options={eventTypes}
                      indicatorColor="var(--dashboard-text-gray-clr)"
                      placeholder={'Select Neighbourhood'}
                      variant="dashboard"
                      optionLabel={'title'}
                      optionValue={'_id'}
                      value={eventType}
                      setValue={setEventType}
                    />
                  </Col>

                  <Col md={6}>
                    <MapMain
                      label={'Pin Location'}
                      placeholder={'Select Pin Location'}
                      variant="dashboard"
                      type="places"
                      setAddress={setLocation}
                      setCoordinates={setCoordinates}
                    />
                  </Col> */}

                    {/* media */}
                    <Col md={12}>
                      <h4>{t('form.eventsMediaText')}</h4>
                    </Col>

                    <Col md={12}>
                      <label className={classes.label}>{t('form.eventsImagesText')}</label>
                      <Row className="gy-3">
                        {uploadedImages
                          // ?.filter((e) => typeof e == 'string')
                          ?.map((item, index) => (
                            <Col md={3} key={index}>
                              <UploadImageBox
                                value={item}
                                edit={false}
                                onDelete={() => {
                                  deleteMedia({ slug, type: 'images', key: item });
                                }}
                              />
                            </Col>
                          ))}
                      </Row>
                    </Col>

                    <Col md={12}>
                      <FileUpload
                        orText={t('form.orText')}
                        text={t('form.dragAndDrop')}
                        buttonLabel={t('form.browseFiles')}
                        files={images}
                        setFiles={setImages}
                      />
                    </Col>

                    <Col md={12}>
                      <Input
                        label={t('form.eventsVideoLabel')}
                        placeholder={t('form.eventsVideoPlaceholder')}
                        variant="dashboard"
                        value={videoLink}
                        setValue={setVideoLink}
                      />
                    </Col>

                    {/* <Col md={12}>
                    <Input
                      label={'360 View Link'}
                      placeholder={'Add 360 View Link'}
                      variant="dashboard"
                      value={View360}
                      setValue={setView360}
                    />
                  </Col>

                  <Col md={12}>OR</Col>
                  <Col md={12}>
                    <FileUpload
                      text="Drag and drop 360 view Video here"
                      maxFiles={1}
                      buttonLabel="Upoload Video"
                      files={View360}
                      setFiles={setView360}
                    />
                  </Col> */}
                    <Col className="text-center">
                      <Button
                        disabled={loading}
                        onClick={handleSubmit}
                        label={
                          loading
                            ? t('form.loadingButton')
                            : singleEventData
                            ? t('form.updateButton')
                            : t('form.submitButton')
                        }
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </SideBarSkeleton>
  );
}

export default NewEvent;
