/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import classes from './HeaderCompareList.module.css';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import NoData from '../../NoData/NoData';
import { MdLocationOn, MdOutlineBedroomChild, MdPhotoLibrary } from 'react-icons/md';
import { AiFillInfoCircle, AiOutlineHome } from 'react-icons/ai';
import { TbBath, TbCalendarTime } from 'react-icons/tb';
import { DateTime, NoOfUnits, noti_img } from '../../../constant/imagePath';
import { useLazyGetApiQuery, usePostApiMutation } from '../../../store/apiSlice/apiSlice';
import Tabs from '../../Tabs';
import { imageUrl } from '../../../config/apiUrl';
import { BiBuildingHouse } from 'react-icons/bi';
import { ReactSVG } from 'react-svg';
import { updateUser } from '../../../store/auth/authSlice';
import { toast } from 'react-toastify';
import Button from '../../Button/Button';
// import { MdNotifications } from "react-icons/md";

const Notifications = ({ item, type }) => {
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  return (
    <div className={classes.detailWrap}>
      <div className={classes.mainImage}>
        <img src={imageUrl(item?.images?.[0])} alt="" />
      </div>
      <div className={classes.priceRow}>
        <div>
          <div className={classes.price}>
            <p>
              $ {item?.price} {item?.propertyType?.en == 'Rent' && <span>per month</span>}
            </p>
          </div>
          <div className={classes.desc}>
            <p>{item?.title?.[selectedLanguage]}</p>
          </div>

          <div className={classes.addressWrap}>
            <MdLocationOn color="#EF8B1D" />
            <p>{item?.address}</p>
          </div>
        </div>
        {type == 'property' ? (
          <>
            <div className={classes.propertyDetailWrap}>
              <div className={classes.propertyDetail}>
                <AiOutlineHome color="#000" />
                <p>{item?.[`${type}Type`]?.title?.[selectedLanguage]}</p>
              </div>
              <div className={classes.propertyDetail}>
                <MdOutlineBedroomChild color="#000" />
                <p>{item?.netArea} sq ft</p>
              </div>
            </div>
            <div style={{ marginBottom: 0 }} className={classes.propertyDetailWrap}>
              <div className={classes.propertyDetail}>
                <TbBath color="#000" />
                <p>{item?.noOfBathrooms} Bathrooms</p>
              </div>
              <div className={classes.propertyDetail}>
                <MdOutlineBedroomChild color="#000" />
                <p>{item?.noOfRooms?.[selectedLanguage]} Rooms</p>
              </div>
            </div>
          </>
        ) : type == 'project' ? (
          <>
            <div className={classes.propertyDetailWrap}>
              <div className={classes.propertyDetail}>
                <BiBuildingHouse color="#000" />
                <p>{item?.projectType?.title?.[selectedLanguage]}</p>
              </div>
              <div className={classes.propertyDetail}>
                <ReactSVG
                  fill="var(--text-dark-gray-clr)"
                  className={classes.svgIcon}
                  src={NoOfUnits}
                />
                <p>{item?.noOfUnits} Units</p>
              </div>
            </div>
            <div style={{ marginBottom: 0 }} className={classes.propertyDetailWrap}>
              <div className={classes.propertyDetail}>
                <ReactSVG
                  color="var(--text-dark-gray-clr)"
                  className={classes.svgIcon}
                  src={DateTime}
                />
                <p>{moment(item?.deliveryDate).format('ll')}</p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={classes.propertyDetailWrap}>
              <div className={classes.propertyDetail}>
                <AiFillInfoCircle color="var(--text-dark-gray-clr)" />
                <p>{item?.eventType?.title?.[selectedLanguage]}</p>
              </div>
              <div className={classes.propertyDetail}>
                <TbCalendarTime color="var(--text-dark-gray-clr)" />
                <p>{moment(item?.eventDate).format('ll')}</p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const HeaderCompareList = ({ t }) => {
  const notificationData = [
    {
      description: 'Full floor for sale I All unit already rented I One bedroom',
      postedBy: 'Anas',
      createdAt: moment()
    },
    {
      description: 'Full floor for sale I All unit already rented I One bedroom',
      postedBy: 'Anas',
      createdAt: moment()
    },
    {
      description: 'Full floor for sale I All unit already rented I One bedroom',
      postedBy: 'Anas',
      createdAt: moment()
    },
    {
      description: 'Full floor for sale I All unit already rented I One bedroom',
      postedBy: 'Anas',
      createdAt: moment()
    },
    {
      description: 'Full floor for sale I All unit already rented I One bedroom',
      postedBy: 'Anas',
      createdAt: moment()
    }
  ];
  const [postApiFunc] = usePostApiMutation();

  const dispatch = useDispatch();
  const [getApiFunc, { isLoading, isFetching, data: response }] = useLazyGetApiQuery();
  const tabsData = [
    {
      id: 1,
      text: `Properties`,
      icon: <MdPhotoLibrary />,
      path: '/compare-list',
      type: 'property'
    },
    {
      id: 2,
      text: `Events`,
      icon: <MdPhotoLibrary />,
      path: '/compare-list',
      type: 'event'
    },
    {
      id: 3,
      text: `Projects`,
      icon: <MdPhotoLibrary />,
      path: '/compare-list',
      type: 'project'
    }
  ];

  const [tabValue, setTabValue] = useState(tabsData[0]);
  const navigate = useNavigate();

  const getData = async (type = tabValue?.type) => {
    await getApiFunc({
      url: `users/compare?type=${type}`,
      tag: `compareList`
    });
  };
  const newArray5 = [1, 2, 3, 4, 5];

  // console.log(newArray5, 'response response');

  const delItem = async () => {
    if (response?.data?.length == 0) return;
    let arrayCheckbox = response?.data.map((item, key) => item?.entity?._id);

    const getResponse = await postApiFunc({
      url: `users/compare/remove`,
      method: 'POST',
      tag: `compareList`,

      data: {
        ids: arrayCheckbox,
        type: tabValue?.type
      }
    });
    if (getResponse?.data) {
      dispatch(updateUser(getResponse?.data?.data));
      toast.success(`${tabValue?.text} removed from compare list`);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className={[classes.HeaderCompareList]}>
      <div className={[classes.notificationHeader]}>
        <h6>{t('webHeader.comaparePopup.title')}</h6>
        <Button
          className={classes?.deleteBtn}
          onClick={delItem}
          disabled={response?.data?.length == 0}>
          {t('webHeader.comaparePopup.deleteAll')}
        </Button>
      </div>
      <Tabs
        className={classes.tab_main}
        tabClass={classes.tab_class_main}
        value={tabValue}
        setValue={(e) => {
          getData(e?.type);
          setTabValue(tabsData.find((item) => item.type == e.type));
        }}
        tabsData={tabsData}
      />
      <div className={classes.notificationSkeleton}>
        {isLoading || isFetching ? (
          newArray5?.map((index) => (
            <Skeleton key={index} height={'80px'} width={'100%'} variant={'rectangular'} />
          ))
        ) : response?.data?.length == 0 ? (
          <NoData text={t('webHeader.comaparePopup.noData')} className={classes.noData} />
        ) : (
          response?.data?.map((item, i) => {
            return (
              <div className={[classes.mb16]} key={i}>
                <Notifications item={item?.entity} type={tabValue?.type} />
              </div>
            );
          })
        )}
      </div>
      <p className={['p18', classes.viewAll].join(' ')} onClick={() => navigate('/compare-list')}>
        {t('webHeader.comaparePopup.seeAll')}
      </p>
    </div>
  );
};
export default HeaderCompareList;
