/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { BiBuildingHouse, BiTransfer } from 'react-icons/bi';
import { BsGrid3X3Gap } from 'react-icons/bs';
import {
  MdLocationPin,
  MdOutlineBathtub,
  MdOutlineHotel,
  MdOutlineStairs,
  MdWifiCalling3
} from 'react-icons/md';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
// import { GoMail } from 'react-icons/go';
import { IoIosMail, IoLogoWhatsapp } from 'react-icons/io';

import { useTranslation } from 'react-i18next';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isMobileViewHook } from '../../CustomHooks/isMobileViewHook';
import { restrictOnlyUserActions } from '../../Helper/restrictActions';
import { imageUrl } from '../../config/apiUrl';
import { useFavoriteEntityMutation, usePostApiMutation } from '../../store/apiSlice/apiSlice';
import { updateUser } from '../../store/auth/authSlice';
import { m2ToFt2, usdToOther } from '../../utils/helpers';
import IconList from '../IconList/IconList';
import Tooltip from '../Tooltip';
import classes from './PropertyHorizontalCard.module.css';

const PropertyHorizontalCard = ({
  data,
  index,
  className = '',
  onEmailClick = () => null,
  refetch
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('webCommon');
  const { isLogin, user } = useSelector((state) => state.authReducer);
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const { areaUnit, currency } = useSelector((state) => state.converterReducer);
  const navigate = useNavigate();
  const [isFavorite, setIsFavorite] = useState(data?.favoriteBy?.includes(user?._id));
  const [isMobile, setIsMobile] = useState(false);

  const [onFavorite, { isLoading: isFavoriting }] = useFavoriteEntityMutation();
  const [postApiFunc] = usePostApiMutation();

  const handleCompare = async (data) => {
    const onlyCompareProperty = user?.compareList?.filter((item) => item?.type == 'Property');
    const ifAlreadyExist = onlyCompareProperty?.find((item) => item?.entity == data?._id);
    if (!ifAlreadyExist && onlyCompareProperty?.length >= 3)
      return toast.error('You can only compare 3 properties at a time');

    const response = await postApiFunc({
      url: `users/compare/add`,
      method: 'POST',
      tag: `compareListFront`,
      data: {
        id: data?._id,
        type: 'property'
      }
    });
    if (response?.data) {
      dispatch(updateUser(response?.data?.data));
      toast.success(
        `Property ${ifAlreadyExist ? 'removed from' : 'added in'} compare list successfully`
      );
    }
  };

  const handleFavorite = async () => {
    if (isFavoriting) return;
    const res = await onFavorite({ id: data?._id, type: 'property' });
    if (res?.data) {
      if (res?.data?.data?.favoriteBy?.includes(user?._id)) {
        toast.success(`${'Property added in favorite list'} successfully`);
      } else {
        toast.success(`${'Property removed from favorite list'} successfully`);
      }
      setIsFavorite(res?.data?.data?.favoriteBy?.includes(user?._id));
    }
    refetch && refetch();
  };

  const responsiveSettings = [
    { breakpoint: isMobile ? 700 : 465, settings: { slidesToShow: 2, slidesToScroll: 2 } },
    { breakpoint: 200, settings: { slidesToShow: 1, slidesToScroll: 1 } }
  ];
  useEffect(() => {
    isMobileViewHook(setIsMobile, 992);
  }, []);

  const handleAction = () => {
    const isAllowedActions = restrictOnlyUserActions(user?.role);
    if (!isLogin) {
      navigate('/login');
    } else if (isAllowedActions) {
      return true;
    }
  };

  return (
    <>
      <style>
        {`
      .${classes.sliderContainer} .react-slideshow-container .default-nav{
        background: transparent;
        color:#FFFFFF !important;
        transition: 0.5s ease;
        opacity:0;
      }
      .${classes.sliderContainer} .react-slideshow-container .default-nav svg{
        fill:#FFFFFF !important;
      }
      .${classes.sliderContainer} .react-slideshow-container .default-nav:hover{
        background: transparent;
      }

      .${classes.sliderContainer}:hover .react-slideshow-container .default-nav{
        opacity:1;
      }
      `}
      </style>

      <div className={[classes?.propertyMain, className].join(' ')}>
        {/* Slider */}
        <div className={classes?.sliderContainer}>
          <Slide responsive={responsiveSettings}>
            {data?.images?.map((image, i) => (
              <div className={classes?.propertyImageContainer} key={i}>
                <img
                  src={image?.includes('https://') ? image : imageUrl(image)}
                  draggable={false}
                />
              </div>
            ))}
          </Slide>
        </div>

        <div
          className={classes?.rightContainer}
          onClick={() => {
            navigate(`/property/${data?.slug ?? '1'}`);
          }}>
          <div>
            {/* Price + Logo */}
            <div className={classes?.topContainer}>
              <div className={classes?.priceContainer}>
                <span>{usdToOther({ amount: data?.price, ...currency })}</span>
                {data?.propertyPurpose?.en == 'Rent' && <span>{t('propertyCard.perMonth')}</span>}
              </div>
              <div
                className={classes?.logoContainer}
                onClick={(e) => {
                  e?.stopPropagation();
                  navigate(`/company/${data?.company?.slug}`);
                }}>
                <img src={imageUrl(data?.company?.photo)} />
              </div>
            </div>

            {/* Title + Location */}
            <div className={classes?.titleContainer}>
              <h6 className={classes.tagline}>{data?.title?.[selectedLanguage]}</h6>
              <div className={classes?.location}>
                <MdLocationPin fill="var(--text-dark-gray-clr)" />
                <span>{data?.address}</span>
              </div>
            </div>

            {/* description */}
            {/* <p className={classes?.description}>{data?.description}</p> */}

            {/* Property Info */}
            <div className={classes?.propertyInfoContainer}>
              <IconList
                className={classes.infoIcons}
                Icon={BiBuildingHouse}
                value={data?.propertyType?.title?.[selectedLanguage]}
                label={t('propertyCard.tooltips.propertyType')}
              />
              <IconList
                className={[classes.infoIconsCount, ''].join(' ')}
                Icon={BsGrid3X3Gap}
                value={`${m2ToFt2({ area: data?.netArea, areaUnit, unit: false })}`}
                label={t('propertyCard.tooltips.area') + ` (${areaUnit})`}
              />
              <IconList
                className={classes.infoIconsCount}
                Icon={MdOutlineHotel}
                value={`${data?.noOfRooms?.[selectedLanguage]}`}
                label={t('propertyCard.tooltips.rooms')}
              />
              <IconList
                className={classes.infoIconsCount}
                Icon={MdOutlineBathtub}
                value={`${data?.noOfBathrooms}`}
                label={t('propertyCard.tooltips.bathrooms')}
              />
              <IconList
                className={classes.infoIconsCount}
                Icon={MdOutlineStairs}
                value={`${data?.floorLevel?.[selectedLanguage]}`}
                label={t('propertyCard.tooltips.level')}
              />
            </div>
          </div>
          {/* bottom strip */}
          <div className={classes?.bottomStrip}>
            {/* <div className={classes?.iconsContainer}>
            <div>
              <MdWifiCalling3 fill="#E2831B" size={30} />
            </div>
            <div>
              <IoIosMail fill="#E2831B" size={30} />
            </div>
            <div>
              <IoLogoWhatsapp fill="#E2831B" size={30} />
            </div>
          </div> */}
            <div className={classes.socialIconsWrap1}>
              <a
                href={`tel:${data?.phoneNumber}`}
                className={classes.socialIcons1}
                onClick={(e) => {
                  e?.stopPropagation();
                }}>
                <Tooltip text={t('propertyCard.tooltips.call')}>
                  <MdWifiCalling3 fill="#E2831B" className={classes.iconsBottom} />
                </Tooltip>
              </a>
              <a
                className={[classes.socialIcons1, classes.midIcon].join(' ')}
                onClick={(e) => {
                  e?.stopPropagation();
                  onEmailClick && onEmailClick();
                }}>
                <Tooltip text={t('propertyCard.tooltips.email')}>
                  <IoIosMail fill="#E2831B" className={classes.iconsBottom} />
                </Tooltip>
              </a>
              <a
                href={`https://wa.me/${data?.whatsapp}`}
                className={classes.socialIcons1}
                target="_blank"
                onClick={(e) => {
                  e?.stopPropagation();
                }}>
                <Tooltip text={t('propertyCard.tooltips.whatsapp')}>
                  <IoLogoWhatsapp fill="#E2831B" className={classes.iconsBottom} />
                </Tooltip>
              </a>
            </div>

            {/* right */}
            <div className={classes.iconWrap}>
              <Tooltip text={t('propertyCard.tooltips.compare')}>
                <BiTransfer
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!handleAction()) return;
                    handleCompare(data);
                  }}
                  className={classes.iconsBottom}
                  // size={30}
                  fill={
                    user?.compareList?.find((e) => e?.entity == data?._id)
                      ? 'var(--action-icon-clr)'
                      : 'var(--text-light-gray-clr)'
                  }
                />
              </Tooltip>
              <Tooltip
                text={
                  isFavorite
                    ? t('propertyCard.tooltips.removeFavorite')
                    : t('propertyCard.tooltips.addFavorite')
                }>
                {isFavorite ? (
                  <AiFillHeart
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFavorite();
                    }}
                    fill="var(--action-icon-clr)"
                    className={classes.iconsBottom}
                    // size={30}
                  />
                ) : (
                  <AiOutlineHeart
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!handleAction()) return;
                      handleFavorite();
                    }}
                    fill="var(--text-light-gray-clr)"
                    className={classes.iconsBottom}
                    // size={30}
                  />
                )}
              </Tooltip>
            </div>

            {/* <div className={classes?.bottomStripRight}>
            <div onClick={() => setIsComparable((p) => !p)}>
              <BsArrowLeftRight fill={isComparable ? 'var(--primary-clr)' : 'gray'} size={28} />
            </div>
            <div onClick={() => setIsFavourite((p) => !p)}>
              <AiOutlineHeart fill={isFavourite ? 'var(--primary-clr)' : 'gray'} size={28} />
            </div>
          </div> */}
          </div>
        </div>

        {(data?.isPremium || data?.isFeatured) && (
          <div className={classes?.featuredPremiumContainer}>
            <span
              className={`${
                (data?.isFeatured && classes?.featuredIco) ||
                (data?.isPremium && classes?.premiumIco)
              }`}>
              {(data?.isPremium && t('propertyCard.premium')) ||
                (data?.isFeatured && t('propertyCard.featured'))}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default PropertyHorizontalCard;
