/*eslint-disable*/
import { createApi } from '@reduxjs/toolkit/query/react';
import { apiHeader, BaseURL, capitalizeFirstLetter, recordsLimit } from '../../config/apiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';

const axiosBaseQuery = async ({
  baseUrl,
  accessToken,
  url,
  method = 'GET',
  data,
  showToast = true
}) => {

  try {
    const customHeaders = apiHeader(accessToken);
    return await axios({
      url: baseUrl + url,
      method,
      data,
      ...customHeaders,
      
    });
  } catch (axiosError) {
    let errorMessage = axiosError?.response?.data?.message;
    let errorObj;
    if (showToast) {
      if (axiosError?.message === 'Network Error') {
        toast.error(`${axiosError?.message} : Please Check Your Network Connection`, {
          position: toast.POSITION.TOP_CENTER
        });
        errorObj = {
          status: 403,
          data: 'Newtwork Error'
        };
      } else {
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER
        });
        errorObj = {
          status: axiosError.response?.data?.status || axiosError?.code,
          data: errorMessage ?? axiosError?.message
        };
      }
    }

    return {
      error: errorObj
    };
  }
};

export const apiSlice = createApi({
  reducerPath: 'apiSlice',

  baseQuery: async (e, { getState }) =>
    await axiosBaseQuery({
      baseUrl: BaseURL(''),
      accessToken: getState()?.authReducer?.accessToken,
      ...e
    }),

  // baseQuery: fetchBaseQuery({
  //   baseUrl: BaseURL(""),
  //   prepareHeaders: (headers, { getState }) => {
  //     const token = getState()?.authReducer?.access_token;
  //     if (token) {
  //       headers.set("authorization", `Bearer ${token}`);
  //     }
  //     return headers;
  //   },
  // }),
  // refetchOnFocus: true,
  // refetchOnReconnect: true,
  // refetchOnMountOrArgChange: true,
  // keepUnusedDataFor: 2,
  tagTypes: [
    'Company',
    'Event',
    'PropertyRequests',
    'InquiryRequests',
    'SavedSearches',
    'Property',
    'CompanyAgents',
    'Agent',
    'myFollowing',
    'SavedPropertiesEventsProjects',
    'Bank',
    'TermsAndConditions',
    'PrivacyPolicy',
    'Blogs',
    'ContactUs'
  ],
  endpoints: (builder) => {
    return {
      // Register Normal User
      registerCustomer: builder.mutation({
        query: (params) => ({
          url: `users/signup`,
          method: 'POST',
          data: params
        })
      }),

      // Login API
      login: builder.mutation({
        query: (params) => ({
          url: `users/login`,
          method: 'POST',
          data: params
        })
      }),

      //   get agent dashboard stats
      getAgentDashboard: builder.query({
        query: () => ({
          url: `users/agentDashboard`
        }),
        keepUnusedDataFor: 0
      }),
      //   get company dashboard stats
      getCompanyDashboard: builder.query({
        query: () => ({
          url: `users/companyDashboard`
        }),
        keepUnusedDataFor: 0
      }),
      getCompanyAgents: builder.query({
        query: () => ({
          url: `users/getAllAgents`,
          showToast: false
        }),
        providesTags: ['CompanyAgents']
      }),
      // Assign Points to Agent By Company
      assignPointsOnAgent: builder.mutation({
        query: ({ slug, data }) => ({
          url: `users/creditAgent/${slug}`,
          data: data,
          method: 'PATCH'
        }),
        invalidatesTags: ['CompanyAgents']
      }),
      // Company Update Profile
      updateProfile: builder.mutation({
        query: (data) => {
          return {
            url: `users/updateMe`,
            method: 'PATCH',
            data
          };
        }
      }),
      updatePassword: builder.mutation({
        query: (data) => {
          return {
            url: `users/updateMyPassword`,
            method: 'PATCH',
            data
          };
        }
      }),
      postApi: builder.mutation({
        query: ({ url, method, data }) => {
          return {
            url: url,
            method: method,
            data
          };
        },
        invalidatesTags: (result, error, arg) => {
          return [{ type: arg?.tag }];
        }
      }),

      getApi: builder.query({
        query: ({ url }) => ({
          url: url
        }),
        keepUnusedDataFor: 0,
        providesTags: (result, error, arg) => {
          // console.log(result, 'resultresultresu');
          return Array.isArray(result?.data)
            ? result?.data?.map((item) => ({ id: item?._id, type: arg?.tag }))
            : [{ id: result?.data?._id, type: arg?.arg }];
        }
      }),

      // *******************************************************Shaam*******************************************************

      // get single event
      getSingleEvent: builder.query({
        query: ({ slug }) => ({
          url: `event/${slug}`
        }),
        providesTags: ['Event']
      }),

      // get all events
      getMyEvents: builder.query({
        query: ({ page, search = '', sortBy = '-1', limit = recordsLimit, lan='' }) => ({
          url: `event/getMyEvents?page=${page}&limit=${limit}&search=${search}&sortBy=${sortBy}&lan=${lan}`
        }),
        providesTags: ['Event']
      }),
      // active/deactive event
      activeDeactiveEvent: builder.mutation({
        query: ({ slug, status }) => ({
          url: `event/active-deactivate/${slug}`,
          method: 'PATCH',
          data: { status }
        }),
        invalidatesTags: ['Event']
      }),
      // add or edit event
      addOrEditEvent: builder.mutation({
        query: ({ slug, data }) => {
          return {
            url: slug ? `event/${slug}` : `event/create`,
            method: slug ? 'PATCH' : 'POST',
            data
          };
        },
        invalidatesTags: ['Event']
      }),
      // delete events
      deleteEvent: builder.mutation({
        query: ({ ids }) => ({
          url: `event/deleteEvents`,
          method: 'DELETE',
          data: { ids }
        }),
        invalidatesTags: ['Event']
      }),
      deleteEventMedia: builder.mutation({
        query: ({ slug, key, type }) => ({
          url: `event/delete-media/${slug}`,
          data: { key, type },
          method: 'PATCH'
        }),
        invalidatesTags: ['Event']
      }),
      // *******************************************************Bila lal*******************************************************
      //  post request
      deleteUnit: builder.mutation({
        query: ({ slug, unitId }) => ({
          url: `project/deleteProjectUnit/${slug}`,
          method: 'DELETE',
          data: { unitId: unitId }
        }),
        invalidatesTags: ['Units']
      }),
      addOrEditUnit: builder.mutation({
        query: ({ slug, data, url = 'project/addProjectUnit/' }) => {
          // console.log('slug', slug, 'url', url, '${url}${slug}', `${url}${slug}`);
          return {
            url: url,
            method: 'PATCH',
            data
          };
        },
        invalidatesTags: ['Units']
      }),

      getProjectUnits: builder.query({
        query: ({ slug }) => ({
          url: `project/projectUnitAll/${slug}`
        }),
        providesTags: ['Units']
      }),

      postRequest: builder.mutation({
        query: ({ data, token }) => ({
          url: token
            ? 'property-request/createPropertyRequestUser'
            : `property-request/createPropertyRequest`,
          method: 'POST',
          data
        })
      }),

      //  get company projects
      getCompanyProjects: builder.query({
        query: ({ page, search = '', limit = recordsLimit, sortBy, lan='' }) => ({
          url: `project/getMyProjects?page=${page}&limit=${limit}&search=${search}&sortBy=${sortBy}&lan=${lan}`
        }),
        providesTags: ['Project']
      }),
      // get single project
      getSingleProject: builder.query({
        query: ({ slug }) => ({
          url: `project/detail/${slug}`
        }),
        providesTags: ['Project']
      }),
      // add or edit project
      addOrEditProject: builder.mutation({
        query: ({ slug, data }) => {
          return {
            url: slug ? `project/updateProject/${slug}` : `project/createProject`,
            method: slug ? 'PATCH' : 'POST',
            data
          };
        },
        invalidatesTags: ['Project']
      }),
      // delete project

      deleteProject: builder.mutation({
        query: ({ ids }) => ({
          url: `project/deleteProjects`,
          method: 'DELETE',
          data: { ids }
        }),
        invalidatesTags: ['Project']
      }),
      // active/deactive project
      activeDeactiveProject: builder.mutation({
        query: ({ slug, data }) => ({
          url: `project/activeDeactivateProject/${slug}`,
          method: 'PATCH',
          data: { status: data }
        }),
        invalidatesTags: ['Project']
      }),
      // refresh project PATCH
      refreshProject: builder.mutation({
        query: ({ slug }) => ({
          url: `project/refreshProject/${slug}`,
          method: 'PATCH'
        }),
        invalidatesTags: ['Project']
      }),

      // refresh event PATCH
      refreshEvent: builder.mutation({
        query: ({ slug }) => ({
          url: `event/refreshEvent/${slug}`,
          method: 'PATCH'
        }),
        invalidatesTags: ['Event']
      }),

      getAllAgent: builder.query({
        query: ({ page, limit = recordsLimit, search, lan='' }) => ({
          url: `users/getAllAgents?page=${page}&limit=${limit}&search=${search}&lan=${lan}`
        }),
        providesTags: ['Agent']
      }),

      // delete agents
      deleteAgent: builder.mutation({
        query: ({ ids }) => {
          // console.log(ids, '123 321');

          return {
            url: `users/deleteAgents`,
            method: 'DELETE',
            data: { ids }
          };
        },
        invalidatesTags: ['Agent']
      }),

      getAllEventType: builder.query({
        query: () => ({
          url: 'event-type/all',
          showToast: false
        }),

        providesTags: ['event-types']
      }),

      // get property type
      getAllPropertyType: builder.query({
        query: () => ({
          url: 'property-type/all',
          showToast: false

        }),
        providesTags: ['property-types']
      }),

      // get project type
      getAllProjectType: builder.query({
        query: () => ({
          url: 'project-type/all',
          showToast: false
        }),
        providesTags: ['project-types']
      }),

      // get amenities
      getAllAmenities: builder.query({
        query: (type) => ({
          url: `amenities/all?type=${type}`,
          showToast: false

        }),
        providesTags: ['amenities']
      }),

      getAgent: builder.query({
        query: ({ slug }) => ({
          url: `users/agent/${slug}`
        })
        // providesTags: ['Agent']
      }),

      //
      addOrEditAgent: builder.mutation({
        query: ({ slug, data }) => ({
          url: slug ? `users/updateAgent/${slug}` : `users/createAgent`,
          method: slug ? 'PATCH' : 'POST',
          data
        }),
        invalidatesTags: ['Agent']
      }),

      // post and patch banner
      addOrEditBanner: builder.mutation({
        query: ({ _id, data }) => ({
          url: _id ? `banner/${_id}` : `banner/create`,
          method: _id ? 'PATCH' : 'POST',
          data
        }),
        invalidatesTags: ['Banner']
      }),

      // get all banner
      getAllBanner: builder.query({
        query: () => {
          return {
            url: `banner/all`,
            showToast: false
          };
        },
        providesTags: ['Banner']
      }),

      // *******************************************************Ramiz*******************************************************
      getAllCompanies: builder.query({
        query: ({ page, limit, search, sortBy }) => ({
          url: `admin/getAllCompanies?page=${page}&limit=${limit}&sortBy=${sortBy}&search=${search}`
        }),
        providesTags: ['Company']
      }),

      // Properties
      getAllProperties: builder.query({
        query: ({ page, limit, search, sortBy, lan }) => ({
          url: `property/getMyProperties?page=${page}&limit=${limit}&sortBy=${sortBy}&search=${search}&lan=${lan}`
        }),
        providesTags: (result) => {
          return result?.data?.length == 0
            ? ['Property']
            : result?.data?.map((e) => ({ type: 'Property', id: e?.slug }));
        }

        // ['Property'],
      }),
      refreshProperty: builder.mutation({
        query: ({ slug }) => ({
          url: `property/refreshProperty/${slug}`,
          data: {},
          method: 'PATCH'
        }),
        invalidatesTags: ['Property']
      }),
      assignAgentOnProperty: builder.mutation({
        query: ({ slug, agentSlug, type = 'property' }) => ({
          url: `${type}/assign-to-agent/${slug}`,
          data: { agentSlug },
          method: 'PATCH'
        }),
        invalidatesTags: (result, error, arg) => [{ type: capitalizeFirstLetter(arg.type) }]
      }),
      UpgradeToPremiumOrFeatured: builder.mutation({
        query: ({ slug, data, type = 'property' }) => ({
          url: `${type}/feature-premium/${slug}`,
          data,
          method: 'PATCH'
        }),
        invalidatesTags: (result, error, arg) => [{ type: capitalizeFirstLetter(arg.type) }]
      }),
      getSingleProperty: builder.query({
        query: ({ slug }) => ({
          url: `property/propertyDetails/${slug}`
        }),
        providesTags: (result, error, arg) => {
          return [{ type: 'Property', id: arg?.slug }];
        }
      }),
      deleteProperties: builder.mutation({
        query: (ids) => ({
          url: `property/deleteProperties`,
          data: { ids },
          method: 'DELETE'
        }),
        invalidatesTags: ['Property']
      }),
      deletePropertyMedia: builder.mutation({
        query: ({ slug, key, type, urlType = 'property' }) => ({
          url: `${urlType}/delete-media/${slug}`,
          data: { key, type },
          method: 'PATCH'
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Property', id: arg?.slug }]
      }),
      activeDeactiveProperty: builder.mutation({
        query: ({ slug, status }) => ({
          url: `property/activeDeactivateProperty/${slug}`,
          data: { status },
          method: 'PATCH'
        }),
        invalidatesTags: ['Property']
      }),
      createProperty: builder.mutation({
        query: ({ data, slug }) => ({
          url: slug ? `property/${slug}` : `property/create`,
          data: data,
          method: slug ? 'PATCH' : 'POST'
        }),
        invalidatesTags: (result) => {
          return [{ type: 'Property', id: result?.slug }];
        }
      }),
      // Properties

      // Propery Requests
      // for Web
      createPropertyRequest: builder.mutation({
        query: ({ data }) => ({
          url: 'property-request/createPropertyRequest',
          data: data,
          method: 'POST'
        })
      }),
      getAllPropertyRequests: builder.query({
        query: ({ page, limit, search = '' }) => ({
          url: `property-request/getAllPropertyRequests?page=${page}&limit=${limit}&search=${search}`
        }),
        providesTags: ['PropertyRequests']
      }),
      deletePropertyRequests: builder.mutation({
        query: ({ ids }) => ({
          url: `property-request/deletePropertyRequests`,
          data: { ids },
          method: 'DELETE'
        }),
        invalidatesTags: ['PropertyRequests']
      }),

      // Propery Requests (Pending)
      // Get My Follwers (For Company, Agent)
      getMyFollowers: builder.query({
        query: ({ page, limit, search, sortBy }) => ({
          url: `users/getMyFollowers?page=${page}&limit=${limit}&search=${search}&sortBy=${sortBy}`
        })
      }),
      // Get My Follwers

      // *******************************************************Public APIS WEB*******************************************************
      getPublicPropertiesAndProjects: builder.mutation({
        query: ({ data = {} }) => ({
          url: `users/random-property-and-projects`,
          data: data,
          method: 'POST',
        })
      }),
      getPublicProperties: builder.mutation({
        query: ({ page, limit, data }) => ({
          url: `property/all?page=${page}&limit=${limit}`,
          data: data,
          method: 'POST'
        })
      }),
      getPublicOkPropertyDetail: builder.query({
        query: ({ slug }) => ({
          url: `property/detail/${slug}`
        })
      }),

      // Public Projects
      getPublicProjects: builder.mutation({
        query: ({ page, limit, data }) => ({
          url: `project/all?page=${page}&limit=${limit}`,
          data: data,
          method: 'POST'
        })
      }),
      getPublicProjectsDetail: builder.query({
        query: ({ slug }) => ({
          url: `project/detail/${slug}`
        })
      }),
      // Public Projects

      // Public Events
      getPublicEvents: builder.mutation({
        query: ({ page, limit, data }) => ({
          url: `event/all?page=${page}&limit=${limit}`,
          data: data,
          method: 'POST'
        })
      }),
      getPublicEventsDetail: builder.query({
        query: ({ slug }) => ({
          url: `event/detail/${slug}`
        })
      }),
      // Public Events

      // Location
      getAllProvinces: builder.query({
        query: (query) => {
          const searchParams = new URLSearchParams(query)?.toString();
          return {
            url: `location?type=province${query ? '&' + searchParams : ''}`
          };
        }
      }),
      getAllTowns: builder.query({
        query: (query) => {
          const searchParams = new URLSearchParams(query)?.toString();
          return {
            url: `location?type=town${query ? '&' + searchParams : ''}`
          };
        }
      }),
      getAllNeighborhoods: builder.query({
        query: (query) => {
          const searchParams = new URLSearchParams(query)?.toString();
          return {
            url: `location?type=neighborhood${query ? '&' + searchParams : ''}`
          };
        }
      }),
      getAllPublicCompanyOrAgents: builder.mutation({
        query: ({ page, data, limit = recordsLimit }) => {
          return {
            url: `users/companies-or-agents?page=${page}&limit=${limit}`,
            method: 'POST',
            data: data
          };
        },
        providesTags: (result, error, arg) => [{ type: capitalizeFirstLetter(arg.type) }]
      }),
      getPublicCompanyDetail: builder.query({
        query: ({ slug }) => {
          return {
            url: `users/getCompany/${slug}/`
          };
        }
      }),
      getPublicCompanyEntities: builder.mutation({
        query: ({ slug, data, ...params }) => {
          const searchParams = new URLSearchParams(params)?.toString();
          return {
            url: `users/getCompanyEntities/${slug}${params ? '?' + searchParams : ''}`,
            method: 'POST',
            data: data
          };
        }
      }),

      getPublicAgentDetail: builder.query({
        query: ({ slug }) => {
          return {
            url: `users/getAgent/${slug}/`
          };
        }
      }),
      getPublicAgentEntities: builder.mutation({
        query: ({ slug, data, ...params }) => {
          const searchParams = new URLSearchParams(params)?.toString();
          return {
            url: `users/getAgentEntities/${slug}${params ? '?' + searchParams : ''}`,
            method: 'POST',
            data: data
          };
        }
      }),
      // Location
      // *******************************************************Public APIS WEB*******************************************************

      // Normal User
      followUnFollowCompanyOrAgent: builder.mutation({
        query: (id) => ({
          url: `users/followUnfollow`,
          method: 'PATCH',
          data: { follow: id }
        })
      }),
      favoriteEntity: builder.mutation({
        query: ({ id, type }) => ({
          url: `users/addRemoveFavorites`,
          method: 'PATCH',
          data: { entity: id, type }
        })
      }),

      deleteSavedPropertyEventsProjects: builder.mutation({
        query: (data) => ({
          url: `users/deleteFavorites`,
          data,
          method: 'DELETE'
        }),
        invalidatesTags: ['SavedPropertiesEventsProjects']
      }),

      // Inquiry
      getAllInquiryRequests: builder.query({
        query: ({ page, limit }) => ({
          url: `inquiry?page=${page}&limit=${limit}`
        }),
        providesTags: ['InquiryRequests']
      }),
      // Inquiry
      // Save Searches
      getAllSavedSearches: builder.query({
        query: ({ page, limit }) => ({
          url: `savedSearch?page=${page}&limit=${limit}`
        }),
        providesTags: ['SavedSearches']
      })
      // Save Searches
      // Normal User
    };
  }
});

export const {
  // Register Normal User
  useRegisterCustomerMutation,
  // Mutation Sample
  useLoginMutation,
  // *******************************************************Shaam*******************************************************
  // Mutation
  useActiveDeactiveEventMutation,
  useAddOrEditEventMutation,
  useDeleteEventMutation,
  useDeleteEventMediaMutation,
  useRefreshEventMutation,

  // Get
  useGetMyEventsQuery,

  // *******************************************************Bila lal*******************************************************
  // Mutation
  useAddOrEditAgentMutation,
  useDeleteUnitMutation,
  useDeleteAgentMutation,
  usePostRequestMutation,
  useGetProjectUnitsQuery,
  useAddOrEditUnitMutation,

  useRefreshProjectMutation,
  useDeleteProjectMutation,
  useActiveDeactiveProjectMutation,
  useAddOrEditProjectMutation,
  usePostApiMutation,

  // Get
  // projects Get for Company
  useGetSingleProjectQuery,
  useLazyGetApiQuery,
  useGetCompanyProjectsQuery,
  // projects Get for Company

  useLazyGetAllPropertyTypeQuery,
  useLazyGetAllProjectTypeQuery,
  useLazyGetAllAmenitiesQuery,
  useLazyGetAllEventTypeQuery,
  useLazyGetCompanyAgentsQuery,

  useGetSingleEventQuery,
  useGetAllAgentQuery,
  useGetAgentQuery,
  useGetAllBannerQuery,
  useLazyGetAllBannerQuery,
  useAddOrEditBannerMutation,

  // *******************************************************Ramiz*******************************************************
  // Mutation
  // Get
  useGetAllCompaniesQuery,
  // Properties
  useGetAllPropertiesQuery,
  useDeletePropertiesMutation,
  useActiveDeactivePropertyMutation,
  useCreatePropertyMutation,
  useLazyGetSinglePropertyQuery,
  useDeletePropertyMediaMutation,
  useRefreshPropertyMutation,
  useUpgradeToPremiumOrFeaturedMutation,
  useAssignAgentOnPropertyMutation,
  // Properties

  useGetCompanyDashboardQuery,
  // Followers
  useGetMyFollowersQuery,
  // Followers
  // Propery Requests
  useGetAllPropertyRequestsQuery,
  useDeletePropertyRequestsMutation,
  useCreatePropertyRequestMutation,
  // Propery Requests

  // Company Update Profile
  useUpdateProfileMutation,
  useUpdatePasswordMutation,

  // *******************************************************Public APIS WEB*******************************************************
  useGetPublicPropertiesMutation,
  useGetAllProvincesQuery,
  useGetAllTownsQuery,
  useGetAllNeighborhoodsQuery,
  useGetPublicOkPropertyDetailQuery,

  // Public Projects
  useGetPublicProjectsMutation,
  useGetPublicProjectsDetailQuery,
  // Public Projects
  // Public Events
  useGetPublicEventsMutation,
  useGetPublicEventsDetailQuery,
  // Public Events

  useAssignPointsOnAgentMutation,

  useGetAllPublicCompanyOrAgentsMutation,
  useGetPublicCompanyDetailQuery,
  useGetPublicCompanyEntitiesMutation,

  useGetPublicAgentDetailQuery,
  useGetPublicAgentEntitiesMutation,

  // Home Page API
  useGetPublicPropertiesAndProjectsMutation,

  // Agent Dashboard APIs
  useGetAgentDashboardQuery,
  // Agent Dashboard APIs

  // Normal User
  useFollowUnFollowCompanyOrAgentMutation,
  useFavoriteEntityMutation,
  useDeleteSavedPropertyEventsProjectsMutation,
  useGetAllInquiryRequestsQuery,
  useGetAllSavedSearchesQuery
  // Normal User

  // *******************************************************Public APIS WEB*******************************************************
} = apiSlice;
