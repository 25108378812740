/*eslint-disable*/
import React, { useState } from 'react';
import classes from './ProjectUnit.module.css';
import SideBarSkeleton from '../../../Component/SideBarSkeleton';
import { Col, Container, Row } from 'react-bootstrap';
import CustomMaterialTable from '../../../Component/CustomMaterialTable';
import { AiFillEye } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';
import { MdOutlineUpload } from 'react-icons/md';
import Button from '../../../Component/Button/Button';
import SearchInput from '../../../Component/SearchInput';
import { useNavigate, useParams } from 'react-router-dom';
import { FaToggleOn } from 'react-icons/fa';
import { dateFilterOptions } from '../../../constant/commonData';
import DropDown from '../../../Component/DropDown/DropDown';
import {
  useAddOrEditUnitMutation,
  useDeleteUnitMutation,
  useGetProjectUnitsQuery
} from '../../../store/apiSlice/apiSlice';
import moment from 'moment';
import { toast } from 'react-toastify';
import AreYouSureModal from '../../../modals/AreYouSureModal';
import AssignAgentModal from '../../../modals/AssignAgentModal';
import UpgradingListingModal from '../../../modals/UpgradingListingModal/UpgradingListingModal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ImUserCheck } from 'react-icons/im';
import { HiRefresh } from 'react-icons/hi';
import { recordsLimit } from '../../../config/apiUrl';
import useDebounce from '../../../CustomHooks/useDebounce';
import UnitAddModal from '../../../Component/UnitAddModal';

const tableData = Array(10)
  .fill({
    id: '5645',
    date: '2020.11.12',
    category: 'For Sale',
    type: 'broker',
    title: 'Text text',
    location: 'London',
    status: 'Active',
    agent: 'John Smith'
  })
  ?.map((item) => ({ ...item, id: Math.floor(Math.random() * 10000) }));

function ProjectUnit() {
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [dateFilter, setDateFilter] = useState(dateFilterOptions[0]);
  const [isUnitAddModal, setIsUnitAddModal] = useState(null);
  const [page, setPage] = useState(1);

  const { t } = useTranslation('dashboardProjects', { lng: selectedLanguage });

  const [addUnitApi, { isLoading: isAddUnitLoading }] = useAddOrEditUnitMutation();

  const [deleteRecordsApi, { isLoading: isDeleting }] = useDeleteUnitMutation();
  //  get company projects
  const debounceSearch = useDebounce(searchText, 500);

  const { data: projectsGetApi, isLoading } = useGetProjectUnitsQuery({
    slug: id,
    page: page,
    sortBy: dateFilter?.value
  });
  console.log(projectsGetApi, 'projectsGetApi projectsGetApi projectsGetApi');
  //  delete api
  const deleteMultiple = async () => {
    const res = await deleteRecordsApi({ unitId: selectedRows?.map((item) => item._id), slug: id });
    if (res?.data) {
      toast.success(`${selectedRows?.length > 1 ? 'Events' : 'Event'} deleted successfully.`);
      setSelectedRows([]);
    }
  };

  const headings = [
    { id: 'carParking', label: t('unitsTable.col1') },
    {
      id: 'noOfRooms',
      label: t('unitsTable.col2'),
      renderValue: (item) => {
        return item?.noOfRooms?.[selectedLanguage];
      }
    },
    { id: 'noOfBathrooms', label: t('unitsTable.col4') },
    {
      id: 'date',
      label: t('unitsTable.col4'),
      renderValue: (item, col) => {
        return moment(item[col?.id]).format('YYYY.DD.MM ');
      }
    },

    { id: 'options', label: t('unitsTable.col5'), align: 'center' }
  ];

  return (
    <SideBarSkeleton>
      <Container fluid className={classes.page}>
        <Row>
          <Col md={12} className={classes.mb58}>
            <div className={classes.header}>
              <div className={classes.left}>
                {/* <DropDown
                  label={t('table.sortByDate')}
                  containerClassName={classes.dropDownContainer}
                  customStyle={{ padding: '0px 6px', minHeight: '40px', minWidth: '200px' }}
                  options={dateFilterOptions}
                  setValue={(e) => {
                    setDateFilter(e);
                  }}
                  value={dateFilter}
                  getOptionLabel={(e) => {
                    return e?.[selectedLanguage];
                  }}
                  // isClearable={true}
                /> */}
              </div>
              <div className={classes.headerRight}>
                <Button
                  borderRadius={'8px'}
                  className={classes.headerBtn}
                  onClick={() => setIsUnitAddModal({ slug: id, boolean: true })}>
                  {t('unitsTable.createBtn')}
                </Button>
                <Button
                  borderRadius={'8px'}
                  className={classes.deleteBtn}
                  disabled={selectedRows?.length == 0}
                  onClick={deleteMultiple}>
                  {isDeleting ? t('unitsTable.delBtnLoading') : t('unitsTable.delBtn')}
                </Button>
                {/* <Button borderRadius={'8px'}>Create New Property</Button> */}
              </div>
            </div>
          </Col>
          <Col md={12}>
            <h5 className={classes.heading}>{'Units'}</h5>
          </Col>
          <Col md={12}>
            <CustomMaterialTable
              noDataText={t('table.noDataText')}
              headings={headings}
              data={projectsGetApi?.data}
              showOptionsColumn
              isLoading={isLoading}
              setPage={setPage}
              page={page}
              totalRecords={Math.ceil(projectsGetApi?.totalRecords / recordsLimit)}
              options={[
                // {
                //   label: t('table.options.view'),
                //   icon: <AiFillEye size={18} />,
                //   onClick: (label, item) => {
                //     navigate(`/projects/${item?.slug}`);
                //   }
                // },

                {
                  label: t('table.options.edit'),
                  icon: <FiEdit size={18} />,
                  onClick: (e, rowData) => {
                    setIsUnitAddModal({ boolean: true, ...rowData });
                    // navigate(`/company/projects-management/edit-project/${rowData.slug}`);
                  }
                }
              ]}
              onOptionClick={() => console.log()}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              allowMultipleSelection
            />
          </Col>
        </Row>
      </Container>
      {isUnitAddModal && (
        <UnitAddModal
          data={isUnitAddModal}
          onClick={async (e) => {
            const response = await addUnitApi({
              slug: isUnitAddModal?.slug,
              data: e,

              url: `project/updateProjectUnit/${id}`
            });
            if (response?.data) {
              toast.success(`Unit added successfully`);
              setIsUnitAddModal(false);
            }
          }}
          show={isUnitAddModal?.boolean}
          setShow={setIsUnitAddModal}
          isApiCall={isAddUnitLoading}
        />
      )}
    </SideBarSkeleton>
  );
}

export default ProjectUnit;
