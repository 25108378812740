import { toast } from 'react-toastify';

export const restrictOnlyUserActions = (role) => {
  if (['company', 'agent'].includes(role)) {
    toast.error('Sorry! You are not allowed to perform this action!');
    return false;
  }else{
    return true;
  }
};
