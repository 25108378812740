import React from 'react';
import { Modal } from 'react-bootstrap';
import classes from './modalSkeleton.module.css';
import { AiOutlineClose } from 'react-icons/ai';

export default function ModalSkeleton({
  show,
  setShow,
  header,
  footer,
  children,
  modalClass,
  hideHeaderBorder,
  headerStyles,
  footerStyles,
  borderRadius,
  showCloseIcon = true,
  width,
  borderLine = true,
  headerClass
}) {
  function handleClose() {
    setShow(false);
  }
  return (
    <>
      <style>{`
       .${classes.modal} .modal-header {
          border-bottom: ${!borderLine && 'none !important'};
          padding-bottom: ${!borderLine && '0 !important'};
        }
        .${classes.modal} .modal-header {
          border-bottom: ${hideHeaderBorder ? 'none' : `1px solid var(--main-color-yellow)`};
          border-top-left-radius: ${borderRadius ? borderRadius : '5px'};
          border-top-right-radius: ${borderRadius ? borderRadius : '5px'};
        }
        .${classes.modal} .modal-footer {
          margin: 0px;
          display: unset;
          justify-content: unset;
          align-items: unset;
          padding: ${!borderLine ? '15px 30px' : '0px'};
        }

        .${classes.header} button {
          color: var(--text-black-clr) !important;
        }
        .${classes.modal} .modal-content {
          width: 100%;
          border-radius: ${borderRadius ? borderRadius : '5px'};
          margin: 0 auto;
        }
        .${classes.modal} .modal-dialog {
          max-width: ${width};
          margin: 0px auto;
        }
        @media (max-width:768px){
          .${classes.modal} .modal-dialog {
            max-width: 80%;
          }
        @media (max-width:575px){
          .${classes.modal} .modal-dialog {
            max-width: 90%;
          }
        }
      `}</style>

      <Modal  show={show} onHide={handleClose} centered className={`${[classes.modal].join(' ')}`}>
      {showCloseIcon && (
          <div className={classes.iconBox} onClick={handleClose} data-show-header={header}>
            <AiOutlineClose size={25} color='var(--white-clr)' />
          </div>
        )}
        {header && (
          <Modal.Header
            className={`${[classes.header, headerClass && headerClass].join(' ')}`}
            style={{ ...headerStyles }}>
            <h6>{header}</h6>
          
          </Modal.Header>
        )}
       
        {/* <Modal.Body className={`${[classes.body, modalClass && modalClass].join(' ')}`}>
          {header && (
            <Modal.Header
              closeButton
              className={`${[classes.header, headerClass && headerClass].join(' ')}`}
              style={{ ...headerStyles }}>
              <div>{header}</div>
            </Modal.Header>
          )}
        </Modal.Body> */}
        
        <Modal.Body className={`${[classes.body, modalClass && modalClass].join(' ')}`}>
          {children}
        </Modal.Body>
        {footer && (
          <Modal.Footer
            className={`${[classes.footer].join(' ')}`}
            style={{
              //   borderTop: `1px solid ${Colors.neutralShadesOfGainsboro}`,
              ...footerStyles
            }}>
            <div>{footer}</div>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}
