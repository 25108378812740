/*eslint-disable*/
import React from 'react';
import classes from './PropertyDetail.module.css';
// import { RxDoubleArrowLeft } from 'react-icons/rx';
import { Col, Container, Row } from 'react-bootstrap';
// import BreadCrumb from '../../Component/BreadCrumb';
// import MultiImageCarousel from '../../Component/MultiImageCarousel';
import Tabs from '../../Component/Tabs';
import {
  MdLocationOn,
  MdOutline360,
  MdOutlineBathtub,
  MdOutlineDateRange,
  MdOutlineHotel,
  MdPhotoLibrary,
  MdReport
} from 'react-icons/md';
import { FaLevelUpAlt, FaStreetView, FaVideo } from 'react-icons/fa';
import { IoLocationSharp } from 'react-icons/io5';
import { useState } from 'react';
import { BiTimeFive } from 'react-icons/bi';
import { RiHotelFill } from 'react-icons/ri';
import HeadingWithUnderline from '../../Component/HeadingWithUnderline';
import ListItemWithCheckMark from '../../Component/ListItemWithCheckMark';
import MortgageCalculator from '../../Component/MortgageCalculator';
import ClientManagerCard from '../../Component/ClientManagerCard';
import { horizontalBanner, map1, map3 } from '../../constant/imagePath';
import { BsGrid3X3Gap, BsShareFill } from 'react-icons/bs';
import BannerImageAd from '../../Component/BannerImageAd';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';

import DetailPageHeroSection from '../../Component/DetailPageHeroSection';
import ShareModal from '../../Component/ShareModal/ShareModal';
import { GiPackedPlanks } from 'react-icons/gi';
import Tooltip from '../../Component/Tooltip';
import ReportListingModal from '../../modals/ReportListingModal';
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import AverageRatesWithMarketTrends from '../../Component/AverageRatesWithMarketTrends';
import BankLoanBanner from '../../Component/BankLoanBanner';
import { propertiesData } from '../../constant/commonData';
import {
  useFavoriteEntityMutation,
  useGetPublicOkPropertyDetailQuery,
  useGetPublicPropertyDetailQuery
} from '../../store/apiSlice/apiSlice';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Loader } from '../../Component/Loader';
import { useTranslation } from 'react-i18next';
import { restrictOnlyUserActions } from '../../Helper/restrictActions';
import { toast } from 'react-toastify';
import { m2ToFt2, usdToOther } from '../../utils/helpers';

const images = [
  'https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1549396535-c11d5c55b9df?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550133730-695473e544be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550167164-1b67c2be3973?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550338861-b7cfeaf8ffd8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550223640-23097fc71cb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550353175-a3611868086b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550330039-a54e15ed9d33?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1549737328-8b9f3252b927?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1549833284-6a7df91c1f65?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1549985908-597a09ef0a7c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550064824-8f993041ffd3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60'
];
const plansPicture = [map1, map3];

const PropertyDetails = () => {
  const { t } = useTranslation('propertyDetail');
  const { user, isLogin } = useSelector((state) => state?.authReducer);
  const tabsData = [
    { icon: <MdPhotoLibrary />, text: t('tabs.photos'), id: '1', value: 'Photos' },
    { icon: <GiPackedPlanks />, text: t('tabs.plans'), id: '2', value: 'Plans' },
    { icon: <MdOutline360 />, text: t('tabs.360view'), id: '3', value: '360' },
    { icon: <IoLocationSharp />, text: t('tabs.location'), id: '4', value: 'Location' },
    { icon: <FaStreetView />, text: t('tabs.streetView'), id: '5', value: 'Street' },
    { icon: <FaVideo />, text: t('tabs.video'), id: '6', value: 'Video' }
  ];
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const { areaUnit, currency } = useSelector((state) => state?.converterReducer);
  // const navigate = useNavigate();
  const slug = useParams()?.slug;
  const { data, isLoading, refetch } = useGetPublicOkPropertyDetailQuery({ slug });
  const [tabValue, setTabValue] = useState(tabsData[0]);
  const [reportShow, setReportShow] = useState('');
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [hearLike, setHearLike] = useState(false);
  const propertyData = data?.data;
  const [onFavorite, { isLoading: isFavoriting }] = useFavoriteEntityMutation();

  const isFavorite = propertyData?.favoriteBy?.includes(user?._id);

  const handleFavorite = async () => {
    if (isFavoriting) return;
    const res = await onFavorite({ id: propertyData?._id, type: 'property' });
    if (res?.data) {
      if (res?.data?.data?.favoriteBy?.includes(user?._id)) {
        toast.success(`${'Property added in favorite list'} successfully`);
      } else {
        toast.success(`${'Property removed from favorite list'} successfully`);
      }
      refetch();
    }
  };

  const handleAction = () => {
    const isAllowedActions = restrictOnlyUserActions(user?.role);
    if (!isLogin) {
      navigate('/login');
    } else if (isAllowedActions) {
      return true;
    }
  };

  return (
    <>
      <Header containerClass="" />

      {isLoading ? (
        <Loader className={classes?.loader} />
      ) : (
        <div className={classes.main}>
          {/* <Container>
          <BreadCrumb
            data={data}
            isBack={true}
            backTitle={'Back to search results'}
            backIcon={<RxDoubleArrowLeft size={23} color="var(--arrow-color)" />}
          />
        </Container> */}
          <section className={classes.images}>
            {/* <Container fluid className="px-0"> */}
            {/* <ElasticCarousel images={images} /> */}
            <DetailPageHeroSection
              plansPicture={propertyData?.planImages ?? []}
              images={propertyData?.images ?? []}
              text={tabValue?.value}
              videoLink={propertyData?.videoLink ?? ''}
              location={{
                lat: propertyData?.location?.coordinates?.[1],
                lng: propertyData?.location?.coordinates?.[0]
              }}
              threeSixtyImage={propertyData?.threeSixtyViewLink}
            />
            {/* </Container> */}
          </section>
          <Container className={[classes.container].join(' ')}>
            <Row className={classes.gap}>
              <Col md={12} lg={9} className={classes.col1}>
                {/* <div> */}
                <Tabs
                  className={classes.tabs_main}
                  value={tabValue}
                  tabClass={classes.tab}
                  setValue={(e) => {
                    setTabValue(e);
                  }}
                  tabsData={tabsData}
                />
                {/* </div> */}
                <div className={classes.detailWrap}>
                  <div className={classes.priceRow}>
                    <div>
                      <div className={classes.price}>
                        <p>{usdToOther({ amount: propertyData?.price, ...currency })}</p>
                        {propertyData?.propertyPurpose?.en == 'Rent' && <p>{t('perMonth')}</p>}
                      </div>
                      <div className={classes.desc}>
                        <p>{propertyData?.title?.[selectedLanguage]}</p>
                      </div>

                      <div className={classes.addressWrap}>
                        <MdLocationOn color="#EF8B1D" />
                        <p>{propertyData?.address}</p>
                      </div>
                    </div>
                    <div className={classes.openHouseWrap}>
                      <p>{t('openHouse')}</p>
                      <div className="flex-align-center">
                        <MdOutlineDateRange size={13} color="var(--text-dark-gray-clr)" />

                        <span className={classes.dateTime}>
                          {moment(propertyData?.openHouseStartDate).format('DD/MM/YYYY')} to
                          {moment(propertyData?.openHouseEndDate).format('DD/MM/YYYY')}
                        </span>
                      </div>
                      <div className="flex-align-center">
                        <BiTimeFive size={13} color="var(--text-dark-gray-clr)" />
                        <span className={classes.dateTime}>
                          {moment(propertyData?.openHouseStartDate).format('hh:mm a')} to{' '}
                          {moment(propertyData?.openHouseEndDate).format('hh:mm a')}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={classes.propertyDetailWrap}>
                    {/* <div className={classes.gap}> */}
                    <div className={classes.propertyDetail}>
                      <Tooltip text={t('tooltips.propertyType')}>
                        <RiHotelFill color="var(--text-dark-gray-clr)" />
                      </Tooltip>
                      <p>{propertyData?.propertyType?.title?.[selectedLanguage]}</p>
                    </div>
                    <div className={classes.propertyDetail}>
                      <Tooltip text={t('tooltips.area') + ` (${areaUnit})`}>
                        <BsGrid3X3Gap color="var(--text-dark-gray-clr)" />
                      </Tooltip>

                      <p>
                        {m2ToFt2({
                          area: propertyData?.netArea,
                          areaUnit,
                          unit: false
                        })}
                      </p>
                    </div>
                    <div className={classes.propertyDetail}>
                      <Tooltip text={t('tooltips.rooms')}>
                        <MdOutlineHotel color="var(--text-dark-gray-clr)" />
                      </Tooltip>
                      <p>{propertyData?.noOfRooms?.[selectedLanguage]}</p>
                    </div>
                    <div className={classes.propertyDetail}>
                      <Tooltip text={t('tooltips.bathrooms')}>
                        <MdOutlineBathtub color="var(--text-dark-gray-clr)" />
                      </Tooltip>
                      <p>{propertyData?.noOfBathrooms}</p>
                    </div>
                    <div className={classes.propertyDetail}>
                      <Tooltip text={t('tooltips.level')}>
                        <FaLevelUpAlt color="var(--text-dark-gray-clr)" />
                      </Tooltip>

                      <p>{propertyData?.floorLevel?.[selectedLanguage]}</p>
                    </div>
                    {/* </div> */}
                  </div>
                  <div className={classes.overView}>
                    <HeadingWithUnderline underlineColor="var(--secondary-clr)">
                      <h5>{t('overview.title')}</h5>
                    </HeadingWithUnderline>
                    <Row className={classes.overViewRow}>
                      <Col md={12} lg={6} className={classes.overviewCol}>
                        <ListItemWithCheckMark showCheck={false} mainClass={classes.list}>
                          <div className={`flex-sb ${classes.listItem}`}>
                            <span>{t('overview.listingID')}</span>
                            <span>{propertyData?.listingId}</span>
                          </div>
                        </ListItemWithCheckMark>
                        <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                          <div className={`flex-sb ${classes.listItem}`}>
                            <span>{t('overview.type')}</span>
                            <span>{propertyData?.propertyType?.title?.[selectedLanguage]}</span>
                          </div>
                        </ListItemWithCheckMark>

                        <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                          <div className={`flex-sb ${classes.listItem}`}>
                            <span>
                              {propertyData?.propertyPurpose?.en == 'Rent'
                                ? t('overview.rentPrice')
                                : t('overview.price')}
                            </span>
                            <span>{usdToOther({ amount: propertyData?.price, ...currency })}</span>
                          </div>
                        </ListItemWithCheckMark>
                        <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                          <div className={`flex-sb ${classes.listItem}`}>
                            <span>{t('overview.area')}</span>
                            <span>
                              {m2ToFt2({
                                area: propertyData?.netArea,
                                areaUnit
                              })}
                            </span>
                          </div>
                        </ListItemWithCheckMark>
                        <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                          <div className={`flex-sb ${classes.listItem}`}>
                            <span>{t('overview.rooms')}</span>
                            <span> {propertyData?.noOfRooms?.[selectedLanguage]}</span>
                          </div>
                        </ListItemWithCheckMark>
                        <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                          <div className={`flex-sb ${classes.listItem}`}>
                            <span>{t('overview.bathrooms')}</span>
                            <span>{propertyData?.noOfBathrooms}</span>
                          </div>
                        </ListItemWithCheckMark>
                        <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                          <div className={`flex-sb ${classes.listItem}`}>
                            <span>{t('overview.propertyTitleDeed')}</span>
                            <span> {propertyData?.titleOfDeed?.[selectedLanguage]}</span>
                          </div>
                        </ListItemWithCheckMark>
                      </Col>
                      <Col md={12} lg={6} className={classes.overviewCol}>
                        <ListItemWithCheckMark showCheck={false} mainClass={classes.list}>
                          <div className={`flex-sb ${classes.listItem}`}>
                            <span>{t('overview.parkingSpaces')}</span>
                            <span>{propertyData?.parkingSpace}</span>
                          </div>
                        </ListItemWithCheckMark>
                        <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                          <div className={`flex-sb ${classes.listItem}`}>
                            <span>{t('overview.furniture')}</span>
                            <span>{propertyData?.furniture?.[selectedLanguage]}</span>
                          </div>
                        </ListItemWithCheckMark>
                        <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                          <div className={`flex-sb ${classes.listItem}`}>
                            <span>{t('overview.floorLevel')}</span>
                            <span>{propertyData?.floorLevel?.[selectedLanguage]}</span>
                          </div>
                        </ListItemWithCheckMark>

                        <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                          <div className={`flex-sb ${classes.listItem}`}>
                            <span>{t('overview.propertyAge')}</span>
                            <span>{propertyData?.propertyAge?.[selectedLanguage]}</span>
                          </div>
                        </ListItemWithCheckMark>
                        <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                          <div className={`flex-sb ${classes.listItem}`}>
                            <span>{t('overview.propertyStatus')}</span>
                            <span>{propertyData?.propertyStatus?.[selectedLanguage]}</span>
                          </div>
                        </ListItemWithCheckMark>
                        <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                          <div className={`flex-sb ${classes.listItem}`}>
                            <span>{t('overview.propertyOrientation')}</span>
                            <span> {propertyData?.propertyOrientation?.[selectedLanguage]}</span>
                          </div>
                        </ListItemWithCheckMark>
                      </Col>
                    </Row>
                  </div>
                  <div className={classes.description}>
                    <HeadingWithUnderline underlineColor="var(--secondary-clr)">
                      <h5>{t('description')}</h5>
                    </HeadingWithUnderline>
                    <p>{propertyData?.description?.[selectedLanguage]}</p>
                  </div>

                  <div className={classes.interior}>
                    <HeadingWithUnderline underlineColor="var(--secondary-clr)">
                      <h5>{t('amenities')}</h5>
                    </HeadingWithUnderline>
                    <div className={classes.interior}>
                      <HeadingWithUnderline>
                        <h6>{t('interiorAmenities')}</h6>
                      </HeadingWithUnderline>
                      <Row className={classes.interior}>
                        {propertyData?.interiorAmenities?.map((item, index) => (
                          <Col md={4} key={index}>
                            <ListItemWithCheckMark mainClass="flex-align-center">
                              <div className={`${classes.interiorList}`}>
                                <span>{item?.title?.[selectedLanguage]}</span>
                              </div>
                            </ListItemWithCheckMark>
                          </Col>
                        ))}
                      </Row>
                    </div>
                    <div className={classes.interior}>
                      <HeadingWithUnderline>
                        <h6>{t('exteriorAmenities')}</h6>
                      </HeadingWithUnderline>
                      <Row className={classes.interior}>
                        {propertyData?.exteriorAmenities?.map((item, index) => (
                          <Col md={4} key={index}>
                            <ListItemWithCheckMark mainClass="flex-align-center">
                              <div className={`${classes.interiorList}`}>
                                <span>{item?.title?.[selectedLanguage]}</span>
                              </div>
                            </ListItemWithCheckMark>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                  <div className={classes.mt50}>
                    <AverageRatesWithMarketTrends t={t} title={t('marketTrends')} />
                  </div>
                  <MortgageCalculator heading={t('mortgage.title')} />
                </div>
              </Col>

              {/* Right column */}
              <Col md={12} lg={3} className={classes.col2}>
                <div className={classes.socialIconsWrap1}>
                  <div className={[classes.socialIcons1, classes.borderRight].join(' ')}>
                    <BsShareFill
                      onClick={() => setIsShareModalOpen(true)}
                      fill="var(--primary-clr)"
                      size={25}
                    />
                  </div>
                  <div className={[classes.socialIcons1, classes.borderRight].join(' ')}>
                    {isFavorite ? (
                      <AiFillHeart
                        onClick={() => {
                          if (!handleAction()) return;
                          handleFavorite();
                        }}
                        fill="var(--primary-clr)"
                        size={25}
                        className="c-p"
                      />
                    ) : (
                      <AiOutlineHeart
                        onClick={() => {
                          if (!handleAction()) return;
                          handleFavorite();
                        }}
                        fill="#9d9e9e"
                        size={25}
                        className="c-p"
                      />
                    )}
                  </div>
                  <div className={[classes.socialIcons1].join(' ')}>
                    <MdReport
                      onClick={() => {
                        setReportShow(true);
                      }}
                      fill="var(--primary-clr)"
                      size={25}
                      className="c-p"
                    />
                  </div>
                </div>
                <div className={classes.clientCard}>
                  <ClientManagerCard
                    pageData={propertiesData[0]}
                    dataType={'property'}
                    data={propertyData}
                  />
                </div>
                {/* <div className={classes?.bannerAdContainer}>
                <BannerImageAd image={verticalBanner} className={classes.verticalBanner} />
              </div> */}
              </Col>
            </Row>

            {/* <div className={classes.similarEvents}>
              <HeadingWithUnderline>
                <h6>SIMILAR PROPERTIES</h6>
              </HeadingWithUnderline>
            </div> */}
            {/* <div className={classes.propertyCarousel}>
              <Carousel
                className="property-carousel"
                breakPoints={breakPoints}
                pagination={false}
                // showArrows={showArrows}
              >
                {Array(4)
                  .fill(0)
                  .map((item, index) => (
                    <PropertyCard
                      onClick={() => {
                        navigate(`/property-detail/${item?._id}`);
                      }}
                      key={index}
                      item={item}
                      feature={true}
                    />
                  ))}
              </Carousel>
            </div> */}
          </Container>
          <BankLoanBanner />
          <Container>
            <div className={classes?.bannerAdContainer}>
              <BannerImageAd image={horizontalBanner} className={classes.horizontalBanner} />
            </div>
          </Container>
          {reportShow && <ReportListingModal show={reportShow} setShow={setReportShow} />}
          {isShareModalOpen && <ShareModal show={isShareModalOpen} setShow={setIsShareModalOpen} />}
        </div>
      )}
      <Footer />
    </>
  );
};

export default PropertyDetails;

const data = {
  price: '2000',
  title: 'popular belief, Lorem Ipsum is not simply',
  location: '2415 Byron Shire, New South - Wales',
  type: 'Villa',
  area: '200',
  noOfBedRooms: '20',
  noOfBathRooms: '20',
  level: '2',
  listingNumber: '12',
  parkingSpaces: '2',
  furniture: 'yes',
  floorLevel: '3',
  propertyOrientation: 'yes',
  propertyAge: '20',
  propertyStatus: 'Commercial',
  description:
    ' Description Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus',
  interiorAmenities: [
    'AirConditioning',
    'Heating',
    'Balcony',
    'Indoor firePlace',
    'AirConditioning',
    'Heating',
    'Balcony',
    'Indoor firePlace',
    'AirConditioning',
    'Heating',
    'Balcony',
    'Indoor firePlace'
  ],
  exteriorAmenities: [
    'AirConditioning',
    'Heating',
    'Balcony',
    'Indoor firePlace',
    'AirConditioning',
    'Heating',
    'Balcony',
    'Indoor firePlace',
    'AirConditioning',
    'Heating',
    'Balcony',
    'Indoor firePlace'
  ]
};
