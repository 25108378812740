import React from 'react';
import PropTypes from 'prop-types';
// import { useSelector } from "react-redux";
import classes from './footer.module.css';
import { Col, Container, Row } from 'react-bootstrap';
import Button from '../Button/Button';
import { Link, useNavigate } from 'react-router-dom';
import { AppStore, PlayStore } from '../../constant/imagePath';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Footer = ({ containerClass = '' }) => {
  const { t } = useTranslation('webCommon');
  // const cmsData = useSelector((state) => state?.commonReducer?.cms?.footer);
  const { isLogin } = useSelector((state) => state?.authReducer);
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  return (
    <>
      <div className={classes.footerOne}>
        <Container className={containerClass}>
          <div className={classes.textWithImages}>
            <h6>{t('webFooter.downloadNow')}</h6>
            <div>
              <div className={classes.imgDiv}>
                <img src={PlayStore} alt="" draggable={false} />
              </div>
              <div className={classes.imgDiv}>
                <img src={AppStore} alt="" draggable={false} />
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className={classes.footerTwo}>
        <Container className={classes.container}>
          <Row className={classes.row}>
            <Col md={9}>
              <div className={classes.linksAndCopyRightText}>
                <div className={classes.links}>
                  <Link to={'/terms-and-conditions'} className="link">
                    {t('webFooter.termsAndConditions')}
                  </Link>
                  <Link to={'/privacy-policy'} className="link">
                    {t('webFooter.privacyPolicy')}
                  </Link>
                  <Link to={'/contact-us'} className="link">
                    {t('webFooter.contactUs')}
                  </Link>
                  <Link to={'/advertise-with-us'} className="link">
                    {t('webFooter.advertiseWithUs')}
                  </Link>
                  <Link to={'/blog'} className="link">
                    {t('webFooter.blog')}
                  </Link>
                </div>
                <p className={`p16 ${classes.allRightsText}`}>
                  ©{currentYear} Demo. {t('webFooter.copyRightText')}.
                </p>
              </div>
            </Col>
            <Col md={3} className={classes.flexEnd}>
              {!isLogin && (
                <Button
                  variant="transparent"
                  className={classes.agentLoginBtn}
                  borderRadius={'3px'}
                  onClick={() => navigate('/login')}>
                  {t('webFooter.login')}
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;

Footer.propTypes = {
  containerClass: PropTypes.string
};
