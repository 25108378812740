/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import classes from './Notification.module.css';
import moment from 'moment';

import { BaseURL } from '../../config/apiUrl';
import { Get } from '../../Axios/AxiosFunctions';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import NoData from '../../Component/NoData/NoData';
import image from '../../assets/images/house.jpg';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import { PaginationComponent } from '../../Component/Pagination/Pagination';

const NotificationsList = ({ item }) => {
  // const navigate = useNavigate();
  return (
    <div
      className={[classes.HeaderNotificationInnerDiv]}
      onClick={() => {
        // item?.flag == 'chat' &&
        //   navigate('/chat', {
        //     state: {
        //       currentRoom: item?.project?.room
        //     }
        //   });
      }}>
      <div className={[classes.ImgDiv]}>
        <img src={image} alt="" />
      </div>
      <div className={[classes.contentDiv]}>
        <div className={[classes.titleDiv]}>
          <h6>{item?.description}</h6>
        </div>
        <div className={classes.content}>
          <p>
            {' '}
            <span>Type:</span> Property For Sale
          </p>
          <p>
            <span>Posted By:</span> Property For Sale
          </p>
          <div className={classes.time}>
            <p>{moment(item?.createdAt).format('Do MMM YYYY hh:mm a')}</p>
          </div>
        </div>
        {/* <p>{item?.message}</p> */}
      </div>
    </div>
  );
};

const NotificationPage = () => {
  const [page,setPage]=useState(1)
  const notificationData = [
    {
      description: 'Full floor for sale I All unit already rented I One bedroom',
      postedBy: 'Anas',
      createdAt: moment()
    },
    {
      description: 'Full floor for sale I All unit already rented I One bedroom',
      postedBy: 'Anas',
      createdAt: moment()
    },
    {
      description: 'Full floor for sale I All unit already rented I One bedroom',
      postedBy: 'Anas',
      createdAt: moment()
    },
    {
      description: 'Full floor for sale I All unit already rented I One bedroom',
      postedBy: 'Anas',
      createdAt: moment()
    },
    {
      description: 'Full floor for sale I All unit already rented I One bedroom',
      postedBy: 'Anas',
      createdAt: moment()
    },
    {
      description: 'Full floor for sale I All unit already rented I One bedroom',
      postedBy: 'Anas',
      createdAt: moment()
    },
    {
      description: 'Full floor for sale I All unit already rented I One bedroom',
      postedBy: 'Anas',
      createdAt: moment()
    },
    {
      description: 'Full floor for sale I All unit already rented I One bedroom',
      postedBy: 'Anas',
      createdAt: moment()
    }
  ];
  const accessToken = useSelector((state) => state?.authReducer?.accessToken);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const fetchNotifications = async () => {
    const url = BaseURL('notifications/all?page=1&limit=5');
    setLoading(false);
    let response = await Get(url, accessToken);
    setLoading(false);

    if (response !== undefined) {
      setNotifications(notifications);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <>
      <Header />
      <div className={classes.page}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={[classes.notificationHeader]}>
                <h4>Notifications</h4>
              </div>
            </Col>
            <Col md={12}>
              <div className={classes.notificationSkeleton}>
                {loading ? (
                  Array(10)
                    .fill(0)
                    ?.map((index) => (
                      <Skeleton
                        key={index}
                        height={'80px'}
                        width={'100%'}
                        variant={'rectangular'}
                      />
                    ))
                ) : notificationData.length == 0 ? (
                  <NoData />
                ) : (
                  notificationData?.map((item, i) => {
                    return (
                      <div className={[classes.mb16]} key={i}>
                        <NotificationsList item={item} />
                      </div>
                    );
                  })
                )}
              </div>
            </Col>
            <Col md={12} className='jCenter'>
              <PaginationComponent totalPages={1} currentPage={page} setCurrentPage={setPage}/>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default NotificationPage;
