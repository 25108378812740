/*eslint-disable*/

import React, { useEffect, useState } from 'react';
import classes from './HeaderFavoriteList.module.css';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import image from '../../assets/images/house.jpg';

import { BaseURL } from '../../config/apiUrl';
import { Get } from '../../Axios/AxiosFunctions';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import NoData from '../NoData/NoData';
import { AiFillHeart } from 'react-icons/ai';
import Tabs from '../Tabs';
import { useLazyGetApiQuery } from '../../store/apiSlice/apiSlice';
// import { MdNotifications } from "react-icons/md";

const Notifications = ({ item, type }) => {
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  // const navigate = useNavigate();
  return (
    <div
      className={[classes.HeaderNotificationInnerDiv]}
      onClick={() => {
        // item?.flag == 'chat' &&
        //   navigate('/chat', {
        //     state: {
        //       currentRoom: item?.project?.room
        //     }
        //   });
      }}>
      <div className={[classes.ImgDiv]}>
        <img src={image} alt="" />
      </div>
      <div className={[classes.contentDiv]}>
        <div className={classes.price}>
          <p>${item?.price}</p>
          {item?.propertyType?.en == 'Rent' && <p>Per Month</p>}
        </div>
        <div className={[classes.titleDiv]}>
          <h6>{item?.title?.[selectedLanguage]}</h6>
        </div>
        <div className={classes.content}>
          <p>
            {' '}
            <span>Type:</span> {item?.[`${type}Type`]?.title?.[selectedLanguage]}
          </p>

          {/* <div className={classes.time}>
            <p>{moment(item?.createdAt).format('Do MMM YYYY hh:mm a')}</p>
          </div> */}
        </div>
        {/* <p>{item?.message}</p> */}
      </div>
    </div>
  );
};

const HeaderFavoriteList = ({ t }) => {
  const [getApiFunc, { isLoading, isFetching, data: response }] = useLazyGetApiQuery();
  const tabsData = [
    {
      id: 1,
      text: `Properties (${response?.savedProperties || 0})`,
      icon: <AiFillHeart />,
      path: '/saved-properties',
      type: 'property'
    },
    {
      id: 2,
      text: `Events (${response?.savedEvents || 0})`,
      icon: <AiFillHeart />,
      path: '/saved-events',
      type: 'event'
    },
    {
      id: 3,
      text: `Projects (${response?.savedProjects || 0})`,
      icon: <AiFillHeart />,
      path: '/saved-projects',
      type: 'project'
    }
  ];

  const [tabValue, setTabValue] = useState(tabsData[0]);
  const navigate = useNavigate();

  const getData = async (type = tabValue?.type) => {
    await getApiFunc({
      url: `users/getMyFavorites?type=${type}&page=${1}&limit=${5}&sortBy=${-1}`,
      tag: `SavedPropertiesEventsProjects`
    });
  };
  const newArray5 = [1, 2, 3, 4, 5];

  // console.log(newArray5, 'response response');

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={[classes.HeaderNotification]}>
      <div className={[classes.notificationHeader]}>
        <AiFillHeart size={25} />
        <h6>{t('webHeader.favoritePopup.title')}</h6>
      </div>

      <Tabs
        className={classes.tab_main}
        tabClass={classes.tab_class_main}
        value={tabValue}
        setValue={(e) => {
          getData(e?.type);
          setTabValue(tabsData.find((item) => item.type == e.type));
        }}
        tabsData={tabsData}
      />
      <div className={classes.notificationSkeleton}>
        {isLoading || isFetching ? (
          newArray5?.map((index) => (
            <Skeleton key={index} height={'80px'} width={'100%'} variant={'rectangular'} />
          ))
        ) : response?.data?.length == 0 ? (
          <NoData text={t('webHeader.favoritePopup.noData')} className={classes.noData} />
        ) : (
          response?.data?.map((item, i) => {
            return (
              <div className={[classes.mb16]} key={i}>
                <Notifications item={item?.entity} type={tabValue?.value} />
              </div>
            );
          })
        )}
      </div>
      <p
        className={['p18', classes.viewAll].join(' ')}
        onClick={() => navigate('/saved-properties')}>
        {t('webHeader.favoritePopup.seeAll')}
      </p>
    </div>
  );
};
export default HeaderFavoriteList;
