import React from 'react';
import classes from './CustomPhoneInput.module.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const CustomPhoneInput = ({ label, value, setter, placeholder = 'phone', variant = '' }) => {
  return (
    <>
      <style>{`
    .react-tel-input .flag-dropdown{
      background-color: var(--white-color) !important;
      border-radius: 3px 0 0 3px !important;
      border-right:1px solid grey !important;
    }
    `}</style>
      <div className={classes.phoneMain} data-variant={variant}>
        {label && <p className={[classes.phoneLabel]}>{label}</p>}
        <PhoneInput
          inputClass={[classes.phoneInput]}
          containerClass={[classes.phoneInputContainer]}
          placeholder={placeholder}
          enableSearch={true}
          value={value}
          onChange={(phone) => {
            setter && setter(phone);
          }}
        />
      </div>
    </>
  );
};

export default CustomPhoneInput;
