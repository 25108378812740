import React from 'react';
import ModalSkeleton from '../ModalSkeleton';
import classes from './ChangePasswordModal.module.css';
import Input from '../../Component/Input/Input';
import { useState } from 'react';
import Button from '../../Component/Button/Button';
import { toast } from 'react-toastify';
import { useUpdatePasswordMutation } from '../../store/apiSlice/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { saveLoginUserData } from '../../store/auth/authSlice';
import { useTranslation } from 'react-i18next';

const ChangePasswordModal = ({ show, setShow }) => {
  const { t: d } = useTranslation('webCommon');
  const t = (key) => d(`updatePasswordModal.${key}`);
  const { user } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();

  const handleLogin = async () => {
    const params = {
      // email,
      // password,
      passwordCurrent: currentPassword,
      password: newPassword,
      confirmPassword
    };
    for (let key in params) {
      if (!params[key]) {
        return toast.error(`Please fill all the fields`);
      }
    }

    if (params?.passwordCurrent?.length < 8) {
      return toast.error('Password must be 8 characters or more');
    }
    if (params?.password?.length < 8) {
      return toast.error('New Password must be 8 characters or more');
    }
    if (params?.passwordCurrent == params?.password) {
      return toast.error('Password must be different from current password');
    }
    if (params?.password !== params.confirmPassword) {
      return toast.error('Passwords must match');
    }

    const response = await updatePassword(params);
    if (response?.data) {
      toast.success('Your password has been reset successfully!');
      dispatch(saveLoginUserData(response?.data));
      navigate(
        `/${['company', 'agent'].includes(user?.role) ? `${user?.role}/dashboard` : 'dashboard'}`
      );
    }
  };
  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      header={t('title')}
      headerClass={classes.header}>
      <div className={classes.inputs}>
        <Input
          value={currentPassword}
          setValue={setCurrentPassword}
          inputStyle={{
            borderRadius: '5px'
          }}
          placeholder={t('currentPassword')}
          type={'password'}
          autoComplete="new-password"
        />
        <Input
          value={newPassword}
          setValue={setNewPassword}
          inputStyle={{
            borderRadius: '5px'
          }}
          placeholder={t('newPassword')}
          type={'password'}
          autoComplete="new-password"
        />
        <Input
          value={confirmPassword}
          setValue={setConfirmPassword}
          inputStyle={{
            borderRadius: '5px'
          }}
          placeholder={t('confirmPassword')}
          type={'password'}
          autoComplete="new-password"
        />
      </div>

      <Button
        className={classes.btn}
        label={isLoading ? t('submitBtnLoading') : t('submitBtn')}
        onClick={handleLogin}
        variant="primary"
      />
    </ModalSkeleton>
  );
};

export default ChangePasswordModal;
