import React from 'react';
import Carousel from 'react-elastic-carousel';
import classes from './ElasticCarousel.module.css';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { imageUrl } from '../../config/apiUrl';

const customBreakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 2 },
  { width: 1200, itemsToShow: 2 }
];
const ElasticCarousel = ({
  customClass,
  showArrows = true,
  pagination = false,
  images,
  navigateLink,
  breakPoints = customBreakPoints,
  className = '',
  ...props
}) => {
  const memoizeValue = React.useMemo(
    () => images?.map((e) => (e?.includes('https://') ? e : imageUrl(e))),
    [images]
  );
  const navigate = useNavigate();
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  return (
    <>
      <style>
        {`
        .image-carousel .rec-slider-container{
            padding-block:0px;
            margin:0px;
        }
       

        .image-carousel .rec-carousel-wrapper{
            height:100%;
        }
        
        .image-carousel .rec-slider{
        }
     
        .${classes.image} .rec-arrow-left, .${classes.image} .rec-arrow-right{
          opacity:0;
        }
        .${classes.image}:hover .rec-arrow-left, .${classes.image}:hover .rec-arrow-right{
          opacity:1;
        }
        .image-carousel .rec-arrow-left{
            position: absolute;
            z-index: 1;
            left: 0px;
            height: 100%;
            top: 0;
            min-width:0px;
            width:30px;
            box-shadow:none;
            margin-inline-start:0px
        }
        .image-carousel .rec-arrow-right{
            position: absolute;
            right: 0px;
            height: 100%;
            min-width:0px;
            width:30px;
            top: 0;
            box-shadow:none;
            margin-inline-end:0px
        }
        .image-carousel .rec-item-wrapper{
            height:100%;
        }
        .rec-carousel-item{
          height:100%;
        }
        `}
      </style>
      <div className={[classes.image, customClass].join(' ')}>
        <div className={classes.invi_box} onClick={() => navigateLink && navigate(navigateLink)} />
        <Carousel
          className={['image-carousel', className].join(' ')}
          breakPoints={breakPoints}
          pagination={pagination}
          showArrows={showArrows}
          isRTL={['ar', 'fa'].includes(selectedLanguage)}
          {...props}>
          {memoizeValue.map((item, index) => (
            // <Item key={item}>{item}</Item>
            <img src={item} key={index} alt="" draggable={false} />
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default ElasticCarousel;
