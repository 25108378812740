import React from 'react';
import classes from './HeroSection.module.css';
import { Container } from 'react-bootstrap';
// import styled from 'styled-components';

function HeroSection({ containerClass = '', children, imageUrl, className = '' }) {
  // const MainDiv = styled.div`
    // background-image: url(${imageUrl});
    // background-repeat: no-repeat;
    // background-size: cover;
  // `;
  return (
    <div className={[classes.heroSection, className].join(' ')}>
      <picture>
        <img src={imageUrl}/>
      </picture>
      <Container className={[classes.contentContainer,containerClass].join(' ')}>{children}</Container>
    </div>
  );
}

export default HeroSection;
