import React, { useRef, useEffect } from 'react';
import classes from './Tabs.module.css';
import { MdPhotoLibrary } from 'react-icons/md';
import styled from 'styled-components';

function Tabs({
  tabsData = [
    { icon: <MdPhotoLibrary />, text: 'Photos', id: '1' },
    { icon: <MdPhotoLibrary />, text: 'Photo', id: '2' },
    { icon: <MdPhotoLibrary />, text: 'Photo1', id: '3' },
    { icon: <MdPhotoLibrary />, text: 'Photo2', id: '4' },
    { icon: <MdPhotoLibrary />, text: 'Photo3', id: '5' },
    { icon: <MdPhotoLibrary />, text: 'Photo4', id: '6' }
  ],
  className = '',
  tabClass = '',
  value = tabsData[0],
  setValue,
  selectedColor = 'var(--primary-clr)',
  bgColor = 'var(--white-clr)',
  separatorColor = 'var(--text-light-gray-clr)',
  scrollOffset = 0,
  selectedTabClass=''
}) {
  const tabRef = useRef(null);
  const selectedIndex = tabsData?.findIndex((item) => item?.id == value?.id);
  useEffect(() => {
    if (value && tabRef?.current) {
      tabRef?.current?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      window.scrollTo({ behavior: 'smooth', top: scrollOffset });
    }
  }, [value]);

  const ULComp = styled.ul`
    background-color: ${bgColor};
    & li.${classes.selected} {
      background-color: ${selectedColor};
    }
    & li:not(.${classes.selected}):not(:last-child):not(.${classes.hideBorder})::before {
      background-color: ${separatorColor};
    }
  `;

  return (
    <ULComp className={[classes.tabs, className].join(' ')} id="tabs">
      {tabsData?.map((item, key) => (
        <li
          key={key}
          className={[
            selectedIndex - 1 == key && classes.hideBorder,
            value?.id == item?.id && classes.selected,
            value?.id == item?.id && selectedTabClass && selectedTabClass,
            tabClass
          ].join(' ')}
          onClick={() => {
            setValue(item);
          }}
          ref={value?.id == item?.id ? tabRef : null}>
          {item?.icon && item?.icon} {item?.text && <span>{item?.text}</span>}
        </li>
      ))}
    </ULComp>
  );
}

export default Tabs;
