/*eslint-disable*/
import React, { useState } from 'react';
import classes from './Followers.module.css';
import SideBarSkeleton from '../../../Component/SideBarSkeleton';
import { Col, Container, Row } from 'react-bootstrap';
import CustomMaterialTable from '../../../Component/CustomMaterialTable';
import SearchInput from '../../../Component/SearchInput';
import { recordsLimit } from '../../../config/apiUrl';
import DropDown from '../../../Component/DropDown/DropDown';
import { dateFilterOptions } from '../../../constant/commonData';
import useDebounce from '../../../CustomHooks/useDebounce';
import { useGetMyFollowersQuery } from '../../../store/apiSlice/apiSlice';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// const tableData = Array(10)
//   .fill({
//     id: '5645',
//     date: '2020.11.12',
//     fullName: 'John Smith',
//     email: 'abc@gmail.com',
//     phoneNo: '2328902183',
//     budget: '1000'
//   })
//   ?.map((item) => ({ ...item, id: Math.floor(Math.random() * 10000) }));

function Followers() {
  const [searchText, setSearchText] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [dateFilter, setDateFilter] = useState(dateFilterOptions[0]);
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const [limit, setLimit] = useState(recordsLimit);
  const [page, setPage] = useState(1);

  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const { t } = useTranslation('dashboardFollowers');

  

  const { data, isLoading, isFetching } = useGetMyFollowersQuery({
    limit,
    page,
    search: debouncedSearchTerm,
    sortBy: dateFilter?.value
  });
  const headings = [
    { id: 'id', label: t('sNo'), renderValue: (_, __, i) => i + 1 },
    { id: 'fullName', label: t('followerName') },
    { id: 'email', label: t('email') },
    { id: 'phoneNumber', label: t('mobile') }
  ];

  return (
    <SideBarSkeleton>
      <Container fluid className={classes.page}>
        <Row>
          <Col md={12} className={classes.mb58}>
            <div className={classes.header}>
              <div className={classes.left}>
                <SearchInput
                  placeholder={t('searchByName')}
                  setter={setSearchText}
                  state={searchText}
                  variant="dashboard"
                  containerStyle={{ width: '300px' }}
                />
                <DropDown
                  label={t('sortByDate')}
                  containerClassName={classes.dropDownContainer}
                  customStyle={{
                    padding: '0px 6px',
                    //  minHeight: '40px',
                    minWidth: '200px'
                  }}
                  options={dateFilterOptions}
                  setValue={(e) => {
                    setDateFilter(e);
                  }}
                  value={dateFilter}
                  placeholder={'Select Date'}
                  // isClearable={true}
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <h5 className={classes.heading}>{t('followers')}</h5>
          </Col>
          <Col md={12}>
            <CustomMaterialTable
              headings={headings}
              data={data?.data}
              totalRecords={Math.ceil(data?.totalRecords / recordsLimit)}
              isLoading={isLoading || isFetching}
              page={page}
              limit={recordsLimit}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              allowMultipleSelection
              rowsPerPage={limit}
              setRowsPerPage={setLimit}
              setPage={setPage}
              showOptionsColumn={false}
            />
          </Col>
        </Row>
      </Container>
    </SideBarSkeleton>
  );
}

export default Followers;
