import React from 'react';
import classes from './BlogCard.module.css';

import { BsCalendarCheck } from 'react-icons/bs';
import { FaUserAlt } from 'react-icons/fa';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { imageUrl } from '../../config/apiUrl';
const BlogCard = ({ data, onClick }) => {
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  return (
    <div className={classes?.mainContainer} onClick={onClick}>
      <div className={classes?.imageContainer}>
        <img src={imageUrl(data?.image)} />
      </div>
      <div className={classes?.detailContainer}>
        <h6>{data?.title?.[selectedLanguage]}</h6>
        <p>{data?.description?.[selectedLanguage]}</p>
      </div>
      <div className={classes?.bottomContainer}>
        <FaUserAlt fill="#cccccc" size={20} />
        <p>
          {data?.postedBy?.firstName} {data?.postedBy?.lastName}
        </p>
        <BsCalendarCheck fill="#cccccc" size={20} />
        <p>{moment(data?.createdAt).format('DDMMM,YYYY')}</p>
      </div>
    </div>
  );
};

export default BlogCard;
