/* eslint-disable */
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import validator from 'validator';
import { Post } from '../../Axios/AxiosFunctions';
import Button from '../../Component/Button/Button';
import CustomPhoneInput from '../../Component/CustomPhoneInput';
import Input from '../../Component/Input/Input';
import { Radio } from '../../Component/Radio/Radio';
import ModalSkeleton from '../ModalSkeleton';
import classes from './EmailAgentModal.module.css';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AiOutlineNumber } from 'react-icons/ai';
import { BiBuildingHouse } from 'react-icons/bi';
import { BsGrid3X3Gap } from 'react-icons/bs';
import { MdLocationOn, MdOutlineBathtub, MdOutlineHotel } from 'react-icons/md';
import { RiHotelFill } from 'react-icons/ri';
import { TbCalendarTime } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Captcha from '../../Component/Captcha';
import Checkbox from '../../Component/Checkbox/Checkbox';
import IconList from '../../Component/IconList/IconList';
import TextArea from '../../Component/TextArea';
import Tooltip from '../../Component/Tooltip';
import { BaseURL, imageUrl } from '../../config/apiUrl';
import { m2ToFt2, usdToOther } from '../../utils/helpers';
import SuccessModal from '../SuccessModal';

const EmailAgentModal = ({ show, setShow, type = 'property', data }) => {
  const { t } = useTranslation('webCommon');

  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const { currency, areaUnit } = useSelector((state) => state.converterReducer);

  const [reason, setReason] = useState(`Hi!, I am interested in your ${type}. please contact me.`);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [contactMethod, setContactMethod] = useState('Email');
  const [isLoading, setIsLoading] = useState(false);
  const [isCaptcha, setIsCaptcha] = useState(false);
  const [isAccept, setIsAccept] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);

  const handleSend = async () => {
    const url = BaseURL('inquiry/create');

    const params = {
      type,
      entity: data?._id,
      message: reason,
      name,
      email,
      phone,
      preferredContactMethod: contactMethod,
      isCaptcha,
      isAccept
    };

    const getFieldName = (key = '') => {
      if (key === 'isCaptcha') return 'Captcha';
      if (key === 'isAccept') return 'Term & Conditions';
      return key;
    };

    for (let key in params) {
      if (!params[key]) {
        return toast.error(`Please fill the ${getFieldName(key)} field`);
      }
    }

    if (!validator.isEmail(email)) {
      return toast.error('Please enter valid email address format');
    }

    setIsLoading(true);

    try {
      const response = await Post(url, params);

      if (response) {
        toast.success('Successfully send');
        setReason(`Hi!, I am interested in your ${type}. please contact me.`);
        setEmail('');
        setName('');
        setPhone('');
        setIsAccept(false);
        setIsCaptcha(false);
        setContactMethod('email');
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
      setShow(false);
    }
  };
  return (
    <>
      {show && (
        <ModalSkeleton
          width={'670px'}
          show={show}
          setShow={setShow}
          header={'Email Agent'}
          headerClass={classes.header}
          modalClass={classes.body}>
          <style>{`.DropdownOptionContainer{
          text-transform:none;
        }`}</style>
          <Row className={classes.gap}>
            <Col md={12}>
              <Row className={[classes.propertyCard, 'gx-0'].join(' ')}>
                <Col md={5}>
                  <div className={classes.imgDiv}>
                    <img src={imageUrl(data?.images[0])} />
                  </div>
                </Col>
                <Col md={7} className={classes.detailInfo}>
                  {type == 'property' ? (
                    <h6 className={classes.price}>
                      {usdToOther({ amount: data?.price, ...currency })}
                      {data?.propertyPurpose?.en == 'Rent' && (
                        <span>{t('propertyCard.perMonth')}</span>
                      )}
                    </h6>
                  ) : (
                    <>
                      <h5>
                        {type == 'project'
                          ? data?.name?.[selectedLanguage]
                          : data?.title?.[selectedLanguage]}
                      </h5>
                      <h6 className={classes.price}>$ {data?.price}</h6>
                    </>
                  )}

                  <p className={classes.tagline}>{data?.title?.[selectedLanguage]}</p>
                  <div className={classes.addressWrap}>
                    <MdLocationOn fill="var(--secondary-clr)" />
                    <p>{data?.address}</p>
                  </div>

                  <div className={classes.propertyDetailWrap}>
                    <Row className={classes.iconsRow}>
                      {type == 'event' && (
                        <>
                          <Col md={6} className={classes.propertyDetail}>
                            <Tooltip text={'Type of Event'}>
                              <RiHotelFill color="var(--text-dark-gray-clr)" />
                            </Tooltip>
                            <p>{data?.eventType?.title?.[selectedLanguage]}</p>
                          </Col>
                          <Col md={6} className={classes.propertyDetail}>
                            <Tooltip text={'Date'}>
                              <TbCalendarTime color="var(--text-dark-gray-clr)" />
                            </Tooltip>
                            <p>{moment(data?.eventDate).format('ll')}</p>
                          </Col>
                        </>
                      )}
                      {type == 'project' && (
                        <>
                          <Col md={6} className={classes.propertyDetail}>
                            <IconList
                              className={classes.infoIcons}
                              Icon={BiBuildingHouse}
                              label={t('projectCard.tooltips.projectType')}
                              value={data?.projectType?.title?.[selectedLanguage]}
                            />
                          </Col>
                          <Col md={6} className={classes.propertyDetail}>
                            <IconList
                              className={classes.infoIcons}
                              Icon={AiOutlineNumber}
                              label={t('projectCard.tooltips.noOfUnits')}
                              value={data?.noOfUnits || '5'}
                            />
                          </Col>
                          <Col md={6} className={classes.propertyDetail}>
                            <IconList
                              className={classes.infoIcons}
                              Icon={TbCalendarTime}
                              label={t('projectCard.tooltips.deliveryDate')}
                              value={moment(data?.deliveryDate).format('ll')}
                            />
                          </Col>
                        </>
                      )}
                      {type == 'property' && (
                        <>
                          <Col md={6} className={classes.propertyDetail}>
                            <IconList
                              // className={classes.infoIcons}
                              Icon={BiBuildingHouse}
                              value={data?.propertyType?.title?.[selectedLanguage]}
                              label={t('propertyCard.tooltips.propertyType')}
                            />
                          </Col>
                          <Col md={6} className={classes.propertyDetail}>
                            <IconList
                              Icon={BsGrid3X3Gap}
                              value={`${m2ToFt2({ area: data?.netArea, unit: false })}`}
                              label={t('propertyCard.tooltips.area') + ` (${areaUnit})`}
                            />
                          </Col>
                          <Col md={6} className={classes.propertyDetail}>
                            <IconList
                              // className={classes.infoIcons}
                              Icon={MdOutlineHotel}
                              value={data?.noOfRooms?.[selectedLanguage]}
                              label={t('propertyCard.tooltips.rooms')}
                            />
                          </Col>
                          <Col md={6} className={classes.propertyDetail}>
                            <IconList
                              // className={classes.infoIcons}
                              Icon={MdOutlineBathtub}
                              value={`${data?.noOfBathrooms}`}
                              label={t('propertyCard.tooltips.bathrooms')}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <TextArea value={reason} setter={setReason} placeholder="Please enter a message" />
            </Col>
            <Col md={6}>
              <Input
                inputStyle={{
                  borderRadius: '5px'
                }}
                value={name}
                setValue={setName}
                placeholder={'Name*'}
              />
            </Col>
            <Col md={6}>
              <Input
                value={email}
                setValue={setEmail}
                inputStyle={{
                  borderRadius: '5px'
                }}
                placeholder={'Email*'}
                type="email"
              />
            </Col>

            <Col md={6}>
              <CustomPhoneInput value={phone} setter={setPhone} placeholder="Phone*" />
            </Col>
            <Col md={6} className={classes.contactWrap}>
              <p>Perferred Method of contact*</p>
              <div className={classes.methodWrap}>
                <Radio value={contactMethod} setValue={setContactMethod} label="Email" />
                <Radio value={contactMethod} setValue={setContactMethod} label="Phone" />
                <Radio value={contactMethod} setValue={setContactMethod} label="Whatsapp" />
              </div>
            </Col>
            <Col md={6}>
              <Button
                className={classes.btn}
                onClick={handleSend}
                label={isLoading ? 'Sending...' : 'Send'}
              />
            </Col>
            <Col md={6}>
              <Captcha setValue={setIsCaptcha} />
            </Col>
            <Col md={12}>
              <Checkbox
                label={'I accept the Terms & Conditions'}
                value={isAccept}
                setValue={setIsAccept}
              />
            </Col>
          </Row>

          {/* <div className={classes.footer}>
        <p>Don’t have a free account yet? </p>
        <p>Create your free account now</p>
      </div> */}
        </ModalSkeleton>
      )}
      {isSuccess && <SuccessModal show={isSuccess} setShow={setIsSuccess} />}
    </>
  );
};

export default EmailAgentModal;
