/* eslint-disable */

import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SideBarSkeleton from '../../../Component/SideBarSkeleton';
import classes from './AddAgentPage.module.css';
import Button from '../../../Component/Button/Button';
import { IoChevronBackOutline } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';
import { ProfileWithEditButton } from '../../../Component/ProfileWithEditButton';
import DropDown from '../../../Component/DropDown/DropDown';
import Input from '../../../Component/Input/Input';
import CustomPhoneInput from '../../../Component/CustomPhoneInput';
import TextArea from '../../../Component/TextArea';
import { toast } from 'react-toastify';
import {
  CreateFormData,
  apiHeader,
  capitalizeEachWord,
  convertArrayKeysToObject,
  formRegEx,
  formRegExReplacer,
  formatLabel,
  validateEmail
} from '../../../config/apiUrl';
import { Post } from '../../../Axios/AxiosFunctions';
import { useSelector } from 'react-redux';
import { useAddOrEditAgentMutation, useGetAgentQuery } from '../../../store/apiSlice/apiSlice';
import { useEffect } from 'react';

import {
  allLanguagesArray,
  designationArray,
  languagesOptions
} from '../../../constant/commonData';
import FormLanguages from '../../../Component/FormLanguages';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { Loader } from '../../../Component/Loader';

function AddAgentPage() {
  const navigate = useNavigate();
  const slug = useParams()?.slug;
  const accessToken = useSelector((state) => state.authReducer.accessToken);
  const languagesKeysObject = convertArrayKeysToObject(languagesOptions?.map((e) => e?.value));
  const [language, setLanguage] = useState(languagesOptions[0]);
  const { t } = useTranslation(['dashboardAgents', 'locationComp'], {
    lng: language?.value
  });

  const [formState, setFormState] = useState({
    image: null,
    agentName: languagesKeysObject,
    agentDesignation: '',
    serviceAreas: languagesKeysObject,
    languagesWeSpeak: [],
    registrationNumber: '',
    description: languagesKeysObject,
    email: '',
    whatsappNumber: '',
    phone: ''
  });

  console.log(formState, 'formState formStateformState');

  const { data, isLoading: isLoadingGet } = useGetAgentQuery(
    {
      slug
    },
    {
      skip: !slug
    }
  );

  const [addOrEditFn, { isLoading: loadingApi }] = useAddOrEditAgentMutation({});

  useEffect(() => {
    let specificAgent = data?.data;
    console.log(specificAgent, 'singleEventData singleEventData singleEventData');

    if (specificAgent) {
      setFormState({
        image: specificAgent?.photo,
        agentName: specificAgent?.agentName,
        agentDesignation: specificAgent?.agentDesignation,
        serviceAreas: specificAgent?.serviceAreas,
        languagesWeSpeak: specificAgent?.language,
        registrationNumber: specificAgent?.registrationNumber,
        description: specificAgent?.description,
        email: specificAgent?.email,
        whatsappNumber: specificAgent?.whatsapp,
        phone: specificAgent?.phoneNumber
      });
    }

    // setImage(data?.data?.image);
    // setAgentName(data?.data?.agentName);
    // setAgentDesignation(
    //   agentDesignationOptions.find((item) => item.value === data?.data?.agentDesignation)
    // );
    // setServiceAreas(data?.data?.serviceAreas);

    // setLanguagesWeSpeak(
    //   languagesWeSpeakOptions.filter((item) => data?.data?.language?.includes(item.value))
    // );
    // setRegistrationNumber(data?.data?.registrationNumber);
    // setDescription(data?.data?.description);
    // setEmail(data?.data?.email);
    // setWhatsappNumber(data?.data?.whatsapp);
    // setPhone(data?.data?.phoneNumber);
  }, [data]);

  const validateParams = () => {
    const multiLanguageParams = {
      agentName: formState?.agentName,
      serviceAreas: formState?.serviceAreas,
      description: formState?.description
    };
    const params = {
      photo: formState?.image,
      agentDesignation: formState?.agentDesignation,
      language: formState?.languagesWeSpeak,
      registrationNumber: formState?.registrationNumber,
      email: formState?.email,
      whatsapp: formState?.whatsappNumber,
      phoneNumber: formState?.phone
    };
    console.log(params, '123 321');

    for (let lang in languagesKeysObject) {
      // validate multiLanguageParams
      for (let key in multiLanguageParams) {
        if (!multiLanguageParams[key][lang]) {
          toast.error(`Please fill the ${formatLabel(key)} field in language "${lang}".`);
          return [false, null];
        }
      }

      // validate params
      for (let key in params) {
        if (!params[key]) {
          toast.error(`Please fill the ${formatLabel(key)} field.`);
          return [false, null];
        }

        // if key is a url
        if (key.includes('Link') || key.includes('link')) {
          if (typeof params[key] == 'object') {
            return [true, { ...params, ...multiLanguageParams }];
          }
          if (!validator.isURL(params[key])) {
            toast.error(`Please enter a valid ${formatLabel(key)}.`);
            return [false, null];
          }
        }
      }
    }

    return [true, { ...params, ...multiLanguageParams }];
  };
  // write a callback hell functions

  // handleSubmit
  const handleSubmit = async () => {
    const [isValid, params] = validateParams();
    if (!isValid) return;
    const formData = await CreateFormData(params);
    const response = await addOrEditFn({ data: formData, slug });
    if (response.data) {
      toast.success(`Agent ${slug ? 'updated' : 'created'} successfully`);
      navigate(-1);
    }
  };

  return (
    <SideBarSkeleton>
      {isLoadingGet ? (
        <Loader />
      ) : (
        <Container fluid className={classes.page}>
          <Row>
            <Col md={12} className={classes.mb30}>
              <Button
                leftIcon={<IoChevronBackOutline />}
                variant=""
                className={classes.backBtn}
                onClick={() => {
                  navigate('/company/agents-management');
                }}>
                Back to Agents Management
              </Button>
            </Col>
            <Col md={12}>
              <div
                className={[
                  classes.formBg,
                  ['ar', 'fa']?.includes(language?.value) ? 'rtl' : 'ltr'
                ].join(' ')}>
                <Container>
                  <Row className={classes.formRow}>
                    <Col md={6}>
                      <h3>{!!slug ? t('form.titleEdit') : t('form.titleCreate')} </h3>
                    </Col>
                    <Col md={6}>
                      <FormLanguages value={language} setter={setLanguage} />
                    </Col>
                    <Col md={12}>
                      <div className={classes.headAndDropDown}>
                        <h4>{t('form.tagline')}</h4>
                      </div>
                    </Col>
                    <Row className="d-flex justify-content-center align-items-center">
                      <Col md={10}>
                        <p>{t('form.pageDesc')}</p>
                      </Col>
                      <Col md={2}>
                        <ProfileWithEditButton
                          isEdit={true}
                          updateImage={formState.image}
                          setUpdateImage={(e) => setFormState({ ...formState, image: e })}
                        />
                      </Col>
                    </Row>
                    <Col md={12}>
                      <h4>{t('form.formTitle')}</h4>
                    </Col>
                    <Col md={6}>
                      <Input
                        label={t('form.agentName')}
                        placeholder={t('form.agentNamePlaceholder')}
                        variant="dashboard"
                        value={formState.agentName[language?.value]}
                        setValue={(e) =>
                          setFormState({
                            ...formState,
                            agentName: {
                              ...formState?.agentName,
                              [language?.value]: e
                            }
                          })
                        }
                      />
                    </Col>
                    <Col md={6}>
                      <DropDown
                        label={t('form.agentDesignation')}
                        indicatorColor="var(--dashboard-text-gray-clr)"
                        placeholder={t('form.agentDesignationPlaceholder')}
                        variant="dashboard"
                        value={
                          formState?.agentDesignation?.length > 0 ? formState?.agentDesignation : []
                        }
                        setValue={(e) => {
                          setFormState({
                            ...formState,
                            agentDesignation: e
                          });
                        }}
                        options={designationArray}
                        getOptionLabel={(e) => {
                          return e?.[language?.value];
                        }}
                        getOptionValue={(e) => {
                          return e?.[language?.value];
                        }}
                        isMulti={true}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        label={t('form.serviceAreas')}
                        placeholder={t('form.serviceAreasPlaceholder')}
                        variant="dashboard"
                        value={formState.serviceAreas[language?.value]}
                        setValue={(e) => {
                          setFormState({
                            ...formState,
                            serviceAreas: {
                              ...formState?.serviceAreas,
                              [language?.value]: e
                            }
                          });
                        }}
                      />
                    </Col>
                    {/* Ram30 k bolne p laga rha hun */}
                    <Col md={6}>
                      <DropDown
                        isMulti
                        label={'Languages We Speak'}
                        indicatorColor="var(--dashboard-text-gray-clr)"
                        placeholder={'Select Languages We Speak'}
                        variant="dashboard"
                        value={
                          formState?.languagesWeSpeak?.length > 0 ? formState?.languagesWeSpeak : []
                        }
                        setValue={(e) => {
                          setFormState({
                            ...formState,
                            languagesWeSpeak: e
                          });
                        }}
                        options={allLanguagesArray}
                        getOptionLabel={(e) => {
                          return e?.[language?.value];
                        }}
                        getOptionValue={(e) => {
                          return e?.[language?.value];
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        label={t('form.registrationNumber')}
                        placeholder={t('form.registrationNumber')}
                        variant="dashboard"
                        value={formState.registrationNumber}
                        setValue={(e) => {
                          setFormState({
                            ...formState,
                            registrationNumber: e
                          });
                        }}
                        type={'number'}
                      />
                    </Col>
                    <Col md={12}>
                      <TextArea
                        label={t('form.description')}
                        placeholder={t('form.descriptionPlaceholder')}
                        variant="dashboard"
                        value={formState.description[language?.value]}
                        setValue={(e) => {
                          setFormState({
                            ...formState,
                            description: {
                              ...formState?.description,
                              [language?.value]: e
                            }
                          });
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        label={t('form.email')}
                        placeholder={t('form.emailPlaceholder')}
                        variant="dashboard"
                        disabled={slug ? true : false}
                        value={formState.email}
                        setValue={(e) => {
                          setFormState({
                            ...formState,
                            email: e
                          });
                        }}
                        autocomplete="new-password"
                      />
                    </Col>
                    {/* <Col md={6}>
                    <Input
                      label={'Password'}
                      placeholder={'Password'}
                      variant="dashboard"
                      value={slug ? '**********' : password}
                      disabled={slug ? true : false}
                      setValue={setPassword}
                      type={'password'}
                      autocomplete="new-password"
                    />
                  </Col> */}
                    <Col md={6}>
                      <CustomPhoneInput
                        label={t('form.phone')}
                        placeholder={t('form.phonePlaceholder')}
                        variant="dashboard"
                        value={formState.phone}
                        setter={(e) => {
                          setFormState({
                            ...formState,
                            phone: e
                          });
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <CustomPhoneInput
                        label={t('form.whatsAppNumber')}
                        placeholder={t('form.whatsAppNumberPlaceholder')}
                        variant="dashboard"
                        value={formState.whatsappNumber}
                        setter={(e) => {
                          setFormState({
                            ...formState,
                            whatsappNumber: e
                          });
                        }}
                      />
                    </Col>

                    <Col md={12} className={classes.btnDiv}>
                      <Button className={classes.btn} disabled={loadingApi}>
                        {t('form.cancelBtn')}
                      </Button>
                      <Button className={classes.btn} onClick={handleSubmit} disabled={loadingApi}>
                        {loadingApi ? t('form.saveBtnLoading') : t('form.saveBtn')}
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </SideBarSkeleton>
  );
}

export default AddAgentPage;

const _d = `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.`;

const agentDesignationOptions = [
  {
    label: 'Real Estate',
    value: 'real_estate',
    _id: 'real_estate'
  },
  {
    label: 'Agent',
    value: 'agent',
    _id: 'agent'
  }
];

const languagesWeSpeakOptions = [
  {
    label: 'English',
    value: 'english',
    _id: 'english'
  },
  {
    label: 'French',
    value: 'french',
    _id: 'french'
  }
];
