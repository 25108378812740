/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import classes from './properties.module.css';
import { BsGrid3X3GapFill, BsListStars } from 'react-icons/bs';
import { IoBookmarkOutline } from 'react-icons/io5';
import Header from '../../Component/Header';
import { Col, Container, Row } from 'react-bootstrap';
import Tabs from '../../Component/Tabs';
import PropertyCard from '../../Component/PropertyCard';
import BannerImageAd from '../../Component/BannerImageAd';
import { horizontalBanner, verticalBanner } from '../../constant/imagePath';
import { PaginationComponent } from '../../Component/Pagination/Pagination';
import Footer from '../../Component/Footer';
import PropertyHorizontalCard from '../../Component/PropertyHorizontalCard/PropertyHorizontalCard';
import { propertyPurposeOptions } from '../../constant/commonData';
import { isMobileViewHook } from '../../CustomHooks/isMobileViewHook';
import { FaMapMarkedAlt } from 'react-icons/fa';
import MapMain from '../../Component/maps/MapMain';
import BuyRentCommercialFilters from '../../Component/BuyRentCommercialFilters';
import Tooltip from '../../Component/Tooltip';
import EmailAgentModal from '../../modals/EmailAgentModal';
import { useSelector } from 'react-redux';
import {
  capitalizeFirstLetter,
  imageUrl,
  recordsLimit,
  showTabsWhenUserIsLogin
} from '../../config/apiUrl';
import { useGetPublicPropertiesMutation } from '../../store/apiSlice/apiSlice';
import { Loader } from '../../Component/Loader';
import { useLocation } from 'react-router-dom';
import NoData from '../../Component/NoData/NoData';
import { useTranslation } from 'react-i18next';
/*eslint-disable*/
function Properties() {
  const routeName = useLocation()?.pathname?.split('/')[1];
  const { allBanners } = useSelector((state) => state.commonReducer);

  const verticalBanners = allBanners?.filter(
    (item) => item?.page === routeName && item?.bannerType == 'vertical'
  );

  const horizontalBanners = allBanners?.filter(
    (item) => item?.page === routeName && item?.bannerType == 'horizontal'
  );

  const { t } = useTranslation('buyRentProperties');
  const pathname = useLocation()?.pathname?.split('/')[1];
  const [isMobile, setIsMobile] = useState(false);
  // const [sortType, setSortType] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState('');
  const { isLogin } = useSelector((state) => state.authReducer);
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const [selectedItem, setSelectedItem] = useState(null);

  const [page, setPage] = useState(1);

  const [filters, setFilters] = useState({
    search: null,
    terms: null,
    // propertyPurpose: null,
    maxPropertyArea: null,
    minPropertyArea: null,
    minPropertyAge: null,
    maxPropertyAge: null,
    parkingSpace: null,
    // maxParkingSpace: null,
    noOfRooms: null,
    noOfBathrooms: null,
    floorLevel: null,
    propertyStatus: null,
    minPrice: null,
    maxPrice: null,
    furniture: null,
    nearby: null,
    country: null,
    lng: null,
    lat: null,
    lan: selectedLanguage,
    propertyType: 'residential'
  });
  const [getProperties, { isLoading, data }] = useGetPublicPropertiesMutation();

  const handleGetProperties = async (fil, pg = page) => {
    await getProperties({
      page: pg,
      limit: recordsLimit,
      data: {
        lan: selectedLanguage,
        propertyPurpose: propertyPurposeOptions?.find(
          (item) => item['en'] == capitalizeFirstLetter(pathname)
        )?.[selectedLanguage],
        ...(fil ? fil : {})
      }
    });
    // if (res?.data) {
    //   setPropertyData(res?.data?.data);
    // }
  };

  const saveSearchTab = showTabsWhenUserIsLogin(isLogin, [
    {
      icon: (
        <Tooltip text={t('tabs.saveSearch')}>
          <IoBookmarkOutline size={20} color="var(--white-clr)" />
        </Tooltip>
      ),
      text: t('tabs.saveSearch'),
      value: 'save search',
      id: '4'
    }
  ]);

  const tabsData = isMobile
    ? [
        {
          icon: (
            <Tooltip text={'Map'}>
              <FaMapMarkedAlt size={22} color="var(--white-clr)" />
            </Tooltip>
          ),
          value: 'map',
          text: t('tabs.map'),
          id: '3'
        },
        ...saveSearchTab
      ]
    : [
        {
          icon: <BsGrid3X3GapFill size={20} color="var(--white-clr)" />,
          text: t('tabs.grid'),
          value: 'grid',
          id: '1'
        },
        {
          icon: <BsListStars size={22} color="var(--white-clr)" />,
          text: t('tabs.list'),
          value: 'list',
          id: '2'
        },
        {
          icon: <FaMapMarkedAlt size={22} color="var(--white-clr)" />,
          value: 'map',
          text: t('tabs.map'),
          id: '3'
        },
        ...saveSearchTab
      ];
  const [selectedTab, setSelectedTab] = useState(tabsData[1]);
  useEffect(() => {
    handleGetProperties();
    isMobileViewHook(setIsMobile, 992);
  }, []);
  useEffect(() => {
    if (isMobile) {
      setSelectedTab({ text: t('tabs.list'), value: 'list', id: '2' });
    }
  }, [isMobile]);

  return (
    <>
      <div className={classes.page}>
        <Header containerClass="" />
        <div className={classes.filtersDiv}>
          <BuyRentCommercialFilters
            containerClass=""
            filtersWrapperClass={classes.filters}
            rowClass={classes.filtersRow}
            filters={filters}
            setFilters={setFilters}
            t={t}
            onSearch={() => {
              // handleGetProperties(filters);
            }}
          />
        </div>
        <div className={classes.tabsDiv}>
          <Container>
            <p>
              {data?.totalRecords ?? 0} {t('title')}
            </p>
            <div>
              <Tabs
                selectedColor="var(--secondary-clr)"
                bgColor="var(--primary-clr)"
                separatorColor="var(--white-clr)"
                tabClass={classes.tab}
                setValue={(e) => {
                  if (e?.value == 'save search') {
                    return;
                  }
                  setSelectedTab(e);
                }}
                value={selectedTab}
                tabsData={tabsData}
              />
            </div>
          </Container>
        </div>

        <Container>
          <Row className={classes.mainRow}>
            <Col
              md={selectedTab?.value == 'map' ? 12 : 10}
              className={[
                selectedTab?.value !== 'map' && 'verticalBannerAndContentWidth',
                classes.col1
              ].join(' ')}>
              {isLoading ? (
                <Loader />
              ) : (
                <Row
                  className={[classes.innerRow, selectedTab?.value == 'map' && classes.h100].join(
                    ' '
                  )}>
                  {/* {['list', 'grid'].includes(selectedTab?.value) && (
                  <Col md={12}>
                    <div className={classes.sortDropDown}>
                      <span>{t('sortBy')}</span>
                      <DropDown
                        setValue={setSortType}
                        value={sortType}
                        placeholder={t('sortByPlaceholder')}
                        classNamePrefix={'smDropdown'}
                      />
                    </div>
                  </Col>
                )} */}
                  {selectedTab?.value == 'map' ? (
                    <>
                      <Col
                        xl={{ span: 3, order: 1 }}
                        xs={{ span: 12, order: 2 }}
                        className={`scroller ${classes.mapLisingCards}`}>
                        <Row className={classes.mapList}>
                          {data?.data?.map((e, key) => (
                            <Col xl={12} lg={4} sm={6} key={key}>
                              <PropertyCard
                                feature
                                data={e}
                                onEmailClick={() => {
                                  setIsModalOpen('email');
                                  setSelectedItem(e);
                                }}
                                refetch={handleGetProperties}
                                // setPropertyData={setPropertyData}
                              />
                            </Col>
                          ))}
                        </Row>
                      </Col>
                      <Col xl={{ span: 9, order: 2 }} xs={{ span: 12, order: 1 }}>
                        <MapMain
                          className={classes.mainMap}
                          mapClass={classes?.mapClass}
                          zoom={13}
                        />
                      </Col>
                    </>
                  ) : data?.data?.length == 0 ? (
                    <NoData text={t('noDataText')} />
                  ) : (
                    data?.data?.map((item, key) => (
                      <>
                        <Col
                          md={selectedTab?.value == 'list' ? 12 : 6}
                          lg={selectedTab?.value == 'list' ? 12 : 4}
                          sm={12}
                          key={key}>
                          {selectedTab?.value == 'list' ? (
                            <PropertyHorizontalCard
                              data={item}
                              index={key}
                              className="mb-0"
                              onEmailClick={() => {
                                setIsModalOpen('email');
                                setSelectedItem(item);
                              }}
                              refetch={handleGetProperties}
                            />
                          ) : (
                            <PropertyCard
                              feature
                              data={item}
                              index={key}
                              onEmailClick={() => {
                                setIsModalOpen('email');
                                setSelectedItem(item);
                              }}
                              refetch={handleGetProperties}

                              // setPropertyData={setPropertyData}
                            />
                          )}
                        </Col>
                        {(selectedTab?.value == 'list'
                          ? (key + 1) % 7 === 0
                          : (key + 1) % 9 === 0 &&
                            horizontalBanners[(page - 1) % horizontalBanners?.length]?.image) && (
                          <Col md={12}>
                            <div className={classes?.bannerAdContainer}>
                              <BannerImageAd
                                className={classes.horizontalBanner}
                                image={imageUrl(
                                  horizontalBanners[(page - 1) % horizontalBanners?.length]?.image
                                )}
                              />
                            </div>
                          </Col>
                        )}
                      </>
                    ))
                  )}
                  {['list', 'grid'].includes(selectedTab?.value) && data?.data?.length > 0 && (
                    <Col md={12} className={[classes.pagination, 'jCenter'].join(' ')}>
                      <PaginationComponent
                        totalPages={Math.ceil(data?.totalRecords / recordsLimit)}
                        currentPage={page}
                        setCurrentPage={(e) => {
                          setPage(e);
                          handleGetProperties(filters, e);
                        }}
                      />
                    </Col>
                  )}
                </Row>
              )}
            </Col>

            <Col
              lg={2}
              className={[
                selectedTab?.value == 'map' && 'd-none',
                'verticalBannerWidth',
                classes.col2
              ].join(' ')}>
              <Row className={`${classes.bannerRow}`} data-margin={selectedTab?.value == 'map'}>
                {verticalBanners?.map((item, key) => (
                  <Col md={12} className={key == 1 && classes?.bannerCol}>
                    <BannerImageAd
                      image={imageUrl(item?.image)}
                      className={classes.verticalBanner}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>

        <EmailAgentModal
          show={isModalOpen == 'email'}
          setShow={setIsModalOpen}
          data={selectedItem}
          type={'property'}
        />
      </div>
      <Footer />
    </>
  );
}

export default Properties;
