import React from "react";
import classes from "./TextArea.module.css";
import PropTypes from "prop-types";

export default function TextArea({
  value,
  setValue,
  label,
  placeholder='Enter text',
  customStyle,
  labelStyle,
  rows = 5,
  className = "",
  disabled,
  variant=''
}) {
  return (
    <div className={classes.textAreaBox} data-variant={variant}>
      {label && <label style={{ ...labelStyle }} className={classes.labelDisabled}>{label}</label>}
      <textarea
        placeholder={placeholder}
        value={value}
        style={{ ...customStyle }}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        disabled={disabled}
        rows={rows}
        className={className}
      />
    </div>
  );
}
TextArea.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  customStyle: PropTypes.object,
  labelStyle: PropTypes.object,
};
