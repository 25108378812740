/* eslint-disable */

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Collapse, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GiHamburgerMenu } from 'react-icons/gi';
import { HiOutlineChevronDown } from 'react-icons/hi';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CURRENCY_UNITS, areaUnitOptions, languagesOptions } from '../../constant/commonData';
import { Logo } from '../../constant/imagePath';
import { convertCurrency } from '../../services/currency-converter';
import { setAreaUnit, setCurrencyUnit } from '../../store/converter/converterSlice';
import Button from '../Button/Button';
import { default as Style, default as classes } from './MobileHeader.module.css';

export const MobileHeader = ({
  customStyle,
  containerClass,
  isScroll,
  onLogout,
  changeLanguage
}) => {
  const navigate = useNavigate();
  const { isLogin, user } = useSelector((state) => state?.authReducer);
  const { areaUnit, currency } = useSelector((state) => state?.converterReducer);
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);

  const dispatch = useDispatch();
  const { t } = useTranslation('webCommon');

  // current page url path name
  const currentPage = window.location.pathname;
  const [isOpen, setIsOpen] = useState(false);
  const [showArea, setShowArea] = useState(false);
  const [showCurrency, setShowCurrency] = useState(false);
  const [showLanguage, setShowLanguage] = useState(false);
  const [loadingCurrency, setLoadingCurrency] = useState(false);

  const toggleDrawer = () => setIsOpen((prevState) => !prevState);
  const toggleLang = () => setShowLanguage((prevState) => !prevState);
  const toggleCurrency = () => setShowCurrency((prev) => !prev);
  const toggleArea = () => setShowArea((prev) => !prev);

  const onChangeCurrency = async (unit = '', symbol = '') => {
    toggleCurrency();

    if (unit === 'USD') {
      dispatch(setCurrencyUnit({ unit, value: 1, symbol }));
      return;
    }

    let value = null;
    setLoadingCurrency(true);

    try {
      value = await convertCurrency({ unit });

      if (!value) throw new Error('');

      dispatch(setCurrencyUnit({ unit, value, symbol }));
    } catch (error) {
      toast.error('Could not convert currency! Please try after sometime.');
    } finally {
      setLoadingCurrency(false);
    }
  };

  const RenderListItem = ({ icon, text, customClass, path, href, onClick }) => {
    return (
      <div
        className={[classes.listItem, customClass].join(' ')}
        onClick={() => {
          if (typeof onClick == 'function') {
            onClick();
            return;
          }
          if (path.toLowerCase() == 'logout') {
            onLogout();
          } else if (path.toLowerCase() == 'login') {
            navigate('/login');
          } else {
            navigate(path, { state: { href } });
          }
        }}>
        {icon}
        <span className={classes.listItemText}>{text}</span>
      </div>
    );
  };

  const renderLanguage = () => (
    <div>
      <Button
        variant="transparent"
        rightIcon={<HiOutlineChevronDown size={20} color="var(--secondary-clr)" />}
        onClick={toggleLang}
        className={[classes.unitBtn, 't-t-c'].join(' ')}>
        {selectedLanguage}
      </Button>

      <Collapse in={showLanguage}>
        <ul className={[Style?.languagesOptions, 'ltr'].join(' ')}>
          {languagesOptions?.map((item, index) => {
            return (
              <li
                key={index}
                data-selected={selectedLanguage == item?.value}
                onClick={() => {
                  changeLanguage(item?.value);
                  setShowLanguage(false);
                }}>
                <img src={item?.flag} /> {item?.label}
              </li>
            );
          })}
        </ul>
      </Collapse>
    </div>
  );

  const renderArea = () => (
    <div>
      <Button
        variant="transparent"
        className={classes.unitBtn}
        rightIcon={<HiOutlineChevronDown size={20} color="var(--secondary-clr)" />}
        onClick={toggleArea}>
        {t('webHeader.areaUnit')} ({areaUnit})
      </Button>
      <Collapse in={showArea}>
        <ul className={[Style?.languagesOptions, 'ltr'].join(' ')}>
          {areaUnitOptions?.map((item, index) => {
            return (
              <li
                key={index}
                data-selected={areaUnit == item?.value}
                onClick={() => {
                  dispatch(setAreaUnit(item?.value));
                  toggleArea();
                }}>
                {item?.label}
              </li>
            );
          })}
        </ul>
      </Collapse>
    </div>
  );

  const renderCurrency = () => (
    <div>
      <Button
        variant="transparent"
        className={classes.unitBtn}
        onClick={toggleCurrency}
        disabled={loadingCurrency}
        rightIcon={<HiOutlineChevronDown size={20} color="var(--secondary-clr)" />}>
        {t('webHeader.currency')} ({currency.unit}){' '}
        {loadingCurrency && <Spinner animation="border" size="sm" />}
      </Button>
      <Collapse in={showCurrency}>
        <ul className={[Style?.languagesOptions, 'ltr'].join(' ')}>
          {CURRENCY_UNITS?.map((item, index) => {
            return (
              <li
                key={index}
                data-selected={currency.unit == item?.value}
                onClick={() => onChangeCurrency(item?.value, item.symbol)}>
                {item?.label} ({item.symbol})
              </li>
            );
          })}
        </ul>
      </Collapse>
    </div>
  );

  return (
    <>
      <div
        className={[classes.headerMainDiv, isScroll && classes.afterScroll].join(' ')}
        id={'navMobileHeader'}>
        <div
          className={[classes.header, containerClass, 'mx-3'].join(' ')}
          style={{ ...customStyle }}>
          <div
            className={classes.imageContainer}
            onClick={() => {
              navigate('/');
            }}>
            {/* <img src={Logo} alt="" /> */}
            <h3>Demp</h3>
          </div>
          <GiHamburgerMenu
            className={classes.hamburger}
            onClick={() => {
              toggleDrawer();
            }}
          />
        </div>
        <Drawer open={isOpen} onClose={toggleDrawer} direction="right" className="bla bla bla">
          <div className={classes.drawerContainer}>
            <div className={classes.drawerUserSection}>
              <>
                <div
                  className={classes.drawerImg}
                  onClick={() => {
                    navigate('/');
                  }}>
                  {/* <img src={Logo} alt="" /> */}
                  <h3>Demo</h3>
                </div>
              </>
            </div>
            <div className={classes.drawerList}>
              <>
                <RenderListItem
                  // icon={<IoHome />}
                  text={'Buy'}
                  customClass={currentPage == '/buy' && classes.activeItem}
                  path={'/buy'}
                />
                <RenderListItem
                  // icon={<IoHome />}
                  text={'Rent'}
                  customClass={currentPage == '/rent' && classes.activeItem}
                  path={'/rent'}
                />
                {/* <RenderListItem
                  // icon={<IoHome />}
                  text={'Commercial'}
                  customClass={currentPage == '/commercial' && classes.activeItem}
                  path={'/commercial'}
                /> */}

                <RenderListItem
                  // icon={<MdInfo />}
                  text={'Projects'}
                  customClass={currentPage == '/projects' && classes.activeItem}
                  path={'/projects'}
                />
                <RenderListItem
                  // icon={<AiFillContacts />}
                  text={'Events'}
                  customClass={currentPage == '/events' && classes.activeItem}
                  path={'/events'}
                />
                <RenderListItem
                  // icon={<IoHome />}
                  text={'Property Request'}
                  customClass={currentPage == '/property-request' && classes.activeItem}
                  path={'/property-request'}
                />
                <RenderListItem
                  // icon={<AiFillContacts />}
                  text={'Agents'}
                  customClass={currentPage == '/agents' && classes.activeItem}
                  path={'/agents'}
                />
                <hr
                  style={{
                    width: '100%',
                    marginTop: '5px',
                    marginBottom: '5px'
                  }}
                />

                {renderLanguage()}
                {renderArea()}
                {renderCurrency()}

                {isLogin && (
                  <>
                    <hr
                      style={{
                        width: '100%',
                        marginTop: '5px',
                        marginBottom: '5px'
                      }}
                    />
                    <RenderListItem
                      text={'Dashboard'}
                      customClass={currentPage == '/dashboard' && classes.activeItem}
                      path={user?.role == 'user' ? '/account-settings' : `${user?.role}/dashboard`}
                    />
                    <RenderListItem
                      customClass={currentPage == '/notifications' && classes.activeItem}
                      text={'Notifications'}
                      path={'/notifications'}
                    />
                    {/* {user?.role == 'user' && (
                      <>
                        <RenderListItem
                          customClass={currentPage == '/saved-properties' && classes.activeItem}
                          text={'Favorite List'}
                          path={'/saved-properties'}
                        />
                        <RenderListItem
                          customClass={currentPage == '/compare-list' && classes.activeItem}
                          text={'Compare List'}
                          path={'/compare-list'}
                        />
                        <RenderListItem
                          customClass={currentPage == '/saved-searches' && classes.activeItem}
                          text={'Saved Search'}
                          path={'/saved-searches'}
                        />
                      </>
                    )} */}
                  </>
                )}

                <hr
                  style={{
                    width: '100%',
                    marginBlock: '5px'
                  }}
                />
                {isLogin ? (
                  <>
                    <RenderListItem text={'logout'} path={'logout'} />
                  </>
                ) : (
                  <>
                    <RenderListItem text={'Login'} path={'login'} />
                  </>
                )}
              </>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
};

MobileHeader.propTypes = {
  customStyle: PropTypes.object
};

MobileHeader.defaulProps = {};
