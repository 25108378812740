import React from "react";
import classes from "./Pagination.module.css";
import Pagination from "@mui/material/Pagination";
import "./pagination.css";

export const PaginationComponent = ({
  totalPages,
  currentPage,
  setCurrentPage,
  className,
}) => {
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  return (
    <div
      className={[classes.paginationDiv, `${className && className}`].join(" ")}
    >
      <style>{`
      .MuiPagination-ul li .Mui-selected{
        background-color:var(--secondary-clr);
        color:var(--white-clr) !important;
      }
      .MuiPagination-ul li:not(.Mui-selected) button:hover, .MuiPagination-ul li:not(.Mui-selected) button:focus{
        background-color:var(--secondary-clr-hover) !important;
        color:var(--white-clr) !important;
      }
      `}</style>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handleChange}
        className={[classes.pageItem,'ltr'].join(" ")}
      />
    </div>
  );
};
