/* eslint-disable */
import React from 'react';
import classes from './StatsCard.module.css';
// import cardImage1 from "../../assets/images/card-image1.png";
// import cardImage2 from "../../assets/images/card-image2.png";
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
const StatsCard = ({ data, isShowRange = true }) => {
  const navigate = useNavigate();

  return (
    <div
      className={classes.statWrap}
      style={{
        backgroundColor: data?.background,
        ...(!isShowRange && {
          backgroundImage: `url(${data?.backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        })
      }}>
      <div className={classes.contentWrap}>
        <div>
          <div className={classes.content}>
            <span className={classes.current}>{data?.current}</span>
            {isShowRange && data?.label && <span className={classes.total}>{data?.label}</span>}
          </div>
          {data?.title && <p>{data?.title}</p>}
        </div>
        <div>
          <ReactSVG className={classes.icon} src={data?.icon} />
        </div>
      </div>
      {/* <div className={classes.bar}>
          <div className={classes.barWrap}>
            <div
              className={classes.innerBar}
              style={{
                backgroundColor: data?.barColor,
                width: `${Math.ceil((data?.current / data?.total) * 100)}%`
              }}></div>
          </div>
          <span>{Math.ceil((data?.current / data?.total) * 100)}%</span>
        </div> */}
      {isShowRange && (
        <>
          {data?.isBar1Visible && (
            <div>
              <span className={classes?.barTitleSm}>{data?.barTitle1}</span>
              <div className={classes.barWrap}>
                <div
                  className={classes.bar}
                  style={
                    data?.barBgColor && {
                      backgroundColor: data?.barBgColor
                    }
                  }>
                  <div
                    className={classes.innerBar}
                    style={{
                      backgroundColor: data?.barColor,
                      width: `${data?.premium}%`
                    }}></div>
                </div>
                <span>{data?.premium}%</span>
              </div>
            </div>
          )}

          {data?.isBar2Visible && (
            <div>
              <span className={classes?.barTitleSm}>{data?.barTitle2}</span>

              <div className={classes.barWrap}>
                <div className={classes.bar}>
                  <div
                    className={classes.innerBar}
                    style={{
                      backgroundColor: data?.barColor,
                      width: `${data?.featured}%`
                    }}></div>
                </div>
                <span>{data?.featured}%</span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default StatsCard;
