import React, { useState } from 'react';
import classes from './SideBar.module.css';
// import { AiFillLock } from 'react-icons/ai';
// import { BiNews } from 'react-icons/bi';
import { TbLogout } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signOutRequest } from '../../store/auth/authSlice';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { decideSidebarOptions, logoutText } from '../../constant/commonData';

const RenderItem = ({ icon, title, subMenu = [], path }) => {
  const active = useLocation()?.pathname;
  const [subnav, setSubnav] = useState(false);
  const subActive = subMenu.find((item) => item?.path == active);
  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <Link
        className={[
          classes?.listItemContainer,
          path == active && classes?.active,
          subActive && classes?.subActive,
          subnav && classes?.marginZero
        ].join(' ')}
        to={path}>
        {icon}
        <span>{title}</span>
        {subMenu?.length > 0 &&
          (subnav ? (
            <RiArrowUpSFill
              onClick={() => {
                if (subMenu?.length > 0) {
                  showSubnav(!subnav);
                }
              }}
              size={20}
              className={classes?.dropDownIcon}
            />
          ) : (
            <RiArrowDownSFill
              onClick={() => {
                if (subMenu?.length > 0) {
                  showSubnav(!subnav);
                }
              }}
              size={20}
              className={classes?.dropDownIcon}
            />
          ))}
      </Link>
      {subnav &&
        subMenu.map((item, index) => {
          return (
            <Link
              className={[
                classes?.innerItemContainer,
                item?.path == active && classes?.active
              ].join(' ')}
              key={index}
              to={item?.path}>
              {item?.icon}
              <span>{item.title}</span>
            </Link>
          );
        })}
    </>
  );
};

const SideBar = () => {
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state?.authReducer);

  const HandleSubmitSignOut = () => {
    dispatch(signOutRequest());
    navigate('/');
  };
  const userRole = user?.role;
  const sidebarOptionAccToRole = decideSidebarOptions(userRole, user);

  return (
    <div className={classes?.mainContainer}>
      <div className={classes.itemsContainer}>
        {sidebarOptionAccToRole?.map((item, key) => (
          <RenderItem
            icon={item?.icon}
            title={item?.title?.[selectedLanguage]}
            path={item?.path}
            key={`sidebar-option-${key}`}
            subMenu={item?.subMenu ?? undefined}
          />
        ))}

        {/* {userRole == 'user' && (
          <>
            <RenderItem
              icon={<BiNews size={20} />}
              title={'Following agents'}
              path={'/following-agents'}
            />
            <RenderItem
              icon={<BiNews size={20} />}
              title={'Following Companies'}
              path={'/following-companies'}
            />
            <RenderItem
              icon={<AiFillLock size={20} />}
              title={'Wishlist Properties'}
              path={'/wishlist-properties'}
            />
          </>
        )} */}

        <div
          className={[classes?.listItemContainer].join(' ')}
          onClick={() => {
            HandleSubmitSignOut();
          }}>
          <TbLogout size={20} color={'var(--sidebar-text-clr)'} />
          <span>{logoutText?.[selectedLanguage]}</span>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
