/*eslint-disable*/

import React from 'react';
import styled from 'styled-components';
import classes from './PackageCard.module.css';
import Button from '../Button/Button';
import { HiOutlineCheck } from 'react-icons/hi';
import {
  PackageBasicIcon,
  PackageLiteIcon,
  PackagePlusIcon,
  PackageProIcon
} from '../../constant/imagePath';
import { useTranslation } from 'react-i18next';
import { usdToOther } from '../../utils/helpers';
import { useSelector } from 'react-redux';

function PackageCard({
  packageType = '',
  status = '',
  disablePackage = {},
  color = '#A4C235',
  data = {
    packageType: 'basic',
    tagline: 'Use it for free and upgrade as you grow',
    price: '12.99',
    features: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vel molestie massa.',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vel molestie massa.',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vel molestie massa.',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vel molestie massa.',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vel molestie massa.'
    ]
  },
  selected = false,
  onRegister = () => null
}) {
  const { t } = useTranslation('webCommon');
  const { currency } = useSelector((state) => state.converterReducer);

  const d = (e) => t(`packageCard.${e}`);
  const HeaderDiv = styled.div`
    background-color: ${color};
    padding-bottom: 10px;
    height: 127px;
    & p {
      color: var(--white-clr);
      margin-bottom: 0px;
      padding-inline: 25px;
    }
  `;
  const BottomDiv = styled.div`
    background-color: ${color};
    height: 11px;
    width: 100%;
  `;
  const decideIcon =
    (data?.packageType == 'basic' && PackageBasicIcon) ||
    (data?.packageType == 'lite' && PackageLiteIcon) ||
    (data?.packageType == 'pro' && PackageProIcon) ||
    (data?.packageType == 'plus' && PackagePlusIcon);

  const getFeatures = ({ currency = {} }) => [
    { title: `${d('agents')} ${d('allowed')}`, value: data?.noOfAgents },
    { title: `${d('events')} ${d('allowed')}`, value: data?.noOfEvents },
    { title: `${d('projects')} ${d('allowed')}`, value: data?.noOfProjects },
    { title: `${d('properties')} ${d('allowed')}`, value: data?.noOfProperties },
    {
      title: `${d('propertyRequests')} ${d('allowed')}`,
      value: data?.receivePropertyRequests ? '' : 'No'
    },
    {
      title: `${d('forSixMonths')}`,
      value: usdToOther({ amount: data?.sixMonthsSubscription, ...currency })
    },
    {
      title: `${d('forThreeMonths')}`,
      value: usdToOther({ amount: data?.threeMonthsSubscription, ...currency })
    },
    {
      title: `${d('forOneYear')}`,
      value: usdToOther({ amount: data?.yearlySubscription, ...currency })
    }
  ];

  return (
    <div className={classes.card}>
      <HeaderDiv>
        <div className={classes.topBorder}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className={classes.headerContent}>
          <h3>{data?.packageType}</h3>
          <div className={classes.iconDiv}>
            <img src={decideIcon} />
          </div>
        </div>
        <p>{data?.tagline}</p>
      </HeaderDiv>
      <div className={classes.cardContent}>
        <div className={classes.pricingAndPeriod}>
          <h3>
            <span>{currency.symbol}</span>
            {usdToOther({ amount: data?.monthlySubscription, ...currency, symbol: '' })}
          </h3>
          <p>{d('perMonth')}</p>
        </div>
        <ul>
          {getFeatures({ currency })?.map((item, key) => (
            <li key={key}>
              <HiOutlineCheck />
              <span>{item?.value}</span>
              <span>{item?.title}</span>
            </li>
          ))}
        </ul>
        <Button
          className={[classes.btn, selected ? classes.btnSelected : ''].join(' ')}
          onClick={onRegister}
          disabled={disablePackage[data?.packageType]}>
          {data?.packageType === packageType ? status : d('registerNow')}
        </Button>
      </div>
      <BottomDiv />
    </div>
  );
}

export default PackageCard;
