import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SideBarSkeleton from '../../../Component/SideBarSkeleton';
import classes from './ProfileSettings.module.css';
import Button from '../../../Component/Button/Button';
import { ProfileWithEditButton } from '../../../Component/ProfileWithEditButton';
import Input from '../../../Component/Input/Input';
import DropDown from '../../../Component/DropDown/DropDown';
import TextArea from '../../../Component/TextArea';
// import MapMain from '../../../Component/maps/MapMain';
import CustomPhoneInput from '../../../Component/CustomPhoneInput';
import ChangePasswordModal from '../../../modals/ChangePasswordModal';
import { useUpdateProfileMutation } from '../../../store/apiSlice/apiSlice';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../../store/auth/authSlice';
import {
  allLanguagesArray,
  companyTypeOptions,
  languagesKeysObject,
  languagesOptions
} from '../../../constant/commonData';
import LocationComponent from '../../../Component/LocationComponent';
import {
  CreateFormData,
  capitalizeFirstLetter,
  formRegEx,
  formRegExReplacer
} from '../../../config/apiUrl';
import { useEffect } from 'react';
import FormLanguages from '../../../Component/FormLanguages';
import { useTranslation } from 'react-i18next';

function ProfileSettings() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.authReducer);
  console.log({ user });
  const [language, setLanguage] = useState(languagesOptions[0]);
  const { t } = useTranslation('dashboardCompanyProfile', {
    lng: language?.value
  });

  const [profileData, setProfileData] = useState({
    photo: user?.photo,
    companyName: user?.companyName ?? languagesKeysObject,
    companyType: user?.companyType,
    serviceAreas: user?.serviceAreas ?? languagesKeysObject,
    language: user?.language,
    // noOfAgents: '',
    registrationNumber: user?.registrationNumber,
    description: user?.description ?? languagesKeysObject,
    // province: user?.province,
    // town: '',
    // neighborhood: '',
    // location: '',
    phoneNumber: user?.phoneNumber,
    whatsapp: user?.whatsapp
  });

  const [locationState, setLocationState] = useState({
    province: user?.province,
    town: user?.town,
    neighbourhood: user?.neighborhood,
    address: user?.address,
    lat: user?.location?.coordinates[1],
    lng: user?.location?.coordinates[0]
  });
  const [showModal, setShowModal] = useState('');
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();

  const updateField = (field, val) => {
    setProfileData((prev) => ({ ...prev, [field]: val }));
  };

  const updateProfileHandler = async () => {
    const multiLangFields = {
      companyName: profileData?.companyName,
      serviceAreas: profileData?.serviceAreas,
      description: profileData?.description
    };

    for (let key in multiLangFields) {
      for (let lang in languagesKeysObject) {
        if (!multiLangFields?.[key]?.[lang]) {
          return toast.error(
            `Please fill the ${capitalizeFirstLetter(
              key?.replace(formRegEx, formRegExReplacer)
            )} in language: ${capitalizeFirstLetter(lang)}`
          );
        }
      }
    }

    const body = {
      ...profileData,
      language: profileData?.language,
      companyType: profileData?.companyType,
      ...locationState,
      province: locationState?.province?._id,
      town: locationState?.town?._id,
      neighborhood: locationState?.neighbourhood?._id
    };

    for (let key in body) {
      if (!body[key]) {
        return toast.error('Please fill all the fields');
      }
    }
    const formData = await CreateFormData(body);
    const res = await updateProfile(formData);
    if (res?.data) {
      dispatch(updateUser(res?.data.data?.user));
      toast.success('Profile updated successfully');
    }
  };

  useEffect(() => {
    console.log({ user });
  }, [locationState]);

  return (
    <SideBarSkeleton>
      <Container fluid className={classes.page}>
        <Row className={[['ar', 'fa']?.includes(language?.value) ? 'rtl' : 'ltr'].join(' ')}>
          <Col md={12} className={classes?.spBtw}>
            <h3 className={classes.headingGreen}>{t('profileSettings')}</h3>
            <FormLanguages value={language} setter={setLanguage} />
          </Col>
          <Col md={12}>
            <div className={classes?.spBtw}>
              <div className="d-flex justify-content-center align-items-center">
                <p className={`m-0 ${classes.tagline}`}>{t('tagline')}</p>
              </div>
              <ProfileWithEditButton
                isEdit={true}
                updateImage={profileData?.photo}
                setUpdateImage={(e) => updateField('photo', e)}
              />
            </div>
          </Col>
          <Col md={12} className={classes.mb30}>
            <h6 className={classes.heading}>{t('information')}</h6>
          </Col>
          <Col md={12}>
            <div className={classes.formBg}>
              <Container>
                <Row className={classes.formRow}>
                  <Col md={6}>
                    <Input
                      label={t('companyName')}
                      placeholder={t('companyName')}
                      variant="dashboard"
                      value={profileData?.companyName?.[language?.value]}
                      setValue={(e) =>
                        setProfileData((prev) => ({
                          ...prev,
                          companyName: { ...prev.companyName, [language?.value]: e }
                        }))
                      }
                    />
                  </Col>
                  <Col md={6}>
                    <DropDown
                      label={t('companyType')}
                      indicatorColor="var(--dashboard-text-gray-clr)"
                      placeholder={t('companyTypePlaceholder')}
                      variant="dashboard"
                      value={profileData?.companyType}
                      setValue={(e) => updateField('companyType', e)}
                      options={companyTypeOptions}
                      getOptionLabel={(e) => e?.[language?.value]}
                      getOptionValue={(e) => e?.[language?.value]}

                      // options={propertyStatusOptions}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      label={t('serviceAreas')}
                      placeholder={t('serviceAreas')}
                      variant="dashboard"
                      value={profileData?.serviceAreas?.[language?.value]}
                      setValue={(e) =>
                        setProfileData((prev) => ({
                          ...prev,
                          serviceAreas: { ...prev.serviceAreas, [language?.value]: e }
                        }))
                      }
                    />
                  </Col>
                  <Col md={6}>
                    <DropDown
                      label={t('languagesWeSpeak')}
                      indicatorColor="var(--dashboard-text-gray-clr)"
                      placeholder={t('languagesWeSpeakPlaceholder')}
                      variant="dashboard"
                      value={profileData?.language}
                      isMulti={true}
                      options={allLanguagesArray}
                      optionLabel={[language?.value]}
                      optionValue={[language?.value]}
                      setValue={(e) => updateField('language', e)}
                    />
                  </Col>
                  {/* No of Agents Will be fetched from the created agents by company */}
                  {/* <Col md={6}>
                    <Input
                      label={'Number of Agents'}
                      placeholder={'number of agents'}
                      variant="dashboard"
                      value={profileData?.noOfAgents}
                      setValue={(e) => updateField('noOfAgents', e)}
                    />
                  </Col> */}
                  <Col md={6}>
                    <Input
                      label={t('registrationNumber')}
                      placeholder={t('registrationNumber')}
                      variant="dashboard"
                      value={profileData?.registrationNumber}
                      setValue={(e) => updateField('registrationNumber', e)}
                    />
                  </Col>
                  <Col md={12}>
                    <TextArea
                      label={t('aboutUs')}
                      placeholder={t('aboutUs')}
                      variant="dashboard"
                      value={profileData?.description?.[language?.value]}
                      setValue={(e) =>
                        setProfileData((prev) => ({
                          ...prev,
                          description: { ...prev.description, [language?.value]: e }
                        }))
                      }
                    />
                  </Col>
                  <Col md={12}>
                    <LocationComponent
                      locationState={locationState}
                      setLocationState={setLocationState}
                      selectedLanguage={language}
                    />
                  </Col>
                  {/* <Col md={6}>
                    <DropDown
                      label={'Province*'}
                      indicatorColor="var(--primary-clr)"
                      placeholder={'Select Province'}
                      variant="dashboard"
                      value={profileData?.province}
                      setValue={(e) => updateField('province', e)}
                    />
                  </Col>
                  <Col md={6}>
                    <DropDown
                      indicatorColor="var(--primary-clr)"
                      label={'Town*'}
                      placeholder={'Select Town'}
                      variant="dashboard"
                      value={profileData?.town}
                      setValue={(e) => updateField('town', e)}
                    />
                  </Col>
                  <Col md={6}>
                    <DropDown
                      indicatorColor="var(--primary-clr)"
                      label={'Neighborhood*'}
                      placeholder={'Select Neighborhood'}
                      variant="dashboard"
                      value={profileData?.neighborhood}
                      setValue={(e) => updateField('neighborhood', e)}
                    />
                  </Col>
                  <Col md={6}>
                    <MapMain
                      type="places"
                      label={'Pin On Map*'}
                      placeholder={'Select your location'}
                      variant="dashboard"
                      address={profileData?.location}
                      setAddress={(e) => updateField('location', e)}
                    />
                  </Col> */}
                  <Col md={6}>
                    <Input
                      label={t('email')}
                      placeholder={t('email')}
                      variant="dashboard"
                      value={user?.email}
                      setValue={() => null}
                      // disabled={true}
                    />
                  </Col>
                  <Col md={6}>
                    <CustomPhoneInput
                      label={t('phone')}
                      placeholder={t('phone')}
                      variant="dashboard"
                      value={profileData?.phoneNumber}
                      setValue={(e) => updateField('phoneNumber', e)}
                    />
                  </Col>
                  <Col md={6}>
                    <CustomPhoneInput
                      label={t('whatsappNumber')}
                      placeholder={t('whatsappNumber')}
                      variant="dashboard"
                      value={profileData?.whatsapp}
                      setValue={(e) => updateField('whatsapp', e)}
                    />
                  </Col>
                  <Col md={6} className={classes.aEnd}>
                    <Button
                      className={classes.changePassBtn}
                      variant=""
                      onClick={() => {
                        setShowModal('password');
                      }}>
                      {t('changePassword')}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
          <Col md={12} className={classes.btnDiv}>
            <Button className={classes.btnCancel} disabled={isLoading}>
              {t('cancelBtn')}
            </Button>
            <Button className={classes.btn} onClick={updateProfileHandler} disabled={isLoading}>
              {isLoading ? t('loadingBtn') : t('saveBtn')}
            </Button>
          </Col>
        </Row>
      </Container>
      {showModal == 'password' && (
        <ChangePasswordModal show={showModal == 'password'} setShow={() => setShowModal('')} />
      )}
    </SideBarSkeleton>
  );
}

export default ProfileSettings;
