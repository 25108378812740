import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ImLocation } from 'react-icons/im';
import classes from './locationFiltersOverlay.module.css';
import {
  useGetAllNeighborhoodsQuery,
  useGetAllProvincesQuery,
  useGetAllTownsQuery
} from '../../store/apiSlice/apiSlice';
import { useSelector } from 'react-redux';
import DropDown from '../DropDown/DropDown';
import { Radio } from '../Radio/Radio';
import Button from '../Button/Button';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { allowLocation } from '../../Helper/allowLocation';

function LocationFiltersOverlay({ setLocationState, setClose, locationState, setSearchText }) {
  const { t } = useTranslation('webCommon');
  const [filters, setFilters] = useState({
    province: locationState?.province,
    town: locationState?.town,
    neighborhood: locationState?.neighborhood,
    nearby: locationState?.nearby,
    latitude: locationState?.latitude,
    longitude: locationState?.longitude
  });
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const { data: provinces, isLoading: isGettingProvinces } = useGetAllProvincesQuery();
  const { data: towns, isLoading: isGettingTowns } = useGetAllTownsQuery(
    {
      ref: filters.province?._id
    },
    { skip: !filters.province?._id }
  );
  const { data: neighborhoods, isLoading: isGettingNeighborhoods } = useGetAllNeighborhoodsQuery(
    {
      ref: filters.town?._id
    },
    { skip: !filters.town?._id }
  );

  const updateValue = (key, val) => {
    setFilters((prev) => ({ ...prev, [key]: val }));
  };
  const applyFilters = async() => {
       const loc = await allowLocation();
    const body = {
      province: filters?.province,
      town: filters?.town,
      neighborhood: filters?.neighborhood,
      nearby: filters?.nearby,
      ...(filters?.nearby && {
        latitude: loc?.latitude,
        longitude: loc?.longitude
      })
    };
    setLocationState((prev) => ({
      ...prev,
      ...body
    }));
    setClose && setClose('');
    let clearSearch = false;
    for (let key in body) {
      if (body[key]) {
        clearSearch = true;
      }
    }
    // console.log(clearSearch, 'clearSearch');
    setSearchText && clearSearch && setSearchText('');
  };
  const clearFilters = () => {
    setFilters({
      province: null,
      town: null,
      neighborhood: null,
      nearby: false
    });
    setLocationState((prev) => ({
      ...prev,
      province: null,
      town: null,
      neighborhood: null,
      nearby: false
    }));
    setClose && setClose('');
  };

  return (
    <div>
      <Row className={classes.row}>
        <Col md={12} className={classes.headWithIcon}>
          <ImLocation /> <h6>{t('locationFilters.location')}</h6>
        </Col>
        <Col md={12}>
          <DropDown
            placeholder={t('locationFilters.province')}
            value={filters?.province}
            setValue={(e) => {
              updateValue('province', e);
              updateValue('nearby', false);
            }}
            options={provinces?.data}
            optionValue={'_id'}
            getOptionLabel={(e) => e?.name?.[selectedLanguage]}
            disabled={isGettingProvinces}
          />
        </Col>
        <Col md={12}>
          <DropDown
            placeholder={t('locationFilters.town')}
            value={filters?.town}
            setValue={(e) => updateValue('town', e)}
            options={towns?.data}
            optionValue={'_id'}
            getOptionLabel={(e) => e?.name?.[selectedLanguage]}
            disabled={isGettingTowns}
          />
        </Col>
        <Col md={12}>
          <DropDown
            placeholder={t('locationFilters.neighborhood')}
            value={filters?.neighborhood}
            setValue={(e) => updateValue('neighborhood', e)}
            options={neighborhoods?.data}
            optionValue={'_id'}
            getOptionLabel={(e) => e?.name?.[selectedLanguage]}
            disabled={isGettingNeighborhoods}
          />
        </Col>
        <Col md={12}>
          <Radio
            label={t('locationFilters.nearby')}
            value={filters?.nearby ? t('locationFilters.nearby') : ''}
            setValue={async (e) => {
              const loc = await allowLocation();
              // console.log(loc, 'loc');
              if (!loc) return;
              setFilters((prev) => ({
                ...prev,
                province: null,
                town: null,
                neighborhood: null,
                nearby: e?.length > 0 ? true : false,
                latitude: loc?.latitude,
                longitude: loc?.longitude
              }));
            }}
          />
        </Col>
        <Col md={12}>
          <div className={classes.spBtw}>
            <Button
              borderRadius={'3px'}
              className={classes.clearBtn}
              onClick={() => clearFilters()}>
              {t('locationFilters.clearBtn')}
            </Button>
            <Button
              borderRadius={'3px'}
              className={classes.btnMaxContent}
              onClick={() => applyFilters()}>
              {t('locationFilters.applyBtn')}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default LocationFiltersOverlay;
