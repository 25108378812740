/* eslint-disable */
import moment from 'moment';
import BlankUserImg from '../assets/images/blank-user-img.png';
// export const apiUrl = 'https://turegu-backend-2f2f64e039d0.herokuapp.com';
export const apiUrl = "https://turegu-backend-dem-21eae6875e8a.herokuapp.com";


// export const apiUrl = 'https://f8572402f231130fdd5d061c6a9ee32e.serveo.net';

export const fallbackUser = BlankUserImg;
export const captchaApiKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
export const google_places_key = 'AIzaSyB30wsHtA_DHmPf0LG_gnPZn-fmJeZE9v0';

export const country_Name = 'Turkey';

export const imageUrl = (url) => `${apiUrl}/api/images/${url}`;
export const pdfUrl = (url) => `${apiUrl}/api/pdf/${url}`;
export const socketURL = `${apiUrl}`;

export const BaseURL = (link) => {
  return `${apiUrl}/api/v1/${link}`;
};

export const apiHeader = (token, isFormData) => {
  if (token && !isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': true
      }
    };
  }
  if (token && isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        'ngrok-skip-browser-warning': true
      }
    };
  }
  if (!token && !isFormData) {
    return {
      headers: {
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': true
      }
    };
  }

  if (!token && isFormData) {
    return {
      headers: {
        'Content-Type': 'multipart/form-data',
        'ngrok-skip-browser-warning': true
      }
    };
  }
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const CreateFormData = (data) => {
  const formData = new FormData();
  for (let key in data) {
    if (Array.isArray(data[key])) {
      for (let d in data[key]) {
        if (typeof data[key][d] == 'string') {
          formData.append(key, data[key][d]);
        } else if (data[key][d] instanceof File || data[key][d] instanceof Date) {
          formData.append(key, data[key][d]);
        } else {
          formData.append(`${key}[]`, JSON.stringify(data[key][d]));
        }
      }
    } else if (typeof data[key] == 'object') {
      if (data[key] instanceof File) {
        formData.append(key, data[key]);
      } else {
        formData.append(key, JSON.stringify(data[key]));
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  return formData;
};

export const formRegEx = /([a-z])([A-Z])/g;
export const formRegExReplacer = '$1 $2';

export const recordsLimit = 10;

export const showTabsWhenUserIsLogin = (isLogin, items = []) => {
  return isLogin ? [...items] : [];
};

export const dateDifference = (date) => {
  let duration = moment.duration(moment().diff(date));
  let daysDiff = duration.asDays();
  return daysDiff;
};

export const capitalizeFirstLetter = (l) => l.charAt(0).toUpperCase() + l.slice(1);

export const capitalizeEachWord = (str) => {
  return str.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
};

export const convertArrayKeysToObject = (array) => {
  let languageDict = {};

  for (let i = 0; i < array.length; i++) {
    languageDict[array[i]] = '';
  }

  return languageDict;
};

export const formatLabel = (label) =>
  capitalizeEachWord(label.replace(formRegEx, formRegExReplacer));

export const discardUndefinedOrNullValues = (obj) => {
  // let newObj = {};
  // const values = [undefined, null, ''];

  // for (let key in obj) {
  //   console.log(obj[key], 'obj[key]',Array.isArray(obj[key]) &&obj[key]?.length == 0);
  //   if (Array.isArray(obj[key]) && obj[key]?.length == 0) {
  //     newObj[key] = undefined;
  //   } else if (!values?.includes(obj[key])) newObj[key] = obj[key];
  // }

  // return newObj;
  const valuesToDiscard = [undefined, null, ''];

  return Object.fromEntries(
    Object.entries(obj)
      .filter(([key, value]) => {
        if (Array.isArray(value) && value.length === 0) {
          return false;
        }
        return !valuesToDiscard.includes(value);
      })
  );
};
