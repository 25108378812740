/* eslint-disable */
import React from 'react';
import classes from './AgentDashboard.module.css';
import SideBarSkeleton from '../../../Component/SideBarSkeleton';
import { Col, Container, Row } from 'react-bootstrap';
import {
  Coin,
  DashboardCardImg1,
  EventSlot,
  People,
  Percent,
  ProjectsIcon,
  PropertiesIcon
} from '../../../constant/imagePath';
import { BsPlusCircleFill } from 'react-icons/bs';
import StatsCard from '../../../Component/StatsCard';
import StatsCardStandardList from '../../../Component/StatsCardStandardList';
import { ReactSVG } from 'react-svg';
import Button from '../../../Component/Button/Button';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { useState } from 'react';
import ContactSales from '../../../modals/ContactSalesModal';
import { useTranslation } from 'react-i18next';
import { useGetAgentDashboardQuery } from '../../../store/apiSlice/apiSlice';
import { Skeleton } from '@mui/material';
import moment from 'moment';

const standardData = {
  title: 'Standard Listing',
  total: 40,
  current: 15,
  icon: Percent,
  barColor: '#03A998',
  totalFeature: 80,
  currentFeature: 70,
  totalPremium: 80,
  currentPremium: 30
};
function AgentDashboard() {
  const { t: d } = useTranslation('dashboard');
  const t = (key) => d(`agent.${key}`);
  const { isLoading, data } = useGetAgentDashboardQuery();
  const [showContact, setShowContact] = useState(false);

  const stats = [
    {
      current: data?.propertyCount,
      icon: PropertiesIcon,
      background: '#D3E0B2',
      barColor: '#A5BF46',
      label: 'Properties',
      barTitle1: t('premiumListings'),
      barTitle2: t('featuredListings'),
      isBar1Visible: true,
      isBar2Visible: true,
      premium: data?.propertyPercentagePremium,
      featured: data?.propertyPercentageFeatured
    },
    {
      current: data?.projectCount,
      icon: ProjectsIcon,
      background: '#F4E0B4',
      barColor: '#F7C04B',
      label: 'Projects',
      barTitle1: t('premiumListings'),
      barTitle2: t('featuredListings'),
      isBar1Visible: true,
      isBar2Visible: true,
      premium: data?.projectPercentagePremium,
      featured: data?.projectPercentageFeatured
    },
    {
      current: data?.eventCount,
      icon: EventSlot,
      background: '#93D7D2',
      barColor: '#03A998',
      label: 'Events',
      barTitle1: t('premiumListings'),
      barTitle2: t('featuredListings'),
      isBar1Visible: true,
      isBar2Visible: true,
      premium: data?.eventPercentagePremium,
      featured: data?.eventPercentageFeatured
    }
  ];
  const followerData = {
    title: t('followers'),
    current: data?.followers,
    icon: People,
    background: '#FFFFFF',
    backgroundImage: DashboardCardImg1
  };
  return (
    <SideBarSkeleton>
      <Container className={classes.main}>
        <Row className={classes.row}>
          {isLoading
            ? Array(3)
                .fill(0)
                .map((item, index) => (
                  <Col md={4} key={index}>
                    <Skeleton variant="rectangular" height={209} />
                  </Col>
                ))
            : stats?.map((item, index) => (
                <Col md={4} key={index}>
                  <StatsCard data={item} />
                </Col>
              ))}
          <Col md={12}>
            {isLoading ? (
              <Skeleton variant="rectangular" height={160} />
            ) : (
              <StatsCard data={followerData} isShowRange={false} />
            )}
          </Col>
          {/* <Col md={8}>
            <StatsCardStandardList data={standardData} />
          </Col> */}
          {isLoading ? (
            Array(2)
              .fill(0)
              .map((item, index) => (
                <Col md={6} key={index}>
                  <Skeleton variant="rectangular" height={209} />
                </Col>
              ))
          ) : (
            <>
              <Col md={6}>
                <div
                  className={classes.memberShipWrap}
                  // style={{
                  //   backgroundImage: `url(${DashboardCardImg2})`,
                  //   backgroundSize: 'cover',
                  //   backgroundPosition: 'center'
                  // }}
                >
                   <p className={classes.membership}>{t('membership')}</p>
                  <p className={classes.lite}>{data?.packageType}</p>
                  <p className={classes.valid}>
                    {t('validTill')} {moment(data?.packageEndDate).format('Do MMM YYYY')}
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <Row className={classes.creditMain}>
                  <Col md={6}>
                    <div className={classes.creditWrap}>
                      <div className={classes.head}>
                      <p>{t('availableCreditBalance')}</p>
                      </div>
                      <div className={classes.content}>
                      <p className={classes.amount}>{data?.availableCreditPoints}</p>

                        <ReactSVG src={Coin} />
                      </div>
                    </div>
                  </Col>
                  <Col md={6} className={classes.purchaseBtnWrap}>
                    {/* <Button
                  className={classes.purchaseBtn}
                  leftIcon={<BsPlusCircleFill size={20} />}
                  label={'Purchase'}
                /> */}
                  </Col>
                </Row>
              </Col>
            </>
          )}

          <Col md={12} className={classes.contactBtn}>
            <Button
              onClick={() => {
                setShowContact(true);
              }}
              className={classes.purchaseBtn}
              leftIcon={<AiFillQuestionCircle size={20} />}
              label={t('contactSales')}
            />
          </Col>
        </Row>
        <ContactSales show={showContact} setShow={setShowContact} t={t} />
      </Container>
    </SideBarSkeleton>
  );
}

export default AgentDashboard;
