/*eslint-disable*/

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AgentAndCompanyFilters from '../../Component/AgentAndCompanyFilters';
import AgentCard from '../../Component/AgentCard/AgentCard';
import BannerImageAd from '../../Component/BannerImageAd';
import CompanyCard from '../../Component/CompanyCard/CompanyCard';
import DropDown from '../../Component/DropDown/DropDown';
import Footer from '../../Component/Footer';
import Header from '../../Component/Header';
import HeroSection from '../../Component/HeroSection';
import { Loader } from '../../Component/Loader';
import NoData from '../../Component/NoData/NoData';
import { PaginationComponent } from '../../Component/Pagination/Pagination';
import { imageUrl, recordsLimit } from '../../config/apiUrl';
import { dateFilterOptions } from '../../constant/commonData';
import {
  HomeHeroBgImg,
  // ComapnyBgImage,
  horizontalBanner
} from '../../constant/imagePath';
import { useGetAllPublicCompanyOrAgentsMutation } from '../../store/apiSlice/apiSlice';
import classes from './AgentOrCompany.module.css';

function AgentOrCompany() {
  const { t } = useTranslation('agentsOrCompany');
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const navigate = useNavigate();
  const [featuredFilterValue, setFeaturedFilterValue] = useState('');
  const [selectedTab, setSelectedTab] = useState('agents');
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    province: null,
    town: null,
    neighborhood: null,
    nearby: false,
    lng: null,
    lat: null,
    languages: []
  });

  const [getData, { isLoading, data }] = useGetAllPublicCompanyOrAgentsMutation();

  const { allBanners } = useSelector((state) => state.commonReducer);

  const horizontalBanners = allBanners?.find(
    (item) => item?.page === selectedTab && item?.bannerType == 'horizontal'
  );

  const getAgentsOrCompany = async (
    pg = page,
    type = selectedTab,
    sortBy = featuredFilterValue?.value
  ) => {
    const filtersData = {
      type: type == 'agents' ? 'agent' : 'company',
      lan: selectedLanguage,
      // Filters
      province: filters?.province?._id,
      town: filters?.town?._id,
      neighborhood: filters?.neighborhood?._id,
      nearby: filters?.nearby,
      lng: filters?.lng,
      lat: filters?.lat,
      language: filters?.languages?.map((e) => e?.[selectedLanguage]),
      ...(sortBy ? { sortBy: sortBy } : {})
    };
    await getData({ data: filtersData, page: pg });
  };
  useEffect(() => {
    getAgentsOrCompany();
  }, []);

  return (
    <>
      <style>
        {`
        .smDropdown__indicator{
            padding: 0px 8px;
        }
        .smDropdown__control{
            border-radius: 0;
        }
        `}
      </style>
      <div className={classes.page}>
        <Header containerClass="" />

        <HeroSection
          imageUrl={HomeHeroBgImg}
          containerClass={classes.heroContainer}
          className={classes.heroSection}>
          <AgentAndCompanyFilters
            containerClass={classes.filtersContainer}
            selectedTab={selectedTab}
            t={t}
            setSelectedTab={(e) => {
              setSelectedTab(e);
              setFilters({
                province: null,
                town: null,
                neighborhood: null,
                nearby: false,
                lng: null,
                lat: null,
                languages: []
              });
              setPage(1);
              getAgentsOrCompany(1, e);
            }}
            filters={filters}
            setFilters={setFilters}
            onSearch={() => {
              const pg = 1;
              getAgentsOrCompany(pg);
              setPage(pg);
            }}
          />
        </HeroSection>

        {/* Body */}
        <div className={classes?.pagePadding}>
          <Container className="">
            {/* filter */}
            <div className={classes?.dataStripRight}>
              <span>{t('sortBy')}</span>
              <DropDown
                classNamePrefix={'smDropdown'}
                options={dateFilterOptions}
                value={featuredFilterValue}
                setValue={(e) => {
                  setFeaturedFilterValue(e);
                  setPage(1);
                  getAgentsOrCompany(1, selectedTab, e?.value);
                }}
                optionLabel={[selectedLanguage]}
                optionValue={'value'}
                placeholder={t('sortByPlaceholder')}
              />
            </div>

            {/* Mapper */}
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {selectedTab === 'agents' && (
                  <>
                    <Row className={classes.innerRow}>
                      {data?.data?.length == 0 ? (
                        <NoData text={t('noAgents')} />
                      ) : (
                        data?.data.map((e, i) => {
                          return (
                            <Col key={i} xxl={3} xl={3} md={4} sm={6} xs={12}>
                              <AgentCard
                                data={e}
                                index={i}
                                onClick={(agentData) => navigate(`/agents/${agentData?.slug}`)}
                              />
                            </Col>
                          );
                        })
                      )}
                    </Row>
                    {!!data?.data?.length && (
                      <div className={`${[classes.pagination, 'jCenter'].join(' ')}`}>
                        <PaginationComponent
                          totalPages={Math.ceil(data?.totalRecords / recordsLimit)}
                          currentPage={page}
                          setCurrentPage={(e) => {
                            getAgentsOrCompany(e);
                            setPage(e);
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
                {selectedTab === 'company' && (
                  <>
                    <Row className={classes.innerRow}>
                      {data?.data?.length == 0 ? (
                        <NoData text={t('noCompany')} />
                      ) : (
                        data?.data?.map((e, i) => {
                          return (
                            <Col key={i} xxl={3} xl={3} md={4} sm={6} xs={12}>
                              <CompanyCard
                                data={e}
                                index={i}
                                onClick={(companyData) => navigate(`/company/${companyData?.slug}`)}
                              />
                            </Col>
                          );
                        })
                      )}
                    </Row>
                    {!!data?.data?.length && (
                      <div className={`${[classes.pagination, 'jCenter'].join(' ')}`}>
                        <PaginationComponent
                          totalPages={Math.ceil(data?.totalRecords / recordsLimit)}
                          currentPage={page}
                          setCurrentPage={(e) => {
                            getAgentsOrCompany(e);
                            setPage(e);
                          }}
                        />
                      </div>
                    )}
                  </>
                )}

                {/* Banner Ad */}
                {horizontalBanners?.image && (
                  <BannerImageAd
                    image={imageUrl(horizontalBanners?.image)}
                    className={classes.horizontalBanner}
                  />
                )}
              </>
            )}
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AgentOrCompany;

export const _agentData = Array(8)
  .fill({
    _id: 1,
    companyLogo:
      'https://www.makeitealing.co.uk/sites/default/files/upload/logos/barnard_marcus_3f09fb979cb9238cb5ffc63a6d28aed6.png',
    companyName: 'HBS Real Estate',
    companyTagLine: 'We are the best',
    image:
      'https://images.unsplash.com/photo-1556157382-97eda2d62296?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
    agentName: 'John Doe',
    designation: 'CEO',
    propertiesForRent: 10,
    propertiesForSale: 10,
    languages: ['English', 'Malay', 'Chinese'],
    location: 'Barierwood, 123, Jalan 123, 12345, Malaysia'
  })
  .map((e) => e);
