import React from 'react'
import classes from "./BannerImageAd.module.css"
const BannerImageAd = ({image,className=''}) => {
  return (
    <div className={[classes.main,className].join(" ")} >
        <img src={image} alt="" />
    </div>
  )
}

export default BannerImageAd