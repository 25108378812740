/*eslint-disable*/

import React from 'react';
import classes from './EventDetail.module.css';
// import { RxDoubleArrowLeft } from 'react-icons/rx';
import { Col, Container, Row } from 'react-bootstrap';
// import BreadCrumb from '../../Component/BreadCrumb';
// import MultiImageCarousel from '../../Component/MultiImageCarousel';
import { useState } from 'react';
import { IoLocationSharp } from 'react-icons/io5';
import {
  MdLocationOn,
  //  MdOutline360,
  MdPhotoLibrary
} from 'react-icons/md';
import Tabs from '../../Component/Tabs';
// import { RiHotelBedFill } from 'react-icons/ri';
import ClientManagerCard from '../../Component/ClientManagerCard';
import HeadingWithUnderline from '../../Component/HeadingWithUnderline';
import { horizontalBanner, map1, map3, verticalBanner } from '../../constant/imagePath';

import { BsShareFill } from 'react-icons/bs';
// import { TbCalendarTime } from 'react-icons/tb';
import { FaStreetView, FaVideo } from 'react-icons/fa';
// import PropertyCard from '../../Component/PropertyCard';
// import Carousel from 'react-elastic-carousel';
import moment from 'moment';
import { AiFillHeart, AiFillInfoCircle, AiOutlineHeart } from 'react-icons/ai';
import BannerImageAd from '../../Component/BannerImageAd';
import DetailPageHeroSection from '../../Component/DetailPageHeroSection';
import ShareModal from '../../Component/ShareModal/ShareModal';
// import { GiPackedPlanks } from 'react-icons/gi';
// import Tooltip from '../../Component/Tooltip';
import { useTranslation } from 'react-i18next';
import { TbCalendarTime } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Footer from '../../Component/Footer';
import Header from '../../Component/Header';
import IconList from '../../Component/IconList/IconList';
import { Loader } from '../../Component/Loader';
import { restrictOnlyUserActions } from '../../Helper/restrictActions';
import { imageUrl } from '../../config/apiUrl';
import { eventsData } from '../../constant/commonData';
import {
  useFavoriteEntityMutation,
  useGetPublicEventsDetailQuery
} from '../../store/apiSlice/apiSlice';
import { usdToOther } from '../../utils/helpers';

// import { resetPassword } from '../../constant/imagePath';
// const data = [
//   {
//     title: 'Home',
//     path: '/'
//   },
//   {
//     title: 'New Event',
//     path: '/'
//   },
//   {
//     title: 'Event Details',
//     path: '/'
//   }
// ];
// const breakPoints = [
//   { width: 1, itemsToShow: 1 },
//   { width: 550, itemsToShow: 2 },
//   { width: 768, itemsToShow: 3 },
//   { width: 1200, itemsToShow: 3 }
// ];
// const images = [
//   'https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
//   'https://images.unsplash.com/photo-1549396535-c11d5c55b9df?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
//   'https://images.unsplash.com/photo-1550133730-695473e544be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
//   'https://images.unsplash.com/photo-1550167164-1b67c2be3973?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
//   'https://images.unsplash.com/photo-1550338861-b7cfeaf8ffd8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
//   'https://images.unsplash.com/photo-1550223640-23097fc71cb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
//   'https://images.unsplash.com/photo-1550353175-a3611868086b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
//   'https://images.unsplash.com/photo-1550330039-a54e15ed9d33?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
//   'https://images.unsplash.com/photo-1549737328-8b9f3252b927?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
//   'https://images.unsplash.com/photo-1549833284-6a7df91c1f65?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
//   'https://images.unsplash.com/photo-1549985908-597a09ef0a7c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
//   'https://images.unsplash.com/photo-1550064824-8f993041ffd3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60'
// ];

const plansPicture = [map1, map3];

const EventDetail = () => {
  const navigate = useNavigate();
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const { user, isLogin } = useSelector((state) => state?.authReducer);
  const { currency } = useSelector((state) => state.converterReducer);

  const { t } = useTranslation('eventDetail');

  const tabsData = [
    { icon: <MdPhotoLibrary />, text: t('tabs.photos'), id: '1', value: 'Photos' },
    { icon: <IoLocationSharp />, text: t('tabs.location'), id: '4', value: 'Location' },
    { icon: <FaStreetView />, text: t('tabs.streetView'), id: '5', value: 'Street' },
    { icon: <FaVideo />, text: t('tabs.video'), id: '6', value: 'Video' }
  ];
  const slug = useParams()?.slug;
  const [tabValue, setTabValue] = useState(tabsData[0]);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const { data, isLoading, refetch } = useGetPublicEventsDetailQuery({ slug });
  const [onFavorite, { isLoading: isFavoriting }] = useFavoriteEntityMutation();

  const eventData = data?.data;
  const isFavorite = eventData?.favoriteBy?.includes(user?._id);

  const routeName = useLocation()?.pathname?.split('/')[1];
  const { allBanners } = useSelector((state) => state.commonReducer);

  const verticalBanners = allBanners?.filter(
    (item) => item?.page === routeName && item?.bannerType == 'vertical'
  );
  const horizontalBanners = allBanners?.find(
    (item) => item?.page === 'event-detail' && item?.bannerType == 'horizontal'
  );

  const handleFavorite = async () => {
    if (isFavoriting) return;
    const res = await onFavorite({ id: eventData?._id, type: 'event' });
    if (res?.data) {
      if (res?.data?.data?.favoriteBy?.includes(user?._id)) {
        toast.success(`${'Event added in favorite list'} successfully`);
      } else {
        toast.success(`${'Event removed from favorite list'} successfully`);
      }
      refetch();
    }
  };

  const handleAction = () => {
    const isAllowedActions = restrictOnlyUserActions(user?.role);
    if (!isLogin) {
      navigate('/login');
    } else if (isAllowedActions) {
      return true;
    }
  };

  return (
    <>
      <Header containerClass="" />

      {isLoading ? (
        <Loader />
      ) : (
        <div className={classes.main}>
          <section className={classes.images}>
            <DetailPageHeroSection
              plansPicture={plansPicture}
              images={eventData?.images ?? []}
              text={tabValue?.value}
              videoLink={eventData?.videoLink ?? ''}
              location={{
                lat: eventData?.location?.coordinates?.[1],
                lng: eventData?.location?.coordinates?.[0]
              }}
            />
          </section>
          <Container className={[classes.container].join(' ')}>
            <Row className={classes.gap}>
              <Col md={12} lg={9} className={classes.col1}>
                <Tabs
                  value={tabValue}
                  setValue={(e) => {
                    setTabValue(e);
                  }}
                  tabsData={tabsData}
                  tabClass={classes.tab}
                />
                <div className={classes.detailWrap}>
                  <div className={classes.priceRow}>
                    <div className={classes.mainImage}>
                      <img src={imageUrl(eventData?.images?.[0])} alt="" />
                    </div>
                    <div>
                      <div className={classes.title}>
                        <p>{eventData?.name?.[selectedLanguage]}</p>
                      </div>
                      <div className={classes.price}>
                        <p>{usdToOther({ amount: eventData?.price, ...currency })}</p>
                      </div>
                      <div className={classes.desc}>
                        <p>{eventData?.title?.[selectedLanguage]}</p>
                      </div>

                      <div className={classes.addressWrap}>
                        <MdLocationOn color="var(--text-dark-gray-clr)" />
                        <p>{eventData?.address}</p>
                      </div>
                    </div>
                  </div>
                  <div className={classes.propertyDetailWrap}>
                    {/* <div className={classes.gap}> */}
                    <IconList
                      className={classes.infoIcons}
                      Icon={AiFillInfoCircle}
                      label={t('tooltips.eventType')}
                      value={eventData?.eventType?.title?.[selectedLanguage]}
                      iconSize={18}
                    />
                    {/* <IconList
                      className={classes.infoIcons}
                      Icon={AiOutlineNumber}
                      label={`No of Units`}
                      value={data?.noOfUnits}
                    /> */}
                    <IconList
                      className={classes.infoIcons}
                      Icon={TbCalendarTime}
                      label={t('tooltips.dateOfEvent')}
                      value={moment(eventData?.eventDate).format('ll')}
                    />
                    {/* <div className={classes.propertyDetail}>
                    <Tooltip text="Event Type">
                      <RiHotelBedFill color="var(--text-dark-gray-clr)" />
                    </Tooltip>
                    <p>{data?.typeOfEvent}</p>
                  </div> */}
                    {/* <div className={classes.propertyDetail}>
                    <Tooltip text="Date">
                      <TbCalendarTime color="var(--text-dark-gray-clr)" />
                    </Tooltip>

                    <p>{data?.date}</p>
                  </div> */}
                    {/* </div> */}
                  </div>

                  <div className={classes.description}>
                    <HeadingWithUnderline underlineColor="var(--secondary-clr)">
                      <h5>{t('description')}</h5>
                    </HeadingWithUnderline>
                    <p>{eventData?.description?.[selectedLanguage]}</p>
                  </div>
                </div>
              </Col>

              {/* Right column */}
              <Col md={12} lg={3} className={classes.col2}>
                <div className={classes.socialIconsWrap1}>
                  <div className={[classes.socialIcons1, classes.borderRight].join(' ')}>
                    <BsShareFill
                      onClick={() => setIsShareModalOpen(true)}
                      fill="var(--primary-clr)"
                      size={25}
                    />
                  </div>
                  <div className={[classes.socialIcons1].join(' ')}>
                    {isFavorite ? (
                      <AiFillHeart
                        onClick={() => {
                          if (!handleAction()) return;
                          handleFavorite();
                        }}
                        fill="var(--primary-clr)"
                        size={25}
                      />
                    ) : (
                      <AiOutlineHeart
                        onClick={() => {
                          if (!handleAction()) return;
                          handleFavorite();
                        }}
                        fill="#9d9e9e"
                        size={25}
                      />
                    )}
                  </div>
                  {/* <div className={[classes.socialIcons1].join(' ')}>
                  <IoMdPrint fill="var(--primary-clr)" size={25} />
                </div> */}
                </div>
                <div className={classes.clientCard}>
                  <ClientManagerCard dataType={'event'} data={eventData} pageData={eventsData} />
                </div>
                <div className={classes?.bannerAdContainer}>
                  {verticalBanners?.map((item, key) => (
                    <BannerImageAd
                      key={key}
                      image={imageUrl(item?.image)}
                      className={classes.verticalBanner}
                    />
                  ))}
                </div>
              </Col>
            </Row>
            <div className={classes?.bannerAdContainer}>
              {horizontalBanners?.image && (
                <BannerImageAd
                  image={imageUrl(horizontalBanners?.image)}
                  className={classes.horizontalBanner}
                />
              )}
            </div>
            {/* <div className={classes.similarEvents}>
              <HeadingWithUnderline>
                <h6>SIMILAR EVENTS</h6>
              </HeadingWithUnderline>
            </div> */}
            {/* <div className={classes.eventCarousel}>
            <Carousel
              className="property-carousel"
              breakPoints={breakPoints}
              pagination={false}
              // showArrows={showArrows}
            >
              {Array(4)
                .fill(0)
                .map((item, index) => (
                  <PropertyCard key={index} item={item} feature={true} onc />
                ))}
            </Carousel>
            </div> */}
          </Container>
          {isShareModalOpen && <ShareModal show={isShareModalOpen} setShow={setIsShareModalOpen} />}
        </div>
      )}
      <Footer />
    </>
  );
};

export default EventDetail;

// const data = {
//   price: '2000',
//   title: 'Marina Towers',
//   desc: 'Full floor for sale I All unit already ',
//   location: '2415 Byron Shire, New South - Wales',
//   typeOfEvent: 'Villa',
//   date: moment().format('DD MMM YYYY'),
//   description:
//     ' Description Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus'
// };
