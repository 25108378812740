import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AiFillCaretDown } from 'react-icons/ai';
import classes from './agentAndCompanyFilters.module.css';
import CustomOverlay from '../CustomOverlay';
import { useState } from 'react';
import Button from '../Button/Button';
import { ImLocation } from 'react-icons/im';
import {
  allLanguagesArray
  // commercialFilterOptions,
  // residentialFilterOptions
} from '../../constant/commonData';
import { BiSearchAlt2, BiUser } from 'react-icons/bi';
import { MdBusiness } from 'react-icons/md';
import AgentsMoreFiltersModal from '../../modals/AgentsMoreFiltersModal';
import { getWindowWidth } from '../../CustomHooks/isMobileViewHook';
import { FaFilter } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import LocationFiltersOverlay from '../LocationFilterOverlay';
import MapMain from '../maps/MapMain';

const FilterDiv = ({
  text,
  icon,
  iconRight = <AiFillCaretDown className={classes.icon} />,
  ...props
}) => {
  return (
    <div className={classes.dropdownBox} {...props}>
      <div>
        {icon} <span>{text}</span>
      </div>
      {iconRight && iconRight}
    </div>
  );
};

const LanguagesOverlay = ({ handleClick, applyFilters, mainData, show, target, t }) => {
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);

  return (
    <CustomOverlay
      show={show == 'languages'}
      target={target}
      handleClose={(e) => handleClick('', e)}
      targetElement={
        <FilterDiv
          onClick={(e) => {
            handleClick(e, 'languages');
          }}
          text={t('filters.languages')}
        />
      }>
      <div className={[classes.overlayBox, classes.p0].join(' ')}>
        <ul className={classes.listOptionsWithBrBtm}>
          {allLanguagesArray?.map((item, key) => (
            <li
              key={key}
              data-selected={mainData?.languages?.find((e) => e?.en == item?.en) && true}
              onClick={() => {
                const index = mainData?.languages?.findIndex((e) => e?.en == item?.en);
                if (index == -1) {
                  applyFilters({ languages: [...mainData.languages, item] });
                } else {
                  applyFilters({
                    languages: mainData.languages?.filter((e) => e?.en !== item?.en)
                  });
                }
              }}>
              {item?.[selectedLanguage]}
            </li>
          ))}
        </ul>
      </div>
    </CustomOverlay>
  );
};
function AgentAndCompanyFilters({
  onSearch,
  hideTabs,
  containerClass = '',
  filtersWrapperClass = '',
  selectedTab,
  setSelectedTab,
  // filters,
  t
}) {
  const [mainData, setMainData]=useState({
    province: null,
    town: null,
    neighborhood: null,
    nearby: false,
    lng: null,
    lat: null,
    languages: []
  })
  const tabsData = [
    { id: 1, text: t('filters.companies'), value: 'company', icon: <MdBusiness /> },
    { id: 2, text: t('filters.agent'), value: 'agents', icon: <BiUser /> }
  ];
  const [searchText, setSearchText] = useState('');

  // For Popover
  const [show, setShow] = useState('');
  const [target, setTarget] = useState(null);

  // For Modal States
  const [screenWidth, setScreenWidth] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getWindowWidth(setScreenWidth);
  }, []);
  const handleClick = (event, type) => {
    // console.log('type', type, event);
    // console.log('click', event);
    setShow(type || '');

    if (event) {
      setTarget(event.target);
    } else {
      setTarget(null);
    }
  };

  const LocationDropDown = () => (
    <CustomOverlay
      show={show == 'location'}
      target={target}
      handleClose={(e) => handleClick('', e)}
      targetElement={
        <FilterDiv
          onClick={(e) => handleClick(e, 'location')}
          icon={<ImLocation color={'var(--secondary-clr)'} />}
          text={t('filters.location')}
        />
      }>
      <div className={classes.overlayBox}>
        <LocationFiltersOverlay
          locationState={mainData}
          setLocationState={setMainData}
          setClose={setShow}
        />
      </div>
    </CustomOverlay>
  );

  return (
    <Container className={`${containerClass}`}>
      {!hideTabs && (
        <ul className={classes.tabs}>
          {tabsData?.map((item, key) => (
            <li
              key={key}
              onClick={() => {
                // navigate(`/${tabsData?.find(item => item?.value !== selectedTab)?.value}`)
                setSelectedTab(item?.value);
              }}>
              <p data-selected={item?.value == selectedTab}>
                {item?.icon} <span>{item?.text}</span>
              </p>
            </li>
          ))}
        </ul>
      )}
      <div className={[classes.otherOptions, filtersWrapperClass].join(' ')}>
        <Row className={`${classes.row} gx-0`}>
          {screenWidth > 992 && (
            <Col md={3} className={classes.width170}>
              <LocationDropDown />
            </Col>
          )}
          <Col md={4}>
            <MapMain
              setCoordinates={(e) => {
                setMainData((prev) => ({ ...prev, lng: e?.lng, lat: e?.lat }));
              }}
              address={searchText}
              setAddress={(e) => {
                setSearchText(e);
                setMainData((prev) => ({
                  ...prev,
                  nearby: false,
                  province: null,
                  town: null,
                  neighborhood: null
                }));
              }}
              placeholder={t('filters.search')}
              type="places"
              comboRootClassName={classes?.searchInput}
            />
          </Col>
          {screenWidth > 992 && (
            <Col md={3} className={classes.width170}>
              <LanguagesOverlay
                mainData={mainData}
                show={show}
                applyFilters={(e) => setMainData((prev) => ({ ...prev, ...e }))}
                handleClick={handleClick}
                target={target}
                t={t}
              />
            </Col>
          )}
          {screenWidth < 992 && (
            <Col md={3} className={classes.width170}>
              <FilterDiv
                onClick={() => {
                  setShowModal(true);
                }}
                iconRight={screenWidth <= 600 ? false : <FaFilter className={classes.icon} />}
                text={
                  screenWidth <= 600 ? <FaFilter className={classes.icon} /> : t('filters.title')
                }
              />
            </Col>
          )}
          <Col md={2} className={classes.searchBtnCol}>
            <Button
              variant="secondary"
              borderRadius={'3px'}
              onClick={onSearch}
              className={classes.searchBtn}>
              {screenWidth <= 600 ? (
                <BiSearchAlt2 color="var(--white-clr)" />
              ) : (
                t('filters.searchBtn')
              )}
            </Button>
          </Col>
          {screenWidth < 992 && (
            <AgentsMoreFiltersModal show={showModal} setShow={setShowModal}>
              <Col md={12}>
                <LanguagesOverlay
                  mainData={mainData}
                  show={show}
                  applyFilters={(e) => setMainData((prev) => ({ ...prev, ...e }))}
                  handleClick={handleClick}
                  target={target}
                  t={t}
                />
              </Col>
              <Col md={12}>
                <LocationDropDown />
              </Col>
            </AgentsMoreFiltersModal>
          )}
        </Row>
      </div>
    </Container>
  );
}

export default AgentAndCompanyFilters;
