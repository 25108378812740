import { City } from 'country-state-city';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AiOutlineDoubleRight, AiOutlineFileText } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { toast } from 'react-toastify';
import validator from 'validator';
import Button from '../../Component/Button/Button';
import CustomPhoneInput from '../../Component/CustomPhoneInput';
import DropDown from '../../Component/DropDown/DropDown';
import Header from '../../Component/Header';
import HeadingWithUnderline from '../../Component/HeadingWithUnderline';
import HeroSection from '../../Component/HeroSection';
import Input from '../../Component/Input/Input';
import TextArea from '../../Component/TextArea';
import {
  enquiryTypeOptions,
  floorLevelOptions,
  furnishingOptions,
  parkingSpaceOptions,
  preferredContactMethodOptions,
  propertyOrientationOptions,
  propertyStatusOptions
} from '../../constant/commonData';
import {
  HeroSec,
  PropertyRequestBanner,
  PropertyRequestFormIcon1,
  PropertyRequestFormIcon2,
  PropertyRequestFormIcon3
} from '../../constant/imagePath';
import { usePostRequestMutation } from '../../store/apiSlice/apiSlice';
import classes from './PropertyRequest.module.css';

const PropertyRequest = () => {
  const { accessToken } = useSelector((state) => state.authReducer);
  const { t } = useTranslation('propertyRequest');
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const propertyTypesOptions = useSelector((state) => state.commonReducer?.propertyTypes);
  const interiorAmenitiesOptions = useSelector((state) => state.commonReducer?.interiorAmenities);
  const exteriorAmenitiesOptions = useSelector((state) => state.commonReducer?.exteriorAmenities);

  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [contactMethod, setContactMethod] = useState('');
  const [enquiryType, setEnquiryType] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [city, setCity] = useState('');
  const [street, setStreet] = useState('');
  const [budget, setBudget] = useState('');
  const [area, setArea] = useState('');
  const [rooms, setRooms] = useState('');
  const [bathrooms, setBathrooms] = useState('');
  const [furnishing, setFurnishing] = useState('');
  const [floorLevel, setFloorLevel] = useState('');
  const [propertyStatus, setPropertyStatus] = useState('');
  const [parkingSpace, setParkingSpace] = useState('');
  const [view, setView] = useState('');
  const [additionalRequest, setAdditionalRequest] = useState('');

  const [inAmenities, setInAmenities] = useState([]);
  const [exAmenities, setExAmenities] = useState([]);

  const [apiFunc, { isLoading: loading }] = usePostRequestMutation();

  // Get All Cities of Turkey
  const getCities = City.getCitiesOfCountry('TR');
  // console.log(getCities, 'abc');
  const handleSubmit = async () => {
    const params = {
      fullName: name,
      email: email,
      phoneNumber: phone,
      contactMethod: contactMethod?.value,
      city: city?.name,
      propertyType: propertyType?._id,
      bedrooms: rooms,
      enquiryType: enquiryType?.value,
      budget,
      bathrooms,
      area,
      street,
      parkingSpace: parkingSpace,
      propertyStatus: propertyStatus,
      interiorAmenities: inAmenities.map((item) => item._id),
      exteriorAmenities: exAmenities.map((item) => item._id),
      furnishing: furnishing?.value,
      floorLevel: floorLevel,
      viewAndOrientation: view,
      requests: additionalRequest
    };
    // validation
    //phone number validation
    if (!validator.isMobilePhone(`+${phone}`)) {
      // return toast.error(t('toast.enterValidPhoneNumber'));
      return toast.error('Please enter a valid phone number');
    }

    if (!validator.isEmail(email)) {
      // return toast.error(t('toast.enterValidEmail'));
      return toast.error('Please enter a valid email');
    }

    for (let key in params) {
      if (!params[key]) {
        // return toast.error(t('toast.fillAllTheFields'));
        return toast.error('Please fill all the fields');
      }
    }
    const response = await apiFunc({
      data: params,
      token: !!accessToken
    });
    if (response?.data) {
      navigate('/');

      toast.success(t('toast.requestSubmitted'));
    }
  };

  return (
    <>
      <Header containerClass={''} />
      <div className={classes.main}>
        <HeroSection className={classes.heroSec} imageUrl={PropertyRequestBanner}>
          <Row>
            <Col
              md={{ span: 6, order: 1 }}
              sm={{ span: 12, order: 2 }}
              className={classes.dNone992}>
              <div className={classes.image}>
                <img src={HeroSec} alt="" draggable={false} />
              </div>
            </Col>
            <Col
              lg={{ span: 6, order: 2 }}
              md={12}
              sm={{ span: 12, order: 1 }}
              className={classes.heroCol2}>
              <div>
                {/* <img src={Logo} alt="" draggable={false} /> */}
                <h3>Demo</h3>
              </div>
              <div>
                <h3>{`"${t('heroSection.title')}"`}</h3>
                <p>{t('heroSection.description1')}</p>
                <p>{t('heroSection.description2')}</p>
              </div>
            </Col>
          </Row>
        </HeroSection>
        <section className={classes.formSection}>
          <Container className={[classes.container].join(' ')}>
            <h1>{t('title')}</h1>
            <div className={classes.stepsWrap}>
              <div className={classes.step}>
                <ReactSVG src={PropertyRequestFormIcon1} className={classes.stepIcon} />
                <p>{t('step1')}</p>
              </div>
              <AiOutlineDoubleRight className={classes.arrowIcon} />
              <div className={classes.step}>
                <ReactSVG src={PropertyRequestFormIcon2} className={classes.stepIcon} />
                <p>{t('step2')}</p>
              </div>
              <AiOutlineDoubleRight className={classes.arrowIcon} />

              <div className={classes.step}>
                <ReactSVG src={PropertyRequestFormIcon3} className={classes.stepIcon} />
                <p>{t('step3')}</p>
              </div>
            </div>
            <div className={classes.formWrap}>
              <Row className={classes.row}>
                <Col md={12}>
                  <HeadingWithUnderline underlineColor={'var(--secondary-clr)'}>
                    <h5>{t('contactDetails')}</h5>
                  </HeadingWithUnderline>
                </Col>
                <Col md={6}>
                  <Input
                    label={t('fullName')}
                    placeholder={t('fullNamePlaceholder')}
                    value={name}
                    setValue={setName}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    label={t('email')}
                    placeholder={t('emailPlaceholder')}
                    value={email}
                    setValue={setEmail}
                  />
                </Col>
                <Col md={6}>
                  <CustomPhoneInput
                    label={t('phoneNumber')}
                    placeholder={t('phoneNumberPlaceholder')}
                    value={phone}
                    setter={setPhone}
                  />
                </Col>
                <Col md={6}>
                  <DropDown
                    label={t('preferredMethodOfContact')}
                    placeholder={t('preferredMethodOfContactPlaceholder')}
                    value={contactMethod}
                    setValue={setContactMethod}
                    options={preferredContactMethodOptions}
                  />
                </Col>
                <Col md={12}>
                  <HeadingWithUnderline underlineColor={'var(--secondary-clr)'}>
                    <h5>{t('propertyRequirement')}</h5>
                  </HeadingWithUnderline>
                </Col>
                <Col md={6}>
                  <DropDown
                    label={t('enquiryType')}
                    placeholder={t('enquiryTypePlaceholder')}
                    value={enquiryType}
                    setValue={setEnquiryType}
                    options={enquiryTypeOptions}
                  />
                </Col>
                <Col md={6}>
                  <DropDown
                    label={t('propertyType')}
                    placeholder={t('propertyTypePlaceholder')}
                    value={propertyType}
                    setValue={setPropertyType}
                    options={propertyTypesOptions}
                    optionValue={'_id'}
                    getOptionLabel={(e) => {
                      return e?.title[selectedLanguage];
                    }}
                  />
                </Col>
                <Col md={6}>
                  <DropDown
                    label={t('city')}
                    placeholder={t('cityPlaceholder')}
                    value={city}
                    setValue={setCity}
                    options={getCities}
                    optionLabel={'name'}
                    optionValue={'name'}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    label={t('area_street')}
                    placeholder={t('area_streetPlaceholder')}
                    value={street}
                    setValue={setStreet}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    type={'number'}
                    label={t('budget')}
                    placeholder={t('budgetPlaceholder')}
                    value={budget}
                    setValue={setBudget}
                    min={0}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    label={t('area')}
                    placeholder={t('areaPlaceholder')}
                    type={'number'}
                    value={area}
                    setValue={setArea}
                    min={0}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    label={t('rooms')}
                    placeholder={t('roomsPlaceholder')}
                    type={'number'}
                    value={rooms}
                    setValue={setRooms}
                    min={0}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    label={t('bathrooms')}
                    placeholder={t('bathroomsPlaceholder')}
                    type={'number'}
                    value={bathrooms}
                    setValue={setBathrooms}
                    min={0}
                  />
                </Col>
                <Col md={6}>
                  <DropDown
                    label={t('furnishing')}
                    placeholder={t('furnishingPlaceholder')}
                    value={furnishing}
                    setValue={setFurnishing}
                    options={furnishingOptions}
                  />
                </Col>{' '}
                <Col md={6}>
                  <DropDown
                    label={t('floorLevel')}
                    placeholder={t('floorLevelPlaceholder')}
                    value={floorLevel}
                    setValue={setFloorLevel}
                    options={floorLevelOptions}
                    optionLabel={[selectedLanguage]}
                    optionValue={[selectedLanguage]}
                  />
                </Col>{' '}
                <Col md={6}>
                  <DropDown
                    label={t('propertyStatus')}
                    placeholder={t('propertyStatusPlaceholder')}
                    value={propertyStatus}
                    setValue={setPropertyStatus}
                    options={propertyStatusOptions}
                    optionLabel={[selectedLanguage]}
                    optionValue={[selectedLanguage]}
                  />
                </Col>{' '}
                <Col md={6}>
                  <DropDown
                    label={t('parkingSpace')}
                    placeholder={t('parkingSpacePlaceholder')}
                    value={parkingSpace}
                    setValue={setParkingSpace}
                    options={parkingSpaceOptions}
                    optionLabel={[selectedLanguage]}
                    optionValue={[selectedLanguage]}
                  />
                </Col>{' '}
                <Col md={6}>
                  <DropDown
                    label={t('viewAndOrientation')}
                    placeholder={t('viewAndOrientationPlaceholder')}
                    value={view}
                    setValue={setView}
                    options={propertyOrientationOptions}
                    optionLabel={[selectedLanguage]}
                    optionValue={[selectedLanguage]}
                  />
                </Col>{' '}
                <Col md={6}>
                  <DropDown
                    label={t('interiorAmenities')}
                    placeholder={t('interiorAmenitiesPlaceholder')}
                    isMulti={true}
                    value={inAmenities}
                    setValue={setInAmenities}
                    options={interiorAmenitiesOptions}
                    optionValue={'_id'}
                    getOptionLabel={(e) => e?.title?.[selectedLanguage]}
                    // getOptionLabel={(e) => e?.title['en']}
                  />
                </Col>
                <Col md={6}>
                  <DropDown
                    isMulti={true}
                    label={t('exteriorAmenities')}
                    placeholder={t('exteriorAmenitiesPlaceholder')}
                    value={exAmenities}
                    setValue={setExAmenities}
                    options={exteriorAmenitiesOptions}
                    optionValue={'_id'}
                    getOptionLabel={(e) => e?.title?.[selectedLanguage]}
                    // getOptionLabel={(e) => e?.title['en']}
                  />
                </Col>
                <Col md={12}>
                  <HeadingWithUnderline underlineColor={'var(--secondary-clr)'}>
                    <h5>{t('additionalRequests')}</h5>
                  </HeadingWithUnderline>
                </Col>
                <Col md={12}>
                  <TextArea
                    value={additionalRequest}
                    setValue={setAdditionalRequest}
                    placeholder={t('additionalRequestsPlaceholder')}
                  />
                </Col>
                <Col md={12} className={classes.btn}>
                  <Button
                    leftIcon={<AiOutlineFileText size={25} />}
                    disabled={loading}
                    variant="secondary"
                    label={loading ? t('submitLoading') : t('submit')}
                    onClick={handleSubmit}
                    borderRadius="5px"
                  />
                  <p className={classes.privacyText}>
                    {t('submittingRequest')}{' '}
                    <span className="c-p" onClick={() => navigate('/privacy-policy')}>
                      {t('privacyPolicy')}
                    </span>
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
};

export default PropertyRequest;
