import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import classes from './ContactUs.module.css';
import Footer from '../../Component/Footer';
import Header from '../../Component/Header';
import { useState } from 'react';
import HeadingWithUnderline from '../../Component/HeadingWithUnderline';
import Input from '../../Component/Input/Input';
import TextArea from '../../Component/TextArea';
import DropDown from '../../Component/DropDown/DropDown';
import Button from '../../Component/Button/Button';
import CustomPhoneInput from '../../Component/CustomPhoneInput';
import {
  ContactUsEmailIcon,
  ContactUsImg,
  ContactUsLocIcon,
  ContactUsPhoneIcon
} from '../../constant/imagePath';
import { useTranslation } from 'react-i18next';
import { usePostApiMutation } from '../../store/apiSlice/apiSlice';
import { toast } from 'react-toastify';
import validator from 'validator';

const ContactUs = () => {
  const { t } = useTranslation('webCommon');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [inquiries, setInquiries] = useState(null);
  const [description, setDescription] = useState('');

  const d = (e) => t(`contactUsPage.${[e]}`);
  const [postApiFunc] = usePostApiMutation();

  const handleSubmit = async () => {
    const body = {
      fullName: name,
      phoneNumber: phone,
      message: description,
      email,
      inquiryType: inquiries
    };
    for (let key in body) {
      if (!body[key]) {
        return toast.error('Please fill all the fields');
      }
    }
    if(!validator.isEmail(email)){
      return toast.error('Please enter a valid email');
    }
    if(!validator.isMobilePhone(`+${phone}`)){
      return toast.error('Please enter a valid phone number');
    }
    body.inquiryType = [inquiries?.value];
    const res = await postApiFunc({
      url: `contact-us`,
      tag: `ContactUs`,
      method:'POST',
      data: body
    });
    if (res?.data) {
      setName('');
      setEmail('');
      setPhone('');
      setInquiries('');
      setDescription('');
      toast.success('Your query has been sent successfully');
    }
  };

  return (
    <div className={classes.page}>
      <Header containerClass="" />
      <div className={classes?.containerWrapper}>
        <Container className="">
          <Row className={classes.row}>
            <Col lg={{ span: 6 }} xs={{ span: 12 }}>
              <Row className={classes.innerRow}>
                <Col sm={12}>
                  <HeadingWithUnderline underlineColor="var(--secondary-clr)">
                    <h2>{d('title')}</h2>
                  </HeadingWithUnderline>
                </Col>
                <Col sm={12}>
                  <p className={classes.infoLine}>{d('tagline')}</p>
                </Col>
                <Col sm={12}>
                  <Input value={name} setValue={setName} placeholder={d('fullName')} />
                </Col>
                <Col sm={12}>
                  <Input value={email} setValue={setEmail} placeholder={d('email')} type="email" />
                </Col>
                <Col sm={12}>
                  <CustomPhoneInput value={phone} setter={setPhone} placeholder={d('phone')} />
                </Col>
                <Col sm={12}>
                  <DropDown
                    value={inquiries}
                    setValue={setInquiries}
                    options={[
                      {label: 'General', value: 'General'},
                    ]}
                    placeholder={d('type')}
                    indicatorColor="var(--secondary-clr)"
                  />
                </Col>
                <Col md={12}>
                  <TextArea
                    value={description}
                    setValue={setDescription}
                    placeholder={d('message')}
                  />
                </Col>
                <Col md={12} className={classes.btnCenter}>
                  <Button label={d('btnText')} variant="secondary" onClick={handleSubmit} />
                </Col>
              </Row>
            </Col>
            <Col lg={{ span: 6 }} xs={{ span: 12 }}>
              <div className={classes.imgDiv}>
                <img src={ContactUsImg} />
              </div>
              <ul className={classes.infoSection}>
                <li>
                  <img src={ContactUsLocIcon} />{' '}
                  <span>
                    Plot No-404-405, 5th Floor, Sector-20, Udyog Vihar, Phase-III, Gurugram,
                    Haryana- 122016
                  </span>
                </li>
                <li>
                  <img src={ContactUsPhoneIcon} />{' '}
                  <div>
                    <a href="tel:+91-124-4698400" target="_blank" rel={'noreferrer'}>
                      +91-124-4698400
                    </a>
                    <a href="tel:+91-124-4698450" target="_blank" rel={'noreferrer'}>
                      +91-124-4698450
                    </a>
                  </div>
                </li>
                <li>
                  <img src={ContactUsEmailIcon} />{' '}
                  <a href="mailto:query@minda.co.in" target="_blank" rel={'noreferrer'}>
                    query@minda.co.in
                  </a>
                </li>
              </ul>
            </Col>
          </Row>

          <div className={classes?.buttonContainer}></div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
