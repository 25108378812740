/* eslint-disable */
import React, { useState } from 'react';
import classes from './Login.module.css';
import { Col, Row } from 'react-bootstrap';
import Input from '../../Component/Input/Input';
import Button from '../../Component/Button/Button';
import { Link, useNavigate } from 'react-router-dom';
import { WhiteLogo } from '../../constant/imagePath';
import Header from '../../Component/Header';
import { useDispatch } from 'react-redux';
import { saveLoginUserData } from '../../store/auth/authSlice';
import Footer from '../../Component/Footer';
// import Checkbox from '../../Component/Checkbox/Checkbox';
import { AiFillGoogleCircle } from 'react-icons/ai';
import { useLoginMutation } from '../../store/apiSlice/apiSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function Login() {
  const { t } = useTranslation('loginAndSignup');
  const d = (e) => t(`login.${e}`);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signinFn, { isLoading: signinLoading }] = useLoginMutation();
  console.log(signinLoading, 'signinLoading signinLoading signinLoading');
  const [formStates, setFormStates] = useState({
    email: '',
    password: ''
  });

  function handleFormStateUpdate(key, value) {
    setFormStates({ ...formStates, [key]: value });
  }
  async function onLogin() {
    const response = await signinFn(formStates);
    console.log(response?.data, '123123 2131231');
    if (response?.data) {
      dispatch(saveLoginUserData(response?.data));

      if (response?.data?.data?.user?.role == 'company') {
        navigate('/company/dashboard');
      } else if (response?.data?.data?.user?.role == 'agent') {
        navigate('/agent/dashboard');
      } else {
        // return navigate('/');
        navigate('/account-settings');
      }
    }
  }
  const currentYear = new Date().getFullYear();
  return (
    <div>
      <Header />
      <div className={classes.bg}>
        <div className={classes.content}>
          <div className={classes.logoDiv}>
            <img src={WhiteLogo} />
          </div>
          <form
            className={classes.form}
            onSubmit={(e) => {
              e?.preventDefault();
              onLogin();
            }}>
            <Row className={classes.formRow}>
              <Col md={12}>
                <h4>{d('title')}</h4>
              </Col>

              <Col md={12}>
                <Input
                  value={formStates?.email}
                  setValue={(e) => handleFormStateUpdate('email', e)}
                  type={'email'}
                  placeholder={d('email')}
                />
              </Col>
              <Col md={12}>
                <Input
                  value={formStates?.password}
                  setValue={(e) => handleFormStateUpdate('password', e)}
                  type={'password'}
                  placeholder={d('password')}
                />
              </Col>
              <Col md={12} className="text-end">
                <Link to={'#'} className={`link ${classes.link}`}>
                  {d('forgotPassword')}
                </Link>
              </Col>
              {/* <Col md={12} className="">
                <Checkbox
                  value={formStates?.rememberMe}
                  setValue={(e) => handleFormStateUpdate('rememberMe', e)}
                  label={'Remember Me'}
                  className={classes.my0}
                />
              </Col> */}

              <Col md={12} className={classes.btns}>
                <Button type={'submit'} variant="secondary">
                  {signinLoading ? 'Please Wait...' : 'Log in'}
                </Button>
              </Col>
              <Col md={12}>
                <Button
                  variant=""
                  leftIcon={<AiFillGoogleCircle color="var(--white-clr)" size={20} />}
                  className={classes.googleBtn}>
                  {d('signInWithGoogle')}
                </Button>
              </Col>

              {/* <Col md={12} className="j-center">
            <p className={classes.alreadyRegistered}>
              Already registered?{' '}
              <Link to={'#'} className="link">
                Login Here
              </Link>
            </p>
          </Col> */}
            </Row>
          </form>
          <div className={classes.createAccountText}>
            <p>{d('notAMemberYet')}</p>
            <p>
              {d('create') + ' '}
              <Link to={'/signup'} className={`link ${classes.link}`}>
                {d('here')}
              </Link>{' '}
            </p>
          </div>
          <p className={classes.copyRightText}>
            &copy;{currentYear} Demo. {d('allRightsReserved')}
          </p>
        </div>
        <DemoAccountLoginCredentials />
      </div>
      <Footer />
    </div>
  );
}

export default Login;
