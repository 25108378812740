import React from 'react';
import classes from './PropertyRequestDetailModal.module.css';
import ModalSkeleton from '../ModalSkeleton';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { enquiryTypeOptions, furnishingOptions } from '../../constant/commonData';
import ShowMoreShowLessText from '../../Component/ShowMoreShowLess/ShowMoreShowLessText';

const LabelAndValue = ({ label, value, className = '' }) => {
  return (
    <div className={[classes.labelValue, className].join(' ')}>
      <span className={classes.label}>
        {label}
        {': '}
      </span>
      <span className={classes.value}>{value ?? '---'}</span>
    </div>
  );
};

function PropertyRequestDetailModal({ show, setShow, data }) {
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      modalClass={classes.main}
      borderRadius={'0px'}
      width={'1200px'}
      header={'Property Request Detail'}
      headerClass={classes.header}>
      <div>
        <Row className={classes?.row}>
          <Col md={12}>
            <h5>Contact Details</h5>
          </Col>
          <Col md={6}>
            <LabelAndValue label={'Full Name'} value={data?.fullName} />
          </Col>
          <Col md={6}>
            <LabelAndValue label={'Email'} value={data?.email} />
          </Col>
          <Col md={6}>
            <LabelAndValue label={'Phone Number'} value={data?.phoneNumber} />
          </Col>
          <Col md={6}>
            <LabelAndValue label={'Preferred Method of Contact'} value={data?.contactMethod} />
          </Col>
          <Col md={12}>
            <h5>Property Requirement</h5>
          </Col>
          <Col md={6}>
            <LabelAndValue
              label={'Enquiry Type'}
              value={enquiryTypeOptions?.find((e) => e?.value == data?.enquiryType)?.label}
            />
          </Col>
          <Col md={6}>
            <LabelAndValue
              label={'Property Type'}
              value={data?.propertyType?.title?.[selectedLanguage]}
            />
          </Col>
          <Col md={6}>
            <LabelAndValue label={'City'} value={data?.city} />
          </Col>
          <Col md={6}>
            <LabelAndValue label={'Area, Street'} value={data?.street} />
          </Col>
          <Col md={6}>
            <LabelAndValue label={'Budget'} value={data?.budget} />
          </Col>
          <Col md={6}>
            <LabelAndValue label={'Area (M2)'} value={data?.area} />
          </Col>
          <Col md={6}>
            <LabelAndValue label={'Rooms'} value={data?.bedrooms} />
          </Col>
          <Col md={6}>
            <LabelAndValue label={'Bathrooms'} value={data?.bathrooms} />
          </Col>
          <Col md={6}>
            <LabelAndValue
              label={'Furnishing'}
              value={furnishingOptions?.find((e) => e?.value == data?.furnishing)?.label}
            />
          </Col>
          <Col md={6}>
            <LabelAndValue label={'Floor Level'} value={data?.floorLevel?.[selectedLanguage]} />
          </Col>
          <Col md={6}>
            <LabelAndValue
              label={'Property Status'}
              value={data?.propertyStatus?.[selectedLanguage]}
            />
          </Col>
          <Col md={6}>
            <LabelAndValue label={'Parking Space'} value={data?.parkingSpace?.[selectedLanguage]} />
          </Col>
          <Col md={6}>
            <LabelAndValue label={'View & Orientation'} value={data?.bathrooms} />
          </Col>
          <Col md={6}>
            <LabelAndValue
              label={'Interior Amenities'}
              value={data?.interiorAmenities?.map((e) => e?.title?.[selectedLanguage])?.join(', ')}
            />
          </Col>
          <Col md={6}>
            <LabelAndValue
              label={'Exterior Amenities'}
              value={data?.exteriorAmenity?.map((e) => e?.title?.[selectedLanguage])?.join(', ')}
            />
          </Col>
          <Col md={12}>
            <h5>Additional Requests</h5>
          </Col>
          <Col md={12}>
            <LabelAndValue
              label={'Description'}
              value={<ShowMoreShowLessText text={data?.requests} />}
              className={classes.description}
            />
          </Col>
        </Row>
      </div>
    </ModalSkeleton>
  );
}

export default PropertyRequestDetailModal;
