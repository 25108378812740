import { combineReducers } from 'redux';
import authReducer from './auth/authSlice';
import commonReducer from './common/commonSlice';
import languageReducer from './language/languageSlice';
import converterReducer from './converter/converterSlice';
import { apiSlice } from './apiSlice/apiSlice';

const rootReducer = combineReducers({
  authReducer,
  commonReducer,
  languageReducer,
  converterReducer,
  [apiSlice.reducerPath]: apiSlice.reducer
});

export default rootReducer;
