/* eslint-disable */
import React from 'react';
import ModalSkeleton from '../ModalSkeleton';
import classes from './SuccessModal.module.css';
import { Col, Row } from 'react-bootstrap';

import { BsCheckCircle } from 'react-icons/bs';

const SuccessModal = ({ show, setShow,title='Your message has been sent successfully' }) => {
  return (
    <ModalSkeleton width={'500px'} show={show} setShow={setShow} modalClass={classes.body} showCloseIcon={true}>
      <Row className={classes.gap}>
        <Col md={12}>
          <div className={classes.iconDiv}>
            <BsCheckCircle size={80} color="var(--primary-clr)" />
            <h6>Success!</h6>
          </div>
        </Col>
        <Col md={12}>
          <p>{title}</p>
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default SuccessModal;
