/*eslint-disable*/
import React, { useRef } from 'react';
import classes from './CustomEnvironment.module.css';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
const CustomFileInput = ({
  containerclass,
  label,
  value,
  setValue,
  buttonClass,
  acceptedFiles = ['.pdf,.docx'],
  placeholder = 'Attach PDF Catalogue',
  btnLabel = 'Choose File',
  isEdit,
  disabled,
  selectedLanguage
}) => {
  const inputRef = useRef(null);
  const { t } = useTranslation(['dashboardProjects'], {
    lng: selectedLanguage?.value
  });

  return (
    <>
      <label className={classes.label}>{label}</label>
      <div className={[classes.mainConatiner, containerclass].join(' ')}>
        <p>{isEdit ? isEdit : value ? value?.name : t('form.projectPdfCataloguePlaceholder')}</p>
        <Button
          label={btnLabel}
          className={[classes.uploadBtn, buttonClass].join(' ')}
          onClick={() => {
            inputRef.current.click();
          }}
          disabled={disabled}
        />
      </div>
      <input
        ref={inputRef}
        hidden
        type="file"
        accept={acceptedFiles.join(',')}
        onChange={(e) => setValue(e.target.files[0])}
        disabled={disabled}
      />
    </>
  );
};

export default CustomFileInput;
