/* eslint-disable */

import React, { Profiler, useState } from 'react';
import classes from './PropertiesManagement.module.css';
import SideBarSkeleton from '../../../Component/SideBarSkeleton';
import { Col, Container, Row } from 'react-bootstrap';
import CustomMaterialTable from '../../../Component/CustomMaterialTable';
import { AiFillEye } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';
import { MdOutlineUpload } from 'react-icons/md';
import Button from '../../../Component/Button/Button';
import SearchInput from '../../../Component/SearchInput';
import { useNavigate } from 'react-router-dom';
import UpgradingListingModal from '../../../modals/UpgradingListingModal/UpgradingListingModal';
import AddCreditPointsModal from '../../../modals/AddCreditPointsModal/AddCreditPointsModal';
import DropDown from '../../../Component/DropDown/DropDown';
import { dateFilterOptions } from '../../../constant/commonData';
import moment from 'moment';
import useDebounce from '../../../CustomHooks/useDebounce';
import {
  useActiveDeactivePropertyMutation,
  useAssignAgentOnPropertyMutation,
  useDeletePropertiesMutation,
  useGetAllPropertiesQuery,
  useRefreshPropertyMutation,
  useUpgradeToPremiumOrFeaturedMutation
} from '../../../store/apiSlice/apiSlice';
import { recordsLimit } from '../../../config/apiUrl';
import { toast } from 'react-toastify';
import AreYouSureModal from '../../../modals/AreYouSureModal';
import { HiRefresh } from 'react-icons/hi';
import { ImUserCheck } from 'react-icons/im';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AssignAgentModal from '../../../modals/AssignAgentModal';

const tableData = Array(10)
  .fill({
    id: '5645',
    date: '2020.11.12',
    category: 'For Sale',
    type: 'broker',
    title: 'Text text',
    location: 'London',
    status: 'Active',
    agent: 'John Smith'
  })
  ?.map((item) => ({ ...item, id: Math.floor(Math.random() * 10000) }));

function PropertiesManagement() {
  const { t } = useTranslation('dashboardProperties');
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [isFeaturedModalOpen, setIsFeaturedModalOpen] = useState(false);
  const [dateFilter, setDateFilter] = useState(dateFilterOptions[0]);
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState('');
  const [selectedItem, setSelectedItem] = useState('');

  const debounceSearch = useDebounce(searchText, 500);
  const { data, isLoading, isFetching } = useGetAllPropertiesQuery({
    limit: recordsLimit,
    page: page,
    search: debounceSearch,
    sortBy: dateFilter?.value,
    lan:selectedLanguage
  });

  const [deleteProperties, { isLoading: isDeleting }] = useDeletePropertiesMutation();

  const [activeDeactiveApi, { isLoading: isStatusLoading }] = useActiveDeactivePropertyMutation();

  const [refreshApi, { isLoading: isRefreshLoading }] = useRefreshPropertyMutation();
  const [upgradeApi, { isLoading: isUpgradeLoading }] = useUpgradeToPremiumOrFeaturedMutation();
  const [assignApi, { isLoading: isAssignLoading }] = useAssignAgentOnPropertyMutation();

  const deleteMultiple = async () => {
    const res = await deleteProperties(selectedRows?.map((item) => item._id));
    if (res?.data) {
      toast.success(`${selectedRows?.length > 1 ? 'Properties' : 'Property'} deleted successfully`);
      setSelectedRows([]);
    }
  };

  const headings = [
    { id: 'listingId', label: t('table.col1') },
    {
      id: 'createdAt',
      label: t('table.col2'),
      renderValue: (item, col) => {
        return moment(item[col?.id]).format('DD/MM/YYYY HH:MM A');
      }
    },
    {
      id: 'propertyStatus',
      label: t('table.col3'),
      renderValue: (item, col) => item?.[col?.id]?.[selectedLanguage]
    },
    {
      id: 'propertyPurpose',
      label: t('table.col4'),
      renderValue: (item, col) => item?.[col?.id]?.[selectedLanguage]
    },

    {
      id: 'propertyType',
      label: t('table.col5'),
      renderValue: (item, col) => item?.[col?.id]?.title?.[selectedLanguage]
    },
    {
      id: 'title',
      label: t('table.col6'),
      renderValue: (item, col) => item?.[col?.id]?.[selectedLanguage]
    },

    { id: 'address', label: t('table.col7') },
    {
      id: 'agent',
      label: t('table.col8'),
      renderValue: (item, col) =>
      item?.assignedAgent ? `${item?.assignedAgent?.agentName?.[selectedLanguage]}` : 'No Agent Assigned'
    },
    {
      id: 'isActive',
      label: t('table.col9'),
      renderValue: (item, col) => (item[col?.id] ? 'Active' : 'Deactive')
    },
    { id: 'options', label: t('table.col10'), align: 'center' }
  ];

  return (
  
    <SideBarSkeleton>
      <Container fluid className={classes.page}>
        <Row>
          <Col md={12} className={classes.mb58}>
            <div className={classes.header}>
              <div className={classes.left}>
                <SearchInput
                  placeholder={t('table.searchInput')}
                  setter={setSearchText}
                  state={searchText}
                  variant="dashboard"
                  containerStyle={{ width: '300px' }}
                />
                <DropDown
                  label={t('table.sortByDate')}
                  containerClassName={classes.dropDownContainer}
                  customStyle={{ padding: '0px 6px', minHeight: '40px', minWidth: '200px' }}
                  options={dateFilterOptions}
                  setValue={(e) => {
                    setDateFilter(e);
                  }}
                  value={dateFilter}
                  getOptionLabel={(e) => {
                    return e?.[selectedLanguage];
                  }}
                />
              </div>
              <div className={classes.headerRight}>
                <Button
                  borderRadius={'8px'}
                  className={classes.headerBtn}
                  onClick={() => navigate('/company/properties-management/new-property')}>
                  {t('table.createNewProperty')}
                </Button>
                <Button
                  borderRadius={'8px'}
                  className={classes.deleteBtn}
                  disabled={selectedRows?.length == 0}
                  onClick={deleteMultiple}>
                  {isDeleting ? t('table.deleting') : t('table.delete')}
                </Button>
              </div>
            </div>
          </Col>
          <Col md={12}>
            <h5 className={classes.heading}>{t('table.title')}</h5>
          </Col>
          <Col md={12}>
            <CustomMaterialTable
              headings={headings}
              data={data?.data}
              setPage={setPage}
              page={page}
              isLoading={isLoading || isFetching}
              totalRecords={Math.ceil(data?.totalRecords / recordsLimit)}
              noDataText={t('table.noDataText')}
              showOptionsColumn
              
              options={[
                // Refresh is irrelevant
                // assign yo agent is irrelevant => will be done in the edit page
                {
                  label: t('table.options.view'),
                  icon: <AiFillEye size={18} />,
                  onClick: (label, item) => {
                    navigate(`/property/${item?.slug}`)
                  }
                },
                {
                  label: t('table.options.refresh'),
                  icon: <HiRefresh size={18} />,
                  onClick: (_, e) => {
                    setSelectedItem(e);
                    setIsModalOpen('refresh');
                  }
                },
                {
                  label: t('table.options.edit'),
                  icon: <FiEdit size={18} />,
                  onClick: (_, e) => {
                    navigate(`/company/properties-management/edit-property/${e?.slug}`);
                  }
                },
                {
                  label: (e) =>
                    e?.isActive ? t('table.options.deactivate') : t('table.options.activate'),
                  onClick: (_, e) => {
                    setSelectedItem(e);
                    setIsModalOpen('active-deactive');
                  },
                  switch: true
                },
                {
                  label: (e) =>
                    e?.assignedAgent
                      ? t('table.options.agentAssigned')
                      : t('table.options.assignToAgent'),
                  className: (e) => (e?.assignedAgent ? 'disabledMenuItem' : ''),
                  icon: <ImUserCheck size={18} />,
                  onClick: (_, e) => {
                    if (!!e?.assignedAgent) return;
                    setSelectedItem(e);
                    setIsModalOpen('assignAgent');
                  }
                },

                {
                  // label: 'Upgrade Package',
                  label: t('table.options.upgradeToPremiumORFeatured'),
                  icon: <MdOutlineUpload size={18} />,
                  onClick: (label, e) => {
                    setSelectedItem(e);
                    setIsFeaturedModalOpen(true);
                  }
                }
              ]}
              // onOptionClick={(label, item) => console.log('dd', label, item)}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              allowMultipleSelection
            />
          </Col>
        </Row>
      </Container>

      {isFeaturedModalOpen && (
        <UpgradingListingModal
          show={isFeaturedModalOpen}
          setShow={setIsFeaturedModalOpen}
          isSubmitting={isUpgradeLoading}
          onSubmit={async (params) => {
            const res = await upgradeApi({ slug: selectedItem?.slug, data: params, type:'Property' });
            if (res?.data) {
              toast.success(`Property upgraded successfully`);
              setIsFeaturedModalOpen(false);
            }
          }}
        />
      )}

      {isModalOpen == 'active-deactive' && (
        <AreYouSureModal
          label1Icon={false}
          label2Icon={false}
          title={t('activeDeactiveModal.title')}
          label1={t('activeDeactiveModal.yesBtn')}
          label2={t('activeDeactiveModal.noBtn')}
          btnApiCallText={t('activeDeactiveModal.yesBtnLoading')}
          show={isModalOpen == 'active-deactive'}
          setShow={setIsModalOpen}
          subTitle={t(`activeDeactiveModal.${selectedItem?.isActive ? 'deactive' : 'active'}Text`)}
          isApiCall={isStatusLoading}
          onClick={async () => {
            const res = await activeDeactiveApi({
              slug: selectedItem?.slug,
              status: !selectedItem?.isActive
            });
            if (res?.data) {
              toast.success(
                `Property ${res?.data?.data?.isActive ? 'activated' : 'deactivated'} successfully`
              );
              setIsModalOpen('');
            }
          }}
        />
      )}
      {isModalOpen == 'refresh' && (
        <AreYouSureModal
          label1Icon={false}
          label2Icon={false}
          title={t('refreshModal.title')}
          label1={t('refreshModal.yesBtn')}
          label2={t('refreshModal.noBtn')}
          btnApiCallText={t('activeDeactiveModal.yesBtnLoading')}
          show={isModalOpen == 'refresh'}
          setShow={setIsModalOpen}
          subTitle={t('refreshModal.text')}
          isApiCall={isRefreshLoading}
          onClick={async () => {
            const res = await refreshApi({
              slug: selectedItem?.slug
            });
            if (res?.data) {
              toast.success(`Property refreshed successfully`);
              setIsModalOpen('');
            }
          }}
        />
      )}
      {isModalOpen == 'assignAgent' && (
        <AssignAgentModal
          show={isModalOpen == 'assignAgent'}
          setShow={setIsModalOpen}
          t={t}
          isLoading={isAssignLoading}
          onClick={async (e) => {
            const res = await assignApi({
              slug: selectedItem?.slug,
              agentSlug: e
            });
            if (res?.data) {
              toast.success(`Agent assigned successfully`);
              setIsModalOpen('');
            }
          }}
        />
      )}
    </SideBarSkeleton>
  );
}

export default PropertiesManagement;
