/*eslint-disable*/
import React, { useState } from 'react';
import classes from './EventsMangement.module.css';
import SideBarSkeleton from '../../../Component/SideBarSkeleton';
import { Col, Container, Row } from 'react-bootstrap';
import CustomMaterialTable from '../../../Component/CustomMaterialTable';
import { AiFillEye } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';
import { MdOutlineUpload } from 'react-icons/md';
import Button from '../../../Component/Button/Button';
import SearchInput from '../../../Component/SearchInput';
import { useNavigate } from 'react-router-dom';
import DropDown from '../../../Component/DropDown/DropDown';
import { dateFilterOptions } from '../../../constant/commonData';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  useActiveDeactiveEventMutation,
  useDeleteEventMutation,
  useGetMyEventsQuery,
  useRefreshEventMutation,
  useUpgradeToPremiumOrFeaturedMutation
} from '../../../store/apiSlice/apiSlice';
import useDebounce from '../../../CustomHooks/useDebounce';
import { toast } from 'react-toastify';
import moment from 'moment';
import AreYouSureModal from '../../../modals/AreYouSureModal';
import UpgradingListingModal from '../../../modals/UpgradingListingModal/UpgradingListingModal';
import { recordsLimit } from '../../../config/apiUrl';
import { HiRefresh } from 'react-icons/hi';

function EventsManagement() {
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const { t } = useTranslation('dashboardEvents', { lng: 'en' });
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [dateFilter, setDateFilter] = useState(dateFilterOptions[0]);
  const [page, setPage] = useState(1);
  const [isFeaturedModalOpen, setIsFeaturedModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');

  const [upgradeApi, { isLoading: isUpgradeLoading }] = useUpgradeToPremiumOrFeaturedMutation();

  const searchDebounce = useDebounce(searchText, 500);

  // const {
  //   data: apiData,
  //   isLoading,
  //   isFetching
  // } = useGetMyEventsQuery({
  //   page: page,
  //   search: searchDebounce,
  //   sortBy: dateFilter?.value
  // });

  const [activeDeactiveApi, { isLoading: isStatusLoading }] = useActiveDeactiveEventMutation();
  const [deleteRecordsApi, { isLoading: isDeleting }] = useDeleteEventMutation();
  const [refreshApi, { isLoading: isRefreshing }] = useRefreshEventMutation();

  //  delete api
  const deleteMultiple = async () => {
    const res = await deleteRecordsApi({ ids: selectedRows?.map((item) => item._id) });
    if (res?.data) {
      toast.success(`${selectedRows?.length > 1 ? 'Events' : 'Event'} deleted successfully.`);
      setSelectedRows([]);
    }
  };

  const handleRefresh = async (slug) => {
    const response = await refreshApi({ slug });
    if (response?.data) {
      toast.success('Event refreshed successfully.');
    }
    setSelectedItem(null);
  };

  // table headings
  const headings = [
    {
      id: 'SNO',
      label: t('table.col1'),
      renderValue: (_, __, i) => {
        return i + 1;
      }
    },
    {
      id: 'createdAt',
      label: t('table.col2'),
      renderValue: (item, col) => {
        return moment(item[col?.id]).format('DD/MM/YYYY HH:MM A');
      }
    },
    {
      id: 'eventType',
      label: t('table.col3'),
      renderValue: (item, col) => item[col?.id]?.title?.[selectedLanguage]
    },
    {
      id: 'title',
      label: t('table.col4'),
      renderValue: (item, col) => item[col?.id]?.[selectedLanguage]
    },
    { id: 'address', label: t('table.col5') },
    {
      id: 'isActive',
      label: t('table.col6'),
      renderValue: (item, col) => {
        return item[col?.id] ? 'Active' : 'Deactive';
      }
    },

    { id: 'options', label: t('table.col8'), align: 'center' }
  ];

  return (
    <SideBarSkeleton>
      <Container fluid className={classes.page}>
        <Row>
          <Col md={12} className={classes.mb58}>
            <div className={classes.header}>
              <div className={classes.left}>
                <SearchInput
                  placeholder={t('table.searchInput')}
                  setter={setSearchText}
                  state={searchText}
                  variant="dashboard"
                  containerStyle={{ width: '300px' }}
                />
                <DropDown
                  label={t('table.sortByDate')}
                  containerClassName={classes.dropDownContainer}
                  customStyle={{ padding: '0px 6px', minHeight: '40px', minWidth: '200px' }}
                  options={dateFilterOptions}
                  setValue={(e) => {
                    setDateFilter(e);
                  }}
                  optionLabel={[selectedLanguage]}
                  value={dateFilter}
                  placeholder={'Select Date'}
                  // isClearable={true}
                />
              </div>
              <div className={classes.headerRight}>
                <Button
                  borderRadius={'8px'}
                  className={classes.headerBtn}
                  onClick={() => navigate('/agent/events-management/new-event')}>
                  {t('table.createNewevents')}
                </Button>
                <Button
                  borderRadius={'8px'}
                  className={classes.deleteBtn}
                  disabled={selectedRows?.length == 0 || isDeleting}
                  onClick={deleteMultiple}>
                  {isDeleting ? t('table.deleting') : t('table.delete')}
                </Button>
                {/* <Button borderRadius={'8px'}>Create New Property</Button> */}
              </div>
            </div>
          </Col>
          <Col md={12}>
            <h5 className={classes.heading}>Events</h5>
          </Col>
          <Col md={12}>
            <CustomMaterialTable
              headings={headings}
              // data={apiData?.data || []}
              showOptionsColumn
              page={page}
              setPage={setPage}
              // isLoading={isLoading || isFetching}
              // totalRecords={Math.ceil(apiData?.totalRecords / recordsLimit)}
              noDataText={t('table.noDataText')}
              options={[
                {
                  label: t('table.options.view'),
                  icon: <AiFillEye size={18} />,
                  onClick: (label, item) => {
                    navigate(`/events/${item?.slug}`);
                  }
                },
                {
                  label: t('table.options.refresh'),
                  icon: <HiRefresh size={18} />,
                  onClick: (label, item) => {
                    setSelectedItem({ ...item, flag: 'refresh' });
                  }
                },
                {
                  label: t('table.options.edit'),
                  icon: <FiEdit size={18} />,
                  onClick: (e, rowData) => {
                    navigate(`/agent/events-management/edit-event/${rowData?.slug}`);
                  }
                },
                {
                  label: (e) =>
                    !e?.isActive ? t('table.options.activate') : t('table.options.deactivate'),
                  onClick: (label, item) => {
                    setSelectedItem({ ...item, flag: 'active-deactive' });
                  },
                  switch: true
                },
                {
                  label: t('table.options.upgradeToPremiumORFeatured'),
                  onClick: (label, item) => {
                    setIsFeaturedModalOpen({ ...item, boolean: true });
                  },

                  icon: <MdOutlineUpload size={18} />
                }
              ]}
              onOptionClick={() => console.log()}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              allowMultipleSelection
            />
          </Col>
        </Row>
      </Container>

      {isFeaturedModalOpen && (
        <UpgradingListingModal show={isFeaturedModalOpen} setShow={setIsFeaturedModalOpen} />
      )}

      {selectedItem?.flag == 'active-deactive' && (
        <AreYouSureModal
          label1Icon={false}
          label2Icon={false}
          label1={t('activeDeactiveModal.yesBtn')}
          label2={t('activeDeactiveModal.noBtn')}
          btnApiCallText={t('activeDeactiveModal.yesBtnLoading')}
          show={selectedItem?.flag == 'active-deactive'}
          setShow={setSelectedItem}
          subTitle={`${
            selectedItem?.isActive
              ? t('activeDeactiveModal.deactiveText')
              : t('activeDeactiveModal.activeText')
          }`}
          isApiCall={isStatusLoading}
          onClick={async () => {
            const res = await activeDeactiveApi({
              slug: selectedItem?.slug,
              status: !selectedItem?.isActive
            });
            if (res?.data) {
              toast.success(
                `${
                  res?.data?.data?.isActive
                    ? t('activeDeactiveModal.successToast')
                    : t('activeDeactiveModal.deactiveToast')
                }`
              );
              setSelectedItem(null);
            }
          }}
        />
      )}

      {selectedItem?.flag == 'refresh' && (
        <AreYouSureModal
          label1Icon={false}
          label2Icon={false}
          label1={t('refreshModal.yesBtn')}
          label2={t('refreshModal.noBtn')}
          show={selectedItem?.flag == 'refresh'}
          setShow={setSelectedItem}
          subTitle={t('refreshModal.text')}
          isApiCall={isRefreshing}
          onClick={() => {
            handleRefresh(selectedItem?.slug);
          }}
        />
      )}

      {isFeaturedModalOpen?.boolean && (
        <UpgradingListingModal
          show={isFeaturedModalOpen?.boolean}
          setShow={setIsFeaturedModalOpen}
          isSubmitting={isUpgradeLoading}
          onSubmit={async (params) => {
            const res = await upgradeApi({
              slug: isFeaturedModalOpen?.slug,
              data: params,
              type: 'event'
            });
            if (res?.data) {
              setIsFeaturedModalOpen(false);
              return toast.success(t('upgradeListingModal.successToast'));
            }
          }}
        />
      )}
    </SideBarSkeleton>
  );
}

export default EventsManagement;
