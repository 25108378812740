/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import classes from './HeaderNotification.module.css';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import image from '../../assets/images/house.jpg';
import { Skeleton } from '@mui/material';
import NoData from '../NoData/NoData';
import { useLazyGetApiQuery } from '../../store/apiSlice/apiSlice';
// import { MdNotifications } from "react-icons/md";

const Notifications = ({ item }) => {
  return (
    <div
      className={[classes.HeaderNotificationInnerDiv]}
      onClick={() => {
        // item?.flag == 'chat' &&
        //   navigate('/chat', {
        //     state: {
        //       currentRoom: item?.project?.room
        //     }
        //   });
      }}>
      <div className={[classes.ImgDiv]}>
        <img src={image} alt="" />
      </div>
      <div className={[classes.contentDiv]}>
        <div className={[classes.titleDiv]}>
          <h6>{item?.title}</h6>
        </div>
        <div className={classes.content}>
          <p>{item?.description}</p>
          <div className={classes.time}>
            <p>{moment(item?.createdAt).format('Do MMM YYYY hh:mm a')}</p>
          </div>
        </div>
        {/* <p>{item?.message}</p> */}
      </div>
    </div>
  );
};

const HeaderNotification = ({ t }) => {
  const [getApiFunc, { isLoading, isFetching, data: response }] = useLazyGetApiQuery();

  const notificationData = [
    {
      title: 'Full floor for sale I All unit already rented I One bedroom',
      description: 'Full floor for sale I All unit already rented I One bedroom',
      postedBy: 'Anas',
      createdAt: moment()
    },
    {
      description: 'Full floor for sale I All unit already rented I One bedroom',
      postedBy: 'Anas',
      createdAt: moment()
    },
    {
      description: 'Full floor for sale I All unit already rented I One bedroom',
      postedBy: 'Anas',
      createdAt: moment()
    },
    {
      description: 'Full floor for sale I All unit already rented I One bedroom',
      postedBy: 'Anas',
      createdAt: moment()
    },
    {
      description: 'Full floor for sale I All unit already rented I One bedroom',
      postedBy: 'Anas',
      createdAt: moment()
    }
  ];
  const navigate = useNavigate();
  // const accessToken = useSelector((state) => state?.authReducer?.accessToken);
  const [loading, setLoading] = useState(false);
  // const [notifications, setNotifications] = useState([]);

  const getData = async () => {
    await getApiFunc({
      url: `notifications/all?page=${1}&limit=${5}&sortBy=${-1}`,
      tag: `Notifications`
    });
  };
  const newArray5 = [1, 2, 3, 4, 5];

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={[classes.HeaderNotification]}>
      <div className={[classes.notificationHeader]}>
        <h6>{t('webHeader.notificationsPopup.title')}</h6>
      </div>
      <div className={classes.notificationSkeleton}>
        {isLoading || isFetching ? (
          newArray5?.map((index) => (
            <Skeleton key={index} height={'80px'} width={'100%'} variant={'rectangular'} />
          ))
        ) : response?.data?.length == 0 ? (
          <NoData text={t('webHeader.notificationsPopup.noData')} className={classes.noData} />
        ) : (
          response?.data?.map((item, i) => {
            return (
              <div className={[classes.mb16]} key={i}>
                <Notifications item={item} />
              </div>
            );
          })
        )}
      </div>
      <p className={['p18', classes.viewAll].join(' ')} onClick={() => navigate('/notifications')}>
        {t('webHeader.notificationsPopup.seeAll')}
      </p>
    </div>
  );
};
export default HeaderNotification;
