import moment from 'moment';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { BiTransfer } from 'react-icons/bi';
import { IoIosMail } from 'react-icons/io';
import { MdLocationOn, MdWifiCalling3 } from 'react-icons/md';
import { RiHotelFill } from 'react-icons/ri';
import { TbCalendarTime } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { toast } from 'react-toastify';
import { restrictOnlyUserActions } from '../../Helper/restrictActions';
import image from '../../assets/images/house.jpg';
import { imageUrl } from '../../config/apiUrl';
import { DateTime, NoOfUnits } from '../../constant/imagePath';
import { useFavoriteEntityMutation, usePostApiMutation } from '../../store/apiSlice/apiSlice';
import { updateUser } from '../../store/auth/authSlice';
import { usdToOther } from '../../utils/helpers';
import ElasticCarousel from '../ElasticCarousel';
import Tooltip from '../Tooltip';
import classes from './ProjectCard.module.css';
const items = [image, image];

// const breakPoints = [
//   { width: 1, itemsToShow: 1 },
//   { width: 550, itemsToShow: 2, itemsToScroll: 2 },
//   { width: 768, itemsToShow: 3 },
//   { width: 1200, itemsToShow: 4 }
// ];

/*eslint-disable*/
const ProjectCard = ({
  data = {
    _id: 12,
    images: items,
    title: 'ABCDEF',
    priceRange: '200-300',
    startPrice: '2000',
    endPrice: '4000',
    description: 'Full floor for sale I All unit already',
    location: '2415 Byron Shire, New South - Wales',
    type: 'Apartment',
    noOfUnits: '5',
    // propertyArea:"700",
    // noOfBedroooms:"5",
    // noOfBathrooms:"2",
    // eventType:"abcd",
    deliveryDate: moment().format('MMM Do YY')
  },
  onEmailClick = () => null,
  refetch
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('webCommon');
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const { currency } = useSelector((state) => state.converterReducer);
  const { user, isLogin } = useSelector((state) => state.authReducer);
  const [compared, setCompared] = useState(false);
  const navigate = useNavigate();
  const [onFavorite, { isLoading: isFavoriting }] = useFavoriteEntityMutation();
  const [postApiFunc] = usePostApiMutation();

  const phoneNumber =
    data?.assignedAgent?.phoneNumber ?? data?.agent?.phoneNumber ?? data?.company?.phoneNumber;

  const feature = data?.isPremium || data?.isFeatured;

  const isFavorite = data?.favoriteBy?.includes(user?._id);
  const handleCompare = async (data) => {
    const onlyCompareProperty = user?.compareList?.filter((item) => item?.type == 'Project');
    const ifAlreadyExist = onlyCompareProperty?.find((item) => item?.entity == data?._id);
    if (!ifAlreadyExist && onlyCompareProperty?.length >= 3)
      return toast.error('You can only compare 3 project at a time');

    const response = await postApiFunc({
      url: `users/compare/add`,
      method: 'POST',
      tag: `compareListFront`,
      data: {
        id: data?._id,
        type: 'project'
      }
    });
    if (response?.data) {
      dispatch(updateUser(response?.data?.data));
      toast.success(
        `Project ${ifAlreadyExist ? 'removed from' : 'added in'} compare list successfully`
      );
    }
  };

  const handleFavorite = async () => {
    if (isFavoriting) return;
    const res = await onFavorite({ id: data?._id, type: 'project' });
    refetch && refetch();
    if (res?.data) {
      if (res?.data?.data?.favoriteBy?.includes(user?._id)) {
        toast.success(`${'Project added in favorite list'} successfully`);
      } else {
        toast.success(`${'Project removed from favorite list'} successfully`);
      }
    }
  };

  const handleAction = () => {
    const isAllowedActions = restrictOnlyUserActions(user?.role);
    if (!isLogin) {
      navigate('/login');
    } else if (isAllowedActions) {
      return true;
    }
  };

  return (
    <>
      <div className={classes.cardMain}>
        <div className={classes.image}>
          {/* Tag */}
          {(data?.isPremium || data?.isFeatured) && (
            <div className={classes?.featuredPremiumContainer}>
              <span
                className={`${
                  (data?.isFeatured && classes?.featuredIco) ||
                  (data?.isPremium && classes?.premiumIco)
                }`}>
                {(data?.isPremium && t('projectCard.premium')) ||
                  (data?.isFeatured && t('projectCard.featured'))}
              </span>
            </div>
          )}
          {/* Tag */}
          <ElasticCarousel navigateLink={`/projects/${data?.slug}`} images={data?.images} />

          <div className={classes.socialIconsWrap}>
            <Tooltip text={t('projectCard.tooltips.call')} href={`tel:${phoneNumber}`}>
              <div className={classes.socialIcons}>
                <MdWifiCalling3 fill="#E2831B" size={20} />
              </div>
            </Tooltip>
            <Tooltip text={t('projectCard.tooltips.email')}>
              <div
                className={classes.socialIcons}
                onClick={(e) => {
                  e?.stopPropagation();
                  onEmailClick && onEmailClick();
                }}>
                <IoIosMail fill="#E2831B" size={22} />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className={classes.contentWrap} onClick={() => navigate(`/projects/${data?.slug}`)}>
          <div className={classes.content}>
            <div className={classes.priceRow}>
              <div className={classes.price1}>
                <p>{data?.title?.[selectedLanguage]}</p>
                <p>{usdToOther({ amount: data?.price, ...currency })}</p>
              </div>
              <div className={classes.iconWrap}>
                <Tooltip text={t('projectCard.tooltips.compare')}>
                  <BiTransfer
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!handleAction()) return;
                      handleCompare(data);
                    }}
                    size={25}
                    fill={`${
                      user?.compareList?.find((item) => item?.entity == data?._id)
                        ? 'var(--action-icon-clr)'
                        : 'var(--text-light-gray-clr)'
                    }`}
                  />
                </Tooltip>
                <Tooltip
                  text={
                    isFavorite
                      ? t('projectCard.tooltips.removeFavorite')
                      : t('projectCard.tooltips.addFavorite')
                  }>
                  {isFavorite ? (
                    <AiFillHeart
                      onClick={(e) => {
                        e.stopPropagation();
                        handleFavorite();
                      }}
                      fill="var(--action-icon-clr)"
                      size={25}
                    />
                  ) : (
                    <AiOutlineHeart
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!handleAction()) return;
                        handleFavorite();
                      }}
                      fill="var(--text-light-gray-clr)"
                      size={25}
                    />
                  )}
                </Tooltip>
              </div>
            </div>
            <div className={classes.description}>
              <p>{data?.description?.[selectedLanguage]}</p>
            </div>

            <div className={classes.addressWrap}>
              <MdLocationOn fill="var(--text-dark-gray-clr)" />
              <p>{data?.address}</p>
            </div>

            {!feature ? (
              <div className={classes.propertyDetailWrap}>
                <Row className={classes.gap}>
                  <Col md={6} className={classes.propertyDetail}>
                    <Tooltip text={t('projectCard.tooltips.projectType')}>
                      <RiHotelFill color="var(--text-dark-gray-clr)" />
                    </Tooltip>
                    <p>{data?.projectType?.title?.[selectedLanguage]}</p>
                  </Col>
                  <Col md={6} className={classes.propertyDetail}>
                    <Tooltip text={t('projectCard.tooltips.noOfUnits')}>
                      <ReactSVG
                        fill="var(--text-dark-gray-clr)"
                        className={classes.svgIcon}
                        src={NoOfUnits}
                      />
                    </Tooltip>

                    <p>
                      {data?.noOfUnits} {t('projectCard.units')}
                    </p>
                  </Col>
                  <Col md={6} className={classes.propertyDetail}>
                    <Tooltip text={t('projectCard.tooltips.deliveryDate')}>
                      <ReactSVG
                        color="var(--text-dark-gray-clr)"
                        className={classes.svgIcon}
                        src={DateTime}
                      />
                    </Tooltip>
                    <p>{moment(data?.deliveryDate).format('ll')}</p>
                  </Col>
                </Row>
              </div>
            ) : (
              <div className={classes.propertyDetailWrap}>
                <Row className={classes.gap}>
                  <Col md={6} className={classes.propertyDetail}>
                    <Tooltip text={t('projectCard.tooltips.projectType')}>
                      <RiHotelFill color="var(--text-dark-gray-clr)" />
                    </Tooltip>
                    <p>{data?.projectType?.title?.[selectedLanguage]}</p>
                  </Col>

                  <Col md={6} className={classes.propertyDetail}>
                    <Tooltip text={t('projectCard.tooltips.deliveryDate')}>
                      <TbCalendarTime color="var(--text-dark-gray-clr)" />
                    </Tooltip>
                    <p>{moment(data?.deliveryDate).format('ll')}</p>
                  </Col>
                </Row>
              </div>
            )}
          </div>
          <div
            className={classes.key}
            onClick={(e) => {
              e?.stopPropagation();
              navigate(`/company/${data?.company?.slug}`);
            }}>
            <img src={imageUrl(data?.company?.photo)} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectCard;
