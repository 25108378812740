import React from 'react';
import PropTypes from 'prop-types';
import classes from './button.module.css';
// import styled from 'styled-components';

const Button = ({
  label,
  customStyle,
  onClick,
  disabled = false,
  children,
  leftIcon,
  rightIcon,
  className = '',
  variant = 'primary',
  borderRadius='10px',
  border = 'none',
  ...props
}) => {
  const customStyleBtn = {
    ...(borderRadius && { borderRadius }),
    ...(border && { border }),
    ...(leftIcon && { padding: '15px 15px' })
  };
  return (
    <>
      <button
        className={` ${classes.btn} ${className} `}
        data-variant={variant}
        data-icon={!!leftIcon || !!rightIcon}
        data-left-icon={!!leftIcon}
        data-right-icon={!!rightIcon}
        style={{ ...customStyleBtn, ...(customStyle && { customStyle }) }}
        onClick={onClick}
        disabled={disabled}
        {...props}>
        {leftIcon && leftIcon}
        {label && <span>{label}</span>}
        {children && <span>{children}</span>}
        {rightIcon && rightIcon}
      </button>
    </>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  customStyle: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary', 'transparent'])
};

Button.defaultProps = {
  onClick: undefined,
  customStyle: {}
};

export default Button;
