import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { captchaApiKey } from '../../config/apiUrl'

function Captcha({setValue}) {
  return (
    <div>
         <ReCAPTCHA
            className={'captcha'}
            // ref={this._reCaptchaRef}
            
            sitekey={captchaApiKey}
            onChange={(e)=>setValue(e)}
            // asyncScriptOnLoad={this.asyncScriptOnLoad}
          />
    </div>
  )
}

export default Captcha