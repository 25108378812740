import React from 'react';
import ModalSkeleton from '../ModalSkeleton';
import classes from './AssignAgentModal.module.css';
import { useState } from 'react';
import Button from '../../Component/Button/Button';
import DropDown from '../../Component/DropDown/DropDown';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const AssignAgentModal = ({ show, setShow, isLoading, onClick, t }) => {
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const { agents } = useSelector((state) => state.commonReducer);
  const [agent, setAgent] = useState('');

  const handleClick = async () => {
    if (!agent) return toast.error('Please select agent');
    onClick && onClick(agent?.slug);
  };
  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      header={t('assignAgentModal.title')}
      headerClass={classes.header}>
      <div className={classes.inputs}>
        <DropDown
          value={agent}
          setValue={setAgent}
          inputStyle={{
            borderRadius: '5px'
          }}
          placeholder={t('assignAgentModal.agentPlaceholder')}
          options={agents}
          getOptionLabel={(e) => e?.agentName?.[selectedLanguage]}
          optionValue={'slug'}
        />
      </div>
      {/* <div className={classes.forgot}>
        <div>
          <Checkbox label={'Remember me'} />
        </div>
        <p>Forgot Password?</p>
      </div> */}
      <Button
        className={classes.btn}
        label={isLoading ? t('assignAgentModal.btnLoading') : t('assignAgentModal.btn')}
        onClick={handleClick}
      />
      {/* <div className={classes.footer}>
        <p>Don’t have a free account yet? </p>
        <p>Create your free account now</p>
      </div> */}
    </ModalSkeleton>
  );
};

export default AssignAgentModal;
