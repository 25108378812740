/* eslint-disable */

import React, { useState } from 'react';
import ModalSkeleton from '../ModalSkeleton';
import classes from './AddCreditPointsModal.module.css';
import { Col, Row } from 'react-bootstrap';
import { coinsImg } from '../../constant/imagePath';
import Input from '../../Component/Input/Input';
import Button from '../../Component/Button/Button';
import { useAssignPointsOnAgentMutation } from '../../store/apiSlice/apiSlice';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const AddCreditPointsModal = ({ show, setShow, data }) => {
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const [updatePoints, { isLoading }] = useAssignPointsOnAgentMutation();
  const { t } = useTranslation('dashboardAgents');

  const [credits, setCredits] = useState('');
  const agentData = [
    { label: t('addPointsModal.name'), value: data?.agentName?.[selectedLanguage] },
    { label: t('addPointsModal.company'), value: data?.company?.companyName?.[selectedLanguage] },
    { label: 'Agent Mobile', value: data?.phoneNumber },
    { label: 'Agent Email', value: data?.email },
    { label: t('addPointsModal.label'), value: data?.availableCreditPoints }
  ];
  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      modalClass={classes.main}
      borderRadius={'0px'}
      width={'1150px'}
      header={true}
      showCloseIcon={false}
      headerClass={classes.header}>
      <div className={classes.main}>
        <Row className={[classes.row, 'g-0', classes].join(' ')}>
          <Col md={6} className={classes.col1}>
            <h4>{t('addPointsModal.title')}</h4>

            <div className={classes?.mainInfo}>
              {agentData?.map((item, index) => {
                return (
                  <div className={classes?.info} key={index}>
                    <span className={classes?.leftDetail}>{item?.label}</span>
                    <span className={classes?.rightDetail}>{item?.value}</span>
                  </div>
                );
              })}
            </div>

            <div className={classes?.addCreditPointsContainer}>
              <Input
                variant="dashboard"
                label={t('addPointsModal.inputLabel')}
                value={credits}
                placeholder={t('addPointsModal.placeholder')}
                setValue={setCredits}
                type={'number'}
              />
              <Button
                label={
                  isLoading ? t('addPointsModal.btnLabelLoading') : t('addPointsModal.btnLabel')
                }
                onClick={async () => {
                  if (credits == 0) {
                    return toast.error(t('addPointsModal.toast1'));
                  }
                  const res = await updatePoints({ slug: data?.slug, data: { credits } });
                  if (res?.data) {
                    toast.success(t('addPointsModal.toast2'));
                    setShow(false);
                  }
                }}
              />
            </div>
          </Col>
          <Col md={6} className="d-flex justify-content-center align-items-center">
            <div className={classes.sideImage}>
              <img src={coinsImg} alt="" draggable={false} />
            </div>
          </Col>
        </Row>
      </div>
    </ModalSkeleton>
  );
};

export default AddCreditPointsModal;
