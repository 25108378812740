import React from 'react';
import ElasticCarousel from '../ElasticCarousel';
import PanoramaView from '../PanoramaView/PanoramaView';
import MapMain from '../maps/MapMain';
import { useState } from 'react';
import { useEffect } from 'react';
import classes from './DetailPageHeroSection.module.css';
import { imageUrl } from '../../config/apiUrl';

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3, itemsToScroll: 3 }
];

const DetailPageHeroSection = ({
  text,
  images,
  plansPicture,
  videoLink = 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
  location = { lat: 25.153824, lng: 55.261943 },
  threeSixtyImage
}) => {
  const decideImagesUrl = (imgs) => {
    return imgs.map((img) => (img.includes('https://') ? img : imageUrl(img)));
  };
  const [renderCompo, setRenderCompo] = useState(
    <div>
      <ElasticCarousel
        className={classes.carouselMain}
        images={decideImagesUrl(images)}
        breakPoints={breakPoints}
      />
    </div>
  );
  const imageArrayMap = {
    Photos: (
      <ElasticCarousel
        className={classes.carouselMain}
        images={decideImagesUrl(images)}
        breakPoints={breakPoints}
      />
    ),
    Plans: (
      <ElasticCarousel className={classes.carouselMain} images={decideImagesUrl(plansPicture)} />
    ),
    plans: (
      <ElasticCarousel className={classes.carouselMain} images={decideImagesUrl(plansPicture)} />
    ),
    360: <PanoramaView image={threeSixtyImage} />,
    Street: (
      <div className={classes?.mapContainer}>
        <MapMain mapClass={classes?.mapClass} zoom={13} isPanorama={true} />
      </div>
    ),
    Video: (
      <div className={classes?.mapContainer}>
        <video
          controls
          autoPlay
          playsInline
          name="media"
          width={'100%'}
          height={'100%'}
          style={{
            background: 'black'
          }}>
          <source src={videoLink} type="video/mp4" />
        </video>
      </div>
    ),
    // Plans: <MapMain mapClass={classes?.mapClass} zoom={13} options={mapOptions} location={location} />,
    Location: (
      <div className={classes?.mapContainer}>
        <MapMain mapClass={classes?.mapClass} zoom={13} options={mapOptions} location={location} />
      </div>
    )
  };

  useEffect(() => {
    if (text != '') {
      setRenderCompo(imageArrayMap[text]);
    }
  }, [text]);

  return (
    <>
      <style>{`
      .${classes.carouselMain} .rec-swipable .rec-item-wrapper{
      height:440px;
  }
  
  @media (max-width: 1220px) {
    
    .${classes.carouselMain} .rec-swipable .rec-item-wrapper{
      height:340px;
  }
  }
  @media (max-width: 1024px) {
    
    .${classes.carouselMain} .rec-swipable .rec-item-wrapper{
      height:300px;
  }
  }
  @media screen and (max-width:768px) {
 
    .${classes.carouselMain} .rec-swipable .rec-item-wrapper{
      height:260px;
  }
  }
  @media screen and (max-width:575px) {
  
    .${classes.carouselMain} .rec-swipable .rec-item-wrapper{
      height:240px;
  }
  }

  `}</style>
      <div className={classes.mainContainer}>{renderCompo}</div>
    </>
  );
};

export default DetailPageHeroSection;
const mapOptions = {
  mapTypeControl: false,
  streetViewControl: true,
  zoomControl: false,
  styles: [
    {
      featureType: 'poi',
      elementType: 'labels'
    }
  ]
};
