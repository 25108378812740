/*eslint-disable*/
import React from 'react';
import classes from './projects.module.css';
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import { Col, Container, Row } from 'react-bootstrap';
import { PaginationComponent } from '../../Component/Pagination/Pagination';
import { useState } from 'react';
import { useEffect } from 'react';
import Tabs from '../../Component/Tabs';
import { BsGrid3X3GapFill, BsListStars } from 'react-icons/bs';
import { IoBookmarkOutline } from 'react-icons/io5';
// import { projectsData } from '../../constant/commonData';
import BannerImageAd from '../../Component/BannerImageAd';
import { horizontalBanner, verticalBanner } from '../../constant/imagePath';
import ProjectFilters from '../../Component/ProjectFilters';
import ProjectCard from '../../Component/ProjectCard';
import ProjectHorizontalCard from '../../Component/ProjectHorizontalCard/ProjectHorizontalCard';
import { isMobileViewHook } from '../../CustomHooks/isMobileViewHook';
import { FaMapMarkedAlt } from 'react-icons/fa';
import MapMain from '../../Component/maps/MapMain';
import Tooltip from '../../Component/Tooltip';
import EmailAgentModal from '../../modals/EmailAgentModal';
import { useSelector } from 'react-redux';
import { imageUrl, recordsLimit, showTabsWhenUserIsLogin } from '../../config/apiUrl';
import { useGetPublicProjectsMutation } from '../../store/apiSlice/apiSlice';
import { Loader } from '../../Component/Loader';
import NoData from '../../Component/NoData/NoData';
import { useTranslation } from 'react-i18next';
/*eslint-disable*/
// const tabsData = [
//   { icon: <BsGrid3X3GapFill size={20} color="var(--white-clr)" />, text: 'grid', id: '1' },
//   { icon: <BsListStars size={22} color="var(--white-clr)" />, text: 'list', id: '2' },
//   { icon: <IoBookmarkOutline size={20} color="var(--white-clr)" />, text: 'save search', id: '3' }
// ];

function Projects() {
  const { t } = useTranslation('projects');
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const { allBanners } = useSelector((state) => state.commonReducer);
  const [isMobile, setIsMobile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState('');
  const { isLogin } = useSelector((state) => state.authReducer);
  const [getProjects, { isLoading, data }] = useGetPublicProjectsMutation();
  const [page, setPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filters, setFilters] = useState({});

  const verticalAd = allBanners?.filter(
    (item) => item?.page === 'projects' && item?.bannerType == 'vertical'
  );
  const horizontalBanners = allBanners?.filter(
    (item) => item?.page === 'projects' && item?.bannerType == 'horizontal'
  );

  const handleGetProjects = async (fil, pg = page) => {
    await getProjects({
      page: pg,
      limit: recordsLimit,
      data: {
        lan: selectedLanguage,
        ...(fil ? fil : {})
      }
    });
  };

  useEffect(() => {
    handleGetProjects();
  }, []);

  const saveSearchTab = showTabsWhenUserIsLogin(isLogin, [
    {
      icon: (
        <Tooltip text={t('tabs.saveSearch')}>
          <IoBookmarkOutline size={20} color="var(--white-clr)" />
        </Tooltip>
      ),
      text: t('tabs.saveSearch'),
      value: 'save search',
      id: '4'
    }
  ]);

  const tabsData = isMobile
    ? [
        {
          icon: (
            <Tooltip text={'Map'}>
              <FaMapMarkedAlt size={22} color="var(--white-clr)" />
            </Tooltip>
          ),
          value: 'map',
          text: t('tabs.map'),
          id: '3'
        },
        ...saveSearchTab
      ]
    : [
        {
          icon: <BsGrid3X3GapFill size={20} color="var(--white-clr)" />,
          text: t('tabs.grid'),
          value: 'grid',
          id: '1'
        },
        {
          icon: <BsListStars size={22} color="var(--white-clr)" />,
          text: t('tabs.list'),
          value: 'list',
          id: '2'
        },
        {
          icon: <FaMapMarkedAlt size={22} color="var(--white-clr)" />,
          value: 'map',
          text: t('tabs.map'),
          id: '3'
        },
        ...saveSearchTab
      ];
  const [selectedTab, setSelectedTab] = useState(tabsData[1]);

  useEffect(() => {
    // setData(projectsData);
    isMobileViewHook(setIsMobile, 992);
  }, []);

  useEffect(() => {
    if (isMobile) {
      setSelectedTab({ text: 'list', id: '2' });
    }
  }, [isMobile]);
  return (
    <div className={classes.page}>
      <Header containerClass="" />
      <div className={classes.filtersDiv}>
        <ProjectFilters
          containerClass=""
          showOneLine
          filtersWrapperClass={classes.filters}
          t={t}
          onSearch={(e) => {
            setPage(1);
            handleGetProjects(e, 1);
          }}
        />
      </div>
      <div className={classes.tabsDiv}>
        <Container className="">
          <p>
            {data?.totalRecords ?? 0} {t('tabs.title')}
          </p>
          <div>
            <Tabs
              selectedColor="var(--secondary-clr)"
              bgColor="var(--primary-clr)"
              separatorColor="var(--white-clr)"
              tabClass={classes.tab}
              setValue={(e) => {
                if (e?.value == 'save search') {
                  return;
                }
                setSelectedTab(e);
              }}
              value={selectedTab}
              tabsData={tabsData}
            />
          </div>
        </Container>
      </div>
      <Container>
        <Row className={classes.mainRow}>
          <Col
            md={selectedTab?.value == 'map' ? 12 : 10}
            className={[
              selectedTab?.value !== 'map' && 'verticalBannerAndContentWidth',
              classes.col1
            ].join(' ')}>
            {isLoading ? (
              <Loader className={classes?.loader} />
            ) : (
              <Row
                className={[classes.innerRow, selectedTab?.value == 'map' && classes.h100].join(
                  ' '
                )}>
                {selectedTab?.value == 'map' ? (
                  <>
                    <Col
                      xl={{ span: 3, order: 1 }}
                      xs={{ span: 12, order: 2 }}
                      className={`scroller ${classes.mapLisingCards}`}>
                      <Row className={classes.mapList}>
                        {data?.data?.slice(0, 9)?.map((item, key) => (
                          <Col xl={12} lg={4} sm={6} key={key}>
                            <ProjectCard
                              data={item}
                              onEmailClick={() => {
                                setIsModalOpen('email');
                              }}
                              refetch={handleGetProjects}
                            />
                          </Col>
                        ))}
                      </Row>
                    </Col>
                    <Col xl={{ span: 9, order: 2 }} xs={{ span: 12, order: 1 }}>
                      <MapMain className={classes.mainMap} mapClass={classes?.mapClass} zoom={13} />
                    </Col>
                  </>
                ) : data?.data?.length == 0 ? (
                  <NoData text={t('noDataText')} />
                ) : (
                  data?.data?.map((item, key) => (
                    <>
                      <Col
                        md={selectedTab?.value == 'list' ? 12 : 6}
                        lg={selectedTab?.value == 'list' ? 12 : 4}
                        sm={12}
                        key={key}>
                        {selectedTab?.value == 'list' ? (
                          <ProjectHorizontalCard
                            data={item}
                            index={key}
                            className="mb-0"
                            onEmailClick={() => {
                              setSelectedItem(item);
                              setIsModalOpen('email');
                            }}
                            refetch={handleGetProjects}
                          />
                        ) : (
                          <ProjectCard
                            data={item}
                            onEmailClick={() => {
                              setSelectedItem(item);
                              setIsModalOpen('email');
                            }}
                            refetch={handleGetProjects}
                          />
                        )}
                      </Col>
                      {(selectedTab?.value == 'list'
                        ? (key + 1) % 7 === 0
                        : (key + 1) % 9 === 0 &&
                          horizontalBanners[(page - 1) % horizontalBanners?.length]) && (
                        <Col md={12}>
                          <div className={classes?.bannerAdContainer}>
                            <BannerImageAd
                              className={classes.horizontalBanner}
                              image={imageUrl(
                                horizontalBanners[(page - 1) % horizontalBanners?.length]?.image
                              )}
                            />
                          </div>
                        </Col>
                      )}
                    </>
                  ))
                )}
                {['list', 'grid'].includes(selectedTab?.value) && !!data?.data?.length && (
                  <Col md={12} className={[classes.pagination, 'jCenter'].join(' ')}>
                    <PaginationComponent
                      totalPages={Math.ceil(data?.totalRecords / recordsLimit)}
                      currentPage={page}
                      setCurrentPage={(e) => {
                        setPage(e);
                        handleGetProjects(filters, e);
                      }}
                    />
                  </Col>
                )}
              </Row>
            )}
          </Col>
          <Col
            md={2}
            className={[
              selectedTab?.value == 'map' && 'd-none',
              'verticalBannerWidth',
              classes.col2
            ].join(' ')}>
            <Row className={`${classes.bannerRow}`}>
              {!isMobile && verticalAd?.[0]?.image && (
                <Col md={12}>
                  <BannerImageAd
                    image={imageUrl(verticalAd?.[0]?.image)}
                    className={classes.verticalBanner}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
      <EmailAgentModal
        show={isModalOpen == 'email'}
        setShow={setIsModalOpen}
        data={selectedItem}
        type={'project'}
      />
    </div>
  );
}

export default Projects;
