/* eslint-disable */
import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { MdPhotoLibrary } from 'react-icons/md';
import Tabs from '../../Component/Tabs';
import classes from './FollowedAgents.module.css';
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import AccountInfoLayout from '../../Component/AccountInfoLayout';
import DropDown from '../../Component/DropDown/DropDown';
import AgentCard from '../../Component/AgentCard/AgentCard';
// import { _agentData } from '../AgentOrCompany';
import { useNavigate } from 'react-router-dom';
import { useLazyGetApiQuery, usePostApiMutation } from '../../store/apiSlice/apiSlice';
import { useEffect } from 'react';
import CompanyCard from '../../Component/CompanyCard/CompanyCard';
import PaginationComponent from '../../Component/PaginationComponent';
import { recordsLimit } from '../../config/apiUrl';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../store/auth/authSlice';
import { Loader } from '../../Component/Loader';
import NoData from '../../Component/NoData/NoData';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const FollowedAgents = () => {
  const { t } = useTranslation('followedAgentsOrCompany');
  const tabsData = [
    { icon: <MdPhotoLibrary />, text: t('followedAgents'), id: '1' },
    { icon: <MdPhotoLibrary />, text: t('followedCompany'), id: '2' }
  ];
  const { user } = useSelector((state) => state?.authReducer);

  const [tabValue, setTabValue] = useState(tabsData[0]);
  const [page, setPage] = useState(1);
  const [checkboxArray, setCheckboxArray] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getApiFunc, { data: renderDataNew, isLoading: loadingNew }] = useLazyGetApiQuery();
  const [deleteApiFunc] = usePostApiMutation();


  const deleteApi = async (allUsers) => {
    // console.log(Array.isArray(allUsers), 'allUsers allUsersallUsers');

    if (Array.isArray(allUsers) && allUsers?.length == 0 && checkboxArray?.length == 0) {
      return toast.error(`Please select at least one ${tabValue?.id == '1' ? 'Agent' : 'Company'}`);
    }
    if (Array.isArray(allUsers) && allUsers?.length == 0) {
      return;
    }

    const deleteResponse = await deleteApiFunc({
      url: `users/unFollowMultiple`,
      data: {
        ids: Array.isArray(allUsers) ? allUsers : checkboxArray
      },
      method: 'DELETE',
      tag: `myFollowing`
    });
    if (deleteResponse?.data) {
      console.log(deleteResponse, 'deleteResponse deleteResponse deleteResponse');
      dispatch(updateUser(deleteResponse?.data?.data));
      setCheckboxArray([]);
      toast.success(
        `${
          allUsers?.length > 0
            ? tabValue?.id == '1'
              ? 'All Agents'
              : 'All Companies'
            : tabValue?.id == '1'
            ? 'Agent'
            : 'Company'
        }  deleted successfully`
      );
      getData();
    }
  };

  const getData = async (type = 'agent') => {
    await getApiFunc({
      url: `users/getMyFollowing?type=${type}&page=${page}&limit=${recordsLimit}`,
      tag: `myFollowing`
    });
  };
  useEffect(() => {
    getData();
  }, [page]);

  return (
    <div className="page_bg">
      <Header containerClass="customContainer" />
      <AccountInfoLayout>
        <Tabs
          className={classes.tab_main}
          tabClass={classes.tab_class_main}
          value={tabValue}
          setValue={(e) => {
            setTabValue(tabsData.find((item) => item.id == e.id));
            getData(e?.id == '1' ? 'agent' : 'company');
            setPage(1);
          }}
         
          tabsData={tabsData}
        />
        <div className={classes.delete_div}>
          <div
            style={{
              display: 'flex'
            }}>
            <p>{`${t('showing')} ${renderDataNew?.data?.length ?? 0}-10 ${
              tabValue?.id == 1 ? t('agent') : t('company')
            } `}</p>
            <p onClick={() => deleteApi(user?.agentFollowing)} className="c-p">
              {t('deleteAll')}
            </p>
            <p
              style={{
                color: checkboxArray?.length == 0 && '#ccc'
              }}
              onClick={() => deleteApi()}
              className="c-p">
              {t('deleteSelected')}
            </p>
          </div>
          {/* <div className={classes.sort_div}>
            <p>Sort by:</p>

            <div
              style={{
                marginRight: '20px'
              }}>
              <DropDown classNamePrefix={'dropDownMain'} placeholder={'Featured'} />
            </div>
            <DropDown classNamePrefix={'dropDownMain'} placeholder={'Featured'} />
          </div> */}
        </div>
        <div className={classes?.contentDiv}>
          <Row className="">
            {loadingNew ? (
              <Loader />
            ) : renderDataNew?.data?.length == 0 ? (
              <NoData
                text={`${tabValue?.id == '1' ? t('noFollowedAgents') : t('noFollowedCompany')}`}
              />
            ) : (
              renderDataNew?.data?.map((e, i) => {
                return (
                  <Col key={i} xl={4} lg={6} md={6}>
                    <Form.Check
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: '10px'
                      }}
                      checked={checkboxArray.includes(e?._id)}
                      onClick={() => {
                        if (checkboxArray.includes(e?._id)) {
                          setCheckboxArray(checkboxArray.filter((item) => item != e?._id));
                        } else {
                          setCheckboxArray([...checkboxArray, e?._id]);
                        }
                      }}
                      name="group1"
                      type={'checkbox'}
                      id={`inline-checkbox-1`}
                    />

                    {tabValue?.id == '1' ? (
                      <AgentCard
                        data={e}
                        index={i}
                        onClick={(agentData) => navigate(`/agents/${agentData?.slug}`)}
                      />
                    ) : (
                      <CompanyCard
                        data={e}
                        index={i}
                        onClick={(agentData) => navigate(`/company/${agentData?.slug}`)}
                      />
                    )}
                  </Col>
                );
              })
            )}

            {renderDataNew?.data?.length > 0 && (
              <div className={`${[classes.pagination, 'jCenter'].join(' ')}`}>
                <PaginationComponent
                  totalPages={Math.ceil(renderDataNew?.totalRecords / recordsLimit)}
                  currentPage={page}
                  setCurrentPage={(e) => {
                    // getAgentsOrCompany(e);
                    setPage(e);
                  }}
                />
              </div>
            )}
          </Row>
        </div>
      </AccountInfoLayout>
      <Footer />
    </div>
  );
};

export default FollowedAgents;
