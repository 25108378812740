/*eslint-disable*/
import React, { useState } from 'react';
import classes from './ContactedProperties.module.css';
import { Col, Row } from 'react-bootstrap';
import CustomMaterialTable from '../../Component/CustomMaterialTable';
import { AiFillEye } from 'react-icons/ai';
import { recordsLimit } from '../../config/apiUrl';
// import DropDown from '../../Component/DropDown/DropDown';
// import { dateFilterOptions } from '../../constant/commonData';
import PropertyRequestDetailModal from '../../modals/PropertyRequestDetailModal';
import {
  useDeletePropertyRequestsMutation,
  useGetAllInquiryRequestsQuery,
} from '../../store/apiSlice/apiSlice';
import useDebounce from '../../CustomHooks/useDebounce';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import AccountInfoLayout from '../../Component/AccountInfoLayout';
import Header from '../../Component/Header';
import Button from '../../Component/Button/Button';
import { useSelector } from 'react-redux';
import ContactedPropertyDetailModal from '../../modals/ContactedPropertyDetailModal';

// const tableData = Array(10)
//   .fill({
//     id: '5645',
//     date: '2020.11.12',
//     fullName: 'John Smith',
//     email: 'abc@gmail.com',
//     phoneNo: '2328902183',
//     budget: '1000'
//   })
//   ?.map((item) => ({ ...item, id: Math.floor(Math.random() * 10000) }));

function UserContactedProperties() {
  const {t}=useTranslation('contactedProperties')
  const {selectedLanguage}=useSelector(state=>state.languageReducer)
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(1);
  // const [dateFilter, setDateFilter] = useState(dateFilterOptions[0]);
  const [showModal, setShowModal] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  // const { t } = useTranslation('dashboardPropertyRequests');
  const { data, isLoading, isFetching } = useGetAllInquiryRequestsQuery({
    limit: recordsLimit,
    page: page
  });
  const headings = [
    { id: 'id', label: t('sNo'), renderValue: (_, __, i) => i + 1 },
    { id: 'propertyName', label: t('property'),renderValue: (e) =>e?.entity?.title?.[selectedLanguage]},
    { id: 'name', label: t('name'), },
    { id: 'email', label: t('email') },
    { id: 'phone', label: t('phone')},
    { id: 'preferredContactMethod', label: t('contactMethod') },
    { id: 'message', label: t('message'), renderValue: (e) =><span className='textOneLine'>{e?.message}</span>},
    { id: 'options', label: t('action'), align: 'center' }
  ];


  const [deleteRecordsApi, { isLoading: isDeleting }] = useDeletePropertyRequestsMutation();
  //  delete api
  const deleteMultiple = async () => {
    const res = await deleteRecordsApi({ ids: selectedRows?.map((item) => item._id) });
    if (res?.data) {
      toast.success(`Property Request${selectedRows?.length > 1 ? 's' : ''} deleted successfully.`);
      setSelectedRows([]);
    }
  };

  return (
    <div className={['page_bg', classes?.page].join(' ')}>
      <Header containerClass="customContainer" />
      <AccountInfoLayout>
        <Row>
          <Col md={12} className={classes?.header}>
            <h5 className={classes.heading}>{t('title')}</h5>
            {/* <Button
              className={classes?.deleteBtn}
              disabled={selectedRows?.length == 0 || isDeleting}
              onClick={deleteMultiple}>
              {isDeleting ? t('deleting') : t('delete')}
            </Button> */}
          </Col>
          <Col md={12}>
            <CustomMaterialTable
              headings={headings}
              noDataText="No Contacted Properties Found"
              data={data?.data}
              isLoading={isLoading || isFetching}
              showOptionsColumn
              containerClass={classes.tableContainer}
              options={[
                {
                  label:t('view'),
                  icon: <AiFillEye size={18} />,
                  onClick: (e, a) => {
                    setSelectedItem(a);
                    setShowModal('detail');
                  }
                }
              ]}
              onOptionClick={() => console.log()}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              // allowMultipleSelection
              rowsPerPage={recordsLimit}
              limit={recordsLimit}
              totalRecords={Math.ceil(data?.totalRecords / recordsLimit)}
              page={page}
              setPage={setPage}
            />
          </Col>
        </Row>
        {showModal == 'detail' && (
          <ContactedPropertyDetailModal
            data={selectedItem}
            show={showModal == 'detail'}
            setShow={setShowModal}
          />
        )}
      </AccountInfoLayout>
    </div>
  );
}

export default UserContactedProperties;
