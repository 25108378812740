import React from 'react';
import classes from './SearchInput.module.css';
import { GoSearch } from 'react-icons/go';
import Input from '../Input/Input';

const SearchInput = ({ state, setter, placeholder = 'Search here', onClick, variant = '',containerStyle={} }) => {
  return (
    <div className={classes.inputWithSearch} data-variant={variant} style={containerStyle}>
      {variant == 'dashboard' && <GoSearch size={40} className={classes.icon} />}
      <Input
        value={state}
        setValue={setter}
        type={'text'}
        placeholder={placeholder}
        customStyle={{
          border: 'none',
          width: '100%'
        }}
        inputStyle={{
          border: 'none',
          paddingBlock:'11px'
        }}
        
        className={classes.inputContainer}
      />
      {variant !== 'dashboard' && <GoSearch onClick={onClick} size={48} className={classes.icon} />}
    </div>
  );
};

export default SearchInput;
