import React from 'react';
import Button from '../../Component/Button/Button';
// import { FiAlertTriangle } from "react-icons/fi";
import classes from './AreYouSureModal.module.css';
import { Modal } from 'react-bootstrap';

import { MdDelete } from 'react-icons/md';
import { ImCancelCircle } from 'react-icons/im';
import { BsTriangleFill } from 'react-icons/bs';
const AreYouSureModal = ({
  show,
  title="Are you Sure",
  titleIcon=<BsTriangleFill size={20} fill='#FFFF'/>,
  setShow,
  subTitle,
  label1="Delete",
  label2="Cancel",
  btnApiCallText='Wait...',
  label1Icon=<MdDelete size={25}/>,
  label2Icon=<ImCancelCircle size={25}/>,
  onClick,
  isApiCall
}) => {
  return (
    <>
      <style>{`
        .modal-content {
          width: 100%;
          border-radius: 20px;
          overflow: hidden;
        }
        .modal-body {
          padding: 24px;
        }
        .modal-header {
          justify-content:center;
          gap:10px;
          align-items:center;
          background: var(--dashboard-main-color);
          border-bottom: none !important;
          padding: 0.75rem;
        }
        .name {
          font-size: 18px;
          color: var(--text-black-clr);
        }
      `}</style>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header className={classes?.header}>
          <div className={classes.iconWrap}>

            {titleIcon}
          </div>
          <h4 className={[classes.headingText].join(' ')}>{title}</h4>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.content}>
            <div className={classes.mainDiv}>
              <p className={[classes.message].join(' ')}>{subTitle}</p>
            </div>
            <div className={classes.btnsBox}>
              <Button
                leftIcon={label1Icon}
                className={classes.yesBtn}
                onClick={onClick}
                disabled={isApiCall}>
                {isApiCall ? btnApiCallText : label1}
              </Button>
              <Button
                leftIcon={label2Icon}
                className={classes.noBtn}
                onClick={async () => {
                  setShow(false);
                }}
                disabled={isApiCall}>
                {label2}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AreYouSureModal;

// import React from "react";
// import { Button } from "../../Component/Button/Button";
// import { FiAlertTriangle } from "react-icons/fi";
// import classes from "./AreYouSureModal.module.css";
// import { Modal } from "react-bootstrap";

// const AreYouSureModal = ({ show, setShow, subTitle, onClick, isApiCall }) => {
//   return (
//     <>
//       <style jsx>{`
//         .modal-content {
//           width: 100%;
//           border-radius: 20px;
//           overflow: hidden;
//         }
//         .modal-body {
//           padding: 24px;
//         }
//         .modal-header {
//           flex-direction: column;
//           background: var(--dashboard-main-color);
//           border-bottom: none;
//           padding: 0.75rem;
//         }
//         .name {
//           font-size: 18px;
//           color: var(--text-black-clr);
//         }
//         .modal .modal-dialog {
//           max-width: 500px;
//         }
//       `}</style>
//       <Modal show={show} onHide={() => setShow(false)} centered size="xs">
//         <Modal.Header>
//           <div className={[classes.iconDiv].join(" ")}>
//             <FiAlertTriangle size={"60px"} color={"var(--white-clr)"} />
//           </div>
//           <p className={[classes.header].join(" ")}>Are You Sure</p>
//         </Modal.Header>
//         <Modal.Body>
//           <div className={classes.content}>
//             <div className={classes.mainDiv}>
//               <h4>{subTitle}</h4>
//             </div>
//             <div className={classes.btnsBox}>
//               <Button
//                 className={classes.yesBtn}
//                 onClick={onClick}
//                 disabled={isApiCall}
//               >
//                 {isApiCall ? "Wait" : "Yes"}
//               </Button>
//               <Button
//                 className={classes.noBtn}
//                 onClick={async () => {
//                   setShow(false);
//                 }}
//                 disabled={isApiCall}
//               >
//                 No
//               </Button>
//             </div>
//           </div>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// };

// export default AreYouSureModal;
