import numeral from 'numeral';

// Area Converter
export const m2ToFt2 = ({ area = 0, areaUnit = 'ft²', unit = true }) => {
  return `${areaUnit === 'ft²' ? (area * 10.7639).toFixed(2) : area}` + ` ${unit ? areaUnit : ''}`;
};

// Currency Converter
export const usdToOther = ({ amount = 0, value = 0, symbol = '', isNumeral = true }) => {
  return `${symbol ? symbol : ''}${
    isNumeral ? numeral(amount * value).format('0,0') : amount * value
  }`;
};
