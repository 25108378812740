import React, { useRef } from 'react';
import classes from './AccountSettings.module.css';
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import { Col, Row } from 'react-bootstrap';
import Tabs from '../../Component/Tabs';
import Input from '../../Component/Input/Input';
import { useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { AiOutlineUser } from 'react-icons/ai';
import DropDown from '../../Component/DropDown/DropDown';
import AccountInfoLayout from '../../Component/AccountInfoLayout';
// import { avatar } from '../../constant/imagePath';
import ChangePasswordModal from '../../modals/ChangePasswordModal';
import { useDispatch, useSelector } from 'react-redux';
import { languagesOptions } from '../../constant/commonData';
import Button from '../../Component/Button/Button';
import { toast } from 'react-toastify';
import { ProfileWithEditButton } from '../../Component/ProfileWithEditButton';
import { useUpdateProfileMutation } from '../../store/apiSlice/apiSlice';
import { updateUser } from '../../store/auth/authSlice';
import { CreateFormData } from '../../config/apiUrl';
import { useTranslation } from 'react-i18next';

function AccountSettings() {
  const { t } = useTranslation('accountSettings');
  const personalInfo = { id: 1, text: t('personalInformation'), icon: <AiOutlineUser /> };
  const accountInfo = { id: 1, text: t('accountPreferences'), icon: <AiOutlineUser /> };
  const { user } = useSelector((state) => state?.authReducer);
  const [firstName, setFirstName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');
  const [displayName, setDisplayName] = useState(user?.displayName || '');
  const [email, setEmail] = useState(user?.email || '');
  const [showModal, setShowModal] = useState('');
  const [language, setLanguage] = useState(
    languagesOptions?.find((e) => e.label == user?.preferredLanguage) || ''
  );
  const [currency, setCurrency] = useState('');
  const [image, setImage] = useState(user?.photo || null);
  const inputRef = useRef(null);
  const [updateFunc, { isLoading: loadingUpdate }] = useUpdateProfileMutation();
  // const [measuringUnits, setMeasuringUnits] = useState('');
  // const [notifications, setNotifications] = useState('');
  const dispatch = useDispatch();

  console.log(user, 'user user user');
  const submitHandler = async () => {
    const data = {
      firstName,
      lastName,
      displayName,
      preferredLanguage: language?.label,
      // currency, //tbd
      photo: image
    };

    for (let key in data) {
      if (data[key] == '') {
        return toast.error(`${key} is required`);
      }
    }
    const formData = CreateFormData(data);

    const response = await updateFunc(formData);
    if (response?.data) {
      dispatch(updateUser(response?.data.data?.user));
      toast.success('Profile updated successfully');
    }
  };

  return (
    <>
      <div className={classes.page}>
        <Header />
        <AccountInfoLayout>
          <Row className={classes.row}>
            <Col md={12}>
              <Tabs
                tabsData={[personalInfo]}
                value={personalInfo}
                setValue={() => null}
                className={classes.tab}
                tabClass={classes.tabLi}
              />
            </Col>
            <Col md={12}>
              <div className={[classes.box, classes.gap20].join(' ')}>
                <div>
                  <ProfileWithEditButton
                    className={classes.avatar}
                    isEdit={true}
                    updateImage={image}
                    setUpdateImage={(e) => setImage(e)}
                  />
                  {/* <div className={classes.avatar}>
                    <img src={avatar} />
                  </div> */}
                  <input
                    type="file"
                    onChange={(e) => setImage(e.target.files[0])}
                    ref={inputRef}
                    style={{ display: 'none' }}
                  />
                  <button onClick={() => inputRef.current.click()} className={classes.uploadBtn}>
                    <FaUser /> <span>{t('uploadAvatar')}</span>
                  </button>
                </div>
                <Row className="gy-2">
                  <Col md={6}>
                    <Input
                      label={t('firstName')}
                      value={firstName}
                      setValue={setFirstName}
                      inputStyle={{ padding: '10px 20px' }}
                      placeholder={t('pleaseEnter')}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      label={t('lastName')}
                      value={lastName}
                      setValue={setLastName}
                      inputStyle={{ padding: '10px 20px' }}
                      placeholder={t('pleaseEnter')}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      label={t('displayName')}
                      value={displayName}
                      setValue={setDisplayName}
                      inputStyle={{ padding: '10px 20px' }}
                      placeholder={t('pleaseEnter')}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      disabled={true}
                      label={t('email')}
                      value={email}
                      setValue={setEmail}
                      inputStyle={{ padding: '10px 20px' }}
                      placeholder={t('pleaseEnter')}
                    />
                  </Col>
                  <Col md={12} className={classes.flexEnd}>
                    <span
                      className="txt-primary c-p"
                      onClick={() => {
                        setShowModal('changePassword');
                      }}>
                      {t('changePassword')}
                    </span>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={12}>
              <Tabs
                tabsData={[accountInfo]}
                value={accountInfo}
                setValue={() => null}
                className={classes.tab}
                tabClass={classes.tabLi}
              />
            </Col>
            <Col md={12}>
              <div className={[classes.box].join(' ')}>
                <Row className="gy-2">
                  <Col xl={4} md={6}>
                    <DropDown
                      label={t('language')}
                      options={languagesOptions}
                      optionLabel={'label'}
                      optionValue={'value'}
                      customStyle={{ padding: '0px 2px' }}
                      setValue={setLanguage}
                      value={language}
                      placeholder={t('pleaseEnter')}
                    />
                  </Col>
                  <Col xl={4} md={6}>
                    <DropDown
                      setValue={setCurrency}
                      value={currency}
                      label={t('currency')}
                      optionLabel={'label'}
                      optionValue={'value'}
                      customStyle={{ padding: '0px 2px' }}
                      placeholder={t('pleaseEnter')}
                    />
                  </Col>
                  <Col xl={4} md={6}>
                    <DropDown
                      label={t('measuringUnits')}
                      customStyle={{ padding: '0px 2px' }}
                      placeholder={t('pleaseEnter')}
                    />
                  </Col>
                  {/* <Col xl={3} md={6}>
                    <DropDown    label={'Notifications'} customStyle={{ padding: '0px 2px' }} />
                  </Col> */}
                </Row>
              </div>
            </Col>
            <Col>
              <div className="text-center">
                <Button
                  onClick={submitHandler}
                  label={loadingUpdate ? t('updating') : t('update')}
                  disabled={loadingUpdate}
                />
              </div>
            </Col>
          </Row>
        </AccountInfoLayout>
      </div>
      <Footer />
      {showModal == 'changePassword' && (
        <ChangePasswordModal
          show={showModal == 'changePassword'}
          setShow={() => setShowModal('')}
        />
      )}
    </>
  );
}

export default AccountSettings;
