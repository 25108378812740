import { GoogleMap, Marker, StreetViewPanorama } from '@react-google-maps/api';
import classes from './MainMain.module.css';
import { mapMarker } from '../../constant/imagePath';

export default function Map({ location, className='', zoom, options={}, isPanorama }) {
  // const center = useMemo(() => location, []);
  // const center = useMemo(() => location, []);
  //   const options = useMemo(() => ({ mapId: "b37671901ca60f9f" }), []);
  // const options = useMemo(() => ({ mapId: "6a59d5a654e7c4b1" }), []); //dark
  return (
    <div className={`${classes?.container} ${className}`}>
      {/* Map */}
      <GoogleMap
        zoom={zoom}
        center={location}
        options={{...options}}
        mapContainerClassName={classes['map-container']}>
        {isPanorama && (
          <StreetViewPanorama
            position={location}
            visible={true}
            options={{
              // show buttons to move
              addressControl: true,
              linksControl: true,
              panControl: true,
              enableCloseButton: false,
              fullscreenControl: true,
              zoomControl: true,
              motionTracking: true,
              motionTrackingControl: true,
              movementTracking: true,


              pov: {
                heading: 34,
                pitch: 10
              }
            }}
          />
        )}
        {location && (
          <>
            <Marker position={location} icon={mapMarker} />
          </>
        )}
      </GoogleMap>
    </div>
  );
}
