import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import Popover from 'react-bootstrap/Popover';
import classes from './customOverlay.module.css';
import { useRef } from 'react';
import { ClickAwayListener } from '@mui/material';

function CustomOverlay({
  children,
  targetElement,
  show,
  target,
  handleClose,
  popoverClass = '',
  onToggle
}) {
  const ref = useRef(null);

  return (
    <ClickAwayListener onClickAway={() => show != '' && handleClose('')}>
      <div className={classes.overlayDiv} ref={ref}>
        <style>{`
            .popover-arrow{
                left:unset !important;
                right: 3% !important;
               transform: translate(-50%, 0px) !important;
            }
            `}</style>

        <OverlayTrigger
          show={show}
          target={target}
          container={ref}
          // onHide={() => handleClose('')}
          onToggle={() => {
            onToggle && onToggle();
            handleClose('');
          }}
          trigger="click"
          placement="bottom"
          overlay={
            <Popover
              id={`popover-positioned-bottom`}
              className={[classes.popover, popoverClass].join(' ')}>
              {children}
            </Popover>
          }>
          {targetElement}
        </OverlayTrigger>
      </div>
    </ClickAwayListener>
  );
}

export default CustomOverlay;
