import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { AiFillPrinter } from 'react-icons/ai';
import { BsFillShareFill } from 'react-icons/bs';
import { IoIosMail, IoLogoWhatsapp } from 'react-icons/io';
import { MdWifiCalling3 } from 'react-icons/md';
import { SlUserFollow, SlUserFollowing } from 'react-icons/sl';
import ShowMoreShowLessText from '../ShowMoreShowLess/ShowMoreShowLessText';
import classes from './AgentIntroSection.module.css';

import BannerImageAd from '../BannerImageAd';
import ShareModal from '../ShareModal/ShareModal';
// import Button from '../Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { restrictOnlyUserActions } from '../../Helper/restrictActions';
import { imageUrl } from '../../config/apiUrl';
import CompanyOrAgentEmailModal from '../../modals/CompanyOrAgentEmailModal';
import { useFollowUnFollowCompanyOrAgentMutation } from '../../store/apiSlice/apiSlice';
import { updateUser } from '../../store/auth/authSlice';
import Button from '../Button/Button';
import Tooltip from '../Tooltip';

const AgentIntroSection = ({ className, data, t, verticalBanner }) => {
  const dispatch = useDispatch();
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const navigate = useNavigate();
  const [isShareModalOpen, setIsShareModalOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [onFollow, { isLoading }] = useFollowUnFollowCompanyOrAgentMutation();
  const { isLogin, user } = useSelector((state) => state.authReducer);

  const isFollowed = user?.agentFollowing?.includes(data?._id);

  const handleAction = () => {
    const isAllowedActions = restrictOnlyUserActions(user?.role);
    if (!isLogin) {
      navigate('/login');
    } else if (isAllowedActions) {
      return true;
    }
  };

  async function handleFollow() {
    if (!handleAction()) return;
    const res = await onFollow(data?._id);
    if (res?.data) {
      dispatch(updateUser(res?.data?.data));
      toast.success(`${'Agent'} ${isFollowed ? 'unfollowed' : 'followed'} successfully`);
    }
  }

  return (
    <section className={className && className}>
      <Row className={classes.row}>
        {/* Basic Intro */}
        <Col sm={12} md={4} lg={3}>
          <div className={classes?.agentIntroContainer}>
            <div className={classes?.profileImageContainer}>
              <img src={imageUrl(data?.photo)} />
            </div>
            <h5>{data?.agentName?.[selectedLanguage]}</h5>
            <p>{data?.agentDesignation?.[0]?.[selectedLanguage]}</p>
            {/* <div className={classes.edit_btn}>
              <Button onClick={() => null} borderRadius label={'Edit'} />
            </div> */}

            {/* <div className={classes?.iconsContainer}>
              <div>
                <MdWifiCalling3 fill="#E2831B" size={30} />
              </div>
              <div>
                <GoMail fill="#E2831B" size={30} />
              </div>
              <div>
                <IoLogoWhatsapp fill="#E2831B" size={30} />
              </div>
            </div> */}
            <div className={classes.socialIconsWrap1}>
              <div className={classes.socialIcons1}>
                <Tooltip text={t('agent.tooltips.call')} href={`tel:${data?.phoneNumber}`}>
                  <MdWifiCalling3 fill="#E2831B" size={30} />
                </Tooltip>
              </div>
              <div
                className={[classes.socialIcons1, classes.midIcon].join(' ')}
                onClick={() => setIsModalOpen(true)}>
                <Tooltip text={t('agent.tooltips.email')}>
                  <IoIosMail fill="#E2831B" size={32} />
                </Tooltip>
              </div>
              <div className={classes.socialIcons1}>
                <Tooltip
                  text={t('agent.tooltips.whatsapp')}
                  href={`https://wa.me/${data?.whatsapp}`}
                  target={'_blank'}>
                  <IoLogoWhatsapp fill="#E2831B" size={30} />
                </Tooltip>
              </div>
            </div>
          </div>
          {/* Follow Btn */}
          <Button
            disabled={isLoading}
            className={classes?.followBtnContainer}
            onClick={handleFollow}>
            {isLoading ? (
              ''
            ) : isFollowed ? (
              <SlUserFollowing fill="#fff" size={25} />
            ) : (
              <SlUserFollow fill="#fff" size={25} />
            )}
            {isLoading
              ? t('agent.followBtnLoading')
              : isFollowed
              ? t('agent.following')
              : t('agent.follow')}
          </Button>
        </Col>

        {/* About Me */}
        <Col sm={12} md={8} lg={7}>
          <div className={classes?.aboutMeContainer}>
            {/* company logo + share */}
            <div className={classes?.companyLogoAndShareContainer}>
              <div className={classes?.companyInfo}>
                <div
                  className={classes?.companyLogoContainer}
                  onClick={() => navigate(`/company/${data?.company?.slug}`)}>
                  <img src={imageUrl(data?.company?.photo)} />
                </div>
                <p>{data?.company?.companyType?.[selectedLanguage]}</p>
              </div>
              {/* Share + print */}
              <div className={classes?.shareLogoContainer}>
                <div onClick={() => setIsShareModalOpen(true)}>
                  <BsFillShareFill fill="#a4a4a4" size={25} />
                </div>
                <div>
                  <AiFillPrinter fill="#a4a4a4" size={25} />
                </div>
              </div>
            </div>

            {/* Agent Details */}
            <div className={classes?.agentDetailsContainer}>
              <Row className={classes?.agentDetails}>
                <Col md={3}>{t('agent.weSpeak')} </Col>
                <Col md={9}>{data?.language?.map((e) => e?.[selectedLanguage])?.join(', ')}</Col>
              </Row>
              <Row className={classes?.agentDetails}>
                <Col md={3}>{t('agent.properties')}</Col>
                <Col md={9}>
                  ({data?.buyProperties ?? 0}) {t('agent.forBuy')}, ({data?.rentProperties ?? 0}){' '}
                  {t('agent.forRent')}, ({data?.totalProjects ?? 0}) {t('agent.projects')}, (
                  {data?.totalEvents ?? 0}) {t('agent.events')}
                </Col>
              </Row>
              <Row className={classes?.agentDetails}>
                <Col md={3}>{t('agent.serviceAreas')} </Col>
                <Col md={9}>{data?.serviceAreas?.[selectedLanguage]}</Col>
              </Row>
            </div>

            {/* Description */}
            <div className={classes?.descriptionContainer}>
              <h5>{t('agent.aboutMe')}</h5>
              <ShowMoreShowLessText text={data?.description?.[selectedLanguage]} visibility={200} />
            </div>
          </div>
        </Col>

        {/* Banner Ad */}
        <Col sm={12} md={12} lg={2}>
          <div className={classes?.bannerAdContainer}>
            <BannerImageAd image={verticalBanner} className={classes.banner} />
          </div>
        </Col>
      </Row>

      {isShareModalOpen && <ShareModal show={isShareModalOpen} setShow={setIsShareModalOpen} />}
      {isModalOpen && (
        <CompanyOrAgentEmailModal
          show={isModalOpen}
          setShow={setIsModalOpen}
          type="agent"
          data={data}
        />
      )}
    </section>
  );
};

export default AgentIntroSection;
