/* eslint-disable */
import React from 'react';
import classes from './ProjectDetail.module.css';
import { Col, Container, Row } from 'react-bootstrap';
import Tabs from '../../Component/Tabs';
import {
  MdLocationOn,
  MdPhotoLibrary,
  MdOutlineBedroomChild,
  MdOutline360,
  MdOutlineBathtub,
  MdOutlineHotel
} from 'react-icons/md';
import { FaLevelUpAlt, FaStreetView, FaVideo } from 'react-icons/fa';
import { IoLocationSharp, IoPricetagOutline } from 'react-icons/io5';
import { useState } from 'react';
import { RiHotelFill } from 'react-icons/ri';
import HeadingWithUnderline from '../../Component/HeadingWithUnderline';
import ListItemWithCheckMark from '../../Component/ListItemWithCheckMark';
import MortgageCalculator from '../../Component/MortgageCalculator';
import ClientManagerCard from '../../Component/ClientManagerCard';
import { BiArea, BiBath, BiBuildingHouse } from 'react-icons/bi';
import { TbCalendarTime, TbParking } from 'react-icons/tb';
import { BsDownload, BsGrid3X3Gap, BsShareFill } from 'react-icons/bs';

import ElasticCarousel from '../../Component/ElasticCarousel';
import BannerImageAd from '../../Component/BannerImageAd';
import Button from '../../Component/Button/Button';
import { DateTime, NoOfUnits, horizontalBanner, map1, map3 } from '../../constant/imagePath';
// import MapMain from '../../Component/maps/MapMain';
// import PanoramaView from '../../Component/PanoramaView/PanoramaView';
import DetailPageHeroSection from '../../Component/DetailPageHeroSection';
import ShareModal from '../../Component/ShareModal/ShareModal';
import { AiOutlineHeart, AiFillHeart, AiOutlineNumber } from 'react-icons/ai';
import { GiPackedPlanks } from 'react-icons/gi';
import Tooltip from '../../Component/Tooltip';
// import { ImFolderDownload } from 'react-icons/im';
import Header from '../../Component/Header';
import CustomOverlay from '../../Component/CustomOverlay';
import Footer from '../../Component/Footer';
import BankLoanBanner from '../../Component/BankLoanBanner';
import IconList from '../../Component/IconList/IconList';
import moment from 'moment';
import { ReactSVG } from 'react-svg';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useFavoriteEntityMutation,
  useGetPublicProjectsDetailQuery
} from '../../store/apiSlice/apiSlice';
import { imageUrl, pdfUrl } from '../../config/apiUrl';
import { useSelector } from 'react-redux';
import { downloadFileFromURL } from '../../Helper/downloadFile';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Loader } from '../../Component/Loader';
import { toast } from 'react-toastify';
import { restrictOnlyUserActions } from '../../Helper/restrictActions';
import { m2ToFt2, usdToOther } from '../../utils/helpers';
// import AverageRatesWithMarketTrends from '../../Component/AverageRatesWithMarketTrends';
const availableUnits = [
  '1 Bed (100 - 150 m2)',
  '2 Beds (200 - 250 m2)',
  '3 Beds (250 - 300 m2)',
  '4 Beds (300 - 350 m2)'
];

const plansPicture = [map1, map3];
const images = [
  'https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1549396535-c11d5c55b9df?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550133730-695473e544be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550167164-1b67c2be3973?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550338861-b7cfeaf8ffd8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550223640-23097fc71cb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550353175-a3611868086b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550330039-a54e15ed9d33?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1549737328-8b9f3252b927?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1549833284-6a7df91c1f65?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1549985908-597a09ef0a7c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
  'https://images.unsplash.com/photo-1550064824-8f993041ffd3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60'
];

// const tabsData = [
//   { icon: <MdPhotoLibrary />, text: 'Photos', id: '1' },
//   { icon: <GiPackedPlanks />, text: 'plans', id: '2' },
//   { icon: <MdOutline360 />, text: '360 View', id: '3' },
//   { icon: <IoLocationSharp />, text: 'Location', id: '4' },
//   { icon: <FaStreetView />, text: 'Street View', id: '5' },
//   { icon: <FaVideo />, text: 'Video', id: '6' }
// ];

const ProjectDetail = () => {
  const navigate = useNavigate();
  const { user, isLogin } = useSelector((state) => state?.authReducer);
  const { areaUnit, currency } = useSelector((state) => state?.converterReducer);

  const { t } = useTranslation('projectDetail');
  const tabsData = [
    { icon: <MdPhotoLibrary />, text: t('tabs.photos'), id: '1', value: 'Photos' },
    { icon: <GiPackedPlanks />, text: t('tabs.plans'), id: '2', value: 'Plans' },
    { icon: <MdOutline360 />, text: t('tabs.360view'), id: '3', value: '360' },
    { icon: <IoLocationSharp />, text: t('tabs.location'), id: '4', value: 'Location' },
    { icon: <FaStreetView />, text: t('tabs.streetView'), id: '5', value: 'Street' },
    { icon: <FaVideo />, text: t('tabs.video'), id: '6', value: 'Video' }
  ];

  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const slug = useParams()?.slug;
  const { data, isLoading, refetch } = useGetPublicProjectsDetailQuery({ slug });
  const projectData = data?.data;

  const [tabValue, setTabValue] = useState(tabsData[0]);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(projectData?.projectUnit[0]);

  const [onFavorite, { isLoading: isFavoriting }] = useFavoriteEntityMutation();

  const isFavorite = projectData?.favoriteBy?.includes(user?._id);

  const handleFavorite = async () => {
    if (isFavoriting) return;
    const res = await onFavorite({ id: projectData?._id, type: 'project' });
    if (res?.data) {
      if (res?.data?.data?.favoriteBy?.includes(user?._id)) {
        toast.success(`${'Project added in favorite list'} successfully`);
      } else {
        toast.success(`${'Project removed from favorite list'} successfully`);
      }
      refetch();
    }
  };

  const handleAction = () => {
    const isAllowedActions = restrictOnlyUserActions(user?.role);
    if (!isLogin) {
      navigate('/login');
    } else if (isAllowedActions) {
      return true;
    }
  };

  useEffect(() => {
    if (!!projectData?.projectUnit) {
      setSelectedUnit(projectData?.projectUnit[0]);
    }
  }, [isLoading]);

  // const imageArrayMap = {
  //   plans: <ElasticCarousel images={images} />,
  //   Photos: <ElasticCarousel images={plansPicture} />,
  //   360: <PanoramaView />,
  //   Street: (
  //     <div className={classes?.mapContainer}>
  //       <MapMain mapClass={classes?.mapClass} zoom={13} isPanorama={true} />
  //     </div>
  //   ),
  //   Video: (
  //     <div className={classes?.mapContainer}>
  //       <video
  //         controls
  //         autoPlay
  //         playsInline
  //         name="media"
  //         width={'100%'}
  //         height={'100%'}
  //         style={{
  //           background: 'black'
  //         }}>
  //         <source
  //           src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
  //           type="video/mp4"
  //         />
  //       </video>
  //     </div>
  //   ),
  //   Plans: <MapMain mapClass={classes?.mapClass} zoom={13} options={mapOptions} />,
  //   Location: (
  //     <div className={classes?.mapContainer}>
  //       <MapMain mapClass={classes?.mapClass} zoom={13} options={mapOptions} />
  //     </div>
  //   )
  // };
  return (
    <>
      <style>
        {`
      
      .${classes.unitfloorImages} .rec-swipable{
        height:330px !important;
    }
    .${classes.popoverMain} .popover-arrow {
      left: unset !important;
      right: 50% !important;
      transform: translate(-50%, 0px) !important;
  }

    @media (max-width:600px){
      .${classes.unitfloorImages} .rec-swipable{
        height:250px !important;
    }
    }

    
      `}
      </style>
      <Header containerClass={''} />

      {isLoading ? (
        <Loader />
      ) : (
        <div className={`${classes.main}`}>
          <section className={classes.images}>
            <DetailPageHeroSection
              // plansPicture={plansPicture} images={images} text={tabValue?.value}
              plansPicture={projectData?.planImages ?? []}
              images={projectData?.images ?? []}
              text={tabValue?.value}
              videoLink={projectData?.videoLink ?? ''}
              location={{
                lat: projectData?.location?.coordinates?.[1],
                lng: projectData?.location?.coordinates?.[0]
              }}
              threeSixtyImage={projectData?.threeSixtyViewLink}
            />
            {/* {imgArray} */}
            {/* {tabValue?.text?.toLocaleLowerCase() === 'photos' && (
              <ElasticCarousel images={images} />
            )}
            {tabValue?.text?.toLocaleLowerCase() === 'plans' && (
              <ElasticCarousel images={plansPicture} />
            )}
            {tabValue?.text?.toLocaleLowerCase() === 'location' && (
              <div className={classes?.mapContainer}>
                <MapMain mapClass={classes?.mapClass} zoom={13} options={mapOptions} />
              </div>
            )}
            {tabValue?.text?.toLocaleLowerCase() === '360 view' && <PanoramaView />}
            {tabValue?.text?.toLocaleLowerCase() === 'street view' && (
              <div className={classes?.mapContainer}>
                <MapMain mapClass={classes?.mapClass} zoom={13} isPanorama={true} />
              </div>
            )}
            {tabValue?.text?.toLocaleLowerCase() === 'video' && (
              <div className={classes?.mapContainer}>
                <video
                  controls
                  autoPlay
                  playsInline
                  name="media"
                  width={'100%'}
                  height={'100%'}
                  style={{
                    background: 'black'
                  }}>
                  <source
                    src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            )} */}
          </section>
          <Container className={[classes.container].join(' ')}>
            <Row className={classes.gap}>
              <Col md={12} lg={9} className={classes.col1}>
                <Tabs
                  className={classes.tabs_main}
                  value={tabValue}
                  // setValue={(e) => {
                  //   setTabValue(e);
                  // }}
                  tabClass={classes.tab}
                  setValue={(e) => {
                    setTabValue(e);
                  }}
                  tabsData={tabsData}
                />
                <div className={classes.detailWrap}>
                  <div className={classes.priceRow}>
                    <div className={classes.mainImage}>
                      <img src={imageUrl(projectData?.logo)} alt="" />
                    </div>
                    <div className={classes.contentMain}>
                      <div className={classes.title}>
                        <p>{projectData?.title?.[selectedLanguage]}</p>
                        <Button
                          className={classes.btn}
                          leftIcon={<BsDownload />}
                          onClick={() => {
                            downloadFileFromURL(
                              pdfUrl(projectData?.projectBrochure),
                              projectData?.projectBrochureName
                            );
                          }}>
                          {t('downloadBrochure')}
                        </Button>
                        {/* <Tooltip text={'Download Brouchure'}>
                        <ImFolderDownload size={30} fill="var(--primary-clr)" />
                      </Tooltip> */}
                      </div>
                      <div className={classes.price}>
                        <p>{usdToOther({ amount: projectData?.price, ...currency })}</p>
                      </div>
                      <div className={classes.desc}>
                        <p>{projectData?.description?.[selectedLanguage]}</p>
                      </div>

                      <div className={classes.addressWrap}>
                        <MdLocationOn color="var(--text-dark-gray-clr)" />
                        <p>{projectData?.address}</p>
                      </div>
                    </div>
                  </div>
                  <div className={classes.propertyDetailWrap}>
                    {/* <div className={classes.gap}> */}
                    {/* <div className={classes?.propertyInfoContainer}> */}

                    <IconList
                      className={classes.infoIcons}
                      Icon={BiBuildingHouse}
                      label={t('tooltips.projectType')}
                      value={projectData?.projectType?.title?.[selectedLanguage]}
                    />
                    <div md={6} className={classes.propertyDetail}>
                      <Tooltip text={t('tooltips.noOfUnits')}>
                        <ReactSVG
                          fill="var(--text-dark-gray-clr)"
                          className={classes.svgIcon}
                          src={NoOfUnits}
                        />
                      </Tooltip>

                      <p>
                        {projectData?.noOfUnits} {t('units')}
                      </p>
                    </div>

                    <div className={[classes.propertyDetail, classes.infoIcons].join(' ')}>
                      <Tooltip text={t('tooltips.deliveryDate')}>
                        <ReactSVG
                          color="var(--text-dark-gray-clr)"
                          className={classes.svgIcon}
                          src={DateTime}
                        />
                      </Tooltip>
                      <p>{moment(projectData?.deliveryDate).format('ll')}</p>
                    </div>

                    {/* </div> */}

                    {/* <div className={classes.propertyDetail}>
                    <Tooltip text="Property Type">
                      <RiHotelFill color="var(--text-dark-gray-clr)" />
                    </Tooltip>
                    <p>{data?.type}</p>
                  </div>
                  <div className={classes.propertyDetail}>
                    <Tooltip text="Area (sq ft)">
                      <BsGrid3X3Gap color="var(--text-dark-gray-clr)" />
                    </Tooltip>

                    <p>{data?.area}</p>
                  </div>
                  <div className={classes.propertyDetail}>
                    <Tooltip text="Rooms">
                      <MdOutlineHotel color="var(--text-dark-gray-clr)" />
                    </Tooltip>
                    <p>{data?.noOfBedRooms}</p>
                  </div>
                  <div className={classes.propertyDetail}>
                    <Tooltip text="Bathrooms">
                      <MdOutlineBathtub color="var(--text-dark-gray-clr)" />
                    </Tooltip>
                    <p>{data?.noOfBathRooms}</p>
                  </div>
                  <div className={classes.propertyDetail}>
                    <Tooltip text="Level">
                      <FaLevelUpAlt color="var(--text-dark-gray-clr)" />
                    </Tooltip>

                    <p>{data?.level}</p>
                  </div> */}
                    {/* </div> */}
                  </div>
                  <div className={classes.overView}>
                    <HeadingWithUnderline underlineColor="var(--secondary-clr)">
                      <h5>{t('overview.title')}</h5>
                    </HeadingWithUnderline>
                    <Row className={classes.overViewRow}>
                      <Col md={12} lg={6} className={classes.overviewCol}>
                        <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                          <div className={`flex-sb ${classes.listItem}`}>
                            <span>{t('overview.listingID')}</span>
                            <span>{projectData?.listingId}</span>
                          </div>
                        </ListItemWithCheckMark>
                        <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                          <div className={`flex-sb ${classes.listItem}`}>
                            <span>{t('overview.type')}</span>
                            <span>{projectData?.projectType?.title?.[selectedLanguage]}</span>
                          </div>
                        </ListItemWithCheckMark>

                        <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                          <div className={`flex-sb ${classes.listItem}`}>
                            <span>{t('overview.price')}</span>
                            <span>$ {`${projectData?.price}`} </span>
                          </div>
                        </ListItemWithCheckMark>
                        <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                          <div className={`flex-sb ${classes.listItem}`}>
                            <span>{t('overview.developer')}</span>
                            <span>{projectData?.developerName?.[selectedLanguage]}</span>
                          </div>
                        </ListItemWithCheckMark>
                      </Col>
                      <Col md={12} lg={6} className={classes.overviewCol}>
                        <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                          <div className={`flex-sb ${classes.listItem}`}>
                            <span>{t('overview.area')}</span>
                            <span>
                              {projectData?.minimumArea} - {projectData?.maximumArea}
                            </span>
                          </div>
                        </ListItemWithCheckMark>

                        {/* Start Year is Nothing Here */}

                        {/* <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                        <div className={`flex-sb ${classes.listItem}`}>
                          <span>Start Year</span>
                          <span>{moment().format('YYYY')}</span>
                        </div>
                      </ListItemWithCheckMark> */}

                        {/* Start Year is Nothing Here */}

                        <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                          <div className={`flex-sb ${classes.listItem}`}>
                            <span>{t('overview.noOfUnits')}</span>
                            <span>{projectData?.noOfUnits}</span>
                          </div>
                        </ListItemWithCheckMark>
                        {/* <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                        <div className={`flex-sb ${classes.listItem}`}>
                          <span>Currency</span>
                          <span>{'dollar'}</span>
                        </div>
                      </ListItemWithCheckMark>
                      <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                        <div className={`flex-sb ${classes.listItem}`}>
                          <span>Unit</span>
                          <span>{'m2'}</span>
                        </div>
                      </ListItemWithCheckMark> */}
                        <ListItemWithCheckMark mainClass={classes.list} showCheck={false}>
                          <div className={`flex-sb ${classes.listItem}`}>
                            <span>{t('overview.plannedDeliveryDate')}</span>
                            <span>
                              {' '}
                              {moment(projectData?.deliveryDate).format('DD MMM YYYY hh:mm')}
                            </span>
                          </div>
                        </ListItemWithCheckMark>
                      </Col>
                    </Row>
                  </div>
                  <div className={classes.description}>
                    <HeadingWithUnderline underlineColor="var(--secondary-clr)">
                      <h5>{t('description')}</h5>
                    </HeadingWithUnderline>
                    <p>{projectData?.description?.[selectedLanguage]}</p>
                  </div>

                  <div className={classes.interior}>
                    <HeadingWithUnderline underlineColor="var(--secondary-clr)">
                      <h5>{t('amenities')}</h5>
                    </HeadingWithUnderline>
                    <div className={classes.interior}>
                      <HeadingWithUnderline>
                        <h6>{t('interiorAmenities')}</h6>
                      </HeadingWithUnderline>
                      <Row className={classes.interior}>
                        {projectData?.interiorAmenities?.map((item, index) => (
                          <Col md={4} key={index}>
                            <ListItemWithCheckMark mainClass="flex-align-center">
                              <div className={`flex-sb ${classes.floor_unit_item}`}>
                                <span>{item?.title?.[selectedLanguage]}</span>
                              </div>
                            </ListItemWithCheckMark>
                          </Col>
                        ))}
                      </Row>
                    </div>
                    <div className={classes.interior}>
                      <HeadingWithUnderline>
                        <h6>{t('exteriorAmenities')}</h6>
                      </HeadingWithUnderline>
                      <Row className={classes.interior}>
                        {projectData?.exteriorAmenities?.map((item, index) => (
                          <Col md={4} key={index}>
                            <ListItemWithCheckMark mainClass="flex-align-center">
                              <div className={`flex-sb ${classes.floor_unit_item}`}>
                                <span>{item?.title?.[selectedLanguage]}</span>
                              </div>
                            </ListItemWithCheckMark>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                  {!!projectData?.projectUnit?.length && (
                    <div className={classes.unit_floor_main}>
                      <HeadingWithUnderline
                        className="mb-4 mt-4"
                        underlineColor="var(--secondary-clr)">
                        <h5>{t('unitInfo.units')}</h5>
                      </HeadingWithUnderline>
                      <div style={{ marginBottom: '20px' }}>
                        <CustomOverlay
                          popoverClass={classes.popoverMain}
                          show={show}
                          target={target}
                          handleClose={(event) => {
                            setShow(!show);

                            if (event) {
                              setTarget(event.target);
                            } else {
                              setTarget(null);
                            }
                          }}
                          targetElement={
                            <Button className={classes.view_btn} label={'Available Units'} />
                          }>
                          <div className={[classes.overlayBox, classes.p0].join(' ')}>
                            <ul className={classes.listOptionsWithBrBtm}>
                              {projectData?.projectUnit?.map((item, key) => (
                                <li
                                  key={key}
                                  data-selected={item?._id == selectedUnit?._id}
                                  // onClick={() => applyFilters('price', { price: item })}
                                  onClick={() => setSelectedUnit(item)}>
                                  {item?.noOfRooms?.[selectedLanguage]} Rooms -
                                  {m2ToFt2({ area: item?.area, areaUnit })}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </CustomOverlay>
                      </div>
                      <div>
                        <div className={classes.border_unit}>
                          <Row className={classes.unit_floor_row}>
                            <Col md={7}>
                              <ElasticCarousel
                                className={classes.unitfloorImages}
                                images={selectedUnit?.images ?? []}
                                breakPoints={[{ width: 1, itemsToShow: 1 }]}
                              />
                            </Col>
                            <Col md={5}>
                              <div className={classes.unit_floor_list_div}>
                                <HeadingWithUnderline underlineColor="var(--secondary-clr)">
                                  <h6 className="mb-4">{t('unitInfo.typeOfUnitInfo')}</h6>
                                </HeadingWithUnderline>
                                <ListItemWithCheckMark showCheck={false} mainClass={classes?.list}>
                                  <div className={`flex-sb ${classes.floor_unit_item}`}>
                                    <span>
                                      <IoPricetagOutline /> {t('unitInfo.price')}
                                    </span>
                                    <span> ${selectedUnit?.price}</span>
                                  </div>
                                </ListItemWithCheckMark>
                                <ListItemWithCheckMark showCheck={false} mainClass={classes.list}>
                                  <div className={`flex-sb ${classes.floor_unit_item}`}>
                                    <span>
                                      <BiArea /> {t('unitInfo.area')}
                                    </span>
                                    <span>{m2ToFt2({ area: selectedUnit?.area, areaUnit })}</span>
                                  </div>
                                </ListItemWithCheckMark>
                                <ListItemWithCheckMark showCheck={false} mainClass={classes.list}>
                                  <div className={`flex-sb ${classes.floor_unit_item}`}>
                                    <span>
                                      <MdOutlineBedroomChild /> {t('unitInfo.rooms')}
                                    </span>
                                    <span> {selectedUnit?.noOfRooms?.[selectedLanguage]}</span>
                                  </div>
                                </ListItemWithCheckMark>
                                <ListItemWithCheckMark showCheck={false} mainClass={classes.list}>
                                  <div className={`flex-sb ${classes.floor_unit_item}`}>
                                    <span>
                                      <BiBath /> {t('unitInfo.bathrooms')}
                                    </span>
                                    <span> {selectedUnit?.noOfBathrooms}</span>
                                  </div>
                                </ListItemWithCheckMark>
                                <ListItemWithCheckMark showCheck={false} mainClass={classes.list}>
                                  <div className={`flex-sb ${classes.floor_unit_item}`}>
                                    <span>
                                      <TbParking /> {t('unitInfo.carParking')}
                                    </span>
                                    <span>{selectedUnit?.carParking}</span>
                                  </div>
                                </ListItemWithCheckMark>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* <AverageRatesWithMarketTrends /> */}
                  <MortgageCalculator tKey="projectDetail" heading="" />
                </div>
              </Col>

              {/* Right column */}
              <Col md={12} lg={3} className={classes.col2}>
                <div className={classes.socialIconsWrap1}>
                  <div className={[classes.socialIcons1, classes.borderRight].join(' ')}>
                    <BsShareFill
                      onClick={() => setIsShareModalOpen(true)}
                      fill="var(--primary-clr)"
                      size={25}
                    />
                  </div>
                  <div className={[classes.socialIcons1].join(' ')}>
                    {isFavorite ? (
                      <AiFillHeart
                        onClick={() => {
                          if (!handleAction()) return;
                          handleFavorite();
                        }}
                        fill="var(--primary-clr)"
                        size={25}
                      />
                    ) : (
                      <AiOutlineHeart
                        onClick={() => {
                          if (!handleAction()) return;
                          handleFavorite();
                        }}
                        fill="#9d9e9e"
                        size={25}
                      />
                    )}
                  </div>
                  {/* <div className={[classes.socialIcons1].join(' ')}>
                  <IoMdPrint fill="var(--primary-clr)" size={25} />
                </div> */}
                </div>
                <div className={classes.clientCard}>
                  <ClientManagerCard
                    pageData={projectData}
                    dataType={'project'}
                    data={projectData}
                  />
                </div>
                {/* <div className={classes?.bannerAdContainer}>
                <BannerImageAd image={verticalBanner} className={classes.verticalBanner} />
              </div> */}
              </Col>
            </Row>
          </Container>
          <BankLoanBanner />
          <Container className="">
            <div className={classes?.bannerAdContainer}>
              <BannerImageAd image={horizontalBanner} className={classes.horizontalBanner} />
            </div>
          </Container>
          {isShareModalOpen && <ShareModal show={isShareModalOpen} setShow={setIsShareModalOpen} />}
        </div>
      )}
      <Footer />
    </>
  );
};

export default ProjectDetail;
// const mapOptions = {
//   mapTypeControl: false,
//   streetViewControl: true,
//   zoomControl: false,
//   styles: [
//     {
//       featureType: 'poi',
//       elementType: 'labels'
//     }
//   ]
// };

const data = {
  price: '2000',
  startPrice: '2000',
  endPrice: '4000',
  title: 'Marina Towers',
  location: '2415 Byron Shire, New South - Wales',
  desc: 'Full floor for sale I All unit already',
  type: 'Apartment',
  area: '200',
  noOfBedRooms: '20',
  noOfBathRooms: '20',
  noOfUnits: '20',
  level: '2',
  listingNumber: '12',
  parkingSpaces: '2',
  furniture: 'yes',
  floorLevel: '3',
  propertyOrientation: 'yes',
  propertyAge: '20',
  propertyStatus: 'Commercial',
  description:
    ' Description Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus',
  interiorAmenities: [
    'AirConditioning',
    'Heating',
    'Balcony',
    'Indoor firePlace',
    'AirConditioning',
    'Heating',
    'Balcony',
    'Indoor firePlace',
    'AirConditioning',
    'Heating',
    'Balcony',
    'Indoor firePlace'
  ],
  exteriorAmenities: [
    'AirConditioning',
    'Heating',
    'Balcony',
    'Indoor firePlace',
    'AirConditioning',
    'Heating',
    'Balcony',
    'Indoor firePlace',
    'AirConditioning',
    'Heating',
    'Balcony',
    'Indoor firePlace'
  ]
};
