import React from 'react';
import classes from './BankLoanBanner.module.css';
import { Col, Container, Row } from 'react-bootstrap';
import Button from '../Button/Button';
import { BankLoanImage } from '../../constant/imagePath';
import { useNavigate } from 'react-router-dom';

function BankLoanBanner({ containerClass = '' }) {
  const navigate=useNavigate()
  return (
    <div className={classes.banner}>
      <Container className={containerClass}>
        <Row>
          <Col md={5}>
            <div className={classes.imgDiv}>
              <img src={BankLoanImage} />
            </div>
          </Col>
          <Col md={7} >
            <div className={classes.aCenter}>
              <h6>Buy your dream house</h6>
              <p>housing and real estate</p>
              <Button borderRadius={'50px'} variant="secondary" onClick={()=>navigate(`/mortgage-bank-loan`)}>
                Click Personal Load Rates
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default BankLoanBanner;
