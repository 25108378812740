import React from 'react';
import ModalSkeleton from '../ModalSkeleton';
import classes from './ReportListingModal.module.css';
import Input from '../../Component/Input/Input';
import { useState } from 'react';
import Button from '../../Component/Button/Button';
import { Col, Row } from 'react-bootstrap';
import DropDown from '../../Component/DropDown/DropDown';
import CustomPhoneInput from '../../Component/CustomPhoneInput';
import { Radio } from '../../Component/Radio/Radio';
import { Post } from '../../Axios/AxiosFunctions';
import validator from 'validator';

import { BaseURL, apiHeader } from '../../config/apiUrl';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { preferredMethodOptions } from '../../constant/commonData';
import { useSelector } from 'react-redux';

const reportResaons = [
  {
    label: 'Abc',
    value: 'abc'
  },
  {
    label: 'Xyz',
    value: 'xyz'
  }
];
const ReportListingModal = ({ show, setShow }) => {
  const {selectedLanguage} = useSelector((state) => state.languageReducer);
  const { t } = useTranslation('webCommon');
  const [reason, setReason] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [contactMethod, setContactMethod] = useState('Email');
  const [isLoading, setIsLoading] = useState(false);
  const handleSend = async () => {
    const url = BaseURL('');
    const params = {
      reason,
      name,
      email,
      phone,
      contactMethod
    };
    for (let key in params) {
      if (params[key] == '' || params[key] == null) {
        return toast.error(`Please fill the ${key} field`);
      }
    }
    if (!validator.isEmail(email)) {
      return toast.error('Your email is not valid');
    }
    setIsLoading(true);
    const response = await Post(url, params, apiHeader());
    if (response !== undefined) {
      console.log(response?.data);
    }
    setIsLoading(false);
  };
  return (
    <ModalSkeleton
      width={'650px'}
      show={show}
      setShow={setShow}
      header={t('reportListingModal.title')}
      headerClass={classes.header}>
      <style>{`.DropdownOptionContainer{
          text-transform:none;
        }`}</style>
      <Row className={classes.gap}>
        <Col md={12}>
          <DropDown
            options={reportResaons}
            value={reason}
            setter={setReason}
            placeholder={t('reportListingModal.reasonPlaceholder')}
            customStyle={{
              width: '100%'
            }}
            optionLabel={'label'}
            optionValue={'value'}
          />
        </Col>
        <Col md={6}>
          <Input
            inputStyle={{
              borderRadius: '5px'
            }}
            value={name}
            setValue={setName}
            placeholder={t('reportListingModal.namePlaceholder')}
          />
        </Col>
        <Col md={6}>
          <Input
            value={email}
            setValue={setEmail}
            inputStyle={{
              borderRadius: '5px'
            }}
            placeholder={t('reportListingModal.emailPlaceholder')}
          />
        </Col>

        <Col md={6}>
          <CustomPhoneInput
            value={phone}
            setter={setPhone}
            placeholder={t('reportListingModal.phonePlaceholder')}
          />
        </Col>
        <Col md={6} className={classes.contactWrap}>
          <p>{t('reportListingModal.preferredMethod')}</p>
          <div className={classes.methodWrap}>
            <Radio value={contactMethod} setValue={setContactMethod} label={preferredMethodOptions[0]?.[selectedLanguage]} />
            <Radio value={contactMethod} setValue={setContactMethod} label={preferredMethodOptions[1]?.[selectedLanguage]}/>
            <Radio value={contactMethod} setValue={setContactMethod} label={preferredMethodOptions[2]?.[selectedLanguage]}/>
          </div>
        </Col>
      </Row>

      <Button className={classes.btn} onClick={handleSend} label={isLoading ?t('reportListingModal.btnTextLoading'):t('reportListingModal.btnText')} />
      {/* <div className={classes.footer}>
        <p>Don’t have a free account yet? </p>
        <p>Create your free account now</p>
      </div> */}
    </ModalSkeleton>
  );
};

export default ReportListingModal;
