/*eslint-disable*/

import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {} from 'react';
import numeral from 'numeral';

import classes from './CompanyDetailPage.module.css';
import Tabs from '../../Component/Tabs';
import DropDown from '../../Component/DropDown/DropDown';
import PropertyHorizontalCard from '../../Component/PropertyHorizontalCard/PropertyHorizontalCard';
import { PaginationComponent } from '../../Component/Pagination/Pagination';
// import EventHorizontalCard from '../../Component/EventHorizontalCard/EventHorizontalCard';
import Header from '../../Component/Header';
import CompanyIntroSection from '../../Component/CompanyIntroSection/CompanyIntroSection';
import { useNavigate, useParams } from 'react-router-dom';
import AgentCard from '../../Component/AgentCard/AgentCard';
import BannerImageAd from '../../Component/BannerImageAd';
import { verticalBanner, horizontalBanner } from '../../constant/imagePath';
import Footer from '../../Component/Footer';
import EmailAgentModal from '../../modals/EmailAgentModal';
import { propertyPurposeOptions } from '../../constant/commonData';
import {
  useGetPublicCompanyDetailQuery,
  useGetPublicCompanyEntitiesMutation
} from '../../store/apiSlice/apiSlice';
import { Loader } from '../../Component/Loader';
import { imageUrl, recordsLimit } from '../../config/apiUrl';
import EventHorizontalCard from '../../Component/EventHorizontalCard/EventHorizontalCard';
import ProjectHorizontalCard from '../../Component/ProjectHorizontalCard/ProjectHorizontalCard';
import { useSelector } from 'react-redux';
import NoData from '../../Component/NoData/NoData';
import { useTranslation } from 'react-i18next';

const CompanyDetailPage = () => {
  const { t } = useTranslation('agentOrCompanyDetail');

  const _tabsData = [
    {
      id: 1,
      text: t('company.tabs.properties'),
      value: 'property'
    },
    {
      id: 2,
      text: t('company.tabs.projects'),
      value: 'project'
    },
    {
      id: 3,
      text: t('company.tabs.events'),
      value: 'event'
    },
    {
      id: 4,
      text: t('company.tabs.agents'),
      value: 'agent'
    }
  ];
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const slug = useParams()?.slug;
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(_tabsData[0]);
  const [featuredFilterValue, setFeaturedFilterValue] = useState('');
  const [propertyPurpose, setPropertyPurpose] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState('');

  const { allBanners } = useSelector((state) => state.commonReducer);

  const verticalBanners = allBanners?.filter(
    (item) => item?.page === 'company-detail' && item?.bannerType == 'vertical'
  );
  const horizontalBanners = allBanners?.filter(
    (item) => item?.page === 'company-detail' && item?.bannerType == 'horizontal'
  );

  const { data, isLoading } = useGetPublicCompanyDetailQuery({ slug });
  const [getData, { data: propertyOrAgent, isLoading: isGetting, isFetching }] =
    useGetPublicCompanyEntitiesMutation();
  const companyData = data?.data;

  async function getDataByType(pg = page, filters = {}, type = selectedTab?.value) {
    getData({
      slug,
      type: type,
      page: pg,
      data: { ...filters, lan: selectedLanguage },
      limit: recordsLimit
    });
  }

  // scroll to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      left: 0
    });
    getDataByType();
  }, []);

  const handleRefetch = () => {
    setPage(1);
    getDataByType(1, {
      ...(propertyPurpose?.value
        ? {
            propertyPurpose: propertyPurpose?.value?.propertyPurpose?.[selectedLanguage]
          }
        : {}),
      ...(featuredFilterValue?.value ? featuredFilterValue?.value : {})
    });
  };

  return (
    <>
      <style>
        {`
        .smDropdown__indicator{
            padding: 0px 8px;
        }
        .smDropdown__control{
            border-radius: 0;
        }
        `}
      </style>

      <div className={classes?.mainPage}>
        <Header containerClass={''} />

        {isLoading ? (
          <Loader />
        ) : (
          <Container className={`${classes?.mainContainer} `}>
            {/* Agent Intro Section: 2Col => Agent Intro, 8Col => About Me, 2Col => Banner Ad */}
            {verticalBanners[0].image && (
              <CompanyIntroSection
                className={'mt-3'}
                data={companyData}
                t={t}
                verticalBanner={imageUrl(verticalBanners[0].image)}
              />
            )}

            {/* Tabs => Properties + Events */}
            <div className={classes?.tabsWrapper}>
              <Tabs
                scrollOffset={500}
                tabsData={_tabsData}
                value={selectedTab}
                setValue={(e) => {
                  setPage(1);
                  setSelectedTab(e);
                  setPropertyPurpose(null);
                  setFeaturedFilterValue(null);
                  getDataByType(1, {}, e?.value);
                }}
                className={classes?.tabsContainer}
              />
            </div>

            <Row className={classes?.dataAndBannerContainer}>
              <Col md={12} lg={10}>
                {/* Data count strip */}
                <div className={classes?.dataCountStripContainer}>
                  <div className={classes?.dataStripLeft}>
                    {t('company.filterResults.showing')} 1 - {propertyOrAgent?.data?.length ?? 0} of{' '}
                    {numeral(propertyOrAgent?.totalRecords ?? 0).format('0,0')}{' '}
                    {selectedTab?.value == 'property'
                      ? t('company.filterResults.properties')
                      : selectedTab?.text}
                  </div>
                  {selectedTab?.value !== 'agent' && (
                    <div className={classes?.dataStripRight}>
                      <span>{t('company.filterResults.sortBy')}</span>

                      {selectedTab?.value == 'property' && (
                        <DropDown
                          className={classes.filters}
                          isClearable={true}
                          classNamePrefix={'smDropdown'}
                          placeholder={t('company.filterResults.buyRentSelect')}
                          options={filterOptions}
                          customStyle={{ width: '150px' }}
                          value={propertyPurpose}
                          setValue={(e) => {
                            setPropertyPurpose(e);
                            setPage(1);
                            getDataByType(1, {
                              ...(e?.value
                                ? {
                                    propertyPurpose: e?.value?.propertyPurpose?.[selectedLanguage]
                                  }
                                : {}),
                              ...(featuredFilterValue?.value ? featuredFilterValue?.value : {})
                            });
                          }}
                          getOptionLabel={(e) => e?.label?.[selectedLanguage]}
                          getOptionValue={(e) => e?.value?.propertyPurpose?.[selectedLanguage]}
                        />
                      )}
                      <DropDown
                        isClearable={true}
                        classNamePrefix={'smDropdown'}
                        options={_featuredFilterData}
                        value={featuredFilterValue}
                        setValue={(e) => {
                          setFeaturedFilterValue(e);
                          setPage(1);
                          getDataByType(1, {
                            ...e?.value,
                            ...(propertyPurpose?.value
                              ? {
                                  propertyPurpose:
                                    propertyPurpose?.value?.propertyPurpose?.[selectedLanguage]
                                }
                              : {})
                          });
                        }}
                        getOptionLabel={(e) => e?.label?.[selectedLanguage]}
                        optionValue={'id'}
                        customStyle={{ width: '190px' }}
                        placeholder={t('company.filterResults.featuredPremiumSelect')}
                      />
                    </div>
                  )}
                </div>
                {isGetting || isFetching ? (
                  <Loader className={classes?.itemLoader} />
                ) : (
                  <>
                    {
                      <Row className={classes?.propertiesContainer}>
                        {propertyOrAgent?.data?.length == 0 ? (
                          <NoData text={t('company.notFound')} />
                        ) : (
                          propertyOrAgent?.data?.map((item, index) => {
                            return (
                              <>
                                <Col md={selectedTab?.value == 'agent' ? 4 : 12} key={index}>
                                  {selectedTab?.value === 'project' ? (
                                    <ProjectHorizontalCard
                                      data={item}
                                      index={index}
                                      onEmailClick={() => {
                                        setSelectedItem(item);
                                        setIsModalOpen('email');
                                      }}
                                      refetch={handleRefetch}
                                    />
                                  ) : selectedTab?.value === 'property' ? (
                                    <PropertyHorizontalCard
                                      data={item}
                                      index={index}
                                      onEmailClick={() => {
                                        setSelectedItem(item);
                                        setIsModalOpen('email');
                                      }}
                                      refetch={handleRefetch}
                                    />
                                  ) : selectedTab?.value === 'event' ? (
                                    <EventHorizontalCard
                                      data={item}
                                      index={index}
                                      onEmailClick={() => {
                                        setSelectedItem(item);
                                        setIsModalOpen('email');
                                      }}
                                      refetch={handleRefetch}
                                    />
                                  ) : (
                                    <AgentCard
                                      data={item}
                                      onClick={(agentData) =>
                                        navigate(`/agents/${agentData?.slug}`)
                                      }
                                    />
                                  )}
                                </Col>

                                {(index + 1) % 9 === 0 && (
                                  <Col md={12}>
                                    <div className={classes?.bannerAdContainer}>
                                      <div className={classes?.bannerAdContainer}>
                                        {horizontalBanners[(page - 1) % horizontalBanners?.length]
                                          ?.image && (
                                          <BannerImageAd
                                            image={
                                              horizontalBanners[
                                                (page - 1) % horizontalBanners?.length
                                              ]?.image
                                            }
                                            className={classes.horizontalBanner}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                )}
                              </>
                            );
                          })
                        )}
                      </Row>
                    }

                    {!!propertyOrAgent?.data?.length && (
                      <div className="jCenter">
                        <PaginationComponent
                          totalPages={Math.ceil(propertyOrAgent?.totalRecords / recordsLimit)}
                          currentPage={page}
                          setCurrentPage={(e) => {
                            setPage(e);
                            getDataByType(e, {
                              ...(propertyPurpose?.value
                                ? {
                                    propertyPurpose:
                                      propertyPurpose?.value?.propertyPurpose?.[selectedLanguage]
                                  }
                                : {}),
                              ...(featuredFilterValue?.value ? featuredFilterValue?.value : {})
                            });
                          }}
                          className={'mb-4'}
                        />
                      </div>
                    )}
                  </>
                )}
              </Col>
              {/* Banner */}
              <Col md={12} lg={2}>
                <div className={classes?.bannerAdContainer}>
                  {verticalBanners[1]?.image && (
                    <BannerImageAd
                      className={classes.verticleBanner}
                      image={imageUrl(verticalBanners[1]?.image)}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        )}
        <Footer />
      </div>

      <EmailAgentModal
        show={isModalOpen == 'email'}
        setShow={setIsModalOpen}
        data={selectedItem}
        type={selectedTab?.value}
      />
    </>
  );
};

export default CompanyDetailPage;

export const filterOptions = [
  {
    label: { ...propertyPurposeOptions[0] },
    value: { propertyPurpose: propertyPurposeOptions[0] }
  },
  {
    label: { ...propertyPurposeOptions[1] },
    value: { propertyPurpose: propertyPurposeOptions[1] }
  }

  // {
  //   label: 'Projects',
  //   value: 'projects'
  // },
  // {
  //   label: 'Events',
  //   value: 'events'
  // }
];

export const _featuredFilterData = [
  {
    id: 1,
    label: {
      en: 'Featured',
      fa: 'ویژه',
      tr: 'Featured',
      ar: 'مميز',
      ru: 'Премиум',
      de: 'Premium',
      fr: 'Premium'
    },
    value: { isFeatured: true }
  },
  {
    id: 2,
    label: {
      en: 'Premium',
      fa: 'ویژه',
      tr: 'Premium',
      ar: 'مميز',
      ru: 'Премиум',
      de: 'Premium',
      fr: 'Premium'
    },
    value: { isPremium: true }
  }
];
