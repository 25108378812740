/* eslint-disable */

import React, { useState } from 'react';
import ModalSkeleton from '../ModalSkeleton';
import classes from './UpgradingListingModal.module.css';
import { Col, Row } from 'react-bootstrap';
import { featureListingImg } from '../../constant/imagePath';
import DropDown from '../../Component/DropDown/DropDown';
import Input from '../../Component/Input/Input';
import Button from '../../Component/Button/Button';
import { toast } from 'react-toastify';
import moment from 'moment';
import { upgradeListingOptions } from '../../constant/commonData';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const UpgradingListingModal = ({ show, setShow, onSubmit, isSubmitting }) => {
  const {t}=useTranslation('dashboardProperties')
  const {selectedLanguage}=useSelector(state=>state.languageReducer)
  const [upgradeType, setUpgradeType] = useState('');
  const [startDate, setStartDate] = useState('');


 async function onClick() {
    const body = {
      type: upgradeType?.type,
      duration: upgradeType?.value,
      startDate
    };
    if (!upgradeType) {
      return toast.error('Please select upgrade type');
    }
    if (!startDate) {
      return toast.error('Please select start date');
    }
    const added6Months = moment().add(6, 'months').format('DD MMM YYYY');
    const endDate = moment(moment(startDate))
      .add(upgradeType?.value, 'months')
      .format('DD MMM YYYY');

    if (!moment(endDate)?.isBefore(added6Months)) {
      return toast.error('Please select the start date under 6 months');
    }
    console.log(body, 'body');
    onSubmit && await onSubmit(body);
  }

  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      modalClass={classes.main}
      borderRadius={'0px'}
      width={'1200px'}
      header={true}
      showCloseIcon={false}
      headerClass={classes.header}>
      <div className={classes.main}>
        <Row className={[classes.row, 'g-0', classes].join(' ')}>
          <Col md={6} className={classes.col1}>
            <h4>{t('upgradeListingModal.title')}</h4>
            <Col md={12} sm={12}>
              <DropDown
                options={upgradeListingOptions}
                value={upgradeType}
                setValue={setUpgradeType}
                label={t('upgradeListingModal.upgradeTypeLabel')}
                placeholder={t('upgradeListingModal.upgradeTypePlaceholder')}
                getOptionLabel={(option) =>
                  `${option?.label?.[selectedLanguage]} - ${option?.duration?.[selectedLanguage]} - ${option?.coinsText?.[selectedLanguage]} `
                }
                optionValue={'id'}
                variant="dashboard"
              />
            </Col>
            <Col md={12} sm={12}>
              <Input
                type={'date'}
                value={startDate}
                setValue={setStartDate}
                label={t('upgradeListingModal.startDate')}
                variant="dashboard"
              />
            </Col>
            <Col md={12} sm={12} className={classes?.mainButton}>
              <Button
                label={isSubmitting ? t('upgradeListingModal.btnLoading') : t('upgradeListingModal.btn')}
                disabled={isSubmitting}
                onClick={onClick}
              />
            </Col>
          </Col>
          <Col md={6} className="d-flex justify-content-center align-items-center">
            <div className={classes.sideImage}>
              <img src={featureListingImg} alt="" draggable={false} />
            </div>
          </Col>
        </Row>
      </div>
    </ModalSkeleton>
  );
};

export default UpgradingListingModal;

