/*eslint-disable*/
import React, { useEffect } from 'react';
import classes from './MortgageLoanPage.module.css';
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import {
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useState } from 'react';
import { BankLoanImage, IngBankLogo, VakifBankLogo } from '../../constant/imagePath';
import DropDown from '../../Component/DropDown/DropDown';
import Button from '../../Component/Button/Button';
import { PaginationComponent } from '../../Component/Pagination/Pagination';
import { useLazyGetApiQuery } from '../../store/apiSlice/apiSlice';
import { imageUrl, recordsLimit } from '../../config/apiUrl';
import { useSelector } from 'react-redux';
import { Loader } from '../../Component/Loader';
import NoData from '../../Component/NoData/NoData';

const PrettoSlider = styled(Slider)({
  color: 'var(--primary-clr)',
  height: 8,
  marginBottom: '-10px',
  '& .MuiSlider-track': {
    height: '8px',
    border: 'none',
    backgroundColor: 'var(--primary-clr)'
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: 'var(--primary-clr)',
    border: '4px solid var(--white-clr)',
    boxShadow: '2px 2px 10px #00000094',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: '2px 2px 10px #00000094'
    },
    '&:before': {
      display: 'none'
    }
  },
  '& .MuiSlider-rail': {
    opacity: 0.3,
    backgroundColor: 'var(--primary-clr)',
    borderRadius: '0px !important',
    height: '8px'
  }
});

function MortgageLoanPage() {
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const [getApi, { isLoading, isFetching, data }] = useLazyGetApiQuery();
  const [propertyValue, setPropertyValue] = useState('');
  const [loanPeriod, setLoanPeriod] = useState('');
  // const [, setPropertyValue]=useState('')
  const [interest, setInterest] = useState('');
  const [downPayment, setDownPayment] = useState('');
  const [page, setPage] = useState(1);
  // const [data] = useState([
  //   {
  //     _id: '12221',
  //     interestRate: '0.93',
  //     financeAmountPercentage: '1.220',
  //     maxAmount: '146.422',
  //     maxFinance: '100',
  //     logo: IngBankLogo,
  //     bankName: 'ING Bank'
  //   },
  //   {
  //     _id: '122s21',
  //     interestRate: '0.93',
  //     financeAmountPercentage: '1.220',
  //     maxAmount: '146.422',
  //     maxFinance: '100',
  //     logo: VakifBankLogo,
  //     bankName: 'Vakif Bank'
  //   }
  // ]);

  useEffect(() => {
    getApi({
      url: `bank/ALL?page=${page}&limit=${recordsLimit}`,
      tag: 'Bank'
    });
  }, []);

  return (
    <>
      <div className={classes.page}>
        <Header />
        <div className={classes.heroSection}>
          <Container className={classes.container}>
            <Row className={classes.row}>
              <Col md={4}>
                <div className={classes.imgDiv}>
                  <img src={BankLoanImage} />
                </div>
              </Col>
              <Col md={8}>
                <h4>Find the best mortgage loan for your home</h4>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <Row className={classes.mainRow}>
            <Col md={12} className={classes.filters}>
              <DropDown label={'sort by'} />
            </Col>
            <Col md={4}>
              <div className={classes.calculatorHeader}>
                <h5>{'Mortgage calculator'}</h5>
              </div>
              <div className={classes.calculatorMain}>
                <Row className={classes.row}>
                  <Col className={classes.col} md={12}>
                    <div>
                      <div className={classes.head}>
                        <h6>Property Value</h6>
                        <div className={classes.valueBox}>{propertyValue} AED</div>
                      </div>
                      <div className={classes.slider}>
                        <PrettoSlider
                          value={propertyValue}
                          onChange={(val) => {
                            setPropertyValue(val.target.value);
                          }}
                          aria-label="Default"
                          valueLabelDisplay="auto"
                          min={30000}
                          step={1000}
                          max={1000000}
                          //   track={'inverted'}
                        />
                      </div>
                      <div className={`flex-sb ${classes.range}`}>
                        <span>30000</span>
                        <span>100,000</span>
                      </div>
                    </div>
                    <div>
                      <div className={classes.head}>
                        <h6>Loan Period</h6>
                        <div className={classes.valueBox}>{'16.6'}%</div>
                      </div>
                      <div className={classes.slider}>
                        <PrettoSlider
                          value={loanPeriod}
                          onChange={(val) => {
                            setLoanPeriod(val.target.value);
                          }}
                          aria-label="Default"
                          valueLabelDisplay="auto"
                          min={1}
                          max={4}
                        />
                      </div>
                      <div className={`flex-sb ${classes.range}`}>
                        <span>1 Year</span>
                        <span>4 Year</span>
                      </div>
                    </div>
                    {/* Down Payment */}

                    <div>
                      <div className={classes.head}>
                        <h6>Down Payment</h6>
                        <div className={classes.valueBox}>{'2'} Year</div>
                      </div>
                      <div className={classes.slider}>
                        <PrettoSlider
                          value={downPayment}
                          onChange={(val) => {
                            setDownPayment(val.target.value);
                          }}
                          aria-label="Default"
                          valueLabelDisplay="auto"
                          min={30000}
                          step={1000}
                          max={1000000}
                        />
                      </div>
                      <div className={`flex-sb ${classes.range}`}>
                        <span>30000</span>
                        <span>100,000</span>
                      </div>
                    </div>
                    {/* Interest Rate */}

                    <div>
                      <div className={classes.head}>
                        <h6>Interest Rate</h6>
                        <div className={classes.valueBox}>{'2'} Year</div>
                      </div>
                      <div className={classes.slider}>
                        <PrettoSlider
                          value={interest}
                          onChange={(val) => {
                            setInterest(val.target.value);
                          }}
                          aria-label="Default"
                          valueLabelDisplay="auto"
                          min={30000}
                          step={1000}
                          max={1000000}
                        />
                      </div>
                      <div className={`flex-sb ${classes.range}`}>
                        <span>30000</span>
                        <span>100,000</span>
                      </div>
                    </div>

                    {/* Cost */}
                    <div className={classes.costValue}>
                      <h6>Monthly Cost</h6>
                      <p>21000 AED</p>
                    </div>
                    <div className={classes.costValue}>
                      <h6>Total Cost</h6>
                      <p data-color="primary">422000 AED</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={8}>
              <TableContainer className={[classes.tableMain, 'scroller'].join(' ')}>
                <Table stickyHeader aria-label="sticky table" className={classes.table}>
                  <TableHead className={classes.thead}>
                    <TableRow className={classes.tableRow}>
                      <TableCell
                        // align={column?.align}
                        // style={{ minWidth: column?.minWidth }}
                        width={'15%'}
                        align="center">
                        Bank Name
                      </TableCell>
                      <TableCell
                        // align={column?.align}
                        // style={{ minWidth: column?.minWidth }}
                        align="center"
                        width={'15%'}>
                        Interest Rate
                      </TableCell>
                      <TableCell
                        // align={column?.align}
                        // style={{ minWidth: column?.minWidth }}
                        align="center"
                        width={'15%'}>
                        Finance Amount Percentage
                      </TableCell>
                      <TableCell
                        // align={column?.align}
                        // style={{ minWidth: column?.minWidth }}
                        align="center"
                        width={'10%'}>
                        Maximum Amount
                      </TableCell>
                      <TableCell
                        // align={column?.align}
                        // style={{ minWidth: column?.minWidth }}
                        align="center"
                        width={'10%'}>
                        Maximum Duration
                      </TableCell>
                      <TableCell
                        // align={column?.align}
                        // style={{ minWidth: column?.minWidth }}
                        align="center"
                        width={'10%'}>
                        Down Payment
                      </TableCell>
                      <TableCell
                        // align={column?.align}
                        // style={{ minWidth: column?.minWidth }}
                        align="center"
                        width={'10%'}>
                        Final Payment
                      </TableCell>
                      <TableCell
                        // align={column?.align}
                        // style={{ minWidth: column?.minWidth }}
                        align="center"
                        width={'15%'}>
                        More Details
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {isLoading || isFetching ? (
                      <TableRow>
                        <TableCell colSpan={8} style={{ border: 'none' }}>
                          <Loader className={classes?.loader} />
                        </TableCell>
                      </TableRow>
                    ) : data?.data?.length ==0 ? (
                      <TableRow> 
                        <TableCell colSpan={8} style={{ border: 'none' }}>
                          <NoData text={'No Banks Found'} />
                        </TableCell>
                      </TableRow>
                    ) : (
                      data?.data?.map((item, key) => (
                        <TableRow
                          role="checkbox"
                          tabIndex={-1}
                          key={key}
                          className={classes.tableRow}>
                          <TableCell width={'15%'} align="center">
                            <div className={classes.imgDiv}>
                              <img src={imageUrl(item?.image)} />
                            </div>
                            {item?.bankName?.[selectedLanguage]}
                          </TableCell>
                          <TableCell width={'15%'} align="center">
                            {item?.interestRate + '%'}
                          </TableCell>
                          <TableCell width={'15%'} align="center">
                            {item?.financialAmountPercentage + ' TL'}
                          </TableCell>
                          <TableCell width={'10%'} align="center">
                            {item?.maximumAmount + ' TL'}
                          </TableCell>
                          <TableCell width={'10%'} align="center">
                            {item?.maximumDuration}
                          </TableCell>
                          <TableCell width={'10%'} align="center">
                            {item?.downPayment}
                          </TableCell>
                          <TableCell width={'10%'} align="center">
                            {item?.finalPayment}
                          </TableCell>
                          <TableCell width={'15%'} align="center">
                            <Button
                              className={classes.tableBtn}
                              onClick={() => {
                                window.open(item?.bankInformationLink, '_blank');
                              }}>
                              More Details
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <PaginationComponent totalPages={1} currentPage={page} setCurrentPage={setPage} />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default MortgageLoanPage;
