import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';

import combineReducer from './combineReducer';
import { apiSlice } from './apiSlice/apiSlice';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authReducer', 'languageReducer', 'converterReducer'],
  blacklist: ['commonReducer']
};

const persistedReducer = persistReducer(persistConfig, combineReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat(apiSlice.middleware)
});

export const persistor = persistStore(store);

export default store;

setupListeners(store.dispatch);
