/*eslint-disable*/

import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CompareCard from '../../Component/CompareCard';
import { MdPhotoLibrary } from 'react-icons/md';
import Tabs from '../../Component/Tabs';
import classes from './CompareList.module.css';
import Header from '../../Component/Header';
import Footer from '../../Component/Footer';
import EventCard from '../../Component/EventCard';
import AccountInfoLayout from '../../Component/AccountInfoLayout';
// import DropDown from '../../Component/DropDown/DropDown';
import { useLazyGetApiQuery, usePostApiMutation } from '../../store/apiSlice/apiSlice';
import { updateUser } from '../../store/auth/authSlice';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import NoData from '../../Component/NoData/NoData';
import { Loader } from '../../Component/Loader';
import { useTranslation } from 'react-i18next';

const CompareList = () => {
  const {t}=useTranslation('compareList')
  const [getApiFunc, { data: renderDataNew, isLoading }] = useLazyGetApiQuery();
  const [postApiFunc] = usePostApiMutation();

  const tabsData = [
    { icon: <MdPhotoLibrary />, text: t('property'), value: 'Property', id: '1', noData:t('noPropertyFound') },
    { icon: <MdPhotoLibrary />, text: t('event'), value: 'Event', id: '2',noData:t('noEventFound') },
    { icon: <MdPhotoLibrary />, text: t('project'), value: 'Project', id: '3', noData:t('noProjectFound') }
  ];
  const [tabValue, setTabValue] = useState(tabsData[0]);
  // const [renderCompo, setRenderCompo] = useState(<CompareCard />);
  const [checkboxArray, setCheckboxArray] = useState([]);

  const dispatch = useDispatch();

  const getData = async (type = tabValue?.value?.toLowerCase()) => {
    await getApiFunc({
      url: `users/compare?type=${type}`,
      tag: `compareList`
    });
  };

  const delItem = async (isDeleteAll) => {
    let arrayCheckbox = isDeleteAll
      ? Array.isArray(renderDataNew?.data) &&
        renderDataNew?.data.map((item, key) => item?.entity?._id)
      : checkboxArray;
    if (isDeleteAll && arrayCheckbox.length == 0) return;
    if (!arrayCheckbox.length) return toast.error(`Please select at least one ${tabValue?.value}`);

    const getResponse = await postApiFunc({
      url: `users/compare/remove`,
      method: 'POST',
      tag: `compareList`,

      data: {
        ids: arrayCheckbox,
        type: tabValue?.value?.toLowerCase()
      }
    });
    if (getResponse?.data) {
      dispatch(updateUser(getResponse?.data?.data));
      toast.success(`${tabValue?.value} removed from compare list`);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className={`page_bg ${classes.page}`}>
        <Header containerClass="customContainer" />
        <AccountInfoLayout>
          <Tabs
            className={classes.tab_main}
            tabClass={classes.tab_class_main}
            value={tabValue}
            setValue={(e) => {
              // setRenderCompo(e.text.split(' ')[0] == 'Compare' ? <CompareCard /> : <EventCard />);

              setTabValue(e);
              setCheckboxArray([]);
              getData(e?.value?.toLowerCase());
            }}
            tabsData={tabsData}
          />
          <div className={classes.delete_div}>
            <div
              style={{
                display: 'flex'
              }}>
              <p className="c-p" onClick={() => delItem(true)}>
                {t('deleteAll')}
              </p>
              <p
                className="c-p"
                onClick={() => {
                  if (checkboxArray.length) {
                    delItem(false);
                  } else {
                    toast.error('Please select at least one property');
                  }
                }}>
                {t('deleteSelected')}
              </p>
            </div>
            {/* <div className={classes.sort_div}>
              <p>Sort by:</p>

              <DropDown classNamePrefix={'dropDownMain'} placeholder={'Featured'} />
            </div> */}
          </div>

          <Row>
            {isLoading ? (
              <Loader />
            ) : renderDataNew?.data?.length == 0 ? (
              <NoData text={t(tabValue?.noData)} />
            ) : (
              renderDataNew?.data.map((item, key) => {
                return (
                  <Col key={key} xl={4} lg={6} md={6}>
                    <Form.Check
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: '10px'
                      }}
                      checked={checkboxArray.includes(item?.entity?._id)}
                      onClick={(e) => {
                        if (checkboxArray.includes(item?.entity?._id)) {
                          setCheckboxArray(checkboxArray.filter((ele) => ele != item?.entity?._id));
                        } else {
                          setCheckboxArray([...checkboxArray, item?.entity?._id]);
                        }
                      }}
                      name="group1"
                      type={'checkbox'}
                      id={`inline-checkbox-1`}
                    />
                    {tabValue?.value == 'Event' ? (
                      <EventCard data={item?.entity} refetch={getData} />
                    ) : (
                      <CompareCard data={item?.entity} type={tabValue?.value} refetch={getData} />
                    )}
                  </Col>
                );
              })
            )}
          </Row>
        </AccountInfoLayout>
      </div>
      <Footer />
    </>
  );
};

export default CompareList;
