import React from 'react';
import classes from './dateRangeCalendar.module.css';
import { DateRange } from 'react-date-range';
// import moment from 'moment';
import styled from 'styled-components';

const StyledDateRange = styled(DateRange)`
  .rdrDateDisplayWrapper {
    display: none !important;
  }
  .rdrStartEdge {
    color: #0080ff70 !important;
    border-left:1px solid blue;
    border-radius:0px;
  }
  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
    color:var(--text-black-clr);
    font-weight:500;
  }
  .rdrEndEdge {
    color: #0080ff70 !important;
    border-radius:0;
    border-right:1px solid blue;
  }
  .rdrInRange {
    background: #0080ff70 !important;
    color: color:var(--text-black-clr) !important;
  }
  .rdrDayNumber {
    color: color:var(--text-black-clr) !important;
  }
  .rdrMonthAndYearWrapper {
    width: 100% !important;
  }
  .rdrMonth {
    width: 100%;
  }
  .rdrCalendarWrapper {
    width: 100% !important;
  }
`;

function DateRangeCalendar({
  value = [
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ],
  setValue,
  disabledDates,
  // minDate = moment().toDate(),
  ...props
}) {
  return (
    <div className={classes.main}>
      <StyledDateRange
        editableDateInputs={true}
        selected={new Date()}
        onChange={(item) => {
            console.log(item, 'item');
          // console.log(item?.selection, 'aaa');
          setValue([item.selection]);
        }}
        moveRangeOnFirstSelection={false}
        showDateDisplay={false}
        ranges={value}
        // minDate={minDate && minDate}
        disabledDates={disabledDates}
        retainEndDateOnFirstSelection={true}
        {...props}
      />
    </div>
  );
}

export default DateRangeCalendar;
