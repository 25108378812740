import React from 'react';
import ModalSkeleton from '../ModalSkeleton';
import classes from './HomeMoreFiltersModal.module.css';
// import Button from '../../Component/Button/Button';
import { Col, Row } from 'react-bootstrap';
import { useState } from 'react';
import {
  parkingSpaceFilterOptions,
  floorLevelFilterOptions,
  propertyAgeFilterOptions,
  furnitureFiltersOptions
} from '../../constant/commonData';
import Checkbox from '../../Component/Checkbox/Checkbox';
import Button from '../../Component/Button/Button';
import { useSelector } from 'react-redux';

const HomeMoreFiltersModal = ({
  show,
  setShow,
  // onClick, isLoading,
  children,
  setFilters
}) => {
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const { interiorAmenities, exteriorAmenities } = useSelector((state) => state?.commonReducer);
  const [selectedInteriorAmenities, setSelectedInteriorAmenities] = useState([]);
  const [selectedExteriorAmenities, setSelectedExteriorAmenities] = useState([]);
  const [propertyAge, setPropertyAge] = useState('');
  const [parkingSpace, setParkingSpace] = useState();
  const [isFurniture, setIsFurniture] = useState('');
  const [floorLevel, setFloorLevel] = useState('');

  // console.log({ selectedInteriorAmenities, selectedExteriorAmenities });

  const onApply = () => {
    setFilters && setFilters((prev) => ({ ...prev, floorLevel:floorLevel?.[selectedLanguage], parkingSpace:parkingSpace?.[selectedLanguage], furniture:isFurniture?.[selectedLanguage], 
      propertyAge:propertyAge?.[selectedLanguage], interiorAmenities:selectedInteriorAmenities, exteriorAmenities:selectedExteriorAmenities}));
  };
  return (
    <ModalSkeleton
      width={'600px'}
      show={show}
      setShow={setShow}
      // header={'More Filters'}
      modalClass={classes.modal}>
      <Row className={classes.row}>
        {children}
        <Col md={12}>
          <h6>Floor Level</h6>
        </Col>
        <Col md={12}>
          <ul className={classes.listSelection}>
            {floorLevelFilterOptions?.map((item, key) => (
              <li
                key={key}
                data-selected={item?.[selectedLanguage] == floorLevel?.[selectedLanguage]}
                onClick={() => {
                  setFloorLevel(prev => prev?.[selectedLanguage] == item?.[selectedLanguage] ? '' : item);
                }}>
                {item?.[selectedLanguage]}
              </li>
            ))}
          </ul>
        </Col>
        <Col md={12}>
          <h6>Parking Space</h6>
        </Col>
        <Col md={12}>
          <ul className={classes.listSelection}>
            {parkingSpaceFilterOptions?.map((item, key) => (
              <li
                key={key}
                data-selected={item?.[selectedLanguage] == parkingSpace?.[selectedLanguage]}
                onClick={() => {
                  setParkingSpace(prev => prev?.[selectedLanguage] == item?.[selectedLanguage] ? '' : item);
                }}>
                {item?.[selectedLanguage]}
              </li>
            ))}
          </ul>
        </Col>
        <Col md={12}>
          <h6>Furniture</h6>
        </Col>
        <Col md={12}>
          <ul className={classes.listSelection}>
            {furnitureFiltersOptions?.map((item, key) => (
              <li
                key={key}
                data-selected={item?.[selectedLanguage] == isFurniture?.[selectedLanguage]}
                onClick={() => {
                  setIsFurniture(prev => prev?.[selectedLanguage] == item?.[selectedLanguage] ? '' : item);
                }}>
                {item?.[selectedLanguage]}
              </li>
            ))}
          </ul>
        </Col>
        {/* <Col md={12} className={classes.checkboxDiv}>
          <Checkbox
            label={'Do you want to have furniture in the property?'}
            value={isFurniture}
            setValue={setIsFurniture}
          />
        </Col> */}

        <Col md={12}>
          <h6>Property Age</h6>
        </Col>
        <Col md={12}>
          <ul className={classes.listSelection}>
            {propertyAgeFilterOptions?.map((item, key) => (
              <li
                key={key}
                data-selected={item?.[selectedLanguage] == propertyAge?.[selectedLanguage]}
                onClick={() => {
                  setPropertyAge(prev => prev?.[selectedLanguage] == item?.[selectedLanguage] ? '' : item);
                }}>
                {item?.[selectedLanguage]}
              </li>
            ))}
          </ul>
        </Col>

        <Col md={12}>
          <h6>Exterior Amenities</h6>
        </Col>
        <Col md={12}>
          <ul className={classes.listWrap}>
            {exteriorAmenities?.map((item, key) => (
              <li key={key}>
                <Checkbox
                  label={item?.title?.[selectedLanguage]}
                  value={
                    selectedExteriorAmenities?.find((e) => e?._id == item?._id)?.title?.[
                      selectedLanguage
                    ]
                  }
                  setValue={() => {
                    setSelectedExteriorAmenities((p) => {
                      const newData = [...p];
                      if (newData?.find((e) => e?._id == item?._id)) {
                        return newData?.filter((fItem) => fItem?._id != item?._id);
                      } else {
                        newData?.push(item);
                      }
                      return newData;
                    });
                  }}
                />
                {/* {item?.name} */}
              </li>
            ))}
          </ul>
        </Col>

        <Col md={12}>
          <h6>Interior Amenities</h6>
        </Col>
        <Col md={12}>
          <ul className={classes.listWrap}>
            {interiorAmenities?.map((item, key) => (
              <li key={key}>
                <Checkbox
                  label={item?.title?.[selectedLanguage]}
                  value={
                    selectedInteriorAmenities?.find((e) => e?._id == item?._id)?.title?.[
                      selectedLanguage
                    ]
                  }
                  setValue={() => {
                    setSelectedInteriorAmenities((p) => {
                      const newData = [...p];
                      if (newData?.find((e) => e?._id == item?._id)) {
                        return newData?.filter((fItem) => fItem?._id != item?._id);
                      } else {
                        newData?.push(item);
                      }
                      return newData;
                    });
                  }}
                />
                {/* {item?.name} */}
              </li>
            ))}
          </ul>
        </Col>

        <Col md={12}>
          <Button className={classes.btn} onClick={onApply}>
            Apply
          </Button>
        </Col>
      </Row>

      {/* <Button className={classes.btn} onClick={onClick} label={!isLoading ? 'Apply' : 'Applying'} /> */}
      {/* <div className={classes.footer}>
        <p>Don’t have a free account yet? </p>
        <p>Create your free account now</p>
      </div> */}
    </ModalSkeleton>
  );
};

export default HomeMoreFiltersModal;
