import React from 'react';
import ReactSelect, { components } from 'react-select';
import classes from './DropDown.module.css';
import PropTypes from 'prop-types';
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';

const DropDown = ({
  options,
  label,
  customStyle = {},
  disabled,
  value,
  setValue,
  placeholder,
  isMulti,
  style,
  leftIcon,
  Components,
  labelClassName,
  indicatorColor = 'var(--secondary-clr)',
  optionLabel,
  optionValue,
  selectRef,
  isSearchable = true,
  borderRadius = '5px',
  classNamePrefix='',
  variant = '',
  containerStyle = {},
  containerClassName='',
  isClearable=false,
  ...props
}) => {
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {props.isFocused ? (
          <MdOutlineArrowDropUp size={30} color={indicatorColor} />
        ) : (
          <MdOutlineArrowDropDown size={30} color={indicatorColor} />
        )}
      </components.DropdownIndicator>
    );
  };

  const dropDownStyle = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled
        ? 'var(--disabled-input-clr)'
        : variant == 'dashboard'
        ? 'var(--dashboard-input-bg-clr)'
        : 'var(--white-clr)',
      padding: variant == 'dashboard' ? '4.5px 0px' : '2px 0px',
      borderRadius: borderRadius,
      color: 'var(--text-black-clr)',
      boxShadow: 'none',
      fontFamily: 'Montserrat',
      fontSize: 16,
      letterSpacing: 1,
      cursor: 'pointer',
      border: variant == 'dashboard' ? 'none' : `1px solid var(--input-border-clr)`,

      ':hover': {
        ...styles[':hover'],
        borderColor: 'var(--primary-clr)'
      },
      ':placeholder': {
        ...styles[':placeholder'],
        color: 'var(--text-black-clr)'
      },
      ':active': {
        ...styles[':active'],
        borderColor: 'var(--primary-clr)'
      },
      ...customStyle
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: 'var(--placeholder-clr)',
        textTransform: 'capitalize'
      };
    },

    option: (styles, { isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected && 'var(--primary-clr)',
        color: isSelected && 'var(--white-clr)',
        padding: '8px 12px',
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: 15,

        ':active': {
          ...styles[':active']
        },
        ':hover': {
          ...styles[':hover'],
          color: 'var(--white-clr)',
          backgroundColor: 'var(--primary-clr)',
          cursor: 'pointer'
        }
      };
    },

    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: 'var(--primary-clr)',
        borderRadius: '8px',
        padding: '4px 8px',
        fontFamily: 'Montserrat',
        fontWeight: 500
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        fontSize: 15,
        fontFamily: 'Montserrat',
        fontWeight: 500
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: '#fff',
      fontWeight: 500
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      fontSize: 12,
      color: '#fff',
      ':hover': {
        color: '#000'
      }
    })
  };
  return (
    <div className={`${[classes.Container,containerClassName].join(' ')}`} data-variant={variant} style={containerStyle}>
      <style>{`
        .DropdownOptionContainer__menu {
          margin: 0px;
          border: 0px;
        }
        .DropdownOptionContainer__single-value {
          color: var(--text-black-clr)
        }
        .DropdownOptionContainer__menu {
          box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
          
        }
       
      `}</style>
      {label && (
        <label
          htmlFor={`dropdown${label}`}
          className={`${[
            classes.label,
            labelClassName && labelClassName,
            disabled && classes.disabled
          ].join(' ')}`}>
          {label}
        </label>
      )}

      <div className={`${[classes.dropdownContainer].join(' ')}`}>
        <ReactSelect
          inputId={`dropdown${label}`}
          value={value}
          onChange={(e) => {
            setValue(e);
          }}
          className={`${[classes.reactSelect].join(' ')}`}
          isMulti={isMulti}
          isDisabled={disabled}
          placeholder={placeholder}
          options={options}
          styles={{ ...dropDownStyle, ...style }}
          isClearable={isClearable}
          isSearchable={isSearchable}
          classNamePrefix={`DropdownOptionContainer ${classNamePrefix}`}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: (e) => DropdownIndicator(e),
            ...Components
          }}
          getOptionLabel={(option) => {
            return optionLabel ? option[optionLabel] : option.label;
          }}
          getOptionValue={(option) => (optionValue ? option[optionValue] : option.value)}
          ref={selectRef}
          
          {...props}
        />
        {leftIcon && <div className={classes.leftIconBox}>{leftIcon}</div>}
      </div>
    </div>
  );
};

DropDown.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  labelTwo: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.object.isRequired,
  setValue: PropTypes.object,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  customStyle: PropTypes.object,
  style: PropTypes.object,
  Components: PropTypes.object,
  labelClassName: PropTypes.string
};

DropDown.defaultProps = {
  placeholder: 'Please Select',
  value: '',
  disabled: false,
  isMulti: false,
  options: [],
  Components: {}
};
export default DropDown;
