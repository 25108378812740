import React from 'react';
import Popover from '@mui/material/Popover';
import classes from './CustomPopover.module.css';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';

export default function CustomPopover({
  identifier = Math.floor(Math.random() * 100000).toString(),
  Icon,
  text = '',
  Component,
  TriggerComp,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right'
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right'
  },
  className = '',
  iconBtnClass = '',
  popoverRef = null,
  anchorEl = null,
  setAnchorEl,
  ...props
}) {
  // const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? `simple-popover-${identifier}` : undefined;

  return (
    <>
      <style>
        {`
            .${classes?.bxShadow} .MuiPaper-elevation.MuiPaper-rounded {
              box-shadow:0px 5px 5px -3px rgb(0 0 0 / 0%), 0px 8px 10px 1px rgb(0 0 0 / 3%), 0px 3px 14px 2px rgb(0 0 0 / 2%) !important;
            }
            
            `}
      </style>
      <div>
        <div aria-describedby={id} onClick={handleClick}>
          {TriggerComp ? (
            <TriggerComp open={open} />
          ) : (
            <IconButton text={text} Icon={Icon} anchorEl={anchorEl} btnClass={iconBtnClass} />
          )}
        </div>
        <Popover
          ref={popoverRef}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          className={`${classes?.bxShadow} ${className && className}`}
          disablePortal
          {...props}>
          {Component}
        </Popover>
      </div>
    </>
  );
}

export const IconButton = ({ Icon, text, anchorEl, btnClass = '' }) => {
  const isOpen = Boolean(anchorEl);
  return (
    <div
      className={`${classes?.iconButtonMain} ${btnClass} ${isOpen && classes?.iconButtonActive}`}>
      {Icon}
      <p>{text}</p>
    </div>
  );
};

export const TablePopperComp = ({
  setAnchorEl,
  anchorEl,
  anchorRef,
  data,
  handleClick,
  isCloseOnClick = true,
  placement = 'bottom-start',
  Component
}) => {
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setAnchorEl(false);
    } else if (event.key === 'Escape') {
      setAnchorEl(false);
    }
  }

  const prevOpen = React.useRef(anchorEl);
  React.useEffect(() => {
    // if (prevOpen.current === true && open === false) {
    // }

    prevOpen.current = anchorEl;
  }, [anchorEl]);

  return (
    <Popper
      open={anchorEl}
      anchorEl={anchorRef.current}
      role={undefined}
      placement={placement}
      transition
      // disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'center-start' ? 'center center' : 'center center'
          }}>
          <Paper className={[classes.popperDiv]}>
            <ClickAwayListener onClickAway={() => setAnchorEl(false)}>
              {Component ? (
                Component
              ) : (
                <MenuList
                  autoFocusItem={anchorEl}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}>
                  {data?.map((item, i) => {
                    return (
                      <MenuItem
                        onClick={() => {
                          handleClick(item);
                          isCloseOnClick && setAnchorEl(false);
                        }}
                        key={i}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              )}
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
