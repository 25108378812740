import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AiFillCaretDown } from 'react-icons/ai';
import classes from './BuyRentCommercialFilters.module.css';
import CustomOverlay from '../CustomOverlay';
import { useState } from 'react';
import Input from '../Input/Input';
import Button from '../Button/Button';
import { ImLocation } from 'react-icons/im';
import {
  bathsFilterOptions,
  bedsFilterOptions,
  // commercialFilterOptions,
  propertyStatusFilterOptions,
  // residentialFilterOptions,
  roomFilterOptions,
  termsFilterOptions
} from '../../constant/commonData';
import { HiOutlineSearch } from 'react-icons/hi';
import { FaFilter } from 'react-icons/fa';
import HomeMoreFiltersModal from '../../modals/HomeMoreFiltersModal';
import { useLocation } from 'react-router-dom';
import { getWindowWidth } from '../../CustomHooks/isMobileViewHook';
import LocationFiltersOverlay from '../LocationFilterOverlay';
import { useSelector } from 'react-redux';

const FilterDiv = ({
  text,
  icon,
  iconRight = <AiFillCaretDown className={classes.icon} />,
  className = '',
  ...props
}) => {
  return (
    <div className={[classes.dropdownBox, className].join(' ')} {...props}>
      <div>
        {icon} <span>{text}</span>
      </div>
      {iconRight && iconRight}
    </div>
  );
};

/*eslint-disable*/
function BuyRentCommercialFilters({
  onSearch,
  containerClass = '',
  data,
  filtersWrapperClass = '',
  rowClass = '',
  filters,
  setFilters,
  t
}) {
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const selectedPropertyType = useLocation()?.pathname?.split('/')[1];
  const [filterType, setFilterType] = useState('buy');
  const [moreFiltersModal, setMoreFiltersModal] = useState(false);
  const [screenWidth, setScreenWidth] = useState();
  const [price, setPrice] = useState({ minPrice: '', maxPrice: '' });
  const [area, setArea] = useState({ min: '', max: '' });
  const [mainData, setMainData] = useState({
    country: '',
    isSearchNearBy: false,
    propertyType: 'commercial',
    propertyStatus: '',
    price: '',
    area: { min: '', max: '' },
    noOfRooms: '',
    noOfBaths: '',
    terms: '',
    moreFilters: {},
    filterType: filterType,
    interiorAmenities: [],
    exteriorAmenities: [],
    search:'',
    // propertyAge:'',
    // propertyAge:'',
    // furniture:'',
    // floorLevel:'',
  });

  // For Popover
  const [show, setShow] = useState('');
  const [target, setTarget] = useState(null);

  useEffect(() => {
    if (selectedPropertyType) {
      setFilterType(selectedPropertyType);
    }
  }, [selectedPropertyType]);

  const handleClick = (event, type) => {
    if (type) {
      setShow(type);
    } else {
      setShow('');
    }
    if (event) {
      setTarget(event.target);
    } else {
      setTarget(null);
    }
  };

  // Apply Filters
  function applyFilters(type, data) {
    if (type == 'location') {
      handleClick();
    }
    setMainData((prev) => ({ ...prev, ...data }));
  }

  useEffect(() => {
    getWindowWidth(setScreenWidth);
  }, []);

  const updateValue = (key, val) => {
    setFilters && setFilters((prev) => ({ ...prev, [key]: val }));
  };

  const test = {
    search:mainData?.search,
    propertyPurpose:selectedPropertyType,
    maxPropertyArea:mainData?.area?.max,
    minPropertyArea:mainData?.area?.min,
    // minPropertyAge:mainData,
    // maxPropertyAge,,
    noOfRooms:mainData?.noOfRooms,
    noOfBathrooms:mainData?.noOfBaths,
    // floorLevel:mainData?,
    minPrice:mainData?.price?.minPrice,
    maxPrice:mainData?.price?.maxPrice,
    maxArea:mainData?.area?.max,
    minArea:mainData?.area?.min,
    // furniture:mainData?.furniture,
    nearby:mainData?.isSearchNearBy,
    lng:mainData?.lng,
    lat:mainData?.lat,
    lan:selectedLanguage,
    province:mainData?.province,
    neighborhood:mainData?.neighborhood,
    town:mainData?.town,
    propertyType:mainData?.propertyType
  };

  return (
    <Container className={`${containerClass}`}>
      <div className={[classes.otherOptions, filtersWrapperClass].join(' ')}>
        <Row className={[classes.row, 'gx-0', rowClass].join(' ')}>
          {screenWidth > 992 && (
            <Col md={2} className={classes.width170}>
              <CustomOverlay
                show={show == 'location'}
                target={target}
                handleClose={(e) => handleClick('', e)}
                targetElement={
                  <FilterDiv
                    onClick={(e) => handleClick(e, 'location')}
                    icon={<ImLocation color={'var(--secondary-clr)'} />}
                    text={t('filters.location')}
                  />
                }>
                <div className={classes.overlayBox}>
                  <LocationFiltersOverlay setLocationState={setFilters} t={t} />
                </div>
              </CustomOverlay>
            </Col>
          )}
          <Col md={4}>
            <Input
              value={filters?.search}
              setValue={(e) => updateValue('search', e)}
              placeholder={t('filters.search')}
              className={classes.searchInput}
            />
          </Col>

          {/* 2nd Option */}
          {screenWidth > 1570 && (
            <Col md={12}>
              <div className={classes.secondLineOptions}>
                <CustomOverlay
                  show={show == 'property-type'}
                  target={target}
                  handleClose={(e) => handleClick('', e)}
                  targetElement={
                    <FilterDiv
                      onClick={(e) => {
                        handleClick(e, 'property-type');
                      }}
                      className={classes.propertyType}
                      text={mainData?.propertyStatus?.[selectedLanguage] ?? 'Property Status'}
                    />
                  }>
                  <div className={[classes.overlayBox, classes.p0].join(' ')}>
                    <ul className={classes.listOptionsWithBrBtm}>
                      {propertyStatusFilterOptions?.map((item, key) => (
                        <li
                          key={key}
                          data-selected={
                            mainData?.propertyStatus?.[selectedLanguage] == item?.[selectedLanguage]
                          }
                          onClick={() => applyFilters('property-type', { propertyStatus: item })}>
                          {item?.[selectedLanguage]}
                        </li>
                      ))}
                    </ul>
                  </div>
                </CustomOverlay>
                <CustomOverlay
                  show={show == 'price'}
                  target={target}
                  handleClose={(e) => handleClick('', e)}
                  targetElement={
                    <FilterDiv
                      onClick={(e) => {
                        handleClick(e, 'price');
                      }}
                      text={t('filters.price')}
                    />
                  }>
                  <div className={[classes.overlayBox, classes.minWidth350].join(' ')}>
                    <Row>
                      <Col md={6}>
                        <Input
                          value={price?.minPrice}
                          label={t('filters.minPrice')}
                          placeholder={t('filters.minPricePlaceholder')}
                          type={'number'}
                          min={0}
                          setValue={(e) => {
                            setPrice((prev) => ({ ...prev, minPrice: e }));
                          }}
                        />
                      </Col>
                      <Col md={6}>
                        <Input
                          label={t('filters.maxPrice')}
                          placeholder={t('filters.maxPricePlaceholder')}
                          value={price?.maxPrice}
                          type={'number'}
                          setValue={(e) => {
                            setPrice((prev) => ({ ...prev, maxPrice: e }));
                          }}
                          min={0}
                        />
                      </Col>
                      <Col md={12}>
                        <div className={[classes.spBtw, classes.mt20].join(' ')}>
                          <Button
                            className={classes.clearBtn}
                            borderRadius={'3px'}
                            onClick={() => {
                              setPrice({ minPrice: '', maxPrice: '' });
                              setFilters((prev) => ({ ...prev, minPrice: '', maxPrice: '' }));
                            }}>
                            {t('filters.clearBtn')}
                          </Button>
                          <Button
                            borderRadius={'3px'}
                            className={classes.btnMaxContent}
                            onClick={() => {
                              setFilters((prev) => ({
                                ...prev,
                                minPrice: price?.minPrice,
                                maxPrice: price?.maxPrice
                              }));
                            }}>
                            {t('filters.applyBtn')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CustomOverlay>
                <CustomOverlay
                  show={show == 'area'}
                  target={target}
                  handleClose={(e) => handleClick('', e)}
                  targetElement={
                    <FilterDiv
                      onClick={(e) => {
                        handleClick(e, 'area');
                      }}
                      text={'area'}
                    />
                  }>
                  <div className={[classes.overlayBox, classes.minWidth350].join(' ')}>
                    <Row>
                      <Col md={6}>
                        <Input
                          value={area?.min}
                          label={t('filters.minArea')}
                          placeholder={t('filters.minAreaPlaceholder')}
                          type={'number'}
                          setValue={(e) => {
                            setArea((prev) => ({ ...prev, min: e }));
                          }}
                          min={0}
                        />
                      </Col>
                      <Col md={6}>
                        <Input
                          label={t('filters.maxArea')}
                          placeholder={t('filters.maxAreaPlaceholder')}
                          value={area?.max}
                          type={'number'}
                          setValue={(e) => {
                            setArea((prev) => ({ ...prev, max: e }));
                          }}
                          min={0}
                        />
                      </Col>
                      <Col md={12}>
                        <div className={[classes.spBtw, classes.mt20].join(' ')}>
                          <Button
                            className={classes.clearBtn}
                            borderRadius={'3px'}
                            onClick={() => {
                              setArea({ min: '', max: '' });
                              setFilters((prev) => ({
                                ...prev,
                                minPropertyArea: '',
                                maxPropertyArea: ''
                              }));
                            }}>
                            {t('filters.clearBtn')}
                          </Button>
                          <Button
                            borderRadius={'3px'}
                            className={classes.btnMaxContent}
                            onClick={() => {
                              setFilters((prev) => ({
                                ...prev,
                                minPropertyArea: area?.min,
                                maxPropertyArea: area?.max
                              }));
                            }}>
                            {t('filters.applyBtn')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CustomOverlay>
                <>
                  <CustomOverlay
                    show={show == 'rooms'}
                    target={target}
                    handleClose={(e) => handleClick('', e)}
                    targetElement={
                      <FilterDiv
                        onClick={(e) => {
                          handleClick(e, 'rooms');
                        }}
                        text={t('filters.rooms')}
                      />
                    }>
                    <div className={[classes.overlayBox, classes.p0].join(' ')}>
                      <ul className={classes.listOptionsWithBrBtm}>
                        {bedsFilterOptions?.map((item, key) => (
                          <li
                            key={key}
                            data-selected={mainData?.noOfRooms == item}
                            onClick={() => applyFilters('rooms', { noOfRooms: item })}>
                            {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </CustomOverlay>
                </>
              </div>
            </Col>
          )}
          <Col md={2} className={classes.width150}>
            <FilterDiv
              onClick={() => {
                setMoreFiltersModal(true);
              }}
              iconRight={screenWidth <= 600 ? false : <FaFilter className={classes.icon} />}
              text={screenWidth <= 600 ? <FaFilter className={classes.icon} /> : t('filters.title')}
            />
          </Col>

          <Col md={2} className={classes.searchBtnWidth}>
            <Button
              leftIcon={<HiOutlineSearch size={24} />}
              className={classes.searchBtn}
              onClick={()=>onSearch(test)}
              variant="secondary"
            />
          </Col>
        </Row>
      </div>
      <HomeMoreFiltersModal
        show={moreFiltersModal}
        setShow={setMoreFiltersModal}
        setFilters={setFilters}>
        {screenWidth <= 992 && (
          <Col md={12}>
            <CustomOverlay
              show={show == 'location'}
              target={target}
              handleClose={(e) => handleClick('', e)}
              targetElement={
                <FilterDiv
                  onClick={(e) => handleClick(e, 'location')}
                  icon={<ImLocation color={'var(--secondary-clr)'} />}
                  text={'Location'}
                />
              }>
              <div className={classes.overlayBox}>
                <LocationFiltersOverlay setLocationState={setFilters} t={t} />
              </div>
            </CustomOverlay>
          </Col>
        )}
        {screenWidth <= 1570 && (
          <Col md={12}>
            <CustomOverlay
              show={show == 'property-type'}
              target={target}
              handleClose={(e) => handleClick('', e)}
              targetElement={
                <FilterDiv
                  onClick={(e) => {
                    handleClick(e, 'property-type');
                  }}
                  text={mainData?.propertyStatus?.[selectedLanguage] ?? 'Property Status'}
                />
              }>
              <div className={[classes.overlayBox, classes.p0].join(' ')}>
                <ul className={classes.listOptionsWithBrBtm}>
                  {propertyStatusFilterOptions?.map((item, key) => (
                    <li
                      key={key}
                      data-selected={
                        mainData?.propertyStatus?.[selectedLanguage] == item?.[selectedLanguage]
                      }
                      onClick={() => applyFilters('property-type', { propertyStatus: item })}>
                      {item?.[selectedLanguage]}
                    </li>
                  ))}
                </ul>
              </div>
            </CustomOverlay>
          </Col>
        )}
        {screenWidth <= 1570 && (
          <Col md={12}>
            <CustomOverlay
              show={show == 'price'}
              target={target}
              handleClose={(e) => handleClick('', e)}
              targetElement={
                <FilterDiv
                  onClick={(e) => {
                    handleClick(e, 'price');
                  }}
                  text={t('filters.price')}
                />
              }>
              <div className={[classes.overlayBox, classes.minWidth350].join(' ')}>
                <Row>
                  <Col md={6}>
                    <Input
                      value={price?.minPrice}
                      label={t('filters.minPrice')}
                      placeholder={t('filters.minPricePlaceholder')}
                      type={'number'}
                      setValue={(e) => {
                        setPrice((prev) => ({ ...prev, minPrice: e }));
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      label={t('filters.maxPrice')}
                      placeholder={t('filters.maxPricePlaceholder')}
                      value={price?.maxPrice}
                      type={'number'}
                      setValue={(e) => {
                        setPrice((prev) => ({ ...prev, maxPrice: e }));
                      }}
                    />
                  </Col>
                  <Col md={12}>
                    <div className={[classes.spBtw, classes.mt20].join(' ')}>
                      <Button
                        borderRadius={'3px'}
                        className={classes.clearBtn}
                        onClick={() => {
                          setPrice({ minPrice: '', maxPrice: '' });
                          setMainData((prev) => ({ ...prev, minPrice: '', maxPrice: '' }));
                        }}>
                        Clear
                      </Button>
                      <Button
                        borderRadius={'3px'}
                        className={classes.btnMaxContent}
                        onClick={() => {
                          const temp = { ...mainData, ...price };
                          setMainData(temp);
                        }}>
                        Apply
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </CustomOverlay>
          </Col>
        )}
        {screenWidth <= 1570 && (
          <Col md={12}>
            <CustomOverlay
              show={show == 'area'}
              target={target}
              handleClose={(e) => handleClick('', e)}
              targetElement={
                <FilterDiv
                  onClick={(e) => {
                    handleClick(e, 'area');
                  }}
                  text={'area'}
                />
              }>
              <div className={[classes.overlayBox, classes.minWidth350].join(' ')}>
                <Row>
                  <Col md={6}>
                    <Input
                      value={area?.min}
                      label={t('filters.minArea')}
                      placeholder={t('filters.minAreaPlaceholder')}
                      type={'number'}
                      setValue={(e) => {
                        setArea((prev) => ({ ...prev, min: e }));
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      label={t('filters.maxArea')}
                      placeholder={t('filters.maxAreaPlaceholder')}
                      value={area?.max}
                      type={'number'}
                      setValue={(e) => {
                        setArea((prev) => ({ ...prev, max: e }));
                      }}
                    />
                  </Col>
                  <Col md={12}>
                    <div className={[classes.spBtw, classes.mt20].join(' ')}>
                      <Button
                        borderRadius={'3px'}
                        className={classes.clearBtn}
                        onClick={() => {
                          setArea({ min: '', max: '' });
                          setMainData((prev) => ({ ...prev, minArea: '', maxArea: '' }));
                        }}>
                        {t('filters.clearBtn')}
                      </Button>
                      <Button
                        borderRadius={'3px'}
                        className={classes.btnMaxContent}
                        onClick={() => {
                          const temp = { ...mainData, minArea: area?.min, maxArea: area?.max };
                          setMainData(temp);
                        }}>
                        {t('filters.applyBtn')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </CustomOverlay>
          </Col>
        )}
        <CustomOverlay
          show={show == 'bathrooms'}
          target={target}
          handleClose={(e) => handleClick('', e)}
          targetElement={
            <FilterDiv
              onClick={(e) => {
                handleClick(e, 'bathrooms');
              }}
              text={t('filters.bathrooms')}
            />
          }>
          <div className={[classes.overlayBox, classes.p0].join(' ')}>
            <ul className={classes.listOptionsWithBrBtm}>
              {bathsFilterOptions?.map((item, key) => (
                <li
                  key={key}
                  data-selected={mainData?.noOfBaths == item}
                  onClick={() => applyFilters('bathrooms', { noOfBaths: item })}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </CustomOverlay>

        {screenWidth <= 1570 && mainData?.propertyType == 'residential' && (
          <>
            <Col md={12}>
              <CustomOverlay
                show={show == 'rooms'}
                target={target}
                handleClose={(e) => handleClick('', e)}
                targetElement={
                  <FilterDiv
                    onClick={(e) => {
                      handleClick(e, 'rooms');
                    }}
                    text={t('filters.rooms')}
                  />
                }>
                <div className={[classes.overlayBox, classes.p0].join(' ')}>
                  <ul className={classes.listOptionsWithBrBtm}>
                    {roomFilterOptions?.map((item, key) => (
                      <li
                        key={key}
                        data-selected={mainData?.noOfRooms == item}
                        onClick={() => updateValue('noOfRooms', item)}>
                        {item?.[selectedLanguage]}
                      </li>
                    ))}
                  </ul>
                </div>
              </CustomOverlay>
            </Col>
            <Col md={12}>
              <CustomOverlay
                show={show == 'bathrooms'}
                target={target}
                handleClose={(e) => handleClick('', e)}
                targetElement={
                  <FilterDiv
                    onClick={(e) => {
                      handleClick(e, 'bathrooms');
                    }}
                    text={t('filters.bathrooms')}
                  />
                }>
                <div className={[classes.overlayBox, classes.p0].join(' ')}>
                  <ul className={classes.listOptionsWithBrBtm}>
                    {bathsFilterOptions?.map((item, key) => (
                      <li
                        key={key}
                        data-selected={mainData?.noOfBaths == item}
                        onClick={() => updateValue('noOfBathrooms', item)}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </CustomOverlay>
            </Col>
          </>
        )}
        {screenWidth <= 1570 && filterType == 'rent' && (
          <Col md={12}>
            <CustomOverlay
              show={show == 'terms'}
              target={target}
              handleClose={(e) => handleClick('', e)}
              targetElement={
                <FilterDiv
                  onClick={(e) => {
                    handleClick(e, 'terms');
                  }}
                  text={t('filters.terms')}
                />
              }>
              <div className={[classes.overlayBox, classes.p0].join(' ')}>
                <ul className={classes.listOptionsWithBrBtm}>
                  {termsFilterOptions?.map((item, key) => (
                    <li
                      key={key}
                      data-selected={mainData?.terms == item}
                      onClick={() => applyFilters('terms', { terms: item })}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </CustomOverlay>
          </Col>
        )}
      </HomeMoreFiltersModal>
    </Container>
  );
}

export default BuyRentCommercialFilters;
