import React from 'react';
import classes from './CompanyCard.module.css';
import { MdLocationPin } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { imageUrl } from '../../config/apiUrl';
import { useTranslation } from 'react-i18next';

const CompanyCard = ({ data, onClick }) => {
  const { t } = useTranslation('webCommon');
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  return (
    <div className={classes?.cardContainer} onClick={onClick.bind(this, data)}>
      {/* company Image */}
      <div className={classes?.agentImageContainer}>
        <img src={imageUrl(data?.photo)} />
      </div>

      {/* top */}
      {/* <div className={classes?.topStrip}>
        <div className={classes?.imageContainer}>
          <img src={data?.companyLogo} />
        </div>
        <div className={classes?.topStripInfo}>
          <p>{data?.companyName}</p>
          <p>{data?.companyTagLine}</p>
        </div>
      </div> */}

      {/* head office*/}
      <div className={classes?.headOfficeContainer}>
        <p>{data?.companyName?.[selectedLanguage]}</p>
        <p className={classes?.paraHeading}>{data?.companyType?.[selectedLanguage]}</p>
        <div className={classes?.locationContainer}>
          <MdLocationPin fill="var(--secondary-clr)" size={20} />
          <span>{data?.address}</span>
        </div>
      </div>

      {/* PropertiesInfo */}
      <div className={classes?.propertiesInfo}>
        <p className={classes?.paraHeading}>{t('companyCard.properties')}</p>
        <div className={classes?.propertyCountInfo}>
          <p>
            <span>{data?.rentProperties ?? 0}</span>{t('companyCard.forRent')}
          </p>
          <p>
            <span>{data?.buyProperties ?? 0}</span>{t('companyCard.forBuy')}
          </p>
        </div>
      </div>

      {/* Languages */}
      <div className={classes?.languagesInfo}>
        <p className={classes?.paraHeading}>{t('companyCard.weSpeak')}</p>
        <p>{data?.language?.map((e) => e?.[selectedLanguage])?.join(', ')}</p>
      </div>
    </div>
  );
};

export default CompanyCard;
