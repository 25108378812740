/* eslint-disable */

import React, { useState } from 'react';
import classes from './EventsMangement.module.css';
import SideBarSkeleton from '../../../Component/SideBarSkeleton';
import { Col, Container, Row } from 'react-bootstrap';
import CustomMaterialTable from '../../../Component/CustomMaterialTable';
import { AiFillEye } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';
import { MdOutlineUpload } from 'react-icons/md';
import Button from '../../../Component/Button/Button';
import SearchInput from '../../../Component/SearchInput';
import { useNavigate } from 'react-router-dom';
import { dateFilterOptions } from '../../../constant/commonData';
import DropDown from '../../../Component/DropDown/DropDown';
import {
  useActiveDeactiveEventMutation,
  useAssignAgentOnPropertyMutation,
  useDeleteEventMutation,
  useGetMyEventsQuery,
  useRefreshEventMutation,
  useUpgradeToPremiumOrFeaturedMutation
} from '../../../store/apiSlice/apiSlice';
import useDebounce from '../../../CustomHooks/useDebounce';
import UpgradingListingModal from '../../../modals/UpgradingListingModal/UpgradingListingModal';
import { recordsLimit } from '../../../config/apiUrl';
import AreYouSureModal from '../../../modals/AreYouSureModal';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { HiRefresh } from 'react-icons/hi';
import { FaToggleOn } from 'react-icons/fa';
import { ImUserCheck } from 'react-icons/im';
import AssignAgentModal from '../../../modals/AssignAgentModal';
import { useTranslation } from 'react-i18next';

const tableData = Array(10)
  .fill({
    id: '5645',
    date: '2020.11.12',
    type: 'broker',
    title: 'Text text',
    location: 'London',
    status: 'Active',
    agent: 'John Smith'
  })
  ?.map((item) => ({ ...item, id: Math.floor(Math.random() * 10000) }));

function EventsManagement() {
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const { t } = useTranslation('dashboardEvents');
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [dateFilter, setDateFilter] = useState(dateFilterOptions[0]);
  const [page, setPage] = useState(1);
  const [isFeaturedModalOpen, setIsFeaturedModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');

  const [upgradeApi, { isLoading: isUpgradeLoading }] = useUpgradeToPremiumOrFeaturedMutation();

  console.log(selectedRows, 'selectedRows selectedRows ');

  const searchDebounce = useDebounce(searchText, 500);

  const {
    data: apiData,
    isLoading,
    isFetching
  } = useGetMyEventsQuery({
    page: page,
    search: searchDebounce,
    sortBy: dateFilter?.value,
    lan: selectedLanguage
  });

  const [activeDeactiveApi, { isLoading: isStatusLoading }] = useActiveDeactiveEventMutation();
  const [deleteRecordsApi, { isLoading: isDeleting }] = useDeleteEventMutation();
  const [refreshApi, { isLoading: isRefreshing }] = useRefreshEventMutation();
  const [assignApi, { isLoading: isAssignLoading }] = useAssignAgentOnPropertyMutation();

  //  delete api
  const deleteMultiple = async () => {
    const res = await deleteRecordsApi({ ids: selectedRows?.map((item) => item._id) });
    if (res?.data) {
      toast.success(`${selectedRows?.length > 1 ? 'Events' : 'Event'} deleted successfully.`);
      setSelectedRows([]);
    }
  };

  const handleRefresh = async (slug) => {
    const response = await refreshApi({ slug });
    if (response?.data) {
      toast.success('Event refreshed successfully.');
    }
    setSelectedItem(null);
  };

  // table headings
  const headings = [
    {
      id: 'SNO',
      label: t('table.col1'),
      renderValue: (_, __, i) => {
        return i + 1;
      }
    },
    {
      id: 'createdAt',
      label: t('table.col2'),
      renderValue: (item, col) => {
        return moment(item[col?.id]).format('DD/MM/YYYY HH:MM A');
      }
    },
    {
      id: 'eventType',
      label: t('table.col3'),
      renderValue: (item, col) => item[col?.id]?.title?.[selectedLanguage]
    },
    {
      id: 'title',
      label: t('table.col4'),
      renderValue: (item, col) => item[col?.id]?.[selectedLanguage]
    },
    { id: 'address', label: t('table.col5') },
    {
      id: 'isActive',
      label: t('table.col6'),
      renderValue: (item, col) => {
        console.log(item[col?.id], 'item');
        return item[col?.id] ? 'Active' : 'Deactive';
      }
    },
    {
      id: 'agent',
      label: t('table.col7'),
      renderValue: (item, col) => {
        console.log(item, 'test');

        return item?.assignedAgent
          ? `${item?.assignedAgent?.agentName?.[selectedLanguage]}`
          : 'No Agent Assigned';
      }
    },
    { id: 'options', label: t('table.col8'), align: 'center' }
  ];

  return (
    <SideBarSkeleton>
      <Container fluid className={classes.page}>
        <Row>
          <Col md={12} className={classes.mb58}>
            <div className={classes.header}>
              <div className={classes.left}>
                <SearchInput
                  placeholder={t('table.searchInput')}
                  setter={setSearchText}
                  state={searchText}
                  variant="dashboard"
                  containerStyle={{ width: '300px' }}
                />
                <DropDown
                  label={t('table.sortByDate')}
                  containerClassName={classes.dropDownContainer}
                  customStyle={{ padding: '0px 6px', minHeight: '40px', minWidth: '200px' }}
                  options={dateFilterOptions}
                  setValue={(e) => {
                    setDateFilter(e);
                  }}
                  value={dateFilter}
                  placeholder={'Select Date'}
                  // isClearable={true}
                />
              </div>
              <div className={classes.headerRight}>
                <Button
                  borderRadius={'8px'}
                  className={classes.headerBtn}
                  onClick={() => navigate('/company/events-management/new-event')}>
                  {t('table.createNewevents')}
                </Button>
                <Button
                  borderRadius={'8px'}
                  className={classes.deleteBtn}
                  disabled={selectedRows?.length == 0 || isDeleting}
                  onClick={deleteMultiple}>
                  {isDeleting ? t('table.deleting') : t('table.delete')}
                </Button>
                {/* <Button borderRadius={'8px'}>Create New Property</Button> */}
              </div>
            </div>
          </Col>
          <Col md={12}>
            <h5 className={classes.heading}>{t('table.title')}</h5>
          </Col>
          <Col md={12}>
            <CustomMaterialTable
              headings={headings}
              data={apiData?.data || []}
              showOptionsColumn
              page={page}
              setPage={setPage}
              isLoading={isLoading || isFetching}
              totalRecords={Math.ceil(apiData?.totalRecords / recordsLimit)}
              noDataText={t('table.noDataText')}
              // options={[
              //   { label: 'View', icon: <AiFillEye size={18} /> },
              //   {
              //     label: 'Edit',
              //     icon: <FiEdit size={18} />,
              //     onClick: (e, item) =>
              //       navigate(`/company/events-management/edit-event/${item?.slug}`)
              //   },
              //   {
              //     label: (e) => (e?.isActive ? 'Deactivate' : 'Activate'),
              //     onClick: (_, e) => {
              //       setSelectedItem(e);
              //       setIsModalOpen('active-deactive');
              //     },
              //     switch: true
              //   },
              //   {
              //     // label: 'Upgrade Package',
              //     label: 'Refresh ',
              //     icon: <MdOutlineUpload size={18} />,
              //     onClick: (label, item) => {
              //       handleRefresh(item?.slug);
              //     }
              //   },
              //   {
              //     // label: 'Upgrade Package',
              //     label: 'Upgrade to Premium/Featured',
              //     icon: <MdOutlineUpload size={18} />,
              //     onClick: (label, item) => {
              //       console.log('upgrade package', label, item);
              //       setIsFeaturedModalOpen(true);
              //     }
              //   }
              // ]}

              options={[
                {
                  label: t('table.options.view'),
                  icon: <AiFillEye size={18} />,
                  onClick: (label, item) => {
                    navigate(`/events/${item?.slug}`);
                  }
                },
                {
                  label: t('table.options.refresh'),
                  icon: <HiRefresh size={18} />,
                  onClick: (label, item) => {
                    setSelectedItem({ ...item, flag: 'refresh' });
                  }
                },
                {
                  label: t('table.options.edit'),
                  icon: <FiEdit size={18} />,
                  onClick: (e, rowData) => {
                    navigate(`/company/events-management/edit-event/${rowData?.slug}`);
                  }
                },
                {
                  label: (e) =>
                  !e?.isActive ? t('table.options.activate') : t('table.options.deactivate'),
                  onClick: (label, item) => {
                    setSelectedItem({ ...item, flag: 'active-deactive' });
                  },
                  switch: true
                },
                {
                  label: (e) =>
                    e?.assignedAgent
                      ? t('table.options.assignToAgent')
                      : t('table.options.agentAssigned'),
                  className: (e) => (e?.assignedAgent ? 'disabledMenuItem' : ''),
                  onClick: (label, item) => {
                    if (!!item?.assignedAgent) return;
                    setSelectedItem({ ...item, flag: 'assignAgent' });
                  },

                  icon: <ImUserCheck size={18} />
                },

                {
                  label: t('table.options.upgradeToPremiumORFeatured'),
                  onClick: (label, item) => {
                    setIsFeaturedModalOpen({ ...item, boolean: true });
                  },

                  icon: <MdOutlineUpload size={18} />
                }
              ]}
              onOptionClick={() => console.log()}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              allowMultipleSelection
            />
          </Col>
        </Row>
      </Container>

      {isFeaturedModalOpen && (
        <UpgradingListingModal show={isFeaturedModalOpen} setShow={setIsFeaturedModalOpen} />
      )}

      {selectedItem?.flag == 'active-deactive' && (
        <AreYouSureModal
          label1Icon={false}
          label2Icon={false}
          label1={t('activeDeactiveModal.yesBtn')}
          label2={t('activeDeactiveModal.noBtn')}
          btnApiCallText={t('activeDeactiveModal.yesBtnLoading')}
          show={selectedItem?.flag == 'active-deactive'}
          setShow={setSelectedItem}
          subTitle={`${
            selectedItem?.isActive
              ? t('activeDeactiveModal.deactiveText')
              : t('activeDeactiveModal.activeText')
          }`}
          isApiCall={isStatusLoading}
          onClick={async () => {
            const res = await activeDeactiveApi({
              slug: selectedItem?.slug,
              status: !selectedItem?.isActive
            });
            if (res?.data) {
              toast.success(
                `${
                  res?.data?.data?.isActive
                    ? t('activeDeactiveModal.successToast')
                    : t('activeDeactiveModal.deactiveToast')
                }`
              );
              setSelectedItem(null);
            }
          }}
        />
      )}

      {/*  */}

      {selectedItem?.flag == 'assignAgent' && (
        <AssignAgentModal
          t={t}
          show={selectedItem?.flag == 'assignAgent'}
          setShow={setSelectedItem}
          isLoading={isAssignLoading}
          onClick={async (e) => {
            const res = await assignApi({
              slug: selectedItem?.slug,
              agentSlug: e,
              type: 'event'
            });
            if (res?.data) {
              toast.success(t('assignAgentModal.agentAssignedToast'));
              setSelectedItem(null);
            }
          }}
        />
      )}
      {selectedItem?.flag == 'refresh' && (
        <AreYouSureModal
          label1Icon={false}
          label2Icon={false}
          label1={t('refreshModal.yesBtn')}
          label2={t('refreshModal.noBtn')}
          show={selectedItem?.flag == 'refresh'}
          setShow={setSelectedItem}
          subTitle={t('refreshModal.text')}
          isApiCall={isRefreshing}
          onClick={() => {
            handleRefresh(selectedItem?.slug);
          }}
        />
      )}

      {isFeaturedModalOpen?.boolean && (
        <UpgradingListingModal
          show={isFeaturedModalOpen?.boolean}
          setShow={setIsFeaturedModalOpen}
          isSubmitting={isUpgradeLoading}
          onSubmit={async (params) => {
            const res = await upgradeApi({
              slug: isFeaturedModalOpen?.slug,
              data: params,
              type: 'event'
            });
            if (res?.data) {
              setIsFeaturedModalOpen(false);
              return toast.success(t('upgradeListingModal.successToast'));
            }
          }}
        />
      )}
    </SideBarSkeleton>
  );
}

export default EventsManagement;
