/*eslint-disable*/
import React from 'react';
import classes from './ContactedPropertyDetailModal.module.css';
import ModalSkeleton from '../ModalSkeleton';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ShowMoreShowLessText from '../../Component/ShowMoreShowLess/ShowMoreShowLessText';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LabelAndValue = ({ label, value, className = '', type = '' }) => {
  return (
    <div
      className={[classes.labelValue, className].join(' ')}
      style={{
        ...(type == 'desc' ? { flexDirection: 'column' } : {})
      }}>
      <span className={classes.label}>
        {label}
        {': '}
      </span>
      {type == 'desc' ? (
        <ShowMoreShowLessText text={value} visibility={200} />
      ) : (
        <span className={classes.value}>{value ?? '---'}</span>
      )}
    </div>
  );
};

function ContactedPropertyDetailModal({ show, setShow, data }) {
  const {t}=useTranslation('contactedProperties')
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      modalClass={classes.main}
      borderRadius={'0px'}
      width={'800px'}
      header={t('viewModal.title')}
      headerClass={classes.header}>
      <div>
        <Row className={classes?.row}>
          <Col md={12}>
            <Link
              title="Click to View Property"
              className={classes?.link}
              to={`${window.location.origin}/property/${data?.entity?.slug}`}>
              {data?.entity?.title?.[selectedLanguage]}
            </Link>
          </Col>
          <Col md={6}>
            <LabelAndValue label={t('viewModal.fullName')} value={data?.name} />
          </Col>
          <Col md={6}>
            <LabelAndValue label={t('viewModal.email')} value={data?.email} />
          </Col>
          <Col md={6}>
            <LabelAndValue label={t('viewModal.phone')} value={data?.phone} />
          </Col>
          <Col md={6}>
            <LabelAndValue
              label={t('viewModal.contactMethod')}
              value={data?.preferredContactMethod}
            />
          </Col>
          <Col md={12}>
            <LabelAndValue label={t('viewModal.description')} value={data?.message} type="desc" />
          </Col>
        </Row>
      </div>


    </ModalSkeleton>
  );
}

export default ContactedPropertyDetailModal;
