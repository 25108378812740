/* eslint-disable */

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import React, { lazy, Suspense, useLayoutEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-modern-drawer/dist/index.css';
import 'react-quill/dist/quill.snow.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './assets/Styles/style.css';
// import BeforeLoginRoute from './Helper/BeforeLoginRoute';
// import ProtectedRoute from './Helper/ProtectedRoute';

// Components
import { Loader } from './Component/Loader';
import AgentDetailPage from './pages/AgentDetailPage/AgentDetailPage';
import AgentOrCompany from './pages/AgentOrCompany';
import Blogs from './pages/Blogs';
import CompanyDetailPage from './pages/CompanyDetailPage/CompanyDetailPage';
import EventDetail from './pages/EventDetails';
import NewEvents from './pages/NewEvents';
import PropertyDetails from './pages/PropertyDetail';

import ScrollToTop from './Helper/ScrollToTop';
import AccountSettings from './pages/AccountSettings';
import AgentDashboard from './pages/Agent/Dashboard';
import AgentRegisterationForm from './pages/AgentRegisterationForm';
import BlogDetail from './pages/BlogDetail';
import CompanyDashboard from './pages/CompanyDashboard';
import CompareList from './pages/CompareList';
import ContactUs from './pages/ContactUs';
import FollowedAgents from './pages/FollowedAgents';
import Login from './pages/Login';
import MortgageLoanPage from './pages/MortgageLoanPage';
import NotificationPage from './pages/Notification';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ProjectDetail from './pages/ProjectDetail';
import Projects from './pages/Projects';
import Properties from './pages/Properties';
import PropertyRequest from './pages/PropertyRequest';
import SavedProperties from './pages/SavedProperties';
import SavedSearches from './pages/SavedSearches';
import Signup from './pages/Signup';
import TermsAndConditions from './pages/TermsAndConditions';

// Old Design Routes
// import FollowingAgents from './pages/FollowingAgents';
// import FollowingCompanies from './pages/FollowingCompanies';
// import WhistlistProperties from './pages/WhistlistProperties';
// import AddOrEditPropertyForm from './pages/AddOrEditPropertyForm';
// import CreateAgentPage from './pages/CreateAgentPage/CreateAgentPage';
// import AddOrEditEventForm from './pages/AddOrEditEventForm';
// import AddOrEditProject from './pages/AddOrEditProject';
// import AddOrEditAgent from './pages/AddOrEditAgent';
// import CompanyDashboard from './pages/CompanyDashboard';
// import BecomeAPartner from './pages/BecomeAPartner';
// import UserDashboard from './pages/Dashboard';

// According to New Design

// import AgentDashboard from './pages/Agent/Dashboard';
// import PropertiesManagement from './pages/Agent/PropertiesManagement';
// import NewProperty from './pages/Agent/NewProperty';
// import ProjectsManagement from './pages/Agent/ProjectsManagement';
// import EventsManagement from './pages/Agent/EventsManagement';
// import NewProject from './pages/Agent/NewProject';
// import NewEvent from './pages/Agent/NewEvent';

// Lazy Imports Pages
const LandingPage = lazy(() => import('./pages/LandingPage/LandingPage'));

// Agent Dashboard Pages
import AgentEventsManagement from './pages/Agent/EventsManagement';
import AgentFollowers from './pages/Agent/Followers';
import AgentCreateNewEvent from './pages/Agent/NewEvent';
import AgentCreateNewProject from './pages/Agent/NewProject';
import AgentCreateNewProperty from './pages/Agent/NewProperty';
import ProfileSettings from './pages/Agent/ProfileSettings';
import AgentProjectsManagement from './pages/Agent/ProjectsManagement';
import AgentPropertiesManagement from './pages/Agent/PropertiesManagement';
import PropertyRequests from './pages/Agent/PropertyRequests';
// Agent Dashboard Pages

// Company Dashboard Pages
import CompanyCreateNewProperty from './pages/Company/NewProperty';
import CompanyPropertiesManagement from './pages/Company/PropertiesManagement';
// import CompanyCreateNewProject from './pages/Company/NewProject';
// import CompanyCreateNewEvent from './pages/Agent/NewEvent';
import AddAgentPage from './pages/Company/AddAgentPage';
import CompanyAgentsManagement from './pages/Company/AgentsManagement';
import CompanyEventsManagement from './pages/Company/EventsManagement';
import CompanyFollowers from './pages/Company/Followers';
import CompanyCreateNewEvent from './pages/Company/NewEvent';
import CompanyCreateNewProject from './pages/Company/NewProject';
import CompanyProfileSettings from './pages/Company/ProfileSettings';
import CompanyProjectsManagement from './pages/Company/ProjectsManagement';
import CompanyPropertyRequests from './pages/Company/PropertyRequests';

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ProtectedRoute from './Helper/ProtectedRoute';
import ProjectUnit from './pages/Company/ProjectUnit';
import UserContactedProperties from './pages/ContactedProperties';
import UserPropertyRequests from './pages/PropertyRequests';
import {
  useLazyGetAllAmenitiesQuery,
  useLazyGetAllBannerQuery,
  useLazyGetAllEventTypeQuery,
  useLazyGetAllProjectTypeQuery,
  useLazyGetAllPropertyTypeQuery,
  useLazyGetCompanyAgentsQuery
} from './store/apiSlice/apiSlice';
import {
  setAllAgents,
  setAllAmenities,
  setAllBanners,
  setAllEventTypes,
  setAllProjectTypes,
  setAllPropertyTypes
} from './store/common/commonSlice';
import { apiUrl } from './config/apiUrl';
import { updateUser } from './store/auth/authSlice';
import { io } from 'socket.io-client';
// Company Dashboard Pages
export let socket = null;

function App() {
  const { ready, i18n } = useTranslation();
  const { isLogin, user } = useSelector((state) => state?.authReducer);
  const { selectedLanguage, direction } = useSelector((state) => state?.languageReducer);

  const [getAllEventsTypes] = useLazyGetAllEventTypeQuery();
  const [getAllPropertiesTypes] = useLazyGetAllPropertyTypeQuery();
  const [getAllProjectsTypes] = useLazyGetAllProjectTypeQuery();
  const [getAllAmenities] = useLazyGetAllAmenitiesQuery();
  const [getCompanyAgents] = useLazyGetCompanyAgentsQuery();
  const [getAllBanners] = useLazyGetAllBannerQuery();
  const dispatch = useDispatch();

  const getData = async () => {
    const [
      dataForEventType,
      dataForPropertyType,
      dataForProjectType,
      dataForInteriorAmenities,
      dataForExteriorAmenities,
      companyAgents,
      allBanners
    ] = await Promise.allSettled([
      getAllEventsTypes(),
      getAllPropertiesTypes(),
      getAllProjectsTypes(),
      getAllAmenities('interior'),
      getAllAmenities('exterior'),
      user?.role == 'company' && getCompanyAgents(),
      getAllBanners()
    ]);
    if (dataForEventType?.value?.data) {
      dispatch(setAllEventTypes(dataForEventType?.value?.data));
    }
    if (dataForPropertyType?.value?.data) {
      dispatch(setAllPropertyTypes(dataForPropertyType?.value?.data));
    }
    if (dataForProjectType?.value?.data) {
      dispatch(setAllProjectTypes(dataForProjectType?.value?.data));
    }
    if (dataForInteriorAmenities?.value?.data) {
      dispatch(
        setAllAmenities({ data: dataForInteriorAmenities?.value?.data?.data, type: 'interior' })
      );
    }
    if (dataForExteriorAmenities?.value?.data) {
      dispatch(
        setAllAmenities({ data: dataForExteriorAmenities?.value?.data?.data, type: 'exterior' })
      );
    }
    if (companyAgents?.value?.data) {
      dispatch(setAllAgents({ data: companyAgents?.value?.data?.data }));
    }
    if (allBanners?.value?.data) {
      dispatch(setAllBanners({ data: allBanners?.value?.data?.data }));
    }
  };

  useEffect(() => {
    // if (isLogin) {
    getData();
    // }
  }, []);

  // For Language
  useLayoutEffect(() => {
    // setShowPage(true);
    if (direction) {
      document.querySelector('body').dir = direction;
    }
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, [direction, selectedLanguage]);

  useEffect(() => {
    if (isLogin) {
      socket = io(apiUrl, {
        transports: ['websocket'],
        reconnection: true,
        reconnectionDelay: 180000,
        reconnectionDelayMax: 300000
      });

      socket?.emit('join', user?._id);

      socket?.on('package-expired', (user) => {
        dispatch(updateUser(user));
      });

      socket?.on('package-purchased', (user) => {
        dispatch(updateUser(user));
      });
    }
  }, [isLogin]);

  // For Language

  if (!ready) return <Loader />;

  return (
    <React.Fragment>
      <ToastContainer />
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <ScrollToTop />
          <Routes>
            <Route path="/" exact element={<LandingPage />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="/agents/:slug" exact element={<AgentDetailPage />} />
            <Route path="/company/:slug" exact element={<CompanyDetailPage />} />
            <Route path="/property/:slug" exact element={<PropertyDetails />} />
            <Route path="/projects/:slug" exact element={<ProjectDetail />} />
            <Route path="/events/:slug" exact element={<EventDetail />} />
            <Route path="/events" exact element={<NewEvents />} />
            <Route path="/agents" exact element={<AgentOrCompany key={'Agent'} />} />
            <Route path="/company" exact element={<AgentOrCompany key={'Company'} />} />
            <Route path="/blog" exact element={<Blogs />} />
            <Route path="/blog/:slug" exact element={<BlogDetail />} />
            <Route path="/projects" exact element={<Projects />} />
            <Route path="/buy" exact element={<Properties key={'buy'} />} />
            <Route path="/rent" exact element={<Properties key={'rent'} />} />
            <Route path="/property-request" exact element={<PropertyRequest />} />
            <Route path="/advertise-with-us" exact element={<AgentRegisterationForm />} />
            {/* New Pages */}
            <Route path="/contact-us" exact element={<ContactUs />} />
            <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" exact element={<TermsAndConditions />} />
            <Route path="/signup" exact element={<Signup />} />

            <Route path="/mortgage-bank-loan" exact element={<MortgageLoanPage />} />
            {/* Common Routes */}
            {/* <Route path="/dashboard" exact element={<UserDashboard />} /> */}
            {/* Common Routes */}

            {/* Agent Dashboard Routes */}
            <Route path="/agent" element={<ProtectedRoute allowedRole={['agent']} />}>
              <Route path="dashboard" exact element={<AgentDashboard />} />
              <Route path="account-settings" exact element={<ProfileSettings />} />

              <Route path="properties-management" exact element={<AgentPropertiesManagement />} />
              <Route
                path="properties-management/new-property"
                exact
                element={<AgentCreateNewProperty />}
              />
              <Route
                path="properties-management/edit-property/:slug"
                exact
                element={<AgentCreateNewProperty />}
              />
              <Route path="projects-management" exact element={<AgentProjectsManagement />} />
              <Route
                path="projects-management/new-project"
                exact
                element={<AgentCreateNewProject />}
              />
              <Route
                path="projects-management/edit-project/:slug"
                exact
                element={<AgentCreateNewProject />}
              />

              <Route path="projects-units/:id" exact element={<ProjectUnit />} />
              <Route path="events-management" exact element={<AgentEventsManagement />} />
              <Route path="events-management/new-event" exact element={<AgentCreateNewEvent />} />

              <Route
                path="events-management/edit-event/:slug"
                exact
                element={<AgentCreateNewEvent />}
              />

              <Route path="property-requests" exact element={<PropertyRequests />} />
              <Route path="followers" exact element={<AgentFollowers />} />
            </Route>
            {/* Agent Dashboard Routes */}

            {/* Company Dashboard Routes */}
            <Route path="/company" element={<ProtectedRoute allowedRole={['company']} />}>
              <Route path="dashboard" exact element={<CompanyDashboard />} />
              <Route path="agents-management/new-agent" exact element={<AddAgentPage />} />
              <Route path="agents-management/edit-agent/:slug" exact element={<AddAgentPage />} />
              <Route path="profile-settings" exact element={<CompanyProfileSettings />} />
              <Route path="agents-management" exact element={<CompanyAgentsManagement />} />

              <Route path="properties-management" exact element={<CompanyPropertiesManagement />} />
              <Route path="projects-units/:id" exact element={<ProjectUnit />} />

              <Route path="projects-management" exact element={<CompanyProjectsManagement />} />

              <Route path="events-management" exact element={<CompanyEventsManagement />} />
              <Route path="property-requests" exact element={<CompanyPropertyRequests />} />
              <Route path="followers" exact element={<CompanyFollowers />} />

              {/* This will replace */}
              <Route path="events-management/new-event" exact element={<CompanyCreateNewEvent />} />
              <Route
                path="events-management/edit-event/:slug"
                exact
                element={<CompanyCreateNewEvent />}
              />
              <Route
                path="properties-management/new-property"
                exact
                element={<CompanyCreateNewProperty />}
              />
              <Route
                path="properties-management/edit-property/:slug"
                exact
                element={<CompanyCreateNewProperty />}
              />
              <Route
                path="projects-management/new-project"
                exact
                element={<CompanyCreateNewProject />}
              />
              <Route
                path="projects-management/edit-project/:slug"
                exact
                element={<CompanyCreateNewProject />}
              />
              {/* This will replace */}
            </Route>
            {/* Company Dashboard Routes */}

            {/* <Route path="/following-agents" exact element={<FollowingAgents />} /> */}
            {/* <Route path="/following-companies" exact element={<FollowingCompanies />} /> */}
            {/* <Route path="/wishlist-properties" exact element={<WhistlistProperties />} /> */}
            {/* <Route path="/RequestPackage" exact element={<BecomeAPartner />} /> */}

            {/* <Route path="/add-event" exact element={<AddOrEditEventForm />} />
            <Route path="/add-property" exact element={<AddOrEditPropertyForm />} />
            <Route path="/add-project" exact element={<AddOrEditProject />} />
            <Route path="/add-agent" exact element={<AddOrEditAgent />} /> */}
            {/* <Route path="/agent/manage" exact element={<CreateAgentPage />} /> */}
            {/*  */}
            {/* User Dashboard */}

            <Route path="/" element={<ProtectedRoute allowedRole={['user']} />}>
              <Route path="notifications" exact element={<NotificationPage />} />
              <Route path="account-settings" exact element={<AccountSettings />} />
              <Route path="followed-agents" exact element={<FollowedAgents />} />
              <Route path="compare-list" exact element={<CompareList />} />
              <Route
                path="saved-properties"
                exact
                element={<SavedProperties key={'saved-properties'} />}
              />
              <Route path="saved-events" exact element={<SavedProperties key={'saved-events'} />} />
              <Route path="property-requests" exact element={<UserPropertyRequests />} />
              <Route path="contacted-properties" exact element={<UserContactedProperties />} />
              <Route
                path="saved-projects"
                exact
                element={<SavedProperties key={'saved-projects'} />}
              />
              <Route path="saved-searches" exact element={<SavedSearches />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
