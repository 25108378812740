/*eslint-disable*/
import React from 'react';
import classes from './CompareCard.module.css';
import image from '../../assets/images/house.jpg';
import { BiTransfer } from 'react-icons/bi';
import { MdLocationOn, MdWifiCalling3 } from 'react-icons/md';
import { GoMail } from 'react-icons/go';
import { IoLogoWhatsapp } from 'react-icons/io';

// import { Col, Row } from 'react-bootstrap';
import Carousel from 'react-elastic-carousel';
import { ReactSVG } from 'react-svg';
import { keyDev } from '../../constant/imagePath';
import HeadingWithUnderline from '../HeadingWithUnderline';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Tooltip from '../Tooltip';
import EmailAgentModal from '../../modals/EmailAgentModal';
import { useState } from 'react';
import { imageUrl } from '../../config/apiUrl';
import { restrictOnlyUserActions } from '../../Helper/restrictActions';
import { useNavigate } from 'react-router-dom';
import { useFavoriteEntityMutation, usePostApiMutation } from '../../store/apiSlice/apiSlice';
import { updateUser } from '../../store/auth/authSlice';
import { toast } from 'react-toastify';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
const items = [image, image];
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 }
];

const CompareCard = ({ data, type = 'Property', refetch }) => {
  const {t}=useTranslation('compareList')
  const { user, isLogin } = useSelector((state) => state?.authReducer);
  const { selectedLanguage } = useSelector((state) => state?.languageReducer);
  const [isModalOpen, setIsModalOpen] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [postApiFunc] = usePostApiMutation();
  const isFavorite = data?.favoriteBy?.includes(user?._id);
  const [onFavorite, { isLoading: isFavoriting }] = useFavoriteEntityMutation();

  const renderData = (listData) => {
    let userGot = listData?.assignedAgent ? listData?.assignedAgent : listData?.company;
    return userGot;
  };
  const handleAction = () => {
    const isAllowedActions = restrictOnlyUserActions(user?.role);
    if (!isLogin) {
      navigate('/login');
    } else if (isAllowedActions) {
      return true;
    }
  };

  const handleCompare = async () => {
    const response = await postApiFunc({
      url: `users/compare/add`,
      method: 'POST',
      tag: `compareListFront`,
      data: {
        id: data?._id,
        type: type?.toLowerCase()
      }
    });
    if (response?.data) {
      dispatch(updateUser(response?.data?.data));
      toast.success(`${type} removed from compare list successfully`);
      refetch && refetch();
    }
  };

  const handleFavorite = async () => {
    if (isFavoriting) return;
    const res = await onFavorite({ id: data?._id, type: type?.toLowerCase() });
    if (res?.data) {
      if (res?.data?.data?.favoriteBy?.includes(user?._id)) {
        toast.success(`${type} added in favorite list successfully`);
      } else {
        toast.success(`${type} removed from favorite list successfully`);
      }
      refetch && refetch();
    }
  };

  return (
    <>
      <style>
        {`
        .image-carousel .rec-slider-container{
            padding-block:0px;
            margin:0px;
        }
       

        .image-carousel .rec-carousel-wrapper{
            height:100%;
        }
        .image-carousel .rec-slider{
        }
        .image-carousel .rec-arrow-left{
            position: absolute;
            z-index: 1;
            left: 0px;
            height: 100%;
            min-width:0px;
            width:30px;
            box-shadow:none;
            background-color:rgba(0, 0, 0, 0.3) !important;
            margin-inline-start:0px
        }
        .image-carousel .rec-arrow-right{
            position: absolute;
            right: 0px;
            height: 100%;
            min-width:0px;
            width:30px;
            box-shadow:none;
            background-color:rgba(0, 0, 0, 0.3) !important;
            margin-inline-end:0px
        }
        .image-carousel .rec-item-wrapper{
            height:100%;
        }
        `}
      </style>

      <div
        className={classes.cardMain}
        onClick={() =>
          navigate(type == 'Property' ? `/properties/${data?.slug}` : `/projects/${data?.slug}`)
        }>
        <div className={classes.image}>
          <Carousel
            className="image-carousel"
            breakPoints={breakPoints}
            pagination={false}
            showArrows={true}>
            {data?.images.map((item, index) => (
              <div className={classes?.imgDiv}>
                <img src={imageUrl(item)} key={index} alt="" />
              </div>
            ))}
          </Carousel>
        </div>
        <div className={classes.contentWrap}>
          {type == 'Property' ? (
            <div className={classes.content}>
              <div className={classes.head_div}>
                <div className={classes.priceRow}>
                  <div className={classes.price}>
                    <h6>${data?.price}</h6>
                    <p>{t('propertyCard.perMonth')}</p>
                  </div>
                  <div className={classes.iconWrap}>
                    <BiTransfer
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!handleAction()) return;
                        handleCompare();
                      }}
                      size={20}
                      fill={`${
                        user?.compareList?.find((item) => item?.entity == data?._id)
                          ? 'var(--action-icon-clr)'
                          : 'var(--text-light-gray-clr)'
                      } `}
                    />
                    {isFavorite ? (
                      <AiFillHeart
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!handleAction()) return;
                          handleFavorite();
                        }}
                        fill="var(--action-icon-clr)"
                        size={20}
                      />
                    ) : (
                      <AiOutlineHeart
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!handleAction()) return;
                          handleFavorite();
                        }}
                        fill="var(--text-light-gray-clr)"
                        size={20}
                      />
                    )}
                  </div>
                </div>
                <div className={classes.description}>
                  <h6>{data?.title?.[selectedLanguage]}</h6>
                </div>

                <div className={classes.addressWrap}>
                  <MdLocationOn color="#EF8B1D" />
                  <h6>{data?.address}</h6>
                </div>
                {
                  <HeadingWithUnderline underlineColor={`var(--secondary-clr)`}>
                    <h5>{t('propertyCard.overView')}</h5>
                  </HeadingWithUnderline>
                }
              </div>
              <div className={classes.feature_div}>
                <p>{t('propertyCard.listingDate')}</p>
                <p>{moment(data?.createdAt).format('ll')}</p>
              </div>
              <div className={classes.feature_div}>
                <p>{t('propertyCard.listingDate')}</p>
                <p>{data?.propertyType?.title?.[selectedLanguage]}</p>
              </div>
              <div className={classes.feature_div}>
                <p>{data?.propertyPurpose?.en == 'Buy' ? t('price') : t('rentPrice')}</p>
                <p>{data?.price}</p>
              </div>
              <div className={classes.feature_div}>
                <p>{t('propertyCard.area')}</p>
                <p>{data?.netArea} sqft</p>
              </div>
              <div className={classes.feature_div}>
                <p>{t('propertyCard.rooms')}</p>
                <p>{data?.noOfRooms?.[selectedLanguage]}</p>
              </div>
              <div className={classes.feature_div}>
                <p>{t('propertyCard.bathrooms')}</p>
                <p>{data?.noOfBathrooms}</p>
              </div>
              <div className={classes.feature_div}>
                <p>{t('propertyCard.parkingSpaces')}</p>
                <p>{data?.parkingSpace} sqft</p>
              </div>
              <div className={classes.feature_div}>
                <p>{t('propertyCard.furniture')}</p>
                <p>{data?.furniture?.[selectedLanguage]}</p>
              </div>
              <div className={classes.feature_div}>
                <p>{t('propertyCard.floorLevel')}</p>
                <p>{data?.floorLevel?.[selectedLanguage]}</p>
              </div>
              {/* <div className={classes.feature_div}>
              <p>Ceiling height</p>
              <p>{}</p>
            </div> */}
              <div className={classes.feature_div}>
                <p>{t('propertyCard.propertyAge')}</p>
                <p>{data?.propertyAge?.[selectedLanguage]}</p>
              </div>
              <div className={classes.feature_div}>
                <p>{t('propertyCard.propertyStatus')}</p>
                <p>{data?.propertyStatus?.[selectedLanguage]}</p>
              </div>
              <div className={classes.feature_div_last}>
                <p>{t('propertyCard.viewAndOrientation')}</p>
                <p>{data?.propertyOrientation?.[selectedLanguage]}</p>
              </div>
              <div className={classes?.iconsContainer}>
                <div className={classes.icon_div}>
                  <a className={classes.socialIcons1} href={`tel:${renderData(data)?.phoneNumber}`}>
                    <Tooltip text={t('propertyCard.tooltips.call')}>
                      <MdWifiCalling3 fill="#E2831B" size={25} />
                    </Tooltip>
                  </a>
                  {/* <MdWifiCalling3 fill="#E2831B" size={25} /> */}
                  <a className={classes.socialIcons1}>
                    <Tooltip text={t('propertyCard.tooltips.email')}>
                      <GoMail
                        onClick={() => {
                          setIsModalOpen('email');
                          setSelectedItem(data);
                        }}
                        fill="#E2831B"
                        size={25}
                      />
                    </Tooltip>
                  </a>
                  <a
                    className={classes.socialIcons1}
                    href={`https://wa.me/${renderData(data)?.whatsapp}`}
                    target={'_blank'}>
                    <Tooltip text={t('propertyCard.tooltips.whatsapp')}>
                      <IoLogoWhatsapp fill="#E2831B" size={25} />
                    </Tooltip>
                  </a>
                </div>
                <div className={classes.card_footer_logo}>
                  <ReactSVG src={keyDev} />
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.content}>
              <div className={classes.head_div}>
                <div className={classes.description1}>
                  <h5>{data?.name?.[selectedLanguage]}</h5>
                </div>
                <div className={classes.priceRow}>
                  <div className={classes.price}>
                    <h6>${data?.price}</h6>
                  </div>
                  <div className={classes.iconWrap}>
                    <BiTransfer
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!handleAction()) return;
                        handleCompare();
                      }}
                      size={20}
                      fill={`${
                        user?.compareList?.find((item) => item?.entity == data?._id)
                          ? 'var(--action-icon-clr)'
                          : 'var(--text-light-gray-clr)'
                      } `}
                    />
                    {isFavorite ? (
                      <AiFillHeart
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!handleAction()) return;
                          handleFavorite();
                        }}
                        fill="var(--action-icon-clr)"
                        size={20}
                      />
                    ) : (
                      <AiOutlineHeart
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!handleAction()) return;
                          handleFavorite();
                        }}
                        fill="var(--text-light-gray-clr)"
                        size={20}
                      />
                    )}
                  </div>
                </div>
                <div className={classes.description}>
                  <h6>{data?.title?.[selectedLanguage]}</h6>
                </div>

                <div className={classes.addressWrap}>
                  <MdLocationOn color="#EF8B1D" />
                  <h6>{data?.address}</h6>
                </div>
                {
                  <HeadingWithUnderline underlineColor={`var(--secondary-clr)`}>
                    <h5>{t('projectCard.overView')}</h5>
                  </HeadingWithUnderline>
                }
              </div>
              <div className={classes.feature_div}>
                <p>{t('projectCard.listingDate')}</p>
                <p>{moment(data?.createdAt).format('ll')}</p>
              </div>
              <div className={classes.feature_div}>
                <p>{t('projectCard.type')}</p>
                <p>{data?.projectCard?.title?.[selectedLanguage]}</p>
              </div>
              <div className={classes.feature_div}>
                <p>{t('projectCard.startingPrice')}</p>
                <p>{data?.price}</p>
              </div>
              <div className={classes.feature_div}>
                <p>{t('projectCard.areaRanges')}</p>
                <p>
                  {data?.minimumArea} - {data?.maximumArea}
                </p>
              </div>
              <div className={classes.feature_div}>
                <p>{t('projectCard.noOfUnits')}</p>
                <p>{data?.noOfUnits}</p>
              </div>
              <div className={classes.feature_div}>
                <p>{t('projectCard.plannedDeliveryDate')}</p>
                <p>{moment(data?.deliveryDate).format('LL')}</p>
              </div>

              <div className={classes?.iconsContainer}>
                <div className={classes.icon_div}>
                  <a className={classes.socialIcons1} href={`tel:${renderData(data)?.phoneNumber}`}>
                    <Tooltip text={t('projectCard.tooltips.call')}>
                      <MdWifiCalling3 fill="#E2831B" size={25} />
                    </Tooltip>
                  </a>
                  {/* <MdWifiCalling3 fill="#E2831B" size={25} /> */}
                  <a className={classes.socialIcons1}>
                    <Tooltip text={t('projectCard.tooltips.email')}>
                      <GoMail
                        onClick={() => {
                          setIsModalOpen('email');
                          setSelectedItem(data);
                        }}
                        fill="#E2831B"
                        size={25}
                      />
                    </Tooltip>
                  </a>
                  <a
                    className={classes.socialIcons1}
                    href={`https://wa.me/${renderData(data)?.whatsapp}`}
                    target={'_blank'}>
                    <Tooltip text={t('projectCard.tooltips.whatsapp')}>
                      <IoLogoWhatsapp fill="#E2831B" size={25} />
                    </Tooltip>
                  </a>
                </div>
                <div className={classes.card_footer_logo}>
                  <ReactSVG src={keyDev} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isModalOpen == 'email' && (
        <EmailAgentModal
          show={isModalOpen == 'email'}
          setShow={setIsModalOpen}
          data={selectedItem}
          type={'property'}
        />
      )}
    </>
  );
};

export default CompareCard;
