/*eslint-disable*/
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Input from '../Input/Input';
import DropDown from '../DropDown/DropDown';
import FileUpload from '../FileUpload';
import ModalSkeleton from '../../modals/ModalSkeleton';
import { languagesOptions, projectUnits, roomsOptions } from '../../constant/commonData';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '../Button/Button';
import { CreateFormData } from '../../config/apiUrl';
import { useEffect } from 'react';
import FormLanguages from '../FormLanguages';
import classes from './UnitAddModal.module.css';
import { useTranslation } from 'react-i18next';
const UnitAddModal = ({ show, setShow, onClick, isApiCall, data }) => {
  const { exteriorAmenities, interiorAmenities } = useSelector((state) => state?.commonReducer);
  const [language, setLanguage] = useState(languagesOptions[0]);

  const [unitsPrice, setUnitsPrice] = useState(data?.price || '');
  const [unitsType, setUnitsType] = useState(data?.unitType || '');
  const [unitsArea, setUnitsArea] = useState(data?.area || '');
  const [rooms, setRooms] = useState(data?.noOfRooms || '');
  const [bathRooms, setBathRooms] = useState(data?.noOfBathrooms || '');
  const [carParking, setCarParking] = useState(data?.carParking || '');
  const [unitImagesAndPlan, setUnitImagesAndPlan] = useState(data?.images || []);
  const [unitExAmenities, setUnitExAmenities] = useState(
    exteriorAmenities?.filter((e) => data?.exteriorAmenities?.includes(e._id)) || ''
  );

  const { t } = useTranslation('dashboardProjects', { lng: language?.value });

  const [unitInAmenities, setUnitInAmenities] = useState(
    interiorAmenities?.filter((e) => data?.interiorAmenities?.includes(e?._id)) || ''
  );

  const handleSubmit = async () => {
    const body = {
      unitType: unitsType,
      area: unitsArea,
      noOfRooms: rooms,
      noOfBathrooms: bathRooms,
      carParking: carParking,
      price: unitsPrice,
      exteriorAmenities: unitExAmenities?.map((e) => e._id),
      interiorAmenities: unitInAmenities?.map((e) => e._id),
      images: unitImagesAndPlan,
      ...(data ? { unitId: data?._id } : null)
    };
    // validation

    for (let key in body) {
      if (body[key] === '') {
        return toast.warn('Please fill all the fields');
      }
    }

    const formData = CreateFormData(body);

    onClick(formData, { projectId: data?._id });
  };
  // console.log(projectUnits, 'projectUnits projectUnits projectUnits');
  return (
    <ModalSkeleton width={'1100px'} show={show} setShow={setShow}>
      <Container>
        <Row
          className={`
        ${classes.modal_row}
        ${[['ar', 'fa']?.includes(language?.value) ? 'rtl' : 'ltr'].join(' ')}`}>
          <Col md={12}>
            <h4>{t('projectUnitInformation.modalTitle')}</h4>
          </Col>
          <Col md={12}>
            <div className={classes.headAndDropDown}>
              <FormLanguages value={language} setter={setLanguage} />
            </div>
          </Col>
          <Col md={6}>
            <DropDown
              label={t('projectUnitInformation.projectUnitsType')}
              placeholder={t('projectUnitInformation.projectUnitsTypePlaceholder')}
              // options={propertyStatusOptions}
              variant="dashboard"
              indicatorColor="var(--dashboard-text-gray-clr)"
              options={projectUnits}
              getOptionValue={(e) => e?.[language?.value]}
              getOptionLabel={(e) => e?.[language?.value]}
              value={unitsType}
              setValue={setUnitsType}
            />
          </Col>
          <Col md={6}>
            <Input
              customStyle={{
                height: '55px'
              }}
              type={'number'}
              label={t('projectUnitInformation.projectUnitsArea')}
              placeholder={t('projectUnitInformation.projectUnitsAreaPlaceholder')}
              variant="dashboard"
              value={unitsArea}
              setValue={setUnitsArea}
            />
          </Col>
          <Col md={6}>
            <DropDown
              type={'number'}
              label={t('projectUnitInformation.noOfRooms')}
              placeholder={t('projectUnitInformation.noOfRoomsPlaceholder')}
              variant="dashboard"
              indicatorColor="var(--dashboard-text-gray-clr)"
              value={rooms}
              setValue={setRooms}
              getOptionValue={(e) => e?.[language?.value]}
              getOptionLabel={(e) => e?.[language?.value]}
              options={roomsOptions}
            />
          </Col>
          <Col md={6}>
            <Input
              customStyle={{
                height: '55px'
              }}
              label={t('projectUnitInformation.noOfBathrooms')}
              placeholder={t('projectUnitInformation.noOfBathroomsPlaceholder')}
              variant="dashboard"
              value={bathRooms}
              setValue={setBathRooms}
              // options={NoOfUnitsOptions}
            />
          </Col>
          <Col md={6}>
            <Input
              customStyle={{
                height: '55px'
              }}
              label={t('projectUnitInformation.carParking')}
              placeholder={t('projectUnitInformation.carParkingPlaceholder')}
              variant="dashboard"
              value={carParking}
              setValue={setCarParking}
            />
          </Col>
          <Col md={6}>
            <Input
              customStyle={{
                height: '55px'
              }}
              label={"Unit's Price"}
              placeholder={"Enter Unit's Price"}
              variant="dashboard"
              value={unitsPrice}
              setValue={setUnitsPrice}
            />
          </Col>
          <Col md={12}>
            <FileUpload
              label={t('projectUnitInformation.projectUnitsPlans')}
              files={unitImagesAndPlan}
              setFiles={setUnitImagesAndPlan}
            />
          </Col>
          <Col md={6}>
            <DropDown
              label={t('projectUnitInformation.exteriorAmenities')}
              placeholder={t('projectUnitInformation.exteriorAmenitiesPlaceholder')}
              variant="dashboard"
              value={unitExAmenities}
              setValue={setUnitExAmenities}
              indicatorColor="var(--dashboard-text-gray-clr)"
              getOptionValue={(e) => e?.title?.[language?.value]}
              getOptionLabel={(e) => e?.title?.[language?.value]}
              options={exteriorAmenities}
              isMulti={true}
            />
          </Col>
          <Col md={6}>
            <DropDown
              label={t('projectUnitInformation.interiorAmenities')}
              placeholder={t('projectUnitInformation.interiorAmenitiesPlaceholder')}
              variant="dashboard"
              value={unitInAmenities}
              setValue={setUnitInAmenities}
              indicatorColor="var(--dashboard-text-gray-clr)"
              options={interiorAmenities}
              getOptionValue={(e) => e?.title?.[language?.value]}
              getOptionLabel={(e) => e?.title?.[language?.value]}
              optionLabel={'label'}
              optionValue={'value'}
              isMulti={true}
            />
          </Col>
          <Col className="text-center mt-4">
            <Button
              disabled={isApiCall}
              label={
                isApiCall
                  ? t('projectUnitInformation.submitBtnLoading')
                  : t('projectUnitInformation.submitBtn')
              }
              onClick={handleSubmit}
            />
          </Col>
        </Row>
      </Container>
    </ModalSkeleton>
  );
};

export default UnitAddModal;
