import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import classes from './PrivacyPolicy.module.css';
import Footer from '../../Component/Footer';
import Header from '../../Component/Header';
import HeadingWithUnderline from '../../Component/HeadingWithUnderline';
import Parser from 'html-react-parser';
import { useLazyGetApiQuery } from '../../store/apiSlice/apiSlice';
import { Loader } from '../../Component/Loader';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const {t}=useTranslation('webCommon')

  const { selectedLanguage } = useSelector((state) => state.languageReducer);

  const [getApiFunc, { isLoading, data: response }] = useLazyGetApiQuery();

  const getData = async () => {
    await getApiFunc({
      url: `privacy-policy`,
      tag: `PrivacyPolicy`
    });
  };

  useEffect(() => {
    getData();
  }, []);

  console.log(response, 'response');

  return (
    <div className={classes.page}>
      <Header containerClass="customContainer" />
      <div className={classes?.containerWrapper}>
        {isLoading ? (
          <Loader />
        ) : (
          <Container className="customContainer py-4">
            <Row className={classes.row}>
              <Col md={12}>
                <HeadingWithUnderline>
                  <h4>{t('privacyPolicy.title')}</h4>
                </HeadingWithUnderline>
              </Col>
              <Col md={12}>
                <div className={classes.decriptionBox}>
                  {response?.data ? Parser(response?.data?.content?.[selectedLanguage]) : ''}
                </div>
              </Col>
            </Row>

            <div className={classes?.buttonContainer}></div>
          </Container>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
